import React, { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { IObjectBookmark } from '../../bookmarks';
import { QuinoLabeled } from '../QuinoLabeled';
import { getMetaProperty } from '@quino/core';
import { useLabelSettings, useMetadata } from '../../settings';
import { useValidation } from '../Util';
import 'devextreme/ui/html_editor/converters/markdown';
import { useRerenderOnChanges } from '../Util/MetaPropertyHooks';
import { QuinoMultiLineRichTextEditor } from './QuinoMultiLineRichTextEditor';

export function QuinoMultiLineRichTextEditorLabeled(props: PropsWithChildren<IQuinoComponentProps<IObjectBookmark>>) {
  const { element, bookmark, actions } = props;
  const metaProperty = getMetaProperty(element);
  const { description, caption, instruction } = metaProperty;

  const labelSettings = useLabelSettings(element);
  const { visible, required } = useMetadata(metaProperty, bookmark.genericObject);

  const [, errorMessages] = useValidation(bookmark, metaProperty);

  useRerenderOnChanges(bookmark, metaProperty);

  return (
    <div className='quino-multiline-rich-text-editor'>
      <QuinoLabeled
        visible={visible}
        label={caption}
        required={required}
        description={description}
        instruction={instruction}
        errorMessages={errorMessages}
        labelSettings={labelSettings}>
        <QuinoMultiLineRichTextEditor bookmark={bookmark} element={element} actions={actions} />
      </QuinoLabeled>
    </div>
  );
}
