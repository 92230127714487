import { useService } from '../ioc';
import { ExpressionContextChangedMessage, ExpressionContextChangedMessageSymbol, IMessenger, IMessengerSymbol } from '@quino/core';
import { useState } from 'react';
import { useOnMount } from '../components';

/**
 * Triggers a re-render if an expression changes because of a context change.
 */
export function useExpression(): any {
  const messenger = useService<IMessenger>(IMessengerSymbol);
  const [lastMessage, setLastMessage] = useState<ExpressionContextChangedMessage | undefined>();

  // TODO: Either this message should be triggered really sporadically or
  //  we should not subscribe to it if we don't have an expression assigned.
  // TODO: Maybe pass in the expression to evaluate if we need to attach at all...

  useOnMount(() => {
    const symbol = messenger.subscribe<ExpressionContextChangedMessage>(ExpressionContextChangedMessageSymbol, setLastMessage);

    return () => messenger.unsubscribe(symbol);
  });

  return lastMessage;
}
