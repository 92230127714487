import {
  getAspectOrDefault,
  getMetaProperty,
  IconAspectIdentifier,
  IIconAspect,
  isIMetaAction,
  ITranslationService,
  ITranslationServiceSymbol
} from '@quino/core';
import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { Button } from 'devextreme-react/button';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { useLabelSettings } from '../../settings';
import { useMetadata } from '../../settings/useMetadata';
import { useService } from '../../ioc/hook';
import { useOnDependencyChanged } from '../Util/MetaElementHooks';
import { IQuinoActionFactory, IQuinoActionFactorySymbol } from '../../actions';

export function QuinoInlineActionButton(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const actionFactory = useService<IQuinoActionFactory>(IQuinoActionFactorySymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const { element, bookmark } = props;
  const metaProperty = getMetaProperty(element);
  const { description } = metaProperty;
  const labelSettings = useLabelSettings(element);
  const { visible, enabled } = useMetadata(metaProperty, bookmark.genericObject);
  const action = (isIMetaAction(metaProperty) && metaProperty) || undefined;
  const icon = useMemo<IIconAspect | null>(() => getAspectOrDefault<IIconAspect>(props.element, IconAspectIdentifier), [props.element]);

  useOnDependencyChanged(element, bookmark);

  return (
    <>
      {(action && (
        <div className={'quino-inline-action-wrapper'}>
          <Button
            stylingMode={'outlined'}
            type={'default'}
            visible={visible}
            icon={icon?.icon}
            disabled={!enabled}
            hint={labelSettings.hintType === 'None' ? description : undefined}
            text={action.caption}
            onClick={() => action && actionFactory.getActionOnClick(action)({ source: bookmark, bookmarkToRefresh: bookmark })}
          />
        </div>
      )) ||
        (visible && <p>{translationService.translate('QuinoActionButton.NoAction', { caption: element.caption })}</p>)}
    </>
  );
}
