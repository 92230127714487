import React, { FC, memo, useMemo } from 'react';
import { Button } from 'devextreme-react/button';
import { IQuinoContextMenuItem, QuinoContextMenuButton } from '../ContextMenu';
import { IQuinoAction, quinoActionPropValue } from '../../actions';

interface IQuinoDataGridRowActionProps {
  genericObject: any;
  action: IQuinoAction;
}

export const QuinoDataGridRowAction: FC<IQuinoDataGridRowActionProps> = memo(({ genericObject, action }) => {
  return (
    <Button
      hint={quinoActionPropValue(action.hint, genericObject)}
      icon={quinoActionPropValue(action.icon, genericObject)}
      disabled={quinoActionPropValue(action.disabled, genericObject)}
      stylingMode={'text'}
      className={'quino-data-grid-row-action'}
      onClick={() => action.onClick({ source: genericObject })}
    />
  );
});

QuinoDataGridRowAction.displayName = 'QuinoDataGridRowAction';

interface IQuinoDataGridRowActionOverflowProps {
  genericObject: any;
  actions: IQuinoAction[];
  buttonHint: string;
  rowKey: any;
}

export const QuinoDataGridRowActionOverflow: FC<IQuinoDataGridRowActionOverflowProps> = memo(({ genericObject, actions, buttonHint, rowKey }) => {
  const contextMenuItems = useMemo((): IQuinoContextMenuItem[] => {
    return actions.map((action) => {
      return {
        icon: quinoActionPropValue(action.icon, genericObject),
        hint: quinoActionPropValue(action.hint, genericObject),
        disabled: quinoActionPropValue(action.disabled, genericObject),
        text: quinoActionPropValue(action.caption, genericObject),
        onItemClick: () => action.onClick({ source: genericObject })
      };
    });
  }, [actions, genericObject]);

  return (
    <div className={'quino-datagrid-row-action-overflow-wrapper'}>
      <QuinoContextMenuButton
        buttonHintKey={buttonHint}
        dataSource={contextMenuItems}
        buttonId={'table-row-actions-overflow-' + rowKey}
        buttonClassname={'quino-data-grid-row-action'}
        buttonIcon={'material-icons-outlined more_vert'}
        position={{ at: 'right bottom', my: 'right top' }}
      />
    </div>
  );
});
