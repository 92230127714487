import { INavigationService, INavigationServiceSymbol } from './INavigationService';
import { IBookmark } from '../bookmarks';
import { useOnMount, useRerender } from '../components/Util';
import { useService } from '../ioc/hook';

/**
 * Gets the current bookmark that is on top of the navigation stack.
 */
export const useOnNavigation = (): IBookmark | undefined => {
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const renderer = useRerender();

  useOnMount(() => {
    const symbol = navigationService.registerOnChange(renderer);

    return () => navigationService.removeOnChange(symbol);
  });

  return navigationService.active;
};
