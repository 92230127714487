import { IMetaAspect } from '../IMetaAspect';
import { IExpression } from '../../expressions';

/**
 * Aspect containing color info
 */
export interface IColorAspect extends IMetaAspect {
  /**
   * Css-readable color string
   */
  color: string | IExpression;
}

/**
 * Aspect containing background color info
 */
export interface IBackgroundColorAspect extends IColorAspect {}
export const BackgroundColorAspectIdentifier = 'BackgroundColorAspect';

/**
 * Aspect containing font color info
 */
export interface IFontColorAspect extends IColorAspect {}
export const FontColorAspectIdentifier = 'FontColorAspect';
