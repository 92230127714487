import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IQuinoServerServiceConfiguration } from './IQuinoServerServiceConfiguration';
import {
  getAspectOrDefault,
  IMetaLayout,
  IMetaRelation,
  IMetaVisitor,
  IMetaVisitorSymbol,
  isIMetaAction,
  isIMetaLayout,
  isIMetaProperty,
  isMetaRelation,
  ISortOrderAspect,
  isValueList,
  LayoutType,
  MetaCardinality,
  SortOrderAspectIdentifier
} from '../../meta';
import { IMetadataTree } from '../IMetadataTree';
import { IMetaClass } from '../../meta/IMetaClass';
import { ILayoutResolver, ILayoutResolverSymbol } from '../../scoping';
import { LookupNormalizer } from '../../storage';
import { isIMetaElement } from '../../meta/IMetaElement';

/**
 * The symbol to use to resolve the ODataUrlBuilder from the IOC.
 */
export const ODataUrlBuilderSymbol = Symbol.for('ODataUrlBuilderSymbol');
export const ODataUrlPathDelimiter = '.';

/**
 * Select and expand options for an OData query.
 */
export interface ISelectAndExpandOptions {
  /**
   * The navigation property to expand.
   */
  navigationProperty: string;

  /**
   * The fields to select. If empty everything is selected.
   */
  select: string[];

  /**
   * Nested expand and select options.
   */
  expand: ISelectAndExpandOptions[];

  /**
   * The layout to use
   */
  layoutFilter?: string;
}

/**
 * Simple service for constructing OData urls.
 */
@injectable()
export class ODataUrlBuilder {
  constructor(
    @inject(QuinoCoreServiceSymbols.IQuinoServerServiceConfiguration) settings: IQuinoServerServiceConfiguration,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(ILayoutResolverSymbol) private readonly layoutResolver: ILayoutResolver,
    @inject(IMetaVisitorSymbol) private readonly metaVisitor: IMetaVisitor
  ) {
    this.baseUrl = !settings.baseUrl.endsWith('/') ? settings.baseUrl + '/odata' : settings.baseUrl + 'odata';
  }

  entity(metaClass: string, primaryKey?: string): ODataUrl {
    return new ODataUrl(
      this.baseUrl + (primaryKey != null ? `/${metaClass}(${primaryKey})?` : `/${metaClass}?`),
      this.metadataTree,
      this.metaVisitor,
      this.layoutResolver
    );
  }

  private readonly baseUrl: string;
}

class ODataUrl {
  constructor(
    url: string,
    private readonly metadataTree: IMetadataTree,
    private readonly metaVisitor: IMetaVisitor,
    private readonly layoutResolver: ILayoutResolver
  ) {
    this.baseUrl = url;
  }

  apply(metaClass: IMetaClass, layout: IMetaLayout, expandRelationsToMultiple: boolean): ODataUrl {
    this.calculateLoadOptions(metaClass, layout, undefined, expandRelationsToMultiple);

    return this;
  }

  toString(): string {
    let url = this.baseUrl;
    url += ODataUrl.optionsToString(this.options);

    return url.endsWith('?') ? url.substring(0, url.length - 1) : url;
  }

  toExpand(): string[] {
    const result = [];
    for (const expandOption of this.options.expand) {
      result.push(ODataUrl.optionsToString(expandOption));
    }

    return result;
  }

  raw(): ISelectAndExpandOptions {
    return this.options;
  }

  select(options: string[]): void {
    this.options.select = [...options];
  }

  setLayoutFilter(layout: IMetaLayout | string): void {
    if (isIMetaElement(layout) && isIMetaLayout(layout)) {
      this.options.layoutFilter = layout.name;
    } else {
      this.options.layoutFilter = layout;
    }
  }

  private readonly calculateLoadOptions = (
    metaClass: IMetaClass,
    layout: IMetaLayout,
    root: ISelectAndExpandOptions | undefined,
    expandRelationsToMultiple: boolean
  ): ISelectAndExpandOptions => {
    const result = root != null ? root : this.options;

    this.metaVisitor.visit(layout, (element) => {
      if (isIMetaAction(element)) {
        return;
      }

      if (isMetaRelation(element)) {
        if (element.cardinality !== MetaCardinality.Multiple || expandRelationsToMultiple) {
          const segments = element.path.split(ODataUrlPathDelimiter);
          if (segments.length > 1) {
            // Travers the path and expand the relation at the end.
            this.expandNavigationSegments(segments, metaClass, result, element);

            return;
          } else {
            if (!isValueList(element)) {
              const existing = result.expand.find((x) => x.navigationProperty === element.path);
              const expandedRelation = existing != null ? existing : { navigationProperty: element.path, select: [], expand: [] };
              this.expandRelated(element, expandedRelation);
              if (!existing) {
                result.expand.push(expandedRelation);
              }
            }
          }
        }
        result.select.push(element.sourceProperties[0]);
        result.select = result.select.filter((n, i) => result.select.indexOf(n) === i);

        return;
      }

      if (isIMetaProperty(element)) {
        if (element.path.indexOf(ODataUrlPathDelimiter) > 0) {
          // The isMetaProperty above only indicates that the final target is a meta property. The rest of the path have to be relations/navigation props.
          const segments = element.path.split(ODataUrlPathDelimiter);

          // Travers the path along the navigation properties.
          const options = this.expandNavigationSegments(segments.slice(0, segments.length - 1), metaClass, result, null);

          // Push the selected property on the expanded path.
          options.select.push(segments[segments.length - 1]);
        } else {
          if (element.path != null) {
            result.select.push(element.path);
          }
        }
      }
    });

    metaClass.primaryKey.forEach((x) => result.select.push(x));
    result.select.push('metaClass', 'primaryKey');

    const sortOrderProperty = getAspectOrDefault<ISortOrderAspect>(metaClass, SortOrderAspectIdentifier)?.sortOrderProperty;
    if (sortOrderProperty) {
      result.select.push(sortOrderProperty);
    }

    result.select = [...new Set<string>(result.select)];

    return result;
  };

  private readonly expandNavigationSegments = (
    segments: string[],
    startingCls: IMetaClass,
    res: ISelectAndExpandOptions,
    element: IMetaRelation | null
  ): ISelectAndExpandOptions => {
    let result: ISelectAndExpandOptions;
    let currentClass = startingCls;
    segments.forEach((value, index) => {
      let existing = res.expand.find((x) => x.navigationProperty === value);
      if (existing == null) {
        existing = { navigationProperty: value, expand: [], select: [] };
        res.expand.push(existing);
      }

      // Since the segment could potentially loop over other relations we need to fetch the correct navigation property.
      const currentRelation = currentClass.relations.find((x) => LookupNormalizer.Normalize(x.name) === LookupNormalizer.Normalize(value));
      if (currentRelation == null) {
        // Failed to follow the path. Something is wrong.
        throw new Error(`Encountered invalid OData-Path or failed to parse it correctly. [${segments}] failed at part [${value}].`);
      }

      currentClass = this.metadataTree.getClass(currentRelation.targetClass);
      this.expandRelated(currentRelation, existing);
      if (index === segments.length - 1 && element != null) {
        res.select.push(element.sourceProperties[0]);
      }

      res = existing;
      result = existing;
    });

    return result!;
  };

  private readonly expandRelated = (element: IMetaRelation, existing?: ISelectAndExpandOptions) => {
    const targetClass = this.metadataTree.getClass(element.targetClass);

    const layoutType = element.cardinality === MetaCardinality.Multiple ? LayoutType.List : LayoutType.Title;
    const layout = this.layoutResolver.tryResolveSingle({ type: layoutType, metaClass: targetClass.name, element: element });

    layout && this.calculateLoadOptions(targetClass, layout, existing, false);
  };

  private static optionsToString(selectOptions: ISelectAndExpandOptions): string {
    const selectSet = new Set<string>(selectOptions.select.filter((x) => x != null && x !== ''));
    const expandClause = selectOptions.expand.length > 0 ? `$expand=${selectOptions.expand.map((x) => ODataUrl.optionsToString(x)).join(',')}` : '';
    const selectClause = selectOptions.select.length > 0 ? `$select=${[...selectSet].join(',')}` : '';
    const layoutFilterClause = selectOptions.layoutFilter ? `&layout=${selectOptions.layoutFilter}` : '';

    if (expandClause === '' && selectClause === '' && layoutFilterClause === '') {
      return selectOptions.navigationProperty;
    }

    if (selectOptions.navigationProperty === '') {
      return `${expandClause}${expandClause !== '' && selectClause !== '' ? '&' : ''}${selectClause}${layoutFilterClause}`;
    }

    return `${selectOptions.navigationProperty}(${expandClause}${
      expandClause !== '' && selectClause !== '' ? ';' : ''
    }${selectClause})${layoutFilterClause}`;
  }

  private options: ISelectAndExpandOptions = { select: [], expand: [], navigationProperty: '' };

  private readonly baseUrl: string;
}
