import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression, isExpression } from './IExpression';
import { isConstantExpression } from './IConstantExpression';
import { injectable } from 'inversify';

export const ConstantExpressionEvaluatorSymbol = Symbol.for('ConstantExpressionEvaluator');

@injectable()
export class ConstantExpressionEvaluator implements IExpressionEvaluator {
  evaluate<TValue>(expression: IExpression | TValue, _context: any): TValue {
    if (isExpression(expression) && isConstantExpression(expression)) {
      return expression.value as TValue;
    }

    // @ts-ignore
    return expression;
  }
}
