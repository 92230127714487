import { IQuinoDataGridProps, QuinoDataGridColumnFactory } from '@quino/ui';
import { IGenericObject, IMetaProperty, IMetaLayout } from '@quino/core';
import { Column } from 'devextreme-react/data-grid';
import dxDataGrid from 'devextreme/ui/data_grid';
import { CONTROL_IDENTIFIER } from './ControlIdentifier';
import React from 'react';
import { HasFileUploadButton } from './HasFileUploadButton';

export class SandboxDataGridColumnFactory extends QuinoDataGridColumnFactory {
  generate(
    property: IMetaProperty,
    forLocalSource: boolean,
    dataGridProps: IQuinoDataGridProps,
    onDrilldown: (data: IGenericObject) => void,
    canEdit?: boolean,
    dxDataGrid?: dxDataGrid,
    fixedColumnsWidth?: number,
    positionFromBack?: number,
    editMode?: boolean,
    editLayout?: IMetaLayout
  ): any {
    if (property.controlName === CONTROL_IDENTIFIER.HAS_FILE_UPLOAD_BUTTON) {
      return (
        <Column
          key={'DownloadColumn'}
          fixedPosition={'right'}
          allowReordering={false}
          cellRender={(data: any) => <HasFileUploadButton genericObject={data.data} metaProperty={property} />}
        />
      );
    }
    return super.generate(
      property,
      forLocalSource,
      dataGridProps,
      onDrilldown,
      canEdit,
      dxDataGrid,
      fixedColumnsWidth,
      positionFromBack,
      editMode,
      editLayout
    );
  }
}
