import { IModelFetcher } from './IModelFetcher';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IQuinoServerServiceConfiguration, IUrlManager } from './QuinoServer';
import { inject, injectable } from 'inversify';
import { IRequestBuilder } from '../request';
import { IHashedMetaModel, IMetaModel } from '../meta';

@injectable()
export class PublicModelFetcher implements IModelFetcher {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) public urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) public requestBuilder: IRequestBuilder,
    @inject(QuinoCoreServiceSymbols.IQuinoServerServiceConfiguration) public quinoServerServiceConfiguration: IQuinoServerServiceConfiguration
  ) {}

  async fetch(): Promise<IMetaModel> {
    const url = this.getPublicMetadataUrl();
    return this.requestBuilder.create(url, 'get').fetchJson<IMetaModel>();
  }

  async fetchHash(): Promise<string> {
    const url = this.getPublicMetadataUrl('/hash');
    return (await this.requestBuilder.create(url, 'get').fetch()).text();
  }

  async fetchCurrentModel(hash: string | null): Promise<IHashedMetaModel> {
    const url = this.getPublicMetadataUrl('/currentModel');
    return this.requestBuilder.create(url, 'post').setJsonPayload(hash).requiresAuthentication().fetchJson<IHashedMetaModel>();
  }

  storageKey(): string {
    return 'public_model';
  }

  hashStorageKey(): string {
    return 'public_model_hash';
  }

  private getPublicMetadataUrl(customRoute?: string): string {
    return this.urlManager.getPublicApiUrl('metadata') + (customRoute ?? '');
  }
}
