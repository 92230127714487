import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { injectable } from 'inversify';
import { IDashboardTileDataSource, IDashboardTileRegistration } from '../registry';
import { useService } from '../../ioc';
import {
  QuinoCustomFormBox,
  QuinoLabeled,
  QuinoPopupDefaultContent,
  QuinoPopupToolbar,
  QuinoPopupToolbarButton,
  searchForErrors
} from '../../components';
import { TextBox } from 'devextreme-react/text-box';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { HtmlEditor } from 'devextreme-react/html-editor';
import noop from 'lodash/noop';
import { TextArea } from 'devextreme-react/text-area';

export interface IQuinoDashboardTranslatedTextTileProps {
  caption: string;
  translationKey: string;
}

export const TranslatedTextTileRegistrationSymbol = Symbol.for('TranslatedTextTileRegistration');

@injectable()
export class QuinoDashboardTranslatedTextTileRegistration implements IDashboardTileRegistration<IQuinoDashboardTranslatedTextTileProps> {
  getCaption(payload: IQuinoDashboardTranslatedTextTileProps): string {
    return payload.caption;
  }

  render(payload: IQuinoDashboardTranslatedTextTileProps, dataSource: IQuinoDashboardTranslatedTextTileProps): React.ReactNode {
    return <QuinoDashboardTranslatedTextTile gridProps={payload} source={dataSource} />;
  }

  renderSettings(
    payload: IQuinoDashboardTranslatedTextTileProps,
    apply: (payload: IQuinoDashboardTranslatedTextTileProps) => void,
    cancel: () => void
  ): React.ReactNode {
    return <QuinoDashboardTranslatedTextTileConfiguration gridProps={payload} apply={apply} cancel={cancel} />;
  }

  deserializePayload(payload: any): IQuinoDashboardTranslatedTextTileProps {
    return payload as IQuinoDashboardTranslatedTextTileProps;
  }

  serializePayload(payload: IQuinoDashboardTranslatedTextTileProps): any {
    return payload;
  }

  showRefresh = false;
  name = 'TranslatedText';
  dataSource: IDashboardTileDataSource<IQuinoDashboardTranslatedTextTileProps> = new QuinoDashboardTranslatedTextTileDataSource();
}

class QuinoDashboardTranslatedTextTileDataSource implements IDashboardTileDataSource<IQuinoDashboardTranslatedTextTileProps> {
  refreshData(): void {
    noop();
  }

  // We use the faked dataSource here to keep the dataSource in the interface
  async updateData(payload: IQuinoDashboardTranslatedTextTileProps): Promise<IQuinoDashboardTranslatedTextTileProps> {
    return payload;
  }
}

function QuinoDashboardTranslatedTextTile(props: {
  gridProps: IQuinoDashboardTranslatedTextTileProps;
  source: IQuinoDashboardTranslatedTextTileProps;
}) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const translatedText = translationService.translate(props.source.translationKey);

  return (
    <div className={'quino-dashboard-translated-text-page'}>
      <HtmlEditor valueType={'markdown'} readOnly={true} value={translatedText} focusStateEnabled={false} />
    </div>
  );
}

function QuinoDashboardTranslatedTextTileConfiguration(props: {
  gridProps: IQuinoDashboardTranslatedTextTileProps;
  apply: (payload: IQuinoDashboardTranslatedTextTileProps) => void;
  cancel: () => void;
}) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const [gridProperties, setGridProperties] = useState<IQuinoDashboardTranslatedTextTileProps>(props.gridProps);

  const translationKeyTextArea = useRef<TextArea>(null);
  const [translationKeyErrors, setTranslationKeyErrors] = useState<string[]>([]);

  const getCurrentTranslationKey = (): string => translationKeyTextArea.current?.instance?.option('text') || '';

  const translationKeyValidityFunction = useCallback(() => {
    return { isValid: getCurrentTranslationKey().length > 0, errorMessage: translationService.translate('Validations.TranslationKeyNotCorrect') };
  }, [translationService]);

  const basicSettings = (): ReactNode => (
    <div>
      <QuinoCustomFormBox>
        <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.TileTitle')} required={true}>
          <TextBox
            value={gridProperties.caption}
            onValueChanged={(value) => {
              setGridProperties({ ...gridProperties, caption: value.value });
            }}
          />
        </QuinoLabeled>
        <QuinoLabeled
          description={translationService.translate('Dashboard.Tile.EditDialog.TranslationKeyDescription')}
          label={translationService.translate('Dashboard.Tile.EditDialog.TranslationKey')}
          required={true}
          errorMessages={translationKeyErrors}>
          <TextArea
            ref={translationKeyTextArea}
            value={gridProperties.translationKey}
            minHeight={150}
            onValueChanged={(value) => {
              setGridProperties({ ...gridProperties, translationKey: value.value });
            }}
            isValid={translationKeyErrors.length === 0}
          />
        </QuinoLabeled>
      </QuinoCustomFormBox>
    </div>
  );

  useEffect(() => {
    setGridProperties({ ...props.gridProps });
  }, [props.gridProps]);

  return (
    <>
      <QuinoPopupDefaultContent hasPaddingHorizontal={true} isScrollable={false}>
        {basicSettings()}
      </QuinoPopupDefaultContent>
      <QuinoPopupToolbar
        showTopBorder={true}
        rightItems={[
          <QuinoPopupToolbarButton
            text={translationService.translate('Cancel')}
            icon={'material-icons-outlined clear'}
            onClick={() => {
              props.cancel();
              setGridProperties(props.gridProps);
            }}
          />,
          <QuinoPopupToolbarButton
            text={translationService.translate('Apply')}
            icon={'material-icons-outlined check'}
            isPrimary={true}
            onClick={() => {
              let errorCount = 0;
              errorCount += searchForErrors(getCurrentTranslationKey(), setTranslationKeyErrors, [translationKeyValidityFunction]);
              if (errorCount > 0) {
                return;
              } else {
                props.apply(gridProperties);
              }
            }}
            disabled={!gridProperties.translationKey || !gridProperties.caption}
          />
        ]}
      />
    </>
  );
}
