import { IMetaGroup, isIMetaGroup } from './IMetaGroup';
import { LayoutType } from './LayoutType';
import { IMetaElement } from './IMetaElement';
import { IMetaSort } from './IMetaSort';

/**
 * The root layout usually used for the top level elements.
 */
export interface IMetaLayout extends IMetaGroup {
  /**
   * The layout type.
   */
  type: LayoutType;

  /**
   * List of sorts
   */
  sorts: IMetaSort[];
}

export function isIMetaLayout(object: IMetaElement): object is IMetaLayout {
  return isIMetaGroup(object) && (object as IMetaLayout).type !== undefined;
}
