import { ICUIRegisterComponentProps } from './CUIRegisterAccountComponent';
import React from 'react';

/**
 * The registration factory symbol.
 */
export const ICUIRegisterAccountComponentFactorySymbol = Symbol.for('ICUIRegisterAccountComponentFactory');

/**
 * Action factory for converting IMetaActions to BookmarkActions that can be displayed.
 */
export interface ICUIRegisterAccountComponentFactory {
  /**
   * Create a component to handle registrations.
   * @param props - the properties to use.
   */
  create: (props: ICUIRegisterComponentProps) => React.ReactNode;
}
