import { TCalendarAppointment } from './ICalendarDataService';
export class CalendarRequestObject {
  constructor(fetch: () => Promise<Array<TCalendarAppointment>>) {
    this.cancelled = false;
    this.fetch = fetch;
  }

  cancel() {
    this.cancelled = true;
  }

  isCancelled(): boolean {
    return this.cancelled;
  }

  readonly fetch: () => Promise<Array<TCalendarAppointment>>;
  private cancelled: boolean;
}
