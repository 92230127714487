import { inject, injectable } from 'inversify';
import { isControlKeyShortCut, isShiftControlKeyShortCut, isSingleKeyShortCut } from './IQuinoShortcutSettings';
import { IShortcutService } from './IShortcutService';
import { IRegisterShortcutService, IRegisterShortcutServiceSymbol } from './IRegisterShortcutService';
import { isCtrlOrCmdEvent, isShiftAndCtrlOrCmdEvent } from './ShortcutUtils';

export interface IRegisteredShortcutSettings {
  actionDisabled?: boolean;
  keyHandlerDisabled?: boolean;
}

@injectable()
export class ShortcutService implements IShortcutService {
  constructor(@inject(IRegisterShortcutServiceSymbol) private readonly registerShortcutService: IRegisterShortcutService) {}

  public startListening = () => {
    document.removeEventListener('keydown', this.handleShortcut);
    document.addEventListener('keydown', this.handleShortcut);
  };

  private readonly handleShortcut: EventListenerOrEventListenerObject = async (event: KeyboardEvent) => {
    const keyboardKey = event.key.toLowerCase();
    const registeredShortcuts = this.registerShortcutService.getRegisteredShortcuts();
    const matchingShortcuts = registeredShortcuts.filter((s) => {
      let shortcutIsPressedAndValid = false;
      const shortcutKeyIsPressed = s.shortcut.key.toLowerCase() === keyboardKey;

      if (!s.settings.keyHandlerDisabled) {
        if (isShiftControlKeyShortCut(s.shortcut)) {
          shortcutIsPressedAndValid = isShiftAndCtrlOrCmdEvent(event) && !event.altKey && shortcutKeyIsPressed;
        } else if (isControlKeyShortCut(s.shortcut)) {
          shortcutIsPressedAndValid = isCtrlOrCmdEvent(event) && !(event.altKey || event.shiftKey) && shortcutKeyIsPressed;
        } else if (isSingleKeyShortCut(s.shortcut)) {
          shortcutIsPressedAndValid = shortcutKeyIsPressed;
        }
      }

      shortcutIsPressedAndValid = shortcutIsPressedAndValid && !s.settings.actionDisabled;
      if (shortcutIsPressedAndValid) {
        // important for QuinoTextBox to write down the current value to bookmark
        // can be removed if https://dev.azure.com/encodo/Quino/_workitems/edit/8907 is fixed
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
        // prevent default and propagation
        event.preventDefault();
        event.stopPropagation();
      }

      return shortcutIsPressedAndValid;
    });

    if (matchingShortcuts.length > 0) {
      matchingShortcuts[matchingShortcuts.length - 1].action();
    }
  };
}
