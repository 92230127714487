import React from 'react';
import { Button } from 'devextreme-react/button';
import {
  IBookmarkFactory,
  INavigationService,
  INavigationServiceSymbol,
  IODataSourceFactory,
  IODataSourceFactorySymbol,
  QuinoUIServiceSymbols,
  useService
} from '@quino/ui';
import { ILayoutResolver, ILayoutResolverSymbol, ILogger, LayoutType, QuinoCoreServiceSymbols } from '@quino/core';

export function CUIUserInformation() {
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const dataSourceFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  return (
    <Button
      stylingMode={'text'}
      icon={'material-icons-outlined perm_identity'}
      visible={true}
      onClick={() => {
        dataSourceFactory
          .fetch('1', layoutResolver.resolveSingle({ metaClass: 'CurrentUser', type: LayoutType.Detail }), 'CurrentUser')
          .then((object) => {
            const objectBookmark = bookmarkFactory.createObject(object);
            navigationService.push(objectBookmark).catch(logger.logError);
          })
          .catch(logger.logError);
      }}
    />
  );
}
