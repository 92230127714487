import { IBookmark } from '../../bookmarks';
import { INavigationLinkAspect, TOpenInTarget } from '@quino/core';

export const INavigationLinkServiceSymbol = Symbol.for('INavigationLinkService');

/**
 * The navigation link service extracts navigation info from an INavigationAspect
 */
export interface INavigationLinkService {
  /**
   * Extracts the onClick action from a NavigationAspect
   * @param elementName - The name of the element to which the aspect was added
   * @param navigationAspect - The NavigationAspect
   * @param currentBookmark - The current bookmark (used for expression calculations)
   */
  getOnClickAction(elementName: string, navigationAspect: INavigationLinkAspect | null, currentBookmark?: IBookmark): (() => void) | undefined;

  /**
   * Opens specified target in targeted area.
   * @param target Target to open.
   * @param openInTarget area to target.
   */
  openInTarget(target: string | IBookmark, openInTarget: TOpenInTarget): void;
}
