import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for retrieving a format aspect.
 */
export const FormatAspectIdentifier = 'format';

/**
 * Format aspect
 */
export interface IFormatAspect extends IMetaAspect {
  formatString: string;
}
