import { IExpression } from './IExpression';

export interface IMetaPropertyExpression extends IExpression {
  ClassName: string;
  PropertyName: string;
}

export const isMetaPropertyExpression = (object: IExpression): object is IMetaPropertyExpression => {
  return (object as IMetaPropertyExpression).ClassName !== undefined && (object as IMetaPropertyExpression).PropertyName !== undefined;
};
