import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { IQuinoComponentProps, IQuinoEditorProps } from '../Types';
import { QuinoContextMenuButton } from '../ContextMenu';
import { QuinoTextBox } from '../QuinoTextBox';
import { useService } from '../../ioc';
import {
  getMetaProperty,
  INotificationService,
  INotificationServiceSymbol,
  isBase64ImageSource,
  ITranslationService,
  ITranslationServiceSymbol,
  ILoadingFeedbackSymbol,
  ILoadingFeedback
} from '@quino/core';
import { QuinoPopup, QuinoPopupDefaultContent, QuinoPopupToolbar, QuinoPopupToolbarButton } from '../QuinoPopup';
import { QuinoMultiLineRichTextEditor } from '../QuinoMultiLineRichTextEditor';
import { TResponsiveMode, useResponsiveMode } from '../../responsivity';
import { IObjectBookmark } from '../../bookmarks';
import { useMetaPropertyValueState } from '../Util';
import { QuinoInfoBar } from '../QuinoNotificationComponents';
import { IQuinoShortcutSettings, IQuinoShortcutSettingsSymbol, useShortcutHandler } from '../../shortcuts';
import noop from 'lodash/noop';

export enum QuinoMultiPurposeTextEditorPopupContent {
  None,
  Base64ImagePicker,
  RichTextEditorMarkdown,
  RichTextEditorHTML
}

export const QuinoPopupMultiPurposeTextEditorNotificationAreaId = 'popup-multi-purpose-text-editor-notification-area';

export const QuinoMultiPurposeTextEditor = (props: IQuinoEditorProps) => {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const responsiveMode = useResponsiveMode();
  const [value, setValue] = useMetaPropertyValueState<string>(props.metaProperty, props.bookmark);

  const [popupContent, setPopupContent] = useState<QuinoMultiPurposeTextEditorPopupContent>(QuinoMultiPurposeTextEditorPopupContent.None);
  const [intermediateStoredValue, setIntermediateStoredValue] = useState<string | undefined>();

  const hidePopup = () => {
    setIntermediateStoredValue(undefined);
    setPopupContent(QuinoMultiPurposeTextEditorPopupContent.None);
  };

  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);
  useShortcutHandler([shortcutSettings.cancel], hidePopup);

  const getPopupTitle = () => {
    switch (popupContent) {
      case QuinoMultiPurposeTextEditorPopupContent.Base64ImagePicker:
        return translationService.translate('QuinoMultiPurposeTextEditor.Base64ImagePicker.Title');
      case QuinoMultiPurposeTextEditorPopupContent.RichTextEditorMarkdown:
        return translationService.translate('QuinoMultiPurposeTextEditor.RichTextEditor.Markdown.Title');
      case QuinoMultiPurposeTextEditorPopupContent.RichTextEditorHTML:
        return translationService.translate('QuinoMultiPurposeTextEditor.RichTextEditor.HTML.Title');
      case QuinoMultiPurposeTextEditorPopupContent.None:
      default:
        return '';
    }
  };

  const renderPopupContent = () => {
    switch (popupContent) {
      case QuinoMultiPurposeTextEditorPopupContent.Base64ImagePicker:
        return <QuinoBase64ImageUploader bookmark={props.bookmark} element={props.metaProperty} />;
      case QuinoMultiPurposeTextEditorPopupContent.RichTextEditorMarkdown:
        return <QuinoMultiLineRichTextEditor bookmark={props.bookmark} element={props.metaProperty} valueType={'markdown'} />;
      case QuinoMultiPurposeTextEditorPopupContent.RichTextEditorHTML:
        return <QuinoMultiLineRichTextEditor bookmark={props.bookmark} element={props.metaProperty} valueType={'html'} />;
      case QuinoMultiPurposeTextEditorPopupContent.None:
      default:
        return <></>;
    }
  };

  return (
    <div className={'quino-multi-purpose-text-editor-container'}>
      <QuinoTextBox key={intermediateStoredValue} {...props}>
        <QuinoContextMenuButton
          buttonId={'quino-multi-purpose-text-editor-container-context-button'}
          buttonIcon={'material-icons-outlined more_vert'}
          buttonStylingMode={'text'}
          dataSource={[
            {
              text: translationService.translate('QuinoMultiPurposeTextEditor.Base64ImagePicker.Title'),
              hint: translationService.translate('QuinoMultiPurposeTextEditor.Base64ImagePicker.Hint'),
              icon: 'material-icons-outlined add_photo_alternate',
              onItemClick: () => setPopupContent(QuinoMultiPurposeTextEditorPopupContent.Base64ImagePicker)
            },
            {
              text: translationService.translate('QuinoMultiPurposeTextEditor.RichTextEditor.Markdown.Title'),
              hint: translationService.translate('QuinoMultiPurposeTextEditor.RichTextEditor.Markdown.Hint'),
              icon: 'material-icons-outlined text_fields',
              onItemClick: () => setPopupContent(QuinoMultiPurposeTextEditorPopupContent.RichTextEditorMarkdown)
            },
            {
              text: translationService.translate('QuinoMultiPurposeTextEditor.RichTextEditor.HTML.Title'),
              hint: translationService.translate('QuinoMultiPurposeTextEditor.RichTextEditor.HTML.Hint'),
              icon: 'material-icons-outlined code',
              onItemClick: () => setPopupContent(QuinoMultiPurposeTextEditorPopupContent.RichTextEditorHTML)
            }
          ]}
        />
      </QuinoTextBox>
      <QuinoPopup
        visible={popupContent != QuinoMultiPurposeTextEditorPopupContent.None}
        title={getPopupTitle()}
        bookmark={props.bookmark}
        height={responsiveMode === TResponsiveMode.Desktop ? '640px' : '90vh'}
        width={responsiveMode === TResponsiveMode.Desktop ? '800px' : '90vw'}
        onShown={() => setIntermediateStoredValue(value)}
        onHiding={hidePopup}>
        <div className={'quino-popup-notification-wrapper'}>
          <QuinoInfoBar isDefault={false} customAreaID={QuinoPopupMultiPurposeTextEditorNotificationAreaId} />
        </div>
        <QuinoPopupDefaultContent hasPaddingHorizontal={true}>{renderPopupContent()}</QuinoPopupDefaultContent>
        <QuinoPopupToolbar
          rightItems={[
            <QuinoPopupToolbarButton
              text={translationService.translate('Cancel')}
              icon={'material-icons-outlined clear'}
              onClick={() => {
                setValue(intermediateStoredValue);
                hidePopup();
              }}
            />,
            <QuinoPopupToolbarButton
              isPrimary={true}
              text={translationService.translate('Done')}
              icon={'material-icons-outlined save'}
              onClick={() => {
                if (popupContent == QuinoMultiPurposeTextEditorPopupContent.RichTextEditorHTML && !value?.startsWith('<html')) {
                  setValue(`<html>${value ?? ''}</html>`);
                }
                hidePopup();
              }}
            />
          ]}
        />
      </QuinoPopup>
    </div>
  );
};

const QuinoBase64ImageUploader = (props: PropsWithChildren<IQuinoComponentProps<IObjectBookmark>>) => {
  const fileInputId = 'quino-file-input';
  const fileViewerId = 'quino-file-viewer';

  const notificationService = useService<INotificationService>(INotificationServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);

  const metaProperty = getMetaProperty(props.element);

  const unload = useRef<() => void>(noop);

  const [value, setValue] = useMetaPropertyValueState<string>(metaProperty, props.bookmark);

  useEffect(() => {
    const symbol = props.bookmark.subscribe((event) => event.type === 'changed' && unload.current());
    return () => props.bookmark.unsubscribe(symbol);
  }, [props.bookmark]);

  const onChange = () => {
    const inputElement = document.getElementById(fileInputId) as HTMLInputElement;
    const files = inputElement.files;
    if (files && files.length > 0) {
      let imageFile = files[0];
      if (imageFile.type.startsWith('image/')) {
        unload.current = loadingFeedback.load();
        let fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
          if (fileLoadedEvent.target) {
            let srcData = fileLoadedEvent.target.result;
            srcData && setValue(srcData.toString());
          }
        };
        fileReader.readAsDataURL(imageFile);
      } else {
        notificationService.notify({
          message: translationService.translate('QuinoMultiPurposeTextEditor.Base64ImagePicker.UploadError'),
          area: 'custom',
          type: 'error',
          customAreaID: QuinoPopupMultiPurposeTextEditorNotificationAreaId,
          autoDisappear: true
        });
        inputElement.value = '';
      }
    }
  };

  return (
    <div className={'quino-base64-image-uploader-container'}>
      <div id={fileViewerId} className={isBase64ImageSource(value) ? '' : 'no-image'}>
        {(isBase64ImageSource(value) && <img src={value} alt={'N/A'} />) || <span className={'material-icons-outlined photo'} />}
      </div>
      <input id={fileInputId} accept='image/*' type='file' onChange={onChange} />
    </div>
  );
};
