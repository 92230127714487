import { isIBookmark } from './IBookmark';

export interface IBookmarkKeyValueStore {
  [key: string]: any;
}
/**
 * Bookmark that has a state
 */
export interface IStateFullBookmark {
  /**
   * Sets a state value
   */
  setStateValue: (key: string, value: any, doNotNotify?: boolean) => void;

  /**
   * Gets a state value
   */
  getStateValue: (key: string) => any;

  /**
   * Clears a key from the state
   * @param key - the key to clear
   */
  clearStateValue: (key: string, doNotNotify?: boolean) => void;

  /**
   * Gets the state
   */
  getState: () => IBookmarkKeyValueStore;

  /**
   * Subscribe to state changes
   */
  subscribeToStateChange: (callback: (scopeKey: string) => void) => Symbol;

  /**
   * Unsubscribe from state changes
   */
  unsubscribeFromStateChange: (symbol: Symbol) => void;
}

/**
 * Whether the given object is a IStateFullBookmark.
 */
export const isStateFullBookmark = (object: any): object is IStateFullBookmark => {
  return (
    isIBookmark(object) &&
    (object as IStateFullBookmark).getStateValue !== undefined &&
    (object as IStateFullBookmark).setStateValue !== undefined &&
    (object as IStateFullBookmark).getState !== undefined
  );
};
