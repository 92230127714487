import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { IQuinoComponentProps } from '../Types';
import { IObjectBookmark } from '../../bookmarks';
import { HtmlEditor, Item, MediaResizing, TableContextMenu, Toolbar } from 'devextreme-react/html-editor';
import { getMetaProperty, IMetaProperty, getAspectOrDefault, ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { useLabelSettings, useMetadata } from '../../settings';
import { useMetaPropertyValueState, useValidation } from '../Util';
import { useSize } from '../Util/MetaElementHooks';
import { IRichTextEditorConfigurationAspect, RichTextEditorConfigurationAspectIdentifier } from '../../aspects';
import { useService } from '../../ioc';
import { HTLMEditorValueType } from '../../aspects/IRichTextEditorConfigurationAspect';

const fontSizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontFamilyValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
const headingValues = [1, 2, 3, 4, false];
const defaultMinHeight = '160px';
const defaultMaxHeight = '400px';

export interface IQuinoMultiLineRichTextEditorProps extends IQuinoComponentProps<IObjectBookmark> {
  valueType?: HTLMEditorValueType;
}

export function QuinoMultiLineRichTextEditor(props: PropsWithChildren<IQuinoMultiLineRichTextEditorProps>) {
  const { element, bookmark, valueType } = props;
  const metaProperty = getMetaProperty(element);
  const { description } = metaProperty;

  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const labelSettings = useLabelSettings(element);
  const { readOnly, enabled } = useMetadata(metaProperty, bookmark.genericObject);
  const [value, setValue] = useMetaPropertyValueState<string>(props.element as IMetaProperty, props.bookmark);
  const [toolbarVisible, setToolbarVisible] = useState<boolean>(false);

  const [isValid] = useValidation(bookmark, metaProperty);

  const { height } = useSize(element);
  const options = useMemo(
    () => getAspectOrDefault<IRichTextEditorConfigurationAspect>(element, RichTextEditorConfigurationAspectIdentifier)?.configuration,
    [element]
  );

  const checkHideToolbar = useCallback((editor: HTMLElement) => {
    const active = document.activeElement;
    if (active === editor) {
      return;
    } else if (editor.contains(active) || active?.classList.contains('dx-menu-item') || active?.classList.contains('dx-context-menu')) {
      active?.addEventListener('blur', () => checkHideToolbar(editor), {
        once: true
      });
    } else {
      setToolbarVisible(false);
    }
  }, []);

  const toolbar = useMemo(() => {
    const groupEnabled = (values: any[]) => values.filter((v) => v !== false).length > 0;

    const typoGroup = groupEnabled([options?.enableSize, options?.enableFont, options?.enableHeader]);
    const decorationGroup = groupEnabled([
      options?.enableBold,
      options?.enableItalic,
      options?.enableStrike,
      options?.enableUnderline,
      options?.enableSubscript,
      options?.enableSuperscript
    ]);
    const colorGroup = groupEnabled([options?.enableColor, options?.enableBackground]);
    const alignGroup = groupEnabled([options?.enableAlignLeft, options?.enableAlignCenter, options?.enableAlignRight, options?.enableAlignJustify]);
    const listGroup = groupEnabled([options?.enableBulletList, options?.enableOrderedList]);
    const insertGroup = groupEnabled([options?.enableLink, options?.enableImage]);
    const blockGroup = groupEnabled([options?.enableCodeBlock, options?.enableBlockquote]);
    const tableGroup = groupEnabled([
      options?.enableInsertTable,
      options?.enableDeleteTable,
      options?.enableInsertColumnLeft,
      options?.enableInsertColumnRight,
      options?.enableDeleteColumn,
      options?.enableInsertRowAbove,
      options?.enableInsertRowBelow,
      options?.enableDeleteRow
    ]);

    return (
      <Toolbar multiline={true}>
        <Item name='size' acceptedValues={fontSizeValues} visible={options?.enableSize} />
        <Item name='font' acceptedValues={fontFamilyValues} visible={options?.enableFont} />
        <Item name='header' acceptedValues={headingValues} visible={options?.enableHeader} />
        <Item name='separator' visible={typoGroup && decorationGroup} />
        <Item name='bold' visible={options?.enableBold} />
        <Item name='italic' visible={options?.enableItalic} />
        <Item name='strike' visible={options?.enableStrike} />
        <Item name='underline' visible={options?.enableUnderline} />
        <Item name='subscript' visible={options?.enableSubscript} />
        <Item name='superscript' visible={options?.enableSuperscript} />
        <Item name='separator' visible={(typoGroup || decorationGroup) && colorGroup} />
        <Item name='color' visible={options?.enableColor} />
        <Item name='background' visible={options?.enableBackground} />
        <Item name='separator' visible={(typoGroup || decorationGroup || colorGroup) && alignGroup} />
        <Item name='alignLeft' visible={options?.enableAlignLeft} />
        <Item name='alignCenter' visible={options?.enableAlignCenter} />
        <Item name='alignRight' visible={options?.enableAlignRight} />
        <Item name='alignJustify' visible={options?.enableAlignJustify} />
        <Item name='separator' visible={(typoGroup || decorationGroup || colorGroup || alignGroup) && listGroup} />
        <Item name='orderedList' visible={options?.enableOrderedList} />
        <Item name='bulletList' visible={options?.enableBulletList} />
        <Item name='separator' visible={(typoGroup || decorationGroup || colorGroup || alignGroup || listGroup) && insertGroup} />
        <Item name='link' visible={options?.enableLink} />
        <Item name='image' visible={options?.enableImage} />
        <Item name='separator' visible={(typoGroup || decorationGroup || colorGroup || alignGroup || listGroup || insertGroup) && blockGroup} />
        <Item name='codeBlock' visible={options?.enableCodeBlock} />
        <Item name='blockquote' visible={options?.enableBlockquote} />
        <Item
          name='separator'
          visible={(typoGroup || decorationGroup || colorGroup || alignGroup || listGroup || insertGroup || blockGroup) && tableGroup}
        />
        <Item name='insertTable' visible={options?.enableInsertTable} />
        <Item name='deleteTable' visible={options?.enableDeleteTable} />
        <Item name='insertRowAbove' visible={options?.enableInsertRowAbove} />
        <Item name='insertRowBelow' visible={options?.enableInsertRowBelow} />
        <Item name='deleteRow' visible={options?.enableDeleteRow} />
        <Item name='insertColumnLeft' visible={options?.enableInsertColumnLeft} />
        <Item name='insertColumnRight' visible={options?.enableInsertColumnRight} />
        <Item name='deleteColumn' visible={options?.enableDeleteColumn} />
        <Item name='separator' />
        <Item name='clear' visible={options?.enableClear} />
        <Item
          widget='dxButton'
          location='after'
          locateInMenu={true}
          options={{
            icon: 'material-icons-outlined backspace',
            stylingMode: 'text',
            onClick: () => setValue(undefined),
            hint: translationService.translate('ClearContents')
          }}
        />
      </Toolbar>
    );
  }, [options, setValue, translationService]);

  return (
    <div className={'quino-multiline-rich-text-editor' + (toolbarVisible ? '' : ' hide-toolbar')}>
      <HtmlEditor
        valueType={valueType || options?.valueType || 'markdown'}
        readOnly={readOnly}
        focusStateEnabled={!readOnly}
        disabled={!enabled}
        isValid={isValid}
        hint={labelSettings.hintType === 'None' ? description : undefined}
        value={value}
        onValueChanged={(e) => setValue(e.value)}
        height={'auto'}
        elementAttr={{ style: `min-height: ${defaultMinHeight}; max-height: ${height || defaultMaxHeight}` }} // TODO: Once maxHeight is integrated in Sizing aspect, adjust max height setting
        onFocusIn={() => setToolbarVisible(true)}
        onFocusOut={(e) => setTimeout(() => checkHideToolbar(e.element), 0)}>
        {toolbar}
        <TableContextMenu enabled={true} />
        <MediaResizing enabled={true} />
      </HtmlEditor>
    </div>
  );
}
