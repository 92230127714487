export const CuiQueryCustomValueIdentifier = 'cuicustomvalues';

export const getBaseUrl = (appIdentifier = 'cui'): string => {
  const currentUrl = document.location.pathname.split('/').filter((x) => x !== '');
  const current = currentUrl.indexOf(appIdentifier);
  let prefix: string;
  if (current > -1) {
    prefix = currentUrl.slice(0, current).join('/');
  } else {
    prefix = currentUrl.join('/');
  }

  return prefix;
};

export const removeSlashes = (url: string): string => {
  if (url[url.length - 1] === '/') {
    url = url.substring(0, url.length - 2);
  }

  if (url[0] === '/') {
    url = url.substring(1, url.length - 1);
  }

  return url;
};

export const getPathParts = (path: string): string[] => {
  const pathname = path.split('?')[0];
  return pathname
    .toLowerCase()
    .split('/')
    .filter((x) => x !== '');
};

export const pathContainsIdentifierSequence = (path: string, appIdentifier: string, bookmarkIdentifier: string): boolean => {
  const parts = getPathParts(path);
  const appIndex = parts.indexOf(appIdentifier);

  if (appIndex <= -1) {
    return false;
  }

  return parts[appIndex + 1] === bookmarkIdentifier;
};
