import { IMaskFormatService } from './IMaskFormatService';
import { injectable } from 'inversify';
import { getAspectOrDefault, IMaskFormatAspect, IMetaElement, MaskFormatAspectIdentifier } from '../../meta';

@injectable()
export class MaskFormatService implements IMaskFormatService {
  get = (element: IMetaElement): string | undefined => {
    return getAspectOrDefault<IMaskFormatAspect>(element, MaskFormatAspectIdentifier)?.maskString;
  };
}
