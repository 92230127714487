import { injectable } from 'inversify';
import { IBookmarkTrackChangesService } from './IBookmarkTrackChangesService';

@injectable()
export class BookmarkTrackChangesService implements IBookmarkTrackChangesService {
  pendingChanges = async (): Promise<boolean> => {
    let anyChanges = false;

    for (const bookmarkHasChanges of this.bookmarkOwners.values()) {
      const hasChanges = await bookmarkHasChanges();
      if (hasChanges) {
        anyChanges = true;
      }
    }
    return anyChanges;
  };

  subscribe = (bookmarkHasChanges: () => Promise<boolean>): Symbol => {
    const symbol = Symbol();
    this.bookmarkOwners.set(symbol, bookmarkHasChanges);

    return symbol;
  };

  unsubscribe = (symbol: Symbol): void => {
    this.bookmarkOwners.delete(symbol);
  };

  private readonly bookmarkOwners = new Map<Symbol, () => Promise<boolean>>();
}
