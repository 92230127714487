import { IProvider } from '@quino/core';
import { INavigationLinkResolver } from './INavigationLinkResolver';

/**
 * The symbol for the INavigationLinkResolverProvider.
 */
export const INavigationLinkResolverProviderSymbol = Symbol.for('INavigationLinkResolverProvider');

export interface INavigationLinkResolverProvider extends IProvider<INavigationLinkResolver> {
  /**
   * Find a NavigationLinkResolver that can resolve the given target type
   * @param targetName - the target type name.
   */
  find(targetName: string): INavigationLinkResolver | undefined;
}
