import { IMetaElementFactory } from './IMetaElementFactory';
import { IMetaElement, IMetaProperty, isIMetaClass, isIMetaGroup, isIMetaLayout, isIMetaProperty, isMetaRelation } from '../../meta';
import { injectable } from 'inversify';

@injectable()
export class MetaElementFactory implements IMetaElementFactory {
  create<T extends IMetaElement>(element: Partial<T>): T {
    let newElement = MetaElementFactory.createMetaElement(element);
    if (isIMetaProperty(newElement)) {
      newElement = MetaElementFactory.createMetaProperty(newElement) as T;
    }

    if (isIMetaGroup(newElement)) {
      newElement = MetaElementFactory.createMetaGroup(newElement) as T;
    }

    if ((isMetaRelation(newElement) || isIMetaClass(newElement) || isIMetaLayout(newElement)) && newElement.sorts == null) {
      newElement.sorts = [];
    }

    return newElement as T;
  }

  private static createMetaGroup(element: Partial<IMetaElement>): IMetaElement {
    return {
      ...element,
      enabled: element.enabled === undefined ? true : element.enabled,
      visible: element.visible === undefined ? true : element.visible,
      required: element.required === undefined ? false : element.required,
      readOnly: element.readOnly === undefined ? false : element.readOnly
    } as IMetaElement;
  }

  private static createMetaElement(element: Partial<IMetaElement>): IMetaElement {
    return {
      ...element,
      enabled: element.enabled === undefined ? true : element.enabled,
      visible: element.visible === undefined ? true : element.visible
    } as IMetaElement;
  }

  private static createMetaProperty(property: Partial<IMetaProperty>): IMetaElement {
    return {
      ...property,
      readOnly: property.readOnly === undefined ? false : property.readOnly,
      required: property.required === undefined ? true : property.required
    } as IMetaProperty;
  }
}
