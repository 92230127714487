(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@quino/core"), require("devextreme-react"), require("devextreme-react/box"), require("devextreme-react/button"), require("devextreme-react/chart"), require("devextreme-react/check-box"), require("devextreme-react/color-box"), require("devextreme-react/context-menu"), require("devextreme-react/data-grid"), require("devextreme-react/date-box"), require("devextreme-react/drop-down-box"), require("devextreme-react/drop-down-button"), require("devextreme-react/file-uploader"), require("devextreme-react/html-editor"), require("devextreme-react/load-indicator"), require("devextreme-react/load-panel"), require("devextreme-react/menu"), require("devextreme-react/number-box"), require("devextreme-react/popover"), require("devextreme-react/popup"), require("devextreme-react/progress-bar"), require("devextreme-react/radio-group"), require("devextreme-react/responsive-box"), require("devextreme-react/scroll-view"), require("devextreme-react/select-box"), require("devextreme-react/tab-panel"), require("devextreme-react/text-area"), require("devextreme-react/text-box"), require("devextreme-react/toolbar"), require("devextreme-react/tooltip"), require("devextreme-react/validation-summary"), require("devextreme/core/guid"), require("devextreme/data/array_store"), require("devextreme/data/data_source"), require("devextreme/data/odata/store"), require("devextreme/excel_exporter"), require("devextreme/localization"), require("devextreme/pdf_exporter"), require("devextreme/ui/data_grid"), require("devextreme/ui/html_editor/converters/markdown"), require("devextreme/ui/notify"), require("inversify"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["@quino/core", "devextreme-react", "devextreme-react/box", "devextreme-react/button", "devextreme-react/chart", "devextreme-react/check-box", "devextreme-react/color-box", "devextreme-react/context-menu", "devextreme-react/data-grid", "devextreme-react/date-box", "devextreme-react/drop-down-box", "devextreme-react/drop-down-button", "devextreme-react/file-uploader", "devextreme-react/html-editor", "devextreme-react/load-indicator", "devextreme-react/load-panel", "devextreme-react/menu", "devextreme-react/number-box", "devextreme-react/popover", "devextreme-react/popup", "devextreme-react/progress-bar", "devextreme-react/radio-group", "devextreme-react/responsive-box", "devextreme-react/scroll-view", "devextreme-react/select-box", "devextreme-react/tab-panel", "devextreme-react/text-area", "devextreme-react/text-box", "devextreme-react/toolbar", "devextreme-react/tooltip", "devextreme-react/validation-summary", "devextreme/core/guid", "devextreme/data/array_store", "devextreme/data/data_source", "devextreme/data/odata/store", "devextreme/excel_exporter", "devextreme/localization", "devextreme/pdf_exporter", "devextreme/ui/data_grid", "devextreme/ui/html_editor/converters/markdown", "devextreme/ui/notify", "inversify", "react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["@quino/ui"] = factory(require("@quino/core"), require("devextreme-react"), require("devextreme-react/box"), require("devextreme-react/button"), require("devextreme-react/chart"), require("devextreme-react/check-box"), require("devextreme-react/color-box"), require("devextreme-react/context-menu"), require("devextreme-react/data-grid"), require("devextreme-react/date-box"), require("devextreme-react/drop-down-box"), require("devextreme-react/drop-down-button"), require("devextreme-react/file-uploader"), require("devextreme-react/html-editor"), require("devextreme-react/load-indicator"), require("devextreme-react/load-panel"), require("devextreme-react/menu"), require("devextreme-react/number-box"), require("devextreme-react/popover"), require("devextreme-react/popup"), require("devextreme-react/progress-bar"), require("devextreme-react/radio-group"), require("devextreme-react/responsive-box"), require("devextreme-react/scroll-view"), require("devextreme-react/select-box"), require("devextreme-react/tab-panel"), require("devextreme-react/text-area"), require("devextreme-react/text-box"), require("devextreme-react/toolbar"), require("devextreme-react/tooltip"), require("devextreme-react/validation-summary"), require("devextreme/core/guid"), require("devextreme/data/array_store"), require("devextreme/data/data_source"), require("devextreme/data/odata/store"), require("devextreme/excel_exporter"), require("devextreme/localization"), require("devextreme/pdf_exporter"), require("devextreme/ui/data_grid"), require("devextreme/ui/html_editor/converters/markdown"), require("devextreme/ui/notify"), require("inversify"), require("react"), require("react-dom"));
	else
		root["@quino/ui"] = factory(root["@quino/core"], root["devextreme-react"], root["devextreme-react/box"], root["devextreme-react/button"], root["devextreme-react/chart"], root["devextreme-react/check-box"], root["devextreme-react/color-box"], root["devextreme-react/context-menu"], root["devextreme-react/data-grid"], root["devextreme-react/date-box"], root["devextreme-react/drop-down-box"], root["devextreme-react/drop-down-button"], root["devextreme-react/file-uploader"], root["devextreme-react/html-editor"], root["devextreme-react/load-indicator"], root["devextreme-react/load-panel"], root["devextreme-react/menu"], root["devextreme-react/number-box"], root["devextreme-react/popover"], root["devextreme-react/popup"], root["devextreme-react/progress-bar"], root["devextreme-react/radio-group"], root["devextreme-react/responsive-box"], root["devextreme-react/scroll-view"], root["devextreme-react/select-box"], root["devextreme-react/tab-panel"], root["devextreme-react/text-area"], root["devextreme-react/text-box"], root["devextreme-react/toolbar"], root["devextreme-react/tooltip"], root["devextreme-react/validation-summary"], root["devextreme/core/guid"], root["devextreme/data/array_store"], root["devextreme/data/data_source"], root["devextreme/data/odata/store"], root["devextreme/excel_exporter"], root["devextreme/localization"], root["devextreme/pdf_exporter"], root["devextreme/ui/data_grid"], root["devextreme/ui/html_editor/converters/markdown"], root["devextreme/ui/notify"], root["inversify"], root["react"], root["react-dom"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__19651__, __WEBPACK_EXTERNAL_MODULE__79416__, __WEBPACK_EXTERNAL_MODULE__79530__, __WEBPACK_EXTERNAL_MODULE__85328__, __WEBPACK_EXTERNAL_MODULE__95134__, __WEBPACK_EXTERNAL_MODULE__4249__, __WEBPACK_EXTERNAL_MODULE__83176__, __WEBPACK_EXTERNAL_MODULE__91396__, __WEBPACK_EXTERNAL_MODULE__29949__, __WEBPACK_EXTERNAL_MODULE__58807__, __WEBPACK_EXTERNAL_MODULE__77634__, __WEBPACK_EXTERNAL_MODULE__22980__, __WEBPACK_EXTERNAL_MODULE__60888__, __WEBPACK_EXTERNAL_MODULE__91738__, __WEBPACK_EXTERNAL_MODULE__24026__, __WEBPACK_EXTERNAL_MODULE__87128__, __WEBPACK_EXTERNAL_MODULE__68837__, __WEBPACK_EXTERNAL_MODULE__22641__, __WEBPACK_EXTERNAL_MODULE__76544__, __WEBPACK_EXTERNAL_MODULE__38192__, __WEBPACK_EXTERNAL_MODULE__61461__, __WEBPACK_EXTERNAL_MODULE__74804__, __WEBPACK_EXTERNAL_MODULE__83125__, __WEBPACK_EXTERNAL_MODULE__25481__, __WEBPACK_EXTERNAL_MODULE__46565__, __WEBPACK_EXTERNAL_MODULE__67290__, __WEBPACK_EXTERNAL_MODULE__62050__, __WEBPACK_EXTERNAL_MODULE__42619__, __WEBPACK_EXTERNAL_MODULE__91676__, __WEBPACK_EXTERNAL_MODULE__2049__, __WEBPACK_EXTERNAL_MODULE__71018__, __WEBPACK_EXTERNAL_MODULE__40759__, __WEBPACK_EXTERNAL_MODULE__36375__, __WEBPACK_EXTERNAL_MODULE__60367__, __WEBPACK_EXTERNAL_MODULE__37413__, __WEBPACK_EXTERNAL_MODULE__29631__, __WEBPACK_EXTERNAL_MODULE__97264__, __WEBPACK_EXTERNAL_MODULE__69979__, __WEBPACK_EXTERNAL_MODULE__21293__, __WEBPACK_EXTERNAL_MODULE__6087__, __WEBPACK_EXTERNAL_MODULE__36140__, __WEBPACK_EXTERNAL_MODULE__22710__, __WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__47111__) => {
return 