import { BoundedRuleAspectIdentifier, DataType, getAspectOrDefault, IBoundedRuleAspect, IMetaProperty } from '../../meta';
import { IFieldValidationResult } from '../IFieldValidationResult';
import { IFieldError } from '../IFieldError';
import { FieldValidationErrorCodes } from '../FieldValidationErrorCodes';
import { inject, injectable } from 'inversify';
import { IValidationMessageProvider } from '../IValidationMessageProvider';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IFieldValidator } from '../IFieldValidator';
import { IValidationContext } from '../IValidationContext';

@injectable()
export class BoundsRuleValidator implements IFieldValidator {
  constructor(@inject(QuinoCoreServiceSymbols.IValidationMessageProvider) private readonly msgProvider: IValidationMessageProvider) {}

  validate(field: IMetaProperty, value: any, _context: IValidationContext): IFieldValidationResult {
    const boundAspect = getAspectOrDefault<IBoundedRuleAspect>(field, BoundedRuleAspectIdentifier);
    if (boundAspect == null || BoundsRuleValidator.isUnsupportedDataType(field)) {
      return {};
    }

    const fieldErrors: IFieldError[] = [];

    if (boundAspect.minValue !== undefined && value < boundAspect.minValue) {
      fieldErrors.push({
        fieldName: field.name,
        errorCode: FieldValidationErrorCodes.BOUNDS_MIN_VALUE,
        errorMessage: this.msgProvider.getBoundsMinError(boundAspect.minValue)
      });
    }

    if (boundAspect.maxValue !== undefined && value > boundAspect.maxValue) {
      fieldErrors.push({
        fieldName: field.name,
        errorCode: FieldValidationErrorCodes.BOUNDS_MAX_VALUE,
        errorMessage: this.msgProvider.getBoundsMaxError(boundAspect.maxValue)
      });
    }

    return fieldErrors.length > 0
      ? {
          fieldErrors: fieldErrors
        }
      : {};
  }

  private static isUnsupportedDataType(field: IMetaProperty) {
    return (
      field.dataType !== DataType.Currency &&
      field.dataType !== DataType.Double &&
      field.dataType !== DataType.Integer &&
      field.dataType !== DataType.LargeInteger &&
      field.dataType !== DataType.SmallInteger &&
      field.dataType !== DataType.TinyInteger
    );
  }
}
