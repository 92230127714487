import { IObjectBookmark } from '../../bookmarks';
import { IFieldError, IMetaElement, IVisibleCalculator, QuinoCoreServiceSymbols } from '@quino/core';
import { useService } from '../../ioc';

export const useValidation = (bookmark: IObjectBookmark, element: IMetaElement): [boolean, string[]] => {
  const visibleCalculator = useService<IVisibleCalculator>(QuinoCoreServiceSymbols.IVisibleCalculator);
  const elementName = element.name;

  if (!visibleCalculator.calculate(element, bookmark.genericObject)) {
    bookmark.fieldErrors.delete(elementName);
  }

  const allErrors: IFieldError[] = [];

  bookmark.fieldErrors.forEach((errors, name) => name.toLowerCase() === elementName.toLowerCase() && allErrors.push(...errors));
  bookmark.fieldErrorsFromServer.forEach((errors, name) => name.toLowerCase() === elementName.toLowerCase() && allErrors.push(...errors));

  return [allErrors.length === 0, allErrors.map((fieldError) => fieldError.errorMessage)];
};
