import { IErrorMapper } from './IErrorMapper';
import { INotification } from '../../notification';
import { IErrorHandlingService } from '../IErrorHandlingService';
import { ErrorGroup } from '../errors/ErrorGroup';
import { ITranslationService } from '../../lang';
import { DefaultErrorMapper } from './DefaultErrorMapper';

export class ErrorGroupMapper extends DefaultErrorMapper implements IErrorMapper {
  constructor(translationService: ITranslationService, private readonly errorHandlingService: IErrorHandlingService) {
    super(translationService);
  }

  get errorName() {
    return 'Error.ErrorGroup';
  }

  formatError(error: Error): INotification {
    const errorGroup = error as ErrorGroup;

    if (errorGroup.errors.length === 1) return this.errorHandlingService.getErrorNotification(errorGroup.errors[0]);

    return super.formatError(error);
  }

  getErrorName(error: Error): string {
    const errorGroup = error as ErrorGroup;

    function onlyUnique<T>(value: T, index: number, self: T[]) {
      return self.indexOf(value) === index;
    }

    return this.translationService.translate('Error.ErrorGroup', {
      errorCount: errorGroup.errors.length,
      errorTypes: errorGroup.errors
        .map((e) => e.name)
        .filter(onlyUnique)
        .map((e) => this.translationService.translate(e))
        .join(', ')
    });
  }

  getErrorMessage(error: Error): string {
    const errorGroup = error as ErrorGroup;
    return errorGroup.errors
      .map((e: Error, k: number) => `[${k + 1}] ${this.errorHandlingService.getErrorName(e)}: ${this.errorHandlingService.getErrorMessage(e)}`)
      .join('\n\n');
  }
}
