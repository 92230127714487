import React, { ReactNode } from 'react';
import { TabPanel } from 'devextreme-react/tab-panel';
import { ScrollView } from 'devextreme-react/scroll-view';

export interface IQuinoTabCustom {
  id: number;
  title: string;
  node: () => ReactNode;
}

export interface IQuinoTabsContainerCustomProps {
  tabs: IQuinoTabCustom[];
}

export const QuinoTabsContainerCustom = (props: IQuinoTabsContainerCustomProps) => {
  return (
    <TabPanel
      deferRendering={false}
      className={`quino-tabs-container`}
      showNavButtons={true}
      items={props.tabs}
      itemKeyFn={(data) => data.id}
      itemRender={(tabItem: IQuinoTabCustom) => (
        <ScrollView
          useNative={false}
          onInitialized={(e) => {
            if (e.component) {
              e.component.option('useKeyboard', false);
            }
          }}>
          <div className={'quino-tabs-container-scrollcontent'}>{tabItem.node()}</div>
        </ScrollView>
      )}
    />
  );
};
