import { IFormatStringService } from './IFormatStringService';
import { injectable } from 'inversify';
import { DataType, FormatAspectIdentifier, getAspectOrDefault, IFormatAspect, IMetaElement, isIMetaProperty } from '../../meta';

@injectable()
export class FormatStringService implements IFormatStringService {
  get(element: IMetaElement): string | undefined {
    const aspect = getAspectOrDefault<IFormatAspect>(element, FormatAspectIdentifier);
    if (aspect) {
      if (aspect.formatString === 'GeneralDateTime') {
        return 'dd.MM.yyyy, HH:mm';
      }
      if (aspect.formatString === 'ShortDate') {
        return 'dd.MM.yyyy';
      }

      return aspect.formatString;
    }

    if (isIMetaProperty(element)) {
      switch (element.dataType) {
        case DataType.Currency:
          return '#,##0.00';
        case DataType.Double:
          return '#,##0.##';
        case DataType.TinyInteger:
        case DataType.SmallInteger:
        case DataType.Integer:
        case DataType.LargeInteger:
          return '#0';
        case DataType.Date:
          return 'dd.MM.yyyy';
        case DataType.DateTime:
          return 'dd.MM.yyyy HH:mm';
        case DataType.Time:
          return 'HH:mm';
      }
    }

    return undefined;
  }
}
