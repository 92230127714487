import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Aggregation,
  ArgumentAxis,
  Chart,
  CommonSeriesSettings,
  Label,
  Legend,
  Series,
  Tooltip,
  ValueAxis,
  ZoomAndPan
} from 'devextreme-react/chart';
import { Button } from 'devextreme-react/button';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox } from 'devextreme-react/text-box';
import {
  DataType,
  getAspectOrDefault,
  ILogger,
  IMetaClass,
  IMetadataTree,
  IMetaProperty,
  IRequestDecoratorProvider,
  isMetaRelation,
  isValueList,
  ITranslationService,
  ITranslationServiceSymbol,
  IValueFormatter,
  IValueFormatterSymbol,
  IValueListAspect,
  LayoutType,
  ODataUrlBuilder,
  ODataUrlBuilderSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { QuinoUIServiceSymbols, useService } from '../../ioc';
import {
  QuinoCustomFormBox,
  QuinoCustomFormRow,
  QuinoLabeled,
  QuinoLayoutSelector,
  QuinoMetaClassSelector,
  QuinoMetaPropertySelector,
  QuinoPopupDefaultContent,
  QuinoPopupToolbar,
  QuinoPopupToolbarButton,
  QuinoTabsContainerCustom
} from '../../components';
import { ColorBox } from 'devextreme-react/color-box';
import dxChart from 'devextreme/viz/chart';
import { IDashboardTileDataSource, IDashboardTileRegistration } from '../registry';
import { IQuinoTabCustom } from '../../components/QuinoTabsContainer/QuinoTabsContainerCustom';
import { CheckBox } from 'devextreme-react/check-box';
import { IBookmarkTitleCalculator, IBookmarkTitleCalculatorSymbol, INavigationService, INavigationServiceSymbol } from '../../navigation';
import { Guid } from 'guid-typescript';
import { inject, injectable } from 'inversify';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { IBookmarkFactory } from '../../bookmarks';

export interface IQuinoDashboardChartTileProps extends IChartBasics {
  class: IMetaClass | undefined;
  series: IQuinoDashboardChartSeriesProps[];
  argumentType: 'datetime' | 'numeric' | 'string' | undefined;
  argumentValueList: any[] | undefined;
  argumentCategories: string[] | undefined;
  argumentSourceProperty: string;
}

interface IQuinoDashboardChartTilePropsSerialized extends IChartBasics {
  class: string;
  series: IQuinoDashboardChartSeriesPropsSerialized[];
}

interface IChartSettings {
  showLegend?: boolean;
  useZoom?: boolean;
  aggregatedDisplay?: boolean;
  rotateChart?: boolean;
  showXLabels?: boolean;
  showYLabels?: boolean;
  invertXAxis?: boolean;
  showTooltip?: boolean;
}

const defaultChartSettings: Required<IChartSettings> = {
  showLegend: true,
  useZoom: false,
  aggregatedDisplay: false,
  rotateChart: false,
  showXLabels: true,
  showYLabels: true,
  invertXAxis: false,
  showTooltip: true
};

interface IChartBasics extends IChartSettings {
  caption: string;
  argument: string;
  layout?: string;
}

export interface IQuinoDashboardChartSeriesProps extends ISeriesBasics {
  value: IMetaProperty | undefined;
}

export interface IQuinoDashboardChartSeriesPropsSerialized extends ISeriesBasics {
  value: string;
}

interface ISeriesBasics {
  id: string;
  type: string;
  color?: string;
  aggregationType?: string;
  customCaption?: string;
}

interface IFilterItem {
  property: string;
  value: any;
  operator: '=' | '<' | '>';
}

const chartTypes = ['bar', 'line'];
const aggregationTypes = ['avg', 'min', 'max', 'sum', 'count', 'custom'];

export const ChartTileRegistrationSymbol = Symbol.for('ChartTileRegistration');

@injectable()
export class QuinoDashboardChartTileRegistration implements IDashboardTileRegistration<IQuinoDashboardChartTileProps> {
  constructor(
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(ODataUrlBuilderSymbol) private readonly urlBuilder: ODataUrlBuilder,
    @inject(QuinoCoreServiceSymbols.IRequestDecoratorProvider) private readonly requestDecorator: IRequestDecoratorProvider,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger
  ) {}

  getCaption(payload: IQuinoDashboardChartTileProps): string {
    return payload.caption;
  }

  render(payload: IQuinoDashboardChartTileProps, dataSource: any): React.ReactNode {
    return <QuinoDashboardChartTile chartProps={payload} dataSource={dataSource} />;
  }

  renderSettings(
    payload: IQuinoDashboardChartTileProps,
    apply: (payload: IQuinoDashboardChartTileProps) => void,
    cancel: () => void
  ): React.ReactNode {
    return <QuinoDashboardChartTileConfiguration chartProps={payload} apply={apply} cancel={cancel} />;
  }

  deserializePayload(payload: any): IQuinoDashboardChartTileProps {
    const metaClass = this.metadataTree.getClass(payload.class || '');

    return {
      ...defaultChartSettings,
      ...payload,
      caption: payload.caption || '',
      class: metaClass,
      ...generateArgumentProperties(metaClass, payload.argument),
      series: this.deserializeSeries(metaClass, payload.series)
    };
  }

  serializePayload(payload: IQuinoDashboardChartTileProps): IQuinoDashboardChartTilePropsSerialized {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { argumentType, argumentSourceProperty, argumentValueList, argumentCategories, ...serializablePayload } = payload;

    return {
      ...serializablePayload,
      class: payload.class?.name || '',
      series: QuinoDashboardChartTileRegistration.serializeSeries(payload.series)
    };
  }

  showRefresh = true;
  name = 'Chart';
  dataSource: IDashboardTileDataSource<IQuinoDashboardChartTileProps> = new QuinoDashboardChartTileDataSource(
    this.urlBuilder,
    this.requestDecorator,
    this.logger
  );

  private deserializeSeries(metaClass: IMetaClass, series: any): IQuinoDashboardChartSeriesProps[] {
    if (series && Array.isArray(series)) {
      return series.map((s) => {
        return {
          ...s,
          id: s.id ? s.id : Guid.create().toString(),
          value: metaClass.properties.find((x) => x.name === s.value) || metaClass.relations.find((x) => x.name === s.value)
        };
      });
    }

    return [];
  }

  private static serializeSeries(series: IQuinoDashboardChartSeriesProps[]): IQuinoDashboardChartSeriesPropsSerialized[] {
    return series.map((x) => {
      return {
        ...x,
        value: x.value?.name || ''
      };
    });
  }
}

class QuinoDashboardChartTileDataSource implements IDashboardTileDataSource<IQuinoDashboardChartTileProps> {
  constructor(urlBuilder: ODataUrlBuilder, requestDecorator: IRequestDecoratorProvider, logger: ILogger) {
    this.urlBuilder = urlBuilder;
    this.requestDecorator = requestDecorator;
    this.logger = logger;
  }

  refreshData(): void {
    this.source?.reload();
  }

  async updateData(payload: IQuinoDashboardChartTileProps): Promise<any> {
    const relevantPropChanges =
      !this.oldProps ||
      this.oldProps.class?.name !== payload.class?.name ||
      this.oldProps.layout !== payload.layout ||
      this.oldProps.argumentSourceProperty !== payload.argumentSourceProperty ||
      this.seriesValuesChanged(this.oldProps.series, payload.series);

    if (relevantPropChanges && payload.class && payload.argumentSourceProperty) {
      const url = this.urlBuilder.entity(payload.class.name);
      const uniqueSeriesValues = QuinoDashboardChartTileDataSource.getUniqueValues(payload.series);
      url.select([payload.argumentSourceProperty, ...uniqueSeriesValues]);
      if (payload.layout) {
        url.setLayoutFilter(payload.layout);
      }

      this.currentUrl = url.toString() + (payload.argumentType === 'string' ? `&$orderby=${payload.argumentSourceProperty}` : '');
      await this.updateSource();
    }

    this.oldProps = payload;
    return this.source;
  }

  private async updateSource(): Promise<void> {
    const store = new ODataStore({
      url: this.currentUrl,
      version: 4,
      beforeSend: (options) => {
        options.headers = {};
        const request = new Request(this.currentUrl, { method: 'get' });
        this.requestDecorator.getInstances().forEach((decorator) => decorator.decorate(request));

        request.headers.forEach((value: string, key: string) => {
          return (options.headers[key] = value);
        });
      }
    });

    const newSource = new DataSource({
      store: store,
      paginate: false,
      errorHandler: (e: any) => {
        this.logger.logError(`Failed to load OData source. ${JSON.stringify(e)}`);
      }
    });

    await newSource.load();
    this.source = newSource;
  }

  private static getUniqueValues(arr: any[]) {
    return new Set<string>(arr.filter((x) => x.value).map((s) => s.value!.name));
  }

  private seriesValuesChanged(prevSeries: any[], newSeries: any[]): boolean {
    const prevUnique = Array.from(QuinoDashboardChartTileDataSource.getUniqueValues(prevSeries)).sort();
    const newUnique = Array.from(QuinoDashboardChartTileDataSource.getUniqueValues(newSeries)).sort();
    return !newUnique.every((value, index) => value === prevUnique[index]);
  }

  private currentUrl: string;
  private oldProps: IQuinoDashboardChartTileProps | undefined;
  private source: DataSource | undefined;
  private readonly urlBuilder: ODataUrlBuilder;
  private readonly requestDecorator: IRequestDecoratorProvider;
  private readonly logger: ILogger;
}

const generateArgumentProperties = (metaClass: IMetaClass | undefined, argumentName: string): Partial<IQuinoDashboardChartTileProps> => {
  const newProperties: Partial<IQuinoDashboardChartTileProps> = {
    argument: argumentName,
    argumentSourceProperty: '',
    argumentType: undefined,
    argumentCategories: undefined,
    argumentValueList: undefined
  };

  if (metaClass) {
    // Check if argument is a relation and store correct source property
    const valueListProperty =
      metaClass.relations.find((rel) => rel.name === argumentName) ?? metaClass.properties.find((p) => p.name === argumentName && isValueList(p));
    newProperties.argumentSourceProperty =
      valueListProperty && isMetaRelation(valueListProperty) ? valueListProperty.sourceProperties[0] : argumentName;

    // If argument is a value list property, store categories (for axis sorting) and value list (for label generation)
    newProperties.argumentValueList = valueListProperty ? getAspectOrDefault<IValueListAspect>(valueListProperty, 'ValueList')?.options : undefined;
    newProperties.argumentCategories = newProperties.argumentValueList
      ? newProperties.argumentValueList.map((option: any) => option.Value)
      : undefined;

    // Set explicit argument data type for dates/times and strings
    if (!valueListProperty) {
      const propertyDataType = metaClass.properties.find((p) => p.name === argumentName)?.dataType;
      switch (propertyDataType) {
        case DataType.Date:
        case DataType.DateTime:
        case DataType.Time:
          newProperties.argumentType = 'datetime';
          break;
        case DataType.Text:
          newProperties.argumentType = 'string';
          break;
        default:
          newProperties.argumentType = undefined;
      }
    }
  }

  return newProperties;
};

function QuinoDashboardChartTile(props: { chartProps: IQuinoDashboardChartTileProps; dataSource: DataSource | undefined }) {
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const titleCalculator = useService<IBookmarkTitleCalculator>(IBookmarkTitleCalculatorSymbol);
  const valueFormatter = useService<IValueFormatter>(IValueFormatterSymbol);

  const [component, setComponent] = useState<dxChart | undefined>();
  const chartData = props.chartProps;

  const countValues = (aggregationInfo: any, xVal: string, yVal: string, truthyOnly = false) => {
    const isTruthy = (value: any) => {
      return value === true || value === 'true' || value === 1;
    };

    return {
      [xVal]: aggregationInfo.intervalStart,
      [yVal]:
        aggregationInfo.data && aggregationInfo.data.length > 0
          ? truthyOnly
            ? aggregationInfo.data.filter((el: any) => isTruthy(el[yVal])).length
            : aggregationInfo.data.length
          : 0
    };
  };

  const getArgumentCategoryLabel = (labelData: any): string => {
    if (chartData.argumentValueList) {
      const argumentValue = chartData.argumentValueList.find((val) => val.Value === labelData.value);
      if (argumentValue) {
        return argumentValue.Caption;
      }
    }
    return labelData.valueText;
  };

  const navigateToFilteredList = async (metaClass: string, filter: IFilterItem[], layoutName?: string) => {
    const filterExpression = filter.map((f) => {
      return [f.property, f.operator, f.value];
    });

    const filteredBookmark = bookmarkFactory.createPrefilteredList(metaClass, filterExpression, layoutName);
    filteredBookmark.options.title = `${titleCalculator.generate(filteredBookmark)}, ${translationService.translate('Dashboard.Prefiltered')}`;
    navigationService.push(filteredBookmark).catch(logger.logError);
  };

  const onPointClick = (info: any) => {
    const filters: IFilterItem[] = [];
    if (info.target.aggregationInfo) {
      filters.push(
        { property: chartData.argumentSourceProperty, operator: '>', value: info.target.aggregationInfo.intervalStart },
        { property: chartData.argumentSourceProperty, operator: '<', value: info.target.aggregationInfo.intervalEnd }
      );
    } else {
      filters.push({ property: chartData.argumentSourceProperty, operator: '=', value: info.target?.originalArgument });
    }
    const seriesItem = chartData.series.find((s) => s.id === info.target?.series.id);
    if (seriesItem && seriesItem.value && seriesItem.aggregationType === 'custom') {
      filters.push({ property: seriesItem.value.name, operator: '=', value: true });
    }

    // chartData.class exists, else point would not be clickable
    navigateToFilteredList(chartData.class!.name, filters, chartData.layout).catch(logger.logError);
  };

  useEffect(() => {
    // There is no dependency array on purpose to update the chart when tile sizes change
    component?.refresh();
  });

  const customizeTooltip = (pointInfo: any) => {
    const set = props.chartProps.series.find((s) => s.id === pointInfo.seriesName);
    return {
      text: set?.value ? valueFormatter.formatValue(set.value, pointInfo.originalValue) : pointInfo.valueText
    };
  };

  const customizeLegend = (seriesInfo: any) => {
    const set = props.chartProps.series.find((s) => s.id === seriesInfo.seriesName);
    return set ? set.customCaption || set.value?.caption : '';
  };

  return (
    <Chart
      onInitialized={(e) => setComponent(e.component)}
      redrawOnResize={true}
      dataSource={props.dataSource}
      className='quino-dashboard-tile-chart'
      animation={false}
      onPointClick={onPointClick}
      onPointHoverChanged={(e) => (e.element.style.cursor = e.target?.isHovered() ? 'pointer' : 'auto')}
      rotated={chartData.rotateChart}
      onIncidentOccurred={(e) => {
        if (e.target.type === 'error' && e.target.id !== 'E2004') {
          logger.logError(e.target.widget + ': ' + e.target.text);
        }
      }}>
      <ArgumentAxis
        aggregateByCategory={chartData.aggregatedDisplay}
        categories={chartData.argumentCategories}
        argumentType={chartData.argumentType}
        inverted={chartData.invertXAxis}>
        <Label customizeText={getArgumentCategoryLabel} overlappingBehavior={'none'} textOverflow={'ellipsis'} visible={chartData.showXLabels} />
      </ArgumentAxis>

      <ValueAxis valueType={chartData.aggregatedDisplay ? 'numeric' : undefined}>
        <Label visible={chartData.showYLabels} />
      </ValueAxis>

      <Tooltip enabled={chartData.showTooltip} customizeTooltip={customizeTooltip} paddingLeftRight={8} paddingTopBottom={8} />

      <CommonSeriesSettings argumentField={chartData.argumentSourceProperty} />
      {chartData.series &&
        chartData.series.map((s, index) => (
          <Series key={index} valueField={s.value?.name} name={s.id} type={s.type || chartTypes[0]} color={s.color}>
            <Aggregation
              enabled={chartData.aggregatedDisplay && s.aggregationType}
              method={s.aggregationType === 'count' ? 'custom' : s.aggregationType}
              calculate={(aggregationInfo: any) =>
                countValues(aggregationInfo, chartData.argumentSourceProperty, s.value?.name || '', s.aggregationType === 'custom')
              }
            />
          </Series>
        ))}

      <Legend
        visible={chartData.showLegend}
        customizeText={customizeLegend}
        position='outside'
        horizontalAlignment='center'
        verticalAlignment='bottom'
      />
      {chartData.useZoom && <ZoomAndPan argumentAxis='both' />}
    </Chart>
  );
}

function QuinoDashboardChartTileConfiguration(props: {
  chartProps: IQuinoDashboardChartTileProps;
  apply: (payload: IQuinoDashboardChartTileProps) => void;
  cancel: () => void;
}) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const [chartProperties, setChartProperties] = useState<IQuinoDashboardChartTileProps>(props.chartProps);
  const translatedChartTypes = chartTypes.map((x) => {
    return {
      value: x,
      display: translationService.translate('Dashboard.ChartType.' + x)
    };
  });

  const translatedAggregationTypes = useMemo(
    () =>
      aggregationTypes.map((x) => {
        return {
          value: x,
          display: translationService.translate('Dashboard.ChartAggregationType.' + x)
        };
      }),
    [translationService]
  );

  const filteredAggregationTypes = (datatype: any) => {
    const includeTypes: string[] = [];
    if (isNumeric(datatype)) {
      includeTypes.push('avg', 'min', 'max', 'sum', 'count');
    } else if (datatype === DataType.Boolean) {
      includeTypes.push('count', 'custom');
    } else {
      includeTypes.push('count');
    }

    return translatedAggregationTypes.filter((x) => includeTypes.includes(x.value));
  };

  const isNumeric = (datatype: any): boolean => {
    switch (datatype) {
      case DataType.TinyInteger:
      case DataType.SmallInteger:
      case DataType.Integer:
      case DataType.LargeInteger:
      case DataType.Double:
      case DataType.Currency:
        return true;
      default:
        return false;
    }
  };

  const updateSeriesItem = (index: number, seriesItem: IQuinoDashboardChartSeriesProps) => {
    const updatedSeries = [...chartProperties.series];
    updatedSeries[index] = seriesItem;
    setChartProperties({ ...chartProperties, series: updatedSeries });
  };

  const standardCheckbox = (property: string, defaultValue: boolean) => {
    return (
      <CheckBox
        value={chartProperties[property] !== undefined ? chartProperties[property] : defaultValue}
        className={'quino-dashboard-checkbox'}
        onValueChanged={(value) => {
          setChartProperties({ ...chartProperties, [property]: value.value });
        }}
      />
    );
  };

  const tabs: IQuinoTabCustom[] = [
    {
      id: 0,
      title: translationService.translate('Dashboard.Tile.EditDialog.BasicSettings'),
      node: () => basicSettings()
    },
    {
      id: 1,
      title: translationService.translate('Dashboard.Tile.EditDialog.AdvancedSettings'),
      node: () => advancedSettings()
    }
  ];

  const basicSettings = (): ReactNode => (
    <>
      <QuinoCustomFormBox>
        <QuinoCustomFormRow columns={2}>
          <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.TileTitle')} required={true}>
            <TextBox
              value={chartProperties.caption}
              onValueChanged={(value) => {
                setChartProperties({ ...chartProperties, caption: value.value });
              }}
            />
          </QuinoLabeled>
        </QuinoCustomFormRow>
        <QuinoCustomFormRow columns={2}>
          <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.TileEntity')} required={true}>
            <QuinoMetaClassSelector
              value={chartProperties.class?.name}
              onSelect={(metaClass) => {
                const metaClassOrUndefined = metaClass ? metaClass : undefined; // make sure "null" becomes "undefined"
                setChartProperties({
                  ...chartProperties,
                  class: metaClassOrUndefined,
                  ...generateArgumentProperties(metaClassOrUndefined, ''),
                  series: Array.isArray(chartProperties.series)
                    ? chartProperties.series.map((series) => {
                        return { ...series, value: undefined };
                      })
                    : []
                });
              }}
            />
          </QuinoLabeled>
          <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.TileLayout')}>
            <QuinoLayoutSelector
              metaClass={chartProperties.class?.name}
              layoutType={LayoutType.List}
              value={chartProperties.layout}
              onSelect={(layoutName) => {
                setChartProperties({ ...chartProperties, layout: layoutName });
              }}
            />
          </QuinoLabeled>
        </QuinoCustomFormRow>
      </QuinoCustomFormBox>

      <QuinoCustomFormBox title={translationService.translate('Dashboard.Tile.EditDialog.XAxis')}>
        <QuinoCustomFormRow columns={2}>
          <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.Argument')} required={true}>
            <QuinoMetaPropertySelector
              metaClass={chartProperties.class?.name}
              value={chartProperties.argument}
              excludeActionProperties={true}
              excludeNonValueListRelations={true}
              onSelect={(property) => {
                const newProperties = generateArgumentProperties(chartProperties.class, property ? property.name : '');
                setChartProperties({ ...chartProperties, ...newProperties });
              }}
            />
          </QuinoLabeled>
          <QuinoCustomFormRow columns={2}>
            <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.AggregateXAxis')}>
              {standardCheckbox('aggregatedDisplay', defaultChartSettings.aggregatedDisplay)}
            </QuinoLabeled>
            <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.InvertXAxis')}>
              {standardCheckbox('invertXAxis', defaultChartSettings.invertXAxis)}
            </QuinoLabeled>
          </QuinoCustomFormRow>
        </QuinoCustomFormRow>
      </QuinoCustomFormBox>

      <QuinoCustomFormBox title={translationService.translate('Dashboard.Tile.EditDialog.Series')}>
        {!(chartProperties.series && chartProperties.series.length) ? (
          <div className={'quino-dashboard-tile-chart-series-placeholder'}>
            {translationService.translate('Dashboard.Tile.EditDialog.Series.EmptyHint')}
          </div>
        ) : (
          <div className={'quino-dashboard-tile-chart-series-title'}>
            <span>{translationService.translate('Dashboard.Tile.EditDialog.Series.Value')}</span>
            <span>{translationService.translate('Dashboard.Tile.EditDialog.Series.CustomLabel')}</span>
            <span>{translationService.translate('Dashboard.Tile.EditDialog.Series.ChartType')}</span>
            <span>{translationService.translate('Dashboard.Tile.EditDialog.Series.AggregationType')}</span>
            <span>{translationService.translate('Dashboard.Tile.EditDialog.Series.Color')}</span>
          </div>
        )}

        {chartProperties.series &&
          chartProperties.series.map((seriesItem, index) => (
            <div key={'series-' + index} className={'quino-dashboard-tile-chart-series-item'}>
              <QuinoMetaPropertySelector
                metaClass={chartProperties.class?.name}
                value={seriesItem.value?.name}
                excludeActionProperties={true}
                excludeNonValueListRelations={true}
                onSelect={(property) => {
                  updateSeriesItem(index, {
                    ...seriesItem,
                    value: property,
                    aggregationType: isNumeric(property.dataType)
                      ? seriesItem.aggregationType || filteredAggregationTypes(property.dataType)[0].value
                      : 'count'
                  });
                }}
              />
              <TextBox
                value={seriesItem.customCaption}
                onValueChanged={(e) => updateSeriesItem(index, { ...seriesItem, customCaption: e.value })}
                placeholder={seriesItem.value?.caption}
                showClearButton={true}
              />
              <SelectBox
                items={translatedChartTypes}
                valueExpr={'value'}
                displayExpr={'display'}
                onValueChanged={(e) => updateSeriesItem(index, { ...seriesItem, type: e.value })}
                value={seriesItem.type}
              />
              <SelectBox
                items={filteredAggregationTypes(seriesItem.value?.dataType)}
                disabled={!chartProperties.aggregatedDisplay}
                valueExpr={'value'}
                displayExpr={'display'}
                onValueChanged={(e) => updateSeriesItem(index, { ...seriesItem, aggregationType: e.value })}
                value={seriesItem.aggregationType || filteredAggregationTypes(seriesItem.value?.dataType)[0].value}
              />
              <ColorBox
                value={seriesItem.color}
                onValueChanged={(value) => {
                  updateSeriesItem(index, { ...seriesItem, color: value.value });
                }}
              />
              <Button
                icon={'material-icons-outlined delete'}
                hint={translationService.translate('Dashboard.Tile.EditDialog.Series.Delete')}
                onClick={() => {
                  const updatedSeries = chartProperties.series.filter((s) => !(seriesItem.id === s.id));
                  setChartProperties({ ...chartProperties, series: [...updatedSeries] });
                }}
              />
            </div>
          ))}

        <Button
          icon={'material-icons-outlined add'}
          text={translationService.translate('Dashboard.Tile.EditDialog.Series.Add')}
          onClick={() => {
            const newSeriesItem: IQuinoDashboardChartSeriesProps = {
              id: Guid.create().toString(),
              value: undefined,
              type: translatedChartTypes[0].value
            };
            const newSeries = chartProperties.series ? [...chartProperties.series, newSeriesItem] : [newSeriesItem];
            setChartProperties({ ...chartProperties, series: newSeries });
          }}
        />
      </QuinoCustomFormBox>
    </>
  );

  const advancedSettings = (): ReactNode => (
    <QuinoCustomFormBox>
      <QuinoCustomFormRow columns={2}>
        <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.ShowLegend')}>
          {standardCheckbox('showLegend', defaultChartSettings.showLegend)}
        </QuinoLabeled>
      </QuinoCustomFormRow>
      <QuinoCustomFormRow columns={2}>
        <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.ShowXLabels')}>
          {standardCheckbox('showXLabels', defaultChartSettings.showXLabels)}
        </QuinoLabeled>
        <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.ShowYLabels')}>
          {standardCheckbox('showYLabels', defaultChartSettings.showYLabels)}
        </QuinoLabeled>
        <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.RotateChart')}>
          {standardCheckbox('rotateChart', defaultChartSettings.rotateChart)}
        </QuinoLabeled>
        <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.EnableZooming')}>
          {standardCheckbox('useZoom', defaultChartSettings.useZoom)}
        </QuinoLabeled>
        <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.ShowTooltip')}>
          {standardCheckbox('showTooltip', defaultChartSettings.showTooltip)}
        </QuinoLabeled>
      </QuinoCustomFormRow>
    </QuinoCustomFormBox>
  );

  useEffect(() => {
    setChartProperties({ ...props.chartProps });
  }, [props]);

  return (
    <>
      <QuinoPopupDefaultContent isScrollable={false}>
        <QuinoTabsContainerCustom tabs={tabs} />
      </QuinoPopupDefaultContent>
      <QuinoPopupToolbar
        showTopBorder={true}
        rightItems={[
          <QuinoPopupToolbarButton
            text={translationService.translate('Cancel')}
            icon={'material-icons-outlined clear'}
            onClick={() => {
              props.cancel();
              setChartProperties(props.chartProps);
            }}
          />,
          <QuinoPopupToolbarButton
            text={translationService.translate('Apply')}
            icon={'material-icons-outlined check'}
            isPrimary={true}
            onClick={() => props.apply(chartProperties)}
            disabled={
              !chartProperties.series ||
              !chartProperties.series.length ||
              !chartProperties.caption ||
              !chartProperties.class ||
              !chartProperties.argument
            }
          />
        ]}
      />
    </>
  );
}
