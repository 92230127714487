import { IExecutable } from './IExecutable';
import { ApplicationStartupGroup, ExecutionGroup, FrameworkStartupGroup, InitializationGroup, ThemeInitializationGroup } from './ExecutionGroup';

/**
 * The root node which offers some.
 */
export class RootExecutable extends ExecutionGroup {
  constructor() {
    super(Symbol.for('RootAction'), null);

    this.children.push(new ExecutionGroup(InitializationGroup, this));
    this.children.push(new ExecutionGroup(ThemeInitializationGroup, this));
    this.children.push(new ExecutionGroup(FrameworkStartupGroup, this));
    this.children.push(new ExecutionGroup(ApplicationStartupGroup, this));
  }

  register = (executable: IExecutable): void => {
    if (!this.searchAndReplace(this, executable)) {
      this.children.push(executable);
    }
  };

  registerForGroup = (executable: IExecutable, group: Symbol): void => {
    const foundGroup = this.find(this, group);
    if (foundGroup == null) {
      throw new Error(`Failed to resolve group with symbol ${group}`);
    }

    const existingIndex = foundGroup.children.indexOf(executable);
    if (existingIndex !== -1) {
      foundGroup[existingIndex] = executable;
    } else {
      executable.parent = foundGroup;
      foundGroup.children.push(executable);
    }
  };

  remove = (symbol: Symbol): void => {
    const result = this.find(this, symbol);
    if (result != null && result.parent != null) {
      const index = result.parent.children.indexOf(result);
      result.parent.children.splice(index, 1);
    }
  };

  get = (symbol: Symbol): IExecutable | undefined => {
    return this.find(this, symbol);
  };

  private readonly find = (from: IExecutable, symbol: Symbol): IExecutable | undefined => {
    let result = from.children.find((child) => child.identifier === symbol);

    if (result == null) {
      for (const child of from.children) {
        result = this.find(child, symbol);
        if (result != null) {
          return result;
        }
      }
    }

    return result;
  };

  private readonly searchAndReplace = (executable: IExecutable, toAdd: IExecutable): boolean => {
    const existing = executable.children.find((value) => value.identifier === toAdd.identifier);

    if (existing != null) {
      const index = executable.children.indexOf(existing);
      executable.children[index] = toAdd;
      return true;
    }

    for (const child of executable.children) {
      if (this.searchAndReplace(child, toAdd)) {
        return true;
      }
    }

    return false;
  };
}
