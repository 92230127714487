import { IAuthenticationData } from './IAuthenticationData';

/**
 * Authentication data which leverages username and password.
 */
export class PasswordAuthenticationData implements IAuthenticationData {
  constructor(public username: string, public password: string) {
    // NOP
  }
}
