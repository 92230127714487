import { FrameworkStartupGroup, IApplication } from '../../../application';
import { QuinoCoreServiceSymbols } from '../../../ioc';
import { ExportExcelService, IExportExcelService } from './service';
import {
  CheckBoxExcelMapper,
  CheckBoxExcelMapperSymbol,
  ColumnsMapper,
  ColumnsMapperSymbol,
  DefaultControlExcelMapper,
  DefaultControlExcelMapperSymbol,
  ExcelDefaultLayoutMapper,
  ExcelDefaultLayoutMapperSymbol,
  ExcelMapperProvider,
  GridEditorExcelMapper,
  GridEditorExcelMapperSymbol,
  GroupBoxExcelMapper,
  GroupBoxExcelMapperSymbol,
  IExcelMapperProvider,
  TabItemExcelMapper,
  TabItemExcelMapperSymbol
} from './mapping';
import {
  IQuinoExcelCellBuilder,
  IQuinoExcelCellBuilderSymbol,
  IQuinoExcelRowBuilder,
  IQuinoExcelRowBuilderSymbol,
  QuinoExcelCellBuilder,
  QuinoExcelRowBuilder
} from './builder';
import {
  defaultExcelExportSettings,
  DefaultExportExcelConfigurationService,
  IExportExcelConfigurationService,
  IExportExcelSettings
} from './settings';

export const ExcelExportMapperRegistrationStartupActionIdentifier = Symbol.for('RegisterExcelExportMapper');

export class ExportExcelModule {
  static use = (application: IApplication): IApplication => {
    application.registerSingle<IExcelMapperProvider>(QuinoCoreServiceSymbols.IExcelMapperProvider, ExcelMapperProvider);
    application.registerSingle<IExportExcelService>(QuinoCoreServiceSymbols.IExportExcelService, ExportExcelService);
    application.registerSingle<CheckBoxExcelMapper>(CheckBoxExcelMapperSymbol, CheckBoxExcelMapper);
    application.registerSingle<GridEditorExcelMapper>(GridEditorExcelMapperSymbol, GridEditorExcelMapper);
    application.registerSingle<GroupBoxExcelMapper>(GroupBoxExcelMapperSymbol, GroupBoxExcelMapper);
    application.registerSingle<DefaultControlExcelMapper>(DefaultControlExcelMapperSymbol, DefaultControlExcelMapper);
    application.registerSingle<TabItemExcelMapper>(TabItemExcelMapperSymbol, TabItemExcelMapper);
    application.registerSingle<ColumnsMapper>(ColumnsMapperSymbol, ColumnsMapper);
    application.registerSingle<ExcelDefaultLayoutMapper>(ExcelDefaultLayoutMapperSymbol, ExcelDefaultLayoutMapper);
    application.register<IQuinoExcelCellBuilder>(IQuinoExcelCellBuilderSymbol, QuinoExcelCellBuilder);
    application.register<IQuinoExcelRowBuilder>(IQuinoExcelRowBuilderSymbol, QuinoExcelRowBuilder);
    application.registerConstant<IExportExcelSettings>(QuinoCoreServiceSymbols.IExportExcelSettings, defaultExcelExportSettings);
    application.registerSingle<IExportExcelConfigurationService>(
      QuinoCoreServiceSymbols.IExportExcelConfigurationService,
      DefaultExportExcelConfigurationService
    );
    application
      .registerStartupAction(ExcelExportMapperRegistrationStartupActionIdentifier, (app) => {
        const provider = app.get<IExcelMapperProvider>(QuinoCoreServiceSymbols.IExcelMapperProvider);
        provider.register(app.get<CheckBoxExcelMapper>(CheckBoxExcelMapperSymbol), CheckBoxExcelMapperSymbol);
        provider.register(app.get<GridEditorExcelMapper>(GridEditorExcelMapperSymbol), GridEditorExcelMapperSymbol);
        provider.register(app.get<GroupBoxExcelMapper>(GroupBoxExcelMapperSymbol), GroupBoxExcelMapperSymbol);
        provider.register(app.get<DefaultControlExcelMapper>(DefaultControlExcelMapperSymbol), DefaultControlExcelMapperSymbol);
        provider.register(app.get<TabItemExcelMapper>(TabItemExcelMapperSymbol), TabItemExcelMapperSymbol);
        provider.register(app.get<ColumnsMapper>(ColumnsMapperSymbol), ColumnsMapperSymbol);
        provider.register(app.get<ExcelDefaultLayoutMapper>(ExcelDefaultLayoutMapperSymbol), ExcelDefaultLayoutMapperSymbol);
      })
      .moveTo(FrameworkStartupGroup);

    return application;
  };
}
