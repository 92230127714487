import React, { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { useService } from '../../ioc/hook';
import { IComponentFactory } from '../ComponentFactory';
import { ILogger, IMetaElement, isIMetaGroup, QuinoCoreServiceSymbols } from '@quino/core';
import { QuinoUIServiceSymbols } from '../../ioc';
import { ObjectBookmark } from '../../bookmarks';
import { useMetadata } from '../../settings';
import { useOnDependencyChanged } from '../Util/MetaElementHooks';

export function QuinoGroupBox(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const factory = useService<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const { visible, readOnly, required, enabled } = useMetadata(props.element, props.bookmark.genericObject);
  useOnDependencyChanged(props.element, props.bookmark);
  if (!visible) {
    return <></>;
  }

  if (isIMetaGroup(props.element)) {
    return (
      <div className='dx-form-group-with-caption' key={props.element.name + '_group_box'}>
        <span key={1} className='dx-form-group-caption'>
          {props.element.caption}
        </span>
        <div key={2} className='dx-form-group-content quino-form-group-content'>
          {props.element.elements.map((child: IMetaElement) => factory.create(child, props.bookmark, props.actions, { enabled, readOnly, required }))}
        </div>
      </div>
    );
  }

  logger.logError(`Trying to render a group with a non-group element. Rendering fragment for: [${props.element}]`);

  return <></>;
}
