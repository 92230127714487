export const parseTimeSpan = (timeSpanValue: any): string => {
  if (!timeSpanValue) {
    return '0.00:00:00.000';
  } else if (typeof timeSpanValue !== 'string' || timeSpanValue[0] !== 'P') {
    console.warn('Invalid time span value received, cannot parse.');
    return String(timeSpanValue);
  }

  // Note: This implementation is based on the .NET timespan that uses only days (D), hours (H), minutes (M) and seconds (S).
  // Refactor in case larger time spans containing years (Y) and months (M) are used.

  const daysResult = /(\d+)D/.exec(timeSpanValue);
  const days = daysResult ? parseInt(daysResult[0]) : 0;

  const hoursResult = /(\d+)H/.exec(timeSpanValue);
  const hours = hoursResult ? parseInt(hoursResult[0]) : 0;

  const minutesResult = /(\d+)M/.exec(timeSpanValue);
  const minutes = minutesResult ? parseInt(minutesResult[0]) : 0;

  const secondsResult = /(\d+\.?\d*)S/.exec(timeSpanValue);
  const seconds = secondsResult ? parseFloat(secondsResult[0]) : 0;
  const secondsPadZero = Math.floor(seconds).toString().length < 2 ? '0' : '';

  return `${days}.${('00' + hours).slice(-2)}:${('00' + minutes).slice(-2)}:${secondsPadZero + seconds.toFixed(3)}`;
};
