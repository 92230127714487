export const isBase64ImageSource = (src: string | undefined): boolean => {
  return (src && src.includes('base64')) || false;
};

export const convertToBase64ImageSource = (src: string): string => {
  if (isBase64ImageSource(src)) {
    return src;
  }

  return 'data:image/*;base64,' + src;
};
