import { IExpression, isExpression } from './IExpression';

/**
 * Function call expression - usually combined with a namespace expression.
 */
export interface IFunctionCallExpression extends IExpression {
  name: string;
}

export const isFunctionCallExpression = (object: any): object is IFunctionCallExpression => {
  return object != null && isExpression(object) && object.ExpressionType === 6;
};
