import { IMetaAspect } from '@quino/core';

/**
 * The identifier for retrieving a layout action aspect.
 */
export const ConfirmationActionAspectIdentifier = 'ConfirmationActionAspect';

/**
 * Aspect containing info about where to display a layout action
 */
export interface IConfirmationActionAspect extends IMetaAspect {
  /**
   * Text of the continue button
   */
  continueButtonText?: string;

  /**
   * Text of the cancel button
   */
  cancelButtonText?: string;

  /**
   * Text of the title
   */
  title?: string;

  /**
   * Text of the message
   */
  message?: string;
}
