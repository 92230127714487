import React, { useEffect, useState } from 'react';
import {
  IObjectBookmark,
  IQuinoBookmarkAction,
  IQuinoShortcutSettings,
  IQuinoShortcutSettingsSymbol,
  isIObjectBookmark,
  quinoActionPropValue,
  setFocusOnElement,
  useService,
  useShortcutHandler,
  IShortcutInformationService,
  IShortcutInformationServiceSymbol,
  IQuinoAction,
  useRerender,
  useOnBookmark
} from '@quino/ui';
import { Resizable } from 'devextreme-react/resizable';
import {
  ILogger,
  IStorageService,
  ITranslationService,
  ITranslationServiceSymbol,
  LocalStorageServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { List } from 'devextreme-react/list';

export interface ICUISidebarActionsProps {
  bookmark: IObjectBookmark;
  actions: IQuinoAction[];
  displayFullHeight: boolean;
  searchEnabled: boolean;
}

export const CUISidebarActions = (props: ICUISidebarActionsProps) => {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const settingsService = useService<IStorageService>(LocalStorageServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const rerender = useRerender();

  const { bookmark, actions, displayFullHeight, searchEnabled } = props;

  const storageKeyPrefix = 'SidebarActionHeightSettings_';
  const minHeight = searchEnabled ? 120 : 64;
  const [storageKey, setStorageKey] = useState<string>();
  const [height, setHeight] = useState<number>(minHeight);

  useOnBookmark(bookmark, rerender);

  useEffect(() => {
    let dismissed = false;

    if (isIObjectBookmark(bookmark)) {
      const key = `${bookmark.genericObject.metaClass}`;
      setStorageKey(key);
      settingsService
        .get(storageKeyPrefix + key)
        .then((height: number) => !dismissed && setHeight(height))
        .catch(logger.logError);
    }

    return () => {
      dismissed = true;
    };
  }, [logger, bookmark, settingsService]);

  const actionsContent =
    actions.length > 0 ? (
      <CUISidebarActionsContent
        bookmark={bookmark}
        actions={actions}
        searchEnabled={searchEnabled}
        searchPlaceholder={translationService.translate('SearchActions')}
      />
    ) : (
      <div className='quino-ecui-sidebar-actions-empty-text'>{translationService.translate('ActionArea.NoActions')}</div>
    );

  return (
    <>
      <div className={'quino-ecui-sidebar-title'}>
        <p>{translationService.translate('ActionArea.Title')}</p>
      </div>
      {displayFullHeight ? (
        <div className={'quino-ecui-sidebar-actions-area full-height'}>{actionsContent}</div>
      ) : (
        <Resizable
          handles={'bottom'}
          height={height}
          minHeight={minHeight}
          className={'quino-ecui-sidebar-actions-area resizable' + (displayFullHeight ? ' invisible' : '')}
          onResize={(e) => e.height != null && setHeight(e.height)}
          onResizeEnd={(e) => e.height != null && storageKey && settingsService.set(storageKeyPrefix + storageKey, e.height)}>
          {actionsContent}
        </Resizable>
      )}
    </>
  );
};

function CUISidebarActionsContent(props: {
  bookmark: IObjectBookmark;
  actions: IQuinoBookmarkAction[];
  searchEnabled: boolean;
  searchPlaceholder: string;
}) {
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);
  const shortcutInformation = useService<IShortcutInformationService>(IShortcutInformationServiceSymbol).get();
  const actionSearchEditorId = 'quino-ecui-sidebar-action-search';

  const source = props.actions.map((a) => {
    return {
      icon: quinoActionPropValue(a.icon, props.bookmark) || 'material-icons-outlined bolt',
      text: quinoActionPropValue(a.caption, props.bookmark),
      visible: quinoActionPropValue(a.visible, props.bookmark),
      disabled: quinoActionPropValue(a.disabled, props.bookmark),
      onClick: () => a.onClick({ source: props.bookmark, bookmarkToRefresh: props.bookmark })
    };
  });

  useShortcutHandler([shortcutSettings.actions], () => setFocusOnElement(actionSearchEditorId, true));

  return (
    <List
      elementAttr={{ class: 'quino-ecui-sidebar-actions-list' }}
      searchEnabled={props.searchEnabled}
      searchExpr={'text'}
      showScrollbar={'always'}
      searchEditorOptions={{
        placeholder: props.searchPlaceholder,
        hint: shortcutInformation.hint(shortcutSettings.menuSearch),
        onContentReady: (e) => {
          if (e.element) {
            e.element.id = actionSearchEditorId;
          }
        }
      }}
      dataSource={source}
      itemRender={(data) => (
        <div className={'sidebar-action-content'}>
          <i className={'sidebar-action-icon ' + data.icon} />
          <div className={'sidebar-action-caption'} title={data.text}>
            {data.text}
          </div>
        </div>
      )}
    />
  );
}
