import { IMetaAction, IProvider } from '@quino/core';
import { IClientAction } from './IClientAction';

/**
 * The symbol for the IClientActionProvider.
 */
export const IClientActionProviderSymbol = Symbol.for('IClientActionProvider');

/**
 * A provider for registering additional client side actions.
 *
 * Per default those actions are mapped over the server side actions. So if an action is mapped explicitly on the client the server action will be overridden.
 */
export interface IClientActionProvider extends IProvider<IClientAction> {
  /**
   * Find the client action that matches the given meta-action.
   * @param action - the action to look for.
   */
  find(action: IMetaAction): IClientAction | undefined;
}
