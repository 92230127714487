import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression, isExpression } from './IExpression';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IExpressionEvaluatorProvider } from './IExpressionEvaluatorProvider';
import cloneDeep from 'lodash/cloneDeep';
import { IContextDecoratorProvider } from '../context';

@injectable()
export class AggregatingExpressionEvaluator implements IExpressionEvaluator {
  constructor(
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluatorProvider) private readonly provider: IExpressionEvaluatorProvider,
    @inject(QuinoCoreServiceSymbols.IContextDecoratorProvider)
    private readonly contextDecoratorProvider: IContextDecoratorProvider
  ) {}

  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (!isExpression(expression)) {
      return expression;
    }

    // Don't modify the original context supplied
    let clonedContext = cloneDeep(context);
    if (clonedContext == null) {
      clonedContext = {};
    }
    this.contextDecoratorProvider.getInstances().forEach((decorator) => decorator.decorate(clonedContext));

    for (const evaluator of this.provider.getInstances()) {
      const evaluateResult: IExpression | TValue = evaluator.evaluate(expression, clonedContext);

      if (evaluateResult !== expression) {
        if (!isExpression(evaluateResult)) {
          return evaluateResult;
        }
      }
    }

    throw new Error(`Can not parse expression [${JSON.stringify(expression)}] with context [${JSON.stringify(clonedContext)}]`);
  }
}
