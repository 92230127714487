import { IDashboardTileRegistry } from './IDashboardTileRegistry';
import { injectable } from 'inversify';
import { IDashboardTileRegistration } from './IDashboardTileRegistration';

@injectable()
export class DashboardTileRegistry implements IDashboardTileRegistry {
  getInstances(): IDashboardTileRegistration<any>[] {
    const instances: any[] = [];
    for (const identifier in this.instances) {
      instances.push(this.instances[identifier]);
    }

    return instances;
  }

  register<TPayload>(symbol: symbol, registration: IDashboardTileRegistration<TPayload>): void {
    const identifier = Symbol.keyFor(symbol);
    if (!identifier) {
      throw new Error('Could not resolve symbol, did you use the global Symbol.for(...) registration?');
    }

    this.instances[identifier] = registration;
  }

  registrations: any[] = [];

  private instances: { [identifier: string]: any } = {};
}
