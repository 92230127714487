import { IAuthenticationFeedback } from './IAuthenticationFeedback';
import { injectable } from 'inversify';

@injectable()
export class NullAuthenticationFeedback implements IAuthenticationFeedback {
  requestLogin = (onResolve: () => void): void => {
    onResolve();
  };

  requestPasswordChange = (): void => {
    // NOP
  };
}
