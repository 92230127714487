import { IQuinoDataGridSortService } from './IQuinoDataGridSortService';
import { injectable } from 'inversify';

@injectable()
export class QuinoDataGridSortService implements IQuinoDataGridSortService {
  sortingMethod = (value1: any, value2: any) => {
    // From https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/#sortingMethod
    // Handling null values
    if (!value1 && value2) {
      return -1;
    }
    if (!value1 && !value2) {
      return 0;
    }
    if (value1 && !value2) {
      return 1;
    }

    if (typeof value1 === 'string') {
      // Determines whether two strings are equivalent in the current locale
      return value1.localeCompare(value2);
    }

    if (value1 < value2) {
      return -1;
    }

    if (value1 > value2) {
      return 1;
    }

    return 0;
  };
}
