import { ExcelMapperBase } from './ExcelMapperBase';
import { IMetaElement } from '../../../../../meta';
import { injectable } from 'inversify';
import { IGenericObject } from '../../../../../data';
import { IQuinoExcelRow } from '../../model';

export const GroupBoxExcelMapperSymbol = Symbol.for('GroupBoxExcelMapper');

@injectable()
export class GroupBoxExcelMapper extends ExcelMapperBase {
  conditionMatches(element: IMetaElement, genericObject: IGenericObject): boolean {
    return this.isVisible(element, genericObject);
  }

  getMappableElements(): string[] {
    return ['GroupBox'];
  }

  async getData(genericObject: IGenericObject, element: IMetaElement): Promise<IQuinoExcelRow[]> {
    const settings = await this.excelExportConfigurationService.getExportSettings(element);
    return [
      this.rowBuilder
        .withDefaultSettings(settings)
        .withMarginTop(settings.groupBoxTitleSettings.rowMargin)
        .withCells([
          this.cellBuilder
            .withValue(element.caption)
            .setIsBold(settings.groupBoxTitleSettings.isBold)
            .withFontSize(settings.groupBoxTitleSettings.fontSize)
            .build(),
          this.cellBuilder
            .withValue(' ')
            .setIsBold(settings.groupBoxTitleSettings.isBold)
            .withFontSize(settings.groupBoxTitleSettings.fontSize)
            .build()
        ])
        .build()
    ];
  }
}
