import { inject, injectable } from 'inversify';
import { MetadataTree } from './MetadataTree';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IMetaElementFactory } from './meta';
import { IPublicModelFetcher, IPublicModelFetcherSymbol } from './IModelFetcher';
import { ILogger } from '../logging';
import { IPublicMetaDataTree } from './IMetadataTree';

export const IPublicMetadataTreeSymbol = Symbol.for('PublicMetadataTree');

@injectable()
export class PublicMetadataTree extends MetadataTree implements IPublicMetaDataTree {
  constructor(
    @inject(QuinoCoreServiceSymbols.IStorage) public storage: Storage,
    @inject(QuinoCoreServiceSymbols.IMetaElementFactory) public metaElementFactory: IMetaElementFactory,
    @inject(IPublicModelFetcherSymbol) public modelFetcher: IPublicModelFetcher,
    @inject(QuinoCoreServiceSymbols.ILogger) public logger: ILogger
  ) {
    super(storage, metaElementFactory, modelFetcher, logger);
  }
}
