import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression, isExpression } from './IExpression';
import { inject, injectable } from 'inversify';
import { isMetaPropertyExpression } from './IMetaPropertyExpression';
import { DataType } from '../meta';
import { QuinoCoreServiceSymbols } from '../ioc/constants/identifiers';
import { IMetadataTree } from '../api/IMetadataTree';
import { IValueFormatter, IValueFormatterSymbol } from '../data/IValueFormatter';

export const MetaPropertyExpressionEvaluatorSymbol = Symbol.for('MetaPropertyExpressionEvaluator');

@injectable()
export class MetaPropertyExpressionEvaluator implements IExpressionEvaluator {
  constructor(
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(IValueFormatterSymbol) private readonly valueFormatter: IValueFormatter
  ) {}

  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (isExpression(expression) && context != null && isMetaPropertyExpression(expression)) {
      let result = context[expression.PropertyName];

      if (!result) {
        const matchingKey = Object.keys(context).find((x) => x.toLowerCase() === expression.PropertyName.toLowerCase());
        if (matchingKey) {
          result = context[matchingKey];
        }
      }

      return this.evaluateValue(result, expression.PropertyName, expression.ClassName);
    }

    // @ts-ignore
    return expression;
  }

  evaluateValue(value: any, propertyName?: string, metaClassName?: string): any {
    const metaClass = metaClassName != null ? this.metadataTree.getClass(metaClassName) : undefined;
    const property = metaClass?.properties.find((x) => x.name === propertyName);

    if (typeof value === 'string' && property != null) {
      if (property.dataType === DataType.DateTime || property.dataType === DataType.Date || property.dataType === DataType.Time) {
        value = new Date(value);
      } else if (property.dataType === DataType.Text) {
        value = this.valueFormatter.formatValue(property, value);
      }
    }

    return value;
  }
}
