import * as React from 'react';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { IMetaProperty, getMetaProperty } from '@quino/core';
import { useMetaPropertyValueState } from '../Util';
import * as CodeMirror from 'codemirror';

import 'codemirror/keymap/sublime.js';
import 'codemirror/mode/xml/xml.js';

import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/xml-hint.js';

import 'codemirror/addon/edit/closetag.js';
import 'codemirror/addon/fold/xml-fold.js';

import 'codemirror/addon/edit/matchtags.js';
import * as tags from './tags.json';
import { QuinoLabeled } from '../QuinoLabeled';
import { useLabelSettings, useMetadata } from '../../settings';

export function XMLEditor(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const { element, bookmark } = props;
  const metaProperty = getMetaProperty(element);
  const { description, caption, instruction } = metaProperty;
  const { visible, required } = useMetadata(metaProperty, bookmark.genericObject);

  const labelSettings = useLabelSettings(metaProperty);
  const textArea = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useMetaPropertyValueState<string>(props.element as IMetaProperty, props.bookmark);
  const [editor, setEditor] = useState<any>();

  function completeAfter(cm: any, pred: any) {
    if (!pred || pred()) {
      setTimeout(function () {
        if (!cm.state.completionActive) {
          cm.showHint({ completeSingle: false });
        }
      }, 100);
    }
    return CodeMirror.Pass;
  }

  useEffect(() => {
    if (textArea && !editor) {
      function completeIfAfterLt(cm: any) {
        return completeAfter(cm, function () {
          const cur = cm.getCursor();
          return cm.getRange(CodeMirror.Pos(cur.line, cur.ch - 1), cur) === '<';
        });
      }

      function completeIfInTag(cm: any) {
        return completeAfter(cm, function () {
          const tok = cm.getTokenAt(cm.getCursor());
          if (tok.type === 'string' && (!/['"]/.test(tok.string.charAt(tok.string.length - 1)) || tok.string.length === 1)) {
            return false;
          }
          const inner = CodeMirror.innerMode(cm.getMode(), tok.state).state;
          return inner.tagName;
        });
      }

      const editor = CodeMirror.fromTextArea(textArea.current!, {
        mode: 'xml',
        theme: 'default',
        keyMap: 'sublime',
        lineNumbers: true,
        autoCloseTags: true,
        smartIndent: true,
        matchTags: { bothTags: true },
        extraKeys: {
          // @ts-ignore
          "'<'": completeAfter,
          "'/'": completeIfAfterLt,
          "' '": completeIfInTag,
          "'='": completeIfInTag,
          'Ctrl-Space': 'autocomplete'
        },
        hintOptions: {
          // @ts-ignore
          schemaInfo: tags
        }
      });
      setEditor(editor);
      editor.on('change', () => setValue(editor.getValue('\n')));
    }
  }, [editor, setValue]);

  // Accept changes from outside (like discard)
  useEffect(() => {
    if (editor && value && editor.getValue('\n') !== value) {
      editor.setValue(value);
    }
  }, [editor, value]);

  return (
    <QuinoLabeled
      visible={visible}
      label={caption}
      required={required}
      description={description}
      instruction={instruction}
      labelSettings={{ ...labelSettings, alignment: labelSettings.alignment }}>
      <div key={'CodeMirrorWrapper'} className={'quino-codemirror'}>
        <textarea ref={textArea} className={'quino-codemirror-textarea'} value={value} />
      </div>
    </QuinoLabeled>
  );
}
