import { IExpression } from './IExpression';
import { ExpressionOperator } from './ExpressionOperator';

export interface IBinaryExpression extends IExpression {
  Operator: ExpressionOperator;
  left: IExpression;
  right: IExpression;
}

export const isBinaryExpression = (object: IExpression): object is IBinaryExpression => {
  return (
    (object as IBinaryExpression).Operator !== undefined &&
    (object as IBinaryExpression).left !== undefined &&
    (object as IBinaryExpression).right !== undefined
  );
};
