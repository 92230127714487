import { IFieldValidationResult, IMetaProperty } from '@quino/core';

export const IFileUploadValidatorSymbol = Symbol.for('IFileUploadValidator');

export interface IFileUploadValidator {
  validateControl(
    metaProperty: IMetaProperty,
    uploadedFiles: any[] | undefined,
    filesInProgress: any[] | undefined,
    required: boolean
  ): IFieldValidationResult;
}
