import { IGlobalSearchInfoDTO, IGlobalSearchResultDTO, IGlobalSearchService } from './IGlobalSearchService';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IUrlManager } from '../QuinoServer';
import { IRequestBuilder } from '../../request';

@injectable()
export class GlobalSearchService implements IGlobalSearchService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder
  ) {}

  async search(
    searchString: string,
    metaClassName: string | undefined = undefined,
    numberOfSearchResults: number | undefined = undefined
  ): Promise<IGlobalSearchResultDTO[]> {
    const url = this.urlManager.getGlobalSearchSearchUrl(searchString, metaClassName, numberOfSearchResults);
    return this.requestBuilder.create(url, 'get').requiresAuthentication().fetchJson<IGlobalSearchResultDTO[]>();
  }

  async info(): Promise<IGlobalSearchInfoDTO[]> {
    const url = this.urlManager.getGlobalSearchUrl('getInfo');
    return this.requestBuilder.create(url, 'get').requiresAuthentication().fetchJson<IGlobalSearchInfoDTO[]>();
  }

  async recreateIndex(): Promise<void> {
    const url = this.urlManager.getGlobalSearchUrl('reCreateIndex');
    await this.requestBuilder.create(url, 'post').requiresAuthentication().fetch();
  }

  async updateIndex(): Promise<void> {
    const url = this.urlManager.getGlobalSearchUrl('updateIndex');
    await this.requestBuilder.create(url, 'post').requiresAuthentication().fetch();
  }
}
