import { IBookmark, IBookmarkFactory, IObjectBookmark, isIObjectBookmark } from '../../bookmarks';
import { INavigationLinkResolver } from './INavigationLinkResolver';
import {
  IExpressionEvaluator,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetadataTree,
  INavigationLinkTarget,
  isExpression,
  isINavigationLinkTargetObject,
  LayoutType,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { QuinoUIServiceSymbols } from '../../ioc';
import { INavigationService, INavigationServiceSymbol } from '../INavigationService';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../../data';
import { resolveLayout } from './ResolverUtils';

export const ObjectNavigationLinkResolverSymbol = Symbol.for('ObjectNavigationLinkResolver');
const StateTabIndexIdentifier = 'tabindex';

@injectable()
export class ObjectNavigationLinkResolver implements INavigationLinkResolver {
  constructor(
    @inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(INavigationServiceSymbol) private readonly navigationService: INavigationService,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(IODataSourceFactorySymbol) private readonly sourceFactory: IODataSourceFactory,
    @inject(ILayoutResolverSymbol) private readonly layoutResolver: ILayoutResolver,
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly expressionEvaluator: IExpressionEvaluator,
    @inject(ILoadingFeedbackSymbol) private readonly loadingFeedback: ILoadingFeedback
  ) {}

  generateOnClickCallback = (target: INavigationLinkTarget, currentBookmark?: IBookmark, openInNewTab?: boolean): (() => void) | undefined => {
    if (isINavigationLinkTargetObject(target)) {
      const metaClass = this.metadataTree.getClass(target.metaClass);
      const primaryKey = isExpression(target.primaryKey)
        ? this.expressionEvaluator.evaluate<string>(target.primaryKey, isIObjectBookmark(currentBookmark) ? currentBookmark.genericObject : undefined)
        : target.primaryKey;
      const targetLayout = resolveLayout(this.expressionEvaluator, this.metadataTree, target.metaClass, target.layout, currentBookmark);

      return async () => {
        const unload = this.loadingFeedback.load();
        const layout = targetLayout ?? this.layoutResolver.resolveSingle({ metaClass: target.metaClass, type: LayoutType.Detail });
        const genericObject = await this.sourceFactory.fetch(primaryKey, layout, metaClass.name);
        const bookmark = this.bookmarkFactory.createObject(genericObject, layout);
        target.tabIndex !== undefined && bookmark.setStateValue(StateTabIndexIdentifier, target.tabIndex);
        this.openBookmark(bookmark, openInNewTab).catch(this.logger.logError).finally(unload);
      };
    }

    return undefined;
  };

  private readonly openBookmark = async (bookmark: IObjectBookmark, openInNewTab?: boolean): Promise<any> => {
    if (openInNewTab) {
      window.open(this.navigationService.extractUrl(bookmark), '_blank');
    } else {
      const currentBookmark = this.navigationService.active;
      if (
        isIObjectBookmark(currentBookmark) &&
        currentBookmark.metaClass === bookmark.metaClass &&
        currentBookmark.layout.name === bookmark.layout.name &&
        currentBookmark.genericObject.primaryKey === bookmark.genericObject.primaryKey
      ) {
        const currentTabIndex = currentBookmark.getStateValue(StateTabIndexIdentifier) ?? 0;
        const requestedTabIndex = bookmark.getStateValue(StateTabIndexIdentifier) ?? 0;

        if (currentTabIndex == requestedTabIndex) {
          currentBookmark.refresh();
        } else {
          currentBookmark.setStateValue(StateTabIndexIdentifier, requestedTabIndex);
        }

        return;
      }

      return this.navigationService.push(bookmark);
    }
  };
}
