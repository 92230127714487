import { inject, injectable } from 'inversify';
import { DataType, IMetaProperty, mapToDevexpressType } from '../meta';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IFormatStringService } from '../api';
import { formatDate, formatNumber } from 'devextreme/localization';
import { parseTimeSpan, recalculateAsUtc } from '../util';
import { IMaskFormatService, IMaskFormatServiceSymbol, IStringMaskingService, IStringMaskingServiceSymbol } from './masks';
import { IValueFormatter } from './IValueFormatter';

@injectable()
export class ValueFormatter implements IValueFormatter {
  constructor(
    @inject(QuinoCoreServiceSymbols.IFormatStringService) private readonly formatStringService: IFormatStringService,
    @inject(IMaskFormatServiceSymbol) private readonly maskFormatService: IMaskFormatService,
    @inject(IStringMaskingServiceSymbol) private readonly stringMaskingService: IStringMaskingService
  ) {}

  formatValue(property: IMetaProperty, rawValue: any): string {
    if (rawValue === '' || rawValue == null) {
      return '';
    }

    const devExDataType = mapToDevexpressType(property.dataType);
    const format = this.formatStringService.get(property) ?? '';
    const isString = typeof rawValue === 'string';

    switch (devExDataType) {
      case 'number':
        return formatNumber(rawValue, format);
      case 'date':
      case 'datetime':
        return isString || rawValue instanceof Date ? formatDate(recalculateAsUtc(rawValue, property.dataType), format) : '';
      case 'object':
        return `[Object ${property.dataType.toString()}]`;
      default:
        if (property.dataType === DataType.TimeSpan) {
          return parseTimeSpan(rawValue);
        } else if (isString || rawValue.toString != null) {
          const stringValue = isString ? rawValue : rawValue.toString();
          const mask = this.maskFormatService.get(property) ?? '';
          return mask != null && mask != '' ? this.stringMaskingService.getMaskedString(stringValue, mask) : stringValue;
        } else {
          return JSON.stringify(rawValue);
        }
    }
  }
}
