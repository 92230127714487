import React, { PropsWithChildren } from 'react';

export interface IQuinoCustomFormBoxProps {
  title?: string;
}

export function QuinoCustomFormBox(props: PropsWithChildren<IQuinoCustomFormBoxProps>) {
  return (
    <div className={'dx-form-group-with-caption'}>
      {props.title && <span className={'dx-form-group-caption'}>{props.title}</span>}
      <div className={'dx-form-group-content quino-form-group-content'}>{props.children}</div>
    </div>
  );
}
