import React, { useState } from 'react';
import { QuinoLabeled } from '../QuinoLabeled';
import { QuinoMetaClassSelector, QuinoMetaPropertySelector } from '../QuinoMetaControls';
import { IMetaClass, IMetaProperty } from '@quino/core';

export function QuinoModelExplorer() {
  const [metaClass, setMetaClass] = useState<IMetaClass | null>(null);
  const [metaProperty, setMetaProperty] = useState<IMetaProperty | null>(null);

  return (
    <QuinoLabeled label={'Model Explorer'}>
      <div className={'quino-model-explorer'}>
        <QuinoMetaClassSelector onSelect={setMetaClass} value={metaClass?.name} />
        {metaClass && (
          <QuinoMetaPropertySelector
            metaClass={metaClass.name}
            onSelect={setMetaProperty}
            excludeActionProperties={false}
            excludeNonNumericProperties={false}
            excludeNonValueListRelations={false}
          />
        )}
      </div>

      <div className={'quino-model-explorer-detail'}>
        <pre>{metaProperty ? JSON.stringify(metaProperty, undefined, 2) : metaClass && JSON.stringify(metaClass, undefined, 2)}</pre>
      </div>
    </QuinoLabeled>
  );
}
