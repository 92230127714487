export enum QuinoLabeledLabelPosition {
  Left,
  Top
}

export type QuinoLabelHint = 'All' | 'TooltipOnly' | 'None';

export interface ILabelSettings {
  alignment: QuinoLabeledLabelPosition;
  hintType: QuinoLabelHint;
}

export const defaultLabelSettings: ILabelSettings = {
  alignment: QuinoLabeledLabelPosition.Left,
  hintType: 'All'
};
