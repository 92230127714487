import { IErrorMapper } from './IErrorMapper';
import { ITranslationService } from '../../lang';
import { INotification } from '../../notification';

export class DefaultErrorMapper implements IErrorMapper {
  constructor(protected readonly translationService: ITranslationService) {}

  canHandleError(error: Error): boolean {
    return false;
  }

  get errorName() {
    return 'Error';
  }

  formatError(error: Error): INotification {
    return {
      message: this.getErrorName(error),
      messageDetails: this.getErrorMessage(error)
    };
  }

  getErrorName(error: Error): string {
    return this.translationService.translate(error.name);
  }

  getErrorMessage(error: Error): string {
    return this.translationService.translate(error.message);
  }
}
