import { IMetaProperty } from '@quino/core';
import DataSource from 'devextreme/data/data_source';

export const IValueListToolsSymbol = Symbol.for('IValueListTools');

/**
 * Service to deal with value lists.
 */
export interface IValueListTools {
  /**
   * Get the related value list objects. If no value list aspect is present this will throws.
   * @param relation - the relation to query for.
   */
  fetchRelated(relation: IMetaProperty): Promise<DataSource>;
}
