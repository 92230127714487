import { getAspectOrDefault, IMetaProperty, IOptionValue, IValueListAspect } from '../../meta';
import { IFieldValidationResult } from '../IFieldValidationResult';
import { FieldValidationErrorCodes } from '../FieldValidationErrorCodes';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IValidationMessageProvider } from '../IValidationMessageProvider';
import { IFieldValidator } from '../IFieldValidator';
import { IValidationContext } from '../IValidationContext';

@injectable()
export class OptionValuesValidator implements IFieldValidator {
  constructor(@inject(QuinoCoreServiceSymbols.IValidationMessageProvider) private readonly msgProvider: IValidationMessageProvider) {}

  validate(field: IMetaProperty, value: any, _context: IValidationContext): IFieldValidationResult {
    const valueList = getAspectOrDefault<IValueListAspect>(field, 'ValueList');

    if (valueList == null || field.controlName !== 'LookupEdit') {
      return {};
    }

    if (value == null || valueList.options.find((option: IOptionValue) => option.Value === value)) {
      return {};
    }

    return {
      fieldErrors: [
        {
          fieldName: field.name,
          errorCode: FieldValidationErrorCodes.OPTIONVALUES_NOT_FOUND,
          errorMessage: this.msgProvider.getOptionValueError()
        }
      ]
    };
  }
}
