import { IVersionInfo } from './IVersionInfo';

/**
 * Version info service symbol.
 */
export const IVersionInfoServiceSymbol = Symbol.for('IVersionInfoService');

export interface IVersionInfoService {
  /**
   * Returns the version info
   */
  getVersionInfo(): Promise<IVersionInfo>;
}
