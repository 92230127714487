import { IListBookmark } from '../bookmarks';

export const IPagingContextServiceSymbol = Symbol.for('IPagingContextService');

/**
 * The paging context service holds the list bookmark for the object pager.
 */
export interface IPagingContextService {
  /**
   * Gets the list bookmark or undefined.
   */
  get: () => IListBookmark | undefined;

  /**
   * Sets the list bookmark.
   * @param listBookmark The list bookmark to set.
   */
  set: (listBookmark: IListBookmark) => void;

  /**
   * Resets the list bookmark to not leave dangling bookmarks.
   */
  reset: () => void;
}
