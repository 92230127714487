import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IQuinoMetaPanelExtensionProps } from './QuinoMetaPanel';
import { Box, Item } from 'devextreme-react/box';
import { QuinoMetaPanelSaveButton } from './QuinoMetaPanelSaveButton';
import { QuinoMetaPanelResetButton } from './QuinoMetaPanelResetButton';

export function QuinoMetaPanelFooter(props: PropsWithChildren<IQuinoMetaPanelExtensionProps>) {
  return (
    <Box direction={'row'} width={'100%'} crossAlign={'end'} className={'quino-meta-panel-footer'} align={'end'}>
      <Item key={'reset_item'} ratio={0} baseSize={150}>
        <QuinoMetaPanelResetButton {...props} />
      </Item>
      <Item key={'spacer_item'} ratio={0} baseSize={20} />
      <Item key={'save_item'} ratio={0} baseSize={150}>
        <QuinoMetaPanelSaveButton {...props} />
      </Item>
    </Box>
  );
}
