import * as React from 'react';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { QuinoMetaDataGrid } from './QuinoMetaDataGrid';
import { IQuinoDataGridProps } from './QuinoDataGrid';
import { QuinoUIServiceSymbols, useService } from '../../ioc';
import { IListBookmark, ListBookmark, ObjectBookmark } from '../../bookmarks';
import {
  getMetaRelation,
  IGenericObject,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILogger,
  IMetadataTree,
  IMetaLayout,
  LayoutType,
  NewGenericObjectPrimaryKey,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { IQuinoComponentProps } from '../Types';
import { useRelatedSource } from '../Util';
import { useMetadata } from '../../settings';
import { IQuinoDataGridConfigurationService } from './IQuinoDataGridConfigurationService';
import DataSource from 'devextreme/data/data_source';
import { useOnDependencyChanged } from '../Util/MetaElementHooks';
import { QuinoLayoutSelector } from '../QuinoMetaControls';
import noop from 'lodash/noop';
import { IPagingContextService, IPagingContextServiceSymbol } from '../../context';

export interface IQuinoInlineDataGridProps {
  customDrillDown: (target: IGenericObject) => void;
}

export function QuinoInlineDataGrid(
  props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark> & Partial<IQuinoDataGridProps> & Partial<IQuinoInlineDataGridProps>>
) {
  const { element, bookmark, actions, customDrillDown } = props;
  const metaRelation = getMetaRelation(element);
  const { visible } = useMetadata(metaRelation, bookmark.genericObject);
  const { targetClass, caption } = metaRelation;
  const metaDataGridConfigurationService = useService<IQuinoDataGridConfigurationService>(QuinoUIServiceSymbols.IQuinoDataGridConfigurationService);
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const metadataService = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const pagingContextService = useService<IPagingContextService>(IPagingContextServiceSymbol);
  const [layout, setLayout] = useState<IMetaLayout>(
    metadataService.resolveSingle({ metaClass: targetClass, element: element, type: LayoutType.List, data: bookmark.genericObject })
  );
  const config = metaDataGridConfigurationService.getInlineGridConfiguration(layout, metaRelation);
  const listBookmark = useMemo<IListBookmark>(() => {
    const newBookmark = new ListBookmark(metadataTree.getClass(targetClass), layout, []);
    newBookmark.options.storageKey = `${newBookmark.options.storageKey}_${element.name}`;
    newBookmark.parentBookmark = bookmark;

    return newBookmark;
  }, [metadataTree, targetClass, layout, element.name, bookmark]);

  const dataSource = useRelatedSource(metaRelation, bookmark.genericObject, layout);

  if (bookmark.genericObject.primaryKey != null && bookmark.genericObject.primaryKey !== NewGenericObjectPrimaryKey) {
    listBookmark.dataSource = dataSource;
  } else {
    listBookmark.dataSource = new DataSource([]);
  }
  useOnDependencyChanged(element, bookmark);
  useEffect(() => {
    const symbol = bookmark.subscribe((event) => {
      if (event.type === 'reload') {
        if (listBookmark.dataSource != null) {
          listBookmark.dataSource.reload().catch((e) => {
            if (e === 'canceled') {
              logger.logWarn(`Ignoring '${e}' data source error while refreshing QuinoInlineDataGrid`);
            } else {
              logger.logError(`Error while refreshing QuinoInlineDataGrid ${JSON.stringify(e)}`);
            }
          });
        }
      }
    });
    return () => {
      bookmark.unsubscribe(symbol);
    };
  }, [bookmark, listBookmark.dataSource, logger, logger.logError]);

  const getTargetClassPluralCaption = (targetClassName: string) => {
    const targetClass = metadataTree.getClass(targetClassName);
    if (targetClass) {
      return targetClass.pluralCaption;
    }
    return '';
  };

  return (
    <div className={'quino-inline-datagrid-wrapper'}>
      <div className={'quino-inline-datagrid-caption-container'}>
        {config.showInlineCaption && (
          <span className={'dx-form-group-caption quino-inline-datagrid-caption'}>
            {caption.length > 0 ? caption : getTargetClassPluralCaption(targetClass)}
          </span>
        )}
        <QuinoLayoutSelector
          key={'quino-inline-datagrid-layout-selector-' + metaRelation.name}
          hideIfNoOptions={true}
          value={layout.name}
          metaClass={targetClass}
          layoutType={LayoutType.List}
          hidden={!config.showInlineLayoutSelector}
          onSelect={(layoutName) => {
            const foundLayout = metadataTree.getClass(targetClass).layouts.find((x) => x.name === layoutName);
            foundLayout && setLayout(foundLayout);
          }}
        />
      </div>
      <QuinoMetaDataGrid
        key={'quino-inline-datagrid-' + metaRelation.name}
        bookmark={listBookmark}
        visible={visible}
        drilldown={(...args) => {
          pagingContextService.set(listBookmark);

          return (customDrillDown ? customDrillDown : actions ? actions.drilldown : noop)(...args);
        }}
        metaRelation={metaRelation}
        gridProperties={{
          className: 'quino-inline-data-grid',
          ...config,

          // TODO: Who is using those explicit props?
          drillDownMode: props.drillDownMode ? props.drillDownMode : config != null ? config.drillDownMode : 'None',
          showDelete: props.showDelete ? props.showDelete : config != null ? config.showDelete : false,
          selectionMode: props.selectionMode ? props.selectionMode : config != null ? config.selectionMode : 'Multiple'
        }}
      />
    </div>
  );
}
