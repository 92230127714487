import { inject, injectable } from 'inversify';
import { ILayoutActionsService, IListRowActions, IListSelectionActions } from './ILayoutActionsService';
import { getAspectOrDefault, IMetaAction, IMetaLayout, isIMetaAction } from '@quino/core';
import { IQuinoAction } from './IQuinoAction';
import { ClassActionAspectIdentifier, IClassActionAspect, IListActionAspect, ListActionAspectIdentifier } from '../aspects';
import { IQuinoActionFactory, IQuinoActionFactorySymbol } from './IQuinoActionFactory';
import { IBookmark, IRefreshableBookmark, isIObjectBookmark } from '../bookmarks';

@injectable()
export class LayoutActionService implements ILayoutActionsService {
  constructor(@inject(IQuinoActionFactorySymbol) private readonly actionFactory: IQuinoActionFactory) {}

  getSidebarActions(bookmark: IBookmark): IQuinoAction[] {
    if (isIObjectBookmark(bookmark)) {
      const metaActions = this.getLayoutActions(bookmark.layout);
      return metaActions.length > 0
        ? metaActions
            .filter((a) => getAspectOrDefault<IClassActionAspect>(a, ClassActionAspectIdentifier) == null)
            .map((a) => this.actionFactory.createAction(a))
        : [];
    }

    return [];
  }

  getListSelectionActions(layout: IMetaLayout): IListSelectionActions {
    const normalActions: IMetaAction[] = [];
    const overflowActions: IMetaAction[] = [];

    this.getLayoutActions(layout).forEach((a) => {
      const listActionAspect = this.getListActionAspect(a);
      if (!listActionAspect) {
        normalActions.push(a);
      } else if (listActionAspect.showInListSelection) {
        listActionAspect.isOverflowByDefault ? overflowActions.push(a) : normalActions.push(a);
      }
    });

    return { normalActions: normalActions, overflowActions: overflowActions };
  }

  getListRowActions(layout: IMetaLayout, bookmarkToRefresh?: IRefreshableBookmark): IListRowActions {
    const normalActions: IQuinoAction[] = [];
    const overflowActions: IQuinoAction[] = [];

    this.getLayoutActions(layout).forEach((a) => {
      const listActionAspect = this.getListActionAspect(a);
      if (listActionAspect?.showInListRow) {
        const action = this.actionFactory.createAction(a);
        if (bookmarkToRefresh) {
          const originalOnClick = action.onClick;
          action.onClick = (args) =>
            originalOnClick({
              source: args.source,
              bookmarkToRefresh: bookmarkToRefresh
            });
        }
        listActionAspect.isOverflowByDefault ? overflowActions.push(action) : normalActions.push(action);
      }
    });

    return { normalActions: normalActions, overflowActions: overflowActions };
  }

  private readonly getLayoutActions = (layout: IMetaLayout): IMetaAction[] => {
    return layout.elements.filter((p) => isIMetaAction(p)).map((a) => a as unknown as IMetaAction);
  };

  private readonly getListActionAspect = (metaAction: IMetaAction): IListActionAspect | null => {
    return getAspectOrDefault<IListActionAspect>(metaAction, ListActionAspectIdentifier);
  };
}
