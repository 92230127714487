/**
 * The symbol for the favorite settings.
 */
export const IFavoriteLayoutServiceSymbol = Symbol.for('IFavoriteLayoutService');

export interface ILayoutFavorite {
  /**
   * The class name. For custom bookmarks: the bookmark name.
   */
  className: string;

  /**
   * The name of the favorite layout
   */
  layoutName: string;
}

/**
 * The favorite settings handler
 */
export interface IFavoriteLayoutService {
  /**
   * Get the name of the favorite layout for a given class
   * @param className
   */
  getFavoriteLayoutName(className: string): string | undefined;

  /**
   * Set the favorite layout for a given class
   * @param className
   * @param layoutName
   */
  setFavoriteLayout(className: string, layoutName: string): Promise<void>;

  /**
   * Removes the favorite setting for a given class
   * @param className
   */
  clearFavoriteLayout(className: string): Promise<void>;

  /**
   * Loads the initial favorite data
   */
  loadInitialFavorites(): Promise<void>;
}
