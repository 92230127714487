import React from 'react';
import {
  getResponsiveClassName,
  IQuinoBookmarkAction,
  IQuinoMetaPanelExtensionProps,
  QuinoInfoBar,
  TResponsiveMode,
  useResponsiveMode
} from '@quino/ui';
import { CUITitle } from './CUITitle';
import { CUIBreadcrumbs } from './CUIBreadcrumbs';
import { CUIObjectPaging } from './CUIObjectPaging';
import { CUIBookmarkActions } from './CUIBookmarkActions';

export interface ICUIStickyHeaderProps extends Partial<IQuinoMetaPanelExtensionProps> {
  icon?: string;
  customBookmarkActions?: IQuinoBookmarkAction[];
}

export function CUIStickyHeader(props: ICUIStickyHeaderProps) {
  const { bookmark, icon, customBookmarkActions } = props;
  const responsiveMode = useResponsiveMode();

  return (
    <>
      {bookmark && (
        <div className={getResponsiveClassName('quino-ecui-sticky-header', responsiveMode)}>
          {responsiveMode !== TResponsiveMode.Phone && (
            <div className='quino-ecui-sticky-header-navigation-row'>
              <CUIBreadcrumbs key={'breadcrumbs'} />
              <CUIObjectPaging key={'paging'} />
            </div>
          )}
          <div className={getResponsiveClassName('quino-ecui-sticky-header-object-row', responsiveMode)}>
            <CUITitle key={'title'} bookmark={bookmark} icon={icon} />
            <CUIBookmarkActions bookmark={bookmark} customBookmarkActions={customBookmarkActions} />
          </div>
          <QuinoInfoBar isDefault={true} hasBottomMargin={true} />
        </div>
      )}
    </>
  );
}
