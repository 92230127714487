import { IMetaAspect } from '@quino/core';
import { IFileUploadSettings } from '../settings';

/**
 * The identifier for retrieving a file upload configuration aspect.
 */
export const FileUploadOptionsAspectIdentifier = 'FileUploadOptionsAspect';

/**
 * A configuration aspect for the file upload.
 */
export interface IFileUploadOptionsAspect extends IMetaAspect {
  /**
   * The options available.
   */
  options?: IFileUploadSettings;

  ownerClass?: string;
  context?: string;
  maxFiles?: number;
  minFiles?: number;
}
