import { IBookmarkSerializer } from './IBookmarkSerializer';
import { IBookmark, IBookmarkFactory, IListBookmark, isIListBookmark } from '../bookmarks';
import {
  ILayoutResolver,
  ILayoutResolverSymbol,
  IMetadataTree,
  IUrlHelper,
  IVisibleCalculator,
  LayoutType,
  LookupNormalizer,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../data';
import { QuinoUIServiceSymbols } from '../ioc';
import DataSource from 'devextreme/data/data_source';
import { StateFullBookmarkSerializer } from './StateFullBookmarkSerializer';
import { getBaseUrl, getPathParts, pathContainsIdentifierSequence, removeSlashes } from './UrlUtilities';

export const ListBookmarkSerializerSymbol = Symbol.for('ListBookmarkSerializer');

@injectable()
export class ListBookmarkSerializer extends StateFullBookmarkSerializer implements IBookmarkSerializer<IListBookmark> {
  constructor(
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(ILayoutResolverSymbol) private readonly layoutResolver: ILayoutResolver,
    @inject(IODataSourceFactorySymbol) private readonly odataFactory: IODataSourceFactory,
    @inject(QuinoCoreServiceSymbols.IVisibleCalculator) private readonly visibleCalculator: IVisibleCalculator,
    @inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory,
    @inject(QuinoCoreServiceSymbols.IUrlHelper) urlHelper: IUrlHelper
  ) {
    super(urlHelper);
  }

  canSerialize(bookmark: IBookmark): boolean {
    return isIListBookmark(bookmark);
  }

  canDeserialize(path: string): boolean {
    return pathContainsIdentifierSequence(path, 'cui', 'l');
  }

  async deserialize(path: string): Promise<IListBookmark> {
    const parts = getPathParts(path);
    const cuiIndex = parts.indexOf('cui');

    if (cuiIndex <= -1) {
      return Promise.reject();
    }

    const metaClass = parts[cuiIndex + 2];
    let resolvedLayout = this.layoutResolver.resolveSingle({ metaClass: metaClass, type: LayoutType.List });
    let source: DataSource | undefined;
    if (parts.length > 2) {
      const layout = parts[cuiIndex + 3];
      const metaLayout = this.metadataTree
        .getLayouts(metaClass)
        .find((x) => LookupNormalizer.Normalize(x.name) === LookupNormalizer.Normalize(layout) && this.visibleCalculator.calculate(x, {}));
      if (metaLayout) {
        resolvedLayout = metaLayout;
      }
    }

    source = this.odataFactory.create(resolvedLayout, metaClass);
    const bookmark = await this.bookmarkFactory.createList(metaClass, resolvedLayout, source);

    super.deserializeState(path, bookmark);

    return bookmark;
  }

  serialize(bookmark: IListBookmark): string {
    return new URL(
      `${removeSlashes(getBaseUrl())}/cui/l/${bookmark.metaClass.name}/${bookmark.layout.name}${this.serializeState(bookmark)}`,
      document.location.origin
    ).toString();
  }
}
