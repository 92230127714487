import { IRequestDecorator } from './IRequestDecorator';
import { IQuinoRequestOptions } from './IQuinoRequestOptions';
import { inject, injectable } from 'inversify';
import { IScopingService, ScopingServiceSymbol } from '../scoping';

@injectable()
export class ScopeDecorator implements IRequestDecorator {
  constructor(@inject(ScopingServiceSymbol) private readonly scopingService: IScopingService) {}

  decorate(request: Request, options?: IQuinoRequestOptions): void {
    if (options && options.ignoreScope) {
      return;
    }

    const scope = this.scopingService.getScope();
    if (scope) {
      request.headers.set('Scope', scope);
    }
  }
}
