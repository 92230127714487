/**
 * User info service symbol to retrieve the service from the IoC container.
 */
export const IUserInfoServiceSymbol = Symbol.for('IUserInfoService');

/**
 * User info service which extracts information for the current user based on the user-info payload.
 */
export interface IUserInfoService {
  /***
   * Whether the current user is in the role.
   * @param role - the role to check for.
   */
  isInRole(role: string): boolean;
}
