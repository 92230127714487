import React, { PropsWithChildren } from 'react';
import { IMetaProperty } from '@quino/core';
import { QuinoLabeled } from '../QuinoLabeled';
import { QuinoMultiSelectBox } from './QuinoMultiSelectBox';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { useRerenderOnChanges, useValidation } from '../Util';
import { useLabelSettings, useMetadata } from '../../settings';

export function QuinoMultiSelectBoxLabeled(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const { bookmark } = props;
  const metaProperty = props.element as IMetaProperty;
  const { visible, required } = useMetadata(metaProperty, bookmark.genericObject);
  const [, errorMessages] = useValidation(bookmark, metaProperty);
  const labelSettings = useLabelSettings(props.element);

  useRerenderOnChanges(bookmark, metaProperty);

  return (
    <QuinoLabeled
      visible={visible}
      required={required}
      description={metaProperty.description}
      instruction={metaProperty.instruction}
      label={metaProperty.caption}
      labelSettings={labelSettings}
      errorMessages={errorMessages}
      className={'quino-input-fields-max-width'}>
      <QuinoMultiSelectBox bookmark={bookmark} element={metaProperty} />
    </QuinoLabeled>
  );
}
