import { IMetaAspect } from '@quino/core';

/**
 * The identifier for retrieving a rich text editor configuration aspect.
 */
export const RichTextEditorConfigurationAspectIdentifier = 'RichTextEditorConfiguration';

export type HTLMEditorValueType = 'html' | 'markdown';

/**
 * Aspect containing configuration info for a rich text editor
 */
export interface IRichTextEditorConfigurationAspect extends IMetaAspect {
  configuration: {
    enableSize?: boolean;
    enableFont?: boolean;
    enableHeader?: boolean;
    enableBold?: boolean;
    enableItalic?: boolean;
    enableStrike?: boolean;
    enableUnderline?: boolean;
    enableSubscript?: boolean;
    enableSuperscript?: boolean;
    enableColor?: boolean;
    enableBackground?: boolean;
    enableAlignLeft?: boolean;
    enableAlignCenter?: boolean;
    enableAlignRight?: boolean;
    enableAlignJustify?: boolean;
    enableOrderedList?: boolean;
    enableBulletList?: boolean;
    enableLink?: boolean;
    enableImage?: boolean;
    enableCodeBlock?: boolean;
    enableBlockquote?: boolean;
    enableInsertTable?: boolean;
    enableDeleteTable?: boolean;
    enableInsertRowAbove?: boolean;
    enableInsertRowBelow?: boolean;
    enableDeleteRow?: boolean;
    enableInsertColumnLeft?: boolean;
    enableInsertColumnRight?: boolean;
    enableDeleteColumn?: boolean;
    enableClear?: boolean;
    valueType?: HTLMEditorValueType;
  };
}
