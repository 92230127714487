import { IStorageService } from './IStorageSevice';
import { inject, injectable } from 'inversify';
import { LookupNormalizer } from './LookupNormalizer';
import { QuinoCoreServiceSymbols } from '../ioc';

/**
 * Symbol for the LocalStorageService
 */
export const LocalStorageServiceSymbol = Symbol.for('LocalStorageServiceSymbol');

@injectable()
export class LocalStorageService implements IStorageService {
  constructor(@inject(QuinoCoreServiceSymbols.IStorage) private readonly storage: Storage) {}

  async get<TPayload>(key: string): Promise<TPayload> {
    const storageItem = this.storage.getItem(LookupNormalizer.Normalize(key));

    return storageItem && JSON.parse(storageItem);
  }

  async set<TPayload>(key: string, value: TPayload): Promise<void> {
    this.storage.setItem(LookupNormalizer.Normalize(key), JSON.stringify(value));
  }

  async delete(key: string): Promise<void> {
    this.storage.removeItem(LookupNormalizer.Normalize(key));
  }

  async clear(prefix?: string): Promise<void> {
    if (prefix) {
      this.storage['indexedArray'].forEach((obj: any) => {
        if (LookupNormalizer.Normalize(obj.key).startsWith(LookupNormalizer.Normalize(prefix))) {
          void this.delete(obj.key);
        }
      });
    } else {
      this.storage.clear();
    }
  }
}
