import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression, isExpression } from './IExpression';
import { inject, injectable } from 'inversify';
import { isTextGroupExpression } from './ITextGroupExpression';
import { QuinoCoreServiceSymbols } from '../ioc';

export const TextGroupExpressionEvaluatorSymbol = Symbol.for('TextGroupExpressionEvaluator');

@injectable()
export class TextGroupExpressionEvaluator implements IExpressionEvaluator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator) {}

  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (isExpression(expression) && isTextGroupExpression(expression)) {
      let result = '';

      for (let i = 0; i <= expression.Count - 1; i++) {
        const innerExp = expression['Element' + i] as IExpression;
        const innerExpressionResult = this.evaluator.evaluate(innerExp, context);

        if (innerExpressionResult === undefined) {
          // Ensure we don't return 'undefined' as a result. Instead, rely on the fallback from the server.
          // @ts-ignore
          return undefined;
        }

        result += this.evaluator.evaluate(innerExp, context);
      }

      // @ts-ignore
      return result;
    }

    // @ts-ignore
    return expression;
  }
}
