export const IResponsivityBreakpointServiceSymbol = Symbol.for('IResponsivityBreakpointService');

/**
 * Service used to get breakpoint values
 */
export interface IResponsivityBreakpointService {
  /**
   * Get the breakpoint for phone mode
   */
  getPhoneBreakpoint(): number;

  /**
   * Get the breakpoint for tablet mode
   */
  getTabletBreakpoint(): number;
}
