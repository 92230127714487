import { IMetaAspect } from '@quino/core';

/**
 * The identifier for retrieving a bookmark actions aspect.
 */
export const BookmarkActionsAspectIdentifier = 'BookmarkActionsAspect';

/**
 * Aspect containing info about whether to show the different standard bookmark actions
 */
export interface IBookmarkActionsAspect extends IMetaAspect {
  /**
   * Whether to hide the save button
   */
  hideSave?: boolean;

  /**
   * Whether to hide the delete button
   */
  hideDelete?: boolean;

  /**
   * Whether to hide the create button
   */
  hideCreate?: boolean;

  /**
   * Whether to hide the refresh button
   */
  hideRefresh?: boolean;

  /**
   * Custom translation for the save button
   */
  customSaveTranslationKey?: string;

  /**
   * Custom translation for the delete button
   */
  customDeleteTranslationKey?: string;

  /**
   * Custom translation for the create button
   */
  customCreateTranslationKey?: string;

  /**
   * Custom translation for the refresh button
   */
  customRefreshTranslationKey?: string;

  /**
   * Custom translation for the save and new button
   */
  customSaveAndNewTranslationKey?: string;

  /**
   * Custom icon for the save button
   */
  customSaveIcon?: string;
}
