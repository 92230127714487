import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression, isExpression } from './IExpression';
import { injectable } from 'inversify';
import { isCreateGuidExpression } from './ICreateGuidExpression';

export const CreateGuidExpressionEvaluatorSymbol = Symbol.for('CreateGuidExpressionEvaluator');

@injectable()
export class CreateGuidExpressionEvaluator implements IExpressionEvaluator {
  evaluate<TValue>(expression: IExpression | TValue, _context: any): TValue {
    if (isExpression(expression) && isCreateGuidExpression(expression)) {
      if (expression.Count === 1) {
        return expression['Element0']['InnerExpression']['value'];
      }
    }

    // @ts-ignore
    return expression;
  }
}
