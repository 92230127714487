import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for retrieving a icon aspect.
 */
export const IconAspectIdentifier = 'IconAspect';

/**
 * Icon aspect containing the identifier for the icon to use.
 */
export interface IIconAspect extends IMetaAspect {
  icon: string;
}
