import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for retrieving a search mode aspect.
 */
export const SearchModeAspectIdentifier = 'SearchMode';

/**
 * Search mode aspect containing the if the search mode should be 'contains'
 */
export interface ISearchModeAspect extends IMetaAspect {
  useContains: boolean;
}
