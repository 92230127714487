import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IBookmarkFactory,
  INavigationService,
  INavigationServiceSymbol,
  IODataSourceFactory,
  IODataSourceFactorySymbol,
  IPendingChangesService,
  IPendingChangesServiceSymbol,
  IQuinoContextMenuItem,
  QuinoContextMenuButton,
  QuinoUIServiceSymbols,
  useOnNavigation,
  useService
} from '@quino/ui';
import {
  IAuthenticationFeedback,
  IAuthenticationService,
  IGenericObject,
  ILanguageMetadata,
  ILanguageService,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILogger,
  ITranslationService,
  ITranslationServiceSymbol,
  LayoutType,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { useBrowserZoom } from '../../util/useBrowserZoom';

export const CUIUserMenu = (props: {
  showSettings?: boolean;
  showChangePassword?: boolean;
  showZoomLevel?: boolean;
  additionalDataSource?: IQuinoContextMenuItem[];
}) => {
  const authenticationService = useService<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService);
  const authenticationFeedback = useService<IAuthenticationFeedback>(QuinoCoreServiceSymbols.IAuthenticationFeedback);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const dataSourceFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const pendingChangesService = useService<IPendingChangesService>(IPendingChangesServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const languageService = useService<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);

  const [availableLanguages, setAvailableLanguages] = useState<ILanguageMetadata[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<string>('');
  const [currentLanguageDisplayLanguageName, setCurrentLanguageDisplayLanguageName] = useState<string>('');

  const { browserZoomEnabled, currentZoomLevel, zoomLevelDataSource } = useBrowserZoom();

  const bookmark = useOnNavigation();
  const userInfo = authenticationService.getUserInfo();
  const additionalDataSource = props.additionalDataSource ?? [];

  const userInitials = useMemo(() => {
    let initials = '';

    const first = userInfo.Firstname?.charAt(0);
    initials += first ? first : '';

    const last = userInfo.Lastname?.charAt(0);
    initials += last ? last : '';

    if (!initials) {
      initials = userInfo.unique_name.charAt(0);
    }

    return initials.toUpperCase();
  }, [userInfo]);

  const fullUserName = useMemo(() => {
    let fullName = '';

    const first = userInfo.Firstname;
    const last = userInfo.Lastname;

    fullName += first ? first.charAt(0).toUpperCase() + first.slice(1) + ' ' : '';
    fullName += last ? last.charAt(0).toUpperCase() + last.slice(1) : '';

    if (!fullName) {
      fullName = userInfo.unique_name.charAt(0).toUpperCase() + userInfo.unique_name.slice(1);
    }

    return fullName;
  }, [userInfo]);

  const onSettingsClick = useCallback(() => {
    dataSourceFactory
      .fetch('1', layoutResolver.resolveSingle({ metaClass: 'CurrentUser', type: LayoutType.Detail }), 'CurrentUser')
      .then((object: IGenericObject) => {
        const objectBookmark = bookmarkFactory.createObject(object);
        navigationService.push(objectBookmark).catch(logger.logError);
      })
      .catch(logger.logError);
  }, [dataSourceFactory, layoutResolver, logger, bookmarkFactory, navigationService]);

  const onLogOutClick = useCallback(async () => {
    const canLeave = await pendingChangesService.getLeavePermission(bookmark, undefined, true);
    if (canLeave) {
      authenticationService
        .logoutAsync()
        .then(() => document.location.assign(document.location.origin))
        .catch(logger.logError);
    }
  }, [authenticationService, bookmark, logger, pendingChangesService]);

  // Language Switcher

  useEffect(() => {
    languageService
      .getLanguageListAsync()
      .then((list) => setAvailableLanguages(list))
      .catch((e) => logger.logError(e));

    languageService
      .getCurrentLanguageAsync()
      .then((language) => {
        setCurrentLanguage(language);
      })
      .catch((e) => logger.logError(e));
  }, [languageService, logger]);

  useEffect(() => {
    const foundLanguage = availableLanguages.find((value) => value.name.startsWith(currentLanguage));
    if (foundLanguage) {
      setCurrentLanguageDisplayLanguageName(foundLanguage.caption);
    }
  }, [availableLanguages, currentLanguage]);

  const languageSwitcherDataSource = useMemo(() => {
    return availableLanguages.map((item) => {
      return {
        text: item.caption,
        onItemClick: async () =>
          languageService
            .setCurrentLanguageAsync(item.name)
            .then(() => {
              setCurrentLanguage(item.name);
            })
            .catch((e) => logger.logError(e)),
        isChecked: item.name.startsWith(currentLanguage),
        languageName: item.name
      };
    });
  }, [availableLanguages, currentLanguage, languageService, logger]);

  return (
    <QuinoContextMenuButton
      buttonId={'cui_user_menu_button'}
      visible={true}
      buttonStylingMode={'outlined'}
      buttonText={userInitials}
      buttonClassname={'quino-ecui-user-menu-button'}
      buttonHintKey={translationService.translate('Header.UserMenu.Hint', { fullUserName })}
      position={{ at: 'right bottom', my: 'right top' }}
      submenuDirection={currentZoomLevel.zoom > 100 ? 'left' : undefined}
      dataSource={[
        {
          text: fullUserName,
          disabled: true,
          className: 'quino-context-menu-title'
        },
        {
          text: currentLanguageDisplayLanguageName,
          icon: 'material-icons-outlined language',
          items: availableLanguages.length > 1 ? languageSwitcherDataSource : undefined,
          beginGroup: true,
          disabled: availableLanguages.length == 1
        },
        {
          text: currentZoomLevel.text,
          icon: 'material-icons-outlined zoom_in',
          items: zoomLevelDataSource,
          visible: browserZoomEnabled && (props.showZoomLevel != null ? props.showZoomLevel : true)
        },
        {
          text: translationService.translate('Header.UserMenu.Settings'),
          icon: 'material-icons-outlined tune',
          onItemClick: onSettingsClick,
          visible: props.showSettings != null ? props.showSettings : true
        },
        {
          text: translationService.translate('Header.UserMenu.ChangePassword'),
          icon: 'material-icons-outlined lock',
          visible: props.showChangePassword != null ? props.showChangePassword : true,
          onItemClick: authenticationFeedback.requestPasswordChange
        },
        {
          text: translationService.translate('Header.UserMenu.LogOut'),
          icon: 'material-icons-outlined power_settings_new',
          onItemClick: onLogOutClick
        },
        ...additionalDataSource
      ]}
    />
  );
};
