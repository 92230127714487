import * as React from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import { IQuinoEditorProps } from '../Types';
import { useLabelSettings, useMetadata } from '../../settings';
import { useMetaPropertyValueState, useValidation } from '../Util';

export function QuinoCheckBox(props: IQuinoEditorProps) {
  const { metaProperty, bookmark, propagateValue } = props;
  const { description } = metaProperty;

  const labelSettings = useLabelSettings(metaProperty);
  const { readOnly, required, enabled } = useMetadata(metaProperty, bookmark.genericObject);
  const [value, setValue] = useMetaPropertyValueState<boolean | null>(metaProperty, bookmark);

  const [isValid] = useValidation(bookmark, metaProperty);

  const onValueChange = () => {
    const newValue = value ? false : required ? true : value === false ? null : true;
    setValue(newValue);
    propagateValue && propagateValue(newValue);
  };

  return (
    <CheckBox
      className={'quino-input-fields-checkbox'}
      readOnly={readOnly != null ? readOnly : false}
      focusStateEnabled={!readOnly}
      hoverStateEnabled={!readOnly}
      activeStateEnabled={!readOnly}
      disabled={!enabled}
      hint={labelSettings.hintType === 'None' ? description : undefined}
      value={value === null ? undefined : value}
      onValueChange={onValueChange}
      isValid={isValid}
    />
  );
}
