import * as React from 'react';
import { injectable } from 'inversify';
import { IStringMaskingService } from './IStringMaskingService';
import { TextBox } from 'devextreme-react/text-box';
import ReactDOM from 'react-dom';

@injectable()
export class StringMaskingService implements IStringMaskingService {
  constructor() {
    this.wrapperElement = document.createElement('div');
    this.wrapperElement.style.width = '0';
    this.wrapperElement.style.height = '0';
    this.wrapperElement.style.position = 'absolute';
    this.wrapperElement.style.visibility = 'hidden';
    document.body.appendChild(this.wrapperElement);
    ReactDOM.render(
      <TextBox visible={false} ref={(element) => (this.textBox = element)} key={'string-masking-service-textbox'} maskChar={''} />,
      this.wrapperElement
    );
  }

  getMaskedString(unmaskedString: string, mask: string): string {
    if (unmaskedString && mask && this.textBox) {
      this.textBox.instance.instance().option({ value: unmaskedString, mask: mask });
      // @ts-ignore
      return this.textBox.instance._textValue;
    }

    return unmaskedString;
  }

  private textBox: TextBox | null;
  private readonly wrapperElement: HTMLDivElement;
}
