import { IQuinoDataGridColumnFactory } from './IQuinoDataGridColumnFactory';
import { inject, injectable } from 'inversify';
import {
  DataType,
  DrillDownAspectIdentifier,
  getAspectOrDefault,
  getElementPath,
  IBatchedRequestService,
  IBatchedRequestServiceSymbol,
  IDrillDownAspect,
  IFormatStringService,
  IGenericObject,
  IGenericObjectFactory,
  IGenericObjectFactorySymbol,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILogger,
  IMaskFormatService,
  IMaskFormatServiceSymbol,
  IMetadataTree,
  IMetaLayout,
  IMetaProperty,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  IMetaRelation,
  INotificationService,
  INotificationServiceSymbol,
  IOptionValue,
  isGenericObject,
  isIMetaAction,
  ISizingAspect,
  isMetaRelation,
  isValueList,
  ITitleCalculator,
  ITitleCalculatorSymbol,
  IValueFormatter,
  IValueFormatterSymbol,
  IValueListAspect,
  IVisibleCalculator,
  LayoutType,
  mapToDevexpressType,
  ODataUrlBuilder,
  ODataUrlBuilderSymbol,
  QuinoCoreServiceSymbols,
  recalculateAsUtc,
  SizingAspectIdentifier,
  ValueListAspectIdentifier
} from '@quino/core';
import { Column, IColumnProps } from 'devextreme-react/data-grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { QuinoCrossLink } from '../QuinoCrossLink';
import { IQuinoDataGridProps } from './QuinoDataGrid';
import dxDataGrid, { DataChange } from 'devextreme/ui/data_grid';
import ArrayStore from 'devextreme/data/array_store';
import { QuinoUIServiceSymbols, useService } from '../../ioc';
import { IQuinoDataGridFilterService } from './IQuinoDataGridFilterService';
import { CONTROL_IDENTIFIER, IComponentRegistry } from '../../rendering';
import { IBookmarkFactory, IObjectBookmark } from '../../bookmarks';
import { IComponentFactory } from '../ComponentFactory';
import { QuinoLabeled } from '../QuinoLabeled';
import { QuinoImagePickerBase } from '../QuinoImagePicker';
import { IColumnConfigurationService, IColumnConfigurationServiceSymbol } from './IColumnConfigurationService';

@injectable()
export class QuinoDataGridColumnFactory implements IQuinoDataGridColumnFactory {
  constructor(
    @inject(ILayoutResolverSymbol) private readonly layoutResolver: ILayoutResolver,
    @inject(IMetaPropertyValueServiceSymbol) private readonly metaPropertyValueService: IMetaPropertyValueService,
    @inject(QuinoCoreServiceSymbols.IVisibleCalculator) private readonly visibleCalculator: IVisibleCalculator,
    @inject(QuinoCoreServiceSymbols.IFormatStringService) private readonly formatStringService: IFormatStringService,
    @inject(IMaskFormatServiceSymbol) private readonly maskFormatService: IMaskFormatService,
    @inject(ITitleCalculatorSymbol) protected titleCalculator: ITitleCalculator,
    @inject(QuinoUIServiceSymbols.IQuinoDataGridFilterService) private readonly dataGridFilterService: IQuinoDataGridFilterService,
    @inject(QuinoUIServiceSymbols.IComponentFactory) private readonly editorFactory: IComponentFactory,
    @inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory,
    @inject(QuinoUIServiceSymbols.IComponentRegistry) private readonly componentRegistry: IComponentRegistry,
    @inject(IValueFormatterSymbol) private readonly valueFormatter: IValueFormatter,
    @inject(IColumnConfigurationServiceSymbol) private readonly columnConfigurationService: IColumnConfigurationService
  ) {}

  generate(
    property: IMetaProperty,
    _forLocalSource = true,
    dataGridProps: IQuinoDataGridProps,
    onDrilldown: (data: IGenericObject) => void,
    canEdit?: boolean,
    dxDataGrid?: dxDataGrid,
    fixedColumnsWidth?: number,
    positionFromBack?: number,
    inlineEditMode?: boolean,
    editLayout?: IMetaLayout
  ): any {
    if (isIMetaAction(property)) {
      return;
    }

    const columnConfiguration = this.columnConfigurationService.getColumnConfiguration(property);
    const isDrilldownColumn =
      dataGridProps.useDrillDownColumn && !inlineEditMode && getAspectOrDefault<IDrillDownAspect>(property, DrillDownAspectIdentifier);
    const sizingAspect = getAspectOrDefault<ISizingAspect>(property, SizingAspectIdentifier);
    let width = undefined;
    if (sizingAspect && sizingAspect.horizontalSizeMode && sizingAspect.horizontalUnits) {
      switch (sizingAspect.horizontalSizeMode) {
        case 'Absolute':
          width = sizingAspect.horizontalUnits + 'px';
          break;
        case 'Percent':
          width = `calc(${sizingAspect.horizontalUnits}% - ${fixedColumnsWidth ? (fixedColumnsWidth * sizingAspect.horizontalUnits) / 100 : 0}px)`;
      }
    }
    const isPropertyOfRelation = property.path.includes('.');
    const isValueListProperty = isValueList(property);

    const devexpressType = mapToDevexpressType(property.dataType);
    const columnIsSearchable =
      columnConfiguration.enableSearching && !isValueListProperty && (property.dataType === DataType.Text || property.dataType === DataType.Relation);
    const filterOptions = this.dataGridFilterService.getColumnFilterOptions(property);

    let columnAlignment = 'left';
    if (devexpressType === 'boolean') {
      columnAlignment = 'center';
    } else if (isValueListProperty) {
      columnAlignment = 'left';
    } else if (devexpressType === 'number' || property.dataType === DataType.TimeSpan) {
      columnAlignment = 'right';
    }

    let allowFiltering = ![DataType.Time, DataType.TimeSpan].includes(property.dataType) && columnConfiguration.enableSearching;

    const valueListAspect = getAspectOrDefault<IValueListAspect>(property, ValueListAspectIdentifier);
    const headerFilterForValueList = {
      dataSource: {
        store: new ArrayStore({ data: valueListAspect?.options ?? [] }),
        map: (dataItem: IOptionValue) => {
          return { text: dataItem.Caption, value: [property.path, '=', dataItem.Value] };
        }
      }
    };

    const drillDownCellRender = (data: any) => {
      const displayValue = data.text;
      const targetPrimaryKey = data.data.primaryKey;
      return (
        <QuinoCrossLink
          targetClass={data.data.metaClass}
          onClick={() => onDrilldown(data.data)}
          isBold={true}
          metaProperty={property}
          primaryKey={targetPrimaryKey || null}
          visible={true}
          showTitle={true}
          title={displayValue}
        />
      );
    };

    const metaRelationCellRender = (data: any) => {
      const displayValue = data.text;
      const targetPrimaryKey = this.metaPropertyValueService.getFieldValue<string>(property, data.data);
      const hasSelectionColumn = dataGridProps.selectionMode !== 'None';
      const currentColumnIndex = hasSelectionColumn ? data.columnIndex - 1 : data.columnIndex;
      const combinedFilter: any[] = (dxDataGrid && dxDataGrid.getCombinedFilter()) || [];
      const globalFilter: any[] = (combinedFilter as any).filterValue === undefined && combinedFilter.length > 2 ? combinedFilter[2] : [];
      const globalColumnFilter = globalFilter.filter ? globalFilter.filter((filter) => filter.columnIndex === currentColumnIndex) : '';
      const filterValue = (globalColumnFilter.length > 0 && globalColumnFilter[0].filterValue) || undefined;

      return targetPrimaryKey ? (
        <QuinoCrossLink
          highlightedText={filterValue}
          metaProperty={property}
          primaryKey={targetPrimaryKey}
          visible={true}
          showTitle={true}
          title={displayValue}
        />
      ) : (
        <></>
      );
    };

    const getObjectBookmark = (data: any): IObjectBookmark => {
      const className = dataGridProps.metaClass ? dataGridProps.metaClass.name : dataGridProps.className ?? '';
      const genericObject = isGenericObject(data) ? data : { primaryKey: 'null', metaClass: className, title: '', ...data };

      return this.bookmarkFactory.createObject(genericObject, editLayout);
    };

    const customValidationCallback = async (e: any): Promise<any> => {
      const bookmark = getObjectBookmark(e.data);
      const allFieldErrors = (await bookmark.validate()).fieldErrors;
      const cellErrors = allFieldErrors ? allFieldErrors.filter((err) => err.fieldName.toLowerCase() === e.column.dataField.toLowerCase()) : [];

      return cellErrors.length > 0 ? Promise.reject(cellErrors.map((err) => err.errorMessage).join('\n')) : true;
    };

    const renderTitleHeader = () => {
      const description = property.description;
      const caption = required && allowEditing ? property.caption + ' *' : property.caption;
      if (description) {
        return <QuinoLabeled className={'column-header-description'} description={description} label={caption} />;
      }
      return caption;
    };

    const readOnly = typeof property.readOnly === 'boolean' && property.readOnly;
    const enabled = typeof property.enabled === 'boolean' && property.enabled;
    const required = typeof property.required === 'boolean' && property.required;
    const allowEditing =
      !isPropertyOfRelation &&
      inlineEditMode &&
      canEdit &&
      enabled &&
      !readOnly &&
      this.componentRegistry.getListEditor(property.controlName) !== undefined;
    const baseCssClass = inlineEditMode ? 'quino-editor-cell ' + (!allowEditing ? ' is--disabled ' : '') : '';

    const editorRender = allowEditing
      ? (e: any): JSX.Element | undefined => {
          const bookmark = getObjectBookmark(e.data);
          const component = this.editorFactory.createListEditor(
            property,
            bookmark,
            (newValue: any, relationPropertyName?: string, relationObject?: any) => {
              if (relationPropertyName) {
                const allChanges = dxDataGrid?.option('editing.changes') as DataChange[];
                const rowChangesIndex = allChanges.findIndex((chg) => chg.key === e.key);
                if (rowChangesIndex !== -1) {
                  allChanges[rowChangesIndex]['data'][relationPropertyName] = newValue;
                } else {
                  const newData = {};
                  newData[relationPropertyName] = newValue;
                  allChanges.push({ data: newData, key: e.key, type: 'update' });
                }

                dxDataGrid?.option('editing.changes', [...allChanges]);

                // #10972 defer to next calculation cycle to prevent losing the validation state
                setTimeout(() => e.setValue(relationObject), 0);
                setTimeout(() => dxDataGrid?.repaintRows([e.rowIndex]), 0);
              } else {
                setTimeout(() => e.setValue(newValue), 0);
              }
            }
          );

          return component ? <>{component}</> : undefined;
        }
      : undefined;

    const baseColumnProperties: Partial<IColumnProps> = {
      alignment: columnAlignment,
      hidingPriority: isDrilldownColumn != null ? undefined : positionFromBack,
      visible: this.visibleCalculator.calculate(property, {}),
      dataType: property.dataType != null ? devexpressType : 'string',
      dataField: getElementPath(property, true),
      name: property.path.replaceAll('.', '#'),
      allowSearch: columnIsSearchable,
      format: this.formatStringService.get(property),
      width: width,
      allowFiltering: allowFiltering,
      allowHeaderFiltering: isValueListProperty,
      headerFilter: isValueListProperty ? headerFilterForValueList : undefined,
      filterOperations: filterOptions,
      allowEditing: allowEditing,
      editCellRender: editorRender,
      validationRules: [{ type: 'async', validationCallback: customValidationCallback, reevaluate: false }],
      headerCellRender: renderTitleHeader,
      caption: property.caption,
      cssClass: `${baseCssClass} ${columnIsSearchable ? '' : 'quino-data-grid-no-search-highlight'} ${
        isValueListProperty ? 'quino-datagrid-hidden-filter-column' : ''
      }`
    };

    if (isMetaRelation(property)) {
      let cellRender: ((data: any) => React.ReactNode) | undefined = undefined;
      if (isDrilldownColumn) {
        cellRender = drillDownCellRender;
      } else if (dataGridProps.useCrossLinks && !inlineEditMode && !isValueListProperty) {
        cellRender = metaRelationCellRender;
      }

      return (
        <Column
          key={property.path}
          {...baseColumnProperties}
          cellRender={cellRender}
          calculateDisplayValue={(x: any) => this.titleCalculator.getTitle(property, x)}
          calculateGroupValue={this.calculateRelationPropertyValue(property, '.')}
          calculateSortValue={this.calculateRelationPropertyValue(property, '/')}
          calculateFilterExpression={(value: any, operation: string) => {
            const result = [];
            if (isValueListProperty) {
              const valueIsString = typeof value === 'string';
              result.push([
                valueIsString ? `${property.path}/Caption` : `${property.path}/Value`,
                operation != null ? operation : valueIsString ? 'contains' : '=',
                value
              ]);
              return result;
            }

            const titleLayout = this.layoutResolver.resolveSingle({ metaClass: property.targetClass, type: LayoutType.Title });
            for (const element of titleLayout.elements) {
              if ((element as IMetaProperty).dataType === DataType.Text) {
                if (result.length > 0) {
                  result.push('or');
                }
                result.push([`${property.path}/${element.name}`, operation != null ? operation : 'contains', value]);
              }
            }

            return result;
          }}
        />
      );
    } else {
      let cellRender = isDrilldownColumn ? drillDownCellRender : undefined;

      if (property.controlName == CONTROL_IDENTIFIER.ENTITYSELECTOR) {
        cellRender = (data: any) => {
          const val = JSON.parse(this.metaPropertyValueService.getPlainValue(property, data.data) as string);
          return val ? <ContextCrossLink {...val} /> : <></>;
        };
      }

      if (property.controlName == CONTROL_IDENTIFIER.IMAGEPICKER) {
        baseColumnProperties.allowFiltering = false;
        baseColumnProperties.allowExporting = false;
        baseColumnProperties.allowEditing = false;
        baseColumnProperties.allowHeaderFiltering = false;
        baseColumnProperties.allowSearch = false;
        baseColumnProperties.allowResizing = false;

        cellRender = (data: any) => {
          const val = this.metaPropertyValueService.getPlainValue(property, data.data);
          return <QuinoImagePickerBase element={property} data={val} />;
        };
      }

      if (isPropertyOfRelation) {
        baseColumnProperties.calculateCellValue = (value: any) => this.titleCalculator.getTitle(property, value);
        baseColumnProperties.calculateFilterExpression = (value: any, operation: string) => {
          const adjustedPath = property.path.replaceAll('.', '/');
          if (isValueListProperty) {
            return [adjustedPath, operation != null ? operation : '=', value];
          } else {
            return [adjustedPath, operation != null ? operation : 'contains', value];
          }
        };
      } else if (isValueListProperty) {
        baseColumnProperties.calculateDisplayValue = (value: any) => this.titleCalculator.getTitle(property, value);
      } else if (devexpressType === 'date' || devexpressType === 'datetime') {
        baseColumnProperties.calculateCellValue = (data: any) => {
          const plainValue = this.metaPropertyValueService.getPlainValue(property, data);
          return recalculateAsUtc(plainValue, property.dataType);
        };
      } else {
        baseColumnProperties.customizeText = (data: any) => this.valueFormatter.formatValue(property, data.value);
      }

      return (
        <Column key={property.path} {...baseColumnProperties} showEditorAlways={property.dataType === DataType.Boolean} cellRender={cellRender} />
      );
    }
  }

  private readonly calculateRelationPropertyValue = (relation: IMetaRelation, nestedSeparator: string): string => {
    if (isValueList(relation)) {
      return `${relation.sourceProperties[0]}`;
    }

    const titleLayout = this.layoutResolver.resolveSingle({ metaClass: relation.targetClass, type: LayoutType.Title });
    return `${relation.path}${nestedSeparator}${titleLayout.elements[0].name}`;
  };
}

const ContextCrossLink = (props: { class: string; id: any }) => {
  const [title, setTitle] = useState<string>();
  const [error, setError] = useState<string | undefined>(undefined);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const notificationService = useService<INotificationService>(INotificationServiceSymbol);
  const batchedRequestService = useService<IBatchedRequestService>(IBatchedRequestServiceSymbol);
  const odataUrlBuilder = useService<ODataUrlBuilder>(ODataUrlBuilderSymbol);
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const genericObjectFactory = useService<IGenericObjectFactory>(IGenericObjectFactorySymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  useEffect(() => {
    setTitle(undefined);
    if (props.id && props.class) {
      const metaClass = props.class,
        primaryKey = props.id;
      const layout = layoutResolver.resolveSingle({ metaClass: props.class, type: LayoutType.Title });
      const builder = odataUrlBuilder.entity(metaClass, primaryKey);
      const targetClass = metadataTree.getClass(metaClass);
      builder.apply(targetClass, layout, false);

      batchedRequestService
        .enqueue({
          url: builder.toString(),
          method: 'get',
          id: `${props.class}_${props.id}`
        })
        .then((result) => genericObjectFactory.create(result.body, metaClass))
        .then((object) => {
          setTitle(object.title);
        })
        .catch((error) => {
          const message = `Failed to load cross-link [${error}]`;
          const caption = '[Crosslink error]';
          notificationService.notify({ message });
          setError(caption);
          logger.logError(message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.class, props.id]);

  return (
    <QuinoCrossLink targetClass={props.class} primaryKey={props.id} visible={true} showTitle={true} title={title ? title : '...'} error={error} />
  );
};
