import { IExpression, IExpressionEvaluator, IMetadataTree, IMetaLayout, isExpression } from '@quino/core';
import { IBookmark, isIObjectBookmark } from '../../bookmarks';

export const resolveLayout = (
  expressionEvaluator: IExpressionEvaluator,
  metadataTree: IMetadataTree,
  metaClass: string,
  targetLayout?: IExpression | string,
  currentBookmark?: IBookmark
): IMetaLayout | undefined => {
  const targetLayoutName = isExpression(targetLayout)
    ? expressionEvaluator.evaluate<string>(targetLayout, isIObjectBookmark(currentBookmark) ? currentBookmark.genericObject : undefined)
    : targetLayout;
  return targetLayoutName ? metadataTree.getLayouts(metaClass).find((l) => l.name.toLowerCase() === targetLayoutName.toLowerCase()) : undefined;
};
