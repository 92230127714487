import React, { useState } from 'react';
import { ILanguageService, QuinoCoreServiceSymbols, ITranslationService, ITranslationServiceSymbol, isBase64ImageSource } from '@quino/core';
import { usePromise, useService } from '@quino/ui';
import { useCuiSettings } from '../../util';

export interface ICUILocalizedImageProps {
  src: string;
  alt: string;
  translationKey: string;
  onClick?: () => void;
  className?: string;
}

export function CUILocalizedImage(props: ICUILocalizedImageProps) {
  const { alt, src, translationKey, onClick, className } = props;

  const languageService = useService<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const [imgErrorOccurredOnce, setImgErrorOccurredOnce] = useState<boolean>(false);

  const enableLocalizedLogos = useCuiSettings().enableLocalizedLogos;
  const [currentLanguage] = usePromise<string, any>(async () => (enableLocalizedLogos && languageService.getLocalLanguageAsync()) || '', []);
  const hasBase64Source = isBase64ImageSource(src);
  const translation = translationService.translate(translationKey);
  const hasBase64TranslatedImage = translation != translationKey && isBase64ImageSource(translation);

  let localizedSrc: string = src;
  // STRATEGY
  if (hasBase64TranslatedImage) {
    // 1. Use translated base64 image if available
    localizedSrc = translation;
  } else if (hasBase64Source) {
    // 2. Use base64 image from config if available
    localizedSrc = src;
  } else if (enableLocalizedLogos && currentLanguage != null) {
    // 3. if localized logos are enabled search for logo name + '_' + language code eg. '_en' for english
    // 'logo.png' -> 'logo_en.png'
    const localizedImageNameArray = src.split('.');
    localizedImageNameArray[0] += '_' + currentLanguage.substring(0, 2);
    localizedSrc = localizedImageNameArray.join('.');
  }

  return (
    <img
      src={`${hasBase64Source || hasBase64TranslatedImage ? '' : '/'}${localizedSrc}`}
      alt={alt}
      className={className}
      onClick={onClick}
      onError={(e) => {
        if (!e.target || !enableLocalizedLogos || imgErrorOccurredOnce) {
          return;
        }
        setImgErrorOccurredOnce(true);
        (e.target as any).onError = null;
        // 4. nothing from strategy 1 to 3 seems to apply, try it with the original source
        (e.target as any).src = `/${src}`;
      }}
    />
  );
}
