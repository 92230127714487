import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc/constants/identifiers';
import { IVersionInfoService } from './IVersionInfoService';
import { IVersionInfo } from './IVersionInfo';
import { IBackendVersionInfo } from './IBackendVersionInfo';
import { IBackendVersionInfoService } from './IBackendVersionInfoService';
import { IFrontendVersionInfo } from './IFrontendVersionInfo';
import { IFrontendVersionInfoService } from './IFrontendVersionInfoService';

@injectable()
export class VersionInfoService implements IVersionInfoService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IFrontendVersionInfoService)
    private readonly frontendVersionInfoService: IFrontendVersionInfoService,
    @inject(QuinoCoreServiceSymbols.IBackendVersionInfoService)
    private readonly backendVersionInfoService: IBackendVersionInfoService
  ) {}

  async getVersionInfo(): Promise<IVersionInfo> {
    const frontendVersionInfo: IFrontendVersionInfo = this.frontendVersionInfoService.getFrontendVersionInfo();
    const backendVersionInfo: IBackendVersionInfo = await this.backendVersionInfoService.getBackendVersionInfo();

    if (
      !this.cachedBackendVersionInfo ||
      JSON.stringify(frontendVersionInfo) !== JSON.stringify(this.cachedBackendVersionInfo.frontend) ||
      JSON.stringify(backendVersionInfo) !== JSON.stringify(this.cachedBackendVersionInfo.backend)
    ) {
      this.cachedBackendVersionInfo = {
        frontend: frontendVersionInfo,
        backend: backendVersionInfo
      };
    }

    return this.cachedBackendVersionInfo;
  }

  // the version info is cached for equality in react hook dependencies
  private cachedBackendVersionInfo: IVersionInfo | undefined;
}
