import { IApplication } from '../application';
import { IErrorHandlingService, IErrorHandlingServiceSymbol } from './IErrorHandlingService';
import { ErrorHandlingService } from './ErrorHandlingService';
import { ITranslationService, ITranslationServiceSymbol } from '../lang';
import { ErrorGroupMapper } from './mappers/ErrorGroupMapper';
import { EntityErrorMapper } from './mappers/EntityErrorMapper';
import { IMetadataTree } from '../api';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IDynamicStringCalculator, IDynamicStringCalculatorSymbol } from '../expressions';
import {
  ExceptionServerErrorMapper,
  ODataServerErrorMapper,
  RequestServerErrorMapper,
  UnclassifiedServerErrorMapper,
  ValidationErrorMapper
} from './mappers';

export class ErrorHandlingModule {
  public static use(application: IApplication) {
    application.registerSingle<IErrorHandlingService>(IErrorHandlingServiceSymbol, ErrorHandlingService);

    application.registerStartupAction(Symbol.for('RegisterDefaultErrorMappers'), (app) => {
      const translationService = app.get<ITranslationService>(ITranslationServiceSymbol);
      const metadataTree = app.get<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
      const dynamicStringCalculator = app.get<IDynamicStringCalculator>(IDynamicStringCalculatorSymbol);

      const provider = app.get<IErrorHandlingService>(IErrorHandlingServiceSymbol);
      provider.register(new ErrorGroupMapper(translationService, provider));
      provider.register(new EntityErrorMapper(translationService));
      provider.register(new ValidationErrorMapper(translationService, metadataTree, dynamicStringCalculator));
      provider.register(new RequestServerErrorMapper(translationService));
      provider.register(new ExceptionServerErrorMapper(translationService));
      provider.register(new UnclassifiedServerErrorMapper(translationService));
      provider.register(new ODataServerErrorMapper(translationService));
    });
  }
}
