import { IBookmark, isIObjectBookmark } from '../../bookmarks';
import { inject, injectable } from 'inversify';
import { IApplication, ILogger, INavigationLinkAspect, QuinoCoreServiceSymbols, TOpenInTarget } from '@quino/core';
import { INavigationLinkService } from '../../navigation/NavigationLinks/INavigationLinkService';
import { IBookmarkSerializerProvider, IBookmarkSerializerProviderSymbol } from '../../navigation/IBookmarkSerializerProvider';
import {
  INavigationLinkResolverProvider,
  INavigationLinkResolverProviderSymbol
} from '../../navigation/NavigationLinks/INavigationLinkResolverProvider';
import { IBookmarkTitleCalculator, IBookmarkTitleCalculatorSymbol } from '../IBookmarkTitleCalculator';
import { INavigationService, INavigationServiceSymbol } from '../INavigationService';
import { ContainerContext } from '../../ioc/hook/ContainerContext';
import ReactDOM from 'react-dom';
import React, { ReactElement } from 'react';
import { QuinoPopup } from '../../components/QuinoPopup';
import { QuinoPopupEditor } from '../../components/QuinoPopupEditor';
import { IQuinoPopupOptions } from '../../components/QuinoPopup/QuinoPopup';
import { QuinoMetaPanel } from '../../components/QuinoMetaPanel/QuinoMetaPanel';

@injectable()
export class NavigationLinkService implements INavigationLinkService {
  constructor(
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(INavigationLinkResolverProviderSymbol) private readonly linkResolverProvider: INavigationLinkResolverProvider,
    @inject(IBookmarkSerializerProviderSymbol) private readonly bookMarkSerializer: IBookmarkSerializerProvider,
    @inject(IBookmarkTitleCalculatorSymbol) private readonly titleCalculator: IBookmarkTitleCalculator,
    @inject(QuinoCoreServiceSymbols.IApplication) private readonly container: IApplication,
    @inject(INavigationServiceSymbol) private readonly navigationService: INavigationService
  ) {
    this.element = document.createElement('div');
    this.element.style.width = '0px';
    this.element.style.height = '0px';
    this.element.style.position = 'absolute';
    document.body.appendChild(this.element);
  }

  getOnClickAction(elementName: string, navigationAspect: INavigationLinkAspect | null, currentBookmark?: IBookmark): (() => void) | undefined {
    if (navigationAspect) {
      const targetType = navigationAspect.target.targetType.toLowerCase();
      const resolver = this.linkResolverProvider.find(targetType);
      if (resolver) {
        return resolver.generateOnClickCallback(navigationAspect.target, currentBookmark, navigationAspect.openInNewTab);
      } else {
        this.logger.logError(`Cannot generate link for target type [${targetType}].`);
        return undefined;
      }
    }

    this.logger.logInfo(`No NavigationAspect found on element [${elementName}]. Cannot generate link.`);
    return undefined;
  }

  openInTarget(target: string | IBookmark, openInTarget: TOpenInTarget) {
    if (typeof target !== 'string') {
      if (openInTarget === 'popup') {
        this.openInPopup(target);
      } else if (openInTarget === 'newTab') {
        const matches = this.bookMarkSerializer.getInstances().filter((x) => x.canSerialize(target));
        window.open(matches[0].serialize(target), '_blank');
      } else {
        this.navigationService.push(target).catch(this.logger.logError);
      }
      return;
    }

    if (openInTarget === 'popup') {
      this.openInPopup(target);
    } else {
      window.open(target, openInTarget === 'newTab' ? '_blank' : '_self');
    }
  }

  private openInPopup(target: string | IBookmark) {
    const commonProps: Partial<IQuinoPopupOptions> = {
      visible: true,
      showCloseButton: true,
      width: '80%',
      height: '75%',
      onHiding: () => this.unMountComponent()
    };

    if (typeof target === 'string') {
      this.mountComponent(
        <QuinoPopup {...commonProps} showTitle={false}>
          <iframe title={'External Content'} src={target} width={'100%'} height={'100%'} />
        </QuinoPopup>
      );
    } else if (isIObjectBookmark(target)) {
      this.mountComponent(<QuinoPopupEditor visible={true} onHide={() => setTimeout(() => this.unMountComponent())} bookmark={target} />);
    } else {
      this.mountComponent(
        <QuinoPopup {...commonProps} title={this.titleCalculator.generate(target)}>
          <QuinoMetaPanel bookmark={target} />
        </QuinoPopup>
      );
    }
  }

  private mountComponent(component: ReactElement): void {
    if (!this.isMounted) {
      ReactDOM.render(<ContainerContext.Provider value={{ container: this.container }}>{component}</ContainerContext.Provider>, this.element);
      this.isMounted = true;
    }
  }

  private unMountComponent(): void {
    ReactDOM.unmountComponentAtNode(this.element);
    this.isMounted = false;
  }
  private readonly element: HTMLElement;
  private isMounted: boolean;
}
