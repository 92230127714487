import { IExpressionEvaluator } from './IExpressionEvaluator';
import { isBinaryExpression } from './IBinaryExpression';
import { ExpressionOperator } from './ExpressionOperator';
import { IExpression, isExpression } from './IExpression';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';

export const BinaryExpressionEvaluatorSymbol = Symbol.for('BinaryExpressionEvaluator');

@injectable()
export class BinaryExpressionEvaluator implements IExpressionEvaluator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator) {}

  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (isExpression(expression) && isBinaryExpression(expression)) {
      const leftResult: any = this.evaluator.evaluate(expression.left, context);
      const rightResult: any = expression.right != null ? this.evaluator.evaluate(expression.right, context) : null;

      switch (parseInt(expression.Operator.toString(), undefined)) {
        case ExpressionOperator.Add:
          return leftResult + rightResult;
        case ExpressionOperator.Subtract:
          // @ts-ignore
          return leftResult - rightResult;
        case ExpressionOperator.Divide:
          // @ts-ignore
          return leftResult / rightResult;
        case ExpressionOperator.Multiply:
          // @ts-ignore
          return leftResult * rightResult;
        case ExpressionOperator.Mod:
          // @ts-ignore
          return leftResult % rightResult;
        case ExpressionOperator.Power:
          // @ts-ignore
          return leftResult ** rightResult;
        case ExpressionOperator.Negate:
          // @ts-ignore
          return !(leftResult && rightResult);
        case ExpressionOperator.Equal:
          // @ts-ignore
          return leftResult === rightResult;
        case ExpressionOperator.NotEqual:
          // @ts-ignore
          return leftResult !== rightResult;
        case ExpressionOperator.LessThan:
          // @ts-ignore
          return leftResult < rightResult;
        case ExpressionOperator.LessThanEqual:
          // @ts-ignore
          return leftResult <= rightResult;
        case ExpressionOperator.GreaterThan:
          // @ts-ignore
          return leftResult > rightResult;
        case ExpressionOperator.GreaterThanEqual:
          // @ts-ignore
          return leftResult >= rightResult;
        case ExpressionOperator.In:
          throw new Error('Can not map [In] operator.');
        case ExpressionOperator.And:
          return leftResult && rightResult;
        case ExpressionOperator.Or:
          return leftResult || rightResult;
        case ExpressionOperator.Not:
          // @ts-ignore
          return !leftResult;
        case ExpressionOperator.Concatenate:
          throw new Error('[Concatenate] operator is not yet implemented.');
        case ExpressionOperator.Coalesce:
          return leftResult != null ? leftResult : rightResult;
        case ExpressionOperator.IsNull:
          // @ts-ignore
          return leftResult == null && rightResult == null;
        case ExpressionOperator.IsNotNull:
          // @ts-ignore
          return leftResult != null;
        case ExpressionOperator.FormatGroups:
          return leftResult || '' + rightResult || '';
        case ExpressionOperator.FormattedAs:
          return leftResult + rightResult;
        case ExpressionOperator.BeginsWith:
          return leftResult != null && leftResult.toString().startsWith(rightResult);
        case ExpressionOperator.EndsWith:
          return leftResult != null && leftResult.toString().endsWith(rightResult);
        case ExpressionOperator.Contains:
          return leftResult != null && leftResult.toString().includes(rightResult);
        case ExpressionOperator.Matches:
          return leftResult != null && leftResult.toString().includes(rightResult);
        case ExpressionOperator.EqualCI:
          // @ts-ignore
          return this.equalsCi(leftResult, rightResult);
        case ExpressionOperator.NotEqualCI:
          // @ts-ignore
          return !this.equalsCi(leftResult, rightResult);
        case ExpressionOperator.BeginsWithCI:
          return leftResult != null && leftResult.toString().startsWith(rightResult);
        case ExpressionOperator.EndsWithCI:
          return leftResult != null && leftResult.toString().endsWith(rightResult);
        case ExpressionOperator.ContainsCI:
          return leftResult != null && leftResult.toString().includes(rightResult);
        case ExpressionOperator.MatchesCI:
          if (rightResult == null) {
            // @ts-ignore
            return false;
          }
          return leftResult != null && leftResult.toString().toLowerCase().includes(rightResult.toString().toLowerCase());
        default:
          throw new Error('Unexpected operator while parsing [BinaryExpression].');
      }
    }

    // @ts-ignore
    return expression;
  }

  private readonly equalsCi = (a: any, b: any) => {
    return typeof a === 'string' && typeof b === 'string' ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0 : a === b;
  };
}
