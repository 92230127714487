import { ILayoutScope } from './ILayoutScope';
import { IMetaLayout } from '../meta';

/**
 * Symbol to retrieve the ILayoutScopeManager from the IoC.
 */
export const LayoutScopeManagerSymbol = Symbol.for('LayoutScopeManager');

/**
 * Scope manager for the layout resolving and filtering.
 */
export interface ILayoutScopeManager {
  /**
   * Push a new scope on top of the chain.
   * @param scope - the scope to apply.
   */
  push(scope: ILayoutScope): void;

  /**
   * Push a new scope on top of the chain.
   * @param scope - the scope to push.
   * @param clear - whether to clear the previous scope. Does not remove the global scope.
   */
  push(scope: ILayoutScope, clear: boolean): void;

  /**
   * Push a new global scope onto the chain.
   * @param scope - the scope to push.
   */
  pushGlobalScope(scope: ILayoutScope): void;

  /**
   * Clear the current scope - including the global scope.
   */
  clear(): void;

  /**
   * Reduce the given layouts with the current pushed scopes.
   * @param payload - the payload to resolve.
   */
  reduce(payload: IMetaLayout[]): IMetaLayout[];
}
