import { inject, injectable } from 'inversify';
import * as React from 'react';
import { IWizardService, IWizardServiceSymbol } from '../../wizard';
import {
  IDataService,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetaClass,
  IMetaElement,
  INotification,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { IQuinoDataGridProps } from './QuinoDataGrid';
import { IExportButtonFactory } from './IExportButtonFactory';
import { ContextMenu } from 'devextreme-react/context-menu';
import ReactDOM from 'react-dom';
import { QuinoNotificationContent } from '../QuinoNotificationComponents/QuinoNotificationContent';
import dxDataGrid from 'devextreme/ui/data_grid';
import { IQuinoDataGridExporter, IQuinoDataGridExporterSymbol } from './IQuinoDataGridExporter';
import { INavigationService, INavigationServiceSymbol } from '../../navigation';
import { isIRefreshableBookmark } from '../../bookmarks';
import { IQuinoContextMenuItem } from '../ContextMenu';
import { ItemClickEvent } from 'devextreme/ui/context_menu';
import { IFeedbackService, IFeedbackServiceSymbol } from '../../feedback';

type TExportItemType = 'pdf' | 'xlsx';

@injectable()
export class ExportButtonFactory implements IExportButtonFactory {
  constructor(
    @inject(IWizardServiceSymbol) private readonly wizardService: IWizardService,
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService,
    @inject(QuinoCoreServiceSymbols.IDataService) private readonly dataService: IDataService,
    @inject(ILoadingFeedbackSymbol) private readonly loadingFeedback: ILoadingFeedback,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(IQuinoDataGridExporterSymbol) private readonly exporter: IQuinoDataGridExporter,
    @inject(INavigationServiceSymbol) private readonly navigationService: INavigationService,
    @inject(IFeedbackServiceSymbol) private readonly feedbackService: IFeedbackService
  ) {}

  createXmlImportItem(): IQuinoContextMenuItem {
    return {
      text: this.translationService.translate('QuinoDataGrid.XmlImport'),
      icon: 'material-icons-outlined file_download',
      onItemClick: () => {
        this.wizardService
          .showWizard(
            this.translationService.translate('QuinoDataGrid.XmlImport'),
            [
              {
                title: this.translationService.translate('QuinoDataGrid.XmlImport.FileSelection'),
                inputs: [{ name: 'file', text: this.translationService.translate('QuinoDataGrid.XmlImport.ImportFile'), type: 'file' }]
              },
              {
                title: this.translationService.translate('QuinoDataGrid.XmlImport.ImportOptions'),
                inputs: [
                  {
                    name: 'conflictBehaviour',
                    text: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour'),
                    description: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Description'),
                    type: 'select',
                    options: [
                      {
                        value: 'Skip',
                        text: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Skip'),
                        hint: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Skip.Hint')
                      },
                      {
                        value: 'Merge',
                        text: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Merge'),
                        hint: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Merge.Hint')
                      },
                      {
                        value: 'Replace',
                        text: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Replace'),
                        hint: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Replace.Hint')
                      },
                      {
                        value: 'Duplicate',
                        text: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Duplicate'),
                        hint: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Duplicate.Hint')
                      },
                      {
                        value: 'Truncate',
                        text: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Truncate'),
                        hint: this.translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Truncate.Hint')
                      }
                    ],
                    default: 'Skip'
                  },
                  {
                    name: 'missingEntityBehaviour',
                    text: this.translationService.translate('QuinoDataGrid.XmlImport.MissingEntityBehaviour'),
                    description: this.translationService.translate('QuinoDataGrid.XmlImport.MissingEntityBehaviour.Description'),
                    type: 'select',
                    options: [
                      {
                        value: 'LogError',
                        text: this.translationService.translate('QuinoDataGrid.XmlImport.MissingEntityBehaviour.LogError'),
                        hint: this.translationService.translate('QuinoDataGrid.XmlImport.MissingEntityBehaviour.LogError.Hint')
                      },
                      {
                        value: 'SetNull',
                        text: this.translationService.translate('QuinoDataGrid.XmlImport.MissingEntityBehaviour.SetNull'),
                        hint: this.translationService.translate('QuinoDataGrid.XmlImport.MissingEntityBehaviour.SetNull.Hint')
                      }
                    ],
                    default: 'SetNull'
                  },
                  {
                    name: 'useTransaction',
                    text: this.translationService.translate('QuinoDataGrid.XmlImport.UseTransaction'),
                    type: 'checkbox',
                    default: true,
                    description: this.translationService.translate('QuinoDataGrid.XmlImport.UseTransaction.Description')
                  }
                ]
              }
            ],
            async (data) =>
              new Promise((resolve) => {
                this.dataService
                  .importFileAsync(data['file'], data['conflictBehaviour'], data['missingEntityBehaviour'], data['useTransaction'])
                  .then((response) => {
                    const successful = !response.find((i) => i.Level === 'Error');
                    const warning = response.find((i) => i.Level === 'Warning');
                    const currentBookmark = this.navigationService.active;
                    if (isIRefreshableBookmark(currentBookmark)) {
                      currentBookmark.refresh();
                    }
                    resolve(
                      <>
                        {successful ? (
                          warning ? (
                            <QuinoNotificationContent
                              type={'warning'}
                              message={this.translationService.translate('QuinoDataGrid.XmlImport.Warning')}
                            />
                          ) : (
                            <QuinoNotificationContent
                              type={'success'}
                              message={this.translationService.translate('QuinoDataGrid.XmlImport.Success')}
                            />
                          )
                        ) : (
                          <QuinoNotificationContent
                            type={'error'}
                            message={this.translationService.translate('QuinoDataGrid.XmlImport.RanWithErrors')}
                          />
                        )}
                        <p>{this.translationService.translate('QuinoDataGrid.XmlImport.LogOutput')}:</p>
                        <code className={'quino-wizard-output'}> {JSON.stringify(response, null, 2)}</code>
                      </>
                    );
                  })
                  .catch((e) => {
                    resolve(
                      <p>
                        Error while sending request: <span className={'color-status-red'}>{e.message ? e.message : JSON.stringify(e)}</span>
                      </p>
                    );
                  });
              }),
            this.translationService.translate('QuinoDataGrid.Import'),
            'material-icons-outlined file_download',
            800,
            600
          )
          .catch((e) => this.logger.logError(e));
      }
    };
  }

  createItem(dataGrid: dxDataGrid, props: IQuinoDataGridProps, selectedOnly = false, exportType: TExportItemType) {
    const fileExport = () => {
      if (dataGrid.option().loadPanel) {
        // The typing doesn't exist, but the property is there.
        // @ts-ignore
        dataGrid.option().loadPanel.animation = false;
      }
      dataGrid.beginCustomLoading(this.translationService.translate('Exporting'));
      dataGrid.beginUpdate();
      setTimeout(() => {
        (exportType == 'pdf'
          ? this.exporter.exportPdf(dataGrid, props.layout!, props.metaClass!, props, selectedOnly)
          : this.exporter.exportExcel(dataGrid, props.layout!, props.metaClass!, props, selectedOnly)
        )
          .catch(this.logger.logError)
          .finally(() => {
            dataGrid.endCustomLoading();
            if (dataGrid.option().loadPanel) {
              // The typing doesn't exist, but the property is there.
              // @ts-ignore
              dataGrid.option().loadPanel.animation = true;
            }
            dataGrid.endUpdate();
          });
      }, 50);
    };

    return {
      text:
        exportType == 'pdf'
          ? this.translationService.translate('QuinoDataGrid.PdfExport')
          : this.translationService.translate('QuinoDataGrid.ExcelExport'),
      icon: exportType == 'pdf' ? 'material-icons-outlined picture_as_pdf' : 'material-icons-outlined explicit',
      onItemClick: () => {
        //eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const changes = (dataGrid?.option('editing.changes') ?? []) as any[];
        if (changes.length > 0) {
          void this.feedbackService.showMessage(
            this.translationService.translate('Export.UnsavedChanges'),
            this.translationService.translate('Export.SaveBeforeExport')
          );
        } else {
          fileExport();
        }
      }
    };
  }

  createExcelExportItem(dataGrid: dxDataGrid, props: IQuinoDataGridProps, selectedOnly = false) {
    return this.createItem(dataGrid, props, selectedOnly, 'xlsx');
  }

  createPdfExportItem(dataGrid: dxDataGrid, props: IQuinoDataGridProps, selectedOnly = false) {
    return this.createItem(dataGrid, props, selectedOnly, 'pdf');
  }

  createXmlExportItem(
    dataGrid: dxDataGrid,
    className: string,
    notify: (notification: INotification | undefined) => void,
    filter?: Map<string, string> | string[]
  ) {
    return {
      text: this.translationService.translate('QuinoDataGrid.XmlExport'),
      icon: 'material-icons-outlined code',
      onItemClick: () => {
        //eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const changes = (dataGrid?.option('editing.changes') ?? []) as any[];
        if (changes.length > 0) {
          void this.feedbackService.showMessage(
            this.translationService.translate('Export.UnsavedChanges'),
            this.translationService.translate('Export.SaveBeforeExport')
          );
        } else {
          const now = new Date();
          const fileName = `${className.toLowerCase()}_export_${this.formatDate(now, [
            { year: 'numeric' },
            { month: '2-digit' },
            { day: '2-digit' }
          ])}_${now.toLocaleTimeString('de-CH')}`;
          this.wizardService
            .showSimpleWizard(
              this.translationService.translate('QuinoDataGrid.XmlExport'),
              [
                { type: 'text', name: 'fileName', text: this.translationService.translate('Filename'), default: fileName },
                {
                  type: 'select',
                  name: 'includeRelations',
                  default: 'None',
                  description: this.translationService.translate('QuinoDataGrid.XmlExport.IncludeRelations.Description'),
                  text: this.translationService.translate('QuinoDataGrid.XmlExport.IncludeRelations'),
                  options: [
                    {
                      value: 'None',
                      text: this.translationService.translate('QuinoDataGrid.XmlExport.IncludeRelations.None'),
                      hint: this.translationService.translate('QuinoDataGrid.XmlExport.IncludeRelations.None.Hint')
                    },
                    {
                      value: 'One',
                      text: this.translationService.translate('QuinoDataGrid.XmlExport.IncludeRelations.One'),
                      hint: this.translationService.translate('QuinoDataGrid.XmlExport.IncludeRelations.One.Hint')
                    },
                    {
                      value: 'Recursive',
                      text: this.translationService.translate('QuinoDataGrid.XmlExport.IncludeRelations.Recursive'),
                      hint: this.translationService.translate('QuinoDataGrid.XmlExport.IncludeRelations.Recursive.Hint')
                    }
                  ]
                }
              ],
              this.translationService.translate('Export'),
              'material-icons-outlined file_upload'
            )
            .then((data) => {
              const unload = this.loadingFeedback.load(this.translationService.translate('Exporting'));
              this.dataService
                .exportClassAsync(className, data['fileName'], data['includeRelations'], filter)
                .then(() => {
                  notify({
                    message: this.translationService.translate('QuinoDataGrid.XmlExport.Completed'),
                    type: 'success',
                    area: 'global',
                    autoDisappear: true
                  });
                })
                .catch((e) => {
                  notify({ message: e.message, type: 'error', area: 'global' });
                })
                .finally(() => {
                  unload();
                });
            })
            .catch((e) => this.logger.logError(e));
        }
      }
    };
  }

  createExportButton(exportContextMenuItems: IQuinoContextMenuItem[], layout: IMetaElement | undefined, metaClass: IMetaClass | undefined): object {
    return {
      widget: 'dxButton',
      cssClass: 'quino-data-grid-header-panel-settings',
      location: 'after',
      locateInMenu: 'never',
      options: {
        stylingMode: 'text',
        icon: 'material-icons-outlined file_upload',
        hint: this.translationService.translate('QuinoDataGrid.Import') + ' / ' + this.translationService.translate('QuinoDataGrid.Export'),
        onClick: (e: any) => {
          if (!e.element) {
            return;
          }
          const id = 'quino-data-grid-header-import-export-button' + metaClass?.name + layout?.name;

          const div = document.createElement('div');

          const contextMenu = (
            <ContextMenu
              showEvent={'dxclick'}
              closeOnOutsideClick={true}
              onHidden={() => ReactDOM.unmountComponentAtNode(div)}
              position={{ my: 'right top', at: 'right bottom', offset: { x: 0, y: 2 }, of: '#' + id }}
              items={exportContextMenuItems}
              displayExpr={'text'}
              onItemClick={(e: ItemClickEvent) => {
                const itemData = e.itemData as IQuinoContextMenuItem;
                itemData.onItemClick && itemData.onItemClick();
              }}
            />
          );

          ReactDOM.render(contextMenu, div);
          e.element.append(div);
          e.element.id = id;
        }
      }
    };
  }

  private formatDate(date: Date, format: Intl.DateTimeFormatOptions[], separator = '.') {
    return format
      .map((m) => {
        let f = new Intl.DateTimeFormat('en', m);
        return f.format(date);
      })
      .join(separator);
  }
}
