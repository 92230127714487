import { getAspectOrDefault, getMetaProperty, IRadioGroupLayoutAspect, RadioGroupLayoutAspectIdentifier } from '@quino/core';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { RadioGroup } from 'devextreme-react/radio-group';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { QuinoLabeled } from '../QuinoLabeled';
import { useMetaPropertyValueState, useValidation, useValueList } from '../Util';
import { useLabelSettings, useMetadata } from '../../settings';

export function QuinoRadioButtonGroup(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const { element, bookmark } = props;
  const metaProperty = getMetaProperty(element);
  const { description, caption, instruction } = metaProperty;
  const labelSettings = useLabelSettings(element);
  const { visible, readOnly, required, enabled } = useMetadata(metaProperty, bookmark.genericObject);
  const [isValid, errorMessages] = useValidation(bookmark, metaProperty);
  const valueList = useValueList(metaProperty);
  const radioGroupLayoutAspect = getAspectOrDefault<IRadioGroupLayoutAspect>(element, RadioGroupLayoutAspectIdentifier);
  const [value, setValue] = useMetaPropertyValueState<any>(metaProperty, bookmark);

  return (
    <QuinoLabeled
      visible={visible}
      className={'quino-input-fields-max-width'}
      label={caption}
      required={required}
      description={description}
      instruction={instruction}
      errorMessages={errorMessages}
      labelSettings={labelSettings}>
      <RadioGroup
        value={value}
        items={valueList != null ? valueList : []}
        valueExpr={'Value'}
        displayExpr={'Caption'}
        layout={radioGroupLayoutAspect != null ? radioGroupLayoutAspect.layout : 'horizontal'}
        disabled={!enabled}
        readOnly={readOnly}
        focusStateEnabled={!readOnly}
        hint={labelSettings.hintType === 'None' ? description : undefined}
        onValueChanged={(e) => setValue(e.value)}
        isValid={isValid}
      />
    </QuinoLabeled>
  );
}
