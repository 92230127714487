export enum ExpressionOperator {
  /// <summary>
  /// Mathematical operation; returns the result of adding the right side to the left side.
  /// </summary>
  Add,

  /// <summary>
  /// Mathematical operation; returns the result of subtracting the right side from the left side.
  /// </summary>
  Subtract,

  /// <summary>
  /// Mathematical operation; returns the result of dividing the left side by the right side.
  /// </summary>
  Divide,

  /// <summary>
  /// Mathematical operation; returns the result of multiplying the left side by the right side.
  /// </summary>
  Multiply,

  /// <summary>
  /// Mathematical operation; returns the integer remainder of dividing the left side by the right side.
  /// </summary>
  Mod,

  /// <summary>
  /// Mathematical operation; returns the result of raising the left side to the power of the right side.
  /// </summary>
  Power,

  /// <summary>
  /// Mathematical operation; returns the negation of the single argument.
  /// </summary>
  Negate,

  /// <summary>
  /// Boolean operation; <c>true</c> if the left side is equal to the right side.
  /// </summary>
  Equal,

  /// <summary>
  /// Boolean operation; <c>true</c> if the left side is not equal to the right side.
  /// </summary>
  NotEqual,

  /// <summary>
  /// Boolean operation; <c>true</c> if the left side is less than the right side.
  /// </summary>
  LessThan,

  /// <summary>
  /// Boolean operation; <c>true</c> if the left side is less than or equal to the right side.
  /// </summary>
  LessThanEqual,

  /// <summary>
  /// Boolean operation; <c>true</c> if the left side is greater than the right side.
  /// </summary>
  GreaterThan,

  /// <summary>
  /// Boolean operation; <c>true</c> if the left side is greater than or equal to the right side.
  /// </summary>
  GreaterThanEqual,

  /// <summary>
  /// Boolean operation; <c>true</c> if the left side is contained in the set on the right side.
  /// </summary>
  In,

  /// <summary>
  /// Boolean operation; <c>true</c> if the logical intersection of the left and right sides is non-empty.
  /// </summary>
  And,

  /// <summary>
  /// Boolean operation; <c>true</c> if the logical union of the left and right sides is non-empty.
  /// </summary>
  Or,

  /// <summary>
  /// Boolean operation; <c>true</c> if the single argument is <c>false</c>.
  /// </summary>
  Not,

  /// <summary>
  /// String operation; returns the result of appending the right side to the left side.
  /// </summary>
  Concatenate,

  /// <summary>
  /// Object operation; returns the left side if it is not <c>null</c>; otherwise, returns the right side.
  /// </summary>
  Coalesce,

  /// <summary>
  /// Boolean operation; <c>true</c> if the single argument is <c>null</c>.
  /// </summary>
  IsNull,

  /// <summary>
  /// Boolean operation; <c>true</c> if the single argument is not <c>null</c>.
  /// </summary>
  IsNotNull,

  /// <summary>
  /// String operation; returns only those elements which are not <c>null</c>; constant elements next to
  /// <c>null</c> elements are also dropped.
  /// </summary>
  FormatGroups,

  /// <summary>
  /// String operation; returns the result of formatting the left side with the formatting string on the right side.
  /// </summary>
  /// <remarks>
  /// Commonly used with dates and floating-point fields.
  /// </remarks>
  FormattedAs,

  /// <summary>
  /// Case-sensitive string operation; <c>true</c> if the text on the left side begins with the text on the right side.
  /// </summary>
  BeginsWith,

  /// <summary>
  /// Case-sensitive string operation; <c>true</c> if the text on the left side ends with the text on the right side.
  /// </summary>
  EndsWith,

  /// <summary>
  /// Case-sensitive string operation; <c>true</c> if the text on the left side contains the text on the right side.
  /// </summary>
  Contains,

  /// <summary>
  /// Case-sensitive string operation; <c>true</c> if the text on the left side matches the regular expression on the right side.
  /// </summary>
  Matches,

  /// <summary>
  /// Case-insensitive string operation; <c>true</c> if the text on the left side equals the text on the right side.
  /// </summary>
  EqualCI,

  /// <summary>
  /// Case-insensitive string operator; <c>true</c> if the left side is not equal to the right side.
  /// </summary>
  NotEqualCI,

  /// <summary>
  /// Case-insensitive string operation; <c>true</c> if the text on the left side begins with the text on the right side.
  /// </summary>
  BeginsWithCI,

  /// <summary>
  /// Case-insensitive string operation; <c>true</c> if the text on the left side ends with the text on the right side.
  /// </summary>
  EndsWithCI,

  /// <summary>
  /// Case-insensitive string operation; <c>true</c> if the text on the left side contains the text on the right side.
  /// </summary>
  ContainsCI,

  /// <summary>
  /// Case-insensitive string operation; <c>true</c> if the text on the left side matches the regular expression on the right side.
  /// </summary>
  MatchesCI
}
