import { IMetaAspect } from '@quino/core';

/**
 * The identifier for retrieving a layout action aspect.
 */
export const ListActionAspectIdentifier = 'ListActionAspect';

/**
 * Aspect containing info about where to display a layout action
 */
export interface IListActionAspect extends IMetaAspect {
  /**
   * Whether to show the action in the selection row of a list
   */
  showInListSelection?: boolean;

  /**
   * Whether to show the action in a single list row
   */
  showInListRow?: boolean;

  /**
   * Whether the action should be placed in the overflow button by default
   */
  isOverflowByDefault?: boolean;
}
