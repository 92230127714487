import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for retrieving a drill down aspect.
 */
export const DrillDownAspectIdentifier = 'DrilldownColumn';

/**
 * DrillDown aspect
 */
export interface IDrillDownAspect extends IMetaAspect {
  /**
   * marker aspect
   */
}
