import * as React from 'react';
import { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { IExpressionEvaluator, IFieldError, IMetaElement, isIMetaGroup, QuinoCoreServiceSymbols } from '@quino/core';
import { IQuinoComponentProps } from '../Types';
import { IBookmark, isIObjectBookmark, isStateFullBookmark, ObjectBookmark } from '../../bookmarks';
import { useService } from '../../ioc/hook';
import { QuinoUIServiceSymbols } from '../../ioc';
import { IValidationResultService } from '../../validations/IValidationResultService';
import { IComponentFactory } from '..';
import { ScrollView } from 'devextreme-react/scroll-view';
import { useMetadata } from '../../settings';
import { useOnDependencyChanged } from '../Util/MetaElementHooks';
import { TabPanel } from 'devextreme-react/tab-panel';

interface IPanelItem {
  index: number;
  contentNode: () => ReactNode;
  titleNode: () => ReactNode;
}

export const bookmarkStateTabIndexKey = 'tabindex';

export function QuinoTabsContainer(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const { element, bookmark, actions } = props;
  const { visible } = useMetadata(element, bookmark.genericObject);

  const factory = useService<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory);
  const expressionEvaluator = useService<IExpressionEvaluator>(QuinoCoreServiceSymbols.IExpressionEvaluator);

  const getVisibleElements = useCallback(
    (element: any): IMetaElement[] => {
      return isIMetaGroup(element) ? element.elements.filter((child) => expressionEvaluator.evaluate(child.visible, bookmark.genericObject)) : [];
    },
    [bookmark.genericObject, expressionEvaluator]
  );

  const [isRootNode, setIsRootNode] = useState(false);
  const [tabIndex, setTabIndex] = useState<number>((isStateFullBookmark(bookmark) && Number(bookmark.getStateValue(bookmarkStateTabIndexKey))) || 0);
  const [rerenderTabs, setRerenderTabs] = useState<IMetaElement[]>(getVisibleElements(element));

  if (isIMetaGroup(element) && element.elements != null) {
    for (const metaElement of element.elements) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useOnDependencyChanged(metaElement, bookmark, () => setRerenderTabs(getVisibleElements(element)));
    }
  }

  useOnDependencyChanged(element, bookmark);

  useEffect(() => {
    if (isIObjectBookmark(bookmark)) {
      const visibleLayoutElements = bookmark
        .getLayoutElementsWithoutActions()
        .filter((element) => expressionEvaluator.evaluate(element.visible, bookmark.genericObject));
      if (visibleLayoutElements.length > 0) {
        const isRootNode = JSON.stringify(visibleLayoutElements[0]) === JSON.stringify(element);
        setIsRootNode(isRootNode);
      }
    }
  }, [bookmark, element, expressionEvaluator]);

  useEffect(() => {
    if (isRootNode && isStateFullBookmark(bookmark)) {
      let dismissed = false;
      const subscriptionSymbol = bookmark.subscribeToStateChange((changeKey) => {
        if (changeKey === bookmarkStateTabIndexKey) {
          const newTabIndex = bookmark.getStateValue(bookmarkStateTabIndexKey);
          if (typeof newTabIndex === 'number' && newTabIndex >= 0 && newTabIndex < rerenderTabs.length) {
            !dismissed && setTabIndex(newTabIndex);
          }
        }
      });
      return () => {
        dismissed = true;
        bookmark.unsubscribeFromStateChange(subscriptionSymbol);
      };
    }

    return undefined;
  }, [bookmark, isRootNode, rerenderTabs.length]);

  const tabs: IPanelItem[] = useMemo(() => {
    return rerenderTabs.map((child: IMetaElement, index: number) => ({
      index,
      contentNode: () => (
        <div key={'tabs_' + index}>{isIMetaGroup(child) && child.elements.map((sub: IMetaElement) => factory.create(sub, bookmark, actions))}</div>
      ),
      titleNode: () => <QuinoTabTitle element={child} bookmark={props.bookmark} />
    }));
  }, [rerenderTabs, factory, bookmark, actions, props.bookmark]);

  const TabContent = (panelItem: IPanelItem) => {
    if (isRootNode) {
      return (
        <ScrollView
          className={'quino-padded-scrollable-content'}
          height={'100%'}
          useNative={false}
          showScrollbar={'always'}
          key={'scroll-view_' + panelItem.index}
          onInitialized={(e) => {
            if (e.component) {
              e.component.option('useKeyboard', false);
            }
          }}>
          {panelItem.contentNode()}
        </ScrollView>
      );
    }
    return panelItem.contentNode();
  };

  return (
    <TabPanel
      selectedIndex={tabIndex}
      visible={visible}
      className={`quino-tabs-container`}
      showNavButtons={true}
      deferRendering={false}
      items={tabs}
      itemTitleKeyFn={(data) => 'tab-title_' + data.index}
      itemKeyFn={(data) => 'tab-title_content' + data.index}
      itemTitleRender={(panelItem: IPanelItem) => panelItem.titleNode()}
      itemRender={(panelItem: IPanelItem) => TabContent(tabs[panelItem.index])}
      onOptionChanged={(e) => {
        if (e.name === 'selectedIndex') {
          const tabIndex = e.value;
          if (tabIndex >= 0 && tabIndex < tabs.length) {
            setTabIndex(tabIndex);
            if (isStateFullBookmark(bookmark) && isRootNode && tabIndex !== bookmark.getStateValue(bookmarkStateTabIndexKey)) {
              bookmark.setStateValue(bookmarkStateTabIndexKey, tabIndex);
            }
          }
        }
      }}
    />
  );
}

function QuinoTabTitle(props: { element: IMetaElement; bookmark: IBookmark }) {
  const { element, bookmark } = props;
  const validationResultService = useService<IValidationResultService>(QuinoUIServiceSymbols.IValidationResultService);
  const [numberOfErrors, setNumberOfErrors] = useState<number>(0);

  useEffect(() => {
    const getNumberOfErrors = (): number => {
      if (isIObjectBookmark(bookmark) && (bookmark.fieldErrors.size > 0 || bookmark.fieldErrorsFromServer.size > 0)) {
        const errors: IFieldError[] = [];
        bookmark.fieldErrors.forEach((x) => errors.push(...x));
        bookmark.fieldErrorsFromServer.forEach((x) => errors.push(...x));
        return errors.length > 0 ? validationResultService.getFieldErrors(element, { hasErrors: true, fieldErrors: errors }).length : 0;
      }
      return 0;
    };

    if (isIObjectBookmark(bookmark)) {
      const subscription = bookmark.subscribe((event) => {
        switch (event.type) {
          case 'saved':
          case 'reset':
          case 'reload':
            setNumberOfErrors(0);
            break;
          case 'changed':
            setNumberOfErrors(getNumberOfErrors());
        }
      });
      return () => bookmark.unsubscribe(subscription);
    }
    return;
  }, [bookmark, element, validationResultService]);

  return (
    <div className={'dx-item-content dx-tab-content'}>
      <div className={'quino-tabs-title'}>
        <span className={'dx-tab-text'}>{element.caption}</span>
        {numberOfErrors > 0 && <i className='material-icons-outlined error quino-tabs-title-badge' />}
      </div>
    </div>
  );
}
