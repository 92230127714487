import { inject, injectable } from 'inversify';
import { IResponseHandler } from './IResponseHandler';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IPreparedRequest } from './IPreparedRequest';
import { ILogger } from '../logging';

@injectable()
export class FailedRequestResponseHandler implements IResponseHandler {
  constructor(@inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger) {}

  async handleResponse(request: IPreparedRequest, response: Response): Promise<Response> {
    if (!response.ok) {
      this.logger.logError(`Failed to request [${response.url}]`);
    }

    return response;
  }
}
