import { IMetaElement } from './IMetaElement';

/**
 * Basic interface for all aspects that are generated from the Web-API.
 */
export interface IMetaAspect {
  /**
   * The name of the aspect. This is usually the type of the Quino aspect equivalent.
   */
  name: string;
}

/**
 * Get the aspect which matches the given name.
 * @param element - the elements to search.
 * @param name - the name to search for.
 * @param startsWith - if true, searches for the first aspect that starts with the given name
 * @return found aspect or null if aspect is not found
 */
export const getAspectOrDefault = <TAspect extends IMetaAspect>(element: IMetaElement, name: string, startsWith = false): TAspect | null => {
  const result =
    element.aspects &&
    element.aspects.find((value) => {
      if (startsWith) {
        return value.name.toLowerCase().startsWith(name.toLowerCase());
      }

      return value.name.localeCompare(name, undefined, { sensitivity: 'base' }) === 0;
    });

  return result ? (result as TAspect) : null;
};

/**
 * Get the aspect which matches the given name.
 * @throws if aspect is not found
 * @param element - the elements to search.
 * @param name - the name to search for.
 * @return found aspect
 */
export const getAspect = <TAspect extends IMetaAspect>(element: IMetaElement, name: string): TAspect => {
  const aspect: TAspect | null = getAspectOrDefault(element, name);

  if (aspect !== null) {
    return aspect;
  }

  throw new Error(`Aspect [${name}] of element [${element.name}] not found. Full element: [${JSON.stringify(element)}]`);
};
