import {
  ApplicationStartupGroup,
  FrameworkStartupGroup,
  IApplication,
  IAuthenticationFeedback,
  IFieldValidatorProvider,
  ILanguageService,
  InitializationGroup,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { CONTROL_IDENTIFIER, IComponentMappingProvider } from '../rendering/ComponentRegistry';
import { QuinoUIServiceSymbols } from './constants/identifiers';
import {
  ComponentFactory,
  DefaultQuinoMetaPanelActions,
  ExportButtonFactory,
  IComponentFactory,
  IExportButtonFactory,
  IExportButtonFactorySymbol,
  IQuinoAuthenticationComponentFactory,
  IQuinoDataGridConfigurationService,
  IQuinoDataGridFilterService,
  IQuinoDataGridSortService,
  IQuinoMetaPanelActions,
  IServerHealthCheckerService,
  IServerHealthCheckerServiceSymbol,
  MetaClassSelector,
  PasswordRepetitionValidator,
  PasswordRepetitionValidatorSymbol,
  QuinoAuthenticationComponentFactory,
  QuinoAuthenticationFeedback,
  QuinoCheckBox,
  QuinoCheckBoxLabeled,
  QuinoColorPicker,
  QuinoColorPickerLabeled,
  QuinoColumnConfigurationService,
  QuinoColumns,
  QuinoDataGridConfigurationService,
  QuinoDataGridFilterService,
  QuinoDataGridSortService,
  QuinoDateTimePicker,
  QuinoDateTimePickerLabeled,
  QuinoDropDown,
  QuinoDropDownLabeled,
  QuinoGroupBox,
  QuinoGroupRow,
  QuinoImagePicker,
  QuinoImagePickerLabeled,
  QuinoInlineActionButton,
  QuinoInlineDataGrid,
  QuinoLabel,
  QuinoMultiLineRichTextEditorLabeled,
  QuinoPasswordRepetition,
  QuinoRadioButtonGroup,
  QuinoResponsiveBox,
  QuinoSearchEdit,
  QuinoSearchEditLabeled,
  QuinoTabsContainer,
  QuinoTextBox,
  QuinoTextBoxLabeled,
  ServerHealthCheckerService
} from '../components';

import {
  DefaultDropDownConfigurationService,
  defaultDropDownSettings,
  DefaultLabelConfigurationService,
  defaultLabelSettings,
  ILabelSettings,
  IQuinoDropDownConfigurationService,
  IQuinoDropDownConfigurationServiceSymbol,
  IQuinoDropDownSettings,
  IQuinoDropDownSettingsSymbol,
  IQuinoLabelConfigurationService
} from '../settings';

import {
  defaultQuinoShortcutSettings,
  IQuinoShortcutSettings,
  IQuinoShortcutSettingsSymbol,
  IRegisterShortcutService,
  IRegisterShortcutServiceSymbol,
  IShortcutInformationService,
  IShortcutInformationServiceSymbol,
  IShortcutService,
  IShortcutServiceSymbol,
  RegisterShortcutService,
  ShortcutInformationService,
  ShortcutService
} from '../shortcuts';
import {
  DashboardConfigurationService,
  DashboardSettingsService,
  DashboardTileRegistry,
  defaultDashboardConfiguration,
  IDashboardConfiguration,
  IDashboardConfigurationService,
  IDashboardConfigurationServiceSymbol,
  IDashboardConfigurationSymbol,
  IDashboardSettingsService,
  IDashboardSettingsServiceSymbol,
  IDashboardTileRegistration,
  IDashboardTileRegistry,
  IDashboardTileRegistrySymbol
} from '../dashboard';
import {
  ChartTileRegistrationSymbol,
  GridTileRegistrationSymbol,
  IQuinoDashboardChartTileProps,
  IQuinoDashboardGridTileProps,
  IQuinoDashboardWebPageTileProps,
  QuinoDashboardChartTileRegistration,
  QuinoDashboardGridTileRegistration,
  QuinoDashboardTranslatedTextTileRegistration,
  QuinoDashboardWebPageTileRegistration,
  WebPageTileRegistrationSymbol
} from '../dashboard/tiles';
import { XMLEditor } from '../components/XMLEditor';
import { IFeedbackService, IFeedbackServiceSymbol } from '../feedback';
import { FeedbackService } from '../feedback/FeedbackService';
import { QuinoModelExplorer } from '../components/QuinoModelExplorer/QuinoModelExplorer';
import { EntitySelector } from '../components/EntitySelector/EntitySelector';
import { IWizardService, IWizardServiceSymbol } from '../wizard';
import { WizardService } from '../wizard/WizardService';
import { BookmarkFactory, BookmarkScopingService, BookmarkScopingServiceSymbol, IBookmarkFactory, IBookmarkScopingService } from '../bookmarks';

import { loadMessages, locale } from 'devextreme/localization';
// @ts-ignore file is not in root folder error
import enMessages from 'devextreme/localization/messages/en.json';
// @ts-ignore file is not in root folder error
import deMessages from 'devextreme/localization/messages/de.json';
// @ts-ignore file is not in root folder error
import frMessages from 'devextreme/localization/messages/fr.json';
// @ts-ignore file is not in root folder error
import itMessages from 'devextreme/localization/messages/it.json';
import { IQuinoFileStorage, IQuinoFileStorageSymbol } from '../components/QuinoFileUpload/api/IQuinoFileStorage';
import { IQuinoFileHandler, IQuinoFileHandlerSymbol } from '../components/QuinoFileUpload/api/IQuinoFileHandler';
import { QuinoFileStorage } from '../components/QuinoFileUpload/api/QuinoFileStorage';
import { QuinoFileHandler } from '../components/QuinoFileUpload/api/QuinoFileHandler';
import { IQuinoFileStorageService, IQuinoFileStorageServiceSymbol } from '../components/QuinoFileUpload/api/IQuinoFileStorageService';
import { QuinoFileStorageService } from '../components/QuinoFileUpload/api/QuinoFileStorageService';
import { QuinoFileUpload } from '../components/QuinoFileUpload/QuinoFileUpload';
import { defaultFileUploadSettings, IFileUploadSettings } from '../settings/IFileUploadSettings';
import { IQuinoFileUploadConfigurationService } from '../settings/IQuinoFileUploadConfigurationService';
import { DefaultFileUploadConfigurationService } from '../settings/DefaultFileUploadConfigurationService';
import { IFileUploadValidator, IFileUploadValidatorSymbol } from '../validations/controlValidator/IFileUploadValidator';
import { FileUploadValidator } from '../validations/controlValidator/FileUploadValidator';
import { QuinoTranslationKeyPicker, QuinoTranslationKeyPickerLabeled } from '../components/Localization';
import { IQuinoDashboardTranslatedTextTileProps, TranslatedTextTileRegistrationSymbol } from '../dashboard/tiles/QuinoDashboardTranslatedTextTile';
import { QuinoMultiPurposeTextEditorLabeled } from '../components/QuinoMultiPurposeTextEditor/QuinoMultiPurposeTextEditorLabeled';
import { ISharedDashboardsService, ISharedDashboardsServiceSymbol } from '../dashboard/configuration/ISharedDashboardsService';
import { SharedDashboardsService } from '../dashboard/configuration/SharedDashboardsService';
import { QuinoMultiSelectBoxLabeled } from '../components/QuinoMultiSelect';
import { IColumnConfigurationService, IColumnConfigurationServiceSymbol } from '../components/QuinoDataGrid/IColumnConfigurationService';

export const DevexpressRegisterControlsStartupActionIdentifier = Symbol.for('quino_devexpress_register_controls_startup_action');
export const DevexpressLocalizationStartupActionIdentifier = Symbol.for('quino_devexpress_localization_startup_action');
export const DevexpressShortcutStartupActionIdentifier = Symbol.for('quino_devexpress_shortcut_startup_action');

export class DevExpressModule {
  static use = (application: IApplication): void => {
    application.registerSingle<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory, ComponentFactory);
    application.registerSingle<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory, BookmarkFactory);
    application.registerSingle<IBookmarkScopingService>(BookmarkScopingServiceSymbol, BookmarkScopingService);
    application.registerSingle<IQuinoMetaPanelActions>(QuinoUIServiceSymbols.IQuinoMetaPanelActions, DefaultQuinoMetaPanelActions);
    application.registerSingle<IAuthenticationFeedback>(QuinoCoreServiceSymbols.IAuthenticationFeedback, QuinoAuthenticationFeedback);
    application.registerSingle<IQuinoAuthenticationComponentFactory>(
      QuinoUIServiceSymbols.IQuinoAuthenticationComponentFactory,
      QuinoAuthenticationComponentFactory
    );
    application.registerConstant<ILabelSettings>(QuinoUIServiceSymbols.ILabelSettings, defaultLabelSettings);
    application.registerSingle<IQuinoLabelConfigurationService>(
      QuinoUIServiceSymbols.IQuinoLabelConfigurationService,
      DefaultLabelConfigurationService
    );
    application.registerConstant<IFileUploadSettings>(QuinoUIServiceSymbols.IFileUploadSettings, defaultFileUploadSettings);
    application.registerSingle<IQuinoFileUploadConfigurationService>(
      QuinoUIServiceSymbols.IQuinoFileUploadConfigurationService,
      DefaultFileUploadConfigurationService
    );
    application.registerConstant<IQuinoDropDownSettings>(IQuinoDropDownSettingsSymbol, defaultDropDownSettings);
    application.registerConstant<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol, defaultQuinoShortcutSettings);
    application.registerSingle<IQuinoDropDownConfigurationService>(IQuinoDropDownConfigurationServiceSymbol, DefaultDropDownConfigurationService);
    application.registerSingle<IQuinoDataGridConfigurationService>(
      QuinoUIServiceSymbols.IQuinoDataGridConfigurationService,
      QuinoDataGridConfigurationService
    );
    application.registerSingle<IQuinoDataGridFilterService>(QuinoUIServiceSymbols.IQuinoDataGridFilterService, QuinoDataGridFilterService);
    application.registerSingle<IQuinoDataGridSortService>(QuinoUIServiceSymbols.IQuinoDataGridSortService, QuinoDataGridSortService);
    application.registerSingle<IFeedbackService>(IFeedbackServiceSymbol, FeedbackService);
    application.registerSingle<IWizardService>(IWizardServiceSymbol, WizardService);
    application.registerSingle<IExportButtonFactory>(IExportButtonFactorySymbol, ExportButtonFactory);
    application.registerConstant<IDashboardConfiguration>(IDashboardConfigurationSymbol, defaultDashboardConfiguration);
    application.registerSingle<IDashboardConfigurationService>(IDashboardConfigurationServiceSymbol, DashboardConfigurationService);
    application.registerSingle<ISharedDashboardsService>(ISharedDashboardsServiceSymbol, SharedDashboardsService);
    application.registerSingle<IDashboardTileRegistry>(IDashboardTileRegistrySymbol, DashboardTileRegistry);
    application.registerSingle<IDashboardSettingsService>(IDashboardSettingsServiceSymbol, DashboardSettingsService);
    application.registerSingle<IDashboardTileRegistration<IQuinoDashboardChartTileProps>>(
      ChartTileRegistrationSymbol,
      QuinoDashboardChartTileRegistration
    );
    application.registerSingle<IDashboardTileRegistration<IQuinoDashboardGridTileProps>>(
      GridTileRegistrationSymbol,
      QuinoDashboardGridTileRegistration
    );
    application.registerSingle<IDashboardTileRegistration<IQuinoDashboardWebPageTileProps>>(
      WebPageTileRegistrationSymbol,
      QuinoDashboardWebPageTileRegistration
    );
    application.registerSingle<IDashboardTileRegistration<IQuinoDashboardTranslatedTextTileProps>>(
      TranslatedTextTileRegistrationSymbol,
      QuinoDashboardTranslatedTextTileRegistration
    );
    application.registerSingle<IShortcutService>(IShortcutServiceSymbol, ShortcutService);
    application.registerSingle<IRegisterShortcutService>(IRegisterShortcutServiceSymbol, RegisterShortcutService);
    application.registerSingle<IShortcutInformationService>(IShortcutInformationServiceSymbol, ShortcutInformationService);
    application.registerSingle<IServerHealthCheckerService>(IServerHealthCheckerServiceSymbol, ServerHealthCheckerService);
    application.registerSingle<IColumnConfigurationService>(IColumnConfigurationServiceSymbol, QuinoColumnConfigurationService);

    application.registerSingle<IQuinoFileStorage>(IQuinoFileStorageSymbol, QuinoFileStorage);
    application.register<IQuinoFileHandler>(IQuinoFileHandlerSymbol, QuinoFileHandler);
    application.registerSingle<IQuinoFileStorageService>(IQuinoFileStorageServiceSymbol, QuinoFileStorageService);
    application.registerSingle<IFileUploadValidator>(IFileUploadValidatorSymbol, FileUploadValidator);

    application
      .registerStartupAction(Symbol.for('RegisterPasswordRepetitionValidator'), (app) => {
        const validatorProvider = app.get<IFieldValidatorProvider>(QuinoCoreServiceSymbols.IFieldValidatorProvider);
        const translationService = app.get<ITranslationService>(ITranslationServiceSymbol);
        validatorProvider.register(new PasswordRepetitionValidator(translationService), PasswordRepetitionValidatorSymbol);
      })
      .moveTo(InitializationGroup);

    application
      .registerStartupAction(DevexpressLocalizationStartupActionIdentifier, async (app) => {
        const languageService = app.get<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);

        loadMessages(deMessages);
        loadMessages(frMessages);
        loadMessages(itMessages);

        // Uppercase translations
        const translationsToUpperCase = ['dxFilterBuilder-and', 'dxFilterBuilder-or', 'dxFilterBuilder-notAnd', 'dxFilterBuilder-notOr'];
        const upperCaseTranslations = { en: {}, de: {} };
        translationsToUpperCase.forEach((value) => {
          upperCaseTranslations.en[value] = enMessages.en[value].toUpperCase();
          upperCaseTranslations.de[value] = deMessages.de[value].toUpperCase();
        });
        loadMessages(upperCaseTranslations);

        const currentLocale = await languageService.getCurrentLocaleAsync();
        locale(currentLocale);
      })
      .moveTo(FrameworkStartupGroup);

    application
      .registerStartupAction(DevexpressShortcutStartupActionIdentifier, (app) => {
        const shortcutService = app.get<IShortcutService>(IShortcutServiceSymbol);
        shortcutService.startListening();
      })
      .moveTo(ApplicationStartupGroup);

    application
      .registerStartupAction(DevexpressRegisterControlsStartupActionIdentifier, (app) => {
        const provider = app.get<IComponentMappingProvider>(QuinoUIServiceSymbols.IComponentMappingProvider);
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.CHECKBOX,
            component: QuinoCheckBoxLabeled,
            listEditor: QuinoCheckBox
          },
          Symbol.for(CONTROL_IDENTIFIER.CHECKBOX)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.SINGLELINETEXTEDITOR,
            component: QuinoTextBoxLabeled,
            listEditor: QuinoTextBox
          },
          Symbol.for(CONTROL_IDENTIFIER.SINGLELINETEXTEDITOR)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.MULTILINETEXTEDITOR,
            component: QuinoTextBoxLabeled,
            listEditor: QuinoTextBox
          },
          Symbol.for(CONTROL_IDENTIFIER.MULTILINETEXTEDITOR)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.PASSWORDEDITOR,
            component: QuinoTextBoxLabeled,
            listEditor: QuinoTextBox
          },
          Symbol.for(CONTROL_IDENTIFIER.PASSWORDEDITOR)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.PASSWORD_REPETITION,
            component: QuinoPasswordRepetition
          },
          Symbol.for(CONTROL_IDENTIFIER.PASSWORD_REPETITION)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.SEARCHEDIT,
            component: QuinoSearchEditLabeled,
            listEditor: QuinoSearchEdit
          },
          Symbol.for(CONTROL_IDENTIFIER.SEARCHEDIT)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.LOOKUPEDIT,
            component: QuinoDropDownLabeled,
            listEditor: QuinoDropDown
          },
          Symbol.for(CONTROL_IDENTIFIER.LOOKUPEDIT)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.COMBOBOX,
            component: QuinoDropDownLabeled,
            listEditor: QuinoDropDown
          },
          Symbol.for(CONTROL_IDENTIFIER.COMBOBOX)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.GRIDEDITOR,
            component: QuinoInlineDataGrid
          },
          Symbol.for(CONTROL_IDENTIFIER.GRIDEDITOR)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.TIMEPICKER,
            component: QuinoDateTimePickerLabeled,
            listEditor: QuinoDateTimePicker
          },
          Symbol.for(CONTROL_IDENTIFIER.TIMEPICKER)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.DATEPICKER,
            component: QuinoDateTimePickerLabeled,
            listEditor: QuinoDateTimePicker
          },
          Symbol.for(CONTROL_IDENTIFIER.DATEPICKER)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.DATETIMEPICKER,
            component: QuinoDateTimePickerLabeled,
            listEditor: QuinoDateTimePicker
          },
          Symbol.for(CONTROL_IDENTIFIER.DATETIMEPICKER)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.LABEL,
            component: QuinoLabel
          },
          Symbol.for(CONTROL_IDENTIFIER.LABEL)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.RADIOBUTTONGROUP,
            component: QuinoRadioButtonGroup,
            listEditor: QuinoDropDown
          },
          Symbol.for(CONTROL_IDENTIFIER.RADIOBUTTONGROUP)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.GROUPBOX,
            component: QuinoGroupBox
          },
          Symbol.for(CONTROL_IDENTIFIER.GROUPBOX)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.COLUMNS,
            component: QuinoColumns
          },
          Symbol.for(CONTROL_IDENTIFIER.COLUMNS)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.GROUPROW,
            component: QuinoGroupRow
          },
          Symbol.for(CONTROL_IDENTIFIER.GROUPROW)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.TABCONTAINER,
            component: QuinoTabsContainer
          },
          Symbol.for(CONTROL_IDENTIFIER.TABCONTAINER)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.RESPONSIVEBOX,
            component: QuinoResponsiveBox
          },
          Symbol.for(CONTROL_IDENTIFIER.RESPONSIVEBOX)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.IMAGEPICKER,
            component: QuinoImagePickerLabeled
          },
          Symbol.for(CONTROL_IDENTIFIER.IMAGEPICKER)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.MULTILINERICHTTEXTEDITOR,
            component: QuinoMultiLineRichTextEditorLabeled
          },
          Symbol.for(CONTROL_IDENTIFIER.MULTILINERICHTTEXTEDITOR)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.LIST,
            component: QuinoInlineDataGrid
          },
          Symbol.for(CONTROL_IDENTIFIER.LIST)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.LANGUAGEPICKER,
            component: QuinoDropDownLabeled,
            listEditor: QuinoDropDown
          },
          Symbol.for(CONTROL_IDENTIFIER.LANGUAGEPICKER)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.COLORPICKER,
            component: QuinoColorPickerLabeled,
            listEditor: QuinoColorPicker
          },
          Symbol.for(CONTROL_IDENTIFIER.COLORPICKER)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.IMAGEURLPICKER,
            component: QuinoImagePicker
          },
          Symbol.for(CONTROL_IDENTIFIER.IMAGEURLPICKER)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.URLLABEL,
            component: QuinoInlineActionButton
          },
          Symbol.for(CONTROL_IDENTIFIER.URLLABEL)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.BUTTON,
            component: QuinoInlineActionButton
          },
          Symbol.for(CONTROL_IDENTIFIER.BUTTON)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.TRANSLATIONKEYPICKER,
            component: QuinoTranslationKeyPickerLabeled,
            listEditor: QuinoTranslationKeyPicker
          },
          Symbol.for(CONTROL_IDENTIFIER.TRANSLATIONKEYPICKER)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.XMLEDITOR,
            component: XMLEditor
          },
          Symbol.for(CONTROL_IDENTIFIER.XMLEDITOR)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.METACLASSSELECTOR,
            component: MetaClassSelector
          },
          Symbol.for(CONTROL_IDENTIFIER.METACLASSSELECTOR)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.QUINOMODELEXPLORER,
            component: QuinoModelExplorer
          },
          Symbol.for(CONTROL_IDENTIFIER.QUINOMODELEXPLORER)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.ENTITYSELECTOR,
            component: EntitySelector
          },
          Symbol.for(CONTROL_IDENTIFIER.ENTITYSELECTOR)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.TIMESPAN,
            component: QuinoTextBoxLabeled,
            listEditor: QuinoTextBox
          },
          Symbol.for(CONTROL_IDENTIFIER.TIMESPAN)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.FILEUPLOAD,
            component: QuinoFileUpload
          },
          Symbol.for(CONTROL_IDENTIFIER.FILEUPLOAD)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.MULTIPURPOSETEXTEDITOR,
            component: QuinoMultiPurposeTextEditorLabeled
          },
          Symbol.for(CONTROL_IDENTIFIER.MULTIPURPOSETEXTEDITOR)
        );
        provider.register(
          {
            controlIdentifier: CONTROL_IDENTIFIER.MULTISELECTBOX,
            component: QuinoMultiSelectBoxLabeled
          },
          Symbol.for(CONTROL_IDENTIFIER.MULTISELECTBOX)
        );

        const dashboardProvider = app.get<IDashboardTileRegistry>(IDashboardTileRegistrySymbol);
        dashboardProvider.register<IQuinoDashboardChartTileProps>(
          Symbol.for('ChartTile'),
          app.get<IDashboardTileRegistration<IQuinoDashboardChartTileProps>>(ChartTileRegistrationSymbol)
        );
        dashboardProvider.register<IQuinoDashboardGridTileProps>(
          Symbol.for('GridTile'),
          app.get<IDashboardTileRegistration<IQuinoDashboardGridTileProps>>(GridTileRegistrationSymbol)
        );
        dashboardProvider.register<IQuinoDashboardWebPageTileProps>(
          Symbol.for('WebPageTile'),
          app.get<IDashboardTileRegistration<IQuinoDashboardWebPageTileProps>>(WebPageTileRegistrationSymbol)
        );
        dashboardProvider.register<IQuinoDashboardTranslatedTextTileProps>(
          Symbol.for('TranslatedTextTile'),
          app.get<IDashboardTileRegistration<IQuinoDashboardTranslatedTextTileProps>>(TranslatedTextTileRegistrationSymbol)
        );
      })
      .moveTo(InitializationGroup);
  };
}
