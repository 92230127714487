import { QuinoUIServiceSymbols, useService } from '../ioc';
import { IDashboardLayout, IDashboardSettingsService, IDashboardSettingsServiceSymbol } from './settings';
import { IFeedbackService, IFeedbackServiceSymbol } from '../feedback';
import React, { useCallback, useEffect, useState } from 'react';
import { QuinoDashboardAddTileButton } from './QuinoDashboardAddTileButton';
import { Button } from 'devextreme-react/button';
import {
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  ITranslationService,
  ITranslationServiceSymbol,
  IUserInfoService,
  IUserInfoServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { useDashboardConfiguration } from './configuration';
import { IBookmarkFactory, IDashboardBookmark } from '../bookmarks';
import { TResponsiveMode, useResponsiveMode } from '../responsivity';
// NOTE: Do not shorten following imports to avoid circular dependencies
import { isIRefreshableBookmark } from '../bookmarks/IRefreshableBookmark';
import { INavigationService, INavigationServiceSymbol } from '../navigation/INavigationService';
import { IQuinoContextMenuItem, QuinoContextMenuButton } from '../components/ContextMenu';
import { DashboardType } from './DashboardType';

export function QuinoDashboardActions(props: { bookmark: IDashboardBookmark }) {
  const responsiveMode = useResponsiveMode();
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const userInfoService = useService<IUserInfoService>(IUserInfoServiceSymbol);
  const settingsService = useService<IDashboardSettingsService>(IDashboardSettingsServiceSymbol);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const feedbackService = useService<IFeedbackService>(IFeedbackServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  const dashboardConfiguration = useDashboardConfiguration();

  const bookmark: IDashboardBookmark = props.bookmark;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [pendingChanges, setPendingChanges] = useState<boolean>(false);
  const dashboardType = bookmark.getCurrentDashboard().dashboardType;
  const isRefreshableBookmark = isIRefreshableBookmark(bookmark);

  const isAdmin = userInfoService.isInRole('admin');
  const isPersonalDashboard = dashboardType == DashboardType.Personal;
  const isSharedDashboard = dashboardType == DashboardType.Shared;

  const canEdit = isPersonalDashboard || (isSharedDashboard && isAdmin);
  const canShare = isPersonalDashboard && dashboardConfiguration.sharedDashboardsEnabled && isAdmin;
  const canUnShare = isSharedDashboard && dashboardConfiguration.sharedDashboardsEnabled && isAdmin;

  const maxDashboardVisibleExpressionLength = 255;

  useEffect(() => {
    setEditMode(false);
    const symbol = bookmark.subscribe((event) => {
      if (event.type === 'changes') {
        setPendingChanges(event.payload);
      } else if (event.type === 'edit') {
        setEditMode(event.payload);
      }
    });
    return () => bookmark.unsubscribe(symbol);
  }, [bookmark]);

  const refreshClickAction = useCallback(() => {
    if (isRefreshableBookmark) {
      bookmark.refresh();
    }
  }, [bookmark, isRefreshableBookmark]);

  const shareDashboard = (visibleExpression: string) => {
    const unload = loadingFeedback.load();
    settingsService
      .deleteCustomDashboard(bookmark.getCurrentDashboard().name, false)
      .then(async () => settingsService.saveSharedDashboard(bookmark.getCurrentDashboard(), visibleExpression))
      .then(async (defaultLayout) => bookmarkFactory.createDashboard(defaultLayout))
      .then(async (newBookmark) => navigationService.replaceCurrent(newBookmark))
      .finally(() => unload())
      .catch((e) => logger.logError(e));
  };

  const unShareDashboard = () => {
    const unload = loadingFeedback.load();
    settingsService
      .deleteSharedDashboard(bookmark.getCurrentDashboard().name, false)
      .then(async () => {
        const dashboardLayout = await settingsService.saveCustomDashboard(bookmark.getCurrentDashboard());
        dashboardLayout.visible = true;
        dashboardLayout.visibleHumanReadable = '';
        return dashboardLayout;
      })
      .then(async (defaultLayout) => bookmarkFactory.createDashboard(defaultLayout))
      .then(async (newBookmark) => navigationService.replaceCurrent(newBookmark))
      .finally(() => unload())
      .catch((e) => logger.logError(e));
  };

  const shareDashboardActions: IQuinoContextMenuItem[] = [
    {
      text: translationService.translate('Share'),
      icon: 'material-icons-outlined cloud',
      visible: canShare,
      onItemClick: () => {
        feedbackService
          .getString(
            translationService.translate('Dashboard.Options.Share'),
            translationService.translate('Dashboard.Options.VisibleExpressionText'),
            translationService.translate('Share'),
            'material-icons-outlined cloud',
            undefined,
            undefined,
            undefined,
            maxDashboardVisibleExpressionLength
          )
          .then((result) => {
            if (result.confirmed) {
              shareDashboard(result.value);
            }
          })
          .catch((e) => logger.logError(e));
      }
    },
    {
      text: translationService.translate('UnShare'),
      icon: 'material-icons-outlined cloud_off',
      visible: canUnShare,
      onItemClick: () => {
        feedbackService
          .getConfirmation(
            translationService.translate('Dashboard.Options.UnShare'),
            translationService.translate('Dashboard.Options.UnShareText'),
            translationService.translate('UnShare'),
            'material-icons-outlined cloud_off',
            undefined,
            undefined
          )
          .then((result) => {
            if (result) {
              unShareDashboard();
            }
          })
          .catch((e) => logger.logError(e));
      }
    },
    {
      text: translationService.translate('Dashboard.Options.EditVisibleExpression'),
      icon: 'material-icons-outlined visibility',
      visible: canUnShare,
      onItemClick: () => {
        feedbackService
          .getString(
            translationService.translate('Dashboard.Options.VisibleExpressionTitle'),
            translationService.translate('Dashboard.Options.VisibleExpressionText'),
            translationService.translate('Save'),
            'material-icons-outlined save',
            undefined,
            undefined,
            `${bookmark.getCurrentDashboard().visibleHumanReadable}`,
            maxDashboardVisibleExpressionLength
          )
          .then((result) => {
            if (result.confirmed) {
              shareDashboard(result.value);
            }
          })
          .catch((e) => logger.logError(e));
      }
    }
  ];

  return (
    <>
      {!dashboardConfiguration.readOnly && responsiveMode !== TResponsiveMode.Phone && (
        <div className={'quino-dashboard-actions'}>
          {editMode ? (
            <>
              <QuinoDashboardAddTileButton bookmark={bookmark} />
              <Button
                text={translationService.translate('Cancel')}
                icon={'material-icons-outlined clear'}
                onClick={() => bookmark.reset()}
                type={'default'}
                stylingMode={'outlined'}
              />
              <Button
                text={translationService.translate('Save')}
                disabled={!pendingChanges}
                icon={'material-icons-outlined save'}
                onClick={async () => bookmark.save()}
                type={'default'}
                stylingMode={'contained'}
              />
            </>
          ) : (
            <>
              <Button
                icon={'material-icons-outlined autorenew'}
                onClick={refreshClickAction}
                visible={isRefreshableBookmark}
                hint={translationService.translate('Dropdown.RefreshContent')}
                stylingMode={'text'}
                type={'normal'}
              />
              <QuinoContextMenuButton
                buttonStylingMode={'text'}
                buttonType={'normal'}
                buttonIcon={canShare ? `material-icons-outlined cloud` : `material-icons cloud`}
                dataSource={shareDashboardActions}
                buttonId={'shareDashboardActionsButton'}
                visible={(canShare || canUnShare) && shareDashboardActions.length > 0}
              />
              <QuinoDashboardAdvancedActionsButton bookmark={bookmark} dashboardType={dashboardType} />
              <Button
                text={translationService.translate('Edit')}
                icon={'material-icons-outlined create'}
                onClick={() => bookmark.setEditMode(true)}
                type={'default'}
                stylingMode={'outlined'}
                visible={canEdit}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

function QuinoDashboardAdvancedActionsButton(props: { bookmark: IDashboardBookmark; dashboardType: DashboardType }) {
  const bookmark = props.bookmark;
  const settingsService = useService<IDashboardSettingsService>(IDashboardSettingsServiceSymbol);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const feedbackService = useService<IFeedbackService>(IFeedbackServiceSymbol);
  const userInfoService = useService<IUserInfoService>(IUserInfoServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const dashboardConfiguration = useDashboardConfiguration();

  const maxDashboardCaptionLength = 64;

  const isAdmin = userInfoService.isInRole('admin');
  const isPersonalDashboard = props.dashboardType == DashboardType.Personal;
  const isSharedDashboard = props.dashboardType == DashboardType.Shared;

  const canRename = isPersonalDashboard || (isSharedDashboard && isAdmin);
  const canAddOrDuplicate = dashboardConfiguration.customDashboardsEnabled || (dashboardConfiguration.sharedDashboardsEnabled && isAdmin);
  const canDelete = isPersonalDashboard || (isSharedDashboard && isAdmin);

  const renameDashboard = (caption: string): void => {
    const unload = loadingFeedback.load();
    let promise: Promise<IDashboardLayout>;
    if (isPersonalDashboard) {
      promise = settingsService.saveCustomDashboard({ ...bookmark.getCurrentDashboard(), caption: caption });
    } else if (isSharedDashboard) {
      promise = settingsService.saveSharedDashboard({ ...bookmark.getCurrentDashboard(), caption: caption });
    } else {
      logger.logWarn(`System dashboards can not be renamed. Tried to rename system dashboard ${bookmark.getCurrentDashboard().name}`);
      return;
    }

    promise
      .then(async (renamedLayout) => bookmarkFactory.createDashboard(renamedLayout))
      .then(async (newBookmark) => navigationService.replaceCurrent(newBookmark))
      .finally(() => unload())
      .catch((e) => logger.logError(e));
  };

  const createDashboard = (caption: string): void => {
    const unload = loadingFeedback.load();
    settingsService
      .createCustomDashboard(caption)
      .then(async (dashboardLayout) => bookmarkFactory.createDashboard(dashboardLayout))
      .then(async (newBookmark) => navigationService.replaceCurrent(newBookmark))
      .finally(() => unload())
      .catch((e) => logger.logError(e));
  };

  const duplicateDashboard = (caption: string): void => {
    const unload = loadingFeedback.load();
    settingsService
      .duplicateCustomDashboard(bookmark.getCurrentDashboard(), caption)
      .then(async (duplicatedLayout) => bookmarkFactory.createDashboard(duplicatedLayout))
      .then(async (newBookmark) => navigationService.replaceCurrent(newBookmark))
      .finally(() => unload())
      .catch((e) => logger.logError(e));
  };

  const deleteDashboard = (): void => {
    const unload = loadingFeedback.load();

    let promise: Promise<IDashboardLayout>;
    if (isPersonalDashboard) {
      promise = settingsService.deleteCustomDashboard(bookmark.getCurrentDashboard().name, true);
    } else if (isSharedDashboard) {
      promise = settingsService.deleteSharedDashboard(bookmark.getCurrentDashboard().name, true);
    } else {
      logger.logWarn(`System dashboards can not be deleted. Tried to delete system dashboard ${bookmark.getCurrentDashboard().name}`);
      return;
    }

    promise
      .then(async (defaultLayout) => bookmarkFactory.createDashboard(defaultLayout))
      .then(async (newBookmark) => navigationService.replaceCurrent(newBookmark))
      .finally(() => unload())
      .catch((e) => logger.logError(e));
  };

  const advancedActions: IQuinoContextMenuItem[] = [
    {
      icon: 'material-icons-outlined drive_file_rename_outline',
      text: translationService.translate('Dashboard.Options.Rename'),
      visible: canRename,
      onItemClick: () => {
        feedbackService
          .getString(
            translationService.translate('Dashboard.Options.Rename'),
            translationService.translate('Dashboard.Options.RenameText'),
            translationService.translate('Rename'),
            'material-icons-outlined drive_file_rename_outline',
            undefined,
            undefined,
            bookmark.getCurrentDashboard().caption,
            maxDashboardCaptionLength
          )
          .then((result) => {
            if (result.confirmed) {
              renameDashboard(result.value);
            }
          })
          .catch((e) => logger.logError(e));
      }
    },
    {
      icon: 'material-icons-outlined add',
      text: translationService.translate('Dashboard.Options.Add'),
      visible: canAddOrDuplicate,
      onItemClick: () => {
        feedbackService
          .getString(
            translationService.translate('Dashboard.Options.Add'),
            translationService.translate('Dashboard.Options.AddText'),
            translationService.translate('Create'),
            'material-icons-outlined add',
            undefined,
            undefined,
            undefined,
            maxDashboardCaptionLength
          )
          .then((result) => {
            if (result.confirmed) {
              createDashboard(result.value);
            }
          })
          .catch((e) => logger.logError(e));
      }
    },
    {
      icon: 'material-icons-outlined content_copy',
      text: translationService.translate('Dashboard.Options.Duplicate'),
      visible: canAddOrDuplicate,
      onItemClick: () => {
        feedbackService
          .getString(
            translationService.translate('Dashboard.Options.Duplicate'),
            translationService.translate('Dashboard.Options.AddText'),
            translationService.translate('Create'),
            'material-icons-outlined add',
            undefined,
            undefined,
            translationService.translate('CopyOf') + ' ' + bookmark.getCurrentDashboard().caption,
            maxDashboardCaptionLength
          )
          .then((result) => {
            if (result.confirmed) {
              duplicateDashboard(result.value);
            }
          })
          .catch((e) => logger.logError(e));
      }
    },
    {
      icon: 'material-icons-outlined delete',
      text: translationService.translate('Dashboard.Options.Delete'),
      visible: canDelete,
      onItemClick: () => {
        feedbackService
          .getConfirmation(
            translationService.translate('Dashboard.Options.Delete'),
            translationService.translate('Dashboard.Options.DeleteText'),
            translationService.translate('Delete'),
            'material-icons-outlined delete'
          )
          .then((result) => {
            if (result) {
              deleteDashboard();
            }
          })
          .catch((e) => logger.logError(e));
      }
    }
  ];

  return (
    <QuinoContextMenuButton
      buttonStylingMode={'text'}
      buttonType={'normal'}
      buttonIcon={'material-icons-outlined more_vert'}
      dataSource={advancedActions}
      buttonId={'test'}
      visible={advancedActions.filter((value) => value.visible).length > 0}
    />
  );
}
