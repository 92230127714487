import { IExpression } from './IExpression';

export interface ITextGroupExpression extends IExpression {
  Count: number;
  Element0: IExpression;
}

export const isTextGroupExpression = (object: IExpression): object is ITextGroupExpression => {
  return (object as ITextGroupExpression).Count !== undefined && (object as ITextGroupExpression).ExpressionType === 10;
};
