import { IBookmark } from '../bookmarks';
import { IBookmarkActionsAspect } from '../aspects';

/**
 * The symbol for resolving the pending changes service.
 */
export const IPendingChangesServiceSymbol = Symbol.for('IPendingChangesService');

export interface IPendingChangesService {
  /**
   * Get feedback from user whether to stay on page, discard or save changes
   @param bookmarkActionOptions - the bookmark actions for customizing
   */
  getLeavingFeedback(bookmarkActionOptions?: IBookmarkActionsAspect | null): Promise<string>;

  /**
   * Handle attempt to leave the current bookmark
   * @param bookmark - the bookmark which needs to be checked for pending changes
   * @param notifyInGlobalArea - whether to show notifications in global area (else they are shown in default area)
   * @param preventUIUpdate - whether to prevent notifiying change handlers after discard/save
   * @returns boolean - whether leaving is allowed
   */
  getLeavePermission(bookmark: IBookmark | undefined, notifyInGlobalArea?: boolean, preventUIUpdate?: boolean): Promise<boolean>;
}
