import { IHashedMetaModel, IMetaModel } from '../meta';

/**
 * The symbol for the standard model fetcher
 */
export const IModelFetcherSymbol = Symbol.for('IModelFetcher');

/**
 * The symbol for the public model fetcher
 */
export const IPublicModelFetcherSymbol = Symbol.for('PublicModelFetcher');

/**
 * Fetches the model from the API.
 */
export interface IModelFetcher {
  /**
   * Fetches the model from the API
   */
  fetch: () => Promise<IMetaModel>;

  /**
   * Fetches the model hash from the API
   */
  fetchHash: () => Promise<string>;

  /**
   * Fetches the model with its hash from the API
   */
  fetchCurrentModel(hash: string | null): Promise<IHashedMetaModel>;

  /**
   * The model storage key to cache the model
   */
  storageKey: () => string;

  /**
   * The model hash storage key to cache the model hash
   */
  hashStorageKey: () => string;
}

export interface IPublicModelFetcher extends IModelFetcher {}
