import { IApplication } from './IApplication';
import { Container, interfaces } from 'inversify';
import { setupLazyInject } from '../ioc/lazyInject';
import { ApplicationStartupGroup, ExecutionGroup } from './ExecutionGroup';
import { Executable } from './Executable';
import { RootExecutable } from './RootExecutable';

export class QuinoApplication extends Container implements IApplication {
  register = <TService>(identifier: interfaces.ServiceIdentifier<TService>, implementation: new (...args: any[]) => TService): IApplication => {
    this.checkSealed();
    this.unbindIfBound(identifier);

    this.bind<TService>(identifier).to(implementation).inTransientScope();

    return this;
  };

  registerConstant = <TService>(identifier: interfaces.ServiceIdentifier<TService>, value: TService): IApplication => {
    this.checkSealed();
    this.unbindIfBound<TService>(identifier);

    this.bind<TService>(identifier).toConstantValue(value);

    return this;
  };

  registerSingle = <TService>(identifier: interfaces.ServiceIdentifier<TService>, implementation: new (...args: any[]) => TService): IApplication => {
    this.checkSealed();
    this.unbindIfBound<TService>(identifier);

    this.bind<TService>(identifier).to(implementation).inSingletonScope();

    return this;
  };

  bind = <T>(serviceIdentifier: interfaces.ServiceIdentifier<T>): interfaces.BindingToSyntax<T> => {
    this.checkSealed();

    return super.bind(serviceIdentifier);
  };

  unbindAll = (): void => {
    this.checkSealed();

    super.unbindAll();
  };

  unbind = (serviceIdentifier: interfaces.ServiceIdentifier<any>): void => {
    this.checkSealed();

    super.unbind(serviceIdentifier);
  };

  build = async (): Promise<void> => {
    setupLazyInject(this);
    this.bind<IApplication>(Symbol.for('IApplication')).toConstantValue(this);

    this.sealed = true;

    await this.actionContainer.execute(this);
  };

  registerStartupAction = (symbol: Symbol, action: (app: IApplication) => void | Promise<any>): Executable => {
    this.unregisterStartupAction(symbol);

    const applicationGroup = this.actionContainer.get(ApplicationStartupGroup) as ExecutionGroup;
    const executable = new Executable(symbol, applicationGroup, action);
    applicationGroup.children.push(executable);

    return executable;
  };

  registerStartupGroup = (symbol: Symbol): ExecutionGroup => {
    const executionGroup = new ExecutionGroup(symbol, this.actionContainer);
    this.actionContainer.register(executionGroup);

    return executionGroup;
  };

  unregisterStartupAction = (identifier: Symbol): void => {
    this.actionContainer.remove(identifier);
  };

  private readonly unbindIfBound = <TIdentifier>(identifier: interfaces.ServiceIdentifier<TIdentifier>): void => {
    if (this.isBound(identifier)) {
      this.unbind(identifier);
    }
  };

  private readonly checkSealed = (): void => {
    if (this.sealed) {
      throw new Error('The container has been sealed. No registration can be done after this point.');
    }
  };

  private sealed = false;
  public readonly actionContainer = new RootExecutable();
}
