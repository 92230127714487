import { ReactNode } from 'react';
/**
 * Symbol for the IHighlightText service.
 */
export const IHighlightTextServiceSymbol = Symbol.for('IHighlightTextService');

/**
 * Gets a highlighted text.
 */
export interface IHighlightTextService {
  /**
   * Calculate the format mask.
   * @param text - the text where strings should be highlighted.
   * @param highlightText - the text which should be highlighted.
   * @return a ReactNode which contains a text with highlighted <span> elements.
   */
  highlight(text: string, highlightText: string | undefined): ReactNode;
}
