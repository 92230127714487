import { IExecutable } from './IExecutable';
import { IApplication } from './IApplication';
import { RootExecutable } from './RootExecutable';

/**
 * Leaf executable that does not contain any children.
 */
export class Executable implements IExecutable {
  constructor(public identifier: Symbol, public parent: IExecutable, private readonly action: (app: IApplication) => void | Promise<any>) {}

  execute = (app: IApplication): void | Promise<any> => {
    return this.action(app);
  };

  moveTo = (group: Symbol) => {
    let root = this.parent;
    while (true) {
      if (root.parent != null) {
        root = root.parent;
      } else {
        break;
      }
    }

    const rootExecutable = root as RootExecutable;
    rootExecutable.remove(this.identifier);
    rootExecutable.registerForGroup(this, group);
  };

  children: IExecutable[] = [];
}
