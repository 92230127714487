import React, { PropsWithChildren, useMemo, useState } from 'react';
import {
  ContextContextDecorator,
  contextContextDecoratorSymbol,
  ContextRequestDecorator,
  contextRequestDecoratorSymbol,
  currentContextStorageKey,
  ExpressionContextChangedMessage,
  IContextDecoratorProvider,
  IMessenger,
  IMessengerSymbol,
  IQuinoContext,
  IRequestDecoratorProvider,
  LocalStorageService,
  LocalStorageServiceSymbol,
  QuinoContextContext,
  QuinoCoreServiceSymbols,
  INamespaceProvider,
  NamespaceProviderSymbol
} from '@quino/core';
import { useService } from '../ioc';
import { isIRefreshableBookmark } from '../bookmarks';
import { useOnNavigation } from '../navigation';
import { IDataGridSettingsService, IDataGridSettingsServiceSymbol } from '../components/QuinoDataGrid/IDataGridSettingsService';
import { ContextNamespace, ContextNamespaceSymbol } from '../expression/ContextNamespace';

export function QuinoContextContextProvider(props: PropsWithChildren<{}>) {
  const localStorageService = useService<LocalStorageService>(LocalStorageServiceSymbol);
  const requestDecoratorProvider = useService<IRequestDecoratorProvider>(QuinoCoreServiceSymbols.IRequestDecoratorProvider);
  const contextDecoratorProvider = useService<IContextDecoratorProvider>(QuinoCoreServiceSymbols.IContextDecoratorProvider);
  const namespaceProvider = useService<INamespaceProvider>(NamespaceProviderSymbol);
  const settingsService = useService<IDataGridSettingsService>(IDataGridSettingsServiceSymbol);

  const messenger = useService<IMessenger>(IMessengerSymbol);

  const bookmark = useOnNavigation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentContext, setCurrentContextInternal] = useState<IQuinoContext>();
  const [availableContexts, setAvailableContexts] = useState<IQuinoContext[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setCurrentContext = async (context: IQuinoContext) => {
    if (!context) {
      await localStorageService.delete(currentContextStorageKey);
      return;
    }

    const contextRequestDecorator = new ContextRequestDecorator();
    requestDecoratorProvider.register(contextRequestDecorator, contextRequestDecoratorSymbol);
    contextRequestDecorator.context = context;

    const contextContextDecorator = new ContextContextDecorator();
    contextDecoratorProvider.register(contextContextDecorator, contextContextDecoratorSymbol);
    contextContextDecorator.context = context;

    const contextNamespace = new ContextNamespace();
    contextNamespace.context = context;
    namespaceProvider.register(contextNamespace, ContextNamespaceSymbol);

    const message = new ExpressionContextChangedMessage();
    message.context = context;
    messenger.notify(message);

    await localStorageService.set(currentContextStorageKey, context);
    await settingsService.clearDataGridFilterSettings();

    isIRefreshableBookmark(bookmark) && bookmark.refresh();

    setCurrentContextInternal(context);
  };

  const context = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      currentContext,
      setCurrentContext,
      availableContexts,
      setAvailableContexts
    }),
    [isLoading, currentContext, setCurrentContext, availableContexts]
  );

  return <QuinoContextContext.Provider value={context}>{props.children}</QuinoContextContext.Provider>;
}
