import { QuinoErrorSummary, QuinoLabeled, QuinoLoadButton, QuinoPasswordBox, searchForErrors, useService } from '@quino/ui';
import { TextBox } from 'devextreme-react/text-box';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AuthenticationState,
  IAuthenticationResult,
  IAuthenticationService,
  ILanguageService,
  ILogger,
  ITokenRequestService,
  ITranslationService,
  ITranslationServiceSymbol,
  PasswordAuthenticationData,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { CUIConfirmationDialogComponent } from './CUIConfirmationDialogComponent';
import { AuthenticationScreenState } from './AuthenticationScreenState';
import { ScrollView } from 'devextreme-react/scroll-view';
import { CUIAuthenticationMarkdownBlock } from './CUIAuthenticationMarkdownBlock';
import { useBackgroundImage } from '../Util';
import { useCuiSettings } from '../../util';
import { ThirdPartyAuthenticationBlock } from './ThirdPartyAuthenticationBlock';

export interface ICUILoginComponentProps {
  onLogin: () => void;
  onAccountNotActivated: () => void;
  onPasswordResetClick: () => void;
  onUserRegistrationClick: () => void;
  onConfirmEmailSent: () => void;
  goToLogin: () => void;
  focusRequested: boolean;
  screenState: AuthenticationScreenState;
}

export function CUILoginComponent(props: React.PropsWithChildren<ICUILoginComponentProps>) {
  const { onLogin, onAccountNotActivated, onConfirmEmailSent, focusRequested, screenState } = props;

  const [usernameErrors, setUsernameErrors] = useState<string[]>([]);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [error, setError] = useState<string[] | undefined>(undefined);
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const usernameTextBox = useRef<TextBox | null>(null);
  const passwordTextBox = useRef<TextBox | null>(null);

  const password = (): string => passwordTextBox.current?.instance?.option('text') || '';

  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const authenticationService = useService<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService);
  const tokenRequestService = useService<ITokenRequestService>(QuinoCoreServiceSymbols.ITokenRequestService);
  const languageService = useService<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  const userObject = useMemo<{ user: string; token: string }>(() => {
    const params = new URLSearchParams(document.location.search);
    return { user: params.get('email')!, token: params.get('token')! };
  }, []);

  const cuiSettings = useCuiSettings();

  const setFocusToUsernameTextBox = () => {
    usernameTextBox.current?.instance && usernameTextBox.current.instance.focus();
  };

  useBackgroundImage(cuiSettings.loginBackgroundImage, 'Login.BackGroundImage', loggedIn);

  useEffect(() => {
    setFocusToUsernameTextBox();
  }, [focusRequested]);

  const requiredCheckFunction = useCallback(
    (text: string) => {
      return { isValid: text !== '', errorMessage: translationService.translate('Validations.RequiredError') };
    },
    [translationService]
  );

  const markdownForgotPassword = translationService.exists('Authentication.MarkdownForgotPassword')
    ? translationService.translate('Authentication.MarkdownForgotPassword')
    : '';
  const markdownRegister = translationService.exists('Authentication.MarkdownRegister')
    ? translationService.translate('Authentication.MarkdownRegister')
    : '';
  const markdownLoginGreeting = translationService.exists('Authentication.MarkdownLoginGreeting')
    ? translationService.translate('Authentication.MarkdownLoginGreeting')
    : '';

  const goToLogin = () => {
    setError([]);
    props.goToLogin();
  };

  const authenticate = () => {
    let errorCount = 0;
    errorCount += searchForErrors(username, setUsernameErrors, [requiredCheckFunction]);
    errorCount += searchForErrors(password(), setPasswordErrors, [requiredCheckFunction]);
    if (errorCount > 0) {
      return;
    }
    setError(undefined);
    setLoading(true);

    authenticationService
      .loginAsync(new PasswordAuthenticationData(username, password()))
      .then((result: IAuthenticationResult) => {
        switch (result.state) {
          case AuthenticationState.Successful:
            setLoggedIn(true);
            languageService
              .updateLocalLanguageAsync()
              .then((willReloadWindow) => {
                !willReloadWindow && onLogin();
              })
              .catch(logger.logError);
            break;
          case AuthenticationState.Failed:
            setError([translationService.translate('QuinoAuthenticationFeedback.FailedToAuthenticate')]);
            break;
          case AuthenticationState.EmailNotConfirmed:
          case AuthenticationState.PhoneNotConfirmed:
            onAccountNotActivated();
            break;
          case AuthenticationState.AccountLocked:
            setError([translationService.translate('QuinoAuthenticationFeedback.AccountLocked')]);
            break;
          case AuthenticationState.RequiresTwoFactor:
            setError([translationService.translate('QuinoAuthenticationFeedback.RequiresTwoFactor')]);
            break;
          default:
            if (result.error) {
              if (Array.isArray(result.error)) {
                setError(result.error.map((x) => x.description));
              } else {
                setError([result.error]);
              }
            }
            break;
        }
      })
      .catch(() => {
        const message = translationService.translate('QuinoAuthenticationFeedback.FailedToAuthenticate');
        setError([message]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendConfirmationEmail = () => {
    setLoading(true);
    tokenRequestService
      .requestActivationEmailAsync(username)
      .then(onConfirmEmailSent)
      .catch(() => {
        const message = translationService.translate('QuinoAuthenticationFeedback.FailedToSendEmail');
        setError([message]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmEmail = () => {
    tokenRequestService.confirmEmailAsync(userObject.user, userObject.token).catch(() => {
      const message = translationService.translate('QuinoAuthenticationFeedback.FailedToConfirmEmail');
      setError([message]);
    });
  };

  let title = '';
  let description = '';
  let buttonText = '';

  switch (screenState) {
    case AuthenticationScreenState.EmailConfirmationRequiredForm:
      title = translationService.translate('QuinoAuthenticationFeedback.TitleRegistrationIncomplete');
      description = translationService.translate('QuinoAuthenticationFeedback.ConfirmationSentDescription');
      buttonText = translationService.translate('QuinoAuthenticationFeedback.ButtonSendConfirmEmail');

      return (
        <CUIConfirmationDialogComponent
          onPerformConfirmAction={sendConfirmationEmail}
          goToLogin={goToLogin}
          error={error}
          title={title}
          description={description}
          buttonText={buttonText}
          hintText={<></>}
          icon={'material-icons-outlined mail'}
          buttonIsDefault={true}
          loading={loading}
        />
      );
    case AuthenticationScreenState.RegistrationConfirmationForm:
      if (error == null) {
        confirmEmail();
      }

      title = translationService.translate('QuinoAuthenticationFeedback.TitleRegistrationSuccessful');
      description = translationService.translate('QuinoAuthenticationFeedback.RegistrationSuccessfulDescription');
      buttonText = translationService.translate('QuinoAuthenticationFeedback.ButtonGotoLogin');

      return (
        <CUIConfirmationDialogComponent
          onPerformConfirmAction={goToLogin}
          goToLogin={undefined}
          error={error}
          title={title}
          description={description}
          buttonText={buttonText}
          hintText={<></>}
          icon={'material-icons-outlined lock'}
          buttonIsDefault={true}
          loading={loading}
        />
      );
    case AuthenticationScreenState.EmailConfirmationSentForm: {
      title = translationService.translate('QuinoAuthenticationFeedback.TitleEmailSent');
      description = translationService.translate('QuinoAuthenticationFeedback.RegistrationMailSentDescription');
      buttonText = translationService.translate('QuinoAuthenticationFeedback.ButtonGotoLogin');
      const contactSupportString = translationService.translate('QuinoAuthenticationFeedback.HintContactSupport');
      const needHelpString = translationService.translate('QuinoAuthenticationFeedback.HintNeedHelp');
      const hintText = (
        <p className={'quino-auth-hint-text'}>
          {needHelpString} <a href={cuiSettings.supportUrl}>{contactSupportString}</a>
        </p>
      );

      return (
        <CUIConfirmationDialogComponent
          onPerformConfirmAction={goToLogin}
          goToLogin={undefined}
          error={error}
          title={title}
          description={description}
          buttonText={buttonText}
          hintText={hintText}
          icon={'material-icons-outlined lock'}
          buttonIsDefault={false}
          loading={loading}
        />
      );
    }
    case AuthenticationScreenState.ResetPasswordConfirmationForm:
      title = translationService.translate('QuinoAuthenticationFeedback.TitleConfirmPasswordChange');
      description = translationService.translate('QuinoAuthenticationFeedback.ConfirmPasswordChangeDescription');
      buttonText = translationService.translate('QuinoAuthenticationFeedback.ButtonGotoLogin');

      return (
        <CUIConfirmationDialogComponent
          onPerformConfirmAction={goToLogin}
          goToLogin={undefined}
          error={error}
          title={title}
          description={description}
          buttonText={buttonText}
          hintText={<></>}
          icon={'material-icons-outlined lock'}
          buttonIsDefault={true}
          loading={loading}
        />
      );
    default:
      return (
        <>
          <h3 className={'quino-auth-title'}>{translationService.translate('QuinoAuthenticationFeedback.Title')}</h3>
          <form className={'quino-auth-form-content'}>
            <ScrollView className={'quino-auth-scrollview'} useNative={false}>
              {/*Do not remove div to prevent scroll view crashes when empty*/}
              <div>
                <CUIAuthenticationMarkdownBlock content={markdownLoginGreeting} customClasses={'margin-bottom'} />
                <QuinoLabeled
                  label={translationService.translate('QuinoAuthenticationFeedback.Username')}
                  required={true}
                  errorMessages={usernameErrors}>
                  <TextBox
                    id='login'
                    // @ts-ignore
                    ref={usernameTextBox}
                    isValid={usernameErrors.length === 0}
                    value={username}
                    onValueChanged={(e) => {
                      setUsername(e.value);
                      searchForErrors(e.value, setUsernameErrors, [requiredCheckFunction]);
                    }}
                    onEnterKey={authenticate}
                    valueChangeEvent={'change'}
                  />
                </QuinoLabeled>
                <QuinoLabeled
                  label={translationService.translate('QuinoAuthenticationFeedback.Password')}
                  required={true}
                  errorMessages={passwordErrors}>
                  <QuinoPasswordBox
                    id='password'
                    // @ts-ignore
                    ref={passwordTextBox}
                    isValid={passwordErrors.length === 0}
                    onValueChanged={(e) => searchForErrors(e.value, setPasswordErrors, [requiredCheckFunction])}
                    onEnterKey={authenticate}
                    valueChangeEvent={'change'}
                  />
                </QuinoLabeled>
                <QuinoErrorSummary errorMessages={error} topPadding />
                <CUIAuthenticationMarkdownBlock content={markdownForgotPassword} customClasses={'grey-text'} />
              </div>
            </ScrollView>
            <div className={'quino-auth-footer'}>
              <QuinoLoadButton
                text={translationService.translate('QuinoAuthenticationFeedback.Button')}
                icon={'material-icons-outlined lock_open'}
                type='default'
                stylingMode='contained'
                className={'quino-auth-button'}
                onClick={authenticate}
                loading={loading}
              />
              <CUIAuthenticationMarkdownBlock content={markdownRegister} customClasses={'grey-text centered no-margin'} />
              <ThirdPartyAuthenticationBlock />
            </div>
          </form>
        </>
      );
  }
}
