import { ICUISettings } from './ICUISettings';

export const ICUISettingsConfigurationServiceSymbol = Symbol.for('ICUISettingsConfigurationService');

export interface ICUISettingsConfigurationService {
  /**
   * Gets the CUISettings
   */
  getCuiSettings(): ICUISettings;
}
