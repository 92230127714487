import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for the sizing aspect.
 */
export const SizingAspectIdentifier = 'sizing';

/**
 * The sizing aspect is used to define the preferred size of a control.
 */
export interface ISizingAspect extends IMetaAspect {
  horizontalUnits: number;
  verticalUnits: number;
  horizontalSizeMode: SizeMode;
  verticalSizeMode: SizeMode;
}

export type SizeMode = 'Percent' | 'Absolute' | 'AutoSize';
