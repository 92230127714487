import { ILayoutScopeManager } from './ILayoutScopeManager';
import { ILayoutScope } from './ILayoutScope';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IMetadataTree } from '../api';
import { IExpressionEvaluator } from '../expressions';
import { EmptyLayoutScope } from './EmptyLayoutScope';
import { ILogger } from '../logging';
import { IMetaLayout } from '../meta';

@injectable()
export class LayoutScopeManager implements ILayoutScopeManager {
  constructor(
    @inject(QuinoCoreServiceSymbols.IMetadataTree) public metadataTree: IMetadataTree,
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) public expressionEvaluator: IExpressionEvaluator,
    @inject(QuinoCoreServiceSymbols.ILogger) public logger: ILogger
  ) {}

  push(scope: ILayoutScope): void;
  push(scope: ILayoutScope, clear: boolean): void;
  push(scope: ILayoutScope, clear?: boolean): void {
    if (clear && this.scopes.length > 0) {
      this.scopes.splice(1);
    }

    this.scopes.push(scope);

    this.logger.logDebug(`Updated scope`);
  }

  pushGlobalScope(scope: ILayoutScope): void {
    this.scopes[0] = scope;
  }

  reduce(payload: IMetaLayout[]): IMetaLayout[] {
    let result = payload;
    for (const scope of this.scopes) {
      result = result.filter((layout) => scope.applicable(layout, this.expressionEvaluator));
    }

    return result;
  }

  clear(): void {
    this.scopes.splice(0);

    this.logger.logDebug(`Cleared scope.`);
  }

  private readonly scopes: ILayoutScope[] = [new EmptyLayoutScope()];
}
