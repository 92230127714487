import React, { useMemo, useState } from 'react';
import { ThirdPartyAuthenticationButton } from './ThirdPartyAuthenticationButton';
import { IRequestFactory, QuinoCoreServiceSymbols, IUrlManager, ITranslationServiceSymbol, ITranslationService } from '@quino/core';
import { usePromise, useService } from '@quino/ui';

export interface IThirdPartyAuthenticationBlockProps {
  customClass?: string;
}

export function ThirdPartyAuthenticationBlock(props: IThirdPartyAuthenticationBlockProps) {
  const urlManager = useService<IUrlManager>(QuinoCoreServiceSymbols.IUrlManager);
  const requestFactory = useService<IRequestFactory>(QuinoCoreServiceSymbols.IRequestFactory);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const [providers, setProviders] = useState<Array<{ name: string; icon: string; caption: string }> | null>(null);

  usePromise(async () => {
    const request = requestFactory.create(urlManager.getThirdPartyOidcProvidersUrl());
    setProviders(await (await window.fetch(request)).json());
  });

  const thirdPartyOidcButtons = useMemo(() => {
    return providers?.map((provider) => {
      return (
        <ThirdPartyAuthenticationButton
          key={provider.name}
          icon={provider.icon}
          text={translationService.translate(provider.caption)}
          onClick={() => (window.location.href = urlManager.getThirdPartyOidcProviderRedirect(provider.name))}
        />
      );
    });
  }, [providers, translationService, urlManager]);

  return thirdPartyOidcButtons != null && thirdPartyOidcButtons.length > 0 ? (
    <div className={`quino-auth-third-party-oidc ${props.customClass ?? ''}`}>
      <div className={'divider'}>
        <span>{translationService.translate('ThirdPartyAuthenticationBlock.Or')}</span>
      </div>
      <div className={'button-container'}>{thirdPartyOidcButtons}</div>
    </div>
  ) : (
    <></>
  );
}
