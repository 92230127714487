import { IMetaAspect } from '@quino/core';

/**
 * The identifier for retrieving a class action aspect.
 */
export const ClassActionAspectIdentifier = 'ClassActionAspect';

/**
 * Aspect containing info about where to display class actions
 */
export interface IClassActionAspect extends IMetaAspect {
  /**
   * Whether to show the action as a main bookmark action (instead of icon only)
   */
  showAsMainAction?: boolean;

  /**
   * Whether to show the action before other actions of the respective group (instead of at the end)
   */
  showAtStartOfGroup?: boolean;

  /**
   * Whether to show the action button as a primary button
   */
  isPrimary?: boolean;
}
