import {
  getMetaProperty,
  getMetaRelation,
  IMetaElement,
  IMetaLayout,
  IMetaProperty,
  IMetaRelation,
  isMetaRelation,
  LayoutType
} from '../../../../../meta';
import {
  IGenericObject,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  ITitleCalculator,
  ITitleCalculatorSymbol,
  NewGenericObjectPrimaryKey
} from '../../../../../data';
import { IQuinoExcelCell, IQuinoExcelCellBuilder, IQuinoExcelRow } from '../../model';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../../../../ioc';
import { IDataService } from '../../../../IDataService';
import { IQuinoExcelCellBuilderSymbol, IQuinoExcelRowBuilder, IQuinoExcelRowBuilderSymbol } from '../../builder';
import { IMetadataTree } from '../../../../IMetadataTree';
import { IExpressionEvaluator, IVisibleCalculator } from '../../../../../expressions';
import { IExportExcelConfigurationService } from '../../settings';
import { IFormatStringService } from '../../../../formatting';
import { ControlExcelMapperBase } from './ControlExcelMapperBase';

export const GridEditorExcelMapperSymbol = Symbol.for('GridEditorExcelMapper');

@injectable()
export class GridEditorExcelMapper extends ControlExcelMapperBase {
  constructor(
    @inject(QuinoCoreServiceSymbols.IDataService) private readonly dataService: IDataService,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataService: IMetadataTree,
    @inject(IQuinoExcelRowBuilderSymbol) rowBuilder: IQuinoExcelRowBuilder,
    @inject(IQuinoExcelCellBuilderSymbol) cellBuilder: IQuinoExcelCellBuilder,
    @inject(QuinoCoreServiceSymbols.IVisibleCalculator) visibleCalculator: IVisibleCalculator,
    @inject(QuinoCoreServiceSymbols.IExportExcelConfigurationService)
    excelExportConfigurationService: IExportExcelConfigurationService,
    @inject(QuinoCoreServiceSymbols.IFormatStringService) formatStringService: IFormatStringService,
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) expressionEvaluator: IExpressionEvaluator,
    @inject(IMetaPropertyValueServiceSymbol) metaPropertyValueService: IMetaPropertyValueService,
    @inject(ITitleCalculatorSymbol) titleCalculator: ITitleCalculator
  ) {
    super(
      rowBuilder,
      cellBuilder,
      visibleCalculator,
      excelExportConfigurationService,
      formatStringService,
      expressionEvaluator,
      metaPropertyValueService,
      titleCalculator
    );
  }

  getMappableElements(): string[] {
    return ['GridEditor', 'Grid'];
  }

  conditionMatches(element: IMetaElement, genericObject: IGenericObject): boolean {
    return isMetaRelation(element) && this.isVisible(element, genericObject);
  }

  async getData(genericObject: IGenericObject, element: IMetaElement): Promise<IQuinoExcelRow[]> {
    const metaRelation = getMetaRelation(element);
    const layout = this.getLayout(metaRelation);
    const source = await this.getSource(metaRelation, genericObject);
    return this.getTable(layout, source);
  }

  private async getTable(layout: IMetaLayout, source: IGenericObject[]): Promise<IQuinoExcelRow[]> {
    return [await this.getHeaderRow(layout)].concat(await this.getDataRows(layout, source));
  }

  private async getHeaderRow(layout: IMetaLayout): Promise<IQuinoExcelRow> {
    const cells: IQuinoExcelCell[] = [];
    const defaultRowSettings = await this.excelExportConfigurationService.getExportSettings(null);
    for (const layoutElement of layout.elements) {
      const defaultSettings = await this.excelExportConfigurationService.getExportSettings(layoutElement);
      cells.push(
        this.cellBuilder
          .clear(defaultSettings)
          .withFontSize(defaultRowSettings.tableTitleSettings.fontSize)
          .setIsBold(defaultRowSettings.tableTitleSettings.isBold)
          .withValue(layoutElement.caption)
          .setIsWrapText(false)
          .build()
      );
    }
    return this.rowBuilder.clear(defaultRowSettings).withMarginTop(defaultRowSettings.tableTitleSettings.rowMargin).withCells(cells).build();
  }

  private async getDataRows(layout: IMetaLayout, genericObjects: IGenericObject[]): Promise<IQuinoExcelRow[]> {
    const defaultRowSettings = await this.excelExportConfigurationService.getExportSettings(null);
    const rows: IQuinoExcelRow[] = [];
    for (const genericObject of genericObjects) {
      const cells: IQuinoExcelCell[] = [];
      for (const element of layout.elements) {
        const metaProperty = getMetaProperty(element);
        const cell = await this.getDataCell(metaProperty, genericObject);
        cells.push(cell);
      }
      rows.push(this.rowBuilder.clear(defaultRowSettings).withCells(cells).build());
    }
    if (rows.length === 0) {
      rows.push(this.rowBuilder.clear(defaultRowSettings).withMarginTop(1).build());
    }
    return rows;
  }

  private async getDataCell(element: IMetaProperty, genericObject: IGenericObject): Promise<IQuinoExcelCell> {
    const cellSettings = await this.excelExportConfigurationService.getExportSettings(element);
    return this.cellBuilder
      .clear(cellSettings)
      .withValue(this.getValue(element, genericObject))
      .withFormat(this.formatStringService.get(element))
      .build();
  }

  private getLayout(metaRelation: IMetaRelation): IMetaLayout {
    return this.metadataService.getLayout(metaRelation.targetClass, LayoutType.List);
  }

  private async getSource(metaRelation: IMetaRelation, genericObject: IGenericObject): Promise<IGenericObject[]> {
    if (genericObject.primaryKey != null && genericObject.primaryKey !== NewGenericObjectPrimaryKey) {
      return this.dataService.getRelatedObjectsAsync(genericObject.metaClass, genericObject.primaryKey, metaRelation.path, metaRelation.name);
    }
    return [];
  }
}
