import { inject, injectable } from 'inversify';
import { IRequestBuilder, IUrlManager, QuinoCoreServiceSymbols } from '@quino/core';
import { IQuinoFileStorageService } from './IQuinoFileStorageService';
import { IFileInfo } from './IFileInfo';

@injectable()
export class QuinoFileStorageService implements IQuinoFileStorageService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager)
    private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder
  ) {}

  async getFileInfoAsync(id: string | null, ownerClass: string | null, ownerPrimaryKey: string | null, context: string | null): Promise<IFileInfo[]> {
    const url = this.urlManager.getFileInfoUrl(id, ownerClass, ownerPrimaryKey, context);
    return this.requestBuilder.create(url, 'get').requiresAuthentication().fetchJson();
  }

  async updateFileInfoAsync(fileInfo: Partial<IFileInfo>): Promise<void> {
    const url = this.urlManager.updateFileInfoUrl();
    await this.requestBuilder.create(url, 'post').requiresAuthentication().setJsonPayload(fileInfo).fetch();
  }

  async deleteFileStorageAsync(id: string): Promise<void> {
    const url = this.urlManager.deleteFileUrl(id);
    await this.requestBuilder.create(url, 'delete').requiresAuthentication().fetch();
  }

  async downloadFileAsync(id: string): Promise<Blob> {
    const url = this.urlManager.downloadFileUrl(id);
    return this.requestBuilder.create(url, 'get').requiresAuthentication().fetchImage();
  }
}
