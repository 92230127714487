import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IUrlManager } from './QuinoServer';
import { IRequestBuilder } from '../request';
import { IModelFetcher } from './IModelFetcher';
import { IHashedMetaModel, IMetaModel } from '../meta';

@injectable()
export class ModelFetcher implements IModelFetcher {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) public urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) public requestBuilder: IRequestBuilder
  ) {}

  async fetch(): Promise<IMetaModel> {
    const url = this.urlManager.getAllMetadataUrl();

    return this.requestBuilder.create(url, 'get').requiresAuthentication().fetchJson<IMetaModel>();
  }

  async fetchHash(): Promise<string> {
    const url = this.urlManager.getAllMetadataUrl();

    const modelHashRequest = await this.requestBuilder
      .create(url + '/hash', 'get')
      .requiresAuthentication()
      .fetch();

    return modelHashRequest.text();
  }

  async fetchCurrentModel(hash: string | null): Promise<IHashedMetaModel> {
    const url = this.urlManager.getCurrentMetadataUrl(hash);

    return this.requestBuilder.create(url, 'post').setJsonPayload(hash).requiresAuthentication().fetchJson<IHashedMetaModel>();
  }

  storageKey(): string {
    return 'model';
  }

  hashStorageKey(): string {
    return 'model_hash';
  }
}
