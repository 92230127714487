import * as React from 'react';
import { PropsWithChildren, ReactElement, useState } from 'react';
import ReactDOM from 'react-dom';
import { inject, injectable } from 'inversify';
import { IFeedbackOption, IFeedbackService } from './IFeedbackService';
import { QuinoLabeled, QuinoPopup, QuinoPopupDefaultContent, QuinoPopupSimpleText, QuinoPopupToolbar, QuinoPopupToolbarButton } from '../components';
import { IApplication, ITranslationService, ITranslationServiceSymbol, QuinoCoreServiceSymbols } from '@quino/core';
import { TextBox } from 'devextreme-react/text-box';
import { IQuinoShortcutSettings, IQuinoShortcutSettingsSymbol, useShortcutHandler } from '../shortcuts';
import { ContainerContext, useService } from '../ioc';
import { IPopupOptions } from 'devextreme-react/popup';

@injectable()
export class FeedbackService implements IFeedbackService {
  constructor(
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService,
    @inject(QuinoCoreServiceSymbols.IApplication) private readonly container: IApplication
  ) {
    this.element = document.createElement('div');
    this.element.style.width = '0px';
    this.element.style.height = '0px';
    this.element.style.position = 'absolute';
    document.body.appendChild(this.element);
  }

  async getConfirmation(
    title: string,
    text: string,
    confirmText?: string,
    confirmIcon?: string,
    rejectText?: string,
    rejectIcon?: string,
    popupOptions?: Partial<IPopupOptions>
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.mountComponent(
        <SimpleConfirmationPopup
          title={title}
          text={text}
          confirmText={confirmText ? confirmText : this.translationService.translate('Yes')}
          confirmIcon={confirmIcon ? confirmIcon : 'material-icons-outlined done'}
          rejectText={rejectText ? rejectText : this.translationService.translate('Cancel')}
          rejectIcon={rejectIcon ? rejectIcon : 'material-icons-outlined clear'}
          callback={(confirmationValue: boolean) => {
            this.unMountComponent();
            resolve(confirmationValue);
          }}
          popupOptions={popupOptions}
        />
      );
    });
  }

  async getString(
    title: string,
    text: string,
    confirmText?: string,
    confirmIcon?: string,
    rejectText?: string,
    rejectIcon?: string,
    initialValue?: string,
    maxLength?: number,
    popupOptions?: Partial<IPopupOptions>
  ): Promise<{ confirmed: boolean; value: string }> {
    return new Promise<{ confirmed: boolean; value: string }>((resolve) => {
      this.mountComponent(
        <SimpleInputPopup
          title={title}
          text={text}
          confirmText={confirmText ? confirmText : this.translationService.translate('Save')}
          confirmIcon={confirmIcon ? confirmIcon : 'material-icons-outlined save'}
          rejectText={rejectText ? rejectText : this.translationService.translate('Cancel')}
          rejectIcon={rejectIcon ? rejectIcon : 'material-icons-outlined clear'}
          initialValue={initialValue || ''}
          maxLength={maxLength || 80}
          callback={(confirmed, value) => {
            this.unMountComponent();
            resolve({ confirmed, value });
          }}
          popupOptions={popupOptions}
        />
      );
    });
  }

  async showMessage(title: string, message: string, messageDetail?: string, popupOptions?: Partial<IPopupOptions>): Promise<void> {
    return new Promise<void>((resolve) => {
      this.mountComponent(
        <SimpleMessagePopup
          title={title}
          text={message}
          additionalText={messageDetail || ''}
          confirmText={'OK'}
          callback={() => {
            this.unMountComponent();
            resolve();
          }}
          popupOptions={popupOptions}
        />
      );
    });
  }

  async getCustomFeedback(title: string, text: string, options: IFeedbackOption[], popupOptions?: Partial<IPopupOptions>): Promise<string> {
    return new Promise<string>((resolve) => {
      this.mountComponent(
        <CustomFeedbackPopup
          title={title}
          text={text}
          options={options}
          callback={(feedbackValue: string) => {
            this.unMountComponent();
            resolve(feedbackValue);
          }}
          popupOptions={popupOptions}
        />
      );
    });
  }

  private mountComponent(component: ReactElement): void {
    if (!this.isMounted) {
      ReactDOM.render(<ContainerContext.Provider value={{ container: this.container }}>{component}</ContainerContext.Provider>, this.element);
      this.isMounted = true;
    }
  }

  private unMountComponent(): void {
    ReactDOM.unmountComponentAtNode(this.element);
    this.isMounted = false;
  }

  private readonly element: HTMLElement;
  private isMounted: boolean;
}

function BaseFeedbackPopup(props: PropsWithChildren<{ title: string; text: string; onHidden: () => void; popupOptions?: Partial<IPopupOptions> }>) {
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);
  useShortcutHandler([shortcutSettings.cancel], props.onHidden);

  return (
    <QuinoPopup visible={true} title={props.title} onHidden={props.onHidden} {...props.popupOptions}>
      <QuinoPopupSimpleText text={props.text} />
      {props.children}
    </QuinoPopup>
  );
}

function SimpleConfirmationPopup(props: {
  title: string;
  text: string;
  rejectText: string;
  confirmText: string;
  rejectIcon: string;
  confirmIcon: string;
  popupOptions?: Partial<IPopupOptions>;
  callback: (confirmed: boolean) => void;
}) {
  return (
    <BaseFeedbackPopup title={props.title} text={props.text} onHidden={() => props.callback(false)} popupOptions={props.popupOptions}>
      <QuinoPopupToolbar
        rightItems={[
          <QuinoPopupToolbarButton text={props.rejectText} icon={props.rejectIcon} onClick={() => props.callback(false)} />,
          <QuinoPopupToolbarButton isPrimary={true} text={props.confirmText} icon={props.confirmIcon} onClick={() => props.callback(true)} />
        ]}
      />
    </BaseFeedbackPopup>
  );
}

function SimpleInputPopup(props: {
  title: string;
  text: string;
  rejectText: string;
  confirmText: string;
  rejectIcon: string;
  confirmIcon: string;
  initialValue: string;
  callback: (confirmed: boolean, value: string) => void;
  maxLength: number;
  popupOptions?: Partial<IPopupOptions>;
}) {
  const [str, setStr] = useState<string>(
    props.initialValue.length > props.maxLength ? props.initialValue.slice(0, props.maxLength) : props.initialValue
  );

  return (
    <BaseFeedbackPopup title={props.title} text={props.text} onHidden={() => props.callback(false, '')} popupOptions={props.popupOptions}>
      <QuinoPopupDefaultContent hasPaddingHorizontal={true}>
        <QuinoLabeled label={''}>
          <TextBox value={str} onValueChanged={(e) => setStr(e.value)} maxLength={props.maxLength} valueChangeEvent={'keyup blur'} />
        </QuinoLabeled>
      </QuinoPopupDefaultContent>
      <QuinoPopupToolbar
        rightItems={[
          <QuinoPopupToolbarButton text={props.rejectText} icon={props.rejectIcon} onClick={() => props.callback(false, '')} />,
          <QuinoPopupToolbarButton
            isPrimary={true}
            disabled={str === ''}
            text={props.confirmText}
            icon={props.confirmIcon}
            onClick={() => props.callback(true, str)}
          />
        ]}
      />
    </BaseFeedbackPopup>
  );
}

function SimpleMessagePopup(props: {
  title: string;
  text: string;
  additionalText: string;
  confirmText: string;
  callback: () => void;
  popupOptions?: Partial<IPopupOptions>;
}) {
  return (
    <BaseFeedbackPopup title={props.title} text={props.text} onHidden={props.callback} popupOptions={props.popupOptions}>
      {props.additionalText && (
        <QuinoPopupDefaultContent hasPaddingHorizontal={true}>
          <pre>{props.additionalText}</pre>
        </QuinoPopupDefaultContent>
      )}
      <QuinoPopupToolbar
        rightItems={[
          <QuinoPopupToolbarButton isPrimary={true} text={props.confirmText} icon={'material-icons-outlined done'} onClick={() => props.callback()} />
        ]}
      />
    </BaseFeedbackPopup>
  );
}

function CustomFeedbackPopup(props: {
  title: string;
  text: string;
  options: IFeedbackOption[];
  callback: (value: string) => void;
  popupOptions?: Partial<IPopupOptions>;
}) {
  const optionButtons = props.options.map((o) => (
    <QuinoPopupToolbarButton text={o.text} icon={o.icon} isPrimary={o.isPrimary} onClick={() => props.callback(o.value)} />
  ));

  return (
    <BaseFeedbackPopup
      title={props.title}
      text={props.text}
      onHidden={() => props.callback(props.options.find((option) => option.isCancelOption)?.value || '')}
      popupOptions={props.popupOptions}>
      <QuinoPopupToolbar rightItems={optionButtons} />
    </BaseFeedbackPopup>
  );
}
