import { IBookmark } from '../bookmarks';

/**
 * The symbol for resolving the navigation service.
 */
export const INavigationServiceSymbol = Symbol.for('INavigationService');

/**
 * The navigation service holds the state of the current bookmark stack.
 */
export interface INavigationService {
  /**
   * Get the bookmark which is currently on top of the stack.
   */
  active: IBookmark;

  /**
   * Push a new bookmark on top of the stack.
   */
  push: (bookmark: IBookmark) => Promise<boolean>;

  /**
   * Remove the top bookmark from the stack.
   */
  pop: (refreshAfterPop?: boolean) => Promise<boolean>;

  /**
   * Replace the top bookmark in the stack
   */
  replaceCurrent: (bookmark: IBookmark) => Promise<boolean>;

  /**
   * Get the current stack of bookmarks.
   */
  get: () => IBookmark[];

  /**
   * Set the current stack of bookmarks.
   */
  set: (bookmarks: IBookmark[], refreshAfterSet?: boolean) => Promise<boolean>;

  /**
   * Register a callback for listening to changes. Ensure to un-register with the returned symbol.
   */
  registerOnChange: (callback: (bookmark: IBookmark) => void) => Symbol;

  /**
   * Remove the given change listener.
   */
  removeOnChange: (symbol: Symbol) => void;

  /**
   * Extracts a new Url from the bookmark.
   */
  extractUrl: (bookmark: IBookmark) => string;
}
