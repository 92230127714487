import React, { useState } from 'react';
import { Resizable } from 'devextreme-react/resizable';
import { CUINavBar } from './CUINavBar';
import { CUINavBarFooter } from './CUINavBarFooter';
import {
  IQuinoShortcutSettings,
  IQuinoShortcutSettingsSymbol,
  QuinoDataGridSearchEditorId,
  setFocusOnElement,
  useService,
  useShortcutHandler,
  TResponsiveMode,
  useResponsiveMode
} from '@quino/ui';
import { CUINavBarTreeViewSearchEditorId } from './CUINavBarTreeView';

export function CUINavWrapper() {
  const responsiveMode = useResponsiveMode();
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);
  const [navBarExpanded, setNavBarExpanded] = useState<boolean>(responsiveMode === TResponsiveMode.Desktop);

  useShortcutHandler([shortcutSettings.quickListSearch], () => setFocusOnElement(QuinoDataGridSearchEditorId, true));
  useShortcutHandler([shortcutSettings.menuSearch], () => setFocusOnElement(CUINavBarTreeViewSearchEditorId, true));

  return (
    <div className={`quino-ecui-nav-bar-resizable-wrapper ${navBarExpanded ? 'is-expanded' : 'is-narrowed'}`}>
      <Resizable handles={'right'} minWidth={200} maxWidth={320} defaultWidth={200}>
        <nav key={2} className={`quino-ecui-nav-bar`}>
          <CUINavBar key={'CUINavBar'} navBarExpanded={navBarExpanded} navBarExpandedCallback={setNavBarExpanded} useFavorites={false} />
          <CUINavBarFooter navBarExpanded={navBarExpanded} navBarExpandedCallback={setNavBarExpanded} />
        </nav>
      </Resizable>
    </div>
  );
}
