import { IApplication } from '..';
import { QuinoCoreServiceSymbols } from '../ioc';
import { DefaultAuthorizationService } from './DefaultAuthorizationService';
import { IAuthorizationService } from './IAuthorizationService';
import { IUserInfoService, IUserInfoServiceSymbol } from './IUserInfoService';
import { UserInfoService } from './UserInfoService';

export class AuthorizationModule {
  static use = (application: IApplication): void => {
    application.registerSingle<IAuthorizationService>(QuinoCoreServiceSymbols.IAuthorizationService, DefaultAuthorizationService);
    application.registerSingle<IUserInfoService>(IUserInfoServiceSymbol, UserInfoService);
  };
}
