import { IExpression } from './IExpression';

export const IExpressionVisitorSymbol = Symbol.for('IExpressionVisitor');

/**
 * Visitor for iterating through expression.
 */
export interface IExpressionVisitor {
  /**
   * Visit the current expression and all subsequent expressions.
   * @param root - the root element.
   * @param callback - the callback to execute on each element.
   */
  visit(root: IExpression, callback: (element: IExpression) => void): void;
}
