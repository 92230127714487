import { inject, injectable } from 'inversify';
import { IODataBatchRequest, IODataBatchRequestFactory, IODataBatchRequestFactorySymbol } from '../api';
import { IBatchedRequestService } from './IBatchedRequestService';
import { IODataBatchResponse } from '../api/QuinoServer/IODataBatchRequestFactory';
import cloneDeep from 'lodash/cloneDeep';

const maxRequestsToBatch = 40;
const maxTimeoutToBatchMS = 200;

@injectable()
export class BatchedRequestService implements IBatchedRequestService {
  constructor(@inject(IODataBatchRequestFactorySymbol) private readonly batchRequestFactory: IODataBatchRequestFactory) {}

  async enqueue(batchRequest: IODataBatchRequest): Promise<any> {
    return new Promise<any>((resolve, reject) => void this.collectBatchRequests(batchRequest, resolve, reject));
  }

  private async collectBatchRequests(
    request: IODataBatchRequest,
    resolve: (value?: IODataBatchResponse | PromiseLike<IODataBatchResponse>) => void,
    reject: (reason?: any) => void
  ): Promise<void> {
    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;

    this.promises.push({ id: request.id, resolve, reject });
    this.requests.set(request.id, request);

    if (this.requests.size >= maxRequestsToBatch) {
      await this.performBatchRequests();
    } else {
      this.timeoutId = setTimeout(() => {
        void this.performBatchRequests();
      }, maxTimeoutToBatchMS);
    }
  }

  private async performBatchRequests(): Promise<void> {
    const promises = cloneDeep(this.promises);
    const requests = Array.from(this.requests.entries()).map(([, value]) => value);

    this.promises = [];
    this.requests = new Map<string, IODataBatchRequest>();

    return this.batchRequestFactory.fetch(requests).then((results) => {
      results.forEach((result) => {
        promises
          .filter((promise) => promise.id === result.id)
          .forEach((promise) => {
            if (result.status != 200) {
              promise.reject(result);
            } else {
              promise.resolve(result);
            }
          });
      });
    });
  }

  private requests = new Map<string, IODataBatchRequest>();
  private promises: {
    id: string;
    resolve: (value?: IODataBatchResponse | PromiseLike<IODataBatchResponse>) => void;
    reject: (reason?: any) => void;
  }[] = [];
  private timeoutId: number | undefined = undefined;
}
