import * as React from 'react';
import { ReactElement } from 'react';
import { IApplication, QuinoCoreServiceSymbols } from '@quino/core';
import { inject, injectable } from 'inversify';
import { IQuinoAuthenticationComponentFactory } from './IQuinoAuthenticationComponentFactory';
import { QuinoAuthenticationComponent } from './QuinoAuthenticationComponent';

// TODO: Are we really supporting authentication without CUI?

@injectable()
export class QuinoAuthenticationComponentFactory implements IQuinoAuthenticationComponentFactory {
  constructor(@inject(QuinoCoreServiceSymbols.IApplication) private readonly application: IApplication) {}

  createComponent(onLoggedIn: () => void): ReactElement {
    return <QuinoAuthenticationComponent application={this.application} onLoggedIn={onLoggedIn} />;
  }

  createChangePasswordComponent(): React.ReactElement {
    return <></>;
  }
}
