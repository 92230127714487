import { IExpression, isExpression } from './IExpression';

export interface IChainExpression extends IExpression {
  call: IExpression;
  target: IExpression;
}

export const isChainExpression = (object: IExpression): object is IChainExpression => {
  return (
    isExpression(object) &&
    object.ExpressionType === 0 &&
    (object as IChainExpression).call !== undefined &&
    (object as IChainExpression).target !== undefined
  );
};
