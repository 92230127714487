import { IFieldValue, IStandardBookmarkActionsService } from './IStandardBookmarkActionsService';
import { PendingChangesFeedbackValue } from '../navigation/PendingChangesService';
import { IPendingChangesService, IPendingChangesServiceSymbol } from '../navigation/IPendingChangesService';
import { IBookmarkTitleCalculator, IBookmarkTitleCalculatorSymbol } from '../navigation/IBookmarkTitleCalculator';
import { IHomeNavigationService, IHomeNavigationServiceSymbol } from '../navigation/IHomeNavigationService';
import { INavigationService, INavigationServiceSymbol } from '../navigation/INavigationService';
import { inject, injectable } from 'inversify';
import { IQuinoActionArgs, IQuinoBookmarkAction } from './index';
import { IBookmark, isIBookmark } from '../bookmarks/IBookmark';
import { IObjectBookmark, isIObjectBookmark, isNewObjectBookmark } from '../bookmarks/IObjectBookmark';
import { IListBookmark, isIListBookmark } from '../bookmarks/IListBookmark';
import { isIRefreshableBookmark } from '../bookmarks/IRefreshableBookmark';
import {
  DataType,
  getAspectOrDefault,
  IAuthorization,
  IAuthorizationService,
  IErrorHandlingService,
  IErrorHandlingServiceSymbol,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  INotificationService,
  INotificationServiceSymbol,
  ISortOrderAspect,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols,
  SortOrderAspectIdentifier
} from '@quino/core';
import { BookmarkActionsAspectIdentifier, IBookmarkActionsAspect } from '../aspects/index';
import { isILayoutAwareBookmark } from '../bookmarks/ILayoutAwareBookmark';
import { IFeedbackService, IFeedbackServiceSymbol } from '../feedback';
import { IGenericObjectPersistenceService, IGenericObjectPersistenceServiceSymbol } from '../data';
import { IBookmarkFactory, isIMetaClassAwareBookmark } from '../bookmarks';
import { QuinoUIServiceSymbols } from '../ioc';
import { TQuinoActionSource } from './IQuinoAction';

@injectable()
export class StandardBookmarkActionsService implements IStandardBookmarkActionsService {
  constructor(
    @inject(INavigationServiceSymbol) private readonly navigationService: INavigationService,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(IPendingChangesServiceSymbol) private readonly pendingChangesService: IPendingChangesService,
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService,
    @inject(QuinoCoreServiceSymbols.IAuthorizationService) private readonly authorizationService: IAuthorizationService,
    @inject(INotificationServiceSymbol) private readonly notificationService: INotificationService,
    @inject(ILoadingFeedbackSymbol) private readonly loadingFeedback: ILoadingFeedback,
    @inject(IFeedbackServiceSymbol) private readonly feedbackService: IFeedbackService,
    @inject(IBookmarkTitleCalculatorSymbol) private readonly titleCalculator: IBookmarkTitleCalculator,
    @inject(IGenericObjectPersistenceServiceSymbol) private readonly persistenceService: IGenericObjectPersistenceService,
    @inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory,
    @inject(IHomeNavigationServiceSymbol) private readonly homeNavigationService: IHomeNavigationService,
    @inject(IErrorHandlingServiceSymbol) private readonly errorHandler: IErrorHandlingService
  ) {
    this.navigationService.registerOnChange(() => {
      this.clearNotifications();
    });
  }

  // TODO: Implement correct button hints with shortcut indications once #10056 is done

  getBookmarkActions = (bookmark: IBookmark): IQuinoBookmarkAction[] => {
    if (isIObjectBookmark(bookmark)) {
      return this.getObjectBookmarkActions(bookmark);
    } else if (isIListBookmark(bookmark)) {
      return this.getListBookmarkActions(bookmark);
    }

    return [];
  };

  getObjectDeleteMethod = (): ((bookmark: IBookmark) => void) => {
    return this.onObjectBookmarkDelete;
  };

  getObjectDirectDeleteMethod = (): ((bookmark: IBookmark) => void) => {
    return this.deleteObjectBookmark;
  };

  getObjectCreateMethod = (): ((bookmark: IBookmark) => void) => {
    return async (bookmark) => this.createNewBookmark(bookmark).then(this.onCreatePushToNavigationStack);
  };

  getObjectDiscardMethod = (): ((bookmark: IBookmark) => void) => {
    return this.onObjectBookmarkDiscard;
  };

  getObjectSaveMethod = (): ((bookmark: IBookmark) => void) => {
    return this.onObjectBookmarkSave;
  };

  createActive = (bookmark: IBookmark): boolean => {
    return (
      this.getBookmarkAuthorization(bookmark).canCreate() &&
      !this.getBookmarkActionOptions(bookmark)?.hideCreate &&
      (isIListBookmark(bookmark) || isIObjectBookmark(bookmark))
    );
  };

  objectDeleteActive = (bookmark: IBookmark): boolean => {
    return (
      isIObjectBookmark(bookmark) &&
      !bookmark.isExecuting() &&
      this.getBookmarkAuthorization(bookmark).canDelete() &&
      !this.getBookmarkActionOptions(bookmark)?.hideDelete
    );
  };

  objectDiscardActive = (bookmark: IBookmark): boolean => {
    return (
      isIObjectBookmark(bookmark) &&
      !bookmark.isExecuting() &&
      (isNewObjectBookmark(bookmark) || (!isNewObjectBookmark(bookmark) && bookmark.hasChanges()))
    );
  };

  objectSaveActive = (bookmark: IBookmark): boolean => {
    return (
      isIObjectBookmark(bookmark) &&
      bookmark.hasChanges() &&
      !bookmark.isExecuting() &&
      this.getBookmarkAuthorization(bookmark).canUpdate() &&
      !this.getBookmarkActionOptions(bookmark)?.hideSave
    );
  };

  private readonly getBookmarkActionOptions = (bookmark: IBookmark): IBookmarkActionsAspect | null | undefined => {
    return isILayoutAwareBookmark(bookmark)
      ? getAspectOrDefault<IBookmarkActionsAspect>(bookmark.layout, BookmarkActionsAspectIdentifier)
      : undefined;
  };

  private readonly getBookmarkAuthorization = (bookmark: IBookmark): IAuthorization => {
    if (isIObjectBookmark(bookmark)) {
      return this.authorizationService.getAuthorization(bookmark.genericObject.metaClass);
    } else if (isIListBookmark(bookmark)) {
      return this.authorizationService.getAuthorization(bookmark.metaClass.name);
    }

    return this.authorizationService.getAuthorization('');
  };

  getObjectBookmarkCreateAction(bookmark: IObjectBookmark, onCreate: (newBookmark: IBookmark | undefined) => void): IQuinoBookmarkAction {
    const sortPropertyName = this.getSortPropertyName(bookmark);
    const createCaption = this.translationService.translate(this.getBookmarkActionOptions(bookmark)?.customCreateTranslationKey || 'Create');

    return {
      onClick: (args) => {
        if (!sortPropertyName && isIObjectBookmark(args.source)) {
          this.createNewBookmark(args.source).then(onCreate).catch(this.logger.logError);
        }
      },
      icon: 'material-icons-outlined add',
      caption: createCaption,
      hint: createCaption,
      visible: (b: IBookmark) =>
        !isNewObjectBookmark(b) && !this.getBookmarkActionOptions(b)?.hideCreate && this.getBookmarkAuthorization(b).canCreate(),
      disabled: (b: IBookmark) => isIObjectBookmark(b) && b.isExecuting(),
      location: 'icon-overflow',
      children: this.getCreateSubItems(bookmark, sortPropertyName, onCreate)
    };
  }

  private getObjectBookmarkActions(bookmark: IObjectBookmark): IQuinoBookmarkAction[] {
    const objectBookmarkActions: IQuinoBookmarkAction[] = [];

    objectBookmarkActions.push(this.getRefreshAction(bookmark));

    objectBookmarkActions.push(this.getObjectBookmarkCreateAction(bookmark, this.onCreatePushToNavigationStack));

    objectBookmarkActions.push({
      onClick: (args) => isIObjectBookmark(args.source) && this.onObjectBookmarkDiscard(args.source),
      icon: (s: TQuinoActionSource) =>
        isIBookmark(s) ? (isNewObjectBookmark(s) ? 'material-icons-outlined clear' : 'material-icons-outlined replay') : '',
      caption: (s: TQuinoActionSource) => (isIBookmark(s) ? this.translationService.translate(isNewObjectBookmark(s) ? 'Cancel' : 'Discard') : ''),
      hint: (s: TQuinoActionSource) => (isIBookmark(s) ? this.translationService.translate(isNewObjectBookmark(s) ? 'Cancel' : 'Discard') : ''),
      visible: (s: TQuinoActionSource) =>
        isIObjectBookmark(s) &&
        ((isNewObjectBookmark(s) && this.getBookmarkAuthorization(s).canCreate()) || (!isNewObjectBookmark(s) && s.hasChanges())),
      disabled: (s: TQuinoActionSource) => !isIObjectBookmark(s) || (isIObjectBookmark(s) && s.isExecuting()),
      location: 'main-before',
      buttonType: 'default',
      buttonStylingMode: 'outlined'
    });

    const deleteCaption = this.translationService.translate(this.getBookmarkActionOptions(bookmark)?.customDeleteTranslationKey || 'Delete');

    objectBookmarkActions.push({
      onClick: (args) => isIObjectBookmark(args.source) && this.onObjectBookmarkDelete(args.source),
      icon: 'material-icons-outlined delete',
      caption: deleteCaption,
      hint: deleteCaption,
      visible: (s: TQuinoActionSource) =>
        isIObjectBookmark(s) &&
        !this.getBookmarkActionOptions(s)?.hideDelete &&
        !isNewObjectBookmark(s) &&
        this.getBookmarkAuthorization(s).canDelete(),
      disabled: (s) => !isIObjectBookmark(s) || (isIObjectBookmark(s) && s.isExecuting()),
      location: 'main-before',
      buttonType: 'default',
      buttonStylingMode: 'outlined'
    });

    const saveAndNewCaption = this.translationService.translate(
      this.getBookmarkActionOptions(bookmark)?.customSaveAndNewTranslationKey || 'SaveAndNew'
    );

    objectBookmarkActions.push({
      onClick: (args) => {
        const { source } = args;
        const sortPropertyName = this.getSortPropertyName(bookmark);
        let customFieldValues: IFieldValue[] | undefined = undefined;
        if (sortPropertyName) {
          const sortValue = bookmark.genericObject[sortPropertyName];
          if (typeof sortValue === 'number') {
            customFieldValues = [{ propertyName: sortPropertyName, propertyValue: sortValue + 1 }];
          }
        }

        isIObjectBookmark(source) &&
          this.onObjectBookmarkSave(source, () => void this.createNewBookmark(source, customFieldValues).then(this.onCreatePushToNavigationStack));
      },
      icon: 'material-icons-outlined add',
      caption: saveAndNewCaption,
      hint: saveAndNewCaption,
      visible: (s: TQuinoActionSource) =>
        isIObjectBookmark(s) &&
        isNewObjectBookmark(s) &&
        !this.getBookmarkActionOptions(s)?.hideCreate &&
        this.getBookmarkAuthorization(s).canCreate(),
      disabled: (s: TQuinoActionSource) => isIObjectBookmark(s) && isNewObjectBookmark(s) && (!s.hasChanges() || s.isExecuting()),
      location: 'main-before',
      buttonType: 'default',
      buttonStylingMode: 'outlined'
    });

    const saveCaption = this.translationService.translate(this.getBookmarkActionOptions(bookmark)?.customSaveTranslationKey || 'Save');

    objectBookmarkActions.push({
      onClick: async (args) => {
        const { source } = args;
        if (isIObjectBookmark(source)) {
          await this.onObjectBookmarkSave(source);
        }
      },
      icon: this.getBookmarkActionOptions(bookmark)?.customSaveIcon || 'material-icons-outlined save',
      caption: saveCaption,
      hint: saveCaption,
      visible: (s: TQuinoActionSource) => {
        if (!isIObjectBookmark(s)) {
          return false;
        }

        const isNew = isNewObjectBookmark(s);
        const authorization = this.getBookmarkAuthorization(s);
        return !this.getBookmarkActionOptions(s)?.hideSave && ((!isNew && authorization.canUpdate()) || (isNew && authorization.canCreate()));
      },
      disabled: (s: TQuinoActionSource) => isIObjectBookmark(s) && (!s.hasChanges() || s.isExecuting()),
      location: 'main-before',
      buttonType: 'default',
      buttonStylingMode: 'contained'
    });

    return objectBookmarkActions;
  }

  getListBookmarkCreateAction(
    bookmark: IListBookmark,
    onCreate: (newBookmark: IBookmark | undefined) => void,
    parentBookmark?: IBookmark
  ): IQuinoBookmarkAction {
    const sortPropertyName = this.getSortPropertyName(bookmark);

    const createCaption = this.translationService.translate(this.getBookmarkActionOptions(bookmark)?.customCreateTranslationKey || 'Create');

    return {
      onClick: (args) => {
        if (!sortPropertyName && isIListBookmark(args.source)) {
          this.createNewBookmark(args.source).then(onCreate).catch(this.logger.logError);
        }
      },
      icon: 'material-icons-outlined add',
      caption: createCaption,
      hint: createCaption,
      visible: (b: IBookmark) => !this.getBookmarkActionOptions(b)?.hideCreate && this.getBookmarkAuthorization(b).canCreate(),
      disabled: false,
      location: 'main-before',
      buttonType: 'default',
      buttonStylingMode: 'contained',
      children: this.getCreateSubItems(bookmark, sortPropertyName, onCreate, parentBookmark)
    };
  }

  private getListBookmarkActions(bookmark: IListBookmark): IQuinoBookmarkAction[] {
    const listBookmarkActions: IQuinoBookmarkAction[] = [];

    listBookmarkActions.push(this.getRefreshAction(bookmark));

    listBookmarkActions.push(this.getListBookmarkCreateAction(bookmark, this.onCreatePushToNavigationStack));

    return listBookmarkActions;
  }

  private readonly getRefreshAction = (bookmark: IBookmark): IQuinoBookmarkAction => {
    const onClick = (args: IQuinoActionArgs) => {
      const { source } = args;
      if (isIRefreshableBookmark(source)) {
        if (isIObjectBookmark(source) && source.hasChanges()) {
          this.pendingChangesService
            .getLeavingFeedback()
            .then((feedback) => {
              if (feedback === PendingChangesFeedbackValue.Discard) {
                return this.onObjectBookmarkDiscard(source);
              } else if (feedback === PendingChangesFeedbackValue.Save) {
                return this.onObjectBookmarkSave(source);
              }
            })
            .catch(this.logger.logError);
        } else {
          source.refresh();
        }
      }
    };

    const refreshCaption = this.translationService.translate(
      this.getBookmarkActionOptions(bookmark)?.customRefreshTranslationKey || 'Dropdown.RefreshContent'
    );

    return {
      onClick: onClick,
      icon: 'material-icons-outlined autorenew',
      caption: refreshCaption,
      hint: refreshCaption,
      visible: (source) => isIRefreshableBookmark(source) && !this.getBookmarkActionOptions(source)?.hideRefresh && !isNewObjectBookmark(source),
      disabled: () => false,
      location: 'icon-normal'
    };
  };

  private readonly onObjectBookmarkSave = async (bookmark: IBookmark, onSaved?: () => void): Promise<void> => {
    if (!isIObjectBookmark(bookmark)) {
      this.logger.logError('Cannot save because bookmark is not an IObjectBookmark');
      return;
    }

    const symbol = Symbol.for('StandardBookmarkActionsService.onObjectBookmarkSave');

    bookmark.setIsExecuting(true);
    const validationResult = await bookmark.validate();
    if (!validationResult.hasErrors) {
      const unload = this.loadingFeedback.load(this.translationService.translate('Saving'));

      try {
        const isNew = isNewObjectBookmark(bookmark);
        const confirmNotification = () =>
          this.notificationService.notify(
            {
              type: 'success',
              message: this.translationService.translate('SaveConfirmation'),
              area: 'global',
              autoDisappear: true
            },
            symbol
          );

        await bookmark.save();

        if (onSaved) {
          onSaved();
        } else if (isNew) {
          await this.navigationService.replaceCurrent(this.bookmarkFactory.createObject(bookmark.genericObject, undefined, bookmark.parentBookmark));
        }

        this.clearNotifications();
        confirmNotification();
      } catch (e) {
        this.errorHandler.notifyError(e, 'default', symbol);
      } finally {
        unload();
      }
    } else {
      this.notificationService.notify({ message: this.translationService.translate('Notification.ValidationError') }, symbol);
    }

    bookmark.setIsExecuting(false);
  };

  private readonly onObjectBookmarkDelete = (bookmark: IBookmark): void => {
    if (!isIObjectBookmark(bookmark)) {
      this.logger.logError('Cannot delete because bookmark is not an IObjectBookmark');
      return;
    }

    this.feedbackService
      .getConfirmation(
        this.translationService.translate('Delete'),
        this.translationService.translate('QuinoDataGrid.DeleteSingle', {
          objectTitle: this.titleCalculator.generate(bookmark)
        }),
        this.translationService.translate('Delete'),
        'material-icons-outlined delete'
      )
      .then((deletionConfirmed) => {
        if (deletionConfirmed) {
          this.deleteObjectBookmark(bookmark);
        }
      })
      .catch(this.logger.logError);
  };

  private readonly onObjectBookmarkDiscard = (bookmark: IBookmark): void => {
    if (!isIObjectBookmark(bookmark)) {
      this.logger.logError('Cannot discard because bookmark is not an IObjectBookmark');
      return;
    }

    if (isNewObjectBookmark(bookmark)) {
      if (this.navigationService.get().length > 1) {
        this.navigationService.pop().catch(this.logger.logError);
      } else {
        this.homeNavigationService.navigateHome().catch(this.logger.logError);
      }
    } else {
      bookmark.reset();
    }

    this.clearNotifications();
  };

  private readonly onCreatePushToNavigationStack = (newBookmark: IBookmark | undefined): void => {
    newBookmark && this.navigationService.push(newBookmark).catch(this.logger.logError);
  };

  public readonly createNewBookmark = async (
    bookmark: IBookmark,
    customFieldValues?: IFieldValue[],
    parentBookmark?: IBookmark
  ): Promise<IObjectBookmark | undefined> => {
    if (isIMetaClassAwareBookmark(bookmark)) {
      isIObjectBookmark(bookmark) && bookmark.setIsExecuting(true);

      const symbol = Symbol.for('StandardBookmarkActionsService.onCreate');

      const cleanUp = () => {
        isIObjectBookmark(bookmark) && bookmark.setIsExecuting(false);
        unload();
      };

      const unload = this.loadingFeedback.load();
      try {
        const newBookmark = await this.bookmarkFactory.createNewObject(
          bookmark.metaClass.name,
          parentBookmark ? parentBookmark : isIObjectBookmark(bookmark) ? bookmark.parentBookmark : undefined
        );
        customFieldValues && customFieldValues.forEach((v) => newBookmark.updateFieldValue(v.propertyName, v.propertyValue));
        this.clearNotifications();
        cleanUp();
        return newBookmark;
      } catch (error) {
        this.errorHandler.notifyError(error, 'default', symbol);
      }

      cleanUp();
    }
    return undefined;
  };

  private readonly deleteObjectBookmark = (bookmark: IBookmark) => {
    if (!isIObjectBookmark(bookmark)) {
      this.logger.logError('Cannot delete because bookmark is not an IObjectBookmark');
      return;
    }

    const symbol = Symbol.for('StandardBookmarkActionsService.deleteObjectBookmark');

    bookmark.setIsExecuting(true);
    const unload = this.loadingFeedback.load(this.translationService.translate('Deleting'));
    bookmark.reset();
    this.persistenceService
      .delete(bookmark.genericObject)
      .then(async () => {
        await this.navigationService.pop(true).catch(this.logger.logError);
        this.notificationService.notify(
          {
            message: this.translationService.translate('DeleteConfirmation'),
            type: 'success',
            area: 'global',
            autoDisappear: true
          },
          symbol
        );
      })
      .catch((error: Error) => {
        this.errorHandler.notifyError(error, 'default', symbol);
      })
      .finally(() => {
        bookmark.setIsExecuting(false);
        unload();
      });
  };

  private readonly clearNotifications = (): void => {
    this.notificationService.clearNotifications([
      Symbol.for('StandardBookmarkActionsService.deleteObjectBookmark'),
      Symbol.for('StandardBookmarkActionsService.onObjectBookmarkSave'),
      Symbol.for('StandardBookmarkActionsService.onCreate')
    ]);
  };

  getSortPropertyName = (bookmark: IBookmark): string | undefined => {
    if (isIMetaClassAwareBookmark(bookmark)) {
      const sortOrderAspect = getAspectOrDefault<ISortOrderAspect>(bookmark.metaClass, SortOrderAspectIdentifier, true);
      if (sortOrderAspect) {
        const sortPropertyName = sortOrderAspect.sortOrderProperty;
        const sortProperty = bookmark.metaClass.properties.find((property) => property.name === sortPropertyName);
        if (sortProperty && sortProperty.dataType === DataType.Integer) {
          return sortPropertyName;
        }
      }
    }

    return undefined;
  };

  private readonly getCreateSubItems = (
    bookmark: IBookmark,
    sortPropertyName: string | undefined,
    onCreate: (newBookmark: IBookmark | undefined) => void,
    parentBookmark?: IBookmark
  ): IQuinoBookmarkAction[] | undefined => {
    if (sortPropertyName) {
      const actions: IQuinoBookmarkAction[] = [];

      actions.push({
        icon: '',
        caption: this.translationService.translate('Detail.InsertStart'),
        hint: this.translationService.translate('Detail.InsertStart'),
        onClick: (args) =>
          isIBookmark(args.source) &&
          this.createNewBookmark(args.source, [{ propertyName: sortPropertyName, propertyValue: 1 }], parentBookmark).then(onCreate),
        visible: true,
        disabled: false
      });

      if (isIObjectBookmark(bookmark)) {
        const sortValue = bookmark.genericObject[sortPropertyName];
        if (typeof sortValue === 'number') {
          actions.push(
            {
              icon: '',
              caption: this.translationService.translate('Detail.InsertBeforeCurrent'),
              hint: this.translationService.translate('Detail.InsertBeforeCurrent'),
              onClick: (args) =>
                isIBookmark(args.source) &&
                this.createNewBookmark(args.source, [{ propertyName: sortPropertyName, propertyValue: sortValue }], parentBookmark).then(onCreate),
              visible: true,
              disabled: false
            },
            {
              icon: '',
              caption: this.translationService.translate('Detail.InsertAfterCurrent'),
              hint: this.translationService.translate('Detail.InsertAfterCurrent'),
              onClick: (args) =>
                isIBookmark(args.source) &&
                this.createNewBookmark(args.source, [{ propertyName: sortPropertyName, propertyValue: sortValue + 1 }], parentBookmark).then(
                  onCreate
                ),
              visible: true,
              disabled: false
            }
          );
        }
      }

      actions.push({
        icon: '',
        caption: this.translationService.translate('Detail.InsertEnd'),
        hint: this.translationService.translate('Detail.InsertEnd'),
        onClick: (args) => isIBookmark(args.source) && this.createNewBookmark(args.source, undefined, parentBookmark).then(onCreate),
        visible: true,
        disabled: false
      });

      return actions;
    }

    return undefined;
  };
}
