import { IMarkdownConverter } from './IMarkdownConverter';
import { inject, injectable } from 'inversify';
import { Converter } from 'showdown';
import { QuinoUIServiceSymbols } from '../../ioc';

const markdownPrefix = '[markdown]';

@injectable()
export class MarkdownConverter implements IMarkdownConverter {
  constructor(@inject(QuinoUIServiceSymbols.MarkdownHtmlRenderer) private readonly htmlRenderer: Converter) {
    htmlRenderer.setFlavor('github');
    htmlRenderer.setOption('tables', true);
  }

  convertToHtml(text: string): string {
    return this.htmlRenderer.makeHtml(text.replace(markdownPrefix, ''));
  }

  isMarkdown(text: any): boolean {
    return typeof text === 'string' ? text.toLocaleLowerCase().startsWith(markdownPrefix) : false;
  }
}
