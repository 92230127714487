import { useService } from '../ioc';
import { INotification, INotificationService, INotificationServiceSymbol } from '@quino/core';
import { useCallback, useEffect, useState } from 'react';

export const useNotifications = () => {
  const notificationService = useService<INotificationService>(INotificationServiceSymbol);
  const [symbols, setSymbols] = useState<Symbol[]>([]);

  const notify = useCallback(
    (notification: INotification, predecessor?: Symbol) => {
      setSymbols((s) => [...s.filter((s) => s != predecessor), notificationService.notify(notification, predecessor)]);
    },
    [notificationService]
  );

  const clearNotifications = useCallback(() => {
    notificationService.clearNotifications(symbols);
    setSymbols([]);
  }, [notificationService, symbols]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearNotifications(), []);

  return {
    notify,
    clearNotifications
  };
};
