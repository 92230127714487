import React, { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { TreeView } from 'devextreme-react/tree-view';
import {
  IBookmark,
  IODataSourceFactory,
  IODataSourceFactorySymbol,
  isIListBookmark,
  isIObjectBookmark,
  QuinoCrossLink,
  useOnBookmarkReloadEvent,
  useOnBookmarkSavedEvent,
  useRerender,
  useService
} from '@quino/ui';
import {
  getAspectOrDefault,
  IExpressionEvaluator,
  IGenericObject,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetaClass,
  IMetadataTree,
  IMetaLayout,
  IMetaRelation,
  ITitleCalculator,
  ITitleCalculatorSymbol,
  ITranslationService,
  ITranslationServiceSymbol,
  IValueListAspect,
  IVisibleInExplorerAspect,
  LayoutType,
  MetaCardinality,
  ODataDefaultData,
  QuinoCoreServiceSymbols,
  ValueListAspectIdentifier,
  VisibleInExplorerAspectIdentifier
} from '@quino/core';
import { Item as TreeViewItem, Node as TreeViewNode } from 'devextreme/ui/tree_view';
import DataSource from 'devextreme/data/data_source';
import cloneDeep from 'lodash/cloneDeep';
import { ICUIExplorerSettings, ICUIExplorerSettingsSymbol } from '../../configuration';

export interface ICUIExplorerProps {
  bookmark: IBookmark;
  loadingWheelContainerSelector?: string;
}

export function CUIExplorer(props: ICUIExplorerProps) {
  const metadataService = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const odataFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const titleCalculator = useService<ITitleCalculator>(ITitleCalculatorSymbol);
  const explorerSettings = useService<ICUIExplorerSettings>(ICUIExplorerSettingsSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const expressionEvaluator = useService<IExpressionEvaluator>(QuinoCoreServiceSymbols.IExpressionEvaluator);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);

  const [error, setError] = useState<Error | undefined>(undefined);
  const [forceRerender, setForceRerender] = useState<number>(0);

  const rerender = useRerender();
  useOnBookmarkSavedEvent(props.bookmark, rerender);

  useOnBookmarkReloadEvent(props.bookmark, () => {
    setForceRerender(Math.random());
  });

  if (error) {
    return (
      <div className='quino-ecui-sidebar-explorer-area has-error'>
        <p className={'quino-error-boundary-message-title'}>
          {translationService.translate('Notification.ComponentErrorBoundaryError', {
            name: (isIObjectBookmark(props.bookmark) && props.bookmark.metaClass.caption) || 'unknown',
            controlName: 'Explorer'
          })}
        </p>
        {<p className={'quino-error-boundary-message-text'}>{error.message}</p>}
      </div>
    );
  }

  if (isIListBookmark(props.bookmark) || (isIObjectBookmark(props.bookmark) && props.bookmark.genericObject.primaryKey === 'null')) {
    return <div className='quino-ecui-sidebar-explorer-area' />;
  }

  const explorerTitle = `Explorer ${
    explorerSettings.showRootSiblings
      ? isIObjectBookmark(props.bookmark)
        ? ` - ${metadataService.getClass(props.bookmark.genericObject.metaClass).pluralCaption}`
        : ''
      : ''
  }`;

  const createEntryWithRelations = (
    level: number,
    parentId: string | undefined | number,
    genericObject: IGenericObject,
    expanded = false,
    bookmark: IBookmark | undefined,
    showCaption = false,
    relation?: IMetaRelation
  ) => {
    const metaClass = metadataService.getClass(genericObject.metaClass);
    const generatedTitle = titleCalculator.generate(genericObject, genericObject.metaClass);
    const title = generatedTitle
      ? showCaption
        ? `${relation ? relation.caption : metaClass.caption} - ${generatedTitle}`
        : generatedTitle
      : metaClass.caption;
    const id = `${parentId}-${genericObject.primaryKey}-${genericObject.metaClass}-${level}-${relation ? relation.name : ''}`;
    const visibleRelations = getVisibleRelations(metaClass, genericObject);
    return {
      level: level,
      id: id,
      genericObject: genericObject,
      text: title,
      relations: visibleRelations,
      hasItems: visibleRelations.length > 0 && level < 5,
      parentId: parentId,
      expanded: expanded,
      displayCrossLink: isIObjectBookmark(bookmark)
        ? bookmark.genericObject.primaryKey !== genericObject.primaryKey || genericObject.metaClass !== bookmark.genericObject.metaClass
        : true
    };
  };

  const getVisibleRelations = (targetClass: IMetaClass, genericObject: IGenericObject): IMetaRelation[] => {
    return targetClass.relations.filter((value: IMetaRelation) => {
      const visibleInExplorerAspect = getAspectOrDefault<IVisibleInExplorerAspect>(value, VisibleInExplorerAspectIdentifier);
      // use the generic object which belongs to the target class
      const data =
        isIObjectBookmark(props.bookmark) && targetClass.name === props.bookmark.genericObject.metaClass
          ? props.bookmark.genericObject
          : genericObject;
      return (
        (visibleInExplorerAspect !== null && expressionEvaluator.evaluate<boolean>(visibleInExplorerAspect.visible, data)) ||
        visibleInExplorerAspect === null
      );
    });
  };

  const createGroupNode = (level: number, parentId: string | undefined | number, relation: IMetaRelation, genericObject: IGenericObject) => {
    const title = relation.caption;
    const id = `${parentId}-${title}-${level}-${relation.name}`;
    return {
      level: level,
      id: id,
      genericObject: genericObject,
      text: title,
      relation: relation,
      hasItems: true,
      parentId: parentId,
      expanded: false,
      displayCrossLink: false,
      icon: 'material-icons-outlined menu'
    };
  };

  const createEmptyEntry = (level: number, parentId: string | number | undefined, relation: IMetaRelation) => {
    const title = `${
      relation.cardinality === MetaCardinality.MustBeOne || relation.cardinality === MetaCardinality.ZeroOrOne ? `${relation.caption} - ` : ''
    }${translationService.translate('CUIExplorer.NoDataText')}`;
    const id = `${parentId}-${title}-${level}-${relation.name}`;
    return { level: level, id: id, text: title, hasItems: false, parentId: parentId, expanded: false, displayCrossLink: false };
  };

  const createCouldNotResolveDataEntry = (level: number, parentId: number | string | undefined, relation: IMetaRelation) => {
    const title = `${
      relation.cardinality === MetaCardinality.MustBeOne || relation.cardinality === MetaCardinality.ZeroOrOne ? `${relation.caption} - ` : ''
    } Could not load the data`;
    const id = `${parentId}-${title}-${level}-${relation.name}`;
    return { level: level, id: id, text: title, hasItems: false, parentId: parentId, expanded: false, displayCrossLink: false };
  };

  const createShowMoreEntry = (level: number, parentId: string | undefined | number, oDataSource: DataSource) => {
    const title = `${translationService.translate('CUIExplorer.More')}...`;
    const id = `${parentId}-moreButton-${level}-${oDataSource.pageIndex()}`;
    return {
      level: level,
      id: id,
      text: title,
      hasItems: true,
      parentId: parentId,
      expanded: false,
      oDataSource: oDataSource,
      displayCrossLink: false
    };
  };

  const createDataSource = (relation: IMetaRelation, genericObject: IGenericObject, withDefaultData: boolean): DataSource | undefined => {
    const defaultData: ODataDefaultData | undefined = withDefaultData
      ? { key: relation.targetProperties[0], data: genericObject[relation.path] }
      : undefined;
    const titleLayout = createTitleLayoutWithRelations(relation.targetClass, genericObject);
    if (!titleLayout) {
      return undefined;
    }
    const dataSource = odataFactory.create(
      titleLayout,
      relation.targetClass,
      {
        requireTotalCount: true,
        pageSize: 10
      },
      defaultData
    );
    dataSource.filter([relation.targetProperties[0], '=', genericObject[relation.sourceProperties[0]]]);
    return dataSource;
  };

  const createTitleLayoutWithRelations = (metaClass: string, genericObject: IGenericObject): IMetaLayout | undefined => {
    const layout = layoutResolver.tryResolveSingle({ metaClass: metaClass, type: LayoutType.Title });
    if (!layout) {
      setError(new Error(`Could not find layout [${LayoutType.Title}] for [${metaClass}] in available layouts.`));
      return undefined;
    }
    const clonedLayout = cloneDeep(layout);
    getVisibleRelations(metadataService.getClass(metaClass), genericObject).forEach(
      (value) =>
        !getAspectOrDefault<IValueListAspect>(value, ValueListAspectIdentifier) &&
        (genericObject[value.sourceProperties[0]] || value.cardinality === MetaCardinality.MustBeOne) &&
        value.cardinality !== MetaCardinality.Multiple &&
        clonedLayout.elements.push(value)
    );
    return clonedLayout;
  };

  const createEntriesForRelations = async (
    relations: IMetaRelation[],
    genericObject: IGenericObject,
    parentId: string | undefined | number,
    level: number
  ): Promise<any[]> => {
    const items = [];
    for (const relation of relations) {
      if (getAspectOrDefault<IValueListAspect>(relation, ValueListAspectIdentifier)) {
        continue;
      }
      switch (relation.cardinality) {
        case MetaCardinality.ZeroOrOne:
        case MetaCardinality.MustBeOne:
          if (genericObject[relation.sourceProperties[0]] != null) {
            const dataSource = createDataSource(relation, genericObject, level < 2);
            if (!dataSource) {
              return [];
            }
            try {
              const result = await dataSource.load();
              items.push(createEntryWithRelations(level, parentId, result[0], false, props.bookmark, true, relation));
            } catch (error) {
              logger.logError(error);
              items.push(createCouldNotResolveDataEntry(level, parentId, relation));
            }
          } else {
            items.push(createEmptyEntry(level, parentId, relation));
          }
          break;
        case MetaCardinality.Multiple:
          items.push(createGroupNode(level, parentId, relation, genericObject));
          break;
      }
    }
    return items;
  };

  const createChildren = async (parentNode: TreeViewNode): Promise<any[]> => {
    if (null == parentNode) {
      if (isIObjectBookmark(props.bookmark)) {
        const unload = loadingFeedback.loadAtPosition({
          my: 'center',
          at: 'center',
          of: props?.loadingWheelContainerSelector ? props?.loadingWheelContainerSelector : 'body'
        });
        const items: any = [];
        const targetClass = metadataService.getClass(props.bookmark.genericObject.metaClass);
        const defaultData: ODataDefaultData = {
          key: targetClass.primaryKey[0],
          data: props.bookmark.genericObject
        };
        const titleLayout = createTitleLayoutWithRelations(props.bookmark.genericObject.metaClass, props.bookmark.genericObject);
        if (!titleLayout) {
          unload();
          return [];
        }
        const dataSource = odataFactory.create(titleLayout, props.bookmark.genericObject.metaClass, undefined, defaultData);
        dataSource.filter([targetClass.primaryKey[0], '=', props.bookmark.genericObject[targetClass.primaryKey[0]]]);

        try {
          await dataSource.load();
        } catch (error) {
          logger.logError(error);
          unload();
          return items;
        }

        const result = dataSource.items();
        if (!explorerSettings.showRootSiblings && result) {
          items.push(...(await createEntriesForRelations(getVisibleRelations(targetClass, result[0]), result[0], '', 1)));
        } else {
          const titleLayout = createTitleLayoutWithRelations(props.bookmark.genericObject.metaClass, props.bookmark.genericObject);
          if (!titleLayout) {
            return [];
          }
          const siblingsDataSource = odataFactory.create(titleLayout, props.bookmark.genericObject.metaClass, {
            requireTotalCount: true,
            pageSize: 5
          });
          siblingsDataSource.filter(['!', [targetClass.primaryKey[0], '=', props.bookmark.genericObject[targetClass.primaryKey[0]]]]);
          try {
            await siblingsDataSource.load();
          } catch (error) {
            logger.logError(error);
            unload();
            return items;
          }
          siblingsDataSource.items().forEach((value) => {
            items.push(createEntryWithRelations(1, '', value, false, props.bookmark));
          });

          items.unshift(createEntryWithRelations(1, '', result[0], true, props.bookmark));

          if (siblingsDataSource.totalCount() / siblingsDataSource.pageSize() > siblingsDataSource.pageIndex() + 1) {
            items.push(createShowMoreEntry(1, '', siblingsDataSource));
          }
        }
        unload();
        return items;
      }
    } else {
      const parentData = parentNode.itemData;
      const newLevel = parentData && parentData.level + 1;
      const items: any[] = [];
      const parentDataId = parentData && parentData.id;

      if (parentData && parentData.relation) {
        const dataSource = createDataSource(parentData.relation, parentData.genericObject, parentData.level === 0);
        if (!dataSource) {
          return [];
        }
        try {
          await dataSource.load();
        } catch (error) {
          logger.logError(error);
          items.push(createCouldNotResolveDataEntry(newLevel, parentDataId, parentData.relation));
          return items;
        }

        const list = dataSource.items();
        if (list.length === 0) {
          items.push(createEmptyEntry(newLevel, parentDataId, parentData.relation));
        }
        for (const entry of list) {
          items.push(createEntryWithRelations(newLevel, parentDataId, entry, false, props.bookmark));
        }
        if (dataSource.totalCount() / dataSource.pageSize() > dataSource.pageIndex() + 1) {
          items.push(createShowMoreEntry(newLevel, parentDataId, dataSource));
        }
      } else if (parentData && parentData.relations) {
        items.push(...(await createEntriesForRelations(parentData.relations, parentData.genericObject, parentDataId, newLevel)));
      } else if (parentData && parentData.oDataSource) {
        parentData.oDataSource.pageIndex(parentData.oDataSource.pageIndex() + 1);

        try {
          await parentData.oDataSource.load();
        } catch (error) {
          logger.logError(error);
          return items;
        }

        const result = parentData.oDataSource.items();
        for (const genericObject of result) {
          items.push(createEntryWithRelations(parentData.level, parentDataId, genericObject, false, props.bookmark));
        }
        if (parentData.oDataSource.totalCount() / parentData.oDataSource.pageSize() > parentData.oDataSource.pageIndex() + 1) {
          items.push(createShowMoreEntry(parentData.level, parentDataId, parentData.oDataSource));
        }

        if (parentData.itemElement) {
          parentData.itemElement.classList.add('hide-item');
        }
      }
      return items;
    }

    return [];
  };

  return (
    <div className={'quino-ecui-sidebar-explorer-area'}>
      <div className={'quino-ecui-sidebar-title'}>
        <p>{explorerTitle}</p>
      </div>
      <div className='quino-ecui-explorer-treeview-wrapper'>
        <TreeView
          className='quino-ecui-explorer-treeview'
          key={isIObjectBookmark(props.bookmark) ? JSON.stringify(props.bookmark.genericObject) + forceRerender : undefined}
          itemKeyFn={(data) => `${data.id}_${data.expanded}`}
          createChildren={createChildren}
          rootValue={''}
          hoverStateEnabled={false}
          activeStateEnabled={false}
          focusStateEnabled={false}
          selectByClick={false}
          expandEvent={'dblclick'}
          onItemClick={async (e: any) => {
            if (
              !(e.event && e.event.target && e.event.target.className && e.event.target.className.trim().includes('quino-cross-link')) &&
              e.itemData.hasItems
            ) {
              if (e.itemData.expanded) {
                e.component?.collapseItem(e.itemData);
              } else {
                if (e.itemData.oDataSource && e.itemElement) {
                  e.itemData.itemElement = e.itemElement;
                }
                if (e.itemData.level < 2 && !e.itemData.isCompletelyLoaded) {
                  // update data of first level elements, which just contains default data
                  const defaultGenericObject = e.itemData.genericObject;
                  const metaClass = metadataService.getClass(defaultGenericObject.metaClass);
                  const titleLayout = createTitleLayoutWithRelations(metaClass.name, defaultGenericObject);
                  if (!titleLayout) {
                    return;
                  }
                  const dataSource = odataFactory.create(titleLayout, metaClass.name);
                  dataSource.filter([metaClass.primaryKey[0], '=', defaultGenericObject[metaClass.primaryKey[0]]]);

                  try {
                    const fullGenericObject = await dataSource.load();
                    e.itemData.isCompletelyLoaded = true;
                    e.itemData.genericObject = fullGenericObject[0];
                    e.itemData.relations = getVisibleRelations(metaClass, e.itemData.genericObject);
                  } catch (error) {
                    logger.logError(error);
                  }
                }
                e.component?.expandItem(e.itemData);
              }
            }

            if (e.itemData.oDataSource && e.itemElement) {
              e.itemData.text = '';
            }
          }}
          onItemExpanded={(e) => {
            if (e.itemData && e.itemData.oDataSource && e.itemElement) {
              e.itemElement.style.display = 'none';
            }
          }}
          itemComponent={TreeViewItemTemplate}
          dataStructure={'plain'}
          noDataText={''}
        />
      </div>
    </div>
  );
}

class TreeViewItemTemplate extends React.PureComponent<{ data: TreeViewItem & any }> {
  render() {
    const icon = this.props.data.hasItems
      ? this.props.data.expanded
        ? 'material-icons-outlined expand_more'
        : 'material-icons-outlined expand_less'
      : undefined;
    const noIconSpecialPadding = icon ? 0 : 27;
    const paddingLeft = 10 + (this.props.data.level - 1) * 8 + noIconSpecialPadding;

    const getEntry = () => {
      return (
        <div
          onClick={() => this.forceUpdate()}
          key={`${this.props.data.id}_${this.props.data.expanded}`}
          style={{ display: 'flex', alignItems: 'center', height: '100%', paddingLeft: `${paddingLeft}px` }}>
          <Button
            key={'expander'}
            focusStateEnabled={false}
            hoverStateEnabled={false}
            visible={this.props.data.hasItems && !this.props.data.oDataSource}
            icon={icon}
            stylingMode={'text'}
          />
          <Button
            key={'group_title'}
            className={'quino-ecui-explorer-button'}
            focusStateEnabled={false}
            hoverStateEnabled={false}
            text={this.props.data.text}
            stylingMode={'text'}
            hint={this.props.data.text}
            icon={this.props.data.icon}
            visible={!this.props.data.displayCrossLink && !this.props.data.oDataSource}
          />

          {this.props.data.oDataSource && <a>{this.props.data.text}</a>}
          {this.props.data.displayCrossLink && (
            <QuinoCrossLink
              targetClass={this.props.data.genericObject.metaClass}
              primaryKey={this.props.data.genericObject.primaryKey}
              visible={true}
              showTitle={true}
              title={this.props.data.text}
            />
          )}
        </div>
      );
    };

    return getEntry();
  }
}
