import { getMetaProperty } from '@quino/core';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { QuinoLabeled } from '../QuinoLabeled';
import { useOnBookmarkAnyEvent, useRerender, useValidation } from '../Util';
import { useLabelSettings, useMetadata } from '../../settings';
import { QuinoTranslationKeyPicker } from './QuinoTranslationKeyPicker';

export function QuinoTranslationKeyPickerLabeled(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const { bookmark } = props;
  const metaProperty = getMetaProperty(props.element);
  const { description, caption, instruction } = metaProperty;
  const labelSettings = useLabelSettings(props.element);
  const { visible, required } = useMetadata(metaProperty, bookmark.genericObject);
  const [, errorMessages] = useValidation(bookmark, metaProperty);
  const rerender = useRerender();

  useOnBookmarkAnyEvent(bookmark, () => rerender());

  return (
    <QuinoLabeled
      visible={visible}
      className={'quino-input-fields-max-width'}
      label={caption}
      required={required}
      description={description}
      instruction={instruction}
      errorMessages={errorMessages}
      labelSettings={labelSettings}>
      <QuinoTranslationKeyPicker metaProperty={metaProperty} bookmark={bookmark} />
    </QuinoLabeled>
  );
}
