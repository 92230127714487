import { IMetaAspect } from '@quino/core';
import { IColumnConfiguration } from '../components/QuinoDataGrid/IColumnConfiguration';

export const ColumnConfigurationAspectIdentifier = 'ColumnConfiguration';

/**
 * Aspect containing configuration info for list columns.
 */
export interface IColumnConfigurationAspect extends IMetaAspect {
  /**
   * The partial configuration information.
   */
  configuration: Partial<IColumnConfiguration>;
}
