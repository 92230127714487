import { TResponsiveMode } from './IResponsivityService';

/**
 * Return classname with the correct suffix for the current responsive mode
 */
export const getResponsiveClassName = (className: string, responsiveMode: TResponsiveMode): string => {
  switch (responsiveMode) {
    case TResponsiveMode.Phone:
      return className + ' is--phone';
    case TResponsiveMode.Tablet:
      return className + ' is--tablet';
    default:
      return className;
  }
};
