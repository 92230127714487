import { httpMethods } from '../../request';

export const IODataBatchRequestFactorySymbol = Symbol.for('IODataBatchRequestFactory');

export interface IODataBatchRequest {
  id: string;
  method: httpMethods;
  url: string;
  body?: any;
  headers?: any;
}

export interface IODataBatchResponse {
  id: string;
  status: number;
  body?: any;
  headers?: { [key: string]: string };
}

/**
 * A factory for creating OData batch requests.
 */
export interface IODataBatchRequestFactory {
  /**
   * Performs a OData batch request which will add all header and authentication to the request which are needed.
   * @param requests - an array of single request to be batched in one request.
   */
  fetch(requests: IODataBatchRequest[]): Promise<IODataBatchResponse[]>;
}
