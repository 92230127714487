import React, { PropsWithChildren, useMemo, useState } from 'react';
import { TextAlignProperty } from 'csstype';
import { ILabelSettings, QuinoLabeledLabelPosition } from '../../settings/ILabelSettings';
import { useLabelSettings } from '../../settings/useLabelSettings';
import { useCurrentLanguage } from '../../settings/useCurrentLanguage';
import { useService } from '../../ioc/hook';
import { IMarkdownConverter } from '../../rendering/MarkdownConverter';
import { IMarkdownConverterSymbol } from '../../rendering/MarkdownConverter/IMarkdownConverter';
import { Button } from 'devextreme-react/button';
import { Tooltip } from 'devextreme-react/tooltip';
import { ILogger, QuinoCoreServiceSymbols } from '@quino/core';
import uniqueId from 'lodash/uniqueId';
import { QuinoErrorSummary } from '../QuinoErrorSummary';
import { INotificationService, INotificationServiceSymbol, ITranslationService, ITranslationServiceSymbol } from '@quino/core';

export function QuinoLabeled(
  props: PropsWithChildren<{
    className?: string;
    label: string;
    description?: string;
    instruction?: string;
    errorMessages?: string[];
    required?: boolean;
    labelPosition?: QuinoLabeledLabelPosition;
    labelSettings?: ILabelSettings;
    valueTextAlign?: TextAlignProperty;
    visible?: boolean;
  }>
) {
  const { label, description, required, children, instruction, valueTextAlign, errorMessages, className, visible } = props;
  const defaultLabelSettings = useLabelSettings(null);
  const labelSettings = props.labelSettings ?? defaultLabelSettings;
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const labelPosition = props.labelPosition == null ? labelSettings.alignment : props.labelPosition;
  const isLabelPositionTop = labelPosition === QuinoLabeledLabelPosition.Top;
  const markdownConverter = useService<IMarkdownConverter>(IMarkdownConverterSymbol);
  const notificationService = useService<INotificationService>(INotificationServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const labelWithRequired = `${label}${required ? ' *' : ''}`;
  const isLatin = useCurrentLanguage() === 'la';
  const [showDescription, setShowDescription] = useState(false);
  const uid = useMemo(() => {
    return 'description-' + uniqueId();
  }, []);
  const style = useMemo(() => {
    if (valueTextAlign) {
      return { textAlign: valueTextAlign };
    }

    return undefined;
  }, [valueTextAlign]);

  return (
    // prevents to crash if a QuinoLabeled is made invisible directly inside a ScrollView
    <div className={`${className ? className : ''}${(visible === false && ' quino-labeled-hidden') || ''}`}>
      {visible !== false && (
        <div className={`dx-field quino-field ${(isLabelPositionTop && 'quino-label-position-top') || ''}`}>
          <div className={'dx-field-label quino-field-label'} title={labelSettings.hintType === 'None' ? description : undefined}>
            {isLatin && (
              <Button
                stylingMode={'text'}
                icon={'material-icons-outlined copy'}
                onClick={() => {
                  navigator.clipboard
                    .writeText(label)
                    .then(() =>
                      notificationService.notify({
                        type: 'info',
                        area: 'global',
                        autoDisappear: true,
                        message: translationService.translate('CopiedToClipboard', { text: label })
                      })
                    )
                    .catch((e) => logger.logError(e));
                }}
              />
            )}
            {label &&
              ((markdownConverter.isMarkdown(labelWithRequired) && (
                <div className={'quino-markdown'} dangerouslySetInnerHTML={{ __html: markdownConverter.convertToHtml(labelWithRequired) }} />
              )) ||
                labelWithRequired)}
            {labelSettings.hintType === 'All' && description && (
              <>
                <i
                  id={uid}
                  className='material-icons-outlined info'
                  onClick={() => setShowDescription(!showDescription)}
                  onMouseEnter={() => setShowDescription(true)}
                  onMouseLeave={() => setShowDescription(false)}
                />
                <Tooltip
                  visible={showDescription}
                  target={`#${uid}`}
                  position={'top'}
                  closeOnOutsideClick={true}
                  onHiding={() => setShowDescription(false)}>
                  <div className={'quino-labeled-description'}>
                    {(markdownConverter.isMarkdown(description) && (
                      <div className={'quino-markdown'} dangerouslySetInnerHTML={{ __html: markdownConverter.convertToHtml(`${description}`) }} />
                    )) ||
                      description}
                  </div>
                </Tooltip>
              </>
            )}
          </div>
          <div className={`dx-field-value quino-field-value`} style={style}>
            {children}
          </div>
          <QuinoErrorSummary errorMessages={errorMessages} />
          {instruction && <div className={'quino-labeled-instruction'}>{instruction}</div>}
        </div>
      )}
    </div>
  );
}
