import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for the responsive box aspect.
 */
export const ResponsiveLocationAspectIdentifier = 'ResponsiveLocation';

/**
 * The responsive box aspect is used to define the rows and columns of the responsive box.
 */
export interface IResponsiveLocationAspect extends IMetaAspect {
  column: number;
  row: number;
  columnSpan: number;
  rowSpan: number;
}
