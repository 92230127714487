import { useEffect, useState } from 'react';
import { IResponsivityService, IResponsivityServiceSymbol, TResponsiveMode } from './IResponsivityService';
import { useService } from '../ioc';

/**
 * Get the current responsive mode
 */
export const useResponsiveMode = (): TResponsiveMode => {
  const responsivityService = useService<IResponsivityService>(IResponsivityServiceSymbol);
  const [responsiveMode, setResponsiveMode] = useState<TResponsiveMode>(responsivityService.getCurrentResponsiveMode());

  useEffect(() => {
    const symbol = responsivityService.subscribeToWidthChanges(setResponsiveMode);

    return () => {
      responsivityService.unsubscribeFromWidthChanges(symbol);
    };
  }, [responsivityService]);

  return responsiveMode;
};

/**
 * @deprecated Use useResponsiveMode instead.
 */
export const useMobileLayout = (): boolean => {
  const mobileLimit = 960;
  const [mobileLayout, setMobileLayout] = useState<boolean>(window.innerWidth < mobileLimit);

  const checkMobileLayout = () => {
    setMobileLayout(window.innerWidth < mobileLimit);
  };

  useEffect(() => {
    checkMobileLayout();
    window.addEventListener('resize', checkMobileLayout);

    return () => {
      window.removeEventListener('resize', checkMobileLayout);
    };
  }, []);

  return mobileLayout;
};
