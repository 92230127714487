import React, { useMemo, useState } from 'react';
import { Resizable } from 'devextreme-react/resizable';
import { IObjectBookmark, ILayoutActionsService, LayoutActionsServiceSymbol, useOnMount, useService } from '@quino/ui';
import { ILogger, IStorageService, LocalStorageServiceSymbol, QuinoCoreServiceSymbols } from '@quino/core';
import { CUISidebarActions } from './CUISidebarActions';
import { CUIExplorer } from './CUIExplorer';
import { useCuiSettings } from '../../util';

export function CUISidebar(props: { bookmark: IObjectBookmark }) {
  const settingsService = useService<IStorageService>(LocalStorageServiceSymbol);
  const actionService = useService<ILayoutActionsService>(LayoutActionsServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const cuiSettings = useCuiSettings();

  const { bookmark } = props;
  const widthStorageKey = 'sidebar_width';
  const sidebarWidthMin = 200;
  const sidebarWidthDefault = 240;
  const sidebarWidthMax = 320;
  const [sidebarWidth, setSidebarWidth] = useState<number>(sidebarWidthDefault);

  const showExplorer = cuiSettings.showExplorer;
  const actions = useMemo(() => actionService.getSidebarActions(bookmark), [actionService, bookmark]);

  useOnMount(() => {
    settingsService
      .get(widthStorageKey)
      .then((value) => value && typeof value === 'number' && setSidebarWidth(value))
      .catch(logger.logError);
  });

  return actions.length > 0 || showExplorer ? (
    <div className={'quino-ecui-sidebar'}>
      <Resizable
        className={'quino-ecui-sidebar-resizable'}
        handles={'left'}
        minWidth={sidebarWidthMin}
        maxWidth={sidebarWidthMax}
        width={
          cuiSettings.initialSidebarWidth >= sidebarWidthMin && cuiSettings.initialSidebarWidth <= sidebarWidthMax
            ? cuiSettings.initialSidebarWidth
            : sidebarWidth
        }
        onResize={(e) => e.width && setSidebarWidth(e.width)}
        onResizeEnd={(e) => e.width && settingsService.set(widthStorageKey, e.width)}>
        <CUISidebarActions actions={actions} bookmark={bookmark} displayFullHeight={!showExplorer} searchEnabled={cuiSettings.showActionSearch} />
        {showExplorer && <CUIExplorer bookmark={bookmark} loadingWheelContainerSelector={'.quino-ecui-sidebar-explorer-area'} />}
      </Resizable>
    </div>
  ) : (
    <></>
  );
}
