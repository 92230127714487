import { IUrlManager } from './index';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IUrlHelper } from '../IUrlHelper';
import isArray from 'lodash/isArray';

export const DEFAULT_SERVER_BASEURL = 'http://localhost:9000/';

/**
 * URL-Manager that implements URL's that points to a Quino WebAPI server.
 */
@injectable()
export class QuinoUrlManager implements IUrlManager {
  constructor(@inject(QuinoCoreServiceSymbols.IUrlHelper) private readonly urlHelper: IUrlHelper) {}

  getDefaultTranslationsUrl() {
    return this.urlHelper.combineUrlParts(this.translationControllerName, 'all');
  }

  getODataBaseUrl(): string {
    return this.urlHelper.combineUrlParts(this.oDataControllerName);
  }

  getODataBatchUrl(): string {
    return this.urlHelper.combineUrlParts(this.oDataControllerName, '$batch');
  }

  getUserInfoUrl(): string {
    return this.urlHelper.combineUrlParts(this.userInfoControllerName);
  }

  getServerRestartUrl(): string {
    return this.urlHelper.combineUrlParts(this.applicationController, 'stop');
  }

  getServerHealthCheckUrl(): string {
    return this.urlHelper.combineUrlParts(this.applicationController, 'healthcheck');
  }

  getIdentityUrl(): string {
    return this.urlHelper.combineUrlParts(this.identityControllerName);
  }

  getConfigurationUrl(): string {
    return this.urlHelper.combineUrlParts(this.configControllerName);
  }

  getSharedDashBoardsUrl(): string {
    return this.urlHelper.combineUrlParts(this.dashboardControllerName, 'sharedDashboards');
  }

  getParseExpressionUrl(expression: string): string {
    return (
      this.urlHelper.combineUrlParts(this.dashboardControllerName, 'parseExpression') +
      this.urlHelper.combineQueryParams({
        expression: expression
      })
    );
  }

  getLanguageTranslationsUrl(languageCode: string) {
    return this.urlHelper.combineUrlParts(this.translationControllerName, languageCode);
  }

  getLanguageListUrl() {
    return this.urlHelper.combineUrlParts(this.metadataControllerName, 'language', 'display');
  }

  getRegistrationUrl() {
    return this.urlHelper.combineUrlParts(this.authenticationControllerName, 'register');
  }

  getLoginUrl() {
    return this.urlHelper.combineUrlParts(this.authenticationControllerName);
  }

  getAllEntitiesUrl() {
    return this.urlHelper.combineUrlParts(this.metadataControllerName, 'class');
  }

  getAllMetadataUrl() {
    return this.urlHelper.combineUrlParts(this.metadataControllerName);
  }

  getCurrentMetadataUrl(_hash: string | null) {
    return this.urlHelper.combineUrlParts(this.metadataControllerName, 'currentModel');
  }

  getMenuMetadata(): string {
    return this.urlHelper.combineUrlParts(this.metadataControllerName, 'navigation');
  }

  getViewsForEntityUrl(className: string) {
    return this.urlHelper.combineUrlParts(this.metadataControllerName, 'class', className, 'view');
  }

  getViewUrl(className: string, viewName: string) {
    return this.urlHelper.combineUrlParts(this.metadataControllerName, 'class', className, 'view', viewName);
  }

  getListUrl = (className: string, viewName: string | null) => {
    if (viewName == null) {
      return this.urlHelper.combineUrlParts(this.genericControllerName, 'class', className);
    }

    return (
      this.urlHelper.combineUrlParts(this.genericControllerName, 'classFromLayout', className) +
      this.urlHelper.combineQueryParams({
        layouts: viewName
      })
    );
  };

  getObjectUrl(className: string, primaryKey: string | null, viewName: string | null) {
    let urlParts = [this.genericControllerName, 'class', className];

    if (primaryKey) {
      urlParts = [...urlParts, 'primaryKey', primaryKey];
    } else {
      urlParts = [...urlParts, 'new'];
    }

    let baseUrl = this.urlHelper.combineUrlParts(...urlParts);

    if (!viewName) {
      return baseUrl;
    }

    const queryParams: any = {};

    if (viewName) {
      queryParams.layout = viewName;
    }

    baseUrl = baseUrl + this.urlHelper.combineQueryParams({ ...queryParams });

    return baseUrl;
  }

  updateObjectUrl(className: string, primaryKey: string) {
    return this.urlHelper.combineUrlParts(this.genericControllerName, 'class', className, 'primaryKey', primaryKey);
  }

  insertObjectUrl(className: string): string {
    return this.urlHelper.combineUrlParts(this.genericControllerName, 'class', className);
  }

  deleteObjectUrl(className: string, primaryKey: string) {
    return this.urlHelper.combineUrlParts(this.genericControllerName, 'class', className, 'primaryKey', primaryKey);
  }

  getRelatedObjectsForUrl(className: string, primaryKey: string, relationName: string, viewName: string | null) {
    const queryParams = {};
    if (viewName) {
      queryParams['viewName'] = viewName;
    }

    return (
      this.urlHelper.combineUrlParts(this.genericControllerName, 'class', className, 'primaryKey', primaryKey, 'relatedObjectsFor', relationName) +
      this.urlHelper.combineQueryParams(queryParams)
    );
  }

  getRelatedObjectForUrl(className: string, primaryKey: string, relationName: string, viewName: string | null) {
    const queryParams = {};
    if (viewName) {
      queryParams['viewName'] = viewName;
    }

    return (
      this.urlHelper.combineUrlParts(this.genericControllerName, 'class', className, 'primaryKey', primaryKey, 'relatedObjectFor', relationName) +
      this.urlHelper.combineQueryParams(queryParams)
    );
  }

  getVersionInfoUrl(): string {
    return this.urlHelper.combineUrlParts(this.serverInfoControllerName, 'version');
  }

  setSettingUrl(key: string): string {
    return this.urlHelper.combineUrlParts(this.settingsControllerName, key);
  }

  getSettingUrl(key: string): string {
    return this.urlHelper.combineUrlParts(this.settingsControllerName, key);
  }

  clearSettingsUrl(): string {
    return this.urlHelper.combineUrlParts(this.settingsControllerName);
  }

  getExportUrl(className: string, includeRelations: 'None' | 'One' | 'Recursive', filter?: Map<string, string> | string[]): string {
    const queryParams = {
      includeRelations: includeRelations
    } as {
      includeRelations: 'None' | 'One' | 'Recursive';
      selected?: string[];
      filter?: Map<string, string>;
    };
    if (filter) {
      if (isArray(filter)) {
        queryParams.selected = filter;
      } else {
        queryParams.filter = filter;
      }
    }

    return this.urlHelper.combineUrlParts(this.exportControllerName, className) + this.urlHelper.combineQueryParams(queryParams);
  }

  getImportUrl(conflictBehaviour: 'Ignore' | 'Overwrite', missingEntityBehaviour: 'SetNull' | 'LogError', useTransaction: boolean): string {
    const queryParams = {
      conflictBehaviour: conflictBehaviour,
      missingEntityBehaviour: missingEntityBehaviour,
      useTransaction: useTransaction
    };
    return this.urlHelper.combineUrlParts(this.exportControllerName, 'import') + this.urlHelper.combineQueryParams(queryParams);
  }

  getContextUrl(): string {
    return this.urlHelper.combineUrlParts(this.contextControllerName);
  }

  getGlobalSearchSearchUrl(searchString: string, metaClassName: string | undefined, numberOfSearchResults: number | undefined): string {
    const queryParams = {};
    queryParams['searchTerms'] = searchString;
    if (metaClassName) {
      queryParams['fixedMetaClassName'] = metaClassName;
    }
    if (numberOfSearchResults) {
      queryParams['maxResults'] = numberOfSearchResults;
    }

    return this.urlHelper.combineUrlParts(this.globalSearchControllerName, 'search') + this.urlHelper.combineQueryParams(queryParams);
  }

  getGlobalSearchUrl(route?: string): string {
    return this.urlHelper.combineUrlParts(this.globalSearchControllerName, route ?? '');
  }

  getOidcLoginUrl(): string {
    return this.urlHelper.combineUrlParts('api/v1/connect/token');
  }

  getOidcAuthorizeUrl(queryString: string): string {
    return this.urlHelper.combineUrlParts('api/v1/connect/authorize') + queryString;
  }

  getPublicApiUrl(route?: string): string {
    return this.urlHelper.combineUrlParts(this.publicApiControllerName, route ?? '');
  }

  getFileInfoUrl(id: string | null, ownerClass: string | null, ownerPrimaryKey: string | null, context: string | null): string {
    const queryParams = {};
    if (id) {
      queryParams['id'] = id;
    }
    if (ownerClass) {
      queryParams['ownerClass'] = ownerClass;
    }
    if (ownerPrimaryKey) {
      queryParams['ownerPrimaryKey'] = ownerPrimaryKey;
    }
    if (context) {
      queryParams['context'] = context;
    }

    return this.urlHelper.combineUrlParts(this.fileStorageControllerName) + this.urlHelper.combineQueryParams(queryParams);
  }

  updateFileInfoUrl(): string {
    return this.urlHelper.combineUrlParts(this.fileStorageControllerName, 'updateFileInfo');
  }

  postFileUrl(): string {
    return this.urlHelper.combineUrlParts(this.fileStorageControllerName);
  }

  downloadFileUrl(id: string): string {
    const queryParams = {};
    if (id) {
      queryParams['id'] = id;
    }

    return this.urlHelper.combineUrlParts(this.fileStorageControllerName, 'download') + this.urlHelper.combineQueryParams(queryParams);
  }

  deleteFileUrl(id: string): string {
    const queryParams = {};
    if (id) {
      queryParams['id'] = id;
    }

    return this.urlHelper.combineUrlParts(this.fileStorageControllerName) + this.urlHelper.combineQueryParams(queryParams);
  }

  getFileUploadUrl(): string {
    return this.urlHelper.combineUrlParts(this.fileStorageControllerName);
  }

  getCalendarUrl(calendarName: string, from: Date, to: Date): string {
    return this.urlHelper.combineUrlParts(this.calendarControllerName, calendarName, from.toISOString(), to.toISOString());
  }

  getThirdPartyOidcProvidersUrl(): string {
    return this.urlHelper.combineUrlParts(this.thirdPartyAuthenticationControllerName, 'providers');
  }

  getThirdPartyOidcProviderRedirect(provider: string): string {
    return this.urlHelper.combineUrlParts(this.thirdPartyAuthenticationControllerName, 'redirect', provider);
  }

  protected authenticationControllerName = 'api/v1/auth';
  protected metadataControllerName = 'api/v1/metadata';
  protected genericControllerName = 'api/v1/generic';
  protected translationControllerName = 'api/v1/translations';
  protected serverInfoControllerName = 'api/v1/serverinfo';
  private readonly userInfoControllerName = 'api/v1/userinfo';
  private readonly applicationController = 'api/v1/application';
  private readonly identityControllerName = 'api/v1/identity';
  private readonly settingsControllerName = 'api/v1/settings';
  private readonly configControllerName = 'api/v1/configuration';
  private readonly dashboardControllerName = 'api/v1/dashboard';
  private readonly exportControllerName = 'api/v1/export';
  private readonly contextControllerName = 'api/v1/context';
  private readonly globalSearchControllerName = 'api/v1/globalSearch';
  private readonly publicApiControllerName = 'api/v1/public';
  private readonly oDataControllerName = 'odata';
  private readonly fileStorageControllerName = 'api/v1/files';
  private readonly calendarControllerName = 'api/v1/calendar';
  private readonly thirdPartyAuthenticationControllerName = 'api/v1/thirdParty/auth';
}
