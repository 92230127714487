import { IOptionValue } from '../IOptionValue';
import { getAspectOrDefault, IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for retrieving a value list aspect.
 */
export const ValueListAspectIdentifier = 'ValueList';
export const SortedValueListAspectIdentifier = 'SortedValueList';

/**
 * Value list aspect containing the available options for a relation based on a fixed list.
 */
export interface IValueListAspect extends IMetaAspect {
  options: IOptionValue[];
}

/**
 * Whether the given property maps to a value list.
 * @param property - the meta property.
 */
export function isValueList(property: any): boolean {
  return property && getAspectOrDefault<IValueListAspect>(property, ValueListAspectIdentifier) != null;
}
