import { IQuinoExcelCell, IQuinoExcelRow } from '../model';
import { IExportExcelSettings } from '../settings';

export const IQuinoExcelRowBuilderSymbol = Symbol.for('IQuinoExcelRowBuilder');

export interface IQuinoExcelRowBuilder {
  /**
   * Returns an @IQuinoExcelRowBuilder with the applied default settings (@IExportExcelSettings) and cleared values
   * @param defaultSettings
   */
  clear: (defaultSettings: IExportExcelSettings) => IQuinoExcelRowBuilder;

  /**
   * Adds cells to the row
   * @param cells IQuinoExcelCell[] which should be added to the @IQuinoExcelRow
   */
  withCells: (cells: IQuinoExcelCell[]) => IQuinoExcelRowBuilder;

  /**
   * Returns an @IQuinoExcelRowBuilder with the applied setting. The given number represent one row, which will
   * @param marginTop margin of the.
   */
  withMarginTop: (marginTop: number) => IQuinoExcelRowBuilder;

  /**
   * Returns an @IQuinoExcelRowBuilder with the applied default settings (@IExportExcelSettings)
   * @param defaultSettings (@IExportExcelSettings)
   */
  withDefaultSettings: (defaultSettings: IExportExcelSettings) => IQuinoExcelRowBuilder;

  /**
   * Returns an @IQuinoExcelRow with all the applied settings and values
   */
  build: () => IQuinoExcelRow;
}
