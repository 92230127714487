import { IAuthenticationFeedback } from '@quino/core';
import ReactDOM from 'react-dom';
import { inject, injectable } from 'inversify';
import { ReactElement } from 'react';
import { IQuinoAuthenticationComponentFactory } from './IQuinoAuthenticationComponentFactory';
import { QuinoUIServiceSymbols } from '../../ioc';

@injectable()
export class QuinoAuthenticationFeedback implements IAuthenticationFeedback {
  constructor(
    @inject(QuinoUIServiceSymbols.IQuinoAuthenticationComponentFactory)
    private readonly authenticationComponentFactory: IQuinoAuthenticationComponentFactory
  ) {
    this.element = document.createElement('div');
    this.element.style.width = '0px';
    this.element.style.height = '0px';
    this.element.style.position = 'absolute';
    document.body.appendChild(this.element);

    this.loginPopup = this.authenticationComponentFactory.createComponent(() => {
      this.resolves.forEach((x) => x());
      this.resolves.splice(0, this.resolves.length);
      ReactDOM.unmountComponentAtNode(this.element);
      this.isMounted = false;
    });

    this.changePasswordPopup = this.authenticationComponentFactory.createChangePasswordComponent(() => {
      ReactDOM.unmountComponentAtNode(this.element);
      this.isMounted = false;
    });
  }

  requestLogin = (onResolve: () => void): void => {
    if (!this.isMounted) {
      ReactDOM.render(this.loginPopup, this.element);
      this.isMounted = true;
    }

    this.resolves.push(onResolve);
  };

  requestPasswordChange = (): void => {
    if (!this.isMounted) {
      ReactDOM.render(this.changePasswordPopup, this.element);
      this.isMounted = true;
    }
  };

  private readonly element: HTMLElement;
  private readonly loginPopup: ReactElement;
  private readonly changePasswordPopup: ReactElement;
  private readonly resolves: (() => void)[] = [];
  private isMounted: boolean;
}
