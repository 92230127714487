import { IConfigurationService, IConfigurationServiceSymbol } from '@quino/core';
import { inject, injectable } from 'inversify';
import { ICUISettingsConfigurationService } from './ICUISettingsConfigurationService';
import { ICUISettings, ICUISettingSymbol } from './ICUISettings';

@injectable()
export class CUISettingsConfigurationService implements ICUISettingsConfigurationService {
  constructor(
    @inject(IConfigurationServiceSymbol) private readonly configService: IConfigurationService,
    @inject(ICUISettingSymbol) private readonly defaultSettings: ICUISettings
  ) {}

  getCuiSettings(): ICUISettings {
    // Apply global remote config
    return this.configService.apply(this.defaultSettings, 'CuiConfiguration');
  }
}
