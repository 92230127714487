import { ListBookmark } from './ListBookmark';
import { IMetaClass, IMetaLayout } from '@quino/core';
import { IPrefilteredListBookmark } from './IPrefilteredListBookmark';
import DataSource from 'devextreme/data/data_source';

/**
 * A pre-filtered list bookmark
 */
export class PrefilteredListBookmark extends ListBookmark implements IPrefilteredListBookmark {
  constructor(metaClass: IMetaClass, layout: IMetaLayout, public filterExpression: any[], public dataSource: DataSource) {
    super(metaClass, layout, []);
  }
}
