/**
 * Symbol for the ILoadingFeedback service.
 */
export const ILoadingFeedbackSymbol = Symbol.for('ILoadingFeedback');

export interface ILoadingFeedbackPosition {
  /**
   * Point from which the loading panel will be aligned to the "of" container.
   * Use this option always with 'at' and 'of', e.g. my: center, at: center, of: selector
   */
  my?: 'center' | 'bottom' | 'top' | undefined;

  /**
   * Point to which the loading panel will be aligned to the "of" container.
   * Use this option always with 'my' and 'of', e.g. my: center, at: center, of: selector
   */
  at?: 'center' | 'bottom' | 'top' | undefined;

  /**
   * The selector of the container to align with.
   * Use this option always with 'my' and 'at', e.g. my: center, at: center, of: selector
   */
  of?: string | undefined;
}

/**
 * A loading feedback that blocks the screen and shows an indicator.
 */
export interface ILoadingFeedback {
  /**
   * Trigger a load. Call the returned callback once your component is done loading.
   */
  load(message?: string): () => void;

  /**
   * Trigger a load at a certain position. Call the returned callback once your component is done loading.
   */
  loadAtPosition(loadingFeedBackOptions: ILoadingFeedbackPosition, message?: string): () => void;
}
