import { QuinoCoreServiceSymbols, IAuthenticationFeedback } from '@quino/core';
import { IQuinoActionArgs, IClientAction } from '../actions';
import { inject, injectable } from 'inversify';

/**
 * The symbol for the ChangePasswordAction.
 */
export const ChangePasswordActionSymbol = Symbol.for('ChangePasswordAction');

/**
 * An action dedicated to change the current users password. Per default included in the user info object.
 */
@injectable()
export class ChangePasswordAction implements IClientAction {
  constructor(@inject(QuinoCoreServiceSymbols.IAuthenticationFeedback) private readonly authenticationFeedback: IAuthenticationFeedback) {}

  onClick(_args: IQuinoActionArgs): void {
    this.authenticationFeedback.requestPasswordChange();
  }
}
