import { useEffect, useMemo, useState } from 'react';
import { IQuinoContextMenuItem, useOnMount, useService } from '@quino/ui';
import {
  ILogger,
  IStorageService,
  ITranslationService,
  ITranslationServiceSymbol,
  LocalStorageServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';

interface IZoomLevel {
  text: string;
  zoom: number;
}

type IZoomLevelDataSource = IZoomLevel & IQuinoContextMenuItem;

const storageKey = 'zoomLevel';

export function useBrowserZoom() {
  const storageService = useService<IStorageService>(LocalStorageServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const browserZoomEnabled = (document.body.style as any).zoom !== undefined;

  const zoomLevel: IZoomLevel[] = useMemo(() => {
    return [
      { text: translationService.translate('Header.UserMenu.ZoomLevel.Mini'), zoom: 80 },
      { text: translationService.translate('Header.UserMenu.ZoomLevel.Small'), zoom: 90 },
      { text: translationService.translate('Header.UserMenu.ZoomLevel.Default'), zoom: 100 },
      { text: translationService.translate('Header.UserMenu.ZoomLevel.Large'), zoom: 110 },
      { text: translationService.translate('Header.UserMenu.ZoomLevel.Maxi'), zoom: 120 }
    ];
  }, [translationService]);

  const [currentZoomLevel, setCurrentZoomLevel] = useState<IZoomLevel>(zoomLevel[2]);

  const zoomLevelDataSource: IZoomLevelDataSource[] = useMemo(() => {
    return zoomLevel.map((item) => {
      return {
        ...item,
        isChecked: currentZoomLevel.zoom === item.zoom,
        onItemClick: () => setCurrentZoomLevel(item)
      };
    });
  }, [currentZoomLevel.zoom, zoomLevel]);

  useOnMount(() => {
    storageService
      .get<number | undefined>(storageKey)
      .then((value) => {
        if (value) {
          const level = zoomLevel.find((level) => level.zoom === value);
          if (level) {
            setCurrentZoomLevel(level);
          }
        }
      })
      .catch(logger.logError);
  });

  useEffect(() => {
    if (browserZoomEnabled) {
      storageService.set(storageKey, currentZoomLevel.zoom).catch(logger.logError);
      (document.body.style as any).zoom = `${currentZoomLevel.zoom}%`;
    }
  }, [browserZoomEnabled, currentZoomLevel, logger.logError, storageService]);

  return { browserZoomEnabled, currentZoomLevel, zoomLevelDataSource };
}
