import React, { useContext } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import { useService, IHomeNavigationService, IHomeNavigationServiceSymbol } from '@quino/ui';
import { QuinoContextContext } from '@quino/core';
import { ICUISettingsConfigurationService, ICUISettingsConfigurationServiceSymbol } from '../../configuration';

export interface ICUIContextSelectorProps {
  standalone?: boolean;
  onChanged?: (value: string) => void;
}

export function CUIContextSelector(props: ICUIContextSelectorProps) {
  const homeNavigationService = useService<IHomeNavigationService>(IHomeNavigationServiceSymbol);
  const fullReload = useService<ICUISettingsConfigurationService>(ICUISettingsConfigurationServiceSymbol).getCuiSettings().contextSelectorHardReload;
  const { currentContext, setCurrentContext, availableContexts } = useContext(QuinoContextContext);

  return (
    <SelectBox
      className={(props.standalone && 'quino-ecui-header-scope-selector') || undefined}
      showClearButton={false}
      acceptCustomValue={false}
      searchEnabled={true}
      searchMode={'contains'}
      dataSource={availableContexts.length === 0 && currentContext != null ? [currentContext] : availableContexts}
      value={currentContext != null ? currentContext.value : undefined}
      displayExpr={'caption'}
      valueExpr={'value'}
      onValueChanged={async (e) => {
        const selectedContext = availableContexts.find((x) => x.value === e.value);
        selectedContext && setCurrentContext(selectedContext);
        if (fullReload) {
          await homeNavigationService.navigateHome();
        }

        props.onChanged && props.onChanged(e.value);
      }}
    />
  );
}
