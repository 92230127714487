import React from 'react';
import { ILogger, IMetaMenuItem, ITranslationService, ITranslationServiceSymbol, QuinoCoreServiceSymbols } from '@quino/core';
import { useService } from '@quino/ui';
import { Item as ContextMenuItem } from 'devextreme/ui/context_menu';
import { ContextMenu } from 'devextreme-react/context-menu';
import { IMetaElementNavigationType, TreeViewKey } from './CUINavBarTreeView';
import { on, trigger } from 'devextreme/events';
import { useMenuItemNavigator } from './useMenuItemNavigator';

export const CUINavBarContextMenuTargetIdPrefix = 'quino-ecui-nav-bar-context-menu-target-';

export const CUINavBarContextMenu: React.FC<{
  dataSource: (ContextMenuItem & IMetaElementNavigationType)[];
  ofId: string;
  useFavorites: boolean;
}> = (props) => {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const navigate = useMenuItemNavigator();

  const noDataContextMenuItem: ContextMenuItem = {
    text: translationService.translate('NavigationBar.NoDataText'),
    disabled: true
  };

  const onContextMenuItemClick = (item: IMetaElementNavigationType) => {
    navigate(item.element as IMetaMenuItem, false).catch((e) => logger.logError(e));
  };

  return (
    <ContextMenu
      cssClass={'quino-ecui-nav-bar-context-menu'}
      width={240}
      showEvent={'dxclick dxhoverstart'}
      closeOnOutsideClick={true}
      position={{ my: 'left top', at: 'right top', offset: { x: 3, y: 0 }, of: '#' + props.ofId }}
      dataSource={props.dataSource.length > 0 ? props.dataSource : [noDataContextMenuItem]}
      itemKeyFn={(data: TreeViewKey) => data.key}
      itemRender={(params: ContextMenuItem & IMetaElementNavigationType) => {
        return (
          <div className='quino-ecui-nav-bar-context-menu-item'>
            <span className='dx-menu-item-text'>{params.text}</span>
            {props.useFavorites && <i className='material-icons-outlined star_border hide' />}
          </div>
        );
      }}
      onItemClick={(e) => onContextMenuItemClick(e.itemData as IMetaElementNavigationType)}
      onInitialized={(e) => {
        const self = document.getElementById(props.ofId);
        const targetsToClose = document.querySelectorAll('[id*=' + CUINavBarContextMenuTargetIdPrefix + ']');
        const component = e.component;
        if (self && targetsToClose && component) {
          const eventName = 'quinoCloseOnOtherTargetHoverStart';
          on(self, eventName, async () => {
            const renderedOnce = component.element().children.length > 0;
            renderedOnce && component.hide().catch(logger.logError);
          });
          on(self, 'dxhoverstart', () =>
            targetsToClose.forEach((targetToClose) => targetToClose.id !== self.id && trigger(targetToClose, eventName))
          );
        }
      }}
    />
  );
};
