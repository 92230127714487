import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for retrieving a mask format aspect.
 */
export const MaskFormatAspectIdentifier = 'mask';

/**
 * Mask aspect
 */
export interface IMaskFormatAspect extends IMetaAspect {
  maskString: string;
}
