import React, { useEffect, useState } from 'react';
import {
  IAuthenticationService,
  IErrorHandlingService,
  IErrorHandlingServiceSymbol,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  IRequestBuilder,
  ITranslationService,
  ITranslationServiceSymbol,
  IUrlManager,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { Button } from 'devextreme-react/button';
import { IOidcBookmark } from '../../bookmarks/IOidcBookmark';
import { useService } from '../../ioc';
import { TResponsiveMode, useResponsiveMode } from '../../responsivity';
import { QuinoPopup } from '../QuinoPopup';

export const OidcAuthorization = ({ bookmark }: { bookmark: IOidcBookmark }) => {
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const urlManager = useService<IUrlManager>(QuinoCoreServiceSymbols.IUrlManager);
  const requestBuilder = useService<IRequestBuilder>(QuinoCoreServiceSymbols.IRequestBuilder);
  const authenticationService = useService<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const errorHandlingService = useService<IErrorHandlingService>(IErrorHandlingServiceSymbol);
  const responsiveMode = useResponsiveMode();
  const [appInfo, setAppInfo] = useState<{ applicationName: string; scope: string }>();

  useEffect(() => {
    requestBuilder
      .create(urlManager.getOidcAuthorizeUrl(bookmark.queryString) + '&checkConsent=true', 'get')
      .requiresAuthentication()
      .fetch()
      .then(async (r) => {
        const result = await r.json();

        if (result.authOk) {
          window.location.href = authenticationService.authenticateUrl(urlManager.getOidcAuthorizeUrl(bookmark.queryString));
        } else {
          setAppInfo(result);
        }
      })
      .catch((e) => {
        errorHandlingService.notifyError(e);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={'quino-overlay'} style={{ visibility: appInfo ? 'collapse' : undefined }} />
      <QuinoPopup
        visible={!!appInfo}
        showTitle={false}
        width={responsiveMode === TResponsiveMode.Phone ? '90%' : 'auto'}
        maxWidth={responsiveMode === TResponsiveMode.Phone ? '320' : 'unset'}>
        <form
          action={authenticationService.authenticateUrl('/api/v1/connect/authorize')}
          method={'POST'}
          onSubmit={() => {
            loadingFeedback.load();
          }}>
          <input type={'hidden'} value={'true'} name={'submit.Accept'} />
          {bookmark.values && Object.entries(bookmark.values).map(([key, value]) => <input type={'hidden'} name={key} value={value} />)}
          <div style={{ display: 'flex', flexDirection: 'column', padding: 10, alignContent: 'center' }}>
            <div style={{ marginBottom: 10 }}>
              <p>
                {translationService.translate('QuinoAuthenticationFeedback.GrantAppAccessText', {
                  app: appInfo?.applicationName ?? '?'
                })}
              </p>
              <p>{translationService.translate('QuinoAuthenticationFeedback.RequestedScopesText')}</p>
              <ul>
                {appInfo?.scope.split(' ').map((v, k) => (
                  <li key={k}>{v}</li>
                ))}
              </ul>
            </div>
            <Button
              text={translationService.translate('QuinoAuthenticationFeedback.AllowAccess')}
              icon={'material-icons-outlined lock_open'}
              type='default'
              stylingMode='contained'
              useSubmitBehavior={true}
            />
          </div>
        </form>
      </QuinoPopup>
    </>
  );
};
