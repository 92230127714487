import { useEffect } from 'react';
import { useService } from '../ioc/hook';
import { IQuinoShortcut } from './IQuinoShortcutSettings';
import { IRegisteredShortcutSettings } from './ShortcutService';
import { IRegisterShortcutService, IRegisterShortcutServiceSymbol } from './IRegisterShortcutService';

export function useShortcutHandler(
  shortcuts: IQuinoShortcut[],
  action: () => void,
  enabled = true,
  configuration: IRegisteredShortcutSettings = {
    actionDisabled: false,
    keyHandlerDisabled: false
  }
): void {
  const registerShortcutService = useService<IRegisterShortcutService>(IRegisterShortcutServiceSymbol);

  useEffect(() => {
    const symbols = (enabled && registerShortcutService.registerShortcut(shortcuts, action, configuration)) || undefined;
    return () => registerShortcutService.unregisterShortcut(symbols);
  }, [shortcuts, action, configuration, registerShortcutService, enabled]);
}
