import { IMetaElement } from './IMetaElement';

export interface IMetaGroup extends IMetaElement {
  /**
   * Child elements of this node.
   */
  elements: IMetaElement[];
}

export const isIMetaGroup = (object?: IMetaElement): object is IMetaGroup => {
  return object != null && (object as IMetaGroup).elements !== undefined;
};
