import { inject, injectable } from 'inversify';
import { IResponsivityBreakpointService } from '@quino/ui';
import { ICUISettings, ICUISettingsConfigurationService, ICUISettingsConfigurationServiceSymbol } from '../../configuration';

@injectable()
export class CUIResponsivityBreakpointService implements IResponsivityBreakpointService {
  constructor(@inject(ICUISettingsConfigurationServiceSymbol) cuiConfigurationService: ICUISettingsConfigurationService) {
    this.cuiConfiguration = cuiConfigurationService.getCuiSettings();
  }

  getPhoneBreakpoint(): number {
    return this.cuiConfiguration.phoneBreakpoint;
  }

  getTabletBreakpoint(): number {
    return this.cuiConfiguration.tabletBreakpoint;
  }

  private readonly cuiConfiguration: ICUISettings;
}
