/**
 * The identifier for the ILayoutScopeAspect.
 */
import { IMetaAspect } from '../meta';
import { IExpression, isExpression } from '../expressions';

export const LayoutScopeAspectIdentifier = 'layoutScope';

/**
 * The aspect for pushing a scope on top of the layout manager.
 */
export interface ILayoutScopeAspect extends IMetaAspect {
  /**
   * The expression to apply for the given scope.
   */
  filter: IExpression;
}

export const isLayoutScopeAspect = (object: any): object is ILayoutScopeAspect => {
  return object != null && isExpression(object.filter);
};
