import React, { Attributes } from 'react';
import { ILogger, IMetaElement, ITranslationService, ITranslationServiceSymbol, QuinoCoreServiceSymbols, IMetaProperty } from '@quino/core';
import { IQuinoComponentProps, IQuinoEditorProps, IQuinoMetaPanelActions } from '../Types';
import { IComponentRegistry } from '../../rendering';
import { QuinoUIServiceSymbols } from '../../ioc';
import { inject, injectable } from 'inversify';
import { IBookmark, IObjectBookmark } from '../../bookmarks';
import { UnknownElement } from '../UnknownElement';
import { IComponentFactory, TMetadataOverrides } from './IComponentFactory';
import { ComponentErrorBoundary } from './ComponentErrorBoundary';

@injectable()
export class ComponentFactory implements IComponentFactory {
  constructor(
    @inject(QuinoUIServiceSymbols.IComponentRegistry) private readonly componentRegistry: IComponentRegistry,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService
  ) {}

  public create = (element: IMetaElement, bookmark: IBookmark, actions?: IQuinoMetaPanelActions, overrides?: TMetadataOverrides): React.ReactNode => {
    const component = this.componentRegistry.getComponent(element.controlName);
    const props: IQuinoComponentProps<IBookmark> & Attributes = {
      bookmark: bookmark,
      element: {
        ...element,
        readOnly: overrides && overrides.readOnly ? overrides.readOnly : element.readOnly,
        enabled: overrides && !overrides.enabled ? overrides.enabled : element.enabled,
        required: overrides && overrides.required ? overrides.required : element.required
      },
      actions: actions,
      key: element.name
    };
    if (component != null) {
      const createElement = React.createElement(component, {
        ...props,
        key: props.element.name + '_component_factory_create_element'
      });
      return (
        <ComponentErrorBoundary
          logger={this.logger}
          translationService={this.translationService}
          componentName={element.name}
          componentControlName={element.controlName}
          key={`ComponentErrorBoundary_${createElement.key}`}>
          {createElement}
        </ComponentErrorBoundary>
      );
    }

    return <UnknownElement key={element.name + 'unknown_element'} {...props} />;
  };

  createListEditor(
    property: IMetaProperty,
    bookmark: IObjectBookmark,
    propagateValue?: ((value: any) => void) | undefined
  ): React.ReactNode | undefined {
    const component = this.componentRegistry.getListEditor(property.controlName);

    if (component != null) {
      const props: IQuinoEditorProps = {
        bookmark: bookmark,
        metaProperty: property,
        propagateValue: propagateValue,
        hideClearButton: true,
        hideNonEditorElements: true
      };

      const reactElement = React.createElement(component, {
        ...props,
        key: property.name + '_component_factory_create_element'
      });

      return (
        <ComponentErrorBoundary
          logger={this.logger}
          translationService={this.translationService}
          componentName={property.name}
          componentControlName={property.controlName}
          key={`ComponentErrorBoundary_${reactElement.key}`}>
          {reactElement}
        </ComponentErrorBoundary>
      );
    }

    return undefined;
  }
}
