import { IMetaAspect } from '../IMetaAspect';
import { IExpression, isExpression } from '../../expressions/IExpression';

/**
 * The identifier for retrieving a layout aspect.
 */
export const LayoutAspectIdentifier = 'LayoutAspect';

/**
 * Layout aspect containing the identifier for the layout to use.
 */
export interface ILayoutAspect extends IMetaAspect {
  layout: string | IExpression;
}

export function isLayoutAspect(object: any): object is ILayoutAspect {
  return object != null && (object as ILayoutAspect).layout !== undefined && (object as ILayoutAspect).name !== undefined;
}

export const isStaticLayoutString = (layout: string | IExpression): layout is string => {
  return !isDynamicLayoutString(layout);
};

export const isDynamicLayoutString = (layout: string | IExpression): layout is IExpression => {
  return isExpression(layout);
};
