import { IDataGridSettingsService, IGridEditorFilterRememberChoiceSettings, IGridEditorModeSettings } from './IDataGridSettingsService';
import { LookupNormalizer, InMemoryStorageServiceSymbol, IStorageService } from '@quino/core';
import { inject, injectable } from 'inversify';

@injectable()
export class DataGridSettingsService implements IDataGridSettingsService {
  constructor(@inject(InMemoryStorageServiceSymbol) private readonly inMemoryStorage: IStorageService) {}

  async getDataGridEditModeSettings(key: string): Promise<IGridEditorModeSettings> {
    return this.inMemoryStorage.get(LookupNormalizer.Normalize(this.dataGridEditorModeSettings + key));
  }

  async getDataGridFilterSettings(key: string): Promise<any> {
    return this.inMemoryStorage.get(LookupNormalizer.Normalize(this.dataGridFilterSettings + key));
  }

  async getDataGridViewSettings(key: string): Promise<any> {
    return this.inMemoryStorage.get(LookupNormalizer.Normalize(this.dataGridViewSettings + key));
  }

  async getDataGridFilterRememberChoiceSettings(key: string): Promise<IGridEditorFilterRememberChoiceSettings> {
    return this.inMemoryStorage.get(LookupNormalizer.Normalize(this.dataGridFilterRememberChoiceSettings + key));
  }

  async setDataGridEditModeSettings(key: string, setting: IGridEditorModeSettings): Promise<void> {
    await this.inMemoryStorage.set<IGridEditorModeSettings>(LookupNormalizer.Normalize(this.dataGridEditorModeSettings + key), setting);
  }

  async setDataGridFilterSettings(key: string, setting: any): Promise<void> {
    await this.inMemoryStorage.set(LookupNormalizer.Normalize(this.dataGridFilterSettings + key), setting);
  }

  async clearDataGridFilterSettings(): Promise<void> {
    await this.inMemoryStorage.clear(this.dataGridFilterSettings);
  }

  async setDataGridViewSettings(key: string, setting: any): Promise<void> {
    await this.inMemoryStorage.set(LookupNormalizer.Normalize(this.dataGridViewSettings + key), setting);
  }

  async setDataGridFilterRememberChoiceSettings(key: string, setting: IGridEditorFilterRememberChoiceSettings): Promise<void> {
    await this.inMemoryStorage.set(LookupNormalizer.Normalize(this.dataGridFilterRememberChoiceSettings + key), setting);
  }

  protected dataGridEditorModeSettings = 'GridEditorModeSettings_';
  protected dataGridFilterSettings = 'DataGridFilterSettings_';
  protected dataGridFilterRememberChoiceSettings = 'DataGridFilterRememberChoiceSettings_';
  protected dataGridViewSettings = 'DataGridViewSettings_';
}
