import { IStorageService } from './IStorageSevice';
import { injectable } from 'inversify';
import { LookupNormalizer } from './LookupNormalizer';

/**
 * Symbol for the InMemoryStorageService
 */
export const InMemoryStorageServiceSymbol = Symbol.for('InMemoryStorageServiceSymbol');

@injectable()
export class InMemoryStorageService implements IStorageService {
  async get<TPayload>(key: string): Promise<TPayload> {
    return this.store[LookupNormalizer.Normalize(key)];
  }

  async set<TPayload>(key: string, value: TPayload): Promise<void> {
    this.store[LookupNormalizer.Normalize(key)] = value;
  }

  async delete(key: string): Promise<void> {
    delete this.store[LookupNormalizer.Normalize(key)];
  }

  async clear(prefix?: string): Promise<void> {
    if (prefix) {
      Object.keys(this.store).forEach((key) => {
        if (LookupNormalizer.Normalize(key).startsWith(LookupNormalizer.Normalize(prefix))) {
          void this.delete(key);
        }
      });
    } else {
      this.store = {};
    }
  }

  private store = {};
}
