import React from 'react';
import {
  IBookmark,
  IBookmarkFactory,
  INavigationService,
  INavigationServiceSymbol,
  IODataSourceFactory,
  IODataSourceFactorySymbol,
  QuinoUIServiceSymbols,
  useService
} from '@quino/ui';
import {
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetaLayout,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { useCuiSettings } from '../../util/useCuiSettings';
import { IMetaElementNavigationType } from './CUINavBarTreeView';
import { Button } from 'devextreme-react/button';
import { List } from 'devextreme-react/list';
import { Item as ListItem } from 'devextreme/ui/list';
import { ICUINavBarProps } from './CUINavBar';

export const CUINavBarListView: React.FC<ICUINavBarProps> = () => {
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const odataFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const cuiSettings = useCuiSettings();
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  const items: ListItem & IMetaElementNavigationType[] = [];

  const loadItem = (metaClass: string, layout: IMetaLayout) => {
    const unload = loadingFeedback.load();
    const source = odataFactory.create(layout, metaClass);
    bookmarkFactory
      .createList(metaClass, layout, source)
      .then(async (result: IBookmark) => navigationService.set([result]))
      .finally(unload)
      .catch((e) => logger.logError(e));
  };

  const onItemClick = (item: IMetaElementNavigationType) => {
    if (item && item.metaClass && item.layout) {
      loadItem(item.metaClass, item.layout);
    }
  };

  return (
    <div className={`quino-ecui-nav-bar-list-wrapper`}>
      <List
        className={`quino-ecui-nav-bar-list`}
        dataSource={items}
        selectionMode={'single'}
        focusStateEnabled={true}
        noDataText={translationService.translate('NavigationBar.NoDataText')}
        searchEnabled={cuiSettings.showMenuSearch}
        searchExpr={'text'}
        searchMode={'contains'}
        searchEditorOptions={{ placeholder: translationService.translate('SearchFavorites') }}
        itemComponent={CUINavBarListItemTemplate}
        onItemClick={(e) => e.itemData && onItemClick(e.itemData)}
      />
    </div>
  );
};

const CUINavBarListItemTemplate: React.FC<{ data: ListItem & IMetaElementNavigationType }> = (props) => {
  const { data } = props;

  return (
    <div className={`quino-ecui-nav-bar-list-item `}>
      <Button
        className={`quino-ecui-nav-bar-list-item-title-button`}
        key={'nav_title'}
        focusStateEnabled={false}
        hoverStateEnabled={false}
        text={data.text}
        hint={data.text}
        stylingMode={'text'}
      />
      <i className='material-icons-outlined close hide' />
    </div>
  );
};
