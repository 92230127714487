import { injectable } from 'inversify';
import { IHighlightTextService } from './IHighlightTextService';
import * as React from 'react';
import { ReactNode } from 'react';

@injectable()
export class HighlightTextService implements IHighlightTextService {
  highlight = (text: string, highlightText: string | undefined): ReactNode => {
    if (!highlightText) {
      return text;
    }

    const indexArray: number[] = [0];
    while (true) {
      const startIndex = indexArray[indexArray.length - 1] + (indexArray.length > 1 ? highlightText.length : 0);
      const index = text.toLowerCase().indexOf(highlightText.toLowerCase(), startIndex);
      indexArray.push(index);
      if (index < 0) {
        break;
      }
    }

    if (indexArray.length === 2) {
      // nothing to highlight
      return text;
    }

    return indexArray.map((value, index) => {
      if (index > 0 && value !== -1) {
        // strings with highlighting
        return (
          <React.Fragment key={index}>
            {text.substring(indexArray[index - 1] + (index > 1 ? highlightText.length : 0), value)}
            <span className={'dx-datagrid-search-text'}>{text.substring(value, value + highlightText.length)}</span>
          </React.Fragment>
        );
      } else if (value === -1) {
        // end string
        return <React.Fragment key={index}>{text.substring(indexArray[index - 1] + highlightText.length, text.length)}</React.Fragment>;
      }
      return React.Fragment;
    });
  };
}
