import { inject, injectable } from 'inversify';
import { INotificationConfigurationService } from './INotificationConfigurationService';
import { INotificationConfiguration, INotificationConfigurationSymbol } from './INotificationConfiguration';

@injectable()
export class NotificationConfigurationService implements INotificationConfigurationService {
  constructor(@inject(INotificationConfigurationSymbol) private readonly notificationConfiguration: INotificationConfiguration) {}

  async getConfiguration(): Promise<INotificationConfiguration> {
    return Promise.resolve(this.notificationConfiguration);
  }
}
