import React, { PropsWithChildren } from 'react';
import { ScrollView } from 'devextreme-react/scroll-view';

export interface IQuinoPopupDefaultContentProps {
  hasPaddingHorizontal?: boolean;
  hasNoToolbar?: boolean;
  isScrollable?: boolean;
}

export function QuinoPopupDefaultContent(props: PropsWithChildren<IQuinoPopupDefaultContentProps>) {
  const className =
    'quino-popup-default-content' + (props.hasPaddingHorizontal ? ' has-padding-horizontal' : '') + (props.hasNoToolbar ? ' has-no-toolbar' : '');

  return (
    <>
      {props.isScrollable ? (
        <ScrollView showScrollbar={'always'} useNative={false} className={className}>
          <div>{props.children}</div>
        </ScrollView>
      ) : (
        <div className={className}>{props.children}</div>
      )}
    </>
  );
}
