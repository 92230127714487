import { IComponentMapping } from './IComponentMapping';
import { IComponentMappingProvider } from './IComponentMappingProvider';
import { injectable } from 'inversify';

@injectable()
export class ComponentMappingProvider implements IComponentMappingProvider {
  getInstances = (): IComponentMapping[] => {
    return this.instances;
  };

  register = (instance: IComponentMapping): void => {
    this.instances = this.instances.filter((value) => value.controlIdentifier !== instance.controlIdentifier);
    this.instances.push(instance);
  };

  private instances: IComponentMapping[] = [];
}
