import { Container, interfaces } from 'inversify';
import getDecorators from 'inversify-inject-decorators';

const lazyInjectContainer = new Container();

/**
 * The application's container is not yet ready when the @lazyInject-annotations are processed.
 * Still, getDecorators needs to register the container already by then.
 * In order to solve this problem, lazyInjectContainer is an empty container which has to be provided with the
 * application's container as parent, as soon as it is ready.
 */
export function setupLazyInject(container: Container) {
  lazyInjectContainer.parent = container;
}

export const lazyInject = (serviceIdentifier: string | symbol | interfaces.Newable<any> | interfaces.Abstract<any>) => {
  return getDecorators(lazyInjectContainer).lazyInject(serviceIdentifier);
};
