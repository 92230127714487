import React from 'react';
import { Button } from 'devextreme-react/button';
import { usePromise, useService } from '@quino/ui';
import { IAuthenticationService, ILogger, QuinoCoreServiceSymbols } from '@quino/core';

export function CUILogoutButton() {
  const authenticationService = useService<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService);
  const [visible] = usePromise<boolean, any>(async () => authenticationService.isLoggedIn(), []);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  return (
    <Button
      icon={'material-icons-outlined power_settings_new'}
      visible={visible}
      stylingMode={'text'}
      onClick={() => {
        authenticationService
          .logoutAsync()
          .then(() => document.location.assign(document.location.origin))
          .catch((e) => logger.logError(e));
      }}
    />
  );
}
