import { IMetaLayout } from '@quino/core';

export const IDataSourceFactoryToolsSymbol = Symbol.for('IDataSourceFactoryTools');

/**
 * Service to deal with data source factories
 */
export interface IDataSourceFactoryTools {
  /**
   * Build the filter for a data source factory
   * @param searchOperation - the search operation for the filter
   * @param searchValue - the search value for the filter
   * @param layout - the meta layout for the filter
   */
  createFilter(searchOperation: string, searchValue: any, layout: IMetaLayout): any[];
}
