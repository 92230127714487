// DevEx does not properly handle promises returned by async functions.
/* eslint-disable @typescript-eslint/promise-function-async */

import CustomStore from 'devextreme/data/custom_store';
import ODataStore from 'devextreme/data/odata/store';

/**
 * Decorator for the ODataStore to cache the initial load if the filter does not change.
 */
export class QuinoODataStore extends CustomStore {
  constructor(protected readonly baseStore: ODataStore) {
    super();
  }

  byKey(key: any): Promise<any> {
    return this.baseStore.byKey(key);
  }

  clearRawDataCache() {
    this.lastLoad = null;
  }

  insert(values: any): Promise<any> {
    return this.baseStore.insert(values);
  }

  key(): any {
    return this.baseStore.key();
  }

  keyOf(obj: any): any {
    return this.baseStore.keyOf(obj);
  }

  load(): Promise<any>;
  load(options: any): Promise<any>;
  load(options?: any): Promise<any> {
    this.saveLastLoadFilter(options);
    const stringify = options != null ? JSON.stringify(options) : '';
    if (this.lastLoad != null && this.lastLoad === stringify) {
      return this.lastResult;
    }

    this.lastLoad = stringify;
    this.lastResult = this.baseStore.load(options);

    return this.lastResult;
  }

  push(changes: any[]): void {
    this.baseStore.push(changes);
  }

  remove(key: any): Promise<void> {
    return this.baseStore.remove(key);
  }

  totalCount(obj: { filter?: any; group?: any }): Promise<number> {
    return this.baseStore.totalCount(obj);
  }

  update(key: any, values: any): Promise<any> {
    return this.baseStore.update(key, values);
  }

  getLastLoadFilter(): any {
    return this.lastLoadFilter;
  }

  private saveLastLoadFilter(options: any) {
    this.lastLoadFilter = options !== null && options.filter ? options.filter : null;
  }

  private lastResult: any = null;
  private lastLoad: any = null;
  private lastLoadFilter: any = null;
}
