import DataSource from 'devextreme/data/data_source';
import { QuinoODataStore } from '@quino/core';

export interface IDataSourceHelperPosition {
  absolutePosition: number;
  isAbsoluteLast: boolean;
}

export class DataSourceHelper {
  constructor(source: DataSource) {
    this.source = source;
  }

  async init(): Promise<void> {
    await this.source.load();
  }

  async getItemPosition(keyName: string, keyValue: any, maxPagesToGoBack = 5): Promise<IDataSourceHelperPosition | undefined> {
    if (this.source.items().length > 0) {
      const newPosition = await this.findPositionRecursive(keyName, keyValue, maxPagesToGoBack);
      if (newPosition !== -1) {
        const absolutePosition = this.source.pageIndex() * this.source.pageSize() + newPosition;
        let isAbsoluteLastItem = false;
        const isLastItemOnPage = newPosition === this.source.items().length - 1;
        if (isLastItemOnPage) {
          if (this.source.items().length < this.source.pageSize()) {
            isAbsoluteLastItem = true;
          } else {
            this.source.requireTotalCount(true);
            await this.source.load();
            isAbsoluteLastItem = absolutePosition === this.source.totalCount() - 1;
            this.source.requireTotalCount(false);
          }
        }

        return {
          absolutePosition: absolutePosition,
          isAbsoluteLast: isAbsoluteLastItem
        };
      }
    }
    return undefined;
  }

  async previousItem(currentPositionAbsolute: number): Promise<any> {
    if (currentPositionAbsolute <= 1) {
      return this.firstItem();
    }
    return this.getElementAtPosition(currentPositionAbsolute - 1);
  }

  async nextItem(currentPositionAbsolute: number): Promise<any> {
    return this.getElementAtPosition(currentPositionAbsolute + 1);
  }

  async firstItem(): Promise<any> {
    if (this.source.pageIndex() > 0) {
      this.source.pageIndex(0);
      await this.source.load();
    }
    return this.source.items().length > 0 ? this.source.items()[0] : {};
  }

  async lastItem(): Promise<any> {
    let lastPosition = 0;
    if (this.source.items().length < this.source.pageSize() && this.source.items().length > 0) {
      lastPosition = this.source.items().length - 1;
    } else {
      this.clearCache();
      this.source.requireTotalCount(true);
      await this.source.load();
      const total = this.source.totalCount();
      this.source.requireTotalCount(false);

      if (total > 0) {
        const lastPage = Math.floor((total - 1) / this.source.pageSize());
        if (this.source.pageIndex() !== lastPage) {
          this.source.pageIndex(lastPage);
          await this.source.load();
        }
        lastPosition = this.source.items().length - 1;
      }
    }
    return this.source.items().length > 0 ? this.source.items()[lastPosition] : {};
  }

  async nextValidItem(currentPositionAbsolute: number, directionForward: boolean): Promise<any> {
    this.clearCache();
    this.source.requireTotalCount(true);
    await this.source.reload();
    const totalCount = this.source.totalCount();
    this.source.requireTotalCount(false);

    if (totalCount === 0) {
      return {};
    }

    if (directionForward) {
      return this.nextItem(currentPositionAbsolute);
    } else {
      return this.previousItem(currentPositionAbsolute);
    }
  }

  private readonly getElementAtPosition = async (position: number): Promise<any> => {
    const targetPage = Math.floor(position / this.source.pageSize());
    const targetPositionInPage = position % this.source.pageSize();

    if (this.source.pageIndex() !== targetPage) {
      this.clearCache();
      this.source.pageIndex(targetPage);
      await this.source.load();
    }

    const lastPositionInPage = this.source.items().length - 1;
    if (targetPositionInPage <= lastPositionInPage) {
      return this.source.items()[targetPositionInPage];
    } else if (this.source.items().length > 0) {
      return this.source.items()[lastPositionInPage];
    } else {
      return this.lastItem();
    }
  };

  private readonly findPositionRecursive = async (keyName: string, keyValue: any, maxPages: number): Promise<number> => {
    const foundIndex = this.source.items().findIndex((x) => x[keyName] === keyValue);
    if (foundIndex !== -1 || this.source.pageIndex() === 0 || maxPages === 0) {
      return foundIndex;
    } else {
      this.source.pageIndex(this.source.pageIndex() - 1);
      await this.source.load();
      return this.findPositionRecursive(keyName, keyValue, maxPages - 1);
    }
  };

  private readonly clearCache = () => {
    const sourceStore = this.source.store() as QuinoODataStore;
    if (sourceStore.clearRawDataCache) {
      sourceStore.clearRawDataCache();
    }
  };

  private readonly source: DataSource;
}
