import { IQuinoContextUrlService } from '@quino/ui';
import { inject, injectable } from 'inversify';
import { IUrlHelper, IUrlManager, QuinoCoreServiceSymbols } from '@quino/core';
import { ICUISettingsConfigurationService, ICUISettingsConfigurationServiceSymbol } from '../../configuration';

@injectable()
export class CUIContextUrlService implements IQuinoContextUrlService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(ICUISettingsConfigurationServiceSymbol)
    private readonly cuiSettingsConfigurationService: ICUISettingsConfigurationService,
    @inject(QuinoCoreServiceSymbols.IUrlHelper) private readonly urlHelper: IUrlHelper
  ) {}

  getUrl(): string | undefined {
    if (!this.cuiSettingsConfigurationService.getCuiSettings().contextSelectorVisible) {
      return undefined;
    }

    const cuiContextSelectorAPIUrl = this.cuiSettingsConfigurationService.getCuiSettings().contextSelectorAPIUrl;
    if (cuiContextSelectorAPIUrl && cuiContextSelectorAPIUrl.length > 1) {
      return this.urlHelper.combineUrlParts(cuiContextSelectorAPIUrl);
    }
    return this.urlManager.getContextUrl();
  }
}
