import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { useLabelSettings, useMetadata } from '../../settings';
import { useRerenderOnChanges } from '../Util';
import { QuinoLabeled } from '../QuinoLabeled';
import { QuinoImagePicker } from './QuinoImagePicker';
import { ObjectBookmark } from '../../bookmarks';
import { getMetaProperty } from '@quino/core';

export const QuinoImagePickerLabeled = (props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) => {
  const { element, bookmark } = props;
  const metaProperty = getMetaProperty(element);
  const { description, caption, instruction } = metaProperty;

  const labelSettings = useLabelSettings(element);
  const { visible, required } = useMetadata(metaProperty, bookmark.genericObject);

  useRerenderOnChanges(bookmark, metaProperty);

  return (
    <QuinoLabeled
      visible={visible}
      className={'quino-input-fields-max-width'}
      label={caption}
      required={required}
      description={description}
      instruction={instruction}
      labelSettings={labelSettings}>
      <QuinoImagePicker bookmark={bookmark} metaProperty={metaProperty} />
    </QuinoLabeled>
  );
};
