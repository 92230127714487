import { IAuthorizationService, IMetaClass, IMetadataTree, LayoutType, QuinoCoreServiceSymbols } from '@quino/core';
import { useService } from '../../ioc';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { SelectBox } from 'devextreme-react/select-box';

export function QuinoMetaClassSelector(props: {
  value?: string;
  onSelect: (value: IMetaClass) => void;
  hideMetaClassesWithoutListLayouts?: boolean;
}) {
  const authorizationService = useService<IAuthorizationService>(QuinoCoreServiceSymbols.IAuthorizationService);
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const source = useMemo(() => {
    const metaClasses = metadataTree.getClasses().sort((a, b) => a.name.localeCompare(b.name));
    if (props.hideMetaClassesWithoutListLayouts === true) {
      return metaClasses.filter((metaClass) => {
        return (
          authorizationService.getAuthorization(metaClass.name).canRead() &&
          metadataTree.getLayouts(metaClass.name).filter((x) => x.type === LayoutType.List).length > 0
        );
      });
    }
    return metaClasses;
  }, [authorizationService, metadataTree, props.hideMetaClassesWithoutListLayouts]);
  const [value, setValue] = useState<IMetaClass | undefined>(props.value ? metadataTree.getClass(props.value) : undefined);

  useEffect(() => {
    if (props.value !== value?.name) {
      setValue(props.value ? metadataTree.getClass(props.value) : undefined);
    }
  }, [metadataTree, props.value, value?.name]);

  return (
    <SelectBox
      deferRendering={false}
      showClearButton={true}
      value={value}
      items={source}
      displayExpr={(data: any) => data && `${data.caption} (${data.name})`}
      searchEnabled={true}
      onValueChanged={(e) => props.onSelect(e.value)}
    />
  );
}
