import { IMetaAction, IMetaActionExecutionResponse } from '../meta';
import { IGenericObject } from '../data';

/**
 * The symbol for the action executor.
 */
export const IActionExecutorSymbol = Symbol.for('IActionExecutor');

/**
 * Executes an action via the API.
 */
export interface IActionExecutor {
  /**
   * Execute the given action in the context of the given object.
   * @param action - the action to execute.
   * @param genericObject - the object to execute with.
   */
  execute: (action: IMetaAction, genericObject: IGenericObject) => Promise<IMetaActionExecutionResponse>;
}
