import { IMetaElement } from './IMetaElement';

/**
 * A meta menu entry which is represented in the sidebar for navigation.
 */
export interface IMetaMenuItem extends IMetaElement {
  /**
   * The context of the entry. If the target represents a class or actions this is the string identifying the given action/class.
   */
  context: any;

  /**
   * The target of this entry.
   */
  target: 'Class' | 'Action' | 'Custom' | 'Url' | 'Object' | 'Calendar';
}

/**
 * Whether the element represents a menu entry.
 * @param object - the element to check.
 */
export function isIMetaMenuItem(object: any): object is IMetaMenuItem {
  return object != null && (object as IMetaMenuItem).context !== undefined && (object as IMetaMenuItem).target !== undefined;
}
