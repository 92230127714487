import { IBookmark, isIBookmark } from './IBookmark';

/**
 * Bookmark that has change tracking abilities
 */
export interface ITrackableBookmark extends IBookmark {
  /**
   * Whether the bookmark has pending changes
   */
  hasChanges: () => boolean;

  /**
   * Manually set the pending changes state
   */
  setHasChanges: (hasChanges: boolean) => void;

  /**
   * Save the bookmark
   */
  save: (preventUIUpdate?: boolean) => Promise<void>;

  /**
   * Reset the bookmark
   */
  reset: (preventUIUpdate?: boolean) => void;
}

/**
 * Whether the given object is a IChangeTrackingBookmark.
 */
export const isITrackableBookmark = (object: any): object is ITrackableBookmark => {
  return isIBookmark(object) && (object as ITrackableBookmark).hasChanges !== undefined;
};
