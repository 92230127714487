import { IMetaLayout } from '@quino/core';
import { isIBookmark, IBookmark } from './IBookmark';

/**
 * A bookmark which is aware of layouts.
 */
export interface ILayoutAwareBookmark extends IBookmark {
  /**
   * The layout used with this bookmark.
   */
  layout: IMetaLayout;
}

/**
 * Whether the given object is a IListBookmark.
 */
export const isILayoutAwareBookmark = (object: any): object is ILayoutAwareBookmark => {
  return isIBookmark(object) && (object as ILayoutAwareBookmark).layout !== undefined;
};
