export const IDashboardConfigurationSymbol = Symbol.for('IDashboardConfiguration');

export interface IDashboardConfiguration {
  /**
   * Grid row height in px. Min: 120, Max: 240.
   */
  rowHeight: number;

  /**
   * Number of columns on desktop. Min: 2, Max: 6.
   */
  numberOfColumns: number;

  /**
   * Default width of new tiles in grid columns. Min: 1, Max: [total number of columns in grid].
   */
  newTileDefaultWidth: number;

  /**
   * Default height of new tiles in grid rows. Min: 1.
   */
  newTileDefaultHeight: number;

  /**
   * Whether custom dashboards are visible.
   */
  customDashboardsEnabled: boolean;

  /**
   * Whether shared dashboards are visible.
   */
  sharedDashboardsEnabled: boolean;

  /**
   * Whether custom dashboards are shown readonly.
   */
  readOnly: boolean;
}

export const defaultDashboardConfiguration: IDashboardConfiguration = {
  rowHeight: 160,
  numberOfColumns: 4,
  newTileDefaultWidth: 2,
  newTileDefaultHeight: 2,
  customDashboardsEnabled: true,
  sharedDashboardsEnabled: true,
  readOnly: false
};

export const dashboardConfigurationLimits = {
  rowHeightMin: 120,
  rowHeightMax: 240,
  numberOfColumnsMin: 2,
  numberOfColumnsMax: 6
};
