import { inject, injectable } from 'inversify';
import { IQuinoDropDownConfigurationService } from './IQuinoDropDownConfigurationService';
import { IQuinoDropDownSettings, IQuinoDropDownSettingsSymbol } from './IQuinoDropDownSettings';
import { IMetaElement } from '@quino/core';

@injectable()
export class DefaultDropDownConfigurationService implements IQuinoDropDownConfigurationService {
  constructor(@inject(IQuinoDropDownSettingsSymbol) private readonly dropDownSettings: IQuinoDropDownSettings) {}

  async getDropDownSettings(_element: IMetaElement): Promise<IQuinoDropDownSettings> {
    return this.dropDownSettings;
  }
}
