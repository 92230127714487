import { IExpression } from './IExpression';

export interface IMetaRelationExpressionExpression extends IExpression {
  ClassName: string;
  RelationName: string;
}

export const isMetaRelationExpression = (object: IExpression): object is IMetaRelationExpressionExpression => {
  return (
    (object as IMetaRelationExpressionExpression).ClassName !== undefined && (object as IMetaRelationExpressionExpression).RelationName !== undefined
  );
};
