import { IGenericObject } from './IGenericObject';

export const IGenericObjectFactorySymbol = Symbol.for('IGenericObjectFactory');

/**
 * Extend the generic object to match the expected interface.
 */
export interface IGenericObjectFactory {
  /**
   * Alter the passed in object to match the generic object.
   * @param obj - the obj to alter.
   * @param metaClass - the meta class.
   */
  create(obj: any, metaClass: string): IGenericObject;
}
