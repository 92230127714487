import React from 'react';
import { useCuiSettings } from '../../util';

export function CUIStagingEnvironment() {
  const cuiSettings = useCuiSettings();

  const isColor = (stageColor: string) => {
    const s = new Option().style;
    s.color = stageColor;
    return s.color !== '';
  };

  const getColor = (): string => {
    return isColor(cuiSettings.stageColor) ? cuiSettings.stageColor : 'var(--base-warning)';
  };

  return (
    <>
      {cuiSettings.stageName && cuiSettings.stageName !== '' && (
        <div className='quino-environment-indicator' style={{ background: getColor() }}>
          <div className='quino-environment-indicator-text' style={{ background: getColor() }}>
            {cuiSettings.stageName}
          </div>
        </div>
      )}
    </>
  );
}
