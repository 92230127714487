export const NewGenericObjectPrimaryKey = 'null';

export interface IGenericObject {
  [propName: string]: any;

  /**
   * The calculated primary key which can be used against the API.
   */
  primaryKey?: string;

  /**
   * The meta class that backs this object.
   */
  metaClass: string;

  /**
   * The title that was constructed out of the title layout.
   */
  title: string;
}

/**
 * Whether the given object is a generic object.
 * @param object - the object to check.
 */
export function isGenericObject(object: any): object is IGenericObject {
  return object != null && (object as IGenericObject).metaClass !== undefined && (object as IGenericObject).title !== undefined;
}
