import { IBookmarkSerializer } from './IBookmarkSerializer';
import { IProvider } from '@quino/core';
import { IBookmark } from '../bookmarks';

/**
 * The symbol for the bookmark serializer provider.
 */
export const IBookmarkSerializerProviderSymbol = Symbol.for('IBookmarkSerializerProvider');

/**
 * Provider to retrieve available bookmark serializers.
 */
export interface IBookmarkSerializerProvider extends IProvider<IBookmarkSerializer<IBookmark>> {}
