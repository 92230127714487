import { IIdentityService } from './IIdentityService';
import { injectable } from 'inversify';
import { AuthenticationState } from './AuthenticationState';
import { IAuthenticationData } from './IAuthenticationData';
import { IAuthenticationResult } from './IAuthenticationResult';

@injectable()
export class NullIdentityService implements IIdentityService {
  registerAsync = async (_data: IAuthenticationData): Promise<IAuthenticationResult> => {
    return Promise.resolve({ state: AuthenticationState.Failed, error: 'No identity service registered' });
  };
}
