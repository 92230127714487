import { INotification } from '../notification';
import { IErrorMapper } from './mappers/IErrorMapper';

export const IErrorHandlingServiceSymbol = Symbol.for('IErrorHandlingService');

export enum ErrorType {
  Client,
  Server
}

export interface IErrorHandlingService {
  /**
   * Register a new error mapper
   * @param instance the mapper to register
   */
  register(instance: IErrorMapper): void;

  /**
   * Fetch the name of an error
   * @param error
   * @param errorType the type of the error
   */
  getErrorName(error: Error, errorType?: ErrorType): string;

  /**
   * Fetch the message of an error
   * @param error
   * @param errorType the type of the error
   */
  getErrorMessage(error: Error, errorType?: ErrorType): string;

  /**
   * Fetch a notification for an error
   * @param error the error to get a notification for
   * @param symbol the symbol to use for the notification
   * @param errorType the type of the error
   */
  getErrorNotification(error: Error, symbol?: Symbol, errorType?: ErrorType): INotification;

  /**
   * Display a nicely formatted error message for specified error
   * @param error the error to show a notification for
   * @param area the area to show the notification in
   * @param symbol the symbol to use for the notification
   * @param errorType the type of the error
   */
  notifyError(error: Error, area?: 'global' | 'default' | 'custom', symbol?: Symbol, errorType?: ErrorType): void;
}
