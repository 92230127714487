import { IQuinoExcelRow } from './IQuinoExcelRow';
import { IQuinoExcelCell } from './IQuinoExcelCell';

export class QuinoExcelRow implements IQuinoExcelRow {
  constructor(private readonly cells: IQuinoExcelCell[], private readonly cellMarginTop: number) {}

  getCells(): IQuinoExcelCell[] {
    return this.cells;
  }

  getTopMargin(): number {
    return this.cellMarginTop;
  }
}
