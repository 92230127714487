import { httpMethods, IRequestBuilder } from './IRequestBuilder';
import { inject, injectable, multiInject } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IRequestDecoratorProvider } from './IRequestDecoratorProvider';
import { IRequestFactory } from './IRequestFactory';
import { IPreparedRequest } from './IPreparedRequest';
import { IAuthenticationFeedback, IAuthenticationService } from '../authentication';
import { IResponseHandler } from './IResponseHandler';
import { PreparedRequest } from './PreparedRequest';
import { IQuinoRequestOptions } from './IQuinoRequestOptions';
import { INotificationService, INotificationServiceSymbol } from '../notification';
import { IUrlManager } from '../api';

@injectable()
export class RequestBuilder implements IRequestBuilder {
  constructor(
    @inject(QuinoCoreServiceSymbols.IRequestDecoratorProvider) private readonly decoratorProvider: IRequestDecoratorProvider,
    @inject(QuinoCoreServiceSymbols.IRequestFactory) private readonly requestFactory: IRequestFactory,
    @inject(QuinoCoreServiceSymbols.IAuthenticationFeedback) private readonly authenticationFeedback: IAuthenticationFeedback,
    @inject(QuinoCoreServiceSymbols.IAuthenticationService) private readonly authenticationService: IAuthenticationService,
    @multiInject(QuinoCoreServiceSymbols.IResponseHandler) private readonly responseHandlers: IResponseHandler[],
    @inject(INotificationServiceSymbol) private readonly notificationService: INotificationService,
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager
  ) {}

  create = (url: string, method: httpMethods, options?: IQuinoRequestOptions, requestOptions?: any): IPreparedRequest => {
    const request = this.requestFactory.create(url, { ...(requestOptions ? requestOptions : {}), method: method.toUpperCase() });
    this.decoratorProvider.getInstances().forEach((decorator) => decorator.decorate(request, options));

    return new PreparedRequest(
      this.responseHandlers,
      this.authenticationService,
      this.authenticationFeedback,
      this.notificationService,
      this.urlManager,
      this,
      request
    );
  };
}
