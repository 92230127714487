import { IQuinoContextUrlService } from './IQuinoContextUrlService';
import { inject, injectable } from 'inversify';
import { IUrlManager, QuinoCoreServiceSymbols } from '@quino/core';

@injectable()
export class QuinoContextUrlService implements IQuinoContextUrlService {
  constructor(@inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager) {}

  getUrl(): string | undefined {
    return this.urlManager.getContextUrl();
  }
}
