import React, { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import { DataType, IMetaAspect, isTypeNumber, parseTimeSpan } from '@quino/core';
import { INumberBoxOptions, NumberBox } from 'devextreme-react/number-box';
import { TextArea } from 'devextreme-react/text-area';
import { ITextBoxOptions, TextBox } from 'devextreme-react/text-box';
import { IQuinoEditorProps } from '../Types';
import { useMetaPropertyOriginalValue, useMetaPropertyValueState, useValidation } from '../Util';
import { CONTROL_IDENTIFIER } from '../../rendering';
import { useLabelSettings, useMetadata } from '../../settings';
import { useFormatString } from '../../settings/useFormatString';
import { useMaskFormat } from '../../settings/useMaskFormat';
import { QuinoPasswordBox } from './QuinoPasswordBox';
import { useMaximumLengthAspect } from '../../settings/useMaximumLengthAspect';
import { isCtrlOrCmdEvent } from '../../shortcuts';
import { TResponsiveMode, useResponsiveMode } from '../../responsivity';
import dxTextBox, {
  ValueChangedEvent as TextBoxValueChangedEvent,
  InputEvent as TextBoxInputEvent,
  KeyDownEvent as TextBoxKeyDownEvent
} from 'devextreme/ui/text_box';
import {
  KeyDownEvent as NumberBoxKeyDownEvent,
  InputEvent as NumberBoxInputEvent,
  ValueChangedEvent as NumberBoxChangedEvent
} from 'devextreme/ui/number_box';
import { ScrollView } from 'devextreme-react/scroll-view';
import dxScrollView from 'devextreme/ui/scroll_view';
import { isIObjectBookmark } from '../../bookmarks';

export const TextAreaAspectIdentifier = 'TextArea';

export interface ITextAreaHeightAspect extends IMetaAspect {
  height: number;
}

export function shouldNullNumberValue(text: string, selectedText: string, cursorPosition: number | null, keyPressed: string): boolean {
  return (
    (text.length === 1 && ((keyPressed === 'Backspace' && cursorPosition === 1) || (keyPressed === 'Delete' && cursorPosition === 0))) ||
    (text === selectedText && (keyPressed === 'Backspace' || keyPressed === 'Delete'))
  );
}

export function QuinoTextBox(props: PropsWithChildren<IQuinoEditorProps>) {
  const { metaProperty, bookmark, propagateValue, hideClearButton, children } = props;
  const { description, dataType, controlName } = metaProperty;
  const { readOnly, enabled } = useMetadata(metaProperty, bookmark.genericObject);
  const formatString = useFormatString(metaProperty);
  const maskFormat = useMaskFormat(metaProperty);
  const maximumSize = useMaximumLengthAspect(metaProperty);
  const labelSettings = useLabelSettings(metaProperty);
  const originalValue = useMetaPropertyOriginalValue<any>(metaProperty, bookmark);

  const [isValid] = useValidation(bookmark, metaProperty);
  const [bookmarkValue, setBookmarkValue] = useMetaPropertyValueState<any>(metaProperty, bookmark);

  const [isFocused, setFocused] = useState<boolean>(false);
  const [scrollView, setScrollView] = useState<dxScrollView>();

  const valueStack = useRef<string[]>([]);
  const shouldPushValueToStack = useRef<boolean>(true);
  const responsiveMode = useResponsiveMode();

  const setValue = useCallback(
    (value: any) => {
      setBookmarkValue(value);
      propagateValue && propagateValue(value);
    },
    [propagateValue, setBookmarkValue]
  );

  function shouldUndo(event?: any, component?: dxTextBox) {
    if (valueStack.current && event && component && isCtrlOrCmdEvent(event) && event.key.toLowerCase() === 'z') {
      event.preventDefault();
      let poppedValue = bookmarkValue;
      if (valueStack.current.length > 0) {
        poppedValue = valueStack.current.pop();
        shouldPushValueToStack.current = false; // prevent pushing the value to the stack again
      }
      component.option('value', poppedValue);
    }
  }

  const getInputElement = (boxElement: HTMLElement): HTMLInputElement | undefined => {
    const inputs: HTMLInputElement[] = Array.from(boxElement.getElementsByTagName('input'));
    return inputs.find((value) => value.className === 'dx-texteditor-input');
  };

  const isInteger = (dataType: DataType): boolean => {
    return (
      dataType === DataType.Integer || dataType === DataType.LargeInteger || dataType === DataType.SmallInteger || dataType === DataType.TinyInteger
    );
  };

  const maxInputLength: number | undefined = useMemo(() => {
    switch (dataType) {
      case DataType.TinyInteger:
        return 3;
      case DataType.SmallInteger:
        return 5;
      case DataType.Integer:
        return 10;
      case DataType.LargeInteger:
        return 19;
      default:
        return undefined;
    }
  }, [dataType]);

  const isNumeric = isTypeNumber(dataType);
  const commonProps: Partial<PropsWithChildren<ITextBoxOptions & INumberBoxOptions>> = {
    children: children,
    inputAttr: { autocomplete: 'dont-autofill-this' },
    readOnly: readOnly != null && readOnly,
    focusStateEnabled: !readOnly,
    hoverStateEnabled: !readOnly,
    value: bookmarkValue,
    showClearButton: !hideClearButton,
    isValid: isValid,
    hint: labelSettings.hintType === 'None' ? description : undefined,
    disabled: !enabled,
    format: formatString,

    onValueChanged: (e: TextBoxValueChangedEvent & NumberBoxChangedEvent) => {
      if (e.previousValue !== null && shouldPushValueToStack.current) {
        valueStack.current.push(e.previousValue);
      } else if (!shouldPushValueToStack.current) {
        shouldPushValueToStack.current = true;
      }

      let newValue = e.value;
      if (originalValue == null && newValue === '') {
        newValue = originalValue;
      }

      setValue(newValue);

      if (scrollView) {
        void scrollView.update();
      }

      if (maskFormat) {
        e.component.option('isValid', true);
      }
    },
    onInput: (e: TextBoxInputEvent & NumberBoxInputEvent) => {
      if (isInteger(dataType)) {
        const inputElement = getInputElement(e.element);
        if (inputElement) {
          const originalValue = inputElement.value;
          let inputValueDigits: string = originalValue.replace(/\D/g, '');
          if (maxInputLength && inputValueDigits.length > maxInputLength) {
            inputValueDigits = inputValueDigits.slice(0, maxInputLength);
          }
          inputValueDigits = (originalValue.includes('-') ? '-' : '') + inputValueDigits;
          if (originalValue.length !== inputValueDigits.length) {
            inputElement.value = inputValueDigits;
          }
        }
      }
    },
    onKeyDown: (e: TextBoxKeyDownEvent & NumberBoxKeyDownEvent) => {
      // Workaround to fix Issue 5940: Controls: Delete nullable number fields (https://dev.azure.com/encodo/Encodo-Common-UI/_workitems/edit/5940)
      const { event, component, element } = e;
      shouldUndo(event, component);
      if (!isNumeric || !event || !component || !element || readOnly) {
        return;
      }

      const dxInput = getInputElement(element);
      if (!dxInput) {
        return;
      }

      const key = (event as any).key;
      const text = component.option('text')?.toString();
      if (text) {
        const selectionStart = dxInput.selectionStart;
        const selectionEnd = dxInput.selectionEnd;
        const selectedText = text ? text.substring(selectionStart ? selectionStart : 0, selectionEnd ? selectionEnd : 0) : '';

        if (shouldNullNumberValue(text, selectedText, selectionStart, key)) {
          component.option('value', undefined);
        }
      }
    },
    valueChangeEvent: 'keyup blur',
    step: 0
  };

  let result: JSX.Element;

  if (controlName === CONTROL_IDENTIFIER.TIMESPAN) {
    // Temporary, until design for time span control is defined #9537
    result = <TextBox {...commonProps} readOnly={true} focusStateEnabled={false} hoverStateEnabled={false} value={parseTimeSpan(bookmarkValue)} />;
  } else if (isNumeric) {
    let min: number | undefined = undefined;
    let max: number | undefined = undefined;
    switch (dataType) {
      case DataType.TinyInteger:
        min = 0;
        max = 255;
        break;
      case DataType.SmallInteger:
        min = -32768;
        max = 32767;
        break;
      case DataType.Integer:
        min = -2147483648;
        max = 2147483647;
        break;
      case DataType.LargeInteger:
        min = Number.MIN_SAFE_INTEGER;
        max = Number.MAX_SAFE_INTEGER;
        break;
    }
    result = (
      <NumberBox min={min} max={max} {...commonProps} inputAttr={responsiveMode === TResponsiveMode.Phone ? { inputmode: 'text' } : undefined} />
    );
  } else {
    const textBoxProps = { ...commonProps, maxLength: maximumSize, mask: maskFormat };
    switch (controlName) {
      case CONTROL_IDENTIFIER.MULTIPURPOSETEXTEDITOR:
      case CONTROL_IDENTIFIER.MULTILINETEXTEDITOR:
        result = (
          <ScrollView
            className={
              'quino-textarea-scroll-view dx-editor-outlined dx-texteditor dx-textbox dx-widget' +
              (commonProps.readOnly ? ' dx-state-readonly' : '') +
              (commonProps.disabled ? ' dx-state-disabled' : '') +
              (commonProps.isValid ? '' : ' quino-textarea-scroll-view-invalid') +
              (isFocused ? ' dx-state-focused' : '')
            }
            onInitialized={(e) => {
              setScrollView(e.component);
            }}>
            <TextArea
              {...textBoxProps}
              isValid={true}
              autoResizeEnabled={true}
              minHeight={74}
              onFocusIn={() => setFocused(true)}
              onFocusOut={() => setFocused(false)}
            />
          </ScrollView>
        );
        break;
      case CONTROL_IDENTIFIER.PASSWORDEDITOR:
        result = (
          <QuinoPasswordBox
            genericObject={isIObjectBookmark(bookmark) ? bookmark.genericObject : undefined}
            metaProperty={metaProperty}
            {...textBoxProps}
            inputAttr={{ autocomplete: 'new-password' }}
          />
        );
        break;
      default:
        if (metaProperty.dataType === DataType.Guid) {
          result = (
            <TextBox
              {...textBoxProps}
              mask={textBoxProps.mask || 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA'}
              value={(textBoxProps.value as any)?.toString()}
              useMaskedValue={true}
            />
          );
        } else result = <TextBox {...textBoxProps} />;
    }
  }

  return result;
}
