import { IODataBatchRequest, IODataBatchRequestFactory, IODataBatchResponse } from './IODataBatchRequestFactory';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IUrlManager } from './IUrlManager';
import { IRequestBuilder } from '../../request';

@injectable()
export class ODataBatchRequestFactory implements IODataBatchRequestFactory {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder
  ) {}

  async fetch(requests: IODataBatchRequest[]): Promise<IODataBatchResponse[]> {
    const response = await this.requestBuilder
      .create(this.urlManager.getODataBatchUrl(), 'post')
      .setJsonPayload({ requests })
      .requiresAuthentication()
      .fetch('application/json');

    if (response.ok) {
      return (await response.json()).responses as IODataBatchResponse[];
    }

    return Promise.reject(await response.text());
  }
}
