import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { injectable } from 'inversify';
import { IDashboardTileDataSource, IDashboardTileRegistration } from '../registry';
import { useService } from '../../ioc';
import {
  QuinoCustomFormBox,
  QuinoLabeled,
  QuinoPopupDefaultContent,
  QuinoPopupToolbar,
  QuinoPopupToolbarButton,
  searchForErrors,
  validUrl
} from '../../components';
import { TextBox } from 'devextreme-react/text-box';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';

export interface IQuinoDashboardWebPageTileProps {
  caption: string;
  url: string;
}

export const WebPageTileRegistrationSymbol = Symbol.for('WebPageTileRegistration');

@injectable()
export class QuinoDashboardWebPageTileRegistration implements IDashboardTileRegistration<IQuinoDashboardWebPageTileProps> {
  getCaption(payload: IQuinoDashboardWebPageTileProps): string {
    return payload.caption;
  }

  render(payload: IQuinoDashboardWebPageTileProps, dataSource: IQuinoDashboardWebPageTileProps): React.ReactNode {
    return <QuinoDashboardWebPageTile gridProps={payload} source={dataSource} />;
  }

  renderSettings(
    payload: IQuinoDashboardWebPageTileProps,
    apply: (payload: IQuinoDashboardWebPageTileProps) => void,
    cancel: () => void
  ): React.ReactNode {
    return <QuinoDashboardWebPageTileConfiguration gridProps={payload} apply={apply} cancel={cancel} />;
  }

  deserializePayload(payload: any): IQuinoDashboardWebPageTileProps {
    return payload as IQuinoDashboardWebPageTileProps;
  }

  serializePayload(payload: IQuinoDashboardWebPageTileProps): any {
    return payload;
  }

  showRefresh = true;
  name = 'WebPage';
  dataSource: IDashboardTileDataSource<IQuinoDashboardWebPageTileProps> = new QuinoDashboardWebPageTileDataSource();
}

class QuinoDashboardWebPageTileDataSource implements IDashboardTileDataSource<IQuinoDashboardWebPageTileProps> {
  refreshData(): void {
    const iframes = document.querySelectorAll('.dashboard-web-page-iframe');
    Array.from(iframes).forEach((element: HTMLIFrameElement) => {
      if (element) {
        // eslint-disable-next-line no-self-assign
        element.src = element.src;
      }
    });
  }

  // We use the faked dataSource here to keep the dataSource in the interface
  async updateData(payload: IQuinoDashboardWebPageTileProps): Promise<IQuinoDashboardWebPageTileProps> {
    return payload;
  }
}

function QuinoDashboardWebPageTile(props: { gridProps: IQuinoDashboardWebPageTileProps; source: IQuinoDashboardWebPageTileProps }) {
  return (
    <div className={'quino-dashboard-tile-web-page'}>
      {props.source.url && <iframe title={props.gridProps.caption} className='dashboard-web-page-iframe' src={props.gridProps.url} />}
    </div>
  );
}

function QuinoDashboardWebPageTileConfiguration(props: {
  gridProps: IQuinoDashboardWebPageTileProps;
  apply: (payload: IQuinoDashboardWebPageTileProps) => void;
  cancel: () => void;
}) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const [gridProperties, setGridProperties] = useState<IQuinoDashboardWebPageTileProps>(props.gridProps);

  const urlTextBox = useRef<TextBox>(null);
  const [urlErrors, setUrlErrors] = useState<string[]>([]);

  const getCurrentUrl = (): string => urlTextBox.current?.instance?.option('text') || '';

  const urlValidityFunction = useCallback(() => {
    return { isValid: validUrl().test(getCurrentUrl()), errorMessage: translationService.translate('Validations.UrlNotCorrect') };
  }, [translationService]);

  const basicSettings = (): ReactNode => (
    <div>
      <QuinoCustomFormBox>
        <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.TileTitle')} required={true}>
          <TextBox
            value={gridProperties.caption}
            onValueChanged={(value) => {
              setGridProperties({ ...gridProperties, caption: value.value });
            }}
          />
        </QuinoLabeled>
        <QuinoLabeled
          description={translationService.translate('Dashboard.Tile.EditDialog.UrlToWebPageDescription')}
          label={translationService.translate('Dashboard.Tile.EditDialog.UrlToWebPage')}
          required={true}
          errorMessages={urlErrors}>
          <TextBox
            ref={urlTextBox}
            value={gridProperties.url}
            onValueChanged={(value) => {
              setGridProperties({ ...gridProperties, url: value.value });
            }}
            isValid={urlErrors.length === 0}
          />
        </QuinoLabeled>
      </QuinoCustomFormBox>
    </div>
  );

  useEffect(() => {
    setGridProperties({ ...props.gridProps });
  }, [props.gridProps]);

  return (
    <>
      <QuinoPopupDefaultContent hasPaddingHorizontal={true} isScrollable={false}>
        {basicSettings()}
      </QuinoPopupDefaultContent>
      <QuinoPopupToolbar
        showTopBorder={true}
        rightItems={[
          <QuinoPopupToolbarButton
            text={translationService.translate('Cancel')}
            icon={'material-icons-outlined clear'}
            onClick={() => {
              props.cancel();
              setGridProperties(props.gridProps);
            }}
          />,
          <QuinoPopupToolbarButton
            text={translationService.translate('Apply')}
            icon={'material-icons-outlined check'}
            isPrimary={true}
            onClick={() => {
              let errorCount = 0;
              errorCount += searchForErrors(getCurrentUrl(), setUrlErrors, [urlValidityFunction]);
              if (errorCount > 0) {
                return;
              } else {
                props.apply(gridProperties);
              }
            }}
            disabled={!gridProperties.url || !gridProperties.caption}
          />
        ]}
      />
    </>
  );
}
