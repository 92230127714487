import React, { useCallback, useMemo, useState } from 'react';
import {
  IExportButtonFactory,
  IExportButtonFactorySymbol,
  IQuinoContextMenuItem,
  IQuinoShortcut,
  IQuinoShortcutSettings,
  IQuinoShortcutSettingsSymbol,
  QuinoContextMenuButton,
  TResponsiveMode,
  usePromise,
  useResponsiveMode,
  useService
} from '@quino/ui';
import {
  IGlobalSearchService,
  IGlobalSearchServiceSymbol,
  ILanguageService,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  INotificationService,
  INotificationServiceSymbol,
  IRequestBuilder,
  ITranslationService,
  ITranslationServiceSymbol,
  IUrlManager,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { useCuiSettings } from '../../util';
import { CUIShortcutItem } from './CUIShortcutItem';

interface IQuinoHelpMenuItem extends IQuinoContextMenuItem {
  shortcut: IQuinoShortcut;
}

interface ICUIMobileContextMenuProps {
  adminMenu?: {
    visible?: boolean;
    restart?: boolean;
    showCaptions?: boolean;
    showSearchTools?: boolean;
    showXMLImport?: boolean;
    additionalDataSource?: IQuinoContextMenuItem[];
  };
  helpInformation?: { visible?: boolean; keyboardShortcuts?: boolean; additionalDataSource?: IQuinoContextMenuItem[] };
}

export const CUIHeaderContextButtons = (props: ICUIMobileContextMenuProps) => {
  const requestBuilder = useService<IRequestBuilder>(QuinoCoreServiceSymbols.IRequestBuilder);
  const languageService = useService<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);
  const urlManager = useService<IUrlManager>(QuinoCoreServiceSymbols.IUrlManager);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const [captionsTitleKey, setCaptionsTitleKey] = useState('');
  const adminMenuAdditionalDataSource = props.adminMenu?.additionalDataSource ?? [];
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const globalSearchService = useService<IGlobalSearchService>(IGlobalSearchServiceSymbol);
  const { notify } = useService<INotificationService>(INotificationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const exportButtonFactory = useService<IExportButtonFactory>(IExportButtonFactorySymbol);

  // Help Information
  const helpInformationAdditionalDataSource = props.helpInformation?.additionalDataSource ?? [];

  const settings = useCuiSettings();
  const responsiveMode = useResponsiveMode();

  const shortcutItemRenderer = useCallback((e: IQuinoHelpMenuItem) => {
    return <CUIShortcutItem shortcut={e.shortcut} />;
  }, []);

  const openUserGuide = useCallback(() => {
    window.open(settings.userGuideUrl);
  }, [settings]);

  const keyboardShortcuts = useMemo(
    () =>
      Object.entries(shortcutSettings).map((entry) => {
        return { shortcut: { ...entry[1] }, disabled: true, itemRenderer: shortcutItemRenderer };
      }),
    [shortcutSettings, shortcutItemRenderer]
  );

  const helpInformationDataSource = [
    {
      text: translationService.translate('Header.HelpInformation.KeyboardShortcuts'),
      icon: 'material-icons-outlined keyboard',
      items: keyboardShortcuts,
      visible: props.helpInformation?.keyboardShortcuts ?? true
    },
    {
      text: translationService.translate('Header.HelpInformation.UserGuide'),
      icon: 'material-icons-outlined help_outline',
      onItemClick: openUserGuide,
      visible: settings.hasUserGuide
    },
    ...helpInformationAdditionalDataSource
  ];

  // Admin Menu

  usePromise(async () => {
    const lang = await languageService.getCurrentLanguageAsync();
    lang === 'la' ? setCaptionsTitleKey('Header.AdminMenu.HideCaptions') : setCaptionsTitleKey('Header.AdminMenu.ShowCaptions');
  });

  const onRestartClick = useCallback(async () => {
    await requestBuilder
      .create(urlManager.getServerRestartUrl() + '/1', 'get')
      .requiresAuthentication()
      .fetch();
  }, [urlManager, requestBuilder]);

  const onCaptionsClick = useCallback(() => {
    languageService.toggleTranslationMode().catch((e) => logger.logError(e));
  }, [languageService, logger]);

  const onGlobalSearchCreateIndex = useCallback(() => {
    const unload = loadingFeedback.load(translationService.translate('GlobalSearch.Tools.Recreating'));
    globalSearchService
      .recreateIndex()
      .then(() =>
        notify({ type: 'success', area: 'global', message: translationService.translate('GlobalSearch.Tools.ReCreateSuccess'), autoDisappear: true })
      )
      .catch(logger.logError)
      .finally(() => unload());
  }, [globalSearchService, loadingFeedback, logger.logError, notify, translationService]);

  const onGlobalSearchUpdateIndex = useCallback(() => {
    const unload = loadingFeedback.load(translationService.translate('GlobalSearch.Tools.Updating'));
    globalSearchService
      .updateIndex()
      .then(() =>
        notify({ type: 'success', area: 'global', message: translationService.translate('GlobalSearch.Tools.UpdateSuccess'), autoDisappear: true })
      )
      .catch(logger.logError)
      .finally(() => unload());
  }, [globalSearchService, loadingFeedback, logger.logError, notify, translationService]);

  const adminMenuDataSource = [
    {
      text: translationService.translate('Header.AdminMenu.Restart'),
      icon: 'material-icons-outlined refresh',
      onItemClick: onRestartClick,
      visible: props.adminMenu?.restart ?? true
    },
    {
      text: translationService.translate(captionsTitleKey),
      icon: 'material-icons-outlined g_translate',
      onItemClick: onCaptionsClick,
      visible: props.adminMenu?.showCaptions ?? true
    },
    {
      text: translationService.translate('GlobalSearch.Tools'),
      icon: 'material-icons-outlined manage_search',
      visible: settings.showGlobalSearch && (props.adminMenu?.showSearchTools ?? true),
      items: [
        {
          text: translationService.translate('GlobalSearch.Tools.ReCreateIndex'),
          icon: 'material-icons-outlined list_alt',
          onItemClick: onGlobalSearchCreateIndex
        },
        {
          text: translationService.translate('GlobalSearch.Tools.UpdateIndex'),
          icon: 'material-icons-outlined update',
          onItemClick: onGlobalSearchUpdateIndex
        }
      ]
    },
    exportButtonFactory.createXmlImportItem(),
    ...adminMenuAdditionalDataSource
  ];

  if (responsiveMode === TResponsiveMode.Phone) {
    return (
      <QuinoContextMenuButton
        buttonId={'cui_mobile_context_menu'}
        buttonIcon={'material-icons-outlined more_vert'}
        position={{ at: 'right bottom', my: 'right top' }}
        submenuDirection={'left'}
        dataSource={[
          {
            text: translationService.translate('Header.HelpInformation.Description'),
            icon: 'material-icons-outlined help_outline',
            items: helpInformationDataSource,
            visible: props.helpInformation?.visible
          },
          {
            text: translationService.translate('Header.AdminMenu.Description'),
            icon: 'material-icons-outlined build',
            items: adminMenuDataSource,
            visible: props.adminMenu?.visible
          }
        ]}
      />
    );
  }

  return (
    <>
      <QuinoContextMenuButton
        position={{ at: 'right bottom', my: 'right top' }}
        submenuDirection={'left'}
        dataSource={helpInformationDataSource}
        buttonIcon={'material-icons-outlined help_outline'}
        buttonHintKey={'Header.HelpInformation.Description'}
        buttonId={'cui_help_information_button'}
        shortcut={shortcutSettings.helpMenu}
        visible={props.helpInformation?.visible}
      />
      <QuinoContextMenuButton
        position={{ at: 'right bottom', my: 'right top' }}
        submenuDirection={'left'}
        dataSource={adminMenuDataSource}
        buttonIcon={'material-icons-outlined build'}
        buttonHintKey={'Header.AdminMenu.Description'}
        buttonId={'cui_admin_menu_button'}
        visible={props.adminMenu?.visible}
      />
    </>
  );
};
