import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { IQuinoMetaPanelOptionalProps, IQuinoMetaPanelProps } from '../QuinoMetaPanel';
import { isICustomBookmark, isIListBookmark, isIObjectBookmark } from '../../bookmarks';
import { IQuinoDataGridProps, QuinoMetaDataGrid } from '../QuinoDataGrid';
import { QuinoMetaPropertyPanel } from '../QuinoMetaPropertyPanel';

export function QuinoViewSwitcher(props: PropsWithChildren<IQuinoMetaPanelProps & IQuinoMetaPanelOptionalProps>) {
  const customBookmark = useMemo(() => {
    if (isICustomBookmark(props.bookmark)) {
      return props.bookmark.render();
    }

    return null;
  }, [props.bookmark]);

  const overrideProps = useMemo(() => {
    const overrideProps: Partial<IQuinoDataGridProps> = {};
    overrideProps.style = { minWidth: '100%' };
    if (props.disableDrillDown) {
      overrideProps.drillDownMode = 'None';
    }
    return overrideProps;
  }, [props.disableDrillDown]);

  if (isIListBookmark(props.bookmark)) {
    return <QuinoMetaDataGrid visible={true} bookmark={props.bookmark} drilldown={props.actions.drilldown} gridProperties={overrideProps} />;
  } else if (isIObjectBookmark(props.bookmark)) {
    return <QuinoMetaPropertyPanel visible={true} bookmark={props.bookmark} actions={props.actions} />;
  } else if (isICustomBookmark(props.bookmark)) {
    return customBookmark;
  } else {
    return <></>;
  }
}
