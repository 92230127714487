import { IActionExecutor } from './IActionExecutor';
import { IMetaAction, IMetaActionExecutionResponse } from '../meta';
import { IGenericObject, NewGenericObjectPrimaryKey } from '../data';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IRequestBuilder } from '../request/IRequestBuilder';
import { IBatchedRequestService, IBatchedRequestServiceSymbol } from '../request/IBatchedRequestService';
import { IUrlHelper } from './IUrlHelper';
import { IDataService } from './IDataService';
import { IODataBatchRequest } from './QuinoServer';

@injectable()
export class RemoteActionExecutor implements IActionExecutor {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlHelper) public urlHelper: IUrlHelper,
    @inject(QuinoCoreServiceSymbols.IDataService) public dataService: IDataService,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) public requestBuilder: IRequestBuilder,
    @inject(IBatchedRequestServiceSymbol) private readonly batchedRequestService: IBatchedRequestService
  ) {}

  async execute(action: IMetaAction, genericObject: IGenericObject): Promise<IMetaActionExecutionResponse> {
    if (!genericObject.primaryKey || genericObject.primaryKey === NewGenericObjectPrimaryKey) {
      if (action.saveCallingObjectBeforeExecuting) {
        throw new Error('Can not execute this action on an unsaved object.');
      }
    }

    if (genericObject.primaryKey == null) {
      throw new Error('Can not execute this action because object does not have a primary key.');
    }

    const url = this.urlHelper.combineUrlParts('api', 'v1', 'generic', genericObject.metaClass, genericObject.primaryKey, 'method', action.name);
    const id = genericObject.primaryKey;
    const request: IODataBatchRequest = {
      url,
      method: 'get',
      id
    };
    const response = await this.batchedRequestService.enqueue(request);

    switch (response.body) {
      case 'None':
        return 'None';
      case 'CallingObjectChanged':
        return 'CallingObjectChanged';
      case 'MultipleObjectsChanged':
        return 'MultipleObjectsChanged';
      default:
        return 'None';
    }
  }
}
