import { useEffect, useRef, useState } from 'react';

export const useRerender = () => {
  const [, rerender] = useState<{}>({});
  const mounted = useRef(false);

  const safeRerender = () => {
    mounted.current && rerender({});
  };

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return () => safeRerender();
};
