import { IContextDecorator } from './IContextDecorator';
import { IAuthenticationService } from '../../authentication';

/**
 * Add user info to global context.
 */
export class UserInfoContextDecorator implements IContextDecorator {
  constructor(authenticationService: IAuthenticationService) {
    this.authenticationService = authenticationService;
  }

  decorate(context: any): void {
    const info = this.authenticationService.getUserInfo();
    if (context != null && context.currentUser == null) {
      context.currentUser = info;
    }
  }

  private readonly authenticationService: IAuthenticationService;
}
