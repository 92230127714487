import { IBookmark, isIBookmark } from './IBookmark';

/**
 * Bookmark that can be refreshed
 */
export interface IRefreshableBookmark extends IBookmark {
  /**
   * Refresh the bookmark
   */
  refresh: () => void;
}

/**
 * Whether the given object is an IRefreshableBookmark.
 */
export const isIRefreshableBookmark = (object: any): object is IRefreshableBookmark => {
  return isIBookmark(object) && (object as IRefreshableBookmark).refresh !== undefined;
};
