import { IQuinoContext } from './IQuinoContext';
import { IRequestDecorator } from '../../request';

export const contextRequestDecoratorSymbol = Symbol.for('ContextRequestDecorator');
export const currentContextStorageKey = 'context';

export class ContextRequestDecorator implements IRequestDecorator {
  public context: IQuinoContext | undefined = undefined;

  decorate(request: Request): void {
    if (this.context) {
      request.headers.set('context', this.context.value);
    }
  }
}
