import * as React from 'react';
import { ReactElement } from 'react';
import { IApplication, QuinoCoreServiceSymbols } from '@quino/core';
import { inject, injectable } from 'inversify';
import { ContainerContext, IQuinoAuthenticationComponentFactory, QuinoServerHealthChecker } from '@quino/ui';
import { CUIAuthenticationComponent } from './CUIAuthenticationComponent';
import { CUIChangePasswordComponent } from './CUIChangePasswordComponent';

@injectable()
export class CUIAuthenticationComponentFactory implements IQuinoAuthenticationComponentFactory {
  constructor(@inject(QuinoCoreServiceSymbols.IApplication) private readonly application: IApplication) {}

  createComponent(onLoggedIn: () => void): ReactElement {
    return (
      <ContainerContext.Provider value={{ container: this.application }}>
        <QuinoServerHealthChecker />
        <CUIAuthenticationComponent onLoggedIn={onLoggedIn} />
      </ContainerContext.Provider>
    );
  }

  createChangePasswordComponent(onClose: () => void): React.ReactElement {
    return (
      <ContainerContext.Provider value={{ container: this.application }}>
        <QuinoServerHealthChecker />
        <CUIChangePasswordComponent close={onClose} />
      </ContainerContext.Provider>
    );
  }
}
