import { ICustomBookmark } from './ICustomBookmark';
import { IRefreshableBookmark } from './IRefreshableBookmark';
import { ICalendarAspect, TCalendarView } from '../calendar';
import { isIBookmark } from './IBookmark';
import { IStateFullBookmark } from './IStateFullBookmark';

export type TCalendarStateObject = { start: Date; end: Date; view: TCalendarView };

export const CalendarBookmarkStateIdentifier = 'options';

export const CalendarBookmarkSymbol = Symbol.for('CalendarBookmark');

// @ts-ignore
export interface ICalendarBookmark extends ICustomBookmark, IRefreshableBookmark, IStateFullBookmark {
  /**
   * Get the current typed calendar-state
   */
  getCalenderState(): TCalendarStateObject | undefined;

  /**
   * Subscribe to the bookmark refresh
   */
  subscribeToRefresh(callback: () => void): Symbol;

  /**
   * Unsubscribe from the bookmark refresh
   */
  unsubscribeFromRefresh(symbol: Symbol): void;

  /**
   * The calendar aspect
   */
  readonly aspect: ICalendarAspect;
}

/**
 * Whether the given object is an ICalendarBookmark
 */
export const isICalendarBookmark = (object: any): object is ICalendarBookmark => {
  return isIBookmark(object) && (object as ICalendarBookmark).aspect !== undefined;
};
