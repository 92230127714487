import { IErrorMapper } from './IErrorMapper';
import { DefaultErrorMapper } from './DefaultErrorMapper';
import { ServerValidationError } from '../../validations';
import { ITranslationService } from '../../lang';
import { IDynamicStringCalculator } from '../../expressions';
import { IMetadataTree } from '../../api';

export class ValidationErrorMapper extends DefaultErrorMapper implements IErrorMapper {
  constructor(
    translationService: ITranslationService,
    private readonly metadataTree: IMetadataTree,
    private readonly dynamicStringCalculator: IDynamicStringCalculator
  ) {
    super(translationService);
  }

  get errorName() {
    return super.errorName + '.Validation';
  }

  getErrorMessage(error: Error): string {
    if (!(error instanceof ServerValidationError)) {
      return super.getErrorMessage(error);
    }
    const metaClass = this.metadataTree.getClass(error.validationInfo.metaClass);

    const result: string[] = [];

    error.validationInfo.errors.forEach((fieldError) => {
      const property = metaClass.properties.find((p) => p.name.toLowerCase() === fieldError.fieldName.toLowerCase());
      const propertyName = property ? this.dynamicStringCalculator.calculateCaption(property, {}) : fieldError.fieldName;
      result.push(propertyName + ': ' + fieldError.errorMessage);
    });

    return result.join(', ');
  }
}
