import { DataType } from '../meta';

export function recalculateAsUtc(value: any, targetDataType: DataType) {
  if ((targetDataType === DataType.DateTime && value != null) || targetDataType === DataType.Time) {
    return new Date(value);
  }

  if (targetDataType === DataType.Date && value != null) {
    // Date need to be recalculated as UTC to ensure we don't mess around with them. Only DateTime and Time is displayed in effective local time.
    const localDate = new Date(value);
    return new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), localDate.getUTCHours(), localDate.getUTCMinutes());
  }

  return value;
}
