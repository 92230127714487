import React, { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { useService } from '../../ioc/hook';
import { IComponentFactory } from '../ComponentFactory';
import { getAspectOrDefault, ILogger, isIMetaGroup, ISizingAspect, QuinoCoreServiceSymbols, SizingAspectIdentifier } from '@quino/core';
import { QuinoUIServiceSymbols } from '../../ioc';
import { ObjectBookmark } from '../../bookmarks';
import { Box, Item } from 'devextreme-react/box';
import { useMetadata } from '../../settings';
import { useOnDependencyChanged } from '../Util/MetaElementHooks';

export function QuinoGroupRow(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const factory = useService<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const { visible, readOnly, enabled, required } = useMetadata(props.element, props.bookmark.genericObject);
  useOnDependencyChanged(props.element, props.bookmark);

  const groupElement = props.element;

  if (isIMetaGroup(groupElement)) {
    const hasAnySizingAspect = groupElement.elements.filter((x) => getAspectOrDefault(x, SizingAspectIdentifier) !== null).length > 0;

    const factor = groupElement.elements.length * 100;
    let taken = 0;
    const sizingInfos = groupElement.elements
      .map((x) => {
        return { aspect: getAspectOrDefault<ISizingAspect>(x, SizingAspectIdentifier), element: x, finalSize: 0 };
      })
      .map((x) => {
        if (x.aspect && x.aspect.horizontalSizeMode === 'Percent') {
          x.finalSize = x.aspect.horizontalUnits * factor;
          taken += x.aspect.horizontalUnits;
        }

        return x;
      });

    const noDefinitionElements = sizingInfos.filter((x) => x.finalSize === 0);
    const remainingPercent = 100 - taken;
    const remainingRatio = remainingPercent / noDefinitionElements.length;
    noDefinitionElements.forEach((x) => (x.finalSize = remainingRatio * factor));

    const elements = groupElement.elements.map((element, index) => {
      const size = sizingInfos.find((x) => x.element === element);

      return (
        <Item visible={visible} key={element.name + '_group_row_item'} ratio={size!.finalSize}>
          <div
            className={
              groupElement.elements.length < 2
                ? 'quino-group-row-single-column'
                : index === 0
                ? 'quino-group-row-first-column'
                : index < groupElement.elements.length - 1
                ? 'quino-group-row-middle-column'
                : 'quino-group-row-last-column'
            }>
            {factory.create(element, props.bookmark, props.actions, { enabled, readOnly, required })}
          </div>
        </Item>
      );
    });

    if (noDefinitionElements.length === 0 && taken !== 100) {
      // Add a placeholder to fill the rest...
      elements.push(<Item key={'placeholder_group_row_item'} ratio={remainingPercent * factor} />);
    }

    return (
      <Box
        visible={visible}
        className={`quino-group-box ${(!hasAnySizingAspect && 'quino-apply-min-width') || 'quino-do-not-apply-max-width'}`}
        key={props.element.name + '_group_row_box'}
        direction='row'
        width='auto'>
        {elements}
      </Box>
    );
  }

  logger.logError(`Trying to render a group-row with a non-group element. Rendering fragment for: [${groupElement}]`);

  return <></>;
}
