import { IValueListTools } from './IValueListTools';
import { getAspectOrDefault, IMetaProperty, isMetaRelation, IValueListAspect, ValueListAspectIdentifier } from '@quino/core';
import { inject, injectable } from 'inversify';
import { IODataSourceFactory, IODataSourceFactorySymbol } from './IODataSourceFactory';
import DataSource from 'devextreme/data/data_source';

@injectable()
export class ValueListTools implements IValueListTools {
  constructor(@inject(IODataSourceFactorySymbol) private readonly odataFactory: IODataSourceFactory) {}

  fetchRelated = async (property: IMetaProperty): Promise<DataSource> => {
    const aspect = getAspectOrDefault<IValueListAspect>(property, ValueListAspectIdentifier);

    if (aspect) {
      return Promise.resolve(new DataSource(aspect.options));
    }

    if (!isMetaRelation(property)) {
      throw new Error(
        `Property [${JSON.stringify(property)}] does not contain a value list aspect and is not a relation. Cannot fetch related values.`
      );
    }

    return this.odataFactory.create(property);
  };
}
