import React, { useEffect } from 'react';
import { Button } from 'devextreme-react/button';
import { useService } from '../../ioc';
import { IFeedbackService, IFeedbackServiceSymbol } from '../../feedback';
import { ITranslationService, ITranslationServiceSymbol, QuinoCoreServiceSymbols, ILogger } from '@quino/core';

export interface IQuinoFileErrorBarProps {
  errorMessages: string[];
  onDismissErrorMessage: () => void;
}

export const QuinoFileErrorBar: React.FC<IQuinoFileErrorBarProps> = (props) => {
  const { errorMessages, onDismissErrorMessage } = props;

  const feedbackService = useService<IFeedbackService>(IFeedbackServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  useEffect(() => {
    const timeoutId = (errorMessages.length > 0 && setTimeout(onDismissErrorMessage, 3000)) || undefined;
    return () => clearTimeout(timeoutId);
  }, [errorMessages, onDismissErrorMessage]);

  if (errorMessages.length < 1) {
    return <></>;
  }

  const errorMessage = errorMessages[errorMessages.length - 1];

  const showDetails = () => {
    feedbackService.showMessage(translationService.translate('Details'), errorMessage).catch(logger.logError);
  };

  return (
    <div className={'quino-file-bar quino-file-error-bar'}>
      <div className={'left-content'}>
        <i className='material-icons-outlined error_outline' />
        <div className={'error-message'}>{errorMessage}</div>
      </div>
      <div className={'right-content'}>
        <Button type={'normal'} stylingMode={'text'} icon='material-icons-outlined receipt' onClick={showDetails} />
        <Button type={'normal'} stylingMode={'text'} icon='material-icons-outlined close' onClick={onDismissErrorMessage} />
      </div>
    </div>
  );
};
