/**
 * The symbol for resolving the home navigation service.
 */
export const IHomeNavigationServiceSymbol = Symbol.for('IHomeNavigationService');

/**
 * The home navigation service navigates to the application's home page
 */
export interface IHomeNavigationService {
  /**
   * Navigate home
   */
  navigateHome(): Promise<void>;
}
