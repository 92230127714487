// DevEx does not properly handle promises returned by async functions.
/* eslint-disable @typescript-eslint/promise-function-async */

import ODataStore from 'devextreme/data/odata/store';
import { Deferred } from 'devextreme/core/utils/deferred';
import { QuinoODataStore } from './QuinoODataStore';
import { ILogger } from '../../logging';

export type ODataDefaultData = {
  key: string;
  data: any;
};

/**
 * Decorator for the ODataStore to cache the initial load if the filter does not change.
 */
export class QuinoDefaultDataODataStore extends QuinoODataStore {
  constructor(baseStore: ODataStore, private readonly logger: ILogger, private readonly defaultData: ODataDefaultData) {
    super(baseStore);
  }

  byKey(key: any): Promise<any> {
    if (this.defaultData.data != undefined && Object.prototype.hasOwnProperty.call(this.defaultData.data, this.defaultData.key)) {
      const value = this.defaultData.data[this.defaultData.key];
      if (value === key) {
        this.logger.logDebug(`Loading default data from odata-source by key: [${key}]`);

        const deferred = Deferred();
        void deferred.resolve(this.defaultData.data);
        return deferred.promise();
      }
    }

    this.logger.logDebug(`Loading data from odata-source by key: [${key}]`);
    return this.baseStore.byKey(key);
  }

  totalCount(obj: { filter?: any; group?: any }): Promise<number> {
    if (this.defaultData.data != undefined && Object.prototype.hasOwnProperty.call(this.defaultData.data, this.defaultData.key)) {
      const value = this.defaultData.data[this.defaultData.key];
      if (obj.filter && Array.isArray(obj.filter) && obj.filter.length === 3) {
        const filterKey = obj.filter[0];
        const filterValue = obj.filter[2];
        if (this.defaultData.key === filterKey && value.toString() === filterValue.toString()) {
          this.logger.logDebug(`Loading default total count from odata-source by filter: [${filterValue.toString()}]`);
          const deferred = Deferred<number>();
          void deferred.resolve(this.defaultData.data.length);
          return deferred.promise();
        }
      }
    }
    return this.baseStore.totalCount(obj);
  }

  load(): Promise<any>;
  load(options: any): Promise<any>;
  load(options?: any): Promise<any> {
    if (this.defaultData.data != undefined && Object.prototype.hasOwnProperty.call(this.defaultData.data, this.defaultData.key)) {
      const value = this.defaultData.data[this.defaultData.key];
      if (options.filter && Array.isArray(options.filter) && options.filter.length === 3) {
        const filterKey = options.filter[0];
        const filterValue = options.filter[2];
        if (this.defaultData.key === filterKey && value.toString() === filterValue.toString()) {
          this.logger.logDebug(`Loading default data from odata-source by filter: [${filterValue.toString()}]`);

          const deferred = Deferred();
          void deferred.resolve([this.defaultData.data]);
          return deferred.promise();
        }
      }
    }
    return this.baseStore.load(options);
  }
}
