import React, { ReactNode } from 'react';
import { Item, Toolbar } from 'devextreme-react/toolbar';
import { getResponsiveClassName, useResponsiveMode } from '../../responsivity';
import { QuinoLoadButton } from '../QuinoLoadButton';
import { IQuinoLoadButtonOptions } from '../QuinoLoadButton/QuinoLoadButton';

export interface IQuinoPopupToolbarProps {
  leftItems?: ReactNode[];
  rightItems?: ReactNode[];
  showTopBorder?: boolean;
}

export const QuinoPopupToolbar = (props: IQuinoPopupToolbarProps) => {
  const responsiveMode = useResponsiveMode();

  return (
    <Toolbar className={getResponsiveClassName('quino-popup-toolbar' + (props.showTopBorder ? ' has-top-border' : ''), responsiveMode)}>
      {props.leftItems &&
        props.leftItems.map((x, i) => (
          <Item key={i} location={'before'}>
            {x}
          </Item>
        ))}
      {props.rightItems &&
        props.rightItems.map((x, i) => (
          <Item key={i} location={'after'}>
            {x}
          </Item>
        ))}
    </Toolbar>
  );
};

export interface IQuinoPopupToolbarButtonProps extends IQuinoLoadButtonOptions {
  isPrimary?: boolean;
}

export const QuinoPopupToolbarButton = (props: IQuinoPopupToolbarButtonProps) => {
  return (
    <QuinoLoadButton
      {...props}
      onClick={props.onClick}
      type={'default'}
      stylingMode={props.stylingMode ?? props.isPrimary ? 'contained' : 'outlined'}
      disabled={props.disabled}
      onContentReady={(e) => props.isPrimary && setTimeout(() => e.component.focus())}
    />
  );
};
