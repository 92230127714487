import React from 'react';
import { ILogger, ITranslationService, INotificationService } from '@quino/core';

export class QuinoErrorBoundary extends React.Component<
  { logger: ILogger; translationService: ITranslationService; notificationService: INotificationService },
  { hasError: boolean; error: any }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error: any, info: any) {
    this.props.logger.logError(error);
    this.props.logger.logError(info);
  }

  render() {
    if (this.state.hasError) {
      const message = this.props.translationService.translate('Notification.ErrorBoundaryError');
      this.props.notificationService.notify({ message: message, messageDetails: String(this.state.error) });

      return React.Fragment;
    }

    return this.props.children;
  }
}
