/**
 * The log levels available with the @ILogger.
 */
export enum LogLevel {
  Debug,
  Information,
  Warning,
  Error
}

/**
 * The logger interface to log messages.
 */
export interface ILogger {
  log(message: string, level: LogLevel): void;

  logDebug(message: string): void;

  logInfo(message: string): void;

  logWarn(message: string): void;

  logError(message: string | Error): void;
}
