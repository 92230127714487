import React, { PropsWithChildren } from 'react';
import { Button, IButtonOptions } from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';

export interface IQuinoLoadButtonOptions extends IButtonOptions {
  loading?: boolean | undefined;
}

export function QuinoLoadButton(props: PropsWithChildren<Partial<IQuinoLoadButtonOptions>>) {
  const { loading, stylingMode, text, icon, disabled } = props;

  return (
    <Button {...props} disabled={loading == true || disabled == true} className={props.className + ' quino-load-button'}>
      <div key={'loadButtonContent'} className={'quino-load-button-content'}>
        {!loading && <i key={'loadButtonIcon'} className={icon ? icon + ' dx-icon' : ''} />}
        <LoadIndicator
          key={'loadButtonIndicator'}
          visible={loading}
          elementAttr={{ class: stylingMode === 'contained' ? 'dx-icon quino-load-indicator is--white' : 'dx-icon quino-load-indicator' }}
        />
        <span key={'loadButtonText'} className='dx-button-text'>
          {text}
        </span>
      </div>
    </Button>
  );
}
