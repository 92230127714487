import { inject, injectable } from 'inversify';
import { IBackendTranslationLoader } from './IBackendTranslationLoader';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IUrlManager } from '../api/QuinoServer/IUrlManager';
import { IRequestBuilder } from '../request';
import { ITranslationApiResponse } from './ITranslationApiResponse';

@injectable()
export class QuinoBackendTranslationLoader implements IBackendTranslationLoader {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder
  ) {}

  async getTranslationsAsync(_languageCode?: string) {
    const url = this.urlManager.getDefaultTranslationsUrl();

    const request = this.requestBuilder.create(url, 'get');

    return request.fetchJson<ITranslationApiResponse>();
  }
}
