import { INotificationConfiguration } from './INotificationConfiguration';

export const INotificationConfigurationServiceSymbol = Symbol.for('INotificationConfigurationService');

export interface INotificationConfigurationService {
  /**
   * Gets the notification configuration
   */
  getConfiguration(): Promise<INotificationConfiguration>;
}
