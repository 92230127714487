(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("inversify"), require("inversify-inject-decorators"), require("devextreme/data/custom_store"), require("devextreme/core/utils/deferred"), require("devextreme/localization"), require("devextreme-react/text-box"), require("exceljs"), require("file-saver"));
	else if(typeof define === 'function' && define.amd)
		define(["inversify", "inversify-inject-decorators", "devextreme/data/custom_store", "devextreme/core/utils/deferred", "devextreme/localization", "devextreme-react/text-box", "exceljs", "file-saver"], factory);
	else if(typeof exports === 'object')
		exports["@quino/core"] = factory(require("inversify"), require("inversify-inject-decorators"), require("devextreme/data/custom_store"), require("devextreme/core/utils/deferred"), require("devextreme/localization"), require("devextreme-react/text-box"), require("exceljs"), require("file-saver"));
	else
		root["@quino/core"] = factory(root["inversify"], root["inversify-inject-decorators"], root["devextreme/data/custom_store"], root["devextreme/core/utils/deferred"], root["devextreme/localization"], root["devextreme-react/text-box"], root["exceljs"], root["file-saver"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__2710__, __WEBPACK_EXTERNAL_MODULE__1795__, __WEBPACK_EXTERNAL_MODULE__8755__, __WEBPACK_EXTERNAL_MODULE__7184__, __WEBPACK_EXTERNAL_MODULE__7264__, __WEBPACK_EXTERNAL_MODULE__2619__, __WEBPACK_EXTERNAL_MODULE__4674__, __WEBPACK_EXTERNAL_MODULE__3346__) => {
return 