import {
  IGenericObject,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILogger,
  IMetadataTree,
  IMetaRelation,
  ITitleCalculator,
  ITitleCalculatorSymbol,
  ITranslationService,
  ITranslationServiceSymbol,
  LayoutType,
  QuinoCoreServiceSymbols,
  ODataUrlPathDelimiter,
  isIMetaProperty,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol
} from '@quino/core';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IQuinoComponentProps } from '../Types';
import { useOnBookmarkSavedEvent, useOnMount, usePromise, useRelatedSource, useRerenderOnChanges } from '../Util';
import { useService } from '../../ioc';
import { IGenericObjectPersistenceService, IGenericObjectPersistenceServiceSymbol, IODataSourceFactory, IODataSourceFactorySymbol } from '../../data';
import { IObjectBookmark, isNewObjectBookmark } from '../../bookmarks';
import { TagBox } from 'devextreme-react';
import ArrayStore from 'devextreme/data/array_store';
import { useMetadata } from '../../settings';
import isEqual from 'lodash/isEqual';

export function QuinoMultiSelectBox(props: IQuinoComponentProps<IObjectBookmark>) {
  const { element, bookmark } = props;
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const sourceFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const titleCalculator = useService<ITitleCalculator>(ITitleCalculatorSymbol);
  const persistenceService = useService<IGenericObjectPersistenceService>(IGenericObjectPersistenceServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const fieldValueService = useService<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  const [commit, setCommit] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [initialSelectedKeys, setInitialSelectedKeys] = useState<any[]>([]);
  const [existingSelection, setExistingSelection] = useState<IGenericObject[]>([]);

  if (!isIMetaProperty(element)) {
    throw new Error('Multi select needs a valid meta property.');
  }

  useRerenderOnChanges(bookmark, element);
  const { readOnly, required, enabled, visible } = useMetadata(element, bookmark.genericObject);

  // Prepare the relations that we need to map the n:n relation.
  const { sourceRelation, targetRelation } = useMemo<{ sourceRelation: IMetaRelation; targetRelation: IMetaRelation }>(() => {
    const paths = element.path.split(ODataUrlPathDelimiter);
    if (paths.length != 2) {
      throw new Error('Invalid path for multi select supplied.');
    }

    const firstRelation = bookmark.metaClass.relations.find((x) => x.name == paths[0]);
    const targetClass = metadataTree.getClass(firstRelation!.targetClass!);
    const secondRelation = targetClass.relations.find((x) => x.name == paths[1]);

    return {
      sourceRelation: firstRelation!,
      targetRelation: secondRelation!
    };
  }, [element.path, metadataTree, bookmark.metaClass.relations]);

  const targetClass = useMemo(() => metadataTree.getClass(targetRelation.targetClass), [metadataTree, targetRelation.targetClass]);
  const sourceClass = useMemo(() => metadataTree.getClass(sourceRelation.targetClass), [metadataTree, sourceRelation.targetClass]);
  const sourcePropertyKey = sourceRelation.targetProperties[0];
  const targetPropertyKey = targetRelation.sourceProperties[0];
  const targetPrimaryKey = targetClass.primaryKey[0];
  const sourcePrimaryKey = sourceClass.primaryKey[0];

  const metaLayout = useMemo(
    () =>
      layoutResolver.resolveSingle({
        element: element,
        metaClass: targetClass.name,
        type: LayoutType.Title
      }),
    // eslint-disable-next-line
    [layoutResolver, element, targetRelation]
  );

  // Fetch the candidates that are displayed without restrictions.
  const [source] = usePromise<ArrayStore, any>(
    async () =>
      sourceFactory
        .create(metaLayout, targetClass.name, { paginate: false })
        .load()
        .then(
          (src) =>
            new ArrayStore({
              key: targetPrimaryKey,
              data: src
            })
        ),
    [metaLayout, sourceFactory, targetRelation, commit]
  );

  // Fetch the already selected items to display them as checked in the list.
  sourceRelation.sorts = [];
  const relatedSource = useRelatedSource(sourceRelation, bookmark.genericObject);
  relatedSource.paginate(false);

  useEffect(() => {
    if (!isNewObjectBookmark(bookmark)) {
      relatedSource
        .load()
        .then(() => {
          const items = relatedSource.items();
          const itemKeys = items.map((x) => x[targetPropertyKey]);
          setSelectedKeys(itemKeys.sort());
          setInitialSelectedKeys([...itemKeys.sort()]);
          setExistingSelection(items);
        })
        .catch(logger.logError);
    }
  }, [bookmark.genericObject]);

  // Once we receive a commit we're persisting the selection.
  useOnBookmarkSavedEvent(bookmark, () => setCommit(true));

  // eslint-disable-next-line
  useEffect(() => update(), [commit]);

  const update = () => {
    if (commit) {
      const sourceKey = bookmark.metaClass.primaryKey[0];
      setInitialSelectedKeys([...selectedKeys]);
      // Save/Delete our selection once the main object is saved.
      const updateCollection = selectedKeys.map((x) => {
        const result: IGenericObject = {
          metaClass: sourceRelation.targetClass,
          title: ''
        };
        result[sourcePropertyKey] = bookmark.genericObject[sourceKey];
        result[targetPropertyKey] = x;
        return result;
      });

      // TODO: Can we map this to a single batch request?
      const promises: any[] = [];
      existingSelection.forEach((existing) => {
        if (
          !updateCollection.find(
            (update) =>
              existing[sourcePrimaryKey] === update ||
              (existing[targetPropertyKey] == update[targetPropertyKey] && existing[sourcePropertyKey] == update[sourcePropertyKey])
          )
        ) {
          promises.push(persistenceService.delete(existing));
        }
      });

      updateCollection.forEach((update) => {
        if (
          !existingSelection.find(
            (existing) =>
              existing[sourcePrimaryKey] === update ||
              (existing[targetPropertyKey] == update[targetPropertyKey] && existing[sourcePropertyKey] == update[sourcePropertyKey])
          )
        ) {
          promises.push(persistenceService.save(update));
        }
      });

      // Reload the selection
      Promise.all(promises)
        .then(() => {
          void relatedSource.reload();
        })
        .catch(logger.logError)
        .finally(() => {
          bookmark.notifyChangeHandlers(bookmark.createObjectBookmarkEvent('changed', [fieldValueService.getElementPath(element)]));
          setCommit(false);
        });
    }
  };

  const onSelectionChanged = useCallback(
    (e) => {
      const addedIds = e.addedItems.map((x: []) => x[targetPrimaryKey]);
      const removedIds = e.removedItems.map((x: []) => x[targetPrimaryKey]);

      const result = selectedKeys;
      removedIds.forEach((x: any) => {
        let index = result.indexOf(x);
        if (index > -1) {
          result.splice(index, 1);
        }
      });
      result.push(...addedIds);

      bookmark.setHasChanges(!isEqual(selectedKeys.sort(), initialSelectedKeys.sort()));

      setSelectedKeys(result);
    },
    [bookmark, selectedKeys, targetPrimaryKey, initialSelectedKeys]
  );

  return (
    <TagBox
      readOnly={readOnly != null ? readOnly : false}
      disabled={!enabled}
      visible={visible}
      applyValueMode='instantly'
      noDataText={readOnly || !enabled ? '' : translationService.translate('Dropdown.NoOptionsAvailable')}
      className={'quino-drop-down-select-box quino-multi-select-box' + (readOnly || !enabled ? ' is--readOnlyOrDisabled' : '')}
      showClearButton={!required}
      dataSource={source}
      value={selectedKeys}
      valueExpr={sourceClass.primaryKey[0]}
      showSelectionControls={true}
      displayExpr={(e) => titleCalculator.generate(e, targetRelation?.targetClass!)}
      onSelectionChanged={onSelectionChanged}
      searchEnabled={true}
      placeholder={readOnly || !enabled ? '' : translationService.translate('Select')}
    />
  );
}
