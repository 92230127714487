import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextBox } from 'devextreme-react/text-box';
import { SelectBox } from 'devextreme-react/select-box';
import { Popover } from 'devextreme-react/popover';
import { List } from 'devextreme-react/list';
import dxList from 'devextreme/ui/list';
import dxTextBox from 'devextreme/ui/text_box';
import {
  IGlobalSearchResultDTO,
  IGlobalSearchService,
  IGlobalSearchServiceSymbol,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  IMetaClass,
  IMetadataTree,
  isKeyboardEvent,
  ITranslationService,
  ITranslationServiceSymbol,
  LayoutType,
  QuinoCoreServiceSymbols
} from '@quino/core';
import {
  IBookmarkFactory,
  INavigationService,
  INavigationServiceSymbol,
  IODataSourceFactory,
  IODataSourceFactorySymbol,
  IQuinoShortcutSettings,
  IQuinoShortcutSettingsSymbol,
  QuinoUIServiceSymbols,
  setFocusOnElement,
  useOnMountAsync,
  useService,
  useShortcutHandler,
  TResponsiveMode,
  useResponsiveMode
} from '@quino/ui';
import DOMPurify from 'dompurify';

interface IGlobalSearchResult extends IGlobalSearchResultDTO {
  key: string;
  metaDataTree: IMetadataTree;
}

interface IGlobalSearchClassItem {
  caption: string;
  value: IMetaClass | undefined;
}

export interface ICUIGlobalSearchProps {
  focus?: boolean;
}

export const CUIGlobalSearch: React.FC<ICUIGlobalSearchProps> = (props) => {
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const dataSourceFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const globalSearchService = useService<IGlobalSearchService>(IGlobalSearchServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedMetaClass, setSelectedMetaClass] = useState<IMetaClass>();
  const [metaClassesItems, setMetaClassesItems] = useState<IGlobalSearchClassItem[]>([]);
  const [searchResultsVisible, setSearchResultsVisible] = useState<boolean>(false);
  const [listComponent, setListComponent] = useState<dxList>();
  const [textBoxComponent, setTextBoxComponent] = useState<dxTextBox>();
  const [searchResults, setSearchResults] = useState<IGlobalSearchResult[]>([]);
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
  const [deferredRender, setDeferredRender] = useState<number>(0);

  const responsiveMode = useResponsiveMode();

  useOnMountAsync(async () => {
    const metaClassNames: string[] = (await globalSearchService.info()).map((globalSearchInfo) => globalSearchInfo.metaClassName);
    const metaClasses = metadataTree
      .getClasses()
      .filter((metaClass) => metaClassNames.includes(metaClass.name))
      .sort((a, b) => a.caption.localeCompare(b.caption));
    const metaClassItems = [
      { caption: translationService.translate('GlobalSearch.AllMetaClasses'), value: undefined },
      ...metaClasses.map((c) => {
        return { caption: c.caption, value: c };
      })
    ];
    setMetaClassesItems(metaClassItems);
  });

  useShortcutHandler([shortcutSettings.globalSearch], () => setFocusOnElement(textBoxInputMarkerID));

  useEffect(() => {
    if (props.focus && textBoxComponent) {
      textBoxComponent.focus();
    }
  }, [props.focus, textBoxComponent]);

  useEffect(() => {
    const textBoxHasFocus = document.activeElement?.id === textBoxInputMarkerID;
    setSearchResultsVisible(textBoxHasFocus);
  }, [deferredRender]);

  const triggerDeferredRender = () => setTimeout(() => setDeferredRender(Date.now()), 150);
  const searchPossible = searchValue.length > 2;

  const performSearchRequest = useCallback(async () => {
    const results = await globalSearchService.search(searchValue, selectedMetaClass?.name, undefined);
    const resultsWithKey: IGlobalSearchResult[] = results.map((result, index) => {
      return { key: index.toString(), metaDataTree: metadataTree, ...result };
    });

    setSearchInProgress(false);
    setSearchResults(resultsWithKey);
    setSearchResultsVisible(true);
  }, [globalSearchService, searchValue, selectedMetaClass?.name, metadataTree]);

  useEffect(
    () => {
      let handle: number | undefined = undefined;
      if (searchPossible) {
        setSearchInProgress(true);
        handle = setTimeout(() => void performSearchRequest(), 500);
      } else {
        setSearchInProgress(false);
        searchResults.length > 0 && setSearchResults([]);
      }
      return () => clearTimeout(handle);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchPossible, selectedMetaClass, searchValue]
  );

  const textBoxInputMarkerID = 'quino-ecui-header-global-search-text-box-input';
  const popoverListID = 'quino-ecui-header-global-search-results';

  return (
    <div
      className={'quino-ecui-header-global-search'}
      onBlur={(el) => {
        if (!el.currentTarget.matches(':focus-within')) {
          // Using timeout to wait for the next render cycle; else focus is not yet ready
          setTimeout(() => {
            // We do not want the Popover to disappear on click inside of it.
            document.activeElement?.tagName !== 'BODY' &&
              document.activeElement?.tagName !== 'A' &&
              !document.getElementById(popoverListID)?.matches('.dx-state-focused') &&
              setSearchResultsVisible(false);
          }, 0);
        }
      }}>
      <TextBox
        className={'quino-ecui-header-global-search-text-box'}
        id={'quino-ecui-header-global-search-text-box'}
        placeholder={translationService.translate('SearchGlobal')}
        onInitialized={(e) => setTextBoxComponent(e.component)}
        onContentReady={(e) => {
          const inputs = e.element?.getElementsByClassName('dx-texteditor-input');
          if (inputs && inputs.length > 0) {
            inputs[0].id = textBoxInputMarkerID;
          }
        }}
        onFocusIn={() => setSearchResultsVisible(true)}
        valueChangeEvent={'keyup'}
        onValueChanged={(e) => setSearchValue(e.value)}
        onKeyDown={(e) => {
          if (isKeyboardEvent(e.event) && e.event.code === 'ArrowDown') {
            listComponent && listComponent.focus();
          }
        }}
        showClearButton={true}
        buttons={[
          {
            name: 'search-button',
            location: 'before',
            options: {
              icon: 'search',
              stylingMode: 'text',
              disabled: true,
              elementAttr: {
                class: searchInProgress ? 'quino-ecui-header-global-search-animated-button' : ''
              }
            }
          },
          'clear'
        ]}
      />
      <SelectBox
        visible={metaClassesItems.length > 1}
        className={'quino-ecui-header-global-search-select-box'}
        items={metaClassesItems}
        displayExpr={'caption'}
        defaultValue={undefined}
        valueExpr={'value'}
        onSelectionChanged={(e) => setSelectedMetaClass(e.selectedItem.value)}
      />
      <Popover
        wrapperAttr={{ class: 'quino-ecui-header-global-search-popover' }}
        id={'quino-ecui-header-global-search-popover'}
        position={{
          at: 'left bottom',
          my: 'left top',
          of: '#quino-ecui-header-global-search-text-box',
          offset: { x: -2, y: -9 }
        }}
        visible={searchResultsVisible}
        animation={undefined}
        onHiding={(e) => {
          // do not hide popover on a click in text box
          const textBoxHasFocus = document.activeElement?.id === textBoxInputMarkerID;
          if (textBoxHasFocus) {
            e.cancel = textBoxHasFocus;
            triggerDeferredRender();
          } else {
            setSearchResultsVisible(false);
          }
        }}
        maxWidth={responsiveMode === TResponsiveMode.Phone ? '95vw' : 450}>
        <List
          visible={searchPossible}
          id={popoverListID}
          className={'quino-ecui-header-global-search-list'}
          dataSource={searchResults}
          noDataText={
            searchInProgress ? translationService.translate('GlobalSearch.Searching') : translationService.translate('GlobalSearch.NoResults')
          }
          itemRender={CUIGlobalSearchListItem}
          itemKeyFn={(itemData: IGlobalSearchResult) => itemData.key}
          onInitialized={(e) => {
            setListComponent(e.component);

            e.element?.addEventListener('keydown', (event: KeyboardEvent) => {
              if (isKeyboardEvent(event) && event.code === 'ArrowUp') {
                const scrollView = e.element?.getElementsByClassName('dx-scrollview-content')[0];
                if (!scrollView || !scrollView.firstElementChild) {
                  return;
                }

                const firstListItemFocused = scrollView.firstElementChild.classList.contains('dx-state-focused');
                if (firstListItemFocused) {
                  event.stopImmediatePropagation();
                  textBoxComponent && textBoxComponent.focus();
                }
              }
            });
          }}
          onItemClick={async (e) => {
            setSearchResultsVisible(false);

            const unload = loadingFeedback.load();
            const result = e.itemData as IGlobalSearchResult;
            const parentLayout = layoutResolver.resolveSingle({ metaClass: result.metaClassName, type: LayoutType.Detail });
            const genericObject = await dataSourceFactory.fetch(result.primaryKey, parentLayout, result.metaClassName);
            if (genericObject !== null && genericObject.primaryKey !== 'null') {
              const objectBookmark = bookmarkFactory.createObject(genericObject);
              await navigationService.push(objectBookmark);
              unload();
            }
          }}
        />
        {!searchPossible && (
          <div className={'quino-ecui-header-global-search-list disabled'}>
            <div className={'dx-list-item'}>
              <p className={'default-text'}>{translationService.translate('GlobalSearch.MinimumCharacterWarning')}</p>
            </div>
            <div className={'dx-list-item'}>
              <p className={'small-text'}>{translationService.translate('GlobalSearch.ScopeWarning')}</p>
            </div>
            <div className={'dx-list-item'}>
              <p className={'global-search-available-operators small-text'}>
                {translationService.translate('GlobalSearch.SearchOperatorsInfo.AvailableOperators')}
              </p>
              <ul>
                <li className={'small-text'}>
                  <span>{translationService.translate('GlobalSearch.SearchOperatorsInfo.AvailableOperators.Wildcard')}</span>
                </li>
                <li className={'small-text'}>
                  <span>{translationService.translate('GlobalSearch.SearchOperatorsInfo.AvailableOperators.Approximation')}</span>
                </li>
                <li className={'small-text'}>
                  <span>{translationService.translate('GlobalSearch.SearchOperatorsInfo.AvailableOperators.Range')}</span>
                </li>
              </ul>
              <div className={'dx-list-item'}>
                <p className={'small-text'}>
                  {translationService.translate('GlobalSearch.SearchOperatorsInfo.AvailableOperators.See')}
                  <a target={'_blank'} href={'https://encodo.atlassian.net/wiki/spaces/EB/pages/99614723/Globale+Suche'} rel='noreferrer'>
                    {translationService.translate('GlobalSearch.SearchOperatorsInfo.AvailableOperators.UserManual')}
                  </a>
                  {translationService.translate('GlobalSearch.SearchOperatorsInfo.AvailableOperators.ForMoreDetails')}
                </p>
              </div>
            </div>
          </div>
        )}
      </Popover>
    </div>
  );
};

export const CUIGlobalSearchListItem: React.FC<IGlobalSearchResult> = (props) => {
  const { highlightedCaption, metaClassName, highlights, metaDataTree } = props;
  const metaClass = metaDataTree.getClass(metaClassName);

  const getCaptionOfPropertyName = (propertyName: string): string => {
    const matchingProperties = metaClass.properties.filter((property) => property.name === propertyName);
    return matchingProperties.length > 0 ? matchingProperties[0].caption : propertyName;
  };

  const sanitizedData = (data: any) => ({
    __html: DOMPurify.sanitize(data)
  });

  return (
    <div className={'quino-ecui-header-global-search-list-item'}>
      <div className={'firstRow'}>
        <p className={'caption'} dangerouslySetInnerHTML={sanitizedData(highlightedCaption)} />
        <p className={'metaClass'}>{metaClass.caption}</p>
      </div>
      {highlights?.map((highlight, index) => (
        <div className={'secondRow'} key={'search-property-result-' + index}>
          {highlight.propertyName && <p className={'propertyName'}>{`${getCaptionOfPropertyName(highlight.propertyName)}:`}&nbsp;</p>}
          {highlight.highlightedValue && <p className={'highlightedValue'} dangerouslySetInnerHTML={sanitizedData(highlight.highlightedValue)} />}
        </div>
      ))}
    </div>
  );
};
