import { injectable } from 'inversify';
import { IScopingService } from './IScopingService';

@injectable()
export class ScopingService implements IScopingService {
  getScope(): string | null {
    return this.scope;
  }

  setScope(scope: string): void {
    this.scope = scope;
  }

  private scope: string | null = null;
}
