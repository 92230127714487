import { IMetaElement } from '../../meta';

export const IDynamicStringCalculatorSymbol = Symbol.for('IDynamicStringCalculator');

export interface IDynamicStringCalculator {
  /**
   * Calculates the caption of an IMetaElement
   * @param element element to calculate the caption of
   * @param object data to calculate the caption with
   */
  calculateCaption(element: IMetaElement, object?: any): string;

  /**
   * Calculates the description of an IMetaElement
   * @param element element to calculate the description of
   * @param object data to calculate the description with
   */
  calculateDescription(element: IMetaElement, object: any): string | undefined;

  /**
   * Calculates the instruction of an IMetaElement
   * @param element element to calculate the instruction of
   * @param object data to calculate the instruction with
   */
  calculateInstruction(element: IMetaElement, object: any): string | undefined;
}
