export enum AuthenticationScreenState {
  None,
  RegistrationForm,
  RegistrationConfirmationForm,
  EmailConfirmationRequiredForm,
  EmailConfirmationSentForm,
  LoginForm,
  ResetTokenForm,
  ResetTokenRequestedForm,
  ResetPasswordForm,
  ResetPasswordConfirmationForm
}
