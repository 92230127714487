import { inject, injectable } from 'inversify';
import { IGenericObjectPersistenceService } from './IGenericObjectPersistenceService';
import { IODataSourceFactory, IODataSourceFactorySymbol } from './IODataSourceFactory';
import {
  QuinoCoreServiceSymbols,
  IDataService,
  IMetadataTree,
  ITranslationService,
  ITranslationServiceSymbol,
  IDataDiffer,
  IMetaRelation,
  LayoutType,
  ILayoutResolver,
  ILayoutResolverSymbol,
  IGenericObject,
  ServerValidationError,
  tryParseServerError
} from '@quino/core';

@injectable()
export class GenericObjectPersistenceService implements IGenericObjectPersistenceService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IDataService) private readonly dataService: IDataService,
    @inject(QuinoCoreServiceSymbols.IDataDiffer) private readonly dataDiffer: IDataDiffer,
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metaDataTree: IMetadataTree,
    @inject(IODataSourceFactorySymbol) private readonly odataFactory: IODataSourceFactory,
    @inject(ILayoutResolverSymbol) private readonly layoutResolver: ILayoutResolver
  ) {}

  async delete(data: IGenericObject | IGenericObject[]): Promise<boolean> {
    const objectsToDelete = (Array.isArray(data) ? data : [data]).filter((o) => o.primaryKey !== undefined);

    const keysToDelete = {};
    for (const obj of objectsToDelete) {
      if (keysToDelete[obj.metaClass] !== undefined) {
        keysToDelete[obj.metaClass].push(obj.primaryKey);
      } else {
        keysToDelete[obj.metaClass] = [obj.primaryKey];
      }
    }

    for (const metaClass of Object.keys(keysToDelete)) {
      await this.dataService.deleteObjectsAsync(metaClass, keysToDelete[metaClass]);
    }

    return true;
  }

  async save(data: IGenericObject, original?: IGenericObject): Promise<IGenericObject> {
    const applyNewValues = async (result: IGenericObject) => {
      return this.odataFactory.fetch(
        result.primaryKey!,
        this.layoutResolver.resolveSingle({ metaClass: result.metaClass, type: LayoutType.Detail }),
        result.metaClass
      );
    };

    try {
      const changedValues = original ? this.dataDiffer.getChangedValues<IGenericObject>(original, data) : data;
      if (data.primaryKey != null && data.primaryKey !== 'null') {
        const result = await this.dataService.updateObjectAsync<IGenericObject>(data.metaClass, data.primaryKey, changedValues);
        return await applyNewValues(result);
      } else {
        const requestData = this.patchKeyValues(original ? { ...original, ...changedValues } : data);
        const result = await this.dataService.insertObjectAsync<IGenericObject>(data.metaClass, requestData);
        return await applyNewValues(result);
      }
    } catch (error) {
      if (error && error.message) {
        const validationDetails = tryParseServerError(error.message, data.metaClass);
        const originalMessage = this.translationService.translate('Detail.ErrorCouldNotSaveDataWithMessage', {
          errorMessage: error.message
        });

        if (validationDetails) {
          throw new ServerValidationError(originalMessage, validationDetails);
        } else {
          throw new Error(originalMessage);
        }
      } else {
        throw new Error(this.translationService.translate('Detail.ErrorCouldNotSaveData'));
      }
    }
  }

  private patchKeyValues(data: IGenericObject): IGenericObject {
    const metaClass = this.metaDataTree.getClass(data.metaClass);
    metaClass.relations.forEach((relation: IMetaRelation) =>
      relation.sourceProperties.forEach(
        (sourceProperty: string) => (data[sourceProperty] = data[sourceProperty] && data[sourceProperty] === 'null' ? null : data[sourceProperty])
      )
    );
    data.primaryKey = undefined;
    metaClass.primaryKey.forEach((x: string) => {
      data[x] = null;
    });
    return data;
  }
}
