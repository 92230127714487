import * as translations from './translations.json';

export function loadDefaultTranslations(code: string): any {
  if (code === 'la') {
    const result = {};
    Object.keys(translations['en']).forEach((value) => {
      result[value] = value;
    });

    return result;
  }

  let result = translations[code];

  if (result == null && code.includes('-')) {
    const baseCode = code.split('-')[0];
    result = translations[baseCode];
  }

  return result ?? translations['en'];
}
