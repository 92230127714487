import { inject, injectable } from 'inversify';
import cloneDeep from 'lodash/cloneDeep';
import { IQuinoFileHandler } from './IQuinoFileHandler';
import { IQuinoFileStorage, IQuinoFileStorageSymbol } from './IQuinoFileStorage';
import { INotificationService, INotificationServiceSymbol, ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { IFileInfo } from './IFileInfo';

interface IQuinoFileHandlerState {
  initial: IFileInfo[];
  new: IFileInfo[];
  deleted: IFileInfo[];
}

@injectable()
export class QuinoFileHandler implements IQuinoFileHandler {
  constructor(
    @inject(IQuinoFileStorageSymbol)
    private readonly quinoFileStorage: IQuinoFileStorage,
    @inject(INotificationServiceSymbol)
    private readonly notificationService: INotificationService,
    @inject(ITranslationServiceSymbol)
    private readonly translationService: ITranslationService
  ) {}

  private files: IQuinoFileHandlerState = { initial: [], new: [], deleted: [] };

  async init(ownerClass: string, ownerPrimaryKey: string | undefined, context: string | null): Promise<IFileInfo[]> {
    let fileHandlerInitialMetadata: IFileInfo[] = [];

    if (ownerPrimaryKey != undefined) {
      fileHandlerInitialMetadata = await this.quinoFileStorage.getFileInfo(null, ownerClass, ownerPrimaryKey, context);
    }

    this.files = { initial: fileHandlerInitialMetadata, new: [], deleted: [] };
    return this.returnFiles();
  }

  async addFile(id: string): Promise<IFileInfo[]> {
    const newFilesMetadata = await this.quinoFileStorage.getFileInfo(id, null, null, null);
    if (newFilesMetadata.length > 0) {
      const newFileMetaData = newFilesMetadata[0];
      const newFileName = newFileMetaData.name;
      let updatedFileName = newFileName;
      const deletedFilesIds: string[] = this.files.deleted.map((deletedFile) => deletedFile.id);
      const currentFileNames: string[] = [...this.files.initial, ...this.files.new]
        .filter((file) => !deletedFilesIds.includes(file.id))
        .map((file) => file.name);

      // update filename of new file until it is unique
      let i = 1;
      while (currentFileNames.includes(updatedFileName)) {
        const name = newFileName.substring(0, newFileName.lastIndexOf('.'));
        const extension = newFileName.split('.').pop();
        updatedFileName = `${name}(${i}).${extension}`;
        i++;
      }

      if (updatedFileName !== newFileName) {
        newFileMetaData.name = updatedFileName;
      }

      this.files.new.push(newFileMetaData);
    }

    return this.returnFiles();
  }

  deleteFile(id: string): IFileInfo[] {
    const newFileToDelete = this.files.new.find((metadata) => metadata.id === id);
    if (newFileToDelete) {
      // if file is a new file, file can just be removed and deleted
      this.files.new = this.files.new.filter((metadata) => metadata.id !== id);
      void this.quinoFileStorage.deleteFile(id);
    } else {
      const initialFileToDelete = this.files.initial.find((metadata) => metadata.id === id);
      if (initialFileToDelete) {
        // if file is an old file, file should be deleted on commit
        this.files.deleted.push(initialFileToDelete);
      }
    }

    return this.returnFiles();
  }

  async commitAllFiles(ownerMetaClass: string, ownerPrimaryKey: string, context?: string): Promise<{ fileMetaData: IFileInfo[] }> {
    for (const metadata of this.files.new) {
      try {
        await this.quinoFileStorage.setFileInfo(metadata.id, metadata.name, ownerMetaClass, ownerPrimaryKey, context);
        this.files.initial.push(metadata);
      } catch (e) {
        this.notificationService.notify({
          message: this.translationService.translate('FileUpload.SetFileInfo.Failed', { name: metadata.name, reason: e.message })
        });
      }
    }

    this.files.new = [];

    const deletePromises = this.files.deleted.map((metadata) => {
      void this.quinoFileStorage.deleteFile(metadata.id);
      this.files.initial = this.files.initial.filter((initialMetadata) => initialMetadata.id !== metadata.id);
    });
    await Promise.all(deletePromises);
    this.files.deleted = [];

    return { fileMetaData: this.returnFiles() };
  }

  reset(): IFileInfo[] {
    const deletePromises = this.files.new.map(async (metadata) => this.quinoFileStorage.deleteFile(metadata.id));
    void Promise.all(deletePromises);

    this.files = { initial: this.files.initial, new: [], deleted: [] };
    return this.returnFiles();
  }

  returnFiles(): IFileInfo[] {
    let currentFiles = this.files.initial.filter(
      (initialMetadata) => !this.files.deleted.some((deletedMetadata) => deletedMetadata.id === initialMetadata.id)
    );
    currentFiles = currentFiles.concat(this.files.new);

    return cloneDeep(currentFiles);
  }
}
