import { IFieldError, IMetaElement, isIMetaGroup, IValidationResult } from '@quino/core';
import { IValidationResultService } from './IValidationResultService';
import { injectable } from 'inversify';

/**
 * @deprecated - use the meta element tree directly this kind of operations.
 */
@injectable()
export class ValidationResultService implements IValidationResultService {
  getFieldErrors(elementNode: IMetaElement, { fieldErrors }: IValidationResult): IFieldError[] {
    if (!fieldErrors) {
      return [];
    }

    const elementNames = this.flattenElements(elementNode).map((element) => element.name.toLowerCase());

    const belongsToCurrent = ({ fieldName }: IFieldError) => elementNames.indexOf(fieldName.toLowerCase()) >= 0;

    return fieldErrors.filter(belongsToCurrent);
  }

  /**
   * Returns a list containing elementNode and all its successors
   * @param elementNode the root of the tree
   * @returns the flattened list of nodes in the tree
   */
  private readonly flattenElements = (elementNode: IMetaElement): IMetaElement[] => {
    const flattenedChildren: IMetaElement[] = [];
    if (isIMetaGroup(elementNode)) {
      elementNode.elements.map(this.flattenElements).forEach((x) => flattenedChildren.push(...x));
    }

    return [elementNode].concat(...flattenedChildren);
  };
}
