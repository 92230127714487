export enum DashboardType {
  /** Not editable dashboards which are retrieved from the model */
  System,

  /** Editable dashboards which are stored in the user settings */
  Personal,

  /** Editable dashboards by admins which are stored in the basic settings */
  Shared
}
