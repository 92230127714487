import React, { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { useService } from '../../ioc/hook';
import { IComponentFactory } from '..';
import { ILogger, isIMetaGroup, QuinoCoreServiceSymbols } from '@quino/core';
import { QuinoUIServiceSymbols } from '../../ioc';
import { ObjectBookmark } from '../../bookmarks';
import { Col, Item as ResponsiveItem, Location, ResponsiveBox, Row } from 'devextreme-react/responsive-box';
import { useMetadata } from '../../settings';
import { useOnDependencyChanged } from '../Util/MetaElementHooks';

export function QuinoColumns(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const factory = useService<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const { visible } = useMetadata(props.element, props.bookmark.genericObject);
  useOnDependencyChanged(props.element, props.bookmark);
  if (!visible) {
    return <></>;
  }

  if (isIMetaGroup(props.element)) {
    const elements = props.element.elements;
    const columns = elements.map((child, index) => (
      <ResponsiveItem key={child.name + index}>
        <Location row={0} col={index} key={props.element.name + '_location'} />
        <div
          key={props.element.name + '_div_column'}
          className={
            elements.length < 2
              ? 'quino-columns-single-column'
              : index === 0
              ? 'quino-columns-first-column'
              : index < elements.length - 1
              ? 'quino-columns-middle-column'
              : 'quino-columns-last-column'
          }>
          {isIMetaGroup(child) && child.elements.map((x) => factory.create(x, props.bookmark, props.actions))}
        </div>
      </ResponsiveItem>
    ));

    return (
      <ResponsiveBox key={props.element.name} visible={props.element.elements.length > 0} height={'auto'}>
        <Row key={'row'} ratio={0} />
        {props.element.elements.map((_, index) => (
          <Col key={index} ratio={1} />
        ))}
        {columns}
      </ResponsiveBox>
    );
  }

  logger.logError(`Trying to render columns with a non-group element. Rendering fragment for: [${props.element}]`);

  return <></>;
}
