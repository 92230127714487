import { Factory } from 'react';
import { FrameworkStartupGroup, IApplication } from '../application';
import { QuinoCoreServiceSymbols } from '../ioc';
import { ISession, ISessionEventTarget, Session, SessionEventTarget } from '../session';
import { RequestDecoratorProvider } from './RequestDecoratorProvider';
import { DefaultRequestFactory } from './DefaultRequestFactory';
import { IRequestBuilder } from './IRequestBuilder';
import { RequestBuilder } from './RequestBuilder';
import { StaticRequestDecorator } from './StaticHeaderDecorator';
import { IResponseHandler } from './IResponseHandler';
import { FailedRequestResponseHandler } from './FailedRequestResponseHandler';
import { IRequestDecoratorProvider } from './IRequestDecoratorProvider';
import { IRequestFactory } from './IRequestFactory';
import { ScopeDecorator } from './ScopeDecorator';
import { IScopingService, ScopingServiceSymbol } from '../scoping/IScopingService';
import { IBatchedRequestService, IBatchedRequestServiceSymbol } from './IBatchedRequestService';
import { BatchedRequestService } from './BatchedRequestService';

export const RequestStartupActionIdentifier = Symbol.for('quino_request_decorator_startup_action');

export class RequestModule {
  static use = (application: IApplication): void => {
    application.registerSingle<IRequestDecoratorProvider>(QuinoCoreServiceSymbols.IRequestDecoratorProvider, RequestDecoratorProvider);
    application.registerSingle<IRequestFactory>(QuinoCoreServiceSymbols.IRequestFactory, DefaultRequestFactory);
    application.register<IRequestBuilder>(QuinoCoreServiceSymbols.IRequestBuilder, RequestBuilder);
    application.bind<Factory<Headers>>(QuinoCoreServiceSymbols.HeadersFactory).toAutoFactory<Headers>(QuinoCoreServiceSymbols.Headers);
    application.registerSingle<ISession>(QuinoCoreServiceSymbols.ISession, Session);
    application.registerSingle<ISessionEventTarget>(QuinoCoreServiceSymbols.ISessionEventTarget, SessionEventTarget);
    application.register<IResponseHandler>(QuinoCoreServiceSymbols.IResponseHandler, FailedRequestResponseHandler);
    application.registerSingle<IBatchedRequestService>(IBatchedRequestServiceSymbol, BatchedRequestService);

    application
      .registerStartupAction(RequestStartupActionIdentifier, (app) => {
        const decorator = app.get<IRequestDecoratorProvider>(QuinoCoreServiceSymbols.IRequestDecoratorProvider);
        const scopingService = app.get<IScopingService>(ScopingServiceSymbol);
        decorator.register(new StaticRequestDecorator(), Symbol.for('StaticRequestDecorator'));
        decorator.register(new ScopeDecorator(scopingService), Symbol.for('ScopeDecorator'));
      })
      .moveTo(FrameworkStartupGroup);
  };
}
