export const INotificationConfigurationSymbol = Symbol.for('INotificationConfiguration');

/**
 * Configuration of the application-wide notification system
 */
export interface INotificationConfiguration {
  /**
   * The display duration (in ms) for non-persistent info-bars
   */
  infoBarDisplayTime: number;

  /**
   * The display duration (in ms) for non-persistent toasts
   */
  toastDisplayTime: number;

  /**
   * Whether to show global success messages
   */
  toastShowSuccessMessages: boolean;

  /**
   * Whether to show global info messages
   */
  toastShowInfoMessages: boolean;
}

export const defaultNotificationConfiguration: INotificationConfiguration = {
  infoBarDisplayTime: 4000,
  toastDisplayTime: 4000,
  toastShowSuccessMessages: true,
  toastShowInfoMessages: true
};
