import { IDataDiffer } from './IDataDiffer';
import { injectable } from 'inversify';

@injectable()
export class DataDiffer implements IDataDiffer {
  // noinspection JSMethodCanBeStatic
  getChangedValues<T>(oldValues: T | undefined, newValues: T): Partial<T> {
    const changedValues: Partial<T> = {};

    if (oldValues) {
      // Diff props from oldValues
      for (const prop in oldValues) {
        if (Object.prototype.hasOwnProperty.call(oldValues, prop) && newValues[prop] !== oldValues[prop]) {
          if (DataDiffer.isPrimitiveValue(newValues, prop, oldValues)) {
            changedValues[prop] = newValues[prop];
          }
        }
      }
    }

    // Diff props from newValues (eg. if props in oldValues are undefined)
    for (const prop in newValues) {
      if (Object.prototype.hasOwnProperty.call(newValues, prop) && (!oldValues || newValues[prop] !== oldValues[prop])) {
        if (typeof newValues[prop] !== 'object') {
          changedValues[prop] = newValues[prop];
        }
      }
    }

    return changedValues;
  }

  private static isPrimitiveValue<T>(newValues: T, prop: string, oldValues: T): boolean {
    return (
      (typeof newValues[prop] === 'object' && newValues[prop] === null && typeof oldValues[prop] !== 'object') || typeof newValues[prop] !== 'object'
    );
  }
}
