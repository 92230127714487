import React, { ReactNode, useEffect, useState } from 'react';
import {
  IQuinoMetaPanelActions,
  QuinoCustomFormBox,
  QuinoCustomFormRow,
  QuinoLabeled,
  QuinoLayoutSelector,
  QuinoMetaClassSelector,
  QuinoMetaDataGrid,
  QuinoPopupDefaultContent,
  QuinoPopupToolbar,
  QuinoPopupToolbarButton,
  QuinoTabsContainerCustom,
  TSelectionMode
} from '../../components';
import { QuinoUIServiceSymbols, useService } from '../../ioc';
import { IDashboardTileDataSource, IDashboardTileRegistration } from '../registry';
import { TextBox } from 'devextreme-react/text-box';
import { ILogger, IMetadataTree, ITranslationService, ITranslationServiceSymbol, LayoutType, QuinoCoreServiceSymbols } from '@quino/core';
import { IQuinoTabCustom } from '../../components/QuinoTabsContainer/QuinoTabsContainerCustom';
import { CheckBox } from 'devextreme-react/check-box';
import { inject, injectable } from 'inversify';
import { IBookmarkFactory, IListBookmark } from '../../bookmarks';

export interface IQuinoDashboardGridTileProps {
  caption: string;
  class: string;
  layout?: string;
  showSearch?: boolean;
  showFilter?: boolean;
  showExportButton?: boolean;
}

export const GridTileRegistrationSymbol = Symbol.for('GridTileRegistration');

@injectable()
export class QuinoDashboardGridTileRegistration implements IDashboardTileRegistration<IQuinoDashboardGridTileProps> {
  constructor(
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger
  ) {}

  getCaption(payload: IQuinoDashboardGridTileProps): string {
    return payload.caption;
  }

  render(payload: IQuinoDashboardGridTileProps, dataSource: IListBookmark | undefined): React.ReactNode {
    return <QuinoDashboardGridTile gridProps={payload} source={dataSource} />;
  }

  renderSettings(payload: IQuinoDashboardGridTileProps, apply: (payload: IQuinoDashboardGridTileProps) => void, cancel: () => void): React.ReactNode {
    return <QuinoDashboardGridTileConfiguration gridProps={payload} apply={apply} cancel={cancel} />;
  }

  deserializePayload(payload: any): IQuinoDashboardGridTileProps {
    return payload as IQuinoDashboardGridTileProps;
  }

  serializePayload(payload: IQuinoDashboardGridTileProps): any {
    return payload;
  }

  dataSource: IDashboardTileDataSource<IQuinoDashboardGridTileProps> = new QuinoDashboardGridTileDataSource(
    this.metadataTree,
    this.bookmarkFactory,
    this.logger
  );

  showRefresh = true;
  name = 'Table';
}

class QuinoDashboardGridTileDataSource implements IDashboardTileDataSource<IQuinoDashboardGridTileProps> {
  constructor(metadataTree: IMetadataTree, bookmarkFactory: IBookmarkFactory, logger: ILogger) {
    this.metadataTree = metadataTree;
    this.bookmarkFactory = bookmarkFactory;
    this.logger = logger;
  }

  refreshData(): void {
    this.source?.refresh();
  }

  async updateData(payload: IQuinoDashboardGridTileProps): Promise<IListBookmark | undefined> {
    const relevantPropChanges = !this.oldProps || payload.class !== this.oldProps.class || payload.layout !== this.oldProps.layout;

    if (relevantPropChanges) {
      try {
        const layout = payload.layout ? this.metadataTree.getLayout(payload.class, payload.layout) : undefined;
        const listBookmark = layout
          ? await this.bookmarkFactory.createList(payload.class, layout)
          : await this.bookmarkFactory.createList(payload.class);
        listBookmark.options.storageKey = undefined;
        this.source = listBookmark;
      } catch (e) {
        this.logger.logError(e);
        return undefined;
      }
    }

    this.oldProps = payload;
    return this.source;
  }

  private oldProps: IQuinoDashboardGridTileProps | undefined;
  private source: IListBookmark | undefined = undefined;
  private readonly metadataTree: IMetadataTree;
  private readonly bookmarkFactory: IBookmarkFactory;
  private readonly logger: ILogger;
}

function QuinoDashboardGridTile(props: { gridProps: IQuinoDashboardGridTileProps; source: IListBookmark | undefined }) {
  const actions = useService<IQuinoMetaPanelActions>(QuinoUIServiceSymbols.IQuinoMetaPanelActions);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  const defaultGridProperties = {
    useColumnChooser: false,
    useExport: false,
    useGrouping: false,
    useHeaderFilter: false,
    useFilterPanel: false,
    useDrillDownColumn: true,
    showDelete: false,
    selectionMode: 'None' as TSelectionMode
  };

  return (
    <div className={'quino-dashboard-tile-grid'}>
      {props.source && (
        <QuinoMetaDataGrid
          drilldown={(target) => {
            actions.drilldown(target).catch((e) => logger.logError(e));
          }}
          visible={true}
          bookmark={props.source}
          gridProperties={{
            ...defaultGridProperties,
            useHeaderFilter: props.gridProps.showFilter !== undefined ? props.gridProps.showFilter : false,
            useSearch: props.gridProps.showSearch !== undefined ? props.gridProps.showSearch : false,
            useExport: props.gridProps.showExportButton !== undefined ? props.gridProps.showExportButton : false
          }}
        />
      )}
    </div>
  );
}

function QuinoDashboardGridTileConfiguration(props: {
  gridProps: IQuinoDashboardGridTileProps;
  apply: (payload: IQuinoDashboardGridTileProps) => void;
  cancel: () => void;
}) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);

  const [gridProperties, setGridProperties] = useState<IQuinoDashboardGridTileProps>(props.gridProps);

  const tabs: IQuinoTabCustom[] = [
    {
      id: 0,
      title: translationService.translate('Dashboard.Tile.EditDialog.BasicSettings'),
      node: () => basicSettings()
    },
    {
      id: 1,
      title: translationService.translate('Dashboard.Tile.EditDialog.AdvancedSettings'),
      node: () => gridOptions()
    }
  ];

  const standardCheckbox = (property: string, defaultValue: boolean) => {
    return (
      <CheckBox
        value={gridProperties[property] !== undefined ? gridProperties[property] : defaultValue}
        className={'quino-dashboard-checkbox'}
        onValueChanged={(value) => {
          setGridProperties({ ...gridProperties, [property]: value.value });
        }}
      />
    );
  };

  const basicSettings = (): ReactNode => (
    <QuinoCustomFormBox>
      <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.TileTitle')} required={true}>
        <TextBox
          value={gridProperties.caption}
          onValueChanged={(value) => {
            setGridProperties({ ...gridProperties, caption: value.value });
          }}
        />
      </QuinoLabeled>
      <QuinoLabeled
        description={translationService.translate('Dashboard.Tile.EditDialog.TileEntity.Description')}
        label={translationService.translate('Dashboard.Tile.EditDialog.TileEntity')}
        required={true}>
        <QuinoMetaClassSelector
          hideMetaClassesWithoutListLayouts={true}
          value={gridProperties.class}
          onSelect={(metaClass) => {
            setGridProperties({ ...gridProperties, class: metaClass?.name || '', layout: '' });
          }}
        />
      </QuinoLabeled>
      <QuinoLabeled
        visible={
          (gridProperties.class && metadataTree.getLayouts(gridProperties.class).filter((x) => x.type === LayoutType.List).length > 1) || false
        }
        label={translationService.translate('Dashboard.Tile.EditDialog.TileLayout')}>
        <QuinoLayoutSelector
          metaClass={gridProperties.class}
          layoutType={LayoutType.List}
          value={gridProperties.layout}
          onSelect={(layoutName) => {
            setGridProperties({ ...gridProperties, layout: layoutName });
          }}
        />
      </QuinoLabeled>
    </QuinoCustomFormBox>
  );

  const gridOptions = (): ReactNode => (
    <QuinoCustomFormBox>
      <QuinoCustomFormRow columns={2}>
        <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.UseSearchField')}>
          {standardCheckbox('showSearch', false)}
        </QuinoLabeled>
        <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.UseSimpleFiltering')}>
          {standardCheckbox('showFilter', false)}
        </QuinoLabeled>
        <QuinoLabeled label={translationService.translate('Dashboard.Tile.EditDialog.UseExportButton')}>
          {standardCheckbox('showExportButton', false)}
        </QuinoLabeled>
      </QuinoCustomFormRow>
    </QuinoCustomFormBox>
  );

  useEffect(() => {
    setGridProperties({ ...props.gridProps });
  }, [props.gridProps]);

  return (
    <>
      <QuinoPopupDefaultContent isScrollable={false}>
        <QuinoTabsContainerCustom tabs={tabs} />
      </QuinoPopupDefaultContent>
      <QuinoPopupToolbar
        showTopBorder={true}
        rightItems={[
          <QuinoPopupToolbarButton
            text={translationService.translate('Cancel')}
            icon={'material-icons-outlined clear'}
            onClick={() => {
              props.cancel();
              setGridProperties(props.gridProps);
            }}
          />,
          <QuinoPopupToolbarButton
            text={translationService.translate('Apply')}
            icon={'material-icons-outlined check'}
            isPrimary={true}
            onClick={() => props.apply(gridProperties)}
            disabled={!gridProperties.class || !gridProperties.caption}
          />
        ]}
      />
    </>
  );
}
