import * as React from 'react';
import { QuinoErrorSummary, QuinoLoadButton, useService } from '@quino/ui';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';

interface ICUIPasswordResetCompletedComponentProps {
  onPerformConfirmAction: () => void;
  goToLogin: (() => void) | undefined;
  title: string;
  description: string;
  buttonText: string;
  buttonIsDefault: boolean;
  hintText: JSX.Element;
  icon: string;
  loading: boolean;
  error?: string[];
}

export function CUIConfirmationDialogComponent(props: React.PropsWithChildren<ICUIPasswordResetCompletedComponentProps>) {
  const { onPerformConfirmAction, goToLogin, title, description, buttonText, buttonIsDefault, hintText, icon, loading, error } = props;
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  return (
    <>
      <h3 className={'quino-auth-title'}>{title}</h3>
      <form className={'quino-auth-form-content'}>
        {description}
        <QuinoErrorSummary errorMessages={error} topPadding />
        <div className={'quino-auth-footer'}>
          <QuinoLoadButton
            text={buttonText}
            icon={icon}
            type='default'
            stylingMode={buttonIsDefault ? 'contained' : 'outlined'}
            className={'quino-auth-button'}
            onClick={onPerformConfirmAction}
            loading={loading}
          />
          {hintText}
          {goToLogin && (
            <p className={'quino-auth-hint-text'}>
              <a href='#' onClick={goToLogin}>
                {translationService.translate('QuinoAuthenticationFeedback.ButtonGotoLogin')}
              </a>
            </p>
          )}
        </div>
      </form>
    </>
  );
}
