import { ConsoleLogger, ILogger } from '../logging';
import { IApplication } from '../application';
import { QuinoCoreServiceSymbols } from '../ioc';
import { DEFAULT_SERVER_BASEURL, IQuinoServerServiceConfiguration, IUrlManager, ODataUrlBuilder, ODataUrlBuilderSymbol } from './QuinoServer';
import { ExportFileModule } from './export';
import { FormatStringService, IFormatStringService } from './formatting';
import { IActionExecutor, IActionExecutorSymbol } from './IActionExecutor';
import { RemoteActionExecutor } from './RemoteActionExecutor';
import {
  IMaskFormatService,
  IMaskFormatServiceSymbol,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  IStringMaskingService,
  IStringMaskingServiceSymbol,
  IValueFormatter,
  IValueFormatterSymbol,
  MaskFormatService,
  MetaPropertyValueService,
  StringMaskingService,
  ValueFormatter
} from '../data';
import { QuinoUrlManager } from './QuinoServer/QuinoUrlManager';
import { IUrlHelper } from './IUrlHelper';
import { UrlHelper } from './UrlHelper';
import { VersionModule } from '../versionInfo';
import { IMessenger, IMessengerSymbol, Messenger } from '../core';
import { GlobalSearchService, IGlobalSearchService, IGlobalSearchServiceSymbol } from './search';

export class ApiModule {
  static use = (application: IApplication): IApplication => {
    // TODO: refactor the configuration API. Move this to a Configuration Module.
    application.registerConstant<IQuinoServerServiceConfiguration>(QuinoCoreServiceSymbols.IQuinoServerServiceConfiguration, {
      baseUrl: DEFAULT_SERVER_BASEURL
    });

    application.registerSingle<IMessenger>(IMessengerSymbol, Messenger);
    application.registerSingle<IUrlHelper>(QuinoCoreServiceSymbols.IUrlHelper, UrlHelper);
    application.registerSingle<IUrlManager>(QuinoCoreServiceSymbols.IUrlManager, QuinoUrlManager);
    application.registerSingle<IFormatStringService>(QuinoCoreServiceSymbols.IFormatStringService, FormatStringService);
    application.registerSingle<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol, MetaPropertyValueService);
    application.registerSingle<ILogger>(QuinoCoreServiceSymbols.ILogger, ConsoleLogger);
    application.registerSingle<IActionExecutor>(IActionExecutorSymbol, RemoteActionExecutor);
    application.registerSingle<ODataUrlBuilder>(ODataUrlBuilderSymbol, ODataUrlBuilder);
    application.registerSingle<IGlobalSearchService>(IGlobalSearchServiceSymbol, GlobalSearchService);
    application.registerSingle<IMaskFormatService>(IMaskFormatServiceSymbol, MaskFormatService);
    application.registerSingle<IStringMaskingService>(IStringMaskingServiceSymbol, StringMaskingService);
    application.registerSingle<IValueFormatter>(IValueFormatterSymbol, ValueFormatter);

    VersionModule.use(application);
    ExportFileModule.use(application);
    return application;
  };
}
