import * as React from 'react';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import {
  IGenericObject,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols,
  INotificationService,
  INotificationServiceSymbol
} from '@quino/core';
import { IQuinoMetaPanelActions } from '../Types';
import { QuinoViewSwitcher } from '../QuinoViewSwitcher';
import { IBookmark, isILayoutAwareBookmark } from '../../bookmarks';
import { QuinoUIServiceSymbols, useService } from '../../ioc';
import { useRerender } from '../Util';
import { QuinoErrorBoundary } from './QuinoErrorBoundary';

export interface IQuinoMetaPanelProps {
  bookmark: IBookmark;
}

export interface IQuinoMetaPanelOptionalProps {
  visible: boolean;
  actions: IQuinoMetaPanelActions;
  context?: any;

  /**
   * @deprecated - don't use this anymore. Instead configure it with the service/settings.
   */
  disableDrillDown: boolean;
}

export interface IQuinoMetaPanelExtensionProps extends Partial<IQuinoMetaPanelProps> {
  position: 'Footer' | 'Header';
  updateMetaPanel?: (bookmark: IBookmark) => void;
  actions?: IQuinoMetaPanelActions;
  context?: any;
}

export function QuinoMetaPanel(props: PropsWithChildren<IQuinoMetaPanelProps & Partial<IQuinoMetaPanelOptionalProps>>) {
  const metaPanelActions = useService<IQuinoMetaPanelActions>(QuinoUIServiceSymbols.IQuinoMetaPanelActions);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const notificationService = useService<INotificationService>(INotificationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);

  const rerender = useRerender();

  const visible = props.visible != null ? props.visible : true;
  const actions = props.actions != null ? props.actions : metaPanelActions;
  const disableDrillDown = props.disableDrillDown != null ? props.disableDrillDown : false;
  const key = isILayoutAwareBookmark(props.bookmark) ? props.bookmark.layout.name + '_viewSwitcher' : 'ViewSwitcher';

  const onDrilldown = useCallback(
    async (target: IGenericObject, context?: any): Promise<IBookmark> => {
      const calcContext = context ? context : props.context != null ? props.context : {};
      const unload = loadingFeedback.load();

      try {
        return await actions.drilldown(target, calcContext);
      } catch (e) {
        logger.logError(e);
        return await Promise.reject(e);
      } finally {
        unload();
      }
    },
    [actions, loadingFeedback, logger, props.context]
  );

  const additionalData = useMemo(() => {
    const headers: React.ReactNode[] = [];
    const footers: React.ReactNode[] = [];
    React.Children.forEach(props.children, (child, index) => {
      const element = child as React.ReactElement;
      const clonedElement = React.cloneElement(element, {
        bookmark: props.bookmark,
        updateMetaPanel: () => {
          rerender();
        },
        actions: actions,
        key: index
      });
      if (element.props.position === 'Header') {
        headers.push(clonedElement);
      } else {
        footers.push(clonedElement);
      }
    });

    return { headers, footers };
  }, [actions, props.bookmark, props.children, rerender]);

  const viewSwitcherActions = useMemo(() => {
    return { ...actions, drilldown: onDrilldown };
  }, [actions, onDrilldown]);

  if (!visible) {
    return <div />;
  }

  return (
    <QuinoErrorBoundary logger={logger} translationService={translationService} notificationService={notificationService}>
      {additionalData.headers}
      <QuinoViewSwitcher
        {...props}
        disableDrillDown={disableDrillDown}
        bookmark={props.bookmark}
        actions={viewSwitcherActions}
        visible={props.visible == null || props.visible}
        key={key}
      />
      {additionalData.footers}
    </QuinoErrorBoundary>
  );
}
