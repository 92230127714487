import React, { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { QuinoMetaClassSelector } from './QuinoMetaClassSelector';
import { useMetaPropertyValueState, useValidation } from '../Util';
import { IMetaProperty, getMetaProperty } from '@quino/core';
import { QuinoLabeled } from '../QuinoLabeled';
import { useLabelSettings, useMetadata } from '../../settings';

export function MetaClassSelector(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const [value, setValue] = useMetaPropertyValueState<string>(props.element as IMetaProperty, props.bookmark);

  const { element, bookmark } = props;
  const metaProperty = getMetaProperty(element);
  const { description, caption, instruction } = metaProperty;

  const labelSettings = useLabelSettings(element);
  const { visible, required } = useMetadata(metaProperty, bookmark.genericObject);

  const [isValid, errorMessages] = useValidation(bookmark, metaProperty);

  return (
    <QuinoLabeled
      visible={visible}
      label={caption}
      required={required}
      description={description}
      instruction={instruction}
      errorMessages={(!isValid && errorMessages) || undefined}
      labelSettings={labelSettings}>
      <QuinoMetaClassSelector value={value} onSelect={(metaClass) => setValue(metaClass.name)} />
    </QuinoLabeled>
  );
}
