import { IApplication } from '../application';
import { QuinoCoreServiceSymbols } from '../ioc';
import { QuinoDataService } from './QuinoServer';
import { IDataService } from './IDataService';
import { MetadataTree } from './MetadataTree';
import { IMetadataTree, IPublicMetaDataTree } from './IMetadataTree';
import { IGenericObjectFactory, IGenericObjectFactorySymbol } from '../data';
import { FrameworkStartupGroup } from '../application/ExecutionGroup';
import { TitleCalculator } from '../data/TitleCalculator';
import { ITitleCalculator, ITitleCalculatorSymbol } from '../data/ITitleCalculator';
import { GenericObjectFactory } from '../data/GenericObjectFactory';
import { IMetaVisitor, IMetaVisitorSymbol, MetaVisitor } from '../meta';
import { IMetaElementFactory, MetaElementFactory } from './meta';
import { IModelFetcher, IModelFetcherSymbol, IPublicModelFetcher, IPublicModelFetcherSymbol } from './IModelFetcher';
import { ModelFetcher } from './ModelFetcher';
import { PublicModelFetcher } from './PublicModelFetcher';
import { IPublicMetadataTreeSymbol, PublicMetadataTree } from './PublicMetadataTree';

/**
 * Identifier for the custom load public model group
 */
export const LoadPublicModelGroup = Symbol.for('LoadPublicModelGroup');

/**
 * Identifiers for the startup action that loads the metadata from the server.
 */
export const MetadataStartupActionIdentifier = Symbol.for('quino_metadata_startup_action');
export const PublicMetadataStartupActionIdentifier = Symbol.for('quino_public_metadata_startup_action');

export class MetadataModule {
  static use = (application: IApplication): IApplication => {
    application.registerSingle<IGenericObjectFactory>(IGenericObjectFactorySymbol, GenericObjectFactory);
    application.registerSingle<ITitleCalculator>(ITitleCalculatorSymbol, TitleCalculator);
    application.registerSingle<IDataService>(QuinoCoreServiceSymbols.IDataService, QuinoDataService);
    application.registerSingle<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree, MetadataTree);
    application.registerSingle<IModelFetcher>(IModelFetcherSymbol, ModelFetcher);
    application.registerSingle<IMetaElementFactory>(QuinoCoreServiceSymbols.IMetaElementFactory, MetaElementFactory);
    application.registerSingle<IMetaVisitor>(IMetaVisitorSymbol, MetaVisitor);
    application
      .registerStartupAction(MetadataStartupActionIdentifier, async (app) => {
        await app.get<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree).initialize();
      })
      .moveTo(FrameworkStartupGroup);

    application.registerSingle<IPublicModelFetcher>(IPublicModelFetcherSymbol, PublicModelFetcher);
    application.registerSingle<IPublicMetaDataTree>(IPublicMetadataTreeSymbol, PublicMetadataTree);
    application.registerStartupGroup(LoadPublicModelGroup).before(FrameworkStartupGroup);
    application
      .registerStartupAction(PublicMetadataStartupActionIdentifier, async (app) => {
        await app.get<IPublicMetaDataTree>(IPublicMetadataTreeSymbol).initialize();
      })
      .moveTo(LoadPublicModelGroup);

    return application;
  };
}
