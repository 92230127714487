export const IGlobalSearchServiceSymbol = Symbol.for('IGlobalSearchService');

export interface IGlobalSearchResultDTO {
  primaryKey: string;
  metaClassName: string;
  highlightedCaption: string;
  highlights?: IGlobalSearchHighlightDTO[];
}

export interface IGlobalSearchHighlightDTO {
  propertyName: string;
  highlightedValue: string;
}

export interface IGlobalSearchInfoDTO {
  metaClassName: string;
}

export interface IGlobalSearchService {
  search(searchString: string, metaClassName: string | undefined, numberOfSearchResults: number | undefined): Promise<IGlobalSearchResultDTO[]>;
  info(): Promise<IGlobalSearchInfoDTO[]>;
  recreateIndex(): Promise<void>;
  updateIndex(): Promise<void>;
}
