import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IAuthenticationService } from '../authentication';
import { IUserInfoService } from './IUserInfoService';

@injectable()
export class UserInfoService implements IUserInfoService {
  constructor(@inject(QuinoCoreServiceSymbols.IAuthenticationService) private readonly authService: IAuthenticationService) {}

  isInRole = (role: string): boolean => {
    const info = this.authService.getUserInfo();
    if (info != null && info.role != null) {
      if (typeof info.role === 'string') {
        return role.toUpperCase() === info.role.toUpperCase();
      } else if (info.role.find != null) {
        return info.role.find((x: string) => x.toUpperCase() === role.toUpperCase()) != null;
      }
    }

    return false;
  };
}
