import { IQuinoExcelCellBuilder } from './IQuinoExcelCellBuilder';
import { IQuinoExcelCell, QuinoExcelCell } from '../model';
import { injectable } from 'inversify';
import { IExportExcelSettings } from '../settings';

@injectable()
export class QuinoExcelCellBuilder implements IQuinoExcelCellBuilder {
  build(): IQuinoExcelCell {
    return new QuinoExcelCell(this.textWrapped, this.cellValue, this.format, this.fontName, this.fontSize, this.textIsBold, this.marginRight);
  }

  withFontName(fontName: string): IQuinoExcelCellBuilder {
    this.fontName = fontName;
    return this;
  }

  withFontSize(fontSize: number): IQuinoExcelCellBuilder {
    this.fontSize = fontSize;
    return this;
  }

  setIsBold(isBold: boolean): IQuinoExcelCellBuilder {
    this.textIsBold = isBold;
    return this;
  }

  setIsWrapText(wrapText: boolean): IQuinoExcelCellBuilder {
    this.textWrapped = wrapText;
    return this;
  }

  withMarginRight(marginRight: number): IQuinoExcelCellBuilder {
    this.marginRight = marginRight;
    return this;
  }

  withValue(value: string | number | Date): IQuinoExcelCellBuilder {
    this.cellValue = value;
    return this;
  }

  clear(defaultSettings: IExportExcelSettings): IQuinoExcelCellBuilder {
    return this.withDefaultSettings(defaultSettings);
  }

  withDefaultSettings(defaultSettings: IExportExcelSettings): IQuinoExcelCellBuilder {
    this.textWrapped = defaultSettings.wrapText;
    this.cellValue = '';
    this.fontName = defaultSettings.fontName;
    this.fontSize = defaultSettings.fontSize;
    this.marginRight = 0;
    this.textIsBold = false;
    return this;
  }

  withFormat(format: string | undefined): IQuinoExcelCellBuilder {
    this.format = format;
    return this;
  }

  private textWrapped = false;
  private cellValue: string | number | Date = '';
  private format: string | undefined = undefined;
  private fontName: string;
  private fontSize: number;
  private textIsBold: boolean;
  private marginRight: number;
}
