import { IMetaClass } from '@quino/core';
import { IBookmark, isIBookmark } from './IBookmark';

/**
 * A bookmark which is aware of a meta class.
 */
export interface IMetaClassAwareBookmark extends IBookmark {
  /**
   * The meta class to use with this bookmark.
   */
  metaClass: IMetaClass;
}

/**
 * Whether the given object is a IListBookmark.
 */
export const isIMetaClassAwareBookmark = (object: any): object is IMetaClassAwareBookmark => {
  return isIBookmark(object) && (object as IMetaClassAwareBookmark).metaClass !== undefined;
};
