(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@quino/core"), require("@quino/ui"), require("devextreme-react/button"), require("devextreme-react/context-menu"), require("devextreme-react/drop-down-box"), require("devextreme-react/list"), require("devextreme-react/load-indicator"), require("devextreme-react/load-panel"), require("devextreme-react/popover"), require("devextreme-react/resizable"), require("devextreme-react/responsive-box"), require("devextreme-react/scroll-view"), require("devextreme-react/select-box"), require("devextreme-react/tab-panel"), require("devextreme-react/text-box"), require("devextreme-react/tree-view"), require("devextreme/core/guid"), require("devextreme/data/data_source"), require("devextreme/events"), require("inversify"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["@quino/core", "@quino/ui", "devextreme-react/button", "devextreme-react/context-menu", "devextreme-react/drop-down-box", "devextreme-react/list", "devextreme-react/load-indicator", "devextreme-react/load-panel", "devextreme-react/popover", "devextreme-react/resizable", "devextreme-react/responsive-box", "devextreme-react/scroll-view", "devextreme-react/select-box", "devextreme-react/tab-panel", "devextreme-react/text-box", "devextreme-react/tree-view", "devextreme/core/guid", "devextreme/data/data_source", "devextreme/events", "inversify", "react"], factory);
	else if(typeof exports === 'object')
		exports["@quino/cui"] = factory(require("@quino/core"), require("@quino/ui"), require("devextreme-react/button"), require("devextreme-react/context-menu"), require("devextreme-react/drop-down-box"), require("devextreme-react/list"), require("devextreme-react/load-indicator"), require("devextreme-react/load-panel"), require("devextreme-react/popover"), require("devextreme-react/resizable"), require("devextreme-react/responsive-box"), require("devextreme-react/scroll-view"), require("devextreme-react/select-box"), require("devextreme-react/tab-panel"), require("devextreme-react/text-box"), require("devextreme-react/tree-view"), require("devextreme/core/guid"), require("devextreme/data/data_source"), require("devextreme/events"), require("inversify"), require("react"));
	else
		root["@quino/cui"] = factory(root["@quino/core"], root["@quino/ui"], root["devextreme-react/button"], root["devextreme-react/context-menu"], root["devextreme-react/drop-down-box"], root["devextreme-react/list"], root["devextreme-react/load-indicator"], root["devextreme-react/load-panel"], root["devextreme-react/popover"], root["devextreme-react/resizable"], root["devextreme-react/responsive-box"], root["devextreme-react/scroll-view"], root["devextreme-react/select-box"], root["devextreme-react/tab-panel"], root["devextreme-react/text-box"], root["devextreme-react/tree-view"], root["devextreme/core/guid"], root["devextreme/data/data_source"], root["devextreme/events"], root["inversify"], root["react"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__9651__, __WEBPACK_EXTERNAL_MODULE__5404__, __WEBPACK_EXTERNAL_MODULE__5328__, __WEBPACK_EXTERNAL_MODULE__1396__, __WEBPACK_EXTERNAL_MODULE__7634__, __WEBPACK_EXTERNAL_MODULE__3935__, __WEBPACK_EXTERNAL_MODULE__4026__, __WEBPACK_EXTERNAL_MODULE__7128__, __WEBPACK_EXTERNAL_MODULE__6544__, __WEBPACK_EXTERNAL_MODULE__8086__, __WEBPACK_EXTERNAL_MODULE__3125__, __WEBPACK_EXTERNAL_MODULE__5481__, __WEBPACK_EXTERNAL_MODULE__6565__, __WEBPACK_EXTERNAL_MODULE__7290__, __WEBPACK_EXTERNAL_MODULE__2619__, __WEBPACK_EXTERNAL_MODULE__8332__, __WEBPACK_EXTERNAL_MODULE__759__, __WEBPACK_EXTERNAL_MODULE__367__, __WEBPACK_EXTERNAL_MODULE__5824__, __WEBPACK_EXTERNAL_MODULE__2710__, __WEBPACK_EXTERNAL_MODULE__8156__) => {
return 