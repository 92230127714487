import React, { useCallback, useMemo, useState } from 'react';
import {
  ILogger,
  IStorageService,
  ITranslationService,
  ITranslationServiceSymbol,
  LookupNormalizer,
  QuinoCoreServiceSymbols,
  PersistentStorageServiceSymbol,
  IMetadataTree,
  IVisibleCalculator
} from '@quino/core';
import {
  QuinoPopup,
  QuinoPopupToolbar,
  QuinoPopupToolbarButton,
  useOnMount,
  useService,
  QuinoPopupDefaultContent,
  IMarkdownConverterSymbol,
  IMarkdownConverter
} from '@quino/ui';
import { useCuiSettings } from '../../util';

export function CUIDisclaimerPopup() {
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const settingsStorage = useService<IStorageService>(PersistentStorageServiceSymbol);
  const markdownConverter = useService<IMarkdownConverter>(IMarkdownConverterSymbol);
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const visibleCalculator = useService<IVisibleCalculator>(QuinoCoreServiceSymbols.IVisibleCalculator);

  const cuiSettings = useCuiSettings();
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const settingsKey = useMemo(() => LookupNormalizer.Normalize('DisclaimerConfirmed'), []);

  const onPopupClose = useCallback(() => {
    setPopupVisible(false);
    settingsStorage.set(settingsKey, true).catch(logger.logError);
  }, [logger, settingsKey, settingsStorage]);

  useOnMount(() => {
    if (cuiSettings.enableDisclaimerPopup) {
      const disclaimerPopupLayout = metadataTree.model.layouts.find((l) => l.name.toLowerCase() === 'disclaimerpopup');
      const showDisclaimerPopup =
        !disclaimerPopupLayout || disclaimerPopupLayout.visible == null || visibleCalculator.calculate(disclaimerPopupLayout, {});

      showDisclaimerPopup &&
        settingsStorage
          .get(settingsKey)
          .then((settingsValue) => {
            const disclaimerConfirmed = settingsValue === true || (typeof settingsValue === 'string' && settingsValue.toLowerCase() === 'true');
            setPopupVisible(!disclaimerConfirmed);
          })
          .catch(logger.logError);
    }
  });

  return (
    <>
      {popupVisible && (
        <QuinoPopup title={translationService.translate('DisclaimerPopup.Title')} visible={popupVisible} width={640}>
          <QuinoPopupDefaultContent isScrollable={true} hasPaddingHorizontal={true}>
            <div
              className={'quino-ecui-disclaimer-popup-content'}
              dangerouslySetInnerHTML={{ __html: markdownConverter.convertToHtml(translationService.translate('DisclaimerPopup.MarkdownContent')) }}
            />
          </QuinoPopupDefaultContent>
          <QuinoPopupToolbar
            rightItems={[
              <QuinoPopupToolbarButton text={translationService.translate('DisclaimerPopup.ButtonCaption')} onClick={onPopupClose} isPrimary={true} />
            ]}
          />
        </QuinoPopup>
      )}
    </>
  );
}
