import { ErrorType, IErrorHandlingService } from './IErrorHandlingService';
import { inject, injectable } from 'inversify';
import { INotification, INotificationService, INotificationServiceSymbol } from '../notification';
import { ITranslationService, ITranslationServiceSymbol } from '../lang';
import { IErrorMapper } from './mappers/IErrorMapper';
import { DefaultErrorMapper, UnclassifiedServerErrorMapper } from './mappers';

@injectable()
export class ErrorHandlingService implements IErrorHandlingService {
  constructor(
    @inject(INotificationServiceSymbol) private readonly notificationsService: INotificationService,
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService
  ) {}

  register(instance: IErrorMapper): void {
    this.instances = this.instances.filter((value) => value.errorName !== instance.errorName);
    this.instances.push(instance);
  }

  getErrorName(error: Error, errorType: ErrorType = ErrorType.Client): string {
    const handler = this.findHandler(error, errorType);
    return handler.getErrorName(error);
  }

  getErrorMessage(error: Error, errorType: ErrorType = ErrorType.Client): string {
    const handler = this.findHandler(error, errorType);
    return handler.getErrorMessage(error);
  }

  getErrorNotification(error: Error, symbol?: Symbol, errorType?: ErrorType): INotification {
    return {
      ...this.findHandler(error, errorType ?? ErrorType.Client).formatError(error),
      type: 'error',
      symbol: symbol
    };
  }

  notifyError(error: Error, area: 'global' | 'default' | 'custom' = 'default', symbol?: Symbol, errorType?: ErrorType): void {
    console.error(error);
    this.notificationsService.notify({
      ...this.getErrorNotification(error, symbol, errorType),
      area: area
    });
  }

  private findHandler(error: Error, errorType: ErrorType): IErrorMapper {
    const handlers = this.instances.filter((x) => error.name.startsWith(x.errorName) || x.canHandleError(error));

    if (handlers.length === 0) {
      if (errorType != undefined && errorType === ErrorType.Server) {
        return new UnclassifiedServerErrorMapper(this.translationService);
      }
      return new DefaultErrorMapper(this.translationService);
    } else {
      return handlers.sort((a, b) => b.errorName.length - a.errorName.length)[0];
    }
  }

  private instances: IErrorMapper[] = [];
}
