/**
 * A client configuration object.
 */
export interface IClientConfiguration {
  key: string;
  value: any;
}

/**
 * Service symbol for the configuration service.
 */
export const IConfigurationServiceSymbol = Symbol.for('IConfigurationService');

/**
 * Configuration service to fetch client side configurations.
 */
export interface IConfigurationService {
  /**
   * Get the configuration node.
   * @param key - the key. Can be delimited with ':' or '.'
   */
  get(key: string): IClientConfiguration | undefined;

  /**
   * Get a collection of configuration entries with the given prefix.
   * @param prefix - the prefix to use.
   */
  getByPrefix(prefix: string): IClientConfiguration[];

  /**
   * Overlay the configuration values onto the provided object.
   * Currently does not support nesting.
   * @param defaults - the defaults to apply.
   * @param prefix - the prefix to use.
   */
  apply(defaults: any, prefix: string): any;

  /**
   * Initialize the configuration. Usually this happens with a call to the backend.
   */
  initialize(): Promise<void>;
}
