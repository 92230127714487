import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { ILogger, ITranslationService, ITranslationServiceSymbol, QuinoCoreServiceSymbols } from '@quino/core';
import { useService } from '../../ioc';
import { QuinoPopup } from '../../components/QuinoPopup';
import { IQuinoDashboardItem } from '../settings';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { IFeedbackService, IFeedbackServiceSymbol } from '../../feedback';

export interface IQuinoDashboardTileProps extends IQuinoDashboardItem {
  deleteTile: () => void;
  savePayload: (payload: any) => void;
  editable: boolean;
  currentWidth: number;
  currentHeight: number;
}

/**
 * Base tile component wrapping the content of all tile types
 */
export function QuinoDashboardTile(props: IQuinoDashboardTileProps) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const feedbackService = useService<IFeedbackService>(IFeedbackServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const [dataLoadIndicator, setDataLoadIndicator] = useState<boolean>(true);
  const [editDialog, setEditDialog] = useState<boolean>(false);
  const [zoomPopup, setZoomPopup] = useState<boolean>(false);
  const [rerenderZoomedContent, setRerenderZoomedContent] = useState<boolean>(false);
  const [currentTileSize, setCurrentTileSize] = useState<{ w: number; h: number }>({
    w: props.position.w!,
    h: props.position.h!
  });
  const [tileProps, setTileProps] = useState<{ tileContent: any; dataSource: any }>({
    tileContent: undefined,
    dataSource: undefined
  });

  const renderedTileContent = useMemo(
    () => (tileProps.tileContent ? props.type.render(tileProps.tileContent, tileProps.dataSource) : <></>),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tileProps, currentTileSize, rerenderZoomedContent]
  );

  const tileTitle = useMemo(() => (tileProps.tileContent ? props.type.getCaption(tileProps.tileContent) : ''), [props.type, tileProps.tileContent]);

  const renderedSettingsContent = useMemo(
    () =>
      tileProps.tileContent ? (
        props.type.renderSettings(
          tileProps.tileContent,
          (data) => {
            setEditDialog(false);
            props.savePayload(data);
          },
          () => setEditDialog(false)
        )
      ) : (
        <></>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tileProps.tileContent]
  );

  useEffect(() => {
    let dismissed = false;
    if (JSON.stringify(props.payload) !== JSON.stringify(tileProps.tileContent)) {
      !editDialog && setDataLoadIndicator(true);
      props.type.dataSource
        .updateData(props.payload)
        .then((result: any) => !dismissed && setTileProps({ tileContent: props.payload, dataSource: result }))
        .finally(() => !dismissed && setDataLoadIndicator(false));
    }

    return () => {
      dismissed = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.payload]);

  useEffect(() => {
    if ((props.currentWidth && props.currentWidth !== currentTileSize.w) || (props.currentHeight && props.currentHeight !== currentTileSize.h)) {
      setCurrentTileSize({ w: props.currentWidth, h: props.currentHeight });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentWidth, props.currentHeight]);

  return (
    <>
      <div className={'grid-stack-item-content quino-dashboard-tile'}>
        <div className='quino-dashboard-tile-title-wrapper'>
          <div className='quino-dashboard-tile-title'>{translationService.translate(tileTitle)}</div>
          <div className='quino-dashboard-tile-options'>
            <Button
              onClick={() => props.type.dataSource.refreshData()}
              stylingMode={'text'}
              hint={translationService.translate('Dropdown.RefreshContent')}
              icon={'material-icons-outlined autorenew'}
              visible={props.type.showRefresh}
            />
            <Button
              stylingMode={'text'}
              icon={'material-icons-outlined zoom_out_map'}
              hint={translationService.translate('Dashboard.Tile.EnlargeButton.Hint')}
              onClick={() => setZoomPopup(true)}
            />
          </div>
        </div>
        <div className='quino-dashboard-tile-content-wrapper'>
          <LoadIndicator visible={dataLoadIndicator} />
          {tileProps.dataSource && !zoomPopup && renderedTileContent}
        </div>
        {props.editable && (
          <div className='quino-dashboard-tile-editable-overlay'>
            <span>{translationService.translate('Dashboard.Tile.EditMode.RepositionInfo')}</span>
            <span>{translationService.translate('Dashboard.Tile.EditMode.ResizeInfo')}</span>
          </div>
        )}
      </div>

      {props.editable && (
        <div className='quino-dashboard-tile-edit-tools-wrapper'>
          <div className='quino-dashboard-tile-edit-tools'>
            <Button
              stylingMode={'text'}
              icon={'material-icons-outlined tune'}
              hint={translationService.translate('Dashboard.Tile.EditButton.Hint')}
              onClick={() => setEditDialog(true)}
            />
            <Button
              stylingMode={'text'}
              icon={'material-icons-outlined delete'}
              hint={translationService.translate('Delete')}
              onClick={() => {
                feedbackService
                  .getConfirmation(
                    translationService.translate('Dashboard.Tile.DeleteDialog.Title'),
                    translationService.translate('Dashboard.Tile.DeleteDialog.Text'),
                    translationService.translate('Delete'),
                    'material-icons-outlined delete'
                  )
                  .then((confirmed) => {
                    if (confirmed) {
                      props.deleteTile();
                    }
                  })
                  .catch((e) => logger.logError(e));
              }}
            />
          </div>
        </div>
      )}

      <QuinoPopup
        visible={zoomPopup}
        showCloseButton={true}
        closeOnOutsideClick={true}
        height={'90vh'}
        width={'90vw'}
        title={translationService.translate(tileTitle)}
        onHiding={() => setZoomPopup(false)}
        onShown={() => setRerenderZoomedContent(!rerenderZoomedContent)}>
        <div className={'quino-dashboard-tile-zoomed-wrapper'}>{zoomPopup && renderedTileContent}</div>
      </QuinoPopup>

      <QuinoPopup visible={editDialog} width={800} height={600} title={translationService.translate('Dashboard.Tile.EditDialog.Title')}>
        {renderedSettingsContent}
      </QuinoPopup>
    </>
  );
}
