import { IQuinoExcelCell } from '../model';
import { IExportExcelSettings } from '../settings';

export const IQuinoExcelCellBuilderSymbol = Symbol.for('IQuinoExcelCellBuilder');

/**
 * Interface for the QuinoExcelCellBuilder
 */
export interface IQuinoExcelCellBuilder {
  /**
   * Returns an @IQuinoExcelCellBuilder with the applied default settings (@IExportExcelSettings)
   * @param defaultSettings @IExportExcelSettings
   */
  withDefaultSettings: (defaultSettings: IExportExcelSettings) => IQuinoExcelCellBuilder;

  /**
   * Returns an @IQuinoExcelCellBuilder with the applied format
   * @param format, format which is derived from IFormatStringService
   */
  withFormat: (format: string | undefined) => IQuinoExcelCellBuilder;

  /**
   * Returns an @IQuinoExcelCellBuilder with the applied default settings (@IExportExcelSettings) and cleared values
   * @param defaultSettings
   */
  clear: (defaultSettings: IExportExcelSettings) => IQuinoExcelCellBuilder;

  /**
   * Returns an @IQuinoExcelCellBuilder and overrides the wrapText attribute with the given value
   * @param wrapText boolean which indicates whether the text should be wrapped or not
   */
  setIsWrapText: (wrapText: boolean) => IQuinoExcelCellBuilder;

  /**
   * Returns an @IQuinoExcelCellBuilder with the applied font
   * @param fontName string representation of a font, e.g. 'Arial'
   */
  withFontName: (fontName: string) => IQuinoExcelCellBuilder;

  /**
   * Returns an @IQuinoExcelCellBuilder with the applied font size
   * @param fontSize
   */
  withFontSize: (fontSize: number) => IQuinoExcelCellBuilder;

  /**
   * Returns an @IQuinoExcelCellBuilder with the applied margin right
   * @param fontSize
   */
  withMarginRight: (marginRight: number) => IQuinoExcelCellBuilder;

  /**
   * Returns an @IQuinoExcelCellBuilder with the applied setting
   * @param isBold boolean which indicates whether the cell is bold or not
   */
  setIsBold: (isBold: boolean) => IQuinoExcelCellBuilder;

  /**
   * Returns an @IQuinoExcelCellBuilder and overrides the value attribute with the given value
   * @param value string value of the cell
   */
  withValue: (value: string | number | Date) => IQuinoExcelCellBuilder;

  /**
   * Returns an @IQuinoExcelCell with all the applied settings and values
   */
  build: () => IQuinoExcelCell;
}
