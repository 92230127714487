import { inject, injectable } from 'inversify';
import { IServerHealthCheckerService } from './IServerHealthCheckerService';
import { IRequestBuilder, IUrlManager, QuinoCoreServiceSymbols } from '@quino/core';

@injectable()
export class ServerHealthCheckerService implements IServerHealthCheckerService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder
  ) {}

  async check(): Promise<void> {
    const url = this.urlManager.getServerHealthCheckUrl();

    return this.requestBuilder
      .create(url, 'get')
      .fetch()
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        throw Error(e.message);
      });
  }

  getHelpline(): string | undefined {
    return undefined;
  }

  getErrorIntervalMS(): number {
    return 60 * 1000;
  }

  getRoutineIntervalMS(): number {
    return 5 * 60 * 1000;
  }
}
