import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression, isExpression } from './IExpression';
import { injectable } from 'inversify';
import { isMetaRelationExpression } from './IMetaRelationExpression';

export const MetaRelationExpressionEvaluatorSymbol = Symbol.for('MetaRelationExpressionEvaluator');

@injectable()
export class MetaRelationExpressionEvaluator implements IExpressionEvaluator {
  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (isExpression(expression) && context != null && isMetaRelationExpression(expression)) {
      return context[expression.RelationName];
    }

    // @ts-ignore
    return expression;
  }
}
