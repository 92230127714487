import React, { useCallback, useEffect, useState } from 'react';
import { QuinoPopup, QuinoPopupToolbar, QuinoPopupToolbarButton } from '../QuinoPopup';
import { ILoadingFeedback, INotificationService, ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { useService } from '../../ioc';
import { IServerHealthCheckerService, IServerHealthCheckerServiceSymbol } from './IServerHealthCheckerService';

/**
 * The health check is only considered successful, if it succeeds before this timeout
 */
const healthCheckTimeoutMS = 10 * 1000;

export interface IInternalQuinoServerHealthCheckerProps {
  cause?: string;
  firstErrorOccurredDate?: number;
  notificationService?: INotificationService;
  loadingFeedback?: ILoadingFeedback;
}

export const InternalQuinoServerHealthChecker: React.FunctionComponent<IInternalQuinoServerHealthCheckerProps> = (props) => {
  const { notificationService, loadingFeedback } = props;

  const [serverReachable, setServerReachable] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [lastHealthCheckDate, setLastHealthCheckDate] = useState<number>();
  const [lastHealthCheckDateString, setLastHealthCheckDateString] = useState<string>('');
  const [nextHealthCheckDate, setNextHealthCheckDate] = useState<number>();
  const [nextHealthCheckDateString, setNextLastHealthCheckDateString] = useState<string>('');
  const [errorOccurrenceDate, setErrorOccurrenceDate] = useState<number>();
  const [errorOccurrenceDateString, setErrorOccurrenceDateString] = useState<string>('');
  const [intervalID, setIntervalID] = useState<number>();
  const [timeoutID, setTimeoutID] = useState<number>();
  const [dateNow, setDateNow] = useState<number>(Date.now());
  const [documentHidden, setDocumentHidden] = useState<boolean>(false);
  const serverHealthCheckerService = useService<IServerHealthCheckerService>(IServerHealthCheckerServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const helpline = serverHealthCheckerService.getHelpline();
  const routineIntervalMS = serverHealthCheckerService.getRoutineIntervalMS();
  const errorIntervalMS = serverHealthCheckerService.getErrorIntervalMS();

  const performHealthCheck = useCallback(
    async (routineCheck: boolean, force: boolean) => {
      // prevent health check from polling
      if (nextHealthCheckDate && !force) {
        return;
      }

      const request = serverHealthCheckerService.check();
      const timeout = new Promise((resolve, reject) => setTimeout(reject, healthCheckTimeoutMS));

      const unload: undefined | (() => void) = routineCheck
        ? undefined
        : loadingFeedback?.load(translationService.translate('HealthChecker.LoadingMessage'));
      try {
        await Promise.race([request, timeout]);
        setServerReachable(true);
        !timeoutID &&
          !intervalID &&
          setTimeoutID(
            setTimeout(() => {
              setTimeoutID(undefined);
              void performHealthCheck(true, true);
            }, routineIntervalMS)
          );
      } catch (e) {
        setServerReachable(false);
        setErrorMessage(e.message);
      } finally {
        unload && unload();
      }
    },
    [intervalID, nextHealthCheckDate, loadingFeedback, serverHealthCheckerService, translationService, routineIntervalMS, timeoutID]
  );

  useEffect(() => {
    let documentHiddenKey = '';
    let visibilityChange = '';
    // from https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#example
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      documentHiddenKey = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof (document as any).msHidden !== 'undefined') {
      documentHiddenKey = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof (document as any).webkitHidden !== 'undefined') {
      documentHiddenKey = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    const handleVisibilityChange = () => setDocumentHidden(document[documentHiddenKey]);
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
    return () => document.removeEventListener(visibilityChange, handleVisibilityChange);
  }, []);

  useEffect(() => {
    if (documentHidden) {
      clearTimeout(timeoutID);
      setTimeoutID(undefined);
    } else {
      !timeoutID && performHealthCheck(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentHidden]);

  const clear = useCallback(() => {
    setNextHealthCheckDate(undefined);
    setLastHealthCheckDate(undefined);
    clearInterval(intervalID);
    setIntervalID(undefined);
  }, [intervalID]);

  const performHealthCheckButtonClick = () => {
    clear();
    void performHealthCheck(true, true);
  };

  const getTimeSpanString = useCallback(
    (timeMS: number): string => {
      const timeS = Math.floor(timeMS / 1000);
      const minutes = Math.floor(timeS / 60);
      const seconds = timeS - minutes * 60;

      return `${minutes} ${translationService.translate('HealthChecker.MinutesShortText')}, ${seconds} ${translationService.translate(
        'HealthChecker.SecondsShortText'
      )}`;
    },
    [translationService]
  );

  useEffect(() => {
    if (serverReachable) {
      clear();
      setErrorOccurrenceDate(undefined);
    } else {
      if (!nextHealthCheckDate) {
        setNextHealthCheckDate(Date.now() + errorIntervalMS);
      }
      if (!lastHealthCheckDate) {
        setLastHealthCheckDate(Date.now());
      }
      if (props.firstErrorOccurredDate) {
        setErrorOccurrenceDate(props.firstErrorOccurredDate);
      } else if (!errorOccurrenceDate) {
        setErrorOccurrenceDate(Date.now());
      }
      if (!intervalID) {
        setDateNow(Date.now());
        clearTimeout(timeoutID);
        setIntervalID(setInterval(() => setDateNow(Date.now()), 1000));
      }
    }
  }, [
    clear,
    errorIntervalMS,
    errorOccurrenceDate,
    intervalID,
    lastHealthCheckDate,
    nextHealthCheckDate,
    props.firstErrorOccurredDate,
    serverReachable,
    timeoutID
  ]);

  useEffect(() => {
    if (!errorOccurrenceDate) {
      setErrorOccurrenceDateString(translationService.translate('HealthChecker.UnknownText'));
      return;
    }
    setErrorOccurrenceDateString(`${new Date(errorOccurrenceDate).toLocaleDateString()}, ${new Date(errorOccurrenceDate).toLocaleTimeString()}`);
  }, [errorOccurrenceDate, translationService]);

  useEffect(() => {
    if (!lastHealthCheckDate) {
      setLastHealthCheckDateString(translationService.translate('HealthChecker.UnknownText'));
      return;
    }
    const lastCheck = dateNow - lastHealthCheckDate;
    setLastHealthCheckDateString(lastCheck > 0 ? getTimeSpanString(lastCheck) : translationService.translate('HealthChecker.NowText'));
  }, [dateNow, getTimeSpanString, lastHealthCheckDate, translationService]);

  useEffect(() => {
    if (!nextHealthCheckDate) {
      setNextLastHealthCheckDateString(translationService.translate('HealthChecker.UnknownText'));
      return;
    }
    const nextCheck = nextHealthCheckDate - dateNow;
    if (nextCheck < 0) {
      void clear();
      void performHealthCheck(false, true);
      return;
    }
    setNextLastHealthCheckDateString(getTimeSpanString(nextCheck));
  }, [clear, dateNow, getTimeSpanString, nextHealthCheckDate, performHealthCheck, translationService]);

  useEffect(() => {
    const symbol = notificationService?.subscribeToNotification(
      'default',
      (notifications) => notifications.some((n) => n.type === 'error') && void performHealthCheck(false, false)
    );
    return () => symbol && notificationService?.unsubscribeFromNotification(symbol);
  }, [notificationService, performHealthCheck, serverReachable]);

  return (
    <>
      <QuinoPopup
        className={'quino-health-checker-popup'}
        visible={!serverReachable}
        title={translationService.translate('HealthChecker.DescriptionText')}>
        <div>
          <p>{translationService.translate('HealthChecker.DescriptionText')}</p>
          <table>
            <tbody>
              <tr>
                <td>{translationService.translate('HealthChecker.CauseText')}</td>
                <td>
                  <b>{props.cause ? props.cause : errorMessage}</b>
                </td>
              </tr>
              <tr>
                <td>{translationService.translate('HealthChecker.OccurredAtText')}</td>
                <td>
                  <b>{errorOccurrenceDateString}</b>
                </td>
              </tr>
              <tr>
                <td>{translationService.translate('HealthChecker.LastReconnectionText')}</td>
                <td>
                  <b>{lastHealthCheckDateString}</b>
                </td>
              </tr>
              <tr>
                <td>{translationService.translate('HealthChecker.NextReconnectionText')}</td>
                <td>
                  <b>{nextHealthCheckDateString}</b>
                </td>
              </tr>
            </tbody>
          </table>
          {helpline && helpline.length > 0 && (
            <p className={'quino-p-border-top'}>
              {translationService.translate('HealthChecker.HelplineText')} {helpline}
            </p>
          )}
          <QuinoPopupToolbar
            key={'EditPopupToolbar'}
            showTopBorder={true}
            rightItems={[
              <QuinoPopupToolbarButton
                text={translationService.translate('HealthChecker.Reconnection.Button')}
                icon={'material-icons-outlined autorenew'}
                onClick={performHealthCheckButtonClick}
              />
            ]}
          />
        </div>
      </QuinoPopup>
    </>
  );
};
