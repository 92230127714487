import { IDashboardSettingsService } from './IDashboardSettingsService';
import { inject, injectable } from 'inversify';
import {
  DefaultAspectIdentifier,
  getAspectOrDefault,
  IExpression,
  IFavoriteLayoutService,
  IFavoriteLayoutServiceSymbol,
  ILogger,
  IMetaAspect,
  IMetadataTree,
  IStorageService,
  ITranslationService,
  ITranslationServiceSymbol,
  IUserInfoService,
  IUserInfoServiceSymbol,
  IVisibleCalculator,
  LayoutType,
  PersistentStorageServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { IDashboardLayout, IQuinoDashboardItem, TQuinoDashboardItemPositioning } from './IDashboardLayout';
import { Guid } from 'guid-typescript';
import { IDashboardConfigurationService, IDashboardConfigurationServiceSymbol } from '../configuration';
import { IDashboardTileRegistry, IDashboardTileRegistrySymbol } from '../registry';
import { DashboardBookmarkSymbol } from '../../bookmarks/IDashboardBookmark';
import { DashboardType } from '../DashboardType';
import { ISharedDashboardsService, ISharedDashboardsServiceSymbol } from '../configuration/ISharedDashboardsService';

interface ISerializedCustomDashboard {
  name: string;
  caption: string;
  tiles: ISerializedTile[];
  visible?: IExpression | boolean;
  visibleHumanReadable?: string;
}

interface ISerializedTile {
  position: TQuinoDashboardItemPositioning;
  payload: any;
  controlName: string;
}

@injectable()
export class DashboardSettingsService implements IDashboardSettingsService {
  constructor(
    @inject(PersistentStorageServiceSymbol) private readonly storageService: IStorageService,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(IDashboardTileRegistrySymbol) private readonly tileRegistry: IDashboardTileRegistry,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService,
    @inject(IFavoriteLayoutServiceSymbol) private readonly favoriteLayoutService: IFavoriteLayoutService,
    @inject(IDashboardConfigurationServiceSymbol) private readonly dashboardConfigurationService: IDashboardConfigurationService,
    @inject(ISharedDashboardsServiceSymbol) private readonly sharedDashboardsService: ISharedDashboardsService,
    @inject(QuinoCoreServiceSymbols.IVisibleCalculator) private readonly visibleCalculator: IVisibleCalculator,
    @inject(IUserInfoServiceSymbol) private readonly userInfoService: IUserInfoService
  ) {}

  async getDashboards(): Promise<IDashboardLayout[]> {
    const isAdmin = this.userInfoService.isInRole('admin');
    const allDashboards: IDashboardLayout[] = [];
    allDashboards.push(...this.getSystemDashboards());
    if ((await this.dashboardConfigurationService.getDashboardConfiguration()).customDashboardsEnabled) {
      allDashboards.push(...(await this.getCustomDashboards()));
    }
    if ((await this.dashboardConfigurationService.getDashboardConfiguration()).sharedDashboardsEnabled) {
      allDashboards.push(...(await this.getSharedDashboards()));
    }
    return allDashboards.filter((x) => (isAdmin && x.dashboardType == DashboardType.Shared) || this.visibleCalculator.calculate(x, {}));
  }

  async getFavoriteOrDefaultDashboard(): Promise<IDashboardLayout> {
    const allDashboards = await this.getDashboards();
    return this.getFavoriteOrDefault(allDashboards);
  }

  async getDashboardOrFallback(dashboardName: string): Promise<IDashboardLayout> {
    const allDashboards = await this.getDashboards();
    const dashboard = allDashboards.find((x) => x.name === dashboardName && this.visibleCalculator.calculate(x, {}));
    return dashboard || this.getFavoriteOrDefault(allDashboards);
  }

  async saveCustomDashboard(dashboard: IDashboardLayout): Promise<IDashboardLayout> {
    dashboard.dashboardType = DashboardType.Personal;
    let newDashboards = [...(await this.getCustomDashboards())];
    if (newDashboards.find((x) => x.name === dashboard.name)) {
      newDashboards = newDashboards.map((x) => (x.name === dashboard.name ? dashboard : x));
    } else {
      newDashboards.push(dashboard);
    }
    await this.saveCustomDashboards(newDashboards);
    return dashboard;
  }

  async saveSharedDashboard(dashboard: IDashboardLayout, visibleExpression?: string): Promise<IDashboardLayout> {
    dashboard.dashboardType = DashboardType.Shared;
    if (visibleExpression) {
      const parsedVisibleExpression = await this.sharedDashboardsService.parseExpression(visibleExpression);
      if (parsedVisibleExpression != undefined) {
        dashboard.visibleHumanReadable = visibleExpression;
        dashboard.visible = parsedVisibleExpression;
      } else {
        dashboard.visibleHumanReadable = '';
        dashboard.visible = true;
      }
    }
    let newDashboards = [...(await this.getSharedDashboards())];
    if (newDashboards.find((x) => x.name === dashboard.name)) {
      newDashboards = newDashboards.map((x) => (x.name === dashboard.name ? dashboard : x));
    } else {
      newDashboards.push(dashboard);
    }
    await this.saveSharedDashboards(newDashboards);
    return dashboard;
  }

  async createCustomDashboard(caption?: string): Promise<IDashboardLayout> {
    const newDashboard = this.createLayout(caption || 'New Dashboard', DashboardType.Personal);
    const updatedDashboards = [...(await this.getCustomDashboards()), newDashboard];
    await this.saveCustomDashboards(updatedDashboards);
    return newDashboard;
  }

  async duplicateCustomDashboard(originalDashboard: IDashboardLayout, newCaption: string): Promise<IDashboardLayout> {
    const newDashboard = this.createLayout(newCaption, DashboardType.Personal);
    newDashboard.items = originalDashboard.items.map((x) => {
      return { ...x, id: Guid.create().toString() };
    });
    const updatedDashboards = [...(await this.getCustomDashboards()), newDashboard];
    await this.saveCustomDashboards(updatedDashboards);
    return newDashboard;
  }

  async deleteCustomDashboard(dashboardName: string, createDefaultDashboardIfNeeded: boolean): Promise<IDashboardLayout> {
    const updatedDashboards = (await this.getCustomDashboards()).filter((x) => x.name !== dashboardName);
    if (
      createDefaultDashboardIfNeeded &&
      updatedDashboards.length === 0 &&
      (!this.systemDashboards || this.systemDashboards.length === 0) &&
      (!this.sharedDashboards || this.sharedDashboards.length === 0)
    ) {
      updatedDashboards.push(this.createLayout('Default Dashboard', DashboardType.Personal));
    }
    await this.saveCustomDashboards(updatedDashboards);
    return this.getFavoriteOrDefault(await this.getDashboards());
  }

  async deleteSharedDashboard(dashboardName: string, createDefaultDashboardIfNeeded: boolean): Promise<IDashboardLayout> {
    const updatedDashboards = (await this.getSharedDashboards()).filter((x) => x.name !== dashboardName);
    if (
      createDefaultDashboardIfNeeded &&
      updatedDashboards.length === 0 &&
      (!this.systemDashboards || this.systemDashboards.length === 0) &&
      (!this.customDashboards || this.customDashboards.length === 0)
    ) {
      updatedDashboards.push(this.createLayout('Default Dashboard', DashboardType.Personal));
    }
    await this.saveSharedDashboards(updatedDashboards);
    return this.getFavoriteOrDefault(await this.getDashboards());
  }

  getCurrentNumberOfDashboards(): number {
    return (
      (this.systemDashboards ? this.systemDashboards.length : 0) +
      (this.customDashboards ? this.customDashboards.length : 0) +
      (this.sharedDashboards ? this.sharedDashboards.length : 0)
    );
  }

  private getSystemDashboards(): IDashboardLayout[] {
    if (!this.systemDashboards) {
      const systemDashboards = this.metadataTree.getDashboardLayouts();
      this.systemDashboards = this.deserializeDashboards(systemDashboards, DashboardType.System);
    }
    return this.systemDashboards;
  }

  private async getCustomDashboards(): Promise<IDashboardLayout[]> {
    if (!this.customDashboards) {
      const customDashboardSettings = await this.storageService.get(this.dashboardSettings);
      let deserializedDashboards = this.deserializeDashboards(customDashboardSettings, DashboardType.Personal);

      if (deserializedDashboards.length === 0) {
        deserializedDashboards = [this.createLayout(this.translationService.translate('DashboardPersonal'), DashboardType.Personal)];
        await this.saveCustomDashboards(deserializedDashboards);
      }

      this.customDashboards = deserializedDashboards;
    }

    return this.customDashboards;
  }

  private async getSharedDashboards(): Promise<IDashboardLayout[]> {
    if (!this.sharedDashboards) {
      let sharedDashboards: any[];
      try {
        const sharedDashboardsFromConfig = await this.sharedDashboardsService.get();
        sharedDashboards = JSON.parse(sharedDashboardsFromConfig);
      } catch {
        this.logger.logError('Could not parse shared dashboards, continue without any.');
        sharedDashboards = [];
      }

      this.sharedDashboards = this.deserializeDashboards(sharedDashboards, DashboardType.Shared);
    }

    return this.sharedDashboards;
  }

  private getFavoriteOrDefault(dashboards: IDashboardLayout[]): IDashboardLayout {
    const favoriteName = this.favoriteLayoutService.getFavoriteLayoutName(DashboardBookmarkSymbol.toString());

    // If favorite is set, return favorite
    if (favoriteName) {
      const favoriteDashboard = dashboards.find((x) => x.name === favoriteName);
      if (favoriteDashboard) {
        return favoriteDashboard;
      }
    }

    const sortedDashboards = dashboards.sort((a, b) => a.caption.localeCompare(b.caption));

    // Else: Return first non system dashboard with content OR first default system dashboard OR first system dashboard OR first dashboard
    const sharedDashboardsWithContent = sortedDashboards.filter((x) => x.dashboardType === DashboardType.Shared && x.items.length > 0);
    const systemDashboards = sortedDashboards.filter((x) => {
      return x.dashboardType == DashboardType.System && this.visibleCalculator.calculate(x, {});
    });
    const defaultSystemDashboard = systemDashboards.find((x) => {
      return getAspectOrDefault(x, DefaultAspectIdentifier);
    });

    if (defaultSystemDashboard !== undefined) {
      return defaultSystemDashboard;
    }

    if (systemDashboards.length > 0) {
      return systemDashboards[0];
    }

    if (sharedDashboardsWithContent.length > 0) {
      return sharedDashboardsWithContent[0];
    }

    if (sortedDashboards.length > 0) {
      return sortedDashboards[0];
    }

    return {
      dashboardType: DashboardType.System,
      visibleHumanReadable: '',
      visible: false,
      caption: 'Kein Dashboard verfügbar',
      name: 'Placeholder',
      type: LayoutType.Dashboard,
      controlName: '',
      elements: [],
      aspects: [],
      uri: '',
      items: [],
      sorts: [],
      enabled: true,
      readOnly: true,
      required: true
    };
  }

  private async saveCustomDashboards(dashboards: IDashboardLayout[]): Promise<void> {
    await this.storageService.set(
      this.dashboardSettings,
      dashboards.map((x) => DashboardSettingsService.serializeDashboard(x))
    );
    this.customDashboards = dashboards;
  }

  private async saveSharedDashboards(dashboards: IDashboardLayout[]): Promise<void> {
    await this.sharedDashboardsService.set(JSON.stringify(dashboards.map((x) => DashboardSettingsService.serializeDashboard(x))));
    this.sharedDashboards = dashboards;
  }

  private static serializeDashboard(dashboard: IDashboardLayout): ISerializedCustomDashboard {
    return {
      name: dashboard.name,
      caption: dashboard.caption,
      tiles: DashboardSettingsService.serializeTiles(dashboard.items),
      visible: dashboard.visible,
      visibleHumanReadable: dashboard.visibleHumanReadable
    };
  }

  private deserializeDashboards(dashboards: any, dashboardType: DashboardType): IDashboardLayout[] {
    if (dashboards && Array.isArray(dashboards)) {
      return dashboards.map((x) => {
        let visible = x.visible;
        if (typeof x.visible === 'string') {
          visible = JSON.parse(visible);
        }

        return this.createLayout(x.caption, dashboardType, x.name, this.deserializeTiles(x.tiles), x.aspects, visible, x.visibleHumanReadable);
      });
    }
    return [];
  }

  private static serializeTiles(tiles: IQuinoDashboardItem[]): ISerializedTile[] {
    return tiles.map((x) => {
      return {
        controlName: x.type.name,
        payload: x.type.serializePayload(x.payload),
        position: x.position
      };
    });
  }

  private deserializeTiles(tiles: any): IQuinoDashboardItem[] {
    if (!tiles || !Array.isArray(tiles)) {
      return [];
    }

    const deserializedTiles: IQuinoDashboardItem[] = [];
    const registeredTileTypes = this.tileRegistry.getInstances();

    tiles.forEach((tile) => {
      const tileType = registeredTileTypes.find((x) => x.name === tile.controlName);
      if (tileType) {
        const deserializedPayload = tileType.deserializePayload(tile.payload);
        deserializedTiles.push({
          id: Guid.create().toString(),
          payload: { ...deserializedPayload, caption: deserializedPayload.caption || tile.caption || '' },
          position: tile.position,
          type: tileType
        });
      } else {
        this.logger.logError(
          `Tile type [${tile.controlName}] does not exist. Tiles of this type will be definitely removed from the dashboard after next save.`
        );
      }
    });

    return deserializedTiles;
  }

  private readonly createLayout = (
    caption: string,
    dashboardType: DashboardType,
    name?: string,
    items?: IQuinoDashboardItem[],
    aspects?: IMetaAspect[],
    visible?: IExpression | boolean,
    visibleHumanReadable?: string
  ): IDashboardLayout => {
    const calculatedName = name || 'dashboard-' + Guid.create().toString();
    return {
      uri: calculatedName,
      name: calculatedName,
      caption: caption,
      type: LayoutType.Dashboard,
      controlName: 'Dashboard',
      enabled: true,
      visibleHumanReadable: visibleHumanReadable !== undefined ? visibleHumanReadable : '',
      visible: visible !== undefined ? visible : true,
      readOnly: false,
      required: false,
      aspects: aspects || [],
      elements: [],
      sorts: [],
      items: items || [],
      dashboardType: dashboardType
    };
  };

  private sharedDashboards: IDashboardLayout[] | undefined = undefined;
  private customDashboards: IDashboardLayout[] | undefined = undefined;
  private systemDashboards: IDashboardLayout[] | undefined = undefined;

  private readonly dashboardSettings = 'CustomDashboards';
}
