export const IQuinoDropDownSettingsSymbol = Symbol.for('IQuinoDropDownSettings');

export interface IQuinoDropDownSettings {
  /**
   * Boolean which indicates if cross links are shown next to the drop down
   */
  useCrossLink: boolean;
  /**
   * Boolean which indicates if we show the "Add new entry" button in the drop down list toolbarItems section.
   */
  useCreateEntryButton: boolean;
  /**
   * Boolean which indicates if we show the "Refresh content" button in the drop down list toolbarItems section.
   */
  useRefreshButton: boolean;

  /**
   * Boolean which indicates if we always show the scrollbar in the dropdown or default (onScroll).
   */
  alwaysShowScrollbar: boolean;
}

export const defaultDropDownSettings: IQuinoDropDownSettings = {
  useCrossLink: false,
  useCreateEntryButton: true,
  useRefreshButton: false,
  alwaysShowScrollbar: false
};
