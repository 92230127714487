import { IMetaProperty } from './IMetaProperty';
import { IGenericObject } from '../data';
import { DataType } from './DataType';

/**
 * Gets the path for a layout element which must be used to access the field value within the data object.
 * @param element The layout element to get the path for.
 * @param forceRawValues True if no special relation logic should be applied. Default is false.
 */
export function getElementPath(element: IMetaProperty, forceRawValues = false): string {
  return element.dataType === DataType.Relation ? getRelationElementPath(element, forceRawValues) : getNormalElementPath(element);
}

/**
 * Gets the value for a field from the row / data object using the layout element definition.
 * @param row Thr row or data object to get the field value from.
 * @param element The layout element to get the value for.
 * @param forceRawValues True if no special relation logic should be applied. Default is false.
 */
export function getFieldValue(row: IGenericObject, element: IMetaProperty, forceRawValues = false): any | undefined {
  return row[getElementPath(element, forceRawValues)];
}

function getNormalElementPath(element: IMetaProperty): string {
  return element.path ? element.path : element.name;
}

function getRelationElementPath(element: IMetaProperty, forceRawValues = false): string {
  return forceRawValues ? getNormalElementPath(element) : `${getNormalElementPath(element)}_title`;
}
