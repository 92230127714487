import { IMetaElement } from './IMetaElement';
import { IMetaLayout } from './IMetaLayout';
import { IMetaRelation } from './IMetaRelation';
import { IMetaProperty } from './IMetaProperty';
import { IMetaSort } from './IMetaSort';

/**
 * The meta class.
 */
export interface IMetaClass extends IMetaElement {
  /**
   * The plural caption.
   */
  pluralCaption: string;

  /**
   * The array of primary keys for this object.
   */
  primaryKey: string[];

  /**
   * Available layouts for a given class.
   */
  layouts: IMetaLayout[];

  /**
   * Relations
   */
  relations: IMetaRelation[];

  /**
   * Properties to use.
   */
  properties: IMetaProperty[];

  /**
   * Sorts related to the meta class.
   */
  sorts: IMetaSort[];
}

export function isIMetaClass(object: any): object is IMetaClass {
  return (
    object != null &&
    (object as IMetaClass).properties != null &&
    (object as IMetaClass).primaryKey != null &&
    (object as IMetaClass).pluralCaption != null
  );
}
