import { IAuthenticationService } from './IAuthenticationService';
import { IAuthenticationResult } from './IAuthenticationResult';
import { IAuthenticationData } from './IAuthenticationData';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IUrlManager } from '../api';
import { AuthenticationState } from './AuthenticationState';
import { DefaultAuthenticationService } from './DefaultAuthenticationService';
import { IMessenger, IMessengerSymbol } from '../core';
import { IRequestDecoratorProvider, IRequestFactory } from '../request';
import { ILogger } from '../logging';

@injectable()
export class OidcAuthenticationService extends DefaultAuthenticationService implements IAuthenticationService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IStorage) readonly storage: Storage,
    @inject(IMessengerSymbol) readonly messenger: IMessenger,
    @inject(QuinoCoreServiceSymbols.IRequestFactory) readonly requestFactory: IRequestFactory,
    @inject(QuinoCoreServiceSymbols.IRequestDecoratorProvider) readonly decoratorProvider: IRequestDecoratorProvider,
    @inject(QuinoCoreServiceSymbols.ILogger) readonly logger: ILogger
  ) {
    super(urlManager, storage, messenger, requestFactory, decoratorProvider, logger);
  }

  loginAsync = async (data: IAuthenticationData): Promise<IAuthenticationResult> => {
    const details = {
      grant_type: 'password',
      username: (data as any).username,
      password: (data as any).password,
      client_id: 'local'
    };
    const formBody = [];
    for (const property in details) {
      formBody.push(encodeURIComponent(property) + '=' + encodeURIComponent(details[property]));
    }
    const request = this.requestFactory.create(this.urlManager.getOidcLoginUrl(), {
      method: 'post',
      body: formBody.join('&'),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    this.decoratorProvider.getInstances().forEach((decorator) => decorator.decorate(request));
    request.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    const result = await window.fetch(request);

    if (result !== undefined && result.ok && !result.bodyUsed) {
      const response = await result.json();

      this.storage.setItem(this.storageKey, response.token_type + ' ' + response.access_token);

      await this.evaluateUserInfo(this.getToken()!);

      return { state: AuthenticationState.Successful };
    } else if (result !== undefined && result.status === 400) {
      const body = await result.json();
      switch (body.error) {
        case 'EmailNotConfirmed':
          return { state: AuthenticationState.EmailNotConfirmed };
        case 'PhoneNotConfirmed':
          return { state: AuthenticationState.PhoneNotConfirmed };
        case 'AccountLocked':
          return { state: AuthenticationState.AccountLocked };
        case 'RequiresTwoFactor':
          return { state: AuthenticationState.RequiresTwoFactor };
        case 'InvalidLogin':
          return { state: AuthenticationState.Failed };
      }
    }

    return { error: result && (await result.text()), state: AuthenticationState.Failed };
  };
}
