import { PositionConfig } from 'devextreme/animation/position';
import { IPopupOptions } from 'devextreme-react/popup';
export const IFeedbackServiceSymbol = Symbol.for('IFeedbackService');

export interface IFeedbackOption {
  value: string;
  text: string;
  icon?: string;
  isPrimary?: boolean;
  isCancelOption: boolean;
}

/**
 * Service that gets simple user feedback
 */
export interface IFeedbackService {
  /**
   * Shows a simple confirmation popup
   * @param title - title of the popup
   * @param text - text of the popup
   * @param confirmText - optional: text of the confirm button; default is "Yes"
   * @param confirmIcon - optional: icon of the confirm button; default is "done" check mark
   * @param rejectText - optional: text of the reject button; default is "Cancel"
   * @param rejectIcon - optional: icon of the reject button; default is "clear" cross
   * @param popupOptions - optional: any additional popup options
   * @returns true if the user confirmed, false otherwise
   */
  getConfirmation(
    title: string,
    text: string,
    confirmText?: string,
    confirmIcon?: string,
    rejectText?: string,
    rejectIcon?: string,
    popupOptions?: Partial<IPopupOptions>
  ): Promise<boolean>;

  /**
   * Shows a simple popup with input field
   * @param title - title of the popup
   * @param text - text of the popup
   * @param confirmText - optional: text of the confirm button; default is "Save"
   * @param confirmIcon - optional: icon of the confirm button; default is "save" icon
   * @param rejectText - optional: text of the reject button; default is "Cancel"
   * @param rejectIcon - optional: icon of the reject button; default is "clear" cross
   * @param initialValue - optional: initial value of the input field; default is empty
   * @param maxLength - optional: max length of the input; default is 80 characters
   * @param popupOptions - optional: any additional popup options
   * @returns the string that was entered by the user, empty if the user canceled
   */
  getString(
    title: string,
    text: string,
    confirmText?: string,
    confirmIcon?: string,
    rejectText?: string,
    rejectIcon?: string,
    initialValue?: string,
    maxLength?: number,
    popupOptions?: Partial<IPopupOptions>
  ): Promise<{ confirmed: boolean; value: string }>;

  /**
   * Shows a read-only popup displaying a message
   * @param title - title of the popup
   * @param message - text of the popup
   * @param messageDetail - optional: additional detail text
   * @param popupOptions - optional: any additional popup options
   * @returns an empty promise that resolves once the user closes the popup
   */
  showMessage(title: string, message: string, messageDetail?: string, popupOptions?: Partial<IPopupOptions>): Promise<void>;

  /**
   * Shows a popup with the provided options
   * @param title - title of the popup
   * @param text - text of the popup
   * @param options - options that are displayed as buttons
   * @param popupOptions - optional: any additional popup options
   * @returns the value of the option that was selected by the user
   */
  getCustomFeedback(title: string, text: string, options: IFeedbackOption[], popupOptions?: Partial<IPopupOptions>): Promise<string>;
}
