import { useService } from '../ioc/hook';
import {
  IAuthorizationService,
  IEnabledCalculator,
  IGenericObject,
  IMetaElement,
  IMetaProperty,
  IReadOnlyCalculator,
  IRequiredCalculator,
  isIMetaProperty,
  IVisibleCalculator,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { isNewObjectPrimaryKey } from '../bookmarks';

export type ElementMetadata = {
  readOnly: boolean;
  enabled: boolean;
  required: boolean;
  visible: boolean;
};

/**
 * Calculate whether something is visible, required, readOnly and visible.
 * @param element - the element to calculate for.
 * @param object - the context object.
 */
export function useMetadata(element: IMetaElement | IMetaProperty, object: IGenericObject): ElementMetadata {
  const readOnlyCalculator = useService<IReadOnlyCalculator>(QuinoCoreServiceSymbols.IReadOnlyCalculator);
  const enabledCalculator = useService<IEnabledCalculator>(QuinoCoreServiceSymbols.IEnabledCalculator);
  const requiredCalculator = useService<IRequiredCalculator>(QuinoCoreServiceSymbols.IRequiredCalculator);
  const visibleCalculator = useService<IVisibleCalculator>(QuinoCoreServiceSymbols.IVisibleCalculator);
  const authorizationService = useService<IAuthorizationService>(QuinoCoreServiceSymbols.IAuthorizationService);

  let readOnly = readOnlyCalculator.calculate(element, object);
  if (isIMetaProperty(element)) {
    const isNewBookmark = isNewObjectPrimaryKey(object.primaryKey);
    const canCreate = authorizationService.getAuthorizationForProperty(element, object.metaClass).canCreate();
    const canUpdate = authorizationService.getAuthorizationForProperty(element, object.metaClass).canUpdate();
    readOnly = readOnly || (isNewBookmark && !canCreate) || (!canUpdate && !isNewBookmark);
  }

  return {
    visible: visibleCalculator.calculate(element, object),
    enabled: enabledCalculator.calculate(element, object),
    required: requiredCalculator.calculate(element, object),
    readOnly: readOnly
  };
}
