import { inject, injectable } from 'inversify';
import { IExpressionEvaluator } from './IExpressionEvaluator';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IExpression, isExpression } from './IExpression';
import { isNowExpression } from './INowExpression';

export const NowExpressionEvaluatorSymbol = Symbol.for('NowExpressionEvaluator');

@injectable()
export class NowExpressionEvaluator implements IExpressionEvaluator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator) {}

  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (isExpression(expression) && context != null && isNowExpression(expression)) {
      // @ts-ignore
      return new Date();
    }

    // @ts-ignore
    return expression;
  }
}
