import { IExpression } from './IExpression';
import { ExpressionOperator } from './ExpressionOperator';

export interface IUnaryExpression extends IExpression {
  Operator: ExpressionOperator;
  Parameter: IExpression;
}

export const isUnaryExpression = (object: IExpression): object is IUnaryExpression => {
  return (object as IUnaryExpression).Operator !== undefined && (object as IUnaryExpression).Parameter !== undefined;
};
