import { IMetaElement } from './IMetaElement';

export const IMetaVisitorSymbol = Symbol.for('IMetaVisitor');

/**
 * Visitor for iterating through meta elements.
 */
export interface IMetaVisitor {
  /**
   * Visit the current element and all subsequent elements.
   * @param root - the root element.
   * @param callback - the callback to execute on each element.
   */
  visit(root: IMetaElement, callback: (element: IMetaElement) => void): void;
}
