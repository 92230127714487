import { FrameworkStartupGroup, IApplication, InitializationGroup } from '../application';
import { ILanguageService } from './ILanguageService';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IRequestDecoratorProvider } from '../request';
import { QuinoLanguageService } from './QuinoLanguageService';
import { QuinoBackendTranslationLoader } from './QuinoBackendTranslationLoader';
import { ITranslationService, ITranslationServiceSymbol } from './ITranslationService';
import { IBackendTranslationLoader, IBackendTranslationLoaderSymbol } from './IBackendTranslationLoader';
import { loadDefaultTranslations } from './DefaultTranslations';
import { TranslationService } from './TranslationService';

export const LoadLanguageGroup = Symbol.for('LoadLanguageGroup');

export const LoadLanguageStartupAction = Symbol.for('LoadLanguageStartupAction');

export const LoadDefaultTranslationsStartupAction = Symbol.for('LoadDefaultTranslationsStartupAction');

export const LoadTranslationsStartupAction = Symbol.for('LoadTranslationsStartupAction');

export class TranslationModule {
  public static use(application: IApplication) {
    application.registerSingle<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService, QuinoLanguageService);
    application.registerSingle<IBackendTranslationLoader>(IBackendTranslationLoaderSymbol, QuinoBackendTranslationLoader);
    application.registerSingle<ITranslationService>(ITranslationServiceSymbol, TranslationService);

    // Push the load default translations to the top to ensure we've valid translations for the login screen.
    application.registerStartupGroup(LoadLanguageGroup).before(FrameworkStartupGroup);
    application
      .registerStartupAction(LoadDefaultTranslationsStartupAction, async (app) => {
        // Load the default translations based on the local storage or browser/fallback language.
        const defaultTranslationService = app.get<ITranslationService>(ITranslationServiceSymbol);
        const localLanguage = await app.get<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService).getLocalLanguageAsync();
        defaultTranslationService.load(loadDefaultTranslations(localLanguage));

        // Ensure the first public requests have the correct language set
        const requestDecoratorProvider = app.get<IRequestDecoratorProvider>(QuinoCoreServiceSymbols.IRequestDecoratorProvider);
        setAcceptLanguage(requestDecoratorProvider, localLanguage);

        // Load the translations from the backend and overlay them over the default translations.
        const translationLoadingService = app.get<IBackendTranslationLoader>(IBackendTranslationLoaderSymbol);
        const translations = await translationLoadingService.getTranslationsAsync();
        defaultTranslationService.load(translations.translations);
      })
      .moveTo(InitializationGroup);
    application
      .registerStartupAction(LoadLanguageStartupAction, async (app) => {
        // Load the language from the server (user-settings) once the user is authenticated
        const language = await app.get<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService).getCurrentLanguageAsync();
        const provider = app.get<IRequestDecoratorProvider>(QuinoCoreServiceSymbols.IRequestDecoratorProvider);

        // Ensure subsequent requests have the correct language set.
        setAcceptLanguage(provider, language);
      })
      .moveTo(LoadLanguageGroup);
  }
}

const setAcceptLanguage = (provider: IRequestDecoratorProvider, language: string) => {
  provider.register(
    {
      decorate: (request) => {
        request.headers.set('Accept-Language', language);
      }
    },
    Symbol.for('SetAcceptLanguage')
  );
};
