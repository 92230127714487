import React from 'react';
import { Button } from 'devextreme-react/button';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { useService } from '../../ioc';
import { IFileInfo } from './api';

export interface IQuinoFileSelectorBarProps {
  selectedFiles: IFileInfo[];
  clearSelection: () => void;
  onDeleteFiles: (files: IFileInfo[]) => void;
  onDownloadFiles: (files: IFileInfo[]) => void;
  readonly: boolean;
}

export const QuinoFileSelectorBar: React.FC<IQuinoFileSelectorBarProps> = (props) => {
  const { selectedFiles, clearSelection, onDeleteFiles, onDownloadFiles, readonly } = props;

  const allSelectedFilesErrors = selectedFiles.filter((fileInfo) => fileInfo.uploadError !== undefined);
  const downloadButtonIsVisible = allSelectedFilesErrors.length < selectedFiles.length;

  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  if (selectedFiles.length < 1) {
    return <></>;
  }

  return (
    <div className={'quino-file-bar quino-file-selector-bar'}>
      <div className={'left-content'}>
        <div className={'selected-files-count'}>{selectedFiles.length}&nbsp;</div>
        {translationService.translate(selectedFiles.length > 1 ? 'FileUpload.Selection.MoreFiles' : 'FileUpload.Selection.OneFile')}
        <Button type={'normal'} stylingMode={'text'} icon='material-icons-outlined close' onClick={clearSelection} />
      </div>
      <div className={'right-content'}>
        <Button
          type={'normal'}
          stylingMode={'text'}
          icon='material-icons-outlined delete'
          disabled={readonly}
          hint={translationService.translate('Delete')}
          onClick={() => {
            onDeleteFiles(selectedFiles);
            clearSelection();
          }}
        />
        <Button
          visible={downloadButtonIsVisible}
          type={'normal'}
          stylingMode={'text'}
          icon='material-icons-outlined get_app'
          hint={translationService.translate('FileUpload.Download')}
          onClick={() => onDownloadFiles(selectedFiles)}
        />
      </div>
    </div>
  );
};
