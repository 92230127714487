import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression, isExpression } from './IExpression';
import { isGenericIdentifierExpression } from './IGenericIdentifierExpression';
import { injectable } from 'inversify';

export const GenericIdentifierExpressionEvaluatorSymbol = Symbol.for('GenericIdentifierExpressionEvaluator');

@injectable()
export class GenericIdentifierExpressionEvaluator implements IExpressionEvaluator {
  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (isExpression(expression) && isGenericIdentifierExpression(expression)) {
      return context[expression.name];
    }

    // @ts-ignore
    return expression;
  }
}
