import dxDropDownEditor from 'devextreme/ui/drop_down_editor/ui.drop_down_editor';

export const registerKeyHandlers = <TProperties>(component: dxDropDownEditor<TProperties> | undefined) => {
  if (!component) {
    return;
  }

  const openEvent = (e: Event) => {
    if (!component.option('opened')) {
      component.open();
      e.preventDefault();
      return false;
    }
    return true;
  };

  const closeEvent = (e: Event) => {
    if (component.option('opened')) {
      component.close();
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    return true;
  };

  component.registerKeyHandler('downArrow', openEvent);
  component.registerKeyHandler('upArrow', openEvent);
  component.registerKeyHandler('escape', closeEvent);
};
