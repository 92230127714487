import * as React from 'react';
import { CSSProperties, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  getAspectOrDefault,
  getElementPath,
  IActionExecutor,
  IActionExecutorSymbol,
  IAuthorizationService,
  IBatchRequestItem,
  IconAspectIdentifier,
  IDataService,
  IEnabledCalculator,
  IErrorHandlingService,
  IErrorHandlingServiceSymbol,
  IFormatStringService,
  IGenericObject,
  IIconAspect,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetaAction,
  IMetaActionExecutionResponse,
  IMetaClass,
  IMetadataTree,
  IMetaElement,
  IMetaProperty,
  IMetaRelation,
  isGenericObject,
  isIMetaGroup,
  isIMetaLayout,
  isIMetaProperty,
  isKeyboardEvent,
  isValueList,
  ITranslationService,
  ITranslationServiceSymbol,
  IValueListAspect,
  IVisibleCalculator,
  LayoutType,
  QuinoContextContext,
  QuinoCoreServiceSymbols
} from '@quino/core';
import {
  Button as DataGridButton,
  Column,
  ColumnChooser,
  ColumnFixing,
  DataGrid,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  Toolbar,
  TotalItem
} from 'devextreme-react/data-grid';
import { ContainerContext, useService } from '../../ioc/hook';
import { IQuinoDataGridConfiguration } from './IQuinoDataGridConfiguration';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import DataSource from 'devextreme/data/data_source';
import { IQuinoDataGridColumnFactory, IQuinoDataGridColumnFactorySymbol } from './IQuinoDataGridColumnFactory';
import { TResponsiveMode, useResponsiveMode } from '../../responsivity';
import dxDataGrid, { CellPreparedEvent, EditorPreparingEvent, SavingEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { Item as ButtonGroupItem, ItemClickEvent as ButtonGroupClickEvent, Properties as ButtonGroupProperties } from 'devextreme/ui/button_group';
import { Item as ToolBarItem } from 'devextreme/ui/toolbar';
import ReactDOM from 'react-dom';
import { Properties as ButtonOptions, Properties as ButtonProperties } from 'devextreme/ui/button';
import { ContextMenu } from 'devextreme-react/context-menu';
import { QuinoRememberChoicePopup } from './QuinoRememberChoicePopup';
import dxCheckBox from 'devextreme/ui/check_box';
import { IDataGridSettingsService, IDataGridSettingsServiceSymbol, TGridEditingMode } from './IDataGridSettingsService';
import { QuinoDataGridRowAction, QuinoDataGridRowActionOverflow } from './QuinoDataGridRowAction';
import { IExportButtonFactory, IExportButtonFactorySymbol } from './IExportButtonFactory';
import { IFeedbackService, IFeedbackServiceSymbol } from '../../feedback';
import { QuinoUIServiceSymbols } from '../../ioc';
import { IQuinoMetaPanelActions } from '../Types';
import { IBookmarkTitleCalculator, IBookmarkTitleCalculatorSymbol } from '../../navigation/IBookmarkTitleCalculator';
import {
  IQuinoShortcutSettings,
  IQuinoShortcutSettingsSymbol,
  isCtrlOrCmdEvent,
  IShortcutInformation,
  IShortcutInformationService,
  IShortcutInformationServiceSymbol,
  isShiftAndCtrlOrCmdEvent
} from '../../shortcuts';
import { IQuinoDataGridColumnAggregationAspect, QuinoDataGridColumnAggregationAspectIdentifier } from '../../aspects';
import {
  IBookmark,
  IBookmarkFactory,
  IObjectBookmark,
  isILayoutAwareBookmark,
  isIListBookmark,
  isIMetaClassAwareBookmark,
  isIObjectBookmark,
  isIRefreshableBookmark,
  isNewObjectBookmark,
  isStateFullBookmark
} from '../../bookmarks';
import { FilterMode, IFilterState, useFilterButton, useFilterState } from './useFilterButton';
import { ILayoutActionsService, LayoutActionsServiceSymbol } from '../../actions/ILayoutActionsService';
import { IQuinoAction, IQuinoActionArgs, quinoActionPropValue } from '../../actions/IQuinoAction';
import { IClientActionProvider, IClientActionProviderSymbol } from '../../actions/IClientActionProvider';
import { IQuinoContextMenuItem } from '../ContextMenu';
import { IODataSourceFactory, IODataSourceFactorySymbol, ODataBooleanUndefined } from '../../data';
import { IQuinoActionFactory, IQuinoActionFactorySymbol, IStandardBookmarkActionsService, StandardBookmarkActionsServiceSymbol } from '../../actions';
import { ConfirmationActionAspectIdentifier, IConfirmationActionAspect } from '../../aspects/IConfirmationActionAspect';
import { ActionConfirmationFeedbackValue } from '../../actions/QuinoActionFactory';
import { QuinoDataGridActionBar } from './ActionBar/QuinoDataGridActionBar';
import { useOnMount } from '../Util';
import { IListSelectionAction } from './ActionBar';
import { INavigationService, INavigationServiceSymbol, useOnNavigation } from '../../navigation';
import { QuinoPopupEditor, QuinoPopupEditorOnHideAction } from '../QuinoPopupEditor';
import { ItemClickEvent } from 'devextreme/ui/context_menu';
import { useNotifications } from '../../feedback/useNotifications';
import { bookmarkStateTabIndexKey } from '../QuinoTabsContainer/QuinoTabsContainer';
import DevExpress from 'devextreme';
import InitializedEventInfo = DevExpress.events.InitializedEventInfo;
import 'jspdf-autotable';

export type TSelectionMode = 'None' | 'Multiple';
export type TDrillDownMode = 'None' | 'All';

export type TQuinoDataGridActions = {
  drilldown: (target: IGenericObject, context?: any) => void;
  delete: (target: IGenericObject | IGenericObject[]) => void;
};

export interface IQuinoDataGridProps extends IQuinoDataGridConfiguration {
  id?: string;
  className?: string;
  source?: IGenericObject[] | DataSource;
  layout?: IMetaElement;
  metaClass?: IMetaClass;
  bookmark: IBookmark;
  actions: TQuinoDataGridActions;
  rowActions: IQuinoAction[];
  headerActions: ToolBarItem[];
  onSelectionChanged?: (selection: IGenericObject[]) => void;
  isLoading?: boolean;
  visible?: boolean;
  stateStorageKey?: string;
  style?: CSSProperties;
  metaRelation?: IMetaRelation;
  onInitialized?: (e: InitializedEventInfo<dxDataGrid<any, any>>) => void;
  allowSelectAll?: boolean;
}

interface IEditModeGroupItem extends ButtonGroupItem {
  value: boolean;
}

export interface IQuinoDataGridColumnAggregation extends IQuinoDataGridColumnAggregationAspect {
  valueFormat?: string;
}

export const QuinoDataGridSearchEditorId = 'quino-data-grid-search-editor';

type TFilterSettingsPopupCallback = ((accept: boolean, rememberChoice: boolean) => Promise<void>) | undefined;

const dxDataGridNonPublicAPI: any = dxDataGrid;
dxDataGridNonPublicAPI.defaultOptions({
  options: {
    scrolling: {
      legacyMode: true
    }
  }
});
const selectColumnWidth = 50;

export const QuinoDataGrid: FC<IQuinoDataGridProps> = (props) => {
  const selectAllStateChanged = useRef<boolean>(false);
  const ignoreNextSelectAllCheckBoxValueChangedEvent = useRef<boolean>(false);
  const visibleRowCount = useRef<number>(0);
  const currentColumnsSortOrder = useRef<('asc' | 'desc' | undefined)[]>([]);
  const actionBarContainer = useRef<HTMLElement>(document.createElement('div'));
  const selectAllCheckBox = useRef<dxCheckBox>();
  const initialFilterStateLoaded = useRef<boolean>(false);
  const currentGridState = useRef<any>();
  const previouslyVisibleIds = useRef<any[]>([]);
  const numberOfSelectedRows = useRef<number>(0);

  const columnFactory = useService<IQuinoDataGridColumnFactory>(IQuinoDataGridColumnFactorySymbol);
  const metaPanelActionService = useService<IQuinoMetaPanelActions>(QuinoUIServiceSymbols.IQuinoMetaPanelActions);
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);
  const settingsService = useService<IDataGridSettingsService>(IDataGridSettingsServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const exportButtonFactory = useService<IExportButtonFactory>(IExportButtonFactorySymbol);
  const feedbackService = useService<IFeedbackService>(IFeedbackServiceSymbol);
  const { notify, clearNotifications } = useNotifications();
  const titleCalculator = useService<IBookmarkTitleCalculator>(IBookmarkTitleCalculatorSymbol);
  const authorizationService = useService<IAuthorizationService>(QuinoCoreServiceSymbols.IAuthorizationService);
  const formatStringService = useService<IFormatStringService>(QuinoCoreServiceSymbols.IFormatStringService);
  const actionExecutor = useService<IActionExecutor>(IActionExecutorSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const shortcutInformationService = useService<IShortcutInformationService>(IShortcutInformationServiceSymbol);
  const layoutActionService = useService<ILayoutActionsService>(LayoutActionsServiceSymbol);
  const clientActionProvider = useService<IClientActionProvider>(IClientActionProviderSymbol);
  const visibleCalculator = useService<IVisibleCalculator>(QuinoCoreServiceSymbols.IVisibleCalculator);
  const enabledCalculator = useService<IEnabledCalculator>(QuinoCoreServiceSymbols.IEnabledCalculator);
  const errorHandlingService = useService<IErrorHandlingService>(IErrorHandlingServiceSymbol);
  const actionFactory = useService<IQuinoActionFactory>(IQuinoActionFactorySymbol);
  const dataService = useService<IDataService>(QuinoCoreServiceSymbols.IDataService);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const dataSourceFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const standardActionsService = useService<IStandardBookmarkActionsService>(StandardBookmarkActionsServiceSymbol);

  const [gridMode, setGridMode] = useState<TGridEditingMode | undefined>();
  const [dxDataGrid, setDxDataGrid] = useState<dxDataGrid>();
  const [dxDataGridHtmlElement, setDxDataGridHtmlElement] = useState<Element>();
  const [takeOverFilterSettingsPopupCallback, setTakeOverFilterSettingsPopupCallback] = useState<TFilterSettingsPopupCallback>(undefined);
  const [deleteFilterSettingsPopupCallback, setDeleteFilterSettingsPopupCallback] = useState<TFilterSettingsPopupCallback>(undefined);
  const [noFilterSettingsPopupCallback, setNoFilterSettingsPopupCallback] = useState<TFilterSettingsPopupCallback>(undefined);
  const [selectionMode, setSelectionMode] = useState<'allPages' | 'page'>('page');
  const [editBookmark, setEditBookmark] = useState<IObjectBookmark | undefined>(undefined);

  const shortcutInformation = useMemo<IShortcutInformation>(() => shortcutInformationService.get(), [shortcutInformationService]);
  const currentBookmark = props.bookmark;
  const activeBookmark = navigationService.active;
  const responsiveMode = useResponsiveMode();
  const { container } = useContext(ContainerContext);
  const { currentContext } = useContext(QuinoContextContext);
  const isDataSource = useMemo(() => props.source instanceof DataSource, [props.source]);
  const [filterState, setFilterState] = useFilterState(props.useHeaderFilter);
  const editLayout = useMemo(() => (props.layout && isIMetaLayout(props.layout) ? props.layout : undefined), [props.layout]);
  const navigationBookmark = useOnNavigation();

  const canCreate = useMemo(
    () => props.metaClass && authorizationService.getAuthorization(props.metaClass.name).canCreate(),
    [authorizationService, props.metaClass]
  );

  const canEdit = useMemo(
    () => props.metaClass && authorizationService.getAuthorization(props.metaClass.name).canUpdate(),
    [authorizationService, props.metaClass]
  );

  const canRead = useMemo(
    () => props.metaClass && authorizationService.getAuthorization(props.metaClass.name).canRead(),
    [authorizationService, props.metaClass]
  );

  const canDelete = useMemo(
    () => props.metaClass && authorizationService.getAuthorization(props.metaClass.name).canDelete(),
    [authorizationService, props.metaClass]
  );

  const rowSelectionEnabled = props.selectionMode === 'Multiple';
  const inlineEditingEnabled = props.useInlineEditing && canEdit && responsiveMode !== TResponsiveMode.Phone;

  const updateInlineEditChangeStatusOnBookmark = useCallback(
    (pendingChanges: boolean) => currentBookmark && isIListBookmark(currentBookmark) && currentBookmark.setHasInlineEditChanges(pendingChanges),
    [currentBookmark]
  );

  useEffect(() => {
    if (isStateFullBookmark(activeBookmark)) {
      let dismissed = false;
      const subscriptionSymbol = activeBookmark.subscribeToStateChange((changeKey) => {
        if (changeKey === bookmarkStateTabIndexKey) {
          !dismissed && dxDataGrid?.hideColumnChooser();
        }
      });
      return () => {
        dismissed = true;
        activeBookmark.unsubscribeFromStateChange(subscriptionSymbol);
      };
    }

    return undefined;
  }, [dxDataGrid, activeBookmark]);

  const onModeSwitch = useCallback(
    (e: ButtonGroupClickEvent) => {
      const newMode = e.itemData.value;
      if (dxDataGrid && gridMode !== newMode) {
        const pendingChanges =
          (gridMode === TGridEditingMode.Selection && dxDataGrid.getSelectedRowsData().length > 0) ||
          (gridMode === TGridEditingMode.InlineEditing && dxDataGrid.hasEditData());
        if (pendingChanges) {
          feedbackService
            .getConfirmation(
              translationService.translate(
                gridMode === TGridEditingMode.Selection ? 'QuinoDataGrid.EditModeDiscardSelection.Title' : 'QuinoDataGrid.EditModeDiscardEdits.Title'
              ),
              translationService.translate(
                gridMode === TGridEditingMode.Selection ? 'QuinoDataGrid.EditModeDiscardSelection.Text' : 'QuinoDataGrid.EditModeDiscardEdits.Text'
              ),
              translationService.translate('Discard'),
              'material-icons-outlined replay',
              gridMode === TGridEditingMode.InlineEditing ? translationService.translate('ReturnToEditor') : undefined,
              gridMode === TGridEditingMode.InlineEditing ? 'material-icons-outlined edit' : undefined
            )
            .then((discardChangesConfirmed) => {
              if (discardChangesConfirmed) {
                if (gridMode === TGridEditingMode.InlineEditing) {
                  updateInlineEditChangeStatusOnBookmark(false);
                  dxDataGrid.cancelEditData();
                  setGridMode(newMode);
                } else if (TGridEditingMode.Selection) {
                  dxDataGrid
                    .deselectAll()
                    .then(() => {
                      // Timeout is used to make sure the onSelectionChanged is over when setting the new grid mode
                      setTimeout(() => setGridMode(newMode), 0);
                    })
                    .catch(logger.logError);
                }
              } else {
                e.component.option('selectedItemKeys', [gridMode]);
              }
            })
            .catch(logger.logError);
        } else {
          setGridMode(newMode);
        }

        // fixes Bug 10431: Column Chooser: Breaks when switching between Inline Edit and Selection Mode
        const columnChooserView = (dxDataGrid as any).getView('columnChooserView');
        columnChooserView && columnChooserView.render();
      }
    },
    [dxDataGrid, feedbackService, gridMode, logger.logError, translationService, updateInlineEditChangeStatusOnBookmark]
  );

  const modeSwitcher: ToolBarItem | undefined = useMemo(() => {
    if (rowSelectionEnabled && inlineEditingEnabled) {
      return {
        widget: 'dxButtonGroup',
        options: {
          elementAttr: { class: 'quino-grid-mode-switcher' },
          selectionMode: 'single',
          stylingMode: 'outlined',
          keyExpr: 'value',
          selectedItemKeys: [gridMode],
          items: [
            {
              icon: 'material-icons-outlined checklist',
              hint: translationService.translate('QuinoDataGrid.EditMode.Selection'),
              value: TGridEditingMode.Selection
            },
            {
              icon: 'material-icons-outlined drive_file_rename_outline',
              hint: translationService.translate('QuinoDataGrid.EditMode.Inline'),
              value: TGridEditingMode.InlineEditing
            }
          ] as any as IEditModeGroupItem[],
          onItemClick: onModeSwitch
        } as ButtonGroupProperties,
        location: 'before'
      };
    }

    return undefined;
  }, [rowSelectionEnabled, inlineEditingEnabled, gridMode, translationService, onModeSwitch]);

  const inlineEditor = useMemo(() => {
    return inlineEditingEnabled && gridMode === TGridEditingMode.InlineEditing ? (
      <Editing
        mode={'batch'}
        allowAdding={canCreate}
        allowUpdating={canEdit}
        allowDeleting={canDelete}
        useIcons={true}
        selectTextOnEditStart={true}
        newRowPosition={props.inlineEditRecordPosition}
      />
    ) : (
      <></>
    );
  }, [canCreate, canDelete, canEdit, gridMode, inlineEditingEnabled, props.inlineEditRecordPosition]);

  const getMetaProperty = useCallback(
    (elementPath: string | undefined): IMetaProperty | undefined =>
      (isIMetaGroup(props.layout) &&
        (props.layout.elements.find((element) => isIMetaProperty(element) && elementPath === getElementPath(element, true)) as IMetaProperty)) ||
      undefined,
    [props.layout]
  );

  const getRelatedProperty = useCallback(
    (className: string, pathElements: string[]): IMetaProperty | undefined => {
      const metaClass = metadataTree.getClass(className);
      const name = pathElements.shift();
      if (pathElements.length === 0) {
        return metaClass.properties.concat(metaClass.relations).find((m) => m.path === name);
      }
      const rel = metaClass.relations.find((r) => r.path === name);
      return rel ? getRelatedProperty(rel.targetClass, pathElements) : undefined;
    },
    [metadataTree]
  );

  const isValueListMetaProperty = useCallback(
    (elementPath: string | undefined): boolean => {
      const metaClassName = props.metaClass?.name ?? (isIMetaClassAwareBookmark(currentBookmark) ? currentBookmark.metaClass.name : '');
      return elementPath ? isValueList(getRelatedProperty(metaClassName, elementPath.split('.'))) : false;
    },
    [props.metaClass, currentBookmark, getRelatedProperty]
  );

  const rowActionsFromLayout = useMemo(
    () =>
      isILayoutAwareBookmark(currentBookmark)
        ? layoutActionService.getListRowActions(currentBookmark.layout, isIRefreshableBookmark(currentBookmark) ? currentBookmark : undefined)
        : undefined,
    [currentBookmark, layoutActionService]
  );

  const editRowAction: IQuinoAction | undefined = useMemo(() => {
    return props.showPopupEditor && canRead
      ? {
          icon: canEdit ? 'material-icons-outlined edit' : 'material-icons-outlined visibility',
          caption: translationService.translate(canEdit ? 'Edit' : 'View'),
          hint: translationService.translate(canEdit ? 'Edit' : 'View'),
          visible: true,
          disabled: false,
          onClick: (args) => {
            const { source } = args;
            if (isGenericObject(source) && source.primaryKey) {
              const layout = layoutResolver.resolveSingle({
                metaClass: source.metaClass,
                type: LayoutType.Detail,
                element: isILayoutAwareBookmark(currentBookmark) && currentBookmark.layout ? currentBookmark.layout : undefined,
                data: source
              });
              dataSourceFactory
                .fetch(source.primaryKey, layout, source.metaClass)
                .then((object: any) => setEditBookmark(bookmarkFactory.createObject(object, layout)))
                .catch(logger.logError);
            } else {
              logger.logError(`Cannot edit object: ${source}`);
            }
          }
        }
      : undefined;
  }, [bookmarkFactory, canEdit, canRead, currentBookmark, dataSourceFactory, layoutResolver, logger, props.showPopupEditor, translationService]);

  const deleteRowAction: IQuinoAction | undefined = useMemo(() => {
    if (props.showDelete) {
      const symbol = Symbol.for('QuinoDataGrid.DeleteSelected');
      return {
        caption: translationService.translate('Delete'),
        hint: translationService.translate('Delete'),
        icon: 'material-icons-outlined delete',
        visible: true,
        disabled: false,
        onClick: (args) => {
          const { source } = args;
          if (isGenericObject(source)) {
            feedbackService
              .getConfirmation(
                translationService.translate('Delete'),
                source.title
                  ? translationService.translate('QuinoDataGrid.DeleteSingle', { objectTitle: source.title })
                  : translationService.translate('QuinoDataGrid.DeleteSingleFallbackText'),
                translationService.translate('Delete'),
                'material-icons-outlined delete'
              )
              .then((response) => {
                if (response) {
                  metaPanelActionService
                    .delete(source)
                    .then((response) => {
                      if (response) {
                        dxDataGrid?.refresh().catch(console.error);
                        notify(
                          {
                            message: translationService.translate('DeleteConfirmation'),
                            area: 'global',
                            type: 'success',
                            autoDisappear: true
                          },
                          symbol
                        );
                        if (isIRefreshableBookmark(navigationBookmark)) {
                          navigationBookmark.refresh();
                        }
                      } else {
                        notify({ message: translationService.translate('Detail.ErrorCouldNotDeleteData') }, symbol);
                      }
                    })
                    .catch((e) => errorHandlingService.notifyError(e, 'default', symbol));
                }
              })
              .catch(console.error);
          } else {
            notify({
              message: translationService.translate('Detail.ErrorCouldNotDeleteData'),
              messageDetails: 'The received data is not a generic object.'
            });
          }
        }
      };
    }

    return undefined;
  }, [dxDataGrid, errorHandlingService, feedbackService, metaPanelActionService, navigationBookmark, notify, props.showDelete, translationService]);

  const rowActions = useMemo<{ normal: IQuinoAction[]; overflow: IQuinoAction[] }>(() => {
    const maxVisibleRowActions = props.maxVisibleRowActions > 0 && responsiveMode !== TResponsiveMode.Phone ? props.maxVisibleRowActions : 0;

    let normalActions: IQuinoAction[] = [];
    let overflowActions: IQuinoAction[] = [];

    normalActions.push(...props.rowActions);
    editRowAction && normalActions.push(editRowAction);
    deleteRowAction && normalActions.push(deleteRowAction);

    if (rowActionsFromLayout) {
      normalActions.push(...rowActionsFromLayout.normalActions);
      overflowActions.push(...rowActionsFromLayout.overflowActions);
    }

    // Note: Visibility is calculated based on bookmark to avoid calculations for every row
    // Remove this and add visibility calculation in QuinoDataGridRowAction if calculation should be done based on row data
    if (currentBookmark) {
      normalActions = normalActions.filter((a) => quinoActionPropValue(a.visible, currentBookmark));
      overflowActions = overflowActions.filter((a) => quinoActionPropValue(a.visible, currentBookmark));
    }

    if (normalActions.length > maxVisibleRowActions) {
      overflowActions = [...normalActions.slice(maxVisibleRowActions), ...overflowActions];
      normalActions = normalActions.slice(0, maxVisibleRowActions);
    }

    return { normal: normalActions, overflow: overflowActions };
  }, [props.maxVisibleRowActions, props.rowActions, responsiveMode, editRowAction, deleteRowAction, rowActionsFromLayout, currentBookmark]);

  const actionColumnWidth: number = useMemo(() => {
    if (rowActions.normal.length > 0 || rowActions.overflow.length > 0) {
      const hPadding = 7;
      const actionWidth = 32;
      return (
        2 * hPadding +
        (responsiveMode === TResponsiveMode.Phone
          ? actionWidth
          : rowActions.normal.length * actionWidth + (rowActions.overflow.length > 0 ? actionWidth : 0))
      );
    }

    return 0;
  }, [responsiveMode, rowActions.normal.length, rowActions.overflow.length]);

  const setSelectAllCheckBox = useCallback((value: boolean | undefined) => {
    if (!selectAllCheckBox.current) {
      return;
    }

    if (selectAllCheckBox.current.option('value') !== value) {
      ignoreNextSelectAllCheckBoxValueChangedEvent.current = true;
      selectAllCheckBox.current.option('value', value);
    }
  }, []);

  const stateStoringCustomSave = useCallback(
    async (gridState: any, newFilterState: IFilterState = filterState) => {
      if (JSON.stringify(dxDataGrid?.state()) !== JSON.stringify(gridState)) {
        dxDataGrid?.state(gridState);
      }

      const oldGridState = currentGridState.current;
      currentGridState.current = gridState;
      const extendedGridState = {
        ...gridState,
        quinoFilterState: newFilterState, // filter state should just be saved if initial load is done, otherwise it will overwrite the filter settings
        selectedRowKeys: undefined // do not store selected row keys
      };
      initialFilterStateLoaded.current && (await settingsService.setDataGridFilterSettings(props.stateStorageKey!.toLowerCase(), extendedGridState));

      const sortingChanged =
        currentColumnsSortOrder.current.length > 0 &&
        dxDataGrid &&
        dxDataGrid.getVisibleColumns().filter((column, index) => currentColumnsSortOrder.current[index] !== column.sortOrder).length > 0;
      currentColumnsSortOrder.current = [];
      dxDataGrid?.getVisibleColumns().map((column, index) => (currentColumnsSortOrder.current[index] = column.sortOrder));

      if (
        sortingChanged ||
        newFilterState.mode !== filterState.mode ||
        newFilterState.enabled !== filterState.enabled ||
        (oldGridState && oldGridState.searchText !== gridState.searchText)
      ) {
        await dxDataGrid?.deselectAll();
        setSelectAllCheckBox(false);
      }
    },
    [filterState, dxDataGrid, settingsService, props.stateStorageKey, setSelectAllCheckBox]
  );

  const updateFilterState = useCallback(
    async (newFilter: IFilterState, clearFilter = false, clearValueListColumnFilters = false) => {
      if (clearFilter) {
        dxDataGrid?.clearFilter();
        dxDataGrid?.getVisibleColumns().forEach((column) => {
          column.selectedFilterOperation = undefined;
          column.filterValue = undefined;
          column.filterType = undefined;
        });
      }

      // we need to put the filter state to the grid state, because we can not determine the filter state out of the grid state
      let currentDataGridState = dxDataGrid?.state();
      if (clearFilter) {
        const columns: any[] = [];
        currentDataGridState.columns.forEach((column: any) => {
          columns.push({ ...column, filterValue: null, filterType: null });
        });
        currentDataGridState = { ...currentDataGridState, filterValue: null, columns: columns };
      }

      // Clear column filters for value lists to prevent erroneous filter combinations
      if (
        clearValueListColumnFilters &&
        currentDataGridState.columns &&
        currentDataGridState.filterValue &&
        Array.isArray(currentDataGridState.filterValue)
      ) {
        currentDataGridState.columns.forEach((column: any) => {
          if (column.dataField && isValueList(isValueListMetaProperty(column.dataField))) {
            column.selectedFilterOperation = undefined;
            column.filterValue = undefined;
            column.filterValues = undefined;
            column.filterType = undefined;
          }
        });
      }

      await stateStoringCustomSave(currentDataGridState, newFilter);
      return newFilter;
    },
    [dxDataGrid, isValueListMetaProperty, stateStoringCustomSave]
  );

  const onFilterChange = async (newFilterState: IFilterState) =>
    new Promise<IFilterState>((resolve, reject) => {
      if (!props.stateStorageKey) {
        currentGridState.current = newFilterState;
        resolve(newFilterState);
        return;
      }

      const anyColumnFilters: boolean =
        (dxDataGrid && dxDataGrid.getVisibleColumns().filter((column) => column.filterValue != null).length > 0) || false;
      const anyGridFilters: boolean = (dxDataGrid && dxDataGrid.state().filterValue != null) || false;

      if (newFilterState.enabled) {
        if ((anyColumnFilters || anyGridFilters) && filterState.mode === FilterMode.simple && newFilterState.mode === FilterMode.advanced) {
          settingsService
            .getDataGridFilterRememberChoiceSettings(props.stateStorageKey)
            .then((rememberChoiceSettings) => {
              if (rememberChoiceSettings && rememberChoiceSettings.takeOverFilterSettings !== undefined) {
                updateFilterState(newFilterState, !rememberChoiceSettings.takeOverFilterSettings, true).then(resolve).catch(reject);
              } else {
                setTakeOverFilterSettingsPopupCallback(() => async (accept: boolean, rememberChoice: boolean) => {
                  if (props.stateStorageKey && rememberChoice) {
                    await settingsService.setDataGridFilterRememberChoiceSettings(props.stateStorageKey, {
                      ...rememberChoiceSettings,
                      takeOverFilterSettings: accept
                    });
                  }
                  updateFilterState(newFilterState, !accept, true).then(resolve).catch(reject);
                });
              }
            })
            .catch(reject);
        } else if (anyGridFilters && filterState.mode === FilterMode.advanced && newFilterState.mode === FilterMode.simple) {
          settingsService
            .getDataGridFilterRememberChoiceSettings(props.stateStorageKey)
            .then((rememberChoiceSettings) => {
              if (rememberChoiceSettings && rememberChoiceSettings.deleteFilterSettings) {
                updateFilterState(newFilterState, true).then(resolve).catch(reject);
              } else {
                setDeleteFilterSettingsPopupCallback(() => async (accept: boolean, rememberChoice: boolean) => {
                  if (props.stateStorageKey && rememberChoice) {
                    await settingsService.setDataGridFilterRememberChoiceSettings(props.stateStorageKey, {
                      ...rememberChoiceSettings,
                      deleteFilterSettings: accept
                    });
                  }
                  if (accept) {
                    resolve(await updateFilterState(newFilterState, true));
                  }
                });
              }
            })
            .catch(reject);
        } else {
          updateFilterState(newFilterState).then(resolve).catch(reject);
        }
      } else if ((anyColumnFilters || anyGridFilters) && filterState.enabled && !newFilterState.enabled) {
        settingsService
          .getDataGridFilterRememberChoiceSettings(props.stateStorageKey)
          .then((rememberChoiceSettings) => {
            if (rememberChoiceSettings && rememberChoiceSettings.noFilterSettings) {
              updateFilterState(newFilterState, true).then(resolve).catch(reject);
            } else {
              setNoFilterSettingsPopupCallback(() => async (accept: boolean, rememberChoice: boolean) => {
                if (props.stateStorageKey && rememberChoice) {
                  await settingsService.setDataGridFilterRememberChoiceSettings(props.stateStorageKey, {
                    ...rememberChoiceSettings,
                    noFilterSettings: accept
                  });
                }
                if (accept) {
                  resolve(await updateFilterState(newFilterState, true));
                }
              });
            }
          })
          .catch(reject);
      } else {
        updateFilterState(newFilterState).then(resolve).catch(reject);
      }
    });

  const filterButton = useFilterButton({
    simpleEnabled: props.useHeaderFilter,
    advancedEnabled: props.useFilterPanel,
    onChange: onFilterChange,
    state: filterState,
    setState: setFilterState
  });

  const selectAllCheckBoxTicked = useCallback(
    async (options: any | null = null) => {
      if (!dxDataGrid || !selectAllCheckBox.current) {
        return;
      }

      if (ignoreNextSelectAllCheckBoxValueChangedEvent.current) {
        ignoreNextSelectAllCheckBoxValueChangedEvent.current = false;
        return;
      }

      const selectedCount = dxDataGrid.getSelectedRowsData().length;
      const visibleCount = dxDataGrid.getVisibleRows().length;

      if (options !== null) {
        // check box click
        if (selectionMode === 'page') {
          if (options.previousValue === undefined) {
            selectAllCheckBox.current.option('value', selectAllStateChanged.current ? undefined : false);
            return;
          } else if (options.value === true) {
            selectAllCheckBox.current.option('value', undefined);
            return;
          }
        }
        const shouldSelectAll = selectionMode === 'page' && (options.value === undefined || selectedCount != visibleCount) ? true : options.value;
        let shouldDeselectAll = false;
        try {
          if (previouslyVisibleIds.current.length > 0 && selectionMode === 'page') {
            dxDataGrid.clearSelection();
            await dxDataGrid.selectRows(previouslyVisibleIds.current, false);
            previouslyVisibleIds.current = [];
          } else if (shouldSelectAll) {
            dxDataGrid.clearSelection();
            await dxDataGrid.selectAll();
          } else {
            shouldDeselectAll = true;
            await dxDataGrid.deselectAll();
          }
        } catch (e) {
          logger.logError(e);
        }
        selectAllStateChanged.current = false;
        if (selectionMode === 'allPages' && shouldSelectAll) {
          setSelectAllCheckBox(shouldSelectAll);
        } else if (selectionMode === 'allPages' && shouldDeselectAll) {
          setSelectAllCheckBox(false);
        }
      } else {
        // selection change
        let selectAllValue: boolean | undefined = undefined;
        if ((selectionMode === 'page' && selectedCount > 0) || (selectionMode === 'allPages' && selectedCount > 0 && selectAllStateChanged.current)) {
          selectAllValue = undefined;
        } else if (selectedCount === 0) {
          selectAllValue = false;
        } else if (visibleCount <= selectedCount) {
          selectAllValue = true;
        }

        setSelectAllCheckBox(selectAllValue);
      }
    },
    [dxDataGrid, logger, selectionMode, setSelectAllCheckBox]
  );

  const onDrilldown = useCallback(
    (data: IGenericObject) => {
      const layout = isILayoutAwareBookmark(currentBookmark) ? currentBookmark.layout : undefined;
      props.actions.drilldown(data, layout);
    },
    [currentBookmark, props.actions]
  );

  const { columns, columnAggregationAspects } = useMemo((): {
    columns: React.ReactElement[];
    columnAggregationAspects: IQuinoDataGridColumnAggregation[];
  } => {
    const columns: React.ReactElement[] = [];
    const columnAggregationAspects: IQuinoDataGridColumnAggregation[] = [];
    if (props.layout != null && isIMetaGroup(props.layout)) {
      const metaProperties = props.layout.elements.filter((element) => isIMetaProperty(element));
      metaProperties.forEach((x: IMetaProperty, index) => {
        const column = columnFactory.generate(
          x,
          !isDataSource,
          props,
          onDrilldown,
          canEdit,
          dxDataGrid,
          actionColumnWidth + selectColumnWidth,
          metaProperties.length - index,
          gridMode === TGridEditingMode.InlineEditing,
          editLayout
        );
        column && columns.push(column);
        const columnAggregationAspect = getAspectOrDefault<IQuinoDataGridColumnAggregationAspect>(x, QuinoDataGridColumnAggregationAspectIdentifier);
        columnAggregationAspect && columnAggregationAspects.push({ ...columnAggregationAspect, valueFormat: formatStringService.get(x) });
      });
      return { columns, columnAggregationAspects };
    }

    return { columns, columnAggregationAspects };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, columnFactory, isDataSource, onDrilldown, dxDataGrid, actionColumnWidth, formatStringService, currentContext, gridMode]);

  const scrolling = useMemo(() => {
    if (props.usePaging) {
      return <Scrolling key={'scrolling'} useNative={false} showScrollbar={'always'} />;
    }

    return (
      <Scrolling
        key={'scrolling'}
        mode={'infinite'}
        rowRenderingMode={props.useRowVirtualization ? 'virtual' : 'standard'}
        useNative={false}
        showScrollbar={'always'}
      />
    );
  }, [props.usePaging, props.useRowVirtualization]);

  const paging = useMemo(() => {
    return <Paging key={'paging'} enabled={props.usePaging} pageSize={props.itemsPerPage} defaultPageSize={20} />;
  }, [props.usePaging, props.itemsPerPage]);

  const pager = useMemo(() => {
    return (
      <Pager
        key={'pager'}
        visible={props.usePaging}
        showPageSizeSelector={false}
        showNavigationButtons={true}
        showInfo={true}
        infoText={`{2} ${translationService.translate('QuinoDataGrid.GrandTotal')}`}
      />
    );
  }, [props.usePaging, translationService]);

  const createAndAppendContextMenu = useCallback((e: any, items: IQuinoContextMenuItem[]) => {
    if (!e.element) {
      return;
    }

    const div = document.createElement('div');
    const id = 'quino-data-grid-header-filter-panel-settings-button';

    const contextMenu = (
      <ContextMenu
        showEvent={'dxclick'}
        closeOnOutsideClick={true}
        position={{ my: 'right top', at: 'right bottom', offset: { x: 0, y: 2 }, of: '#' + id }}
        items={items}
        onHidden={() => ReactDOM.unmountComponentAtNode(div)}
        onItemClick={(e: ItemClickEvent) => {
          const itemData = e.itemData as IQuinoContextMenuItem;
          itemData.onItemClick && itemData.onItemClick();
        }}
      />
    );

    ReactDOM.render(contextMenu, div);
    e.element.append(div);
    e.element.id = id;
  }, []);

  const addNewBookmark = useCallback(
    async (forPopup: boolean, newBookmark?: IObjectBookmark) => {
      const unload = loadingFeedback.load();
      try {
        if (isIMetaClassAwareBookmark(currentBookmark)) {
          const newObjectBookmark = newBookmark ? newBookmark : await bookmarkFactory.createNewObject(currentBookmark.metaClass.name, activeBookmark);
          if (forPopup) {
            setEditBookmark(newObjectBookmark);
          } else {
            await navigationService.push(newObjectBookmark);
          }
        }
      } catch (error) {
        notify({ message: translationService.translate('Notification.ErrorBoundaryError'), messageDetails: String(error) });
      } finally {
        unload();
      }
    },
    [activeBookmark, bookmarkFactory, currentBookmark, loadingFeedback, navigationService, notify, translationService]
  );

  const toolbarItems = useMemo(() => {
    const newItems: ToolBarItem[] = [];

    props.useSearch &&
      newItems.push({
        // @ts-ignore
        name: 'searchPanel',
        location: 'before'
      });

    if (props.useExport && canRead && dxDataGrid) {
      const exportItems: IQuinoContextMenuItem[] = [];

      exportItems.push(exportButtonFactory.createExcelExportItem(dxDataGrid, props));
      exportItems.push(exportButtonFactory.createPdfExportItem(dxDataGrid, props));

      if (canEdit && canCreate && props.showXmlExport && props.metaClass) {
        if (!props.metaRelation) {
          exportItems.push(exportButtonFactory.createXmlExportItem(dxDataGrid, props.metaClass.name, notify));
        } else {
          const filter = new Map<string, string>();
          filter.set(
            props.metaRelation.targetProperties[0],
            (props.bookmark as any).parentBookmark.genericObject[props.metaRelation.sourceProperties[0]]
          );
          exportItems.push(exportButtonFactory.createXmlExportItem(dxDataGrid, props.metaClass.name, notify, filter));
        }
      }

      newItems.push(exportButtonFactory.createExportButton(exportItems, props.layout, props.metaClass));
    }

    // filter
    responsiveMode !== TResponsiveMode.Phone && filterButton && newItems.push(filterButton);

    // mode switcher
    modeSwitcher && newItems.push(modeSwitcher);

    // settings
    if (props.useGroupPanel || props.useColumnChooser) {
      const settingContextMenuItems: IQuinoContextMenuItem[] = [];
      props.useGroupPanel &&
        settingContextMenuItems.push({
          icon: 'material-icons-outlined sort',
          text: translationService.translate('QuinoDataGrid.AdvancedGrouping'),
          onItemClick: undefined
        });

      props.useColumnChooser &&
        settingContextMenuItems.push({
          icon: 'material-icons-outlined view_column',
          text: translationService.translate('QuinoDataGrid.ColumnChooserTitle'),
          onItemClick: dxDataGrid?.showColumnChooser
        });

      responsiveMode !== TResponsiveMode.Phone &&
        newItems.push({
          widget: 'dxButton',
          options: {
            stylingMode: 'text',
            icon: 'material-icons-outlined settings',
            hint: translationService.translate('QuinoDataGrid.Settings'),
            onClick: (e) => createAndAppendContextMenu(e, settingContextMenuItems)
          } as ButtonProperties,
          location: 'after'
        });
    }

    if (
      (props.showPopupCreate || props.showDrillDownEditor) &&
      canCreate &&
      isIListBookmark(currentBookmark) &&
      !isNewObjectBookmark(currentBookmark.parentBookmark)
    ) {
      // popup create button
      if (props.showPopupCreate) {
        const onPopupCreate = (newBookmark?: IBookmark | undefined) => {
          isIObjectBookmark(newBookmark) && addNewBookmark(true, newBookmark).catch((e) => logger.logError(e));
        };
        const listBookmarkCreateActions = standardActionsService.getListBookmarkCreateAction(currentBookmark, onPopupCreate, activeBookmark);
        const settingContextMenuItems = listBookmarkCreateActions.children?.map((action) =>
          actionFactory.convertBookmarkActionToContextMenuItem(action, currentBookmark)
        );

        newItems.push({
          widget: 'dxButton',
          cssClass: 'quino-data-grid-header-panel-settings quino-entity-action-button',
          options: {
            stylingMode: 'contained',
            type: 'default',
            icon: 'material-icons-outlined add_box',
            disabled: gridMode === TGridEditingMode.InlineEditing,
            onClick: async (e) =>
              (!listBookmarkCreateActions.children && addNewBookmark(true).catch((e) => logger.logError(e))) ||
              (settingContextMenuItems && createAndAppendContextMenu(e, settingContextMenuItems))
          } as ButtonOptions,
          location: 'after'
        });
      }

      // drilldown create button
      if (props.showDrillDownEditor) {
        const onDrillDownCreate = (newBookmark?: IBookmark | undefined) => {
          isIObjectBookmark(newBookmark) && addNewBookmark(false, newBookmark).catch((e) => logger.logError(e));
        };
        const listBookmarkCreateActions = standardActionsService.getListBookmarkCreateAction(currentBookmark, onDrillDownCreate, activeBookmark);
        const settingContextMenuItems = listBookmarkCreateActions.children?.map((action) =>
          actionFactory.convertBookmarkActionToContextMenuItem(action, currentBookmark)
        );

        newItems.push({
          widget: 'dxButton',
          cssClass: 'quino-data-grid-header-panel-settings quino-entity-action-button',
          options: {
            stylingMode: 'contained',
            type: 'default',
            icon: 'material-icons-outlined add',
            disabled: gridMode === TGridEditingMode.InlineEditing,
            onClick: async (e) =>
              (!listBookmarkCreateActions.children && addNewBookmark(false).catch((e) => logger.logError(e))) ||
              (settingContextMenuItems && createAndAppendContextMenu(e, settingContextMenuItems))
          } as ButtonOptions,
          location: 'after'
        });
      }
    }

    return newItems;
  }, [
    actionFactory,
    activeBookmark,
    addNewBookmark,
    canCreate,
    canEdit,
    canRead,
    createAndAppendContextMenu,
    currentBookmark,
    dxDataGrid,
    exportButtonFactory,
    filterButton,
    gridMode,
    logger,
    modeSwitcher,
    notify,
    props,
    responsiveMode,
    standardActionsService,
    translationService
  ]);

  const updateValueListFilterDisplayValue = useCallback(
    (dataGrid: dxDataGrid | undefined, filterValue: any, dataField: any = undefined, reset = false): string => {
      let newValue = 'All';
      const basePropertyName = typeof dataField === 'string' ? dataField : undefined;
      const metaClassName = props.metaClass?.name ?? (isIListBookmark(currentBookmark) ? currentBookmark.metaClass.name : undefined);
      const property = metaClassName && basePropertyName ? getRelatedProperty(metaClassName, basePropertyName.split('.')) : undefined;

      // filter value: [[["Category","=",2],["Category","=",3]]];
      if (property && filterValue && filterValue.length > 0 && filterValue[0] && filterValue[0].length > 2) {
        const aspect = getAspectOrDefault<IValueListAspect>(property, 'ValueList')?.options;
        if (aspect) {
          let selectedCount: number;
          const columnOptions = dataGrid?.columnOption(dataField);
          if (columnOptions != undefined) {
            selectedCount = columnOptions && columnOptions.filterValues ? columnOptions.filterValues.length : 0;
          } else {
            selectedCount = currentGridState.current.filterValue.length > 2 ? currentGridState.current.filterValue[2].length : 0;
          }

          if (
            (columnOptions && columnOptions.filterType === 'exclude') ||
            (currentGridState.current.filterValue && currentGridState.current.filterValue[1] === 'noneof')
          ) {
            const uniqueCount = aspect.length;
            selectedCount = uniqueCount - selectedCount;
          }

          if (selectedCount === 1) {
            aspect.forEach((value) => {
              if (
                (columnOptions && columnOptions.filterType === 'exclude') ||
                (currentGridState.current.filterValue && currentGridState.current.filterValue[1] === 'noneof')
              ) {
                if (!JSON.stringify(filterValue).includes(value.Value)) {
                  newValue = value.Caption;
                }
              } else {
                if (value.Value === filterValue[0][2]) {
                  newValue = value.Caption;
                }
              }
            });
          } else {
            newValue = translationService.translate('QuinoDataGrid.MultipleFilterSelected', { selectedCount });
          }

          if (reset) {
            newValue = ' ';
          }

          const filterButton = document.getElementById(`customValueListFilterButtonFor${basePropertyName}`);
          const filterButtonSpans = filterButton && filterButton.getElementsByTagName('span');
          if (filterButtonSpans && filterButtonSpans.length > 0) {
            filterButtonSpans[0].textContent = newValue;
          }
        }
      }

      return newValue;
    },
    [props.metaClass, currentBookmark, getRelatedProperty, translationService]
  );

  const stateStoringCustomLoad = useCallback(async (): Promise<any> => {
    const filterSettings = await settingsService.getDataGridFilterSettings(props.stateStorageKey!.toLowerCase());
    if (!initialFilterStateLoaded.current) {
      setFilterState(filterSettings?.quinoFilterState != null ? filterSettings.quinoFilterState : { enabled: false, mode: FilterMode.simple });
      initialFilterStateLoaded.current = true;
    }
    currentGridState.current = filterSettings;
    return filterSettings;
  }, [settingsService, props.stateStorageKey, setFilterState]);

  const deleteSelectedRowsWithoutConfirmation = useCallback(
    (gridComponent: dxDataGrid) => {
      const symbol = Symbol.for('QuinoDataGrid.DeleteSelected');
      Promise.resolve(gridComponent.getSelectedRowsData())
        .then((selectedRows) => {
          if (selectedRows.length > 0) {
            metaPanelActionService
              .delete(selectedRows)
              .then((response) => {
                if (response) {
                  gridComponent.clearSelection();
                  notify(
                    {
                      message: translationService.translate('DeleteConfirmation'),
                      area: 'global',
                      type: 'success',
                      autoDisappear: true
                    },
                    symbol
                  );
                } else {
                  notify({ message: translationService.translate('Detail.ErrorCouldNotDeleteData') }, symbol);
                }
              })
              .catch((e) => errorHandlingService.notifyError(e, 'default', symbol))
              .finally(() => {
                gridComponent.refresh().catch(console.error);
              });
          }
        })
        .catch(console.error);
    },
    [errorHandlingService, metaPanelActionService, notify, translationService]
  );

  const deleteSelectedRows = useCallback(
    (gridComponent: dxDataGrid) => {
      feedbackService
        .getConfirmation(
          translationService.translate('Delete'),
          translationService.translate('QuinoDataGrid.DeleteMultiple', {
            itemsCount: gridComponent.getSelectedRowsData().length,
            listTitle: currentBookmark ? titleCalculator.generate(currentBookmark) : ''
          })
        )
        .then((response) => {
          if (response) {
            deleteSelectedRowsWithoutConfirmation(gridComponent);
          }
        })
        .catch(console.error);
    },
    [feedbackService, translationService, currentBookmark, titleCalculator, deleteSelectedRowsWithoutConfirmation]
  );

  const onKeyDown = useCallback(
    async (e: any) => {
      if (!e.event || !isKeyboardEvent(e.event)) {
        return;
      }

      if (e.event.key === 'Enter') {
        if (gridMode !== TGridEditingMode.InlineEditing && props.drillDownMode !== 'None') {
          const rowIndex = dxDataGrid?.option().focusedRowIndex;
          const rowKey = rowIndex && dxDataGrid?.getKeyByRowIndex(rowIndex);
          const rowData = await dxDataGrid?.byKey(rowKey);
          onDrilldown(rowData);
        } else if (gridMode === TGridEditingMode.InlineEditing) {
          e.event.preventDefault();
        }
      } else if (
        isShiftAndCtrlOrCmdEvent(e.event) &&
        e.event.key.toLowerCase() === shortcutSettings.forceDeleteDetailPrimary.key.toLowerCase() &&
        canDelete
      ) {
        e.event.preventDefault();
        dxDataGrid && deleteSelectedRowsWithoutConfirmation(dxDataGrid);
      } else if (isCtrlOrCmdEvent(e.event) && e.event.key.toLowerCase() === shortcutSettings.deleteDetailPrimary.key.toLowerCase() && canDelete) {
        e.event.preventDefault();
        dxDataGrid && deleteSelectedRows(dxDataGrid);
      }
    },
    [gridMode, props.drillDownMode, shortcutSettings, canDelete, dxDataGrid, onDrilldown, deleteSelectedRowsWithoutConfirmation, deleteSelectedRows]
  );

  const actionButtons = useMemo(() => {
    return rowActions.normal.length > 0 || rowActions.overflow.length > 0 ? (
      <Column
        key={'rowActions'}
        cssClass={'quino-data-grid-action-column'}
        type={'buttons'}
        fixed={true}
        fixedPosition={'right'}
        allowResizing={false}
        allowReordering={false}
        visible={true}>
        {rowActions.normal.map((a, i) => (
          <DataGridButton key={'action-' + i} render={(e: any) => <QuinoDataGridRowAction genericObject={e.data} action={a} />} />
        ))}
        {rowActions.overflow.length > 0 && (
          <DataGridButton
            key={'overflow'}
            render={(e: any) => (
              <QuinoDataGridRowActionOverflow
                genericObject={e.data}
                actions={rowActions.overflow}
                buttonHint={translationService.translate('ActionArea.MoreActions')}
                rowKey={e.key}
              />
            )}
          />
        )}
      </Column>
    ) : (
      <></>
    );
  }, [rowActions.normal, rowActions.overflow, translationService]);

  const existingSelectAllContextMenuParent = useRef<Element | null>(null);
  const disposeExistingSelectAllContextMenu = useCallback(() => {
    if (existingSelectAllContextMenuParent.current) {
      ReactDOM.unmountComponentAtNode(existingSelectAllContextMenuParent.current);
    }
  }, []);

  const onCellPrepared = useCallback(
    (e: CellPreparedEvent) => {
      if (!e.component || !e.cellElement) {
        return;
      }

      // works as row height to guarantee virtual scrolling
      e.cellElement.style.height = '36px';

      // customize select all header cell
      if (
        e.rowType === 'header' &&
        e.columnIndex === 0 &&
        rowSelectionEnabled &&
        gridMode === TGridEditingMode.Selection &&
        props.allowSelectAll !== false
      ) {
        const buttonId = 'quino-ecui-data-grid-select-mode-button-' + props.stateStorageKey;

        const onItemClick = (e: any): void => {
          const newSelectionMode = e.itemIndex === 0 ? 'page' : 'allPages';
          const selectionModeChanged = newSelectionMode !== selectionMode;
          if (selectionModeChanged) {
            if (newSelectionMode == 'page') {
              previouslyVisibleIds.current = dxDataGrid?.getVisibleRows().map((c: any) => c.key) ?? [];
            } else {
              dxDataGrid?.deselectAll();
            }
          }
          setSelectionMode(newSelectionMode);

          if (selectionModeChanged) {
            selectAllCheckBox.current && selectAllCheckBox.current.option('value', false);
            const contentReadyEventHandler = (): void => {
              dxDataGrid?.off('contentReady', contentReadyEventHandler);
              selectAllCheckBox.current && selectAllCheckBox.current.option('value', true);
            };
            dxDataGrid?.on('contentReady', contentReadyEventHandler);
          } else {
            selectAllCheckBox.current && selectAllCheckBox.current.option('value', true);
          }
        };

        const onShowing = (e: any): void =>
          e.component!.option('items', [
            {
              text: translationService.translate('QuinoDataGrid.SelectAll.Page', {
                itemCount: dxDataGrid?.getVisibleRows().length ?? '?'
              }),
              selected: selectionMode === 'page'
            },
            {
              text: translationService.translate('QuinoDataGrid.SelectAll.AllPages'),
              selected: selectionMode === 'allPages'
            }
          ]);

        // We have to unmount the previously created <SelectAllContextMenu /> to prevent a memory leak.
        disposeExistingSelectAllContextMenu();
        existingSelectAllContextMenuParent.current = e.cellElement;

        const SelectAllContextMenu: FC = () => (
          <div id={'CellContextMenu'} className={'quino-ecui-data-grid-select-all'}>
            <CheckBox
              className={'dx-datagrid-checkbox-size'}
              onValueChanged={selectAllCheckBoxTicked}
              onInitialized={(options) => (selectAllCheckBox.current = options.component)}
            />
            <Button id={buttonId} stylingMode={'text'} icon={'material-icons-outlined expand_more'} />
            <ContextMenu
              showEvent={'dxclick'}
              closeOnOutsideClick={true}
              position={{ my: 'left top', at: 'left bottom', offset: { x: 0, y: 2 }, of: '#' + buttonId }}
              onShowing={onShowing}
              onItemClick={onItemClick}
            />
          </div>
        );

        ReactDOM.render(<SelectAllContextMenu />, e.cellElement);
      }
    },
    [
      rowSelectionEnabled,
      gridMode,
      props.stateStorageKey,
      disposeExistingSelectAllContextMenu,
      selectionMode,
      dxDataGrid,
      translationService,
      selectAllCheckBoxTicked,
      props.allowSelectAll
    ]
  );

  const executeAction = useCallback(
    async (action: IMetaAction, selection: IGenericObject[]) => {
      const confirmActionAspect = getAspectOrDefault<IConfirmationActionAspect>(action, ConfirmationActionAspectIdentifier);

      if (confirmActionAspect) {
        const feedback = await actionFactory.getConfirmationActionFeedback(action, confirmActionAspect);
        if (feedback === ActionConfirmationFeedbackValue.Cancel) {
          return;
        }
      }

      const unload = loadingFeedback.load();
      const promises: Promise<IMetaActionExecutionResponse>[] = [];
      for (const genericObject of selection) {
        promises.push(actionExecutor.execute(action, genericObject));
      }
      Promise.all(promises)
        .then(() => {
          if (isIListBookmark(currentBookmark)) {
            currentBookmark.reloadDataSource();
            notify({
              message: translationService.translate('Action.Executed', { name: action.caption }),
              area: 'global',
              type: 'success',
              autoDisappear: true
            });
          }
        })
        .finally(unload)
        .catch((reason) => console.error(reason));
    },
    [actionExecutor, actionFactory, currentBookmark, loadingFeedback, notify, translationService]
  );

  const executeClientAction = useCallback(
    (clientActionCallback: (args: IQuinoActionArgs) => void, selection: IGenericObject[]) => {
      const unload = loadingFeedback.load();
      selection.forEach((o) => clientActionCallback({ source: o }));
      unload();
    },
    [loadingFeedback]
  );

  const selectionActionsFromLayout = useMemo(
    () => (isILayoutAwareBookmark(currentBookmark) ? layoutActionService.getListSelectionActions(currentBookmark.layout) : undefined),
    [currentBookmark, layoutActionService]
  );

  const generateSelectionActionsFromLayout = useCallback(
    (selection: IGenericObject[]): IListSelectionAction[] => {
      const createSelectionAction = (actions: IMetaAction[] | undefined, isOverflow?: boolean): IListSelectionAction[] => {
        return actions
          ? actions.map((action) => {
              const clientAction = clientActionProvider.find(action);
              const iconAspect = getAspectOrDefault<IIconAspect>(action, IconAspectIdentifier);

              return {
                icon: iconAspect?.icon ?? 'material-icons-outlined bolt',
                caption: action.caption,
                onClick: clientAction ? () => executeClientAction(clientAction.onClick, selection) : async () => executeAction(action, selection),
                isOverflow: isOverflow,
                visible: action.visible !== undefined ? visibleCalculator.calculate(action, currentBookmark) : true,
                disabled: !(action.enabled !== undefined ? enabledCalculator.calculate(action, currentBookmark) : true)
              };
            })
          : [];
      };

      return [
        ...createSelectionAction(selectionActionsFromLayout?.normalActions),
        ...createSelectionAction(selectionActionsFromLayout?.overflowActions, true)
      ];
    },
    [clientActionProvider, currentBookmark, enabledCalculator, executeAction, executeClientAction, selectionActionsFromLayout, visibleCalculator]
  );

  const updateActionBar = useCallback(
    (actions?: IListSelectionAction[]) => {
      ReactDOM.render(
        <QuinoDataGridActionBar
          applicationContainer={container}
          gridMode={gridMode}
          gridComponent={dxDataGrid}
          selectionActions={actions ?? []}
          inlineCreateButtonVisible={canCreate === true && props.showInlineEditingCreateButton}
          numberOfSelectedRows={numberOfSelectedRows.current}
        />,
        actionBarContainer.current
      );
    },
    [canCreate, container, dxDataGrid, gridMode, numberOfSelectedRows, props.showInlineEditingCreateButton]
  );

  const onSelectionChanged = useCallback(
    async (e: SelectionChangedEvent) => {
      let selection = (e.selectedRowsData ?? []) as IGenericObject[];

      if (props.onSelectionChanged != null) {
        props.onSelectionChanged(selection);
      }

      if (e.currentDeselectedRowKeys && e.currentDeselectedRowKeys.length === 1) {
        selectAllStateChanged.current = true;
      }

      await selectAllCheckBoxTicked();
      if (actionBarContainer.current && gridMode === TGridEditingMode.Selection) {
        let selection = ((await dxDataGrid?.getSelectedRowsData()) ?? []) as IGenericObject[];
        const actions: IListSelectionAction[] = [];
        actions.push(...generateSelectionActionsFromLayout(selection));

        if (props.showXmlExport) {
          const exportItems: IListSelectionAction[] = [];

          const excelExportItem = dxDataGrid ? exportButtonFactory.createExcelExportItem(dxDataGrid, props, true) : undefined;
          excelExportItem &&
            exportItems.push({
              caption: excelExportItem.text ?? '',
              onClick: excelExportItem.onItemClick,
              icon: excelExportItem.icon ?? '',
              visible: true,
              disabled: false
            });

          const pdfExportItem = dxDataGrid ? exportButtonFactory.createPdfExportItem(dxDataGrid, props, true) : undefined;
          pdfExportItem &&
            exportItems.push({
              caption: pdfExportItem.text ?? '',
              onClick: pdfExportItem.onItemClick,
              icon: pdfExportItem.icon ?? '',
              visible: true,
              disabled: false
            });

          const xmlExportItem =
            canEdit && canCreate && props.showXmlExport && props.metaClass && dxDataGrid
              ? exportButtonFactory.createXmlExportItem(
                  dxDataGrid,
                  props.metaClass.name,
                  notify,
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                  selection.map((i) => i[props.metaClass!.primaryKey[0]])
                )
              : undefined;
          xmlExportItem &&
            exportItems.push({
              caption: xmlExportItem.text ?? '',
              onClick: xmlExportItem.onItemClick,
              icon: xmlExportItem.icon ?? '',
              visible: true,
              disabled: false
            });

          exportItems.length > 0 &&
            actions.push(
              exportItems.length > 1
                ? {
                    caption: translationService.translate('Export'),
                    icon: 'material-icons-outlined file_upload',
                    children: exportItems,
                    visible: true,
                    disabled: false
                  }
                : exportItems[0]
            );
        }
        if (props.showDelete) {
          actions.push({
            icon: 'material-icons-outlined delete_outline',
            caption: translationService.translate('Delete'),
            onClick: deleteSelectedRows,
            visible: true,
            disabled: false
          });
        }

        numberOfSelectedRows.current = selection.length;
        updateActionBar(actions);
      }
    },
    [
      props,
      selectAllCheckBoxTicked,
      gridMode,
      generateSelectionActionsFromLayout,
      updateActionBar,
      dxDataGrid,
      exportButtonFactory,
      canEdit,
      canCreate,
      notify,
      translationService,
      deleteSelectedRows
    ]
  );

  const onContentReady = useCallback(
    async (grid) => {
      const gridComponent: dxDataGrid | undefined = grid.component;
      const gridElement: HTMLElement | undefined = grid.element;

      if (!gridComponent) {
        return;
      }

      if (props.isLoading) {
        gridComponent.beginCustomLoading(translationService.translate('Loading'));
      }

      if (selectionMode === 'page' && visibleRowCount.current !== gridComponent.getVisibleRows().length) {
        visibleRowCount.current = gridComponent.getVisibleRows().length;
        await selectAllCheckBoxTicked();
      }

      // Workaround to position the column chooser
      const columnChooserView = (gridComponent as any).getView('columnChooserView');
      if (columnChooserView && !columnChooserView._popupContainer) {
        columnChooserView._initializePopupContainer();
        columnChooserView.render();
        columnChooserView._popupContainer.option('position', { of: gridElement, my: 'right top', at: 'right top', offset: '-24 120' });
      }

      // Calculate/freeze column widths
      if (gridElement) {
        let propertiesChanged = false;
        const visibleColumns = gridComponent.getVisibleColumns();
        const gridWidth = gridElement.clientWidth;

        visibleColumns.forEach((column) => {
          // This is currently the only way to get the best fit width calculated by the grid
          const bestFitWidth: number | undefined = (column as any).bestFitWidth;

          const currentColumnWidth = gridComponent.columnOption(column.visibleIndex!, 'width');
          if (
            column.type !== 'groupExpand' &&
            (currentColumnWidth === 'auto' || currentColumnWidth === undefined) &&
            bestFitWidth &&
            column.caption
          ) {
            let columnWidth = bestFitWidth > props.columnMaxWidthPixels ? props.columnMaxWidthPixels : bestFitWidth;

            const maxPercentWidth = Math.floor((gridWidth * props.columnMaxWidthPercent) / 100);
            if (columnWidth > maxPercentWidth && visibleColumns.length >= 100 / props.columnMaxWidthPercent) {
              columnWidth = maxPercentWidth;
            }

            gridComponent.columnOption(column.visibleIndex!, 'width', columnWidth + 'px');
            propertiesChanged = true;
          } else if (typeof column.width === 'string' && column.width.includes('%')) {
            gridComponent.columnOption(column.visibleIndex!, 'width', (parseFloat(column.width) / 100) * gridWidth + 'px');
            propertiesChanged = true;
          }
        });

        const editColumnWidth = gridMode === TGridEditingMode.InlineEditing ? 42 : actionColumnWidth;
        if (gridComponent.columnOption('command:edit', 'width') !== editColumnWidth) {
          gridComponent.columnOption('command:edit', 'width', editColumnWidth);
          propertiesChanged = true;
        }

        if (propertiesChanged) {
          gridComponent.updateDimensions();
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.isLoading,
      props.columnMinWidth,
      props.columnMaxWidthPixels,
      props.columnMaxWidthPercent,
      selectionMode,
      translationService,
      selectAllCheckBoxTicked,
      actionColumnWidth,
      gridMode
    ]
  );

  const onEditorPreparing = useCallback(
    (e: EditorPreparingEvent) => {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.hint = shortcutInformation.hint(shortcutSettings.quickListSearch);
        if (e.element) {
          e.element.id = QuinoDataGridSearchEditorId;
        }
      } else if (e.parentType === 'filterRow') {
        const metaProperty = getMetaProperty(e.dataField);
        if (metaProperty && isValueListMetaProperty(e.dataField)) {
          let text = ' '; // leave the whitespace that the span of the filter button is created
          if (currentGridState.current && currentGridState.current.filterValue && currentGridState.current.filterValue.length > 2) {
            // the saved filter value looks like this for multiple filter: [["Status","anyof",[["Status","=",0]]],"and",["Category","anyof",[["Category","=",2],["Category","=",3]]]]
            // the saved filter value looks like this for single filter:  ['Status', 'anyof', [['Status', '=', 0]]]

            const multipleFilter = currentGridState.current.filterValue.find((f: any) => f === 'and' || f === 'or') != undefined;
            const normalizedFilterValues = multipleFilter ? currentGridState.current.filterValue : [currentGridState.current.filterValue];
            const filterValue = normalizedFilterValues.find((f: any) => Array.isArray(f) && f.find((f2) => f2 === metaProperty.path) != undefined);
            if (filterValue) {
              text = updateValueListFilterDisplayValue(e.component, filterValue[2]);
            }
          }

          e.editorOptions.elementAttr = { class: 'quino-datagrid-valuelist-filter' };

          e.editorOptions.buttons = [
            {
              name: 'headerFilter',
              location: 'before',
              options: {
                stylingMode: 'text',
                elementAttr: {
                  id: `customValueListFilterButtonFor${metaProperty.path}`
                },
                text: text, // leave this that the span is created
                hint: text,
                icon: 'material-icons-outlined filter_alt',
                onClick: (args: any) => {
                  const nonPublicAPI: any = e;
                  nonPublicAPI.component.getController('headerFilter').showHeaderFilterMenu(nonPublicAPI.index);
                  nonPublicAPI.component.getView('headerFilterView').updatePopup(args.element, { alignment: 'right' });
                }
              }
            }
          ];
        }

        const dataType = e.dataField && e.component.columnOption(e.dataField, 'dataType');
        if (dataType === 'boolean') {
          e.editorOptions.valueExpr = 'filterValue';
          e.editorOptions.displayExpr = 'filterText';
          e.editorOptions.dataSource = [
            { filterValue: null, filterText: `(${translationService.translate('QuinoDataGrid.Filter.Boolean.All')})` },
            { filterValue: true, filterText: translationService.translate('QuinoDataGrid.Filter.Boolean.True') },
            { filterValue: false, filterText: translationService.translate('QuinoDataGrid.Filter.Boolean.False') }
          ];

          if (metaProperty && metaProperty.required === false) {
            e.editorOptions.dataSource.push({
              filterValue: ODataBooleanUndefined,
              filterText: translationService.translate('QuinoDataGrid.Filter.Boolean.Undefined')
            });
          }
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [shortcutInformation, shortcutSettings, props.layout]
  );

  const onOptionChanged = (e: { component?: dxDataGrid; element?: Element; model?: any; name?: string; fullName?: string; value?: any }): void => {
    if (e.name === 'columns' && e.fullName?.endsWith('filterValues')) {
      setTimeout(() => {
        if (e.fullName) {
          const colIndex = parseInt(e.fullName.substring(e.fullName.indexOf('[') + 1, e.fullName.lastIndexOf(']')), 10);
          const columns = e.component?.option('columns');
          const column = columns && columns[colIndex];
          const dataField = column && typeof column !== 'string' ? column.dataField : undefined;

          if (e.value == null && Array.isArray((e as any).previousValue)) {
            updateValueListFilterDisplayValue(e.component, (e as any).previousValue, dataField, true);
          } else {
            updateValueListFilterDisplayValue(e.component, e.value, dataField);
          }
        }
      }, 250);
    }

    if (e.name === 'editing' && gridMode === TGridEditingMode.InlineEditing) {
      updateInlineEditChangeStatusOnBookmark(e.component?.hasEditData() ?? false);
      updateActionBar();
    }
  };

  const filterEditingChangeData = useCallback((changeData: any) => {
    const filteredChanges = { ...changeData };
    Object.keys(filteredChanges)
      .filter((key) => typeof filteredChanges[key] === 'object' && filteredChanges[key] !== null)
      .forEach((key) => delete filteredChanges[key]);

    return filteredChanges;
  }, []);

  const onSaving = useCallback(
    async (e: SavingEvent): Promise<void> => {
      e.cancel = true;
      const symbol = Symbol.for('DataGridInlineEditFeedback');
      const className = props.metaClass?.name;
      if (className && e.changes.length > 0) {
        const confirmed = await feedbackService.getConfirmation(
          translationService.translate('QuinoDataGrid.EditModeSaveChanges.Title'),
          translationService.translate('QuinoDataGrid.EditModeSaveChanges.Text', { numberOfDatasets: e.changes.length }),
          translationService.translate('Save'),
          'material-icons-outlined save'
        );

        if (confirmed) {
          const changes: IBatchRequestItem[] = e.changes.map((change) => {
            switch (change.type) {
              case 'remove':
                return {
                  method: 'delete',
                  primaryKey: change.key
                };
              case 'update':
                return {
                  method: 'patch',
                  primaryKey: change.key,
                  data: filterEditingChangeData(change.data)
                };
              case 'insert':
                return {
                  method: 'post',
                  primaryKey: change.key,
                  data: filterEditingChangeData(change.data)
                };
            }
          });

          const result = await dataService.batchProcessObjectsAsync(className, changes);

          const errors = result.filter((r) => r.status !== 200);

          let errorDetailText = '';
          if (errors.length > 0) {
            const currentMetaClass = metadataTree.getClass(className);
            const layout = layoutResolver.resolveSingle({ metaClass: currentMetaClass.name, type: LayoutType.Detail });
            let genericObjects: IGenericObject[] = [];
            if (props.source != undefined) {
              if (props.source instanceof DataSource) {
                genericObjects = props.source.items();
              } else {
                genericObjects = props.source;
              }
            }

            const errorDetailsArray = errors.map((e) => {
              const errorBody = e.body;
              let errorTitle = translationService.translate('QuinoDataGrid.EditModeNewEntry');
              const genericObject = genericObjects.find((object) => `${object[currentMetaClass.primaryKey[0]]}` === e.id);
              if (genericObject) {
                const errorBookmark = bookmarkFactory.createObject(genericObject, layout);
                errorTitle = titleCalculator.generate(errorBookmark);
              }
              let errorText = `${errorTitle}: ${errorBody ? (errorBody.title ? errorBody.title : errorBody.toString()) : 'status ' + e.status}\n`;

              const errorDetails = errorBody?.errors;
              if (errorDetails) {
                Object.keys(errorDetails).forEach((k) => {
                  const property = currentMetaClass.properties.find((metaProperty) => metaProperty.name.toLowerCase() === k.toLowerCase());
                  const caption = property ? property.caption : k;
                  errorText += `- ${caption}: ${errorDetails[k]}\n`;
                });
              }

              return errorText;
            });

            errorDetailText = errorDetailsArray.join('\n');
          }

          if (errors.length === 0) {
            clearNotifications();
            notify(
              {
                message: translationService.translate('QuinoDataGrid.EditModeSaveSuccess'),
                type: 'success',
                area: 'global',
                autoDisappear: true
              },
              symbol
            );

            dxDataGrid?.refresh(true);
            dxDataGrid?.option('editing.editRowKey', null);
            dxDataGrid?.option('editing.editColumnName', null);
            dxDataGrid?.option('editing.changes', []);
          } else if (errors.length < e.changes.length) {
            notify(
              {
                message: translationService.translate('QuinoDataGrid.EditModeSavePartialSuccess'),
                messageDetails: errorDetailText,
                type: 'warning'
              },
              symbol
            );
            const remainingChanges = e.changes.filter((chg) => errors.find((err) => err.id == chg.key));
            dxDataGrid?.option('editing.changes', [...remainingChanges]);
          } else {
            notify({ message: translationService.translate('QuinoDataGrid.EditModeSaveFail'), messageDetails: errorDetailText }, symbol);
          }
        }
      }
    },
    [
      bookmarkFactory,
      clearNotifications,
      dataService,
      dxDataGrid,
      feedbackService,
      filterEditingChangeData,
      layoutResolver,
      metadataTree,
      notify,
      props.metaClass?.name,
      props.source,
      titleCalculator,
      translationService
    ]
  );

  useEffect(() => {
    if (responsiveMode === TResponsiveMode.Phone && filterState.enabled) {
      updateFilterState({ ...filterState, enabled: false }, true)
        .then(setFilterState)
        .catch(console.error);
    }
  }, [filterState, responsiveMode, updateFilterState, setFilterState]);

  useEffect(() => {
    setFilterState({ ...filterState, enabled: false });
    initialFilterStateLoaded.current = false;
  }, [props.stateStorageKey]); // eslint-disable-line react-hooks/exhaustive-deps

  // Mode change
  useEffect(() => {
    updateActionBar();
    gridMode && props.stateStorageKey && settingsService.setDataGridEditModeSettings(props.stateStorageKey, { gridEditorMode: gridMode });
  }, [gridMode, props.stateStorageKey, settingsService, updateActionBar]);

  // Insert Action Bar
  useEffect(() => {
    const dataGridHeader = dxDataGridHtmlElement && dxDataGridHtmlElement.querySelector('.dx-datagrid-headers');
    if (dataGridHeader && actionBarContainer.current) {
      dataGridHeader.parentNode?.insertBefore(actionBarContainer.current, dataGridHeader);
      updateActionBar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dxDataGridHtmlElement]);

  // Set initial grid mode
  useOnMount(() => {
    props.stateStorageKey &&
      settingsService.getDataGridEditModeSettings(props.stateStorageKey).then((settings) => {
        const settingsModeValid =
          settings &&
          ((settings.gridEditorMode === TGridEditingMode.Selection && rowSelectionEnabled) ||
            (settings.gridEditorMode === TGridEditingMode.InlineEditing && inlineEditingEnabled));
        setGridMode(
          settingsModeValid
            ? settings.gridEditorMode
            : rowSelectionEnabled
            ? TGridEditingMode.Selection
            : inlineEditingEnabled
            ? TGridEditingMode.InlineEditing
            : undefined
        );
      });

    // Unmount the latest content (manually mounted) of the Action Bar
    return () => {
      ReactDOM.unmountComponentAtNode(actionBarContainer.current);
    };
  });

  return (
    <>
      <DataGrid
        id={'QuinoDataGrid_' + props.id}
        key={'QuinoDataGrid_' + props.id}
        className={props.className}
        visible={props.visible !== undefined ? props.visible : true}
        width={'100%'}
        height={'100%'}
        hoverStateEnabled={true}
        columnHidingEnabled={responsiveMode === TResponsiveMode.Phone && columns.length > 1}
        remoteOperations={{ filtering: isDataSource, sorting: isDataSource, paging: isDataSource }}
        dataSource={props.source}
        onKeyDown={onKeyDown}
        onDisposing={disposeExistingSelectAllContextMenu}
        onInitNewRow={async (ev) => {
          if (!props.metaClass) return;
          ev.promise = bookmarkFactory
            .createNewObject(props.metaClass.name, isIListBookmark(currentBookmark) ? currentBookmark.parentBookmark : undefined, editLayout?.name)
            .then((bookmark) => {
              ev.data = Object.fromEntries(Object.entries(bookmark.genericObject).filter(([, value]) => value !== null));
            })
            .catch((e) => errorHandlingService.notifyError(e));
        }}
        selection={{
          mode: rowSelectionEnabled && (gridMode === TGridEditingMode.Selection || !gridMode) ? 'multiple' : 'none',
          showCheckBoxesMode: rowSelectionEnabled && (gridMode === TGridEditingMode.Selection || !gridMode) ? 'always' : 'none',
          allowSelectAll: props.allowSelectAll ?? true,
          selectAllMode: selectionMode,
          deferred: (dxDataGrid?.getVisibleRows().length ?? 0) > 0 && selectionMode == 'allPages'
        }}
        stateStoring={{
          enabled: props.stateStorageKey != null,
          type: 'custom',
          savingTimeout: 0,
          customLoad: stateStoringCustomLoad,
          customSave: stateStoringCustomSave
        }}
        onInitialized={(e) => {
          props.onInitialized && props.onInitialized(e);

          setDxDataGridHtmlElement(e.element);
          setDxDataGrid(e.component);

          // Set fixed column sizes
          e.component?.columnOption('command:select', 'width', selectColumnWidth);
          e.component?.columnOption('command:expand', 'width', 24);
        }}
        onCellPrepared={onCellPrepared}
        style={props.style}
        showColumnLines={true}
        showRowLines={true}
        showBorders={false}
        rowAlternationEnabled={false}
        columnAutoWidth={true}
        allowColumnReordering={responsiveMode !== TResponsiveMode.Phone && props.useColumnReordering}
        columnMinWidth={props.columnMinWidth}
        allowColumnResizing={responsiveMode !== TResponsiveMode.Phone && props.useColumnResizing}
        columnResizingMode={'widget'}
        sorting={{ mode: props.useSorting ? (props.useMultipleSorting ? 'multiple' : 'single') : 'none' }}
        onSelectionChanged={onSelectionChanged}
        loadPanel={{ enabled: isDataSource || props.isLoading, showPane: true, showIndicator: true }}
        onContentReady={onContentReady}
        onRowDblClick={
          props.useDoubleClick && props.drillDownMode !== 'None' && gridMode !== TGridEditingMode.InlineEditing
            ? (e) => onDrilldown(e.data)
            : undefined
        }
        onRowClick={
          !props.useDoubleClick && props.drillDownMode !== 'None' && gridMode !== TGridEditingMode.InlineEditing
            ? (e) => onDrilldown(e.data)
            : undefined
        }
        onRowRemoving={(e) => props.actions.delete(e.data)}
        onEditorPreparing={onEditorPreparing}
        filterSyncEnabled={filterState.mode === FilterMode.simple && filterState.enabled}
        onOptionChanged={onOptionChanged}
        onSaving={onSaving}
        onEditCanceled={() => {
          dxDataGrid?.refresh(true);
          clearNotifications();
        }}
        onRowValidating={(e) =>
          !e.isValid && notify({ message: translationService.translate('Notification.ValidationError') }, Symbol.for('DataGridValidation' + props.id))
        }>
        {inlineEditor}
        <Toolbar items={toolbarItems} visible={toolbarItems.length > 0} />
        <Export key={'export'} enabled={props.useExport} fileName={props.id} allowExportSelectedData={rowSelectionEnabled} />
        <FilterRow key={'filterRow'} visible={filterState.enabled && props.useHeaderSearch && filterState.mode === FilterMode.simple} />
        <FilterPanel key={'filterPanel'} visible={filterState.enabled && props.useFilterPanel && filterState.mode === FilterMode.advanced} />
        <ColumnChooser key={'columnChooser'} enabled={props.useColumnSelection} sortOrder={'asc'} />
        <ColumnFixing key={'columnFixing'} enabled={props.useColumnFixing && props.drillDownMode === 'All'} />
        <GroupPanel key={'groupPanel'} visible={props.useGroupPanel} />
        <SearchPanel
          key={'searchPanel'}
          onTextChange={dxDataGrid?.clearSelection}
          visible={props.useSearch}
          highlightCaseSensitive={false}
          highlightSearchText={true}
          searchVisibleColumnsOnly={true}
          placeholder={translationService.translate('SearchList')}
        />
        <Grouping key={'grouping'} contextMenuEnabled={props.useGrouping} />
        {scrolling}
        {props.usePaging && paging}
        {props.usePaging && pager}
        {columns}
        {gridMode !== TGridEditingMode.InlineEditing && actionButtons}
        {props.useAggregation && (
          <Summary>
            {columnAggregationAspects.map((columnAggregation: IQuinoDataGridColumnAggregation) => (
              <TotalItem
                key={'total-' + columnAggregation.columnName}
                column={columnAggregation.columnName}
                summaryType={columnAggregation.aggregationMethod}
                valueFormat={columnAggregation.valueFormat}
              />
            ))}
          </Summary>
        )}
      </DataGrid>
      {takeOverFilterSettingsPopupCallback && (
        <QuinoRememberChoicePopup
          key={'QuinoTakeOverFilterSettingsPopup_' + props.id}
          title={translationService.translate('QuinoDataGrid.Filter.Popup.TakeOverFilter.Title')}
          text={translationService.translate('QuinoDataGrid.Filter.Popup.TakeOverFilter.Text')}
          rememberChoiceText={translationService.translate('QuinoDataGrid.Filter.Popup.RememberChoice')}
          acceptButtonText={translationService.translate('Yes')}
          declineButtonText={translationService.translate('No')}
          hideCallback={() => setTakeOverFilterSettingsPopupCallback(undefined)}
          callback={takeOverFilterSettingsPopupCallback}
        />
      )}
      {deleteFilterSettingsPopupCallback && (
        <QuinoRememberChoicePopup
          key={'QuinoDeleteFilterSettingsPopup_' + props.id}
          title={translationService.translate('QuinoDataGrid.Filter.Popup.DeleteFilter.Title')}
          text={translationService.translate('QuinoDataGrid.Filter.Popup.DeleteFilter.Text')}
          acceptButtonText={translationService.translate('QuinoDataGrid.Filter.Popup.Continue')}
          declineButtonText={translationService.translate('Cancel')}
          rememberChoiceText={translationService.translate('QuinoDataGrid.Filter.Popup.DoNotShowAgain')}
          hideCallback={() => setDeleteFilterSettingsPopupCallback(undefined)}
          callback={deleteFilterSettingsPopupCallback}
        />
      )}
      {noFilterSettingsPopupCallback && (
        <QuinoRememberChoicePopup
          key={'QuinoNoFilterSettingsPopup_' + props.id}
          title={translationService.translate('QuinoDataGrid.Filter.Popup.NoFilter.Title')}
          text={translationService.translate('QuinoDataGrid.Filter.Popup.NoFilter.Text')}
          acceptButtonText={translationService.translate('QuinoDataGrid.Filter.Popup.Continue')}
          declineButtonText={translationService.translate('Cancel')}
          rememberChoiceText={translationService.translate('QuinoDataGrid.Filter.Popup.DoNotShowAgain')}
          hideCallback={() => setNoFilterSettingsPopupCallback(undefined)}
          callback={noFilterSettingsPopupCallback}
        />
      )}
      {(props.showPopupEditor || props.showPopupCreate) && (
        <QuinoPopupEditor
          visible={true}
          bookmark={editBookmark}
          onHide={(action) => {
            if (action === QuinoPopupEditorOnHideAction.createNewEntry) {
              if (editBookmark) {
                const sortPropertyName = standardActionsService.getSortPropertyName(editBookmark!);
                if (sortPropertyName) {
                  const sortValue = editBookmark.genericObject[sortPropertyName];
                  standardActionsService
                    .createNewBookmark(editBookmark, [{ propertyName: sortPropertyName, propertyValue: sortValue + 1 }])
                    .then(async (objectBookmark: IObjectBookmark | undefined) => addNewBookmark(true, objectBookmark).catch(logger.logError))
                    .catch(logger.logError);
                  return;
                }
              }
              addNewBookmark(true).catch((e) => logger.logError(e));
            } else {
              setEditBookmark(undefined);
              isIRefreshableBookmark(navigationBookmark) && navigationBookmark.refresh();
            }
          }}
        />
      )}
    </>
  );
};

QuinoDataGrid.displayName = 'QuinoDataGrid';
