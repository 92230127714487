import React, { PropsWithChildren, useMemo, useRef } from 'react';
import { IObjectBookmark } from '../../bookmarks';
import { QuinoUIServiceSymbols } from '../../ioc';
import { useService } from '../../ioc/hook';
import { IComponentFactory } from '../ComponentFactory';
import { useOnBookmarkReloadEvent, useOnBookmarkResetEvent, useOnBookmarkSavedEvent } from '../Util/BookmarkHooks';
import { useKeepFocusInContainerWhileTabbing, useRerender } from '../Util';
import { IQuinoMetaPanelActions } from '../Types';
import { ScrollView } from 'devextreme-react/scroll-view';

interface IQuinoMetaPropertyPanelProps {
  visible: boolean;
  bookmark: IObjectBookmark;
  actions: IQuinoMetaPanelActions;
  className?: string;
  isPopup?: boolean;
}

/**
 * The property panel is responsible for rendering an object bookmark.
 * @param props - the properties to use.
 */
export function QuinoMetaPropertyPanel(props: PropsWithChildren<IQuinoMetaPropertyPanelProps>) {
  const formFactory = useService<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory);

  const rerender = useRerender();
  useOnBookmarkSavedEvent(props.bookmark, rerender);
  useOnBookmarkResetEvent(props.bookmark, rerender);
  useOnBookmarkReloadEvent(props.bookmark, rerender);

  const quinoMetaPropertyPanelRef = useRef<HTMLDivElement>(null);
  useKeepFocusInContainerWhileTabbing(quinoMetaPropertyPanelRef);

  const rootNodeIsTabContainer = useMemo<boolean>(() => props.bookmark.rootNodeIsTabContainer(), [props.bookmark]);
  const layoutElements = props.bookmark
    .getLayoutElementsWithoutActions()
    .map((element) => formFactory.create(element, props.bookmark, props.actions));

  return (
    <div className={`quino-meta-property-panel ${props.isPopup ? 'is--popup' : ''}`} ref={quinoMetaPropertyPanelRef} key={props.bookmark.rerenderKey}>
      {rootNodeIsTabContainer || props.isPopup ? (
        <>{layoutElements}</>
      ) : (
        <ScrollView
          className={'quino-padded-scrollable-content'}
          useNative={false}
          showScrollbar={'always'}
          onInitialized={(e) => e.component && e.component.option('useKeyboard', false)}>
          {/* The following div is necessary in order to prevent ScrollView crashes.
           If a component is not visible anymore, the ScrollView crashes, wrapping the elements with a div prevents the crash.   */}
          <div>{layoutElements}</div>
        </ScrollView>
      )}
    </div>
  );
}
