import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import { QuinoUIServiceSymbols, useService } from '../../ioc';
import {
  DataType,
  getMetaRelation,
  IGenericObjectFactory,
  IGenericObjectFactorySymbol,
  ILayoutResolver,
  ILayoutResolverSymbol,
  IMetadataTree,
  IMetaLayout,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  isMetaRelation,
  isValueList,
  ITitleCalculator,
  ITitleCalculatorSymbol,
  ITranslationService,
  ITranslationServiceSymbol,
  LayoutType,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { IQuinoEditorProps } from '../Types';
import { ListBookmark } from '../../bookmarks';
import { IQuinoDataGridConfigurationService, QuinoMetaDataGrid } from '../QuinoDataGrid';
import { registerKeyHandlers, useValidation } from '../Util';
import { useDropDownSettings, useLabelSettings, useMetadata } from '../../settings';
import dxDropDownBox from 'devextreme/ui/drop_down_box';
import { QuinoCrossLink } from '../QuinoCrossLink';
import { useValueListSource } from '../Util/ValueListReducer';

type TQuinoSearchEditState = {
  layout: IMetaLayout;
};

export function QuinoSearchEdit(props: IQuinoEditorProps) {
  const { metaProperty, bookmark, propagateValue, hideClearButton, hideNonEditorElements } = props;

  if (!isMetaRelation(metaProperty)) {
    throw new Error('the Search Edit component can currently only handle relations.');
  }

  const metaRelation = getMetaRelation(metaProperty);
  const { description, targetClass } = metaRelation;
  const labelSettings = useLabelSettings(metaRelation);
  const { readOnly, required, enabled } = useMetadata(metaRelation, bookmark.genericObject);
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const titleCalculator = useService<ITitleCalculator>(ITitleCalculatorSymbol);
  const objectFactory = useService<IGenericObjectFactory>(IGenericObjectFactorySymbol);
  const valueService = useService<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol);
  const metaDataGridConfigurationService = useService<IQuinoDataGridConfigurationService>(QuinoUIServiceSymbols.IQuinoDataGridConfigurationService);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const dropDownSettings = useDropDownSettings(metaProperty);
  const [component, setComponent] = useState<dxDropDownBox | undefined>(undefined);
  const [state] = useState<TQuinoSearchEditState>({
    layout: layoutResolver.resolveSingle({ type: LayoutType.List, metaClass: targetClass, element: metaRelation })
  });
  const relatedObject = valueService.getPlainValue(metaRelation, bookmark.genericObject);
  const relatedGeneric = relatedObject != null ? objectFactory.create(relatedObject, metaRelation.targetClass) : null;
  const [isValid] = useValidation(bookmark, metaProperty);
  const defaultData = useMemo(() => {
    return { key: metaRelation.targetProperties[0], data: bookmark.genericObject[metaRelation.path] };
  }, [bookmark.genericObject, metaRelation]);
  const dataSource = useValueListSource(metaRelation, state.layout, defaultData);

  const listBookmark = useMemo(() => {
    const targetMetaClass = metadataTree.getClass(targetClass);
    const listBookmark = new ListBookmark(targetMetaClass, state.layout, []);
    listBookmark.dataSource = dataSource;

    return listBookmark;
  }, [dataSource, metadataTree, state.layout, targetClass]);

  const dataGridOptions = metaDataGridConfigurationService.getSearchEditGridConfiguration(metaRelation);
  const showCrossLink =
    metaRelation.dataType === DataType.Relation &&
    isMetaRelation(metaRelation) &&
    !isValueList(metaRelation) &&
    !hideNonEditorElements &&
    relatedGeneric !== null &&
    dropDownSettings.useCrossLink;

  const displayExpression = useCallback(
    (item: any) => {
      if (item == null) {
        return '';
      }

      if (!isValueList(metaRelation)) {
        return titleCalculator.generate(item, metaRelation.targetClass);
      }

      return item['Caption'];
    },
    [metaRelation, titleCalculator]
  );

  const updateValue = useCallback(
    (pathValue: any, sourcePropertyValue: any) => {
      bookmark.updateFieldValue(metaRelation.sourceProperties[0], sourcePropertyValue);
      bookmark.updateFieldValue(metaRelation.path, pathValue);
      propagateValue && propagateValue(sourcePropertyValue, metaRelation.sourceProperties[0], pathValue);
    },
    [bookmark, metaRelation.path, metaRelation.sourceProperties, propagateValue]
  );

  return (
    <div className={'quino-editor-with-crosslink'}>
      <DropDownBox
        dropDownOptions={{ height: '340px', width: '800px', maxWidth: '95vw' }}
        key={`QuinoSearchEdit_Combo_${metaRelation.path}`}
        dataSource={dataSource}
        displayExpr={displayExpression}
        value={relatedGeneric}
        isValid={isValid}
        deferRendering={true}
        hint={labelSettings.hintType === 'None' ? description : undefined}
        disabled={!enabled}
        readOnly={readOnly}
        showClearButton={!required && !hideClearButton}
        focusStateEnabled={!readOnly}
        hoverStateEnabled={!readOnly}
        visible={!readOnly || !showCrossLink}
        placeholder={readOnly || !enabled ? '' : translationService.translate('Select')}
        openOnFieldClick={true}
        onInitialized={(e) => {
          setComponent(e.component);
          registerKeyHandlers(e.component);
        }}
        onValueChanged={(e) => {
          if (e.value == null) {
            updateValue(null, null);
          }
        }}
        contentRender={() => (
          <QuinoMetaDataGrid
            key={`QuinoSearchEdit_${metaRelation.name}`}
            bookmark={listBookmark}
            visible={true}
            drilldown={(target) => {
              const sourcePropertyValue =
                typeof target[metaRelation.targetProperties[0]] === 'number'
                  ? target[metaRelation.targetProperties[0]]
                  : target[metaRelation.targetProperties[0]].toString();
              updateValue(target, sourcePropertyValue);
              component!.close();
            }}
            gridProperties={{
              stateStorageKey: `QuinoSearchEdit_${metaRelation.targetClass}_${metaRelation.name}`,
              className: 'quino-search-edit-data-grid',
              ...dataGridOptions,
              onSelectionChanged: (selection) => {
                if (selection != null && selection.length > 0) {
                  updateValue(selection[0], selection[metaRelation.targetProperties[0]]);
                } else {
                  if (!required) {
                    updateValue(null, null);
                  }
                }
              }
            }}
          />
        )}
      />
      <QuinoCrossLink
        metaProperty={metaProperty}
        primaryKey={relatedGeneric?.primaryKey || null}
        title={titleCalculator.getTitle(metaRelation, bookmark.genericObject)}
        visible={showCrossLink}
        icon={!readOnly ? 'material-icons-outlined link' : undefined}
        showTitle={readOnly}
        isReadOnlyField={readOnly}
      />
    </div>
  );
}
