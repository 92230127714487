import { IMetaSort } from './IMetaSort';

export enum DataType {
  Unknown = 'Unknown',
  Relation = 'Relation',
  Method = 'Method',
  Boolean = 'Boolean',
  LargeInteger = 'LargeInteger',
  Integer = 'Integer',
  SmallInteger = 'SmallInteger',
  TinyInteger = 'TinyInteger',
  Double = 'Double',
  Currency = 'Currency',
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime',
  TimeSpan = 'TimeSpan',
  Text = 'Text',
  Binary = 'Binary',
  Stream = 'Stream',
  Guid = 'Guid',
  Key = 'Key'
}

export const isTypeNumber = (datatype?: DataType): boolean =>
  datatype === DataType.Currency ||
  datatype === DataType.Double ||
  datatype === DataType.Integer ||
  datatype === DataType.Key ||
  datatype === DataType.LargeInteger ||
  datatype === DataType.SmallInteger ||
  datatype === DataType.TinyInteger;

export const isTypeString = (datatype?: DataType): boolean => datatype === DataType.Guid || datatype === DataType.Text;

export const mapToDevexpressSort = (sorts: IMetaSort[]): { selector: string; desc?: boolean }[] | undefined => {
  if (sorts != null && sorts.length > 0) {
    return sorts.map((x) => {
      const sort: any = { selector: x.property };
      if (x.desc != null) {
        sort.desc = true;
      }
      return sort;
    });
  }

  return undefined;
};

export const mapToDevexpressType = (type: DataType): 'object' | 'boolean' | 'number' | 'date' | 'datetime' | 'string' => {
  switch (type) {
    case DataType.Unknown:
    case DataType.Method:
    case DataType.Binary:
    case DataType.Stream:
      return 'object';
    case DataType.Boolean:
      return 'boolean';
    case DataType.LargeInteger:
    case DataType.Integer:
    case DataType.SmallInteger:
    case DataType.TinyInteger:
    case DataType.Double:
    case DataType.Currency:
    case DataType.Key:
      return 'number';
    case DataType.Date:
      return 'date';
    case DataType.Time:
    case DataType.DateTime:
      return 'datetime';
    case DataType.TimeSpan:
    case DataType.Relation:
    case DataType.Text:
    case DataType.Guid:
      return 'string';
  }
};
