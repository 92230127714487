import React, { useCallback, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { ContextMenu } from 'devextreme-react/context-menu';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { IQuinoContextMenuItem } from './IQuinoContextMenuItem';
import dxButton from 'devextreme/ui/button';
import dxContextMenu from 'devextreme/ui/context_menu';
import { IQuinoShortcut, useShortcutHandler } from '../../shortcuts';
import { useService } from '../../ioc';
import { positionConfig } from 'devextreme/animation/position';
import { TResponsiveMode, useResponsiveMode } from '../../responsivity';

export interface IQuinoContextButtonProps {
  dataSource: IQuinoContextMenuItem[];
  buttonIcon?: string;
  buttonHintKey?: string;
  buttonId: string;
  buttonStylingMode?: 'text' | 'outlined' | 'contained';
  buttonType?: 'danger' | 'default' | 'normal' | 'success';
  buttonClassname?: string;
  buttonClassnameOpen?: string;
  buttonText?: string;
  visible?: boolean;
  shortcut?: IQuinoShortcut;
  submenuDirection?: 'auto' | 'left' | 'right';
  position?: positionConfig;
  onButtonClick?: () => void;
  onItemRendered?: (e: {
    component?: dxContextMenu | undefined;
    element?: Element | undefined;
    model?: any;
    itemData?: any;
    itemElement?: Element | undefined;
    itemIndex?: number | undefined;
  }) => any;
}

export const QuinoContextMenuButton = (props: IQuinoContextButtonProps) => {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const responsiveMode = useResponsiveMode();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const itemRenderer = useCallback((e: IQuinoContextMenuItem) => {
    if (e.itemRenderer) {
      return e.itemRenderer(e);
    }

    const icon = () => {
      if (e.isChecked != undefined) {
        return <span className={'material-icons-outlined check' + ' quino-context-menu-icon' + (e.isChecked ? '' : ' is--transparent')} />;
      } else if (e.icon != undefined) {
        return <span className={e.icon + ' quino-context-menu-icon'} />;
      }
      return null;
    };

    return (
      <div className={'quino-context-menu-item' + (e.className ? ' ' + e.className : '')} title={e.hint}>
        {icon()}
        <div className={'quino-context-menu-text'}>{e.text}</div>
        {e.items ? <span className='material-icons-outlined chevron_right quino-context-menu-arrow' /> : null}
      </div>
    );
  }, []);

  const switchMenuState = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  useShortcutHandler(props.shortcut ? [props.shortcut] : [], switchMenuState);

  const onItemClick = useCallback((e) => {
    if (e.itemData.onItemClick) {
      e.itemData.onItemClick();
      setIsMenuOpen(false);
    }
  }, []);

  const buttonOnInitialized = useCallback(
    (e: { component?: dxButton; element?: Element }) => {
      e.component?.registerKeyHandler('downArrow', switchMenuState);
    },
    [switchMenuState]
  );

  return (
    <>
      <Button
        id={props.buttonId}
        stylingMode={props.buttonStylingMode ?? 'text'}
        type={props.buttonType ?? 'normal'}
        icon={props.buttonIcon}
        height={34}
        width={props.buttonText && responsiveMode == TResponsiveMode.Desktop ? undefined : 34}
        className={
          (isMenuOpen ? 'quino-context-menu-button-open' + (props.buttonClassnameOpen ? ' ' + props.buttonClassnameOpen : '') : '') +
          (props.buttonClassname ? ' ' + props.buttonClassname : '')
        }
        visible={props.visible ?? true}
        onClick={(e) => {
          e.event?.stopImmediatePropagation();
          e.event?.preventDefault();
          switchMenuState();
          props.onButtonClick && props.onButtonClick();
        }}
        hint={props.buttonHintKey ? translationService.translate(props.buttonHintKey) : undefined}
        text={props.buttonText}
        onInitialized={buttonOnInitialized}
      />
      <ContextMenu
        cssClass={'quino-context-menu'}
        disabledExpr={'disabled'}
        visible={isMenuOpen}
        position={
          /* destructuring of props.position is important, otherwise ContextMenu will crash  */
          props.position ? { ...props.position } : { at: 'left bottom' }
        }
        showEvent={'dxclick'}
        dataSource={props.dataSource}
        target={'#' + props.buttonId}
        onHidden={() => setIsMenuOpen(false)}
        itemRender={itemRenderer}
        onItemClick={onItemClick}
        onItemRendered={props.onItemRendered}
        submenuDirection={props.submenuDirection ?? 'auto'}
      />
    </>
  );
};
