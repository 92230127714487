import { IMetaAspect } from '../IMetaAspect';
import { IExpression } from '../../expressions';

/**
 * The identifier for retrieving maximum length aspect.
 */
export const PasswordRequirementsAspectIdentifier = 'PasswordRequirementsAspect';

/**
 * Maximum length aspect containing the identifier for the maximum length to use.
 */
export interface IPasswordRequirementsAspect extends IMetaAspect {
  showRequirements: IExpression;
}
