import { IMetaAspect } from '@quino/core';

/**
 * The identifier for retrieving a column aggregation aspect.
 */
export const QuinoDataGridColumnAggregationAspectIdentifier = 'AggregationColumnAspect';

/**
 * QuinoDataGridColumnAggregation aspect
 */
export interface IQuinoDataGridColumnAggregationAspect extends IMetaAspect {
  aggregationMethod: string;
  columnName: string;
}
