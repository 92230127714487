import DataSource from 'devextreme/data/data_source';
import {
  DataType,
  IMetadataTree,
  QuinoCoreServiceSymbols,
  IGenericObject,
  ILayoutResolver,
  ILayoutResolverSymbol,
  IMetaRelation,
  LayoutType,
  NewGenericObjectPrimaryKey,
  mapToDevexpressSort,
  IMetaLayout
} from '@quino/core';
import { useService } from '../../ioc/hook';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../../data/IODataSourceFactory';
import { useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Guid from 'devextreme/core/guid';

/**
 * Fetch the related data source for the given relations.
 * @param relation - the relation to load the objects for.
 * @param sourceObject - the source object where the relation is defined on.
 * @param layoutToUse - the layout to use if a specific one is required.
 */
export const useRelatedSource = (relation: IMetaRelation, sourceObject: IGenericObject, layoutToUse?: IMetaLayout): DataSource => {
  const dataService = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const metaService = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);

  let layout =
    layoutToUse != null ? layoutToUse : metaService.resolveSingle({ element: relation, metaClass: relation.targetClass, type: LayoutType.List });
  const titleLayout = metaService.resolveSingle({ element: relation, metaClass: relation.targetClass, type: LayoutType.Title });
  layout = cloneDeep(layout);
  layout.elements.push(...titleLayout.elements);

  return useMemo(() => {
    let value = sourceObject[relation.sourceProperties[0]];
    if (value && value !== NewGenericObjectPrimaryKey) {
      const dataType = metadataTree.getClass(relation.targetClass).properties.find((x) => x.name === relation.targetProperties[0])!.dataType;
      value = dataType === DataType.Guid ? new Guid(value) : value;

      return dataService.create(layout, relation.targetClass, {
        pageSize: 5,
        filter: [relation.targetProperties[0], '=', value],
        select: relation.targetProperties[0], // add target property of relation to fix "Bug 11708: Inline list: "Select all" checkbox not showing status properly"
        sort: mapToDevexpressSort([...layout.sorts, ...relation.sorts])
      });
    }

    return new DataSource([]);
  }, [dataService, layout, metadataTree, relation.sorts, relation.sourceProperties, relation.targetClass, relation.targetProperties, sourceObject]);
};
