import { inject, injectable } from 'inversify';
import { IConfigurationService, IConfigurationServiceSymbol } from '@quino/core';
import { ICalendarConfiguration, ICalendarConfigurationSymbol } from './ICalendarConfiguration';
import { ICalendarAspect } from '../aspect';
import { ICalendarConfigurationService } from './ICalendarConfigurationService';

export const CalendarConfigurationServiceSymbol = Symbol.for('CalendarConfigurationService');

@injectable()
export class CalendarConfigurationService implements ICalendarConfigurationService {
  constructor(
    @inject(ICalendarConfigurationSymbol) private readonly calendarConfiguration: ICalendarConfiguration,
    @inject(IConfigurationServiceSymbol) private readonly configService: IConfigurationService
  ) {}

  async getCalendarConfiguration(aspect: ICalendarAspect): Promise<ICalendarConfiguration> {
    const defaultConfiguration = this.calendarConfiguration;

    // Apply global remote config
    this.configService.apply(defaultConfiguration, 'CalendarConfiguration');

    // Apply custom options from the element
    const customOverlay = aspect.configuration ? aspect.configuration : {};

    return { ...defaultConfiguration, ...customOverlay };
  }
}
