import { IQuinoEditorProps } from '../Types';
import * as React from 'react';
import { useMetaPropertyValueState } from '../Util';
import { QuinoImagePickerBase } from './QuinoImagePickerBase';

export const QuinoImagePicker = (props: IQuinoEditorProps) => {
  const { metaProperty, bookmark } = props;

  const [value] = useMetaPropertyValueState<any>(metaProperty, bookmark);

  if (!value || typeof value !== 'string') {
    return (
      <div className={'quino-image-picker-placeholder'}>
        <span className={'material-icons-outlined photo'} />
      </div>
    );
  }

  return <QuinoImagePickerBase data={value} element={metaProperty} />;
};
