import { IUrlHelper } from './IUrlHelper';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IQuinoServerServiceConfiguration } from './QuinoServer';
import toPairs from 'lodash/toPairs';
import isArray from 'lodash/isArray';
import isMap from 'lodash/isMap';

/**
 * Base class for URL managers with some protected helper methods.
 */
@injectable()
export class UrlHelper implements IUrlHelper {
  constructor(@inject(QuinoCoreServiceSymbols.IQuinoServerServiceConfiguration) private readonly settings: IQuinoServerServiceConfiguration) {
    this.baseUrl = this.settings.baseUrl;
  }

  combineUrlParts(...parts: string[]) {
    return [this.removeTrailingSlash(this.baseUrl), ...parts].join('/');
  }

  combineQueryParams(params: object): string {
    const result = toPairs(params)
      .map(([key, value]) => {
        if (isArray(value)) {
          return `${encodeURIComponent(key)}=${value.map((v) => encodeURIComponent(v)).join(',')}`;
        } else if (isMap(value)) {
          const values: string[] = [];
          value.forEach((v, k) => values.push(`${encodeURIComponent(key)}[${encodeURIComponent(k)}]=${encodeURIComponent(v)}`));
          return values.join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');

    return result.length > 0 ? '?' + result : '';
  }

  convertBoolToString(boolValue: boolean): string {
    return boolValue ? 'true' : 'false';
  }

  removeTrailingSlash = (url: string): string => {
    return url.endsWith('/') ? url.substring(0, url.length - 1) : url;
  };

  private readonly baseUrl: string;
}
