import { IBookmarkSerializer } from './IBookmarkSerializer';
import { IBookmark } from '../bookmarks';
import { injectable } from 'inversify';
import { StateFullBookmarkSerializer } from './StateFullBookmarkSerializer';
import { IOidcBookmark, isIOidcBookmark } from '../bookmarks/IOidcBookmark';
import { getBaseUrl, getPathParts, pathContainsIdentifierSequence, removeSlashes } from './UrlUtilities';
import { OidcBookmark } from '../bookmarks/OidcBookmark';

export const OidcBookmarkSerializerSymbol = Symbol.for('OidcBookmarkSerializer');

@injectable()
export class OidcBookmarkSerializer extends StateFullBookmarkSerializer implements IBookmarkSerializer<IOidcBookmark> {
  canSerialize(bookmark: IBookmark): boolean {
    return isIOidcBookmark(bookmark);
  }

  canDeserialize(path: string): boolean {
    return pathContainsIdentifierSequence(path, 'cui', 'oidc');
  }

  async deserialize(path: string): Promise<IOidcBookmark> {
    const parts = getPathParts(path);
    const cuiIndex = parts.indexOf('cui');

    if (cuiIndex <= -1 || parts.length < cuiIndex + 2) {
      return Promise.reject();
    }

    return new OidcBookmark(new URLSearchParams(window.location.search));
  }

  serialize(bookmark: IOidcBookmark): string {
    return new URL(`${removeSlashes(getBaseUrl())}/cui/oidc${bookmark.queryString}`, document.location.origin).toString();
  }
}
