import { IMetaElement } from '@quino/core';
import { useService } from '../../ioc/hook';
import { usePromise } from '../../components/Util';
import { IQuinoDropDownSettings, IQuinoDropDownSettingsSymbol } from './IQuinoDropDownSettings';
import { IQuinoDropDownConfigurationService, IQuinoDropDownConfigurationServiceSymbol } from './IQuinoDropDownConfigurationService';

export function useDropDownSettings(element: IMetaElement): IQuinoDropDownSettings {
  const quinoDropDownConfigurationService = useService<IQuinoDropDownConfigurationService>(IQuinoDropDownConfigurationServiceSymbol);
  const defaultSettings = useService<IQuinoDropDownSettings>(IQuinoDropDownSettingsSymbol);

  const [dropDownSettings] = usePromise<IQuinoDropDownSettings, any>(async () => quinoDropDownConfigurationService.getDropDownSettings(element), []);

  return dropDownSettings ? dropDownSettings : defaultSettings;
}
