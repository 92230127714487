import { IApplication } from '../application';
import { InMemoryStorageService, InMemoryStorageServiceSymbol } from './InMemoryStorageService';
import { LocalStorageService, LocalStorageServiceSymbol } from './LocalStorageService';
import { IStorageService } from './IStorageSevice';
import { PersistentStorageService, PersistentStorageServiceSymbol } from './PersistentStorageService';

export class SettingsModule {
  static use = (application: IApplication): void => {
    application.registerSingle<IStorageService>(InMemoryStorageServiceSymbol, InMemoryStorageService);
    application.registerSingle<IStorageService>(LocalStorageServiceSymbol, LocalStorageService);
    application.registerSingle<IStorageService>(PersistentStorageServiceSymbol, PersistentStorageService);
  };
}
