import { IMetaProperty } from './index';
import { IMetaElement } from './IMetaElement';
import { IMetaSort } from './IMetaSort';

/**
 * Represents a final element in the meta tree. Complies to a relation in the layout.
 */
export interface IMetaRelation extends IMetaProperty {
  /**
   * The target class to which this relation points to.
   */
  targetClass: string;

  /**
   * Gets the target properties on the related object.
   */
  targetProperties: [string];

  /**
   * The source properties in the current object.
   */
  sourceProperties: [string];

  /**
   * The cardinality of the relation.
   */
  cardinality: MetaCardinality;

  /**
   * Sorts to apply on the grid.
   */
  sorts: IMetaSort[];
}

export enum MetaCardinality {
  ZeroOrOne,
  MustBeOne,
  Multiple
}

export function isMetaRelation(object: IMetaElement): object is IMetaRelation {
  return (
    object != null &&
    (object as IMetaRelation).dataType != null &&
    (object as IMetaRelation).dataType === 'Relation' &&
    (object as IMetaRelation).targetClass !== undefined
  );
}

export function getMetaRelation(object: IMetaElement): IMetaRelation {
  if (!isMetaRelation(object)) {
    throw new Error(`Element [${object.name}] is not a valid IMetaRelation. Full element: [${JSON.stringify(object)}]`);
  }

  return object;
}
