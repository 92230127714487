import { IMetaProperty } from '../meta';

export const IValueFormatterSymbol = Symbol.for('IValueFormatter');

/**
 * Service used to get formatted values from raw values
 */
export interface IValueFormatter {
  /**
   * Format a raw value based on MetaProperty info.
   */
  formatValue(property: IMetaProperty, rawValue: any): string;
}
