import React, { useCallback, useEffect, useState } from 'react';
import { INotification, INotificationService, INotificationServiceSymbol, ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { QuinoNotificationContent } from './QuinoNotificationContent';
import { useService } from '../../ioc';
import { useNotificationConfiguration } from '../../settings';

export interface IQuinoInfoBarProps {
  /**
   * Whether the infobar is the default notification area of the application or a custom infobar; only one default area per application is recommended
   */
  isDefault: boolean;

  /**
   * ID of the infobar, required for custom info bars, to make sure that the notifications targeted at this area are displayed
   */
  customAreaID?: string;

  /**
   * Whether to add bottom margin when the infobar is visible
   */
  hasBottomMargin?: boolean;
}

export function QuinoInfoBar(props: IQuinoInfoBarProps) {
  const config = useNotificationConfiguration();
  const { subscribeToNotification, unsubscribeFromNotification, clearNotification } = useService<INotificationService>(INotificationServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const [currentNotifications, setCurrentNotifications] = useState<INotification[]>([]);

  const humanizeNotification = useCallback(
    (currentNotification: INotification) => {
      let messageDetails = undefined;
      try {
        messageDetails = currentNotification && currentNotification.messageDetails ? JSON.parse(currentNotification.messageDetails) : undefined;
      } catch {
        //noop
      }

      const httpStatus = messageDetails && messageDetails.httpStatus ? messageDetails.httpStatus : undefined;
      if (httpStatus && httpStatus == 403) {
        const message = translationService.translate('QuinoInfoBar.Error.403.Message');
        messageDetails = translationService.translate('QuinoInfoBar.Error.403.Message.Detail');
        const humanNotification: INotification | undefined = {
          type: 'error',
          area: currentNotification.area,
          message: message,
          messageDetails: messageDetails,
          autoDisappear: currentNotification.autoDisappear,
          customActions: currentNotification.customActions,
          customAreaID: currentNotification.customAreaID,
          customElement: currentNotification.customElement,
          symbol: currentNotification.symbol
        };
        return humanNotification;
      }
      return currentNotification;
    },
    [translationService]
  );

  useEffect(() => {
    const checkIncomingNotification = (notifications: INotification[]) => {
      setCurrentNotifications(notifications.filter((n) => n.customAreaID === props.customAreaID));
    };

    const symbol = subscribeToNotification(props.isDefault ? 'default' : 'custom', checkIncomingNotification);
    return () => unsubscribeFromNotification(symbol);
  }, [
    clearNotification,
    config.infoBarDisplayTime,
    currentNotifications,
    props.customAreaID,
    props.isDefault,
    subscribeToNotification,
    unsubscribeFromNotification
  ]);

  return (
    <div
      id={props.customAreaID}
      className={'quino-info-bar' + (currentNotifications.length > 0 ? ' is--open' : '') + (props.hasBottomMargin ? ' has-margin-bottom' : '')}>
      {currentNotifications.map(humanizeNotification).map((humanizedNotification) => (
        <QuinoNotificationContent
          closeButtonIsShown={humanizedNotification.closeButtonIsShown}
          key={humanizedNotification.symbol?.toString()}
          onClose={() => {
            humanizedNotification.symbol && clearNotification(humanizedNotification.symbol);
          }}
          type={humanizedNotification.type || 'error'}
          message={humanizedNotification.message || ''}
          customElement={humanizedNotification.customElement}
          messageDetails={humanizedNotification.messageDetails}
          customActions={humanizedNotification.customActions}
        />
      ))}
    </div>
  );
}
