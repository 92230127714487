import { FrameworkStartupGroup, IApplication } from '../application';
import { ILayoutResolver, ILayoutResolverSymbol } from './ILayoutResolver';
import { LayoutResolver } from './LayoutResolver';
import { ILayoutScopeManager, LayoutScopeManagerSymbol } from './ILayoutScopeManager';
import { LayoutScopeManager } from './LayoutScopeManager';
import { IFavoriteLayoutService, IFavoriteLayoutServiceSymbol } from './IFavoriteLayoutService';
import { FavoriteLayoutService } from './FavoriteLayoutService';
import { IScopingService, ScopingServiceSymbol } from './IScopingService';
import { ScopingService } from './ScopingService';

export const FavoriteLayoutServiceStartupActionIdentifier = Symbol.for('quino_favorite_layout_service_startup_action');

/**
 * Register support for layout scoping.
 */
export class ScopingModule {
  public static use(application: IApplication) {
    application.registerSingle<ILayoutResolver>(ILayoutResolverSymbol, LayoutResolver);
    application.registerSingle<ILayoutScopeManager>(LayoutScopeManagerSymbol, LayoutScopeManager);
    application.registerSingle<IFavoriteLayoutService>(IFavoriteLayoutServiceSymbol, FavoriteLayoutService);
    application.registerSingle<IScopingService>(ScopingServiceSymbol, ScopingService);

    application
      .registerStartupAction(FavoriteLayoutServiceStartupActionIdentifier, async (app) => {
        const favoriteLayoutService = app.get<IFavoriteLayoutService>(IFavoriteLayoutServiceSymbol);
        await favoriteLayoutService.loadInitialFavorites();
      })
      .moveTo(FrameworkStartupGroup);
  }
}
