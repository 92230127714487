import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button as TextBoxButton, ITextBoxOptions, TextBox } from 'devextreme-react/text-box';
import { useService } from '../../ioc';
import {
  FieldValidationErrorCodes,
  getAspectOrDefault,
  IControlBehaviorRegistry,
  IExpressionEvaluator,
  IFieldError,
  IFieldValidationResult,
  IGenericObject,
  ILogger,
  IMetaProperty,
  IPasswordRequirementsAspect,
  IRequestBuilder,
  ITranslationService,
  ITranslationServiceSymbol,
  IUrlManager,
  PasswordRequirementsAspectIdentifier,
  QuinoCoreServiceSymbols
} from '@quino/core';

interface IPasswordOptions {
  requireDigit: boolean;
  requireLowercase: boolean;
  requireNonAlphanumeric: boolean;
  requireUppercase: boolean;
  requiredLength: number;
  requiredUniqueChars: number;
}

export interface IQuinoPasswordBoxProps {
  genericObject?: IGenericObject;
  onValidChanged?: (fieldErrors: IFieldError[]) => void;
  metaProperty?: IMetaProperty;
  showRequirements?: boolean;
}

export interface IPasswordValidationState {
  length: boolean;
  uniqueChars: boolean;
  nonAlphaNumeric: boolean;
  lowercase: boolean;
  uppercase: boolean;
  digit: boolean;
}

export const QuinoPasswordBox = React.forwardRef<TextBox, PropsWithChildren<Partial<ITextBoxOptions> & IQuinoPasswordBoxProps>>((props, ref) => {
  const { genericObject, onValidChanged, metaProperty, showRequirements } = props;
  const requestBuilder = useService<IRequestBuilder>(QuinoCoreServiceSymbols.IRequestBuilder);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const expressionEvaluator = useService<IExpressionEvaluator>(QuinoCoreServiceSymbols.IExpressionEvaluator);
  const controlValidationProvider = useService<IControlBehaviorRegistry<IFieldValidationResult>>(QuinoCoreServiceSymbols.ControlValidatorRegistry);
  const urlManager = useService<IUrlManager>(QuinoCoreServiceSymbols.IUrlManager);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const fieldErrorRef = useRef<IFieldError[]>([]);

  const [validationState, setValidationState] = useState<IPasswordValidationState>({
    length: false,
    uniqueChars: false,
    nonAlphaNumeric: false,
    lowercase: false,
    uppercase: false,
    digit: false
  });
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [passwordOptions, setPasswordOptions] = useState<IPasswordOptions | undefined>();

  const passwordRequirementsAspect = useMemo(
    () => (metaProperty ? getAspectOrDefault<IPasswordRequirementsAspect>(metaProperty, PasswordRequirementsAspectIdentifier) : undefined),
    [metaProperty]
  );
  const showPasswordRequirements =
    showRequirements ??
    (passwordRequirementsAspect && expressionEvaluator.evaluate<boolean>(passwordRequirementsAspect?.showRequirements, genericObject ?? {}));

  useEffect(() => {
    requestBuilder
      .create(urlManager.getIdentityUrl() + '/password/options', 'get')
      .fetch()
      .then((result) => {
        result.json().then(setPasswordOptions).catch(logger.logError);
      })
      .catch(logger.logError);
  }, [logger.logError, requestBuilder, urlManager]);

  const calculatedFieldErrors = useCallback(
    (validationState: IPasswordValidationState) => {
      let passwordFieldErrors: IFieldError[] = [];

      if (showPasswordRequirements && Object.values(validationState).some((v) => !v)) {
        passwordFieldErrors = [
          {
            fieldName: metaProperty?.name ?? '',
            errorCode: FieldValidationErrorCodes.MAXLENGTH,
            errorMessage: translationService.translate('Validation.PasswordRequirements')
          }
        ];
      }

      fieldErrorRef.current = passwordFieldErrors;
    },
    [metaProperty?.name, showPasswordRequirements, translationService]
  );

  useEffect(() => {
    if (metaProperty) {
      return controlValidationProvider.register(metaProperty, () => {
        return {
          fieldErrors: fieldErrorRef.current
        };
      });
    }
    return;
  }, [controlValidationProvider, metaProperty]);

  const validatePassword = (password: string | undefined, passwordOptions: IPasswordOptions | undefined): IPasswordValidationState => {
    const requiredLength = passwordOptions?.requiredLength ?? 0;
    const requiredUniqueChars = passwordOptions?.requiredUniqueChars ?? 0;

    return {
      length: password != undefined && password.length >= requiredLength,
      uniqueChars: new Set(password).size >= requiredUniqueChars,
      nonAlphaNumeric: password != undefined && !/^[a-zA-Z0-9]*$/.test(password),
      lowercase: password != undefined && /[a-z]/.test(password),
      uppercase: password != undefined && /[A-Z]/.test(password),
      digit: password != undefined && /\d/.test(password)
    };
  };

  const getValidationIcon = useCallback((valid: boolean, translation: string) => {
    return (
      <div className='validation-hint-container'>
        <i className={valid ? 'material-icons check_circle success-icon' : 'material-icons-outlined check_circle'} />
        <span>{translation}</span>
      </div>
    );
  }, []);

  return (
    <>
      <TextBox
        ref={ref}
        {...props}
        mode={passwordVisible ? 'text' : 'password'}
        showClearButton={false}
        onValueChanged={(e) => {
          const validationState = validatePassword(e.value, passwordOptions);
          setValidationState(validationState);
          calculatedFieldErrors(validationState);
          onValidChanged && onValidChanged(fieldErrorRef.current);

          props.onValueChanged?.(e);
        }}>
        <TextBoxButton
          name='showPassword'
          location='after'
          options={{
            stylingMode: 'text',
            icon: passwordVisible ? 'material-icons-outlined visibility' : 'material-icons-outlined visibility_off',
            onClick: () => setPasswordVisible(!passwordVisible),
            focusStateEnabled: false
          }}
        />
        {props.children}
      </TextBox>
      {showPasswordRequirements && (
        <div className='validation-hint-wrapper'>
          {getValidationIcon(
            validationState.length && validationState.uniqueChars,
            translationService.translate('Validations.MinLengthAndUniqueChars', {
              chars: passwordOptions ? passwordOptions?.requiredLength : '',
              uniqueChars: passwordOptions ? passwordOptions?.requiredUniqueChars : ''
            })
          )}
          {getValidationIcon(validationState.uppercase && validationState.lowercase, translationService.translate('Validations.UpperLowerCase'))}
          {getValidationIcon(validationState.nonAlphaNumeric && validationState.digit, translationService.translate('Validations.NonAlphaDigit'))}
        </div>
      )}
    </>
  );
});
