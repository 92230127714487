import { isIListBookmark, ListBookmarkEvent } from './IListBookmark';
import { useEffect, useState } from 'react';
import { IGenericObject } from '@quino/core';
import { IBookmark } from './IBookmark';

/**
 * Use the on source change.
 * @param bookmark - the bookmark to subscribe to.
 */
export const useOnSourceChange = (bookmark: IBookmark): IGenericObject[] => {
  const [source, onSourceChanged] = useState<IGenericObject[]>(isIListBookmark(bookmark) ? bookmark.source : []);
  useEffect(() => {
    if (isIListBookmark(bookmark) && !bookmark.dataSource) {
      const symbol = bookmark.subscribe((event) => onSourceChanged(event), ListBookmarkEvent.SourceChanged);

      return () => bookmark.unsubscribe(symbol, ListBookmarkEvent.SourceChanged);
    }

    return () => {
      // NOP
    };
  }, [bookmark]);

  return source;
};

/**
 * Use the on selection change.
 * @param bookmark - the bookmark to subscribe to.
 */
export const useOnSelectionChange = (bookmark: IBookmark): IGenericObject[] => {
  const [selection, onSelectionChange] = useState<IGenericObject[]>([]);
  useEffect(() => {
    if (isIListBookmark(bookmark)) {
      const symbol = bookmark.subscribe((event) => onSelectionChange(event), ListBookmarkEvent.SelectionChanged);

      return () => bookmark.unsubscribe(symbol, ListBookmarkEvent.SelectionChanged);
    }

    return () => {
      // NOP
    };
  }, [bookmark]);

  return selection;
};
