import { IBookmark, IBookmarkFactory, IListBookmark, isIListBookmark } from '../../bookmarks';
import { INavigationLinkResolver } from './INavigationLinkResolver';
import {
  IExpressionEvaluator,
  ILogger,
  IMetadataTree,
  INavigationLinkTarget,
  isINavigationLinkTargetClass,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { QuinoUIServiceSymbols } from '../../ioc';
import { INavigationService, INavigationServiceSymbol } from '../INavigationService';
import { resolveLayout } from './ResolverUtils';

export const ClassNavigationLinkResolverSymbol = Symbol.for('ClassNavigationLinkResolver');

@injectable()
export class ClassNavigationLinkResolver implements INavigationLinkResolver {
  constructor(
    @inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(INavigationServiceSymbol) private readonly navigationService: INavigationService,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly expressionEvaluator: IExpressionEvaluator
  ) {}

  generateOnClickCallback(target: INavigationLinkTarget, currentBookmark?: IBookmark, openInNewTab?: boolean): (() => void) | undefined {
    if (isINavigationLinkTargetClass(target)) {
      const layout = resolveLayout(this.expressionEvaluator, this.metadataTree, target.metaClass, target.layout, currentBookmark);

      // TODO: Custom Filter

      return layout
        ? async () => this.bookmarkFactory.createList(target.metaClass, layout).then(async (bookmark) => this.openBookmark(bookmark, openInNewTab))
        : async () => this.bookmarkFactory.createList(target.metaClass).then(async (bookmark) => this.openBookmark(bookmark, openInNewTab));
    }

    return undefined;
  }

  private readonly openBookmark = (bookmark: IListBookmark, openInNewTab?: boolean) => {
    if (openInNewTab) {
      window.open(this.navigationService.extractUrl(bookmark), '_blank');
    } else {
      const currentBookmark = this.navigationService.active;
      if (isIListBookmark(currentBookmark) && currentBookmark.metaClass === bookmark.metaClass && currentBookmark.layout === bookmark.layout) {
        // TODO: Check for filter/source similarity

        currentBookmark.refresh();
        return;
      }

      this.navigationService.push(bookmark).catch(this.logger.logError);
    }
  };
}
