import React from 'react';
import { Button } from 'devextreme-react/button';

export interface IThirdPartyAuthenticationButtonProps {
  icon: string;
  text: string;
  onClick: () => void;
}

export function ThirdPartyAuthenticationButton(props: IThirdPartyAuthenticationButtonProps) {
  return <Button type='default' stylingMode='outlined' className={'quino-auth-button'} {...props} />;
}
