export enum TGridEditingMode {
  Selection = 'select',
  InlineEditing = 'inline-edit'
}

export interface IGridEditorModeSettings {
  gridEditorMode: TGridEditingMode;
}

export interface IGridEditorFilterRememberChoiceSettings {
  takeOverFilterSettings: boolean | undefined;
  deleteFilterSettings: boolean | undefined;
  noFilterSettings: boolean | undefined;
}

/**
 * Service symbol for the IDataGridSettingsService.
 */
export const IDataGridSettingsServiceSymbol = Symbol.for('IDataGridSettingsService');

/**
 * Setting service to store grid related settings.
 */
export interface IDataGridSettingsService {
  /**
   * Sets the settings of the data grid modes
   * @param: key of the data grid
   * @param: setting to store
   */
  setDataGridEditModeSettings(key: string, setting: IGridEditorModeSettings): Promise<void>;

  /**
   * Gets the settings of the data grid modes
   * @param: key of the data grid
   * @returns {Promise<IGridEditorModeSettings>}
   */
  getDataGridEditModeSettings(key: string): Promise<IGridEditorModeSettings>;

  /**
   * Sets the settings of the data grid views
   * @param: key of the data grid
   * @param: setting to store
   */
  setDataGridViewSettings(key: string, setting: any): Promise<void>;

  /**
   * Gets the settings of the data grid views
   * @param: key of the data grid
   * @returns {Promise<any>}
   */
  getDataGridViewSettings(key: string): Promise<any>;

  /**
   * Sets the settings of the data grid filter
   * @param: key of the data grid
   * @param: setting to store
   */
  setDataGridFilterSettings(key: string, setting: any): Promise<void>;

  /**
   * Clears the settings of the data grid filter
   */
  clearDataGridFilterSettings(): Promise<void>;

  /**
   * Gets the settings of the data grid filter
   * @param: key of the data grid
   * @returns {Promise<any>}
   */
  getDataGridFilterSettings(key: string): Promise<any>;

  /**
   * Sets the remember choice settings of the data grid filter
   * @param: key of the data grid
   * @param: setting to store
   */
  setDataGridFilterRememberChoiceSettings(key: string, setting: IGridEditorFilterRememberChoiceSettings): Promise<void>;

  /**
   * Gets the remember choice settings of the data grid filter
   * @param: key of the data grid
   * @returns {Promise<IGridEditorFilterRememberChoiceSettings>}
   */
  getDataGridFilterRememberChoiceSettings(key: string): Promise<IGridEditorFilterRememberChoiceSettings>;
}
