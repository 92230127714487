import { DefaultErrorMapper } from './DefaultErrorMapper';
import { EntityError } from '../errors';

export class EntityErrorMapper extends DefaultErrorMapper {
  get errorName(): string {
    return super.errorName + '.Entity';
  }

  getErrorMessage(error: Error): string {
    const entityError = error as EntityError;
    const entityDetail = entityError.entity != undefined ? ' ' + JSON.stringify(entityError.entity) : '';
    return `Entity '${entityError.entityKey}'${entityDetail}: ${super.getErrorMessage(error)}`;
  }
}
