export const IQuinoShortcutSettingsSymbol = Symbol.for('IQuinoShortcutSettings');

export interface IQuinoShortcutBasic {
  key: string;
  descriptionTranslationKey: string;
}

export interface IQuinoSingleKeyShortcut extends IQuinoShortcutBasic {}
export interface IQuinoControlKeyShortcut extends IQuinoShortcutBasic {
  controlShortcut: boolean;
}
export interface IQuinoShiftControlKeyShortcut extends IQuinoControlKeyShortcut {
  shiftShortcut: boolean;
}

export type IQuinoShortcut = IQuinoSingleKeyShortcut | IQuinoControlKeyShortcut | IQuinoShiftControlKeyShortcut;

export interface IQuinoShortcutSettings {
  globalSearch: IQuinoShortcut;
  quickListSearch: IQuinoShortcut;
  menuSearch: IQuinoShortcut;
  createEntryPrimary: IQuinoShortcut;
  saveDetail: IQuinoShortcut;
  deleteDetailPrimary: IQuinoShortcut;
  forceDeleteDetailPrimary: IQuinoShortcut;
  discardDetail: IQuinoShortcut;
  helpMenu: IQuinoShortcut;
  cancel: IQuinoShortcut;
  actions: IQuinoShortcut;
  // TODO implement with Bug 9315: Keyboard shortcuts
  // tabChange: IQuinoShortcut;
}

export const defaultQuinoShortcutSettings: IQuinoShortcutSettings = {
  globalSearch: {
    key: 'g',
    controlShortcut: true,
    descriptionTranslationKey: 'Shortcut.GlobalSearch.Description'
  },
  quickListSearch: {
    key: 'f',
    controlShortcut: true,
    descriptionTranslationKey: 'Shortcut.QuickListSearch.Description'
  },
  menuSearch: {
    key: 'm',
    controlShortcut: true,
    descriptionTranslationKey: 'Shortcut.MenuSearch.Description'
  },
  createEntryPrimary: {
    key: 'e',
    controlShortcut: true,
    descriptionTranslationKey: 'Shortcut.CreateEntryOnList.Description'
  },
  saveDetail: {
    key: 's',
    controlShortcut: true,
    descriptionTranslationKey: 'Shortcut.SaveOnDetail.Description'
  },
  deleteDetailPrimary: {
    key: 'l',
    controlShortcut: true,
    descriptionTranslationKey: 'Shortcut.DeleteOnDetail.Description'
  },
  forceDeleteDetailPrimary: {
    key: 'l',
    controlShortcut: true,
    shiftShortcut: true,
    descriptionTranslationKey: 'Shortcut.ForceDeleteOnDetail.Description'
  },
  discardDetail: {
    key: 'r',
    controlShortcut: true,
    descriptionTranslationKey: 'Shortcut.DiscardOnDetail.Description'
  },
  helpMenu: {
    key: 'h',
    controlShortcut: true,
    descriptionTranslationKey: 'Shortcut.HelpMenu.Description'
  },
  cancel: { key: 'Escape', descriptionTranslationKey: 'Shortcut.Cancel.Description' },
  actions: { key: '.', controlShortcut: true, descriptionTranslationKey: 'Shortcut.Actions.Description' }
  // tabChange: {key: 'Tab', controlShortcut: true, descriptionTranslationKey: 'Shortcut.Detail.TabChange'},
};

export function isSingleKeyShortCut(object: IQuinoShortcut): object is IQuinoSingleKeyShortcut {
  return (
    object != null &&
    (object as IQuinoSingleKeyShortcut).key != null &&
    (object as IQuinoSingleKeyShortcut).key.length > 1 &&
    !(object as IQuinoControlKeyShortcut).controlShortcut
  );
}

export function isControlKeyShortCut(object: IQuinoShortcut): object is IQuinoControlKeyShortcut {
  return object != null && (object as IQuinoControlKeyShortcut).key != null && (object as IQuinoControlKeyShortcut).controlShortcut;
}

export function isShiftControlKeyShortCut(object: IQuinoShortcut): object is IQuinoShiftControlKeyShortcut {
  return (
    object != null &&
    (object as IQuinoShiftControlKeyShortcut).key != null &&
    (object as IQuinoShiftControlKeyShortcut).controlShortcut &&
    (object as IQuinoShiftControlKeyShortcut).shiftShortcut
  );
}
