import { inject, injectable } from 'inversify';
import { QuinoUIServiceSymbols } from '../ioc';
import { IQuinoFileUploadConfigurationService } from './IQuinoFileUploadConfigurationService';
import { IFileUploadSettings } from './IFileUploadSettings';
import { IMetaElement } from '@quino/core';

@injectable()
export class DefaultFileUploadConfigurationService implements IQuinoFileUploadConfigurationService {
  constructor(@inject(QuinoUIServiceSymbols.IFileUploadSettings) private readonly fileUploadSettings: IFileUploadSettings) {}

  getFileUploadSettings(_element: IMetaElement | null): IFileUploadSettings {
    return this.fileUploadSettings;
  }
}
