import { IMetaRelation, IMetaClass, IMetaLayout } from '@quino/core';
import { ListBookmark } from './ListBookmark';
import DataSource from 'devextreme/data/data_source';
import { IRelatedListBookmark } from './IRelatedListBookmark';

/**
 * A filtered list bookmark that is based on a relation.
 */
export class RelatedListBookmark extends ListBookmark implements IRelatedListBookmark {
  constructor(
    public sourceClass: IMetaClass,
    targetClass: IMetaClass,
    layout: IMetaLayout,
    public dataSource: DataSource,
    public relation: IMetaRelation,
    public foreignKey: any
  ) {
    super(targetClass, layout, []);
  }
}
