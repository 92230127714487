import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { useRerenderOnChanges, useValidation } from '../Util';
import { getMetaProperty } from '@quino/core';
import { QuinoLabeled } from '../QuinoLabeled';
import { useLabelSettings, useMetadata } from '../../settings';
import { QuinoColorPicker } from './QuinoColorPicker';

export function QuinoColorPickerLabeled(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const { element, bookmark } = props;
  const metaProperty = getMetaProperty(element);
  const { description, caption, instruction } = metaProperty;
  const labelSettings = useLabelSettings(props.element);
  const { required, visible } = useMetadata(metaProperty, bookmark.genericObject);
  const [, errorMessages] = useValidation(bookmark, metaProperty);

  useRerenderOnChanges(bookmark, metaProperty);

  return (
    <QuinoLabeled
      visible={visible}
      className={'quino-input-fields-max-width'}
      label={caption}
      required={required}
      description={description}
      instruction={instruction}
      errorMessages={errorMessages}
      labelSettings={labelSettings}>
      <QuinoColorPicker metaProperty={metaProperty} bookmark={bookmark} />
    </QuinoLabeled>
  );
}
