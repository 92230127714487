import React from 'react';
import { ICUIRegisterAccountComponentFactory } from './ICUIRegisterAccountComponentFactory';
import { injectable } from 'inversify';
import { CUIRegisterAccountComponent, ICUIRegisterComponentProps } from './CUIRegisterAccountComponent';

@injectable()
export class RegisterAccountComponentFactory implements ICUIRegisterAccountComponentFactory {
  create(props: ICUIRegisterComponentProps): React.ReactNode {
    return <CUIRegisterAccountComponent onRegister={props.onRegister} onGoBack={props.onGoBack} focusRequested={props.focusRequested} />;
  }
}
