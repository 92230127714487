import { IApplication, ILogger, isBase64ImageSource, QuinoCoreServiceSymbols, ThemeInitializationGroup } from '@quino/core';
import {
  defaultLabelSettings,
  ILabelSettings,
  IQuinoAuthenticationComponentFactory,
  IQuinoContextUrlService,
  IQuinoContextUrlServiceSymbol,
  IQuinoDataGridConfigurationService,
  IQuinoDropDownConfigurationService,
  IQuinoDropDownConfigurationServiceSymbol,
  IQuinoFileUploadConfigurationService,
  IQuinoLabelConfigurationService,
  IResponsivityBreakpointService,
  IResponsivityBreakpointServiceSymbol,
  IServerHealthCheckerService,
  IServerHealthCheckerServiceSymbol,
  QuinoLabeledLabelPosition,
  QuinoUIServiceSymbols
} from '@quino/ui';
import {
  CUIDataGridConfigurationService,
  CUIDropDownConfigurationService,
  CUIScopeSelectorService,
  CUISettingsConfigurationService,
  defaultCUIExplorerSettings,
  defaultCUISettings,
  ICUIExplorerSettings,
  ICUIExplorerSettingsSymbol,
  ICUIScopeSelectorService,
  ICUISettings,
  ICUISettingsConfigurationService,
  ICUISettingsConfigurationServiceSymbol,
  ICUISettingSymbol
} from './configuration';
import { CUIAuthenticationComponentFactory } from './components/authentication/CUIAuthenticationComponentFactory';
import { CUILabelConfigurationService } from './configuration/CUILabelConfigurationService';
import {
  ICUIRegisterAccountComponentFactory,
  ICUIRegisterAccountComponentFactorySymbol
} from './components/authentication/ICUIRegisterAccountComponentFactory';
import { RegisterAccountComponentFactory } from './components/authentication/RegisterAccountComponentFactory';
import { IThemeCustomizationService, IThemeCustomizationServiceSymbol } from './configuration/IThemeCustomizationService';
import { ThemeCustomizationService } from './configuration/ThemeCustomizationService';
import { ICUIScopeSelectorServiceSymbol } from './configuration/ICUIScopeSelectorService';
import { CUIContextUrlService } from './components/context/CUIContextUrlService';
import { CUIHealthCheckerService } from './components/Application/CUIHealthCheckerService';
import { CUIFileUploadConfigurationService } from './configuration/CUIFileUploadConfigurationService';
import { CUIResponsivityBreakpointService } from './components/Application/CUIResponsivityBreakpointService';

export class CUIModule {
  static use = (application: IApplication): void => {
    application.registerConstant<ICUISettings>(ICUISettingSymbol, defaultCUISettings);
    application.registerSingle<ICUISettingsConfigurationService>(ICUISettingsConfigurationServiceSymbol, CUISettingsConfigurationService);
    application.registerSingle<IQuinoAuthenticationComponentFactory>(
      QuinoUIServiceSymbols.IQuinoAuthenticationComponentFactory,
      CUIAuthenticationComponentFactory
    );
    application.registerConstant<ICUIExplorerSettings>(ICUIExplorerSettingsSymbol, defaultCUIExplorerSettings);
    application.registerConstant<ILabelSettings>(QuinoUIServiceSymbols.ILabelSettings, {
      ...defaultLabelSettings,
      alignment: QuinoLabeledLabelPosition.Top
    });
    application.registerSingle<IQuinoDataGridConfigurationService>(
      QuinoUIServiceSymbols.IQuinoDataGridConfigurationService,
      CUIDataGridConfigurationService
    );
    application.registerSingle<IQuinoDropDownConfigurationService>(IQuinoDropDownConfigurationServiceSymbol, CUIDropDownConfigurationService);
    application.registerSingle<IQuinoLabelConfigurationService>(QuinoUIServiceSymbols.IQuinoLabelConfigurationService, CUILabelConfigurationService);
    application.registerSingle<IQuinoFileUploadConfigurationService>(
      QuinoUIServiceSymbols.IQuinoFileUploadConfigurationService,
      CUIFileUploadConfigurationService
    );
    application.registerSingle<ICUIRegisterAccountComponentFactory>(ICUIRegisterAccountComponentFactorySymbol, RegisterAccountComponentFactory);
    application.registerSingle<IQuinoContextUrlService>(IQuinoContextUrlServiceSymbol, CUIContextUrlService);
    application.registerSingle<IThemeCustomizationService>(IThemeCustomizationServiceSymbol, ThemeCustomizationService);
    application.registerSingle<ICUIScopeSelectorService>(ICUIScopeSelectorServiceSymbol, CUIScopeSelectorService);
    application.registerSingle<IServerHealthCheckerService>(IServerHealthCheckerServiceSymbol, CUIHealthCheckerService);
    application.registerSingle<IResponsivityBreakpointService>(IResponsivityBreakpointServiceSymbol, CUIResponsivityBreakpointService);

    application
      .registerStartupAction(Symbol.for('SetFavicon'), (app) => {
        const cuiSettings = app.get<ICUISettingsConfigurationService>(ICUISettingsConfigurationServiceSymbol).getCuiSettings();
        const faviconElement = document.getElementById(cuiSettings.faviconSelector);

        faviconElement?.setAttribute('href', (isBase64ImageSource(cuiSettings.faviconLogo) ? '' : '/') + cuiSettings.faviconLogo);
      })
      .moveTo(ThemeInitializationGroup);
    application
      .registerStartupAction(Symbol.for('SetTitle'), (app) => {
        const cuiSettings = app.get<ICUISettingsConfigurationService>(ICUISettingsConfigurationServiceSymbol).getCuiSettings();
        document.title = cuiSettings.applicationName;
      })
      .moveTo(ThemeInitializationGroup);
    application
      .registerStartupAction(Symbol.for('CustomizeApplicationTheme'), async (app) => {
        const customizationService = app.get<IThemeCustomizationService>(IThemeCustomizationServiceSymbol);
        const logger = app.get<ILogger>(QuinoCoreServiceSymbols.ILogger);
        await customizationService.initialize();
        customizationService.updateColorVariables();
        customizationService.changeCssPath().catch(logger.logError);
      })
      .moveTo(ThemeInitializationGroup);
  };
}
