import { ExcelFormat, IQuinoExcelFile } from './IQuinoExcelFile';
import { Workbook, Worksheet } from 'exceljs';

const MAX_WORKSHEET_NAME_LENGTH = 31;

// eslint-disable-next-line no-useless-escape
const INVALID_CHARS_REGEX = /[@\/?*\[\]:]/gm;

export class QuinoExcelFile implements IQuinoExcelFile {
  constructor(workbook: Workbook) {
    this.format = 'xlsx';
    this.name = 'file';
    this.workbook = workbook;
  }

  getActiveWorksheet(): Worksheet {
    return this.activeWorkSheet;
  }

  addWorksheet(worksheet: string): void {
    worksheet = worksheet.replace(INVALID_CHARS_REGEX, ' ').replace('  ', ' ').trim();
    worksheet = worksheet.length > MAX_WORKSHEET_NAME_LENGTH ? worksheet.substring(0, MAX_WORKSHEET_NAME_LENGTH - 3) + '...' : worksheet;
    this.activeWorkSheet = this.workbook.addWorksheet(worksheet, {
      pageSetup: {
        paperSize: 9,
        orientation: 'portrait',
        fitToPage: true,
        fitToHeight: 0,
        fitToWidth: 1
      }
    });
  }

  async getBlob(): Promise<Blob> {
    switch (this.format) {
      case 'csv': {
        const csvBuffer = await this.workbook.csv.writeBuffer();
        return new Blob([csvBuffer], { type: 'text/csv' });
      }
      case 'xlsx': {
        const xlsxBuffer = await this.workbook.xlsx.writeBuffer();
        return new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      }
    }
  }

  getFileName(): string {
    return `${this.name}.${this.format}`;
  }

  name: string;
  format: ExcelFormat;
  workbook: Workbook;
  private activeWorkSheet: Worksheet;
}
