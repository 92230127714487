import { getAspectOrDefault, IMetaAspect, IMetaProperty, isMetaRelation, MetaCardinality, RequiredRuleAspectIdentifier } from '../../meta';
import { IFieldValidationResult } from '../IFieldValidationResult';
import { inject, injectable } from 'inversify';
import { FieldValidationErrorCodes } from '../FieldValidationErrorCodes';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IValidationMessageProvider } from '../IValidationMessageProvider';
import { IFieldValidator } from '../IFieldValidator';
import { IValidationContext } from '../IValidationContext';
import { IExpressionEvaluator } from '../../expressions';

@injectable()
export class RequiredRuleValidator implements IFieldValidator {
  constructor(
    @inject(QuinoCoreServiceSymbols.IValidationMessageProvider) private readonly msgProvider: IValidationMessageProvider,
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly expressionEvaluator: IExpressionEvaluator
  ) {}

  validate(field: IMetaProperty, value: any, context: IValidationContext): IFieldValidationResult {
    const aspect = getAspectOrDefault(field, RequiredRuleAspectIdentifier);

    if (this.isNotRequiredField(aspect, field, context) || this.valueIsNotEmpty(value)) {
      return {};
    }

    return {
      fieldErrors: [
        {
          fieldName: field.name,
          errorCode: FieldValidationErrorCodes.REQUIRED,
          errorMessage: this.msgProvider.getRequiredError()
        }
      ]
    };
  }

  isNotRequiredField = (validationRule: IMetaAspect | null, field: IMetaProperty, context: IValidationContext) => {
    if (field.controlName === 'FileUpload') {
      return true;
    }

    if (isMetaRelation(field)) {
      if (field.cardinality == MetaCardinality.Multiple) {
        return true;
      }
    }

    if (field.required) {
      return !this.expressionEvaluator.evaluate<boolean>(field.required, context != null ? context.data : {});
    }

    return !validationRule;
  };

  valueIsNotEmpty = (value: any) => value !== undefined && value !== null && value !== '';
}
