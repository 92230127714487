import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for retrieving a breadcrumb parent aspect.
 */
export const BreadcrumbParentAspectIdentifier = 'BreadcrumbParent';

/**
 * Breadcrumb aspect
 */
export interface IBreadcrumbParentAspect extends IMetaAspect {
  parentRelationName: string;
  maxNumberOfLevels?: number;
}
