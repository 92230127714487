import {
  IExpressionVisitor,
  IExpressionVisitorSymbol,
  IFieldError,
  ILogger,
  IMetaProperty,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  isMetaRelation,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { IBookmark, IObjectBookmark, isIObjectBookmark } from '../../bookmarks';
import { useService } from '../../ioc/hook';
import { Dispatch, useCallback } from 'react';
import { useOnBookmarkChangedEvent } from './BookmarkHooks';
import { useRerender } from './useRenderer';
import { getDependentProperties } from './MetaElementHooks';

export const useMetaPropertyValueState = <T>(metaProperty: IMetaProperty, bookmark: IObjectBookmark): [T | undefined, Dispatch<T | undefined>] => {
  const fieldValueService = useService<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol);
  const value = fieldValueService.getFieldValue<T>(metaProperty, bookmark.genericObject);

  const updateBookmarkAndSetValue = useCallback(
    (newValue: T) => {
      if (newValue !== undefined) {
        bookmark.updateFieldValue(fieldValueService.getElementPath(metaProperty), newValue);
      } else {
        bookmark.updateFieldValue(fieldValueService.getElementPath(metaProperty), null);
      }
    },
    [bookmark, fieldValueService, metaProperty]
  );

  useRerenderOnChanges(bookmark, metaProperty);

  return [value, updateBookmarkAndSetValue];
};

export const useMetaPropertyOriginalValue = <T>(metaProperty: IMetaProperty, bookmark: IObjectBookmark): T | undefined => {
  const fieldValueService = useService<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol);
  return fieldValueService.getFieldValue<T>(metaProperty, bookmark.originalObject);
};

export const useRerenderOnChanges = (bookmark: IBookmark, metaProperty: IMetaProperty) => {
  const rerender = useRerender();
  const fieldValueService = useService<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol);
  const expressionVisitor = useService<IExpressionVisitor>(IExpressionVisitorSymbol);

  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  useOnBookmarkChangedEvent(
    bookmark,
    () => {
      if (isIObjectBookmark(bookmark)) {
        fieldValueService
          .validate(metaProperty, fieldValueService.getFieldValue(metaProperty, bookmark.genericObject), {
            layout: bookmark.layout,
            data: bookmark.genericObject
          })
          .then((fieldErrors: IFieldError[]) => {
            setValidationResult(fieldErrors, bookmark, metaProperty);
            rerender();
          })
          .catch((e) => {
            const message = 'Failed to rerender on on bookmark changes.';
            logger.logError(`${message} ${JSON.stringify(e)}`);
          });
      }
    },
    [metaProperty.path, ...(isMetaRelation(metaProperty) ? [fieldValueService.getElementPath(metaProperty)] : [])]
  );

  useOnBookmarkChangedEvent(
    bookmark,
    () => {
      if (isIObjectBookmark(bookmark)) {
        rerender();
      }
    },
    [...getDependentProperties(expressionVisitor, metaProperty)]
  );
};

const setValidationResult = (fieldErrors: IFieldError[], bookmark: IObjectBookmark, property: IMetaProperty) => {
  bookmark.fieldErrors.delete(property.name);
  for (const x of fieldErrors) {
    if (bookmark.fieldErrors.has(x.fieldName)) {
      bookmark.fieldErrors.get(x.fieldName)!.push(x);
    } else {
      bookmark.fieldErrors.set(x.fieldName, [x]);
    }
  }
};
