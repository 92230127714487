import React from 'react';
import { IComponentRegistry } from './IComponentRegistry';
import { inject, injectable } from 'inversify';
import { QuinoUIServiceSymbols } from '../../ioc';
import { IComponentMappingProvider } from './IComponentMappingProvider';
import { IComponentMapping } from './IComponentMapping';
import { IQuinoEditorProps } from '../../components';

@injectable()
export class ComponentRegistry implements IComponentRegistry {
  constructor(@inject(QuinoUIServiceSymbols.IComponentMappingProvider) private readonly provider: IComponentMappingProvider) {}

  getComponent(controlIdentifier: string): React.ComponentType<any> | undefined {
    return this.getInstance(controlIdentifier)?.component;
  }

  getListEditor(controlIdentifier: string): React.ComponentType<IQuinoEditorProps> | undefined {
    return this.getInstance(controlIdentifier)?.listEditor;
  }

  private getInstance(controlIdentifier: string): IComponentMapping | undefined {
    const instances = this.provider.getInstances();
    return instances.find((x) => x.controlIdentifier === controlIdentifier);
  }
}
