import { IMetaAspect } from './IMetaAspect';
import { IExpression } from '../expressions';

/**
 * Top level interface for working with layouts.
 */
export interface IMetaElement {
  /**
   * The name used by this layout.
   */
  name: string;

  /**
   * Identifies the given element inside the model-graph.
   * The value will be unique for each element in the model.
   */
  uri: string;

  /**
   * The control to use.
   */
  controlName: string;

  /**
   * Whether the layout is visible.
   */
  visible: IExpression | boolean;

  /**
   * Whether the element is read-only.
   */
  readOnly: IExpression | boolean;

  /**
   * Whether the element is required.
   */
  required: IExpression | boolean;

  /**
   * The caption to use with this group.
   */
  caption: string;

  /**
   * Whether the element is enabled.
   */
  enabled: IExpression | boolean;

  /**
   * List of aspects related to this element.
   */
  aspects: IMetaAspect[];
}

export function isIMetaElement(object: any): object is IMetaElement {
  return (
    object != null &&
    (object as IMetaElement).name !== undefined &&
    (object as IMetaElement).caption !== undefined &&
    (object as IMetaElement).aspects !== undefined
  );
}
