import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IDynamicStringCalculator } from './IDynamicStringCalculator';
import { IExpressionEvaluator } from '..';
import { IMetaElement, IMetaProperty } from '../../meta';

@injectable()
export class DynamicStringCalculator implements IDynamicStringCalculator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator) {}

  calculateCaption(element: IMetaElement, object: any = {}): string {
    if (element.caption == undefined) {
      return element.name;
    }

    if (typeof element.caption === 'string') {
      return element.caption;
    }

    return this.evaluator.evaluate<string>(element.caption, object);
  }

  calculateDescription(element: IMetaProperty, object: any): string | undefined {
    if (element.description === undefined || typeof element.description === 'string') {
      return element.description;
    }

    return this.evaluator.evaluate<string>(element.description, object);
  }

  calculateInstruction(element: IMetaProperty, object: any): string | undefined {
    if (element.instruction === undefined || typeof element.instruction === 'string') {
      return element.instruction;
    }

    return this.evaluator.evaluate<string>(element.instruction, object);
  }
}
