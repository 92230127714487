import { IExpression } from './IExpression';

export interface ICreateGuidExpression extends IExpression {
  Count: number;
  name: string;
}

export const isCreateGuidExpression = (object: IExpression): object is ICreateGuidExpression => {
  return (
    (object as ICreateGuidExpression).Count !== undefined &&
    (object as ICreateGuidExpression).name !== undefined &&
    (object as ICreateGuidExpression).name === 'CreateGuid'
  );
};
