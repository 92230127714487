import { inject, injectable } from 'inversify';
import { IBackendVersionInfoService } from './IBackendVersionInfoService';
import { QuinoCoreServiceSymbols } from '../ioc';
import { QuinoDataService } from '../api/QuinoServer';
import { IBackendVersionInfo } from './IBackendVersionInfo';

@injectable()
export class BackendVersionInfoService implements IBackendVersionInfoService {
  private readonly initialized: Promise<any>;
  constructor(@inject(QuinoCoreServiceSymbols.IDataService) private readonly quinoDataService: QuinoDataService) {
    this.initialized = this.initialize();
  }

  private async initialize(): Promise<void> {
    this.backendVersionInfo = await this.quinoDataService.getVersionInfoAsync();
  }

  async getBackendVersionInfo(): Promise<IBackendVersionInfo> {
    await this.initialized;
    return this.backendVersionInfo;
  }

  private backendVersionInfo: IBackendVersionInfo;
}
