import { IGenericObject } from '../../../../../data';
import { IMetaElement, isIMetaLayout } from '../../../../../meta';
import { IQuinoExcelFile, IQuinoExcelRow } from '../../model';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../../../../ioc';
import { IExportExcelConfigurationService } from '../../settings';
import { IExcelMapper } from '../IExcelMapper';

export const ExcelDefaultLayoutMapperSymbol = Symbol.for('ExcelDefaultLayoutMapper');

@injectable()
export class ExcelDefaultLayoutMapper implements IExcelMapper {
  constructor(
    @inject(QuinoCoreServiceSymbols.IExportExcelConfigurationService)
    private readonly excelExportConfigurationService: IExportExcelConfigurationService
  ) {}

  async map(genericObject: IGenericObject, element: IMetaElement, excelFile: IQuinoExcelFile): Promise<void> {
    if (excelFile.getActiveWorksheet() === undefined) {
      excelFile.name = `${genericObject.metaClass}_${element.caption}`;
      let title = `${element.caption}`;
      if (genericObject.title) {
        title = `${genericObject.title}`;
      }
      title = title.trimEnd();
      excelFile.addWorksheet(title);
      const row = excelFile.getActiveWorksheet().addRow([title]);
      const exportSettings = await this.excelExportConfigurationService.getExportSettings(null);
      row.font = {
        name: exportSettings.fontName,
        size: exportSettings.workSheetTitleSettings.fontSize,
        bold: exportSettings.workSheetTitleSettings.isBold
      };
      excelFile.getActiveWorksheet().pageSetup.margins = {
        top: this.toInch(exportSettings.pageMarginTop),
        bottom: this.toInch(exportSettings.pageMarginBottom),
        left: this.toInch(exportSettings.pageMarginLeft),
        right: this.toInch(exportSettings.pageMarginRight),
        header: this.toInch(exportSettings.pageMarginHeader),
        footer: this.toInch(exportSettings.pageMarginFooter)
      };
    }
  }

  toInch(cm: number): number {
    return cm * ExcelDefaultLayoutMapper.cmToInch;
  }

  canMap(element: IMetaElement): boolean {
    return isIMetaLayout(element);
  }

  canMapChildren(_element: IMetaElement): boolean {
    return false;
  }

  async getData(_genericObject: IGenericObject, _element: IMetaElement): Promise<IQuinoExcelRow[]> {
    return Promise.resolve([]);
  }

  private static readonly cmToInch = 0.3937007874;
}
