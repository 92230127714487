import { useService } from '../ioc';
import { usePromise } from '../components/Util/PromiseReducer';
import {
  INotificationConfiguration,
  INotificationConfigurationService,
  INotificationConfigurationServiceSymbol,
  INotificationConfigurationSymbol
} from '@quino/core';

export function useNotificationConfiguration(): INotificationConfiguration {
  const defaultConfiguration = useService<INotificationConfiguration>(INotificationConfigurationSymbol);
  const configurationService = useService<INotificationConfigurationService>(INotificationConfigurationServiceSymbol);

  const [configurationFromService] = usePromise<INotificationConfiguration, any>(async () => configurationService.getConfiguration(), []);

  return configurationFromService || defaultConfiguration;
}
