import { IExpression } from './IExpression';

export interface IIfExpressionEvaluator extends IExpression {
  name: string;
  Count: Number;
  Element0: IExpression;
  Element1: IExpression;
  Element2: IExpression;
}

export const isIfExpression = (object: IExpression): object is IIfExpressionEvaluator => {
  return (
    (object as IIfExpressionEvaluator).name === 'If' &&
    (object as IIfExpressionEvaluator).Element0 !== undefined &&
    (object as IIfExpressionEvaluator).Element1 !== undefined &&
    (object as IIfExpressionEvaluator).Element2 !== undefined &&
    (object as IIfExpressionEvaluator).Count === 3
  );
};
