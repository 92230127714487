import { inject, injectable } from 'inversify';
import { IBookmarkScopingService } from './IBookmarkScopingService';
import { INavigationService, INavigationServiceSymbol } from '../navigation/INavigationService';
import { isStateFullBookmark } from './IStateFullBookmark';
import { IScopingService, ScopingServiceSymbol } from '@quino/core';

@injectable()
export class BookmarkScopingService implements IBookmarkScopingService {
  constructor(
    @inject(INavigationServiceSymbol) private readonly navigationService: INavigationService,
    @inject(ScopingServiceSymbol) private readonly scopingService: IScopingService
  ) {}

  getScope(): string | null {
    return this.scopingService.getScope();
  }

  setScope(scope: string): void {
    const bookmark = this.navigationService.active;
    if (isStateFullBookmark(bookmark)) {
      bookmark.setStateValue('scope', scope);
    }

    this.scopingService.setScope(scope);
  }
}
