import * as React from 'react';
import { PropsWithChildren } from 'react';
import { getMetaRelation, isMetaRelation } from '@quino/core';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { QuinoLabeled } from '../QuinoLabeled';
import { useValidation } from '../Util';
import { useLabelSettings, useMetadata } from '../../settings';
import { useRerenderOnChanges } from '../Util/MetaPropertyHooks';
import { QuinoSearchEdit } from './QuinoSearchEdit';

export function QuinoSearchEditLabeled(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const { element, bookmark } = props;

  if (!isMetaRelation(element)) {
    throw new Error('the Search Edit component can currently only handle relations.');
  }

  const metaRelation = getMetaRelation(element);
  const { description, caption, instruction } = metaRelation;
  const labelSettings = useLabelSettings(element);
  const { visible, required } = useMetadata(metaRelation, bookmark.genericObject);
  const [, errorMessages] = useValidation(bookmark, metaRelation);

  useRerenderOnChanges(bookmark, metaRelation);

  return (
    <QuinoLabeled
      visible={visible}
      className={'quino-input-fields-max-width'}
      label={caption}
      required={required}
      description={description}
      instruction={instruction}
      errorMessages={errorMessages}
      labelSettings={labelSettings}>
      <QuinoSearchEdit metaProperty={metaRelation} bookmark={bookmark} />
    </QuinoLabeled>
  );
}
