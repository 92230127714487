import { useEffect, useState } from 'react';
import { useService } from '../ioc';
import { IResponsivityService, IResponsivityServiceSymbol, TScreenHeight } from './IResponsivityService';

export const useScreenHeightMode = (): TScreenHeight => {
  const responsivityService = useService<IResponsivityService>(IResponsivityServiceSymbol);
  const [screenHeightMode, setScreenHeightMode] = useState<TScreenHeight>(responsivityService.getCurrentHeightMode());

  useEffect(() => {
    const symbol = responsivityService.subscribeToHeightChanges(setScreenHeightMode);

    return () => {
      responsivityService.unsubscribeFromHeightChanges(symbol);
    };
  }, [responsivityService]);

  return screenHeightMode;
};
