import { useService } from '../ioc';
import { ILanguageService, QuinoCoreServiceSymbols } from '@quino/core';

/**
 * Get the currently used language.
 */
export function useCurrentLanguage(): string | null {
  const languageService = useService<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);

  return languageService.getDeterminedLanguage();
}
