import React, { useEffect, useState } from 'react';
import { INotification, INotificationService, INotificationServiceSymbol } from '@quino/core';
import { QuinoNotificationContent } from './QuinoNotificationContent';
import { useService } from '../../ioc';
import { useNotificationConfiguration } from '../../settings';

export function QuinoToast() {
  const config = useNotificationConfiguration();
  const { subscribeToNotification, unsubscribeFromNotification, clearNotification } = useService<INotificationService>(INotificationServiceSymbol);
  const [currentNotifications, setCurrentNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    const symbol = subscribeToNotification('global', (notifications: INotification[]) => {
      setCurrentNotifications(
        notifications.filter(
          (notification) =>
            !(notification.type === 'success' && !config.toastShowSuccessMessages) && !(notification.type === 'info' && !config.toastShowInfoMessages)
        )
      );
    });
    return () => unsubscribeFromNotification(symbol);
  }, [config.toastShowInfoMessages, config.toastShowSuccessMessages, subscribeToNotification, unsubscribeFromNotification]);

  return (
    <div className={'quino-toast-container' + (currentNotifications.length > 0 ? ' is--open' : '')}>
      {currentNotifications.map((notification, index) => (
        <QuinoNotificationContent
          key={'quino-notification-content-' + index}
          closeButtonIsShown={notification.closeButtonIsShown}
          type={notification?.type || 'error'}
          message={notification?.message || ''}
          messageDetails={notification?.messageDetails}
          customElement={notification?.customElement}
          onClose={() => notification.symbol && clearNotification(notification.symbol)}
          customHeight={44}
          customActions={notification?.customActions}
        />
      ))}
    </div>
  );
}
