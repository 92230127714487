import { IClientActionProvider } from './IClientActionProvider';
import { IMetaAction, Provider } from '@quino/core';
import { injectable } from 'inversify';
import { IClientAction } from './IClientAction';

@injectable()
export class ClientActionProvider extends Provider<IClientAction> implements IClientActionProvider {
  find = (action: IMetaAction): IClientAction | undefined => {
    const key = Object.keys(this.instances).find((x) => x === action.name);
    if (key) {
      return this.instances[key];
    }

    return undefined;
  };
}
