import { IListBookmark, isIListBookmark } from './IListBookmark';
import { isIBookmark } from './IBookmark';

/**
 * A list bookmark with a prefiltered data source
 * The filterExpression must be an array of filter expressions applicable to a DevEx DataSource
 */
export interface IPrefilteredListBookmark extends IListBookmark {
  filterExpression: any[];
}

/**
 * Whether the given object is an IPrefilteredListBookmark
 */
export const isIPrefilteredListBookmark = (object: any): object is IPrefilteredListBookmark => {
  return isIBookmark(object) && isIListBookmark(object) && (object as IPrefilteredListBookmark).filterExpression !== undefined;
};
