export const ICUIExplorerSettingsSymbol = Symbol.for('ICUIExplorerSettings');

/**
 * Settings for configuring the CUI environment.
 */
export interface ICUIExplorerSettings {
  /**
   * Boolean which indicates if the root siblings should be visible
   */
  showRootSiblings: boolean;
}

export const defaultCUIExplorerSettings: ICUIExplorerSettings = {
  showRootSiblings: false
};
