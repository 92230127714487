import { inject, injectable } from 'inversify';
import {
  IConfigurationService,
  IConfigurationServiceSymbol,
  IExpression,
  IRequestBuilder,
  ITranslationService,
  ITranslationServiceSymbol,
  IUrlManager,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { IDashboardConfigurationService } from './IDashboardConfigurationService';
import { IDashboardConfiguration, IDashboardConfigurationSymbol } from './IDashboardConfiguration';
import { ISharedDashboardsService } from './ISharedDashboardsService';
import { IFeedbackService, IFeedbackServiceSymbol } from '../../feedback';

@injectable()
export class SharedDashboardsService implements ISharedDashboardsService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder,
    @inject(IFeedbackServiceSymbol) private readonly feedbackService: IFeedbackService,
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService
  ) {}

  async get(): Promise<string> {
    return this.requestBuilder.create(this.urlManager.getSharedDashBoardsUrl(), 'get').requiresAuthentication().fetchJson();
  }

  async set(value: string): Promise<void> {
    await this.requestBuilder.create(this.urlManager.getSharedDashBoardsUrl(), 'post').requiresAuthentication().setJsonPayload(value).fetch();
  }

  async parseExpression(stringExpression: string): Promise<IExpression | undefined> {
    const result = await this.requestBuilder
      .create(this.urlManager.getParseExpressionUrl(stringExpression), 'get')
      .requiresAuthentication()
      .fetchJson<IExpression>();
    if (!result) {
      void this.feedbackService.showMessage(
        this.translationService.translate('Dashboard.Options.VisibleExpression.Error.Title'),
        this.translationService.translate('Dashboard.Options.VisibleExpression.Error.Text')
      );
    }
    return result;
  }
}
