import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression, isExpression } from './IExpression';
import { inject, injectable } from 'inversify';
import { isChainExpression } from './IChainExpression';
import { QuinoCoreServiceSymbols } from '../ioc';

export const ChainExpressionEvaluatorSymbol = Symbol.for('ChainExpressionEvaluator');

@injectable()
export class ChainExpressionEvaluator implements IExpressionEvaluator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator) {}

  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (isExpression(expression) && isChainExpression(expression)) {
      let innerContext = context;

      innerContext = this.evaluator.evaluate(expression.target, innerContext);
      innerContext = this.evaluator.evaluate(expression.call, { ...context, ...innerContext });

      return innerContext;
    }

    // @ts-ignore
    return expression;
  }
}
