import { IAuthenticationService } from './IAuthenticationService';
import { IAuthenticationData } from './IAuthenticationData';
import { IAuthenticationResult } from './IAuthenticationResult';
import { injectable } from 'inversify';
import { IChangePasswordData } from './IChangePasswordData';
import { IResetPasswordData } from './IResetPasswordData';
import { AuthenticationState } from './AuthenticationState';

@injectable()
export class NullAuthenticationService implements IAuthenticationService {
  authenticateRequest = (_options: Request): void => {
    // NOP
  };

  isLoggedIn = async (): Promise<boolean> => Promise.resolve(true);

  loginAsync = async (_data: IAuthenticationData): Promise<IAuthenticationResult> => {
    return Promise.resolve({ state: AuthenticationState.Successful });
  };

  registerAsync = async (_data: IAuthenticationData): Promise<IAuthenticationResult> => {
    return Promise.resolve({ state: AuthenticationState.Successful });
  };

  logoutAsync = async (): Promise<void> => {
    return Promise.resolve();
  };

  authenticateUrl = (url: string): string => {
    return url;
  };

  getToken = (): string | null => {
    return null;
  };

  getUserInfo(): any {
    return {};
  }

  changePasswordAsync = async (_data: IChangePasswordData): Promise<void> => {
    return Promise.resolve();
  };

  resetPasswordAsync = async (_data: IResetPasswordData): Promise<void> => {
    return Promise.resolve();
  };
}
