import React from 'react';
import { ILogger, ITranslationService } from '@quino/core';

interface IProps {
  logger: ILogger;
  translationService: ITranslationService;
  componentName: string;
  componentControlName: string;
}

interface IState {
  hasError: boolean;
  error: Error | undefined;
}

export class ComponentErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false,
      error: undefined
    };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error: error
    };
  }

  componentDidCatch(error: any, info: any) {
    const message = 'Error boundary caught an error.';
    this.props.logger.logError(`${message} error: ${JSON.stringify(error)} info: ${JSON.stringify(info)}`);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <p className={'quino-error-boundary-message-title'}>
            {this.props.translationService.translate('Notification.ComponentErrorBoundaryError', {
              name: this.props.componentName,
              controlName: this.props.componentControlName
            })}
          </p>
          {this.state.error && <p className={'quino-error-boundary-message-text'}>{this.state.error.name + ': ' + this.state.error.message}</p>}
        </div>
      );
    }

    return this.props.children;
  }
}
