import { ILayoutScope } from './ILayoutScope';
import { IExpression, IExpressionEvaluator } from '../expressions';
import { IMetaLayout } from '../meta';

/**
 * A layout scope based on an expression.
 */
export class ExpressionLayoutScope implements ILayoutScope {
  constructor(private readonly expression: IExpression, private readonly evaluator: IExpressionEvaluator) {}

  applicable(layout: IMetaLayout): boolean {
    return this.evaluator.evaluate<boolean>(this.expression, layout);
  }
}
