import {
  DropDownOptionsAspectIdentifier,
  DefaultLabelConfigurationService,
  QuinoUIServiceSymbols,
  ILabelSettings,
  ILabelOptionsAspect
} from '@quino/ui';
import { getAspectOrDefault, IConfigurationService, IConfigurationServiceSymbol, IMetaElement } from '@quino/core';
import { inject, injectable } from 'inversify';

@injectable()
export class CUILabelConfigurationService extends DefaultLabelConfigurationService {
  constructor(
    @inject(QuinoUIServiceSymbols.ILabelSettings) labelSettings: ILabelSettings,
    @inject(IConfigurationServiceSymbol) private readonly configService: IConfigurationService
  ) {
    super(labelSettings);
  }

  getLabelSettings(element: IMetaElement | null): ILabelSettings {
    const result = super.getLabelSettings(element);

    // Apply global remote config
    this.configService.apply(result, 'LabelConfiguration');

    // Apply custom options from the element
    let customOverlay = null;
    if (element) {
      const aspect = getAspectOrDefault<ILabelOptionsAspect>(element, DropDownOptionsAspectIdentifier);
      customOverlay = aspect != null ? aspect.options : {};
    }

    return { ...result, ...customOverlay };
  }
}
