import { injectable } from 'inversify';
import { IResponsivityBreakpointService } from './IResponsivityBreakpointService';

@injectable()
export class ResponsivityBreakpointService implements IResponsivityBreakpointService {
  getPhoneBreakpoint(): number {
    return 768;
  }

  getTabletBreakpoint(): number {
    return 960;
  }
}
