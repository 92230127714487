import { IAuthenticationService } from './IAuthenticationService';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IUrlManager } from '../api';
import { IAuthenticationResult } from './IAuthenticationResult';
import { IAuthenticationData } from './IAuthenticationData';
import { IChangePasswordData } from './IChangePasswordData';
import { IResetPasswordData } from './IResetPasswordData';
import { AuthenticationState } from './AuthenticationState';
import { IMessenger, IMessengerSymbol } from '../core';
import { ExpressionContextChangedMessage, ExpressionContextChangedMessageSymbol } from '../expressions';
import { IRequestDecoratorProvider, IRequestFactory } from '../request';
import { ILogger } from '../logging';

@injectable()
export class DefaultAuthenticationService implements IAuthenticationService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) protected readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IStorage) protected readonly storage: Storage,
    @inject(IMessengerSymbol) readonly messenger: IMessenger,
    @inject(QuinoCoreServiceSymbols.IRequestFactory) readonly requestFactory: IRequestFactory,
    @inject(QuinoCoreServiceSymbols.IRequestDecoratorProvider) readonly decoratorProvider: IRequestDecoratorProvider,
    @inject(QuinoCoreServiceSymbols.ILogger) readonly logger: ILogger
  ) {
    messenger.subscribe(ExpressionContextChangedMessageSymbol, (payload: ExpressionContextChangedMessage) => {
      // Re-calculate the user-info if a context-switch happens.
      const token = storage.getItem(this.storageKey);
      if (token != null) {
        void this.evaluateUserInfo(token, payload.context);
      }
    });
  }

  loginAsync = async (data: IAuthenticationData): Promise<IAuthenticationResult> => {
    const result = await window.fetch(this.urlManager.getLoginUrl(), {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (result !== undefined && result.ok && !result.bodyUsed) {
      const token = await result.text();

      if (!token.toLowerCase().startsWith('bearer')) {
        throw new Error('Invalid token received');
      }

      this.storage.setItem(this.storageKey, token);

      await this.evaluateUserInfo(token);

      return { state: AuthenticationState.Successful };
    } else if (result !== undefined && result.status === 401) {
      const body = await result.text();
      switch (body) {
        case 'EmailNotConfirmed':
          return { state: AuthenticationState.EmailNotConfirmed };
        case 'PhoneNotConfirmed':
          return { state: AuthenticationState.PhoneNotConfirmed };
        case 'AccountLocked':
          return { state: AuthenticationState.AccountLocked };
        case 'RequiresTwoFactor':
          return { state: AuthenticationState.RequiresTwoFactor };
        case 'InvalidLogin':
          return { state: AuthenticationState.Failed };
      }
    }

    return { error: result && (await result.text()), state: AuthenticationState.Failed };
  };

  changePasswordAsync = async (data: IChangePasswordData): Promise<void> => {
    const request = this.requestFactory.create(this.urlManager.getIdentityUrl() + '/password/change', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.getToken()!
      }
    });

    this.decoratorProvider.getInstances().forEach((decorator) => decorator.decorate(request));
    const result = await window.fetch(request);

    if (result.status !== 200) {
      return Promise.reject(await result.json());
    }
  };

  resetPasswordAsync = async (data: IResetPasswordData): Promise<void> => {
    const request = this.requestFactory.create(this.urlManager.getIdentityUrl() + '/password/reset', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    this.decoratorProvider.getInstances().forEach((decorator) => decorator.decorate(request));
    const result = await window.fetch(request);

    if (!result.ok) {
      return Promise.reject(await result.json());
    }
  };

  isLoggedIn = async (): Promise<boolean> => {
    if (this.storage.getItem(this.storageKey) == null) {
      return Promise.resolve(false);
    }

    const url = this.urlManager.getLoginUrl() + '/check';
    const authorization = this.storage.getItem(this.storageKey)!;
    const request = this.requestFactory.create(url, {
      method: 'get',
      headers: {
        Authorization: authorization
      }
    });

    this.decoratorProvider.getInstances().forEach((decorator) => decorator.decorate(request));
    const result = await window.fetch(request);

    if (result.ok && this.userInfo == null) {
      await this.evaluateUserInfo(authorization);
    }

    if (result.status === 401) {
      this.storage.removeItem(this.storageKey);
    }

    return Promise.resolve(result.ok);
  };

  logoutAsync = async (): Promise<void> => {
    this.storage.removeItem(this.storageKey);
    this.userInfo = null;

    return Promise.resolve();
  };

  authenticateRequest = (options: Request): void => {
    const token = this.storage.getItem(this.storageKey);
    if (!token) {
      console.debug('Cannot authenticate request - no token available.');
    } else {
      options.headers.set('Authorization', token);
    }
  };

  authenticateUrl = (url: string): string => {
    const token = this.storage.getItem(this.storageKey);
    if (!token) {
      console.debug('Cannot authenticate request - no token available.');
    } else {
      const prefix = url.indexOf('?') > -1 ? '&' : '?';
      return `${url + prefix}access_token=${encodeURIComponent(token)}`;
    }

    return url;
  };

  getToken = (): string | null => {
    return this.storage.getItem(this.storageKey);
  };

  getUserInfo(): any {
    return this.userInfo;
  }

  protected async evaluateUserInfo(token: string, context: any = null): Promise<any> {
    const additionalContext = context ? ({ Context: context.value } as any) : {};
    const request = this.requestFactory.create(this.urlManager.getUserInfoUrl(), {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
        ...additionalContext
      }
    });

    this.decoratorProvider.getInstances().forEach((decorator) => decorator.decorate(request));
    this.userInfo = await (await window.fetch(request)).json();

    if (this.userInfo == null) {
      // #10966: token valid, but user deleted
      this.logoutAsync()
        .then(() => document.location.assign(document.location.origin))
        .catch(this.logger.logError);
    }
  }

  private userInfo: any;

  protected readonly storageKey = '_authToken';
}
