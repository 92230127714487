import { IAuthorizationService } from './IAuthorizationService';
import { IAuthorization } from './IAuthorization';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IAuthenticationService } from '../authentication';
import { IMetaProperty } from '../meta';

@injectable()
export class DefaultAuthorizationService implements IAuthorizationService {
  constructor(@inject(QuinoCoreServiceSymbols.IAuthenticationService) private readonly authService: IAuthenticationService) {}

  getAuthorization(entityName: string): IAuthorization {
    const userInfo = this.authService.getUserInfo();
    if (userInfo == null) {
      return { ...this.defaultAuth };
    }

    const userInfoAuthorizationElement = userInfo['authorization'];
    if (userInfoAuthorizationElement == null) {
      return { ...this.defaultAuth };
    }

    const permissions = userInfoAuthorizationElement[entityName] as string[];
    if (permissions == null) {
      return { ...this.defaultAuth };
    }

    const authorization: IAuthorization = {
      canRead: () => permissions.find((x) => x.toUpperCase() === 'READ') != null,
      canCreate: () => permissions.find((x) => x.toUpperCase() === 'CREATE') != null,
      canDelete: () => permissions.find((x) => x.toUpperCase() === 'DELETE') != null,
      canUpdate: () => permissions.find((x) => x.toUpperCase() === 'UPDATE') != null,
      canExecute: () => permissions.find((x) => x.toUpperCase() === 'EXECUTE') != null
    };

    return {
      ...authorization
    };
  }

  getAuthorizationForProperty = (property: IMetaProperty, metaClass: string): IAuthorization => {
    return this.getAuthorization(metaClass);
  };

  private readonly defaultAuth: IAuthorization = {
    canRead: () => true,
    canCreate: () => true,
    canDelete: () => true,
    canUpdate: () => true,
    canExecute: () => true
  };
}
