export const IWizardServiceSymbol = Symbol.for('IWizardService');

/**
 * Service that shows a wizard with multiple steps or a single one with multiple inputs
 */
export interface IWizardService {
  /**
   * Show a wizard with multiple steps and a result step
   * @param title title of the wizard dialogue
   * @param steps list of steps to be displayed
   * @param action action to be executed on submission
   * @param doneText text of the submission button
   * @param doneIcon icon of the submission button
   * @param width of the wizard
   * @param height of the wizard
   */
  showWizard(
    title: string,
    steps: IWizardStep[],
    action: (data: object) => Promise<JSX.Element>,
    doneText?: string,
    doneIcon?: string,
    width?: string | number,
    height?: string | number
  ): Promise<void>;

  /**
   * Shows a simple one step wizard
   * @param title title of the wizard dialogue
   * @param inputs list of inputs to be displayed
   * @param doneText text of the submission button
   * @param doneIcon icon of the submission button
   */
  showSimpleWizard(title: string, inputs: WizardInput[], doneText?: string, doneIcon?: string): Promise<object>;
}

export interface IWizardStep {
  title: string;
  inputs: WizardInput[];
}

interface IBaseWizardInput {
  name: string;
  text: string;
  default?: any;
  description?: string;
  instruction?: string;
}

interface IWizardInput extends IBaseWizardInput {
  type: 'text' | 'file' | 'checkbox';
}

interface IWizardSelect extends IBaseWizardInput {
  type: 'select';
  options: IWizardSelectOption[];
}

interface IWizardSelectOption {
  value: string | number;
  text?: string;
  hint?: string;
}

export type WizardInput = IWizardInput | IWizardSelect;
