import { inject, injectable } from 'inversify';
import {
  AuthenticationState,
  IAuthenticationData,
  IAuthenticationResult,
  IIdentityService,
  IUrlManager,
  IUrlHelper,
  IRequestBuilder,
  QuinoCoreServiceSymbols
} from '@quino/core';

@injectable()
export class IdentityService implements IIdentityService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IUrlHelper) private readonly urlHelper: IUrlHelper,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) readonly requestBuilder: IRequestBuilder
  ) {}

  registerAsync = async (data: IAuthenticationData, returnUrl: string | undefined): Promise<IAuthenticationResult> => {
    const request = this.requestBuilder
      .create(this.urlHelper.removeTrailingSlash(this.urlManager.getIdentityUrl()) + '/register', 'post')
      .setJsonPayload({ ...data, returnUrl });

    const result = await request.fetch();

    if (result !== undefined && result.ok && !result.bodyUsed) {
      return { state: AuthenticationState.Successful };
    }

    return { error: result && (await result.json()), state: AuthenticationState.Failed };
  };
}
