import { IGenericObject } from './index';
import { IMetaProperty } from '../meta';
import { IFieldError, IValidationContext } from '../validations';

/**
 * Symbol for the title calculator.
 */
export const IMetaPropertyValueServiceSymbol = Symbol.for('IMetaPropertyValueService');

export interface IMetaPropertyValueService {
  /**
   * Gets the value for a field from the data object using the metaProperty.
   * @param metaProperty the metaProperty which contains the path for the value.
   * @param genericObject the data object the value originates from.
   */
  getFieldValue<T>(metaProperty: IMetaProperty, genericObject: IGenericObject): T | undefined;

  /**
   * Gets the value for a field from the data object using the metaProperty.
   * @param metaProperty the IMetaProperty which contains the path for the value.
   * @param genericObject the data object the value originates from.
   */
  getPlainValue<T>(metaProperty: IMetaProperty, genericObject: IGenericObject): T | undefined;

  /**
   * Gets the data for a related property field
   * @param baseProperty The property containing the path to the targeted property.
   * @param baseObject The data object containing the extended data.
   * @returns The target property and the generic object containing the property value
   */
  getRelatedPropertyData(
    baseProperty: IMetaProperty,
    baseObject: IGenericObject
  ): { metaProperty: IMetaProperty; genericObject: IGenericObject } | undefined;

  /**
   * Sets the value for a field from the data object using the metaProperty.
   * @param value the new meta properties value.
   * @param fieldName field name of the value to set
   * @param genericObject the data object the value originates from.
   */
  setPlainValue<T>(value: T | undefined, fieldName: string, genericObject: IGenericObject): boolean;

  /**
   * Gets the path for a metaProperty which must be used to access the field value within the data object.
   * @param metaProperty The metaProperty to get the path for.
   */
  getElementPath(metaProperty: IMetaProperty): string;

  /**
   * Validates a value based on the information in the metaProperty
   * @param metaProperty The metaProperty the validation is based on.
   * @param value The current value.
   * @param validationContext The the context in which the field gets validated.
   */
  validate(metaProperty: IMetaProperty, value: any, validationContext: IValidationContext): Promise<IFieldError[]>;
}
