import {
  IComponentFactory,
  IGenericObjectPersistenceService,
  IGenericObjectPersistenceServiceSymbol,
  IObjectBookmark,
  IQuinoMetaPanelActions,
  ObjectBookmark,
  QuinoErrorSummary,
  QuinoLoadButton,
  QuinoUIServiceSymbols,
  useOnMount,
  useService
} from '@quino/ui';
import {
  AuthenticationState,
  IAuthenticationResult,
  IControlBehaviorRegistry,
  IGenericObject,
  IIdentityService,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  IPublicMetaDataTree,
  IPublicMetadataTreeSymbol,
  ITranslationService,
  ITranslationServiceSymbol,
  IValidator,
  IVisibleCalculator,
  QuinoCoreServiceSymbols
} from '@quino/core';
import React, { ReactNode, useState } from 'react';
import { ScrollView } from 'devextreme-react/scroll-view';
import { CUIAuthenticationMarkdownBlock } from './CUIAuthenticationMarkdownBlock';

export interface ICUIRegisterComponentProps {
  onRegister: () => void;
  onGoBack: () => void;
  focusRequested: boolean;
}

export function CUIRegisterAccountComponent(props: React.PropsWithChildren<ICUIRegisterComponentProps>) {
  const componentFactory = useService<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory);
  const actions = useService<IQuinoMetaPanelActions>(QuinoUIServiceSymbols.IQuinoMetaPanelActions);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const publicMetadataTree = useService<IPublicMetaDataTree>(IPublicMetadataTreeSymbol);
  const validator = useService<IValidator>(QuinoCoreServiceSymbols.IValidator);
  const metaPropertyValueService = useService<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol);
  const genericObjectPersistenceService = useService<IGenericObjectPersistenceService>(IGenericObjectPersistenceServiceSymbol);
  const identityService = useService<IIdentityService>(QuinoCoreServiceSymbols.IIdentityService);
  const controlModifiedRegistry = useService<IControlBehaviorRegistry<boolean>>(QuinoCoreServiceSymbols.ControlModifiedRegistry);
  const visibleCalculator = useService<IVisibleCalculator>(QuinoCoreServiceSymbols.IVisibleCalculator);

  const { onRegister, onGoBack } = props;
  const [error, setError] = useState<string[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [layoutElements, setLayoutElements] = useState<ReactNode[]>([]);
  const [objectBookmark, setObjectBookmark] = useState<IObjectBookmark>();
  const registrationLayoutName = 'Registration';
  const registrationClassName = 'QuinoIdentityUser';

  const markdownRegistrationGreeting = translationService.exists('Authentication.MarkdownRegistrationGreeting')
    ? translationService.translate('Authentication.MarkdownRegistrationGreeting')
    : '';

  const markdownRegistrationConditions = translationService.exists('Authentication.MarkdownRegistrationConditions')
    ? translationService.translate('Authentication.MarkdownRegistrationConditions')
    : '';

  const register = async (): Promise<void> => {
    setLoading(true);
    objectBookmark
      ?.validate()
      .then(async (validationResult) => {
        if (!validationResult.hasErrors) {
          return identityService
            .registerAsync(objectBookmark?.genericObject, document.location.pathname + document.location.search)
            .then((result: IAuthenticationResult) => {
              switch (result.state) {
                case AuthenticationState.Successful:
                  onRegister();
                  break;
                default:
                  if (result.error) {
                    if (Array.isArray(result.error)) {
                      setError(result.error.map((x) => x.description));
                    } else {
                      setError([result.error]);
                    }
                  }
                  break;
              }
            });
        }

        return Promise.resolve();
      })
      .catch(() => setError([translationService.translate('Notification.ErrorBoundaryError')]))
      .finally(() => {
        setLoading(false);
      });
  };

  const getLayoutElements = () => {
    const metaClass = publicMetadataTree.model.classes.find((c) => c.name.toLowerCase() === registrationClassName.toLowerCase());
    if (!metaClass) {
      throw new Error(`Could not find [${registrationClassName}] class in public model.classes`);
    }

    const layout = metaClass.layouts.find((layout) => layout.name === registrationLayoutName);
    if (!layout) {
      throw new Error(`Could not find [${registrationLayoutName}] layout in [${registrationClassName}] class.`);
    }

    // Create a new object manually because ODataSourceFactory cannot be used without authorization
    const newObject: IGenericObject = { metaClass: registrationClassName, title: layout.caption };
    const newObjectBookmark = new ObjectBookmark(
      newObject,
      layout,
      metaClass,
      async () => Promise.resolve(newObject),
      genericObjectPersistenceService,
      validator,
      metaPropertyValueService,
      logger,
      controlModifiedRegistry,
      visibleCalculator
    );

    setObjectBookmark(newObjectBookmark);
    setLayoutElements(newObjectBookmark.layout.elements.map((child) => componentFactory.create(child, newObjectBookmark, actions)));
  };

  useOnMount(() => {
    const unload = loadingFeedback.load();
    try {
      getLayoutElements();
    } catch (error) {
      logger.logError(error);
      setError([error.message]);
    } finally {
      unload();
    }
  });

  return (
    <>
      <h3 className={'quino-auth-title'}>{translationService.translate('QuinoAuthenticationFeedback.TitleRegister')}</h3>
      <form className={'quino-auth-form-content'}>
        <ScrollView height={400} className={'quino-auth-scrollview'} useNative={false}>
          {/*Do not remove div to prevent scroll view crashes when empty*/}
          <div>
            <CUIAuthenticationMarkdownBlock content={markdownRegistrationGreeting} customClasses={'margin-bottom'} />
            <div className={'quino-auth-registration-layout-elements'}>{layoutElements}</div>
            <QuinoErrorSummary errorMessages={error} />
            <CUIAuthenticationMarkdownBlock content={markdownRegistrationConditions} customClasses={'margin-top'} />
          </div>
        </ScrollView>
        <div className={'quino-auth-footer'}>
          <QuinoLoadButton
            text={translationService.translate('QuinoAuthenticationFeedback.ButtonRegister')}
            icon={'material-icons-outlined check'}
            type={'default'}
            stylingMode={'contained'}
            className={'quino-auth-button'}
            onClick={register}
            disabled={!objectBookmark}
            loading={loading}
          />
          <p className={'quino-auth-hint-text'}>
            {translationService.translate('QuinoAuthenticationFeedback.HintAlreadyHaveAccount')}{' '}
            <a href='#' onClick={onGoBack}>
              {translationService.translate('QuinoAuthenticationFeedback.ButtonGotoLogin')}
            </a>
          </p>
        </div>
      </form>
    </>
  );
}
