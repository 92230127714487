/**
 * Lookup normalizer to unify keys.
 */
export class LookupNormalizer {
  /**
   * Normalize the given key.
   * @param key - the key to normalize.
   */
  public static Normalize(key: string): string {
    return key != null ? key.toUpperCase() : key;
  }
}
