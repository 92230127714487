import { QuinoUIServiceSymbols, useService } from '../../ioc';
import * as React from 'react';
import { HOC, Omit } from '../../rendering';
import { IComponentFactory } from './IComponentFactory';

interface IWithComponentFactory {
  componentFactory: IComponentFactory;
}

interface IProps<T extends IWithComponentFactory> {
  innerComponent: React.ComponentType<T>;
  innerProps: Omit<T, 'componentFactory'>;
}

const ComponentFactoryProvider = <T extends IWithComponentFactory>(props: IProps<T>) => {
  const componentFactory = useService<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory);

  const InnerComponent = props.innerComponent;
  return <InnerComponent componentFactory={componentFactory} {...(props.innerProps as any)} />;
};

export const withComponentFactory: HOC<IWithComponentFactory> = (InnerComponent) => (props) =>
  <ComponentFactoryProvider innerComponent={InnerComponent} innerProps={props as any} />;
