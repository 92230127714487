import { ITranslationApiResponse } from './index';

/**
 * Backend translation service symbol.
 */
export const IBackendTranslationLoaderSymbol = Symbol.for('IBackendTranslationLoader');

/**
 * Interface for retrieving translations from the API
 */
export interface IBackendTranslationLoader {
  /**
   * Retrieve a list of translations for all languages
   */
  getTranslationsAsync(languageCode?: string): Promise<ITranslationApiResponse>;
}
