import {
  DefaultFileUploadConfigurationService,
  FileUploadOptionsAspectIdentifier,
  IFileUploadOptionsAspect,
  IFileUploadSettings,
  QuinoUIServiceSymbols
} from '@quino/ui';
import { getAspectOrDefault, IConfigurationService, IConfigurationServiceSymbol, IMetaElement } from '@quino/core';
import { inject, injectable } from 'inversify';

@injectable()
export class CUIFileUploadConfigurationService extends DefaultFileUploadConfigurationService {
  constructor(
    @inject(QuinoUIServiceSymbols.IFileUploadSettings) fileUploadSettings: IFileUploadSettings,
    @inject(IConfigurationServiceSymbol) private readonly configService: IConfigurationService
  ) {
    super(fileUploadSettings);
  }

  getFileUploadSettings(element: IMetaElement | null): IFileUploadSettings {
    const result = super.getFileUploadSettings(element);

    // Apply global remote config
    this.configService.apply(result, 'FileUploadConfiguration');

    // Apply custom options from the element
    let customOverlay = null;
    if (element) {
      const aspect = getAspectOrDefault<IFileUploadOptionsAspect>(element, FileUploadOptionsAspectIdentifier);
      customOverlay = aspect != null && aspect.options != null ? aspect.options : {};
    }

    return { ...result, ...customOverlay };
  }
}
