import {
  DefaultDropDownConfigurationService,
  IQuinoDropDownSettingsSymbol,
  IQuinoDropDownSettings,
  IDropDownOptionsAspect,
  DropDownOptionsAspectIdentifier
} from '@quino/ui';
import { getAspectOrDefault, IConfigurationService, IConfigurationServiceSymbol, IMetaElement } from '@quino/core';
import { inject, injectable } from 'inversify';

@injectable()
export class CUIDropDownConfigurationService extends DefaultDropDownConfigurationService {
  constructor(
    @inject(IQuinoDropDownSettingsSymbol) dropDownSettings: IQuinoDropDownSettings,
    @inject(IConfigurationServiceSymbol) private readonly configService: IConfigurationService
  ) {
    super(dropDownSettings);
  }

  async getDropDownSettings(element: IMetaElement): Promise<IQuinoDropDownSettings> {
    // Fetch the default settings from the base service
    const result = await super.getDropDownSettings(element);

    // Basic overrides for CUI
    result.useCrossLink = true;

    // Apply global remote config
    this.configService.apply(result, 'DropDownConfiguration');

    // Apply custom options from the element
    const aspect = getAspectOrDefault<IDropDownOptionsAspect>(element, DropDownOptionsAspectIdentifier);
    const customOverlay = aspect != null ? aspect.options : {};

    return { ...result, ...customOverlay };
  }
}
