import { useService } from '../ioc/hook';
import { ILabelSettings } from './ILabelSettings';
import { QuinoUIServiceSymbols } from '../ioc';
import { IQuinoLabelConfigurationService } from './IQuinoLabelConfigurationService';
import { IMetaElement } from '@quino/core';

export function useLabelSettings(element: IMetaElement | null): ILabelSettings {
  const quinoLabelConfigurationService = useService<IQuinoLabelConfigurationService>(QuinoUIServiceSymbols.IQuinoLabelConfigurationService);
  return quinoLabelConfigurationService.getLabelSettings(element);
}
