import { IErrorMapper } from './IErrorMapper';
import { DefaultErrorMapper } from './DefaultErrorMapper';

export class RequestServerErrorMapper extends DefaultErrorMapper implements IErrorMapper {
  get errorName() {
    return 'ServerError.Request';
  }

  canHandleError(error: Error): boolean {
    try {
      const parsedError = JSON.parse(error.message);
      return parsedError.error != undefined && parsedError.error.code != undefined && parsedError.error.message != undefined;
    } catch {
      return false;
    }
  }

  getErrorMessage(error: Error): string {
    const parsedError = JSON.parse(error.message);
    return parsedError.error.code + ': ' + parsedError.error.message;
  }

  getErrorName(error: Error): string {
    return this.translationService.translate(this.errorName);
  }
}
