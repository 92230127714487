export const isShiftAndCtrlOrCmdEvent = (event: any): boolean => {
  return event && isCtrlOrCmdEvent(event) && event.shiftKey;
};

export const isCtrlOrCmdEvent = (event: any): boolean => {
  return event && (event.ctrlKey || event.metaKey);
};

export const setFocusOnElement = (id: string, focusOnChildInput?: boolean) => {
  let element = document.getElementById(id);
  if (element) {
    if (focusOnChildInput) {
      const inputs = element.getElementsByTagName('input');
      inputs.length > 0 && inputs[0].focus();
    } else {
      element.focus();
    }
  }
};
