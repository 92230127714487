import {
  DataType,
  getAspectOrDefault,
  IAuthenticationFeedback,
  IGenericObject,
  IGenericObjectFactory,
  IGenericObjectFactorySymbol,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILogger,
  IMetadataTree,
  IMetaLayout,
  IMetaRelation,
  INotificationService,
  INotificationServiceSymbol,
  IRequestBuilder,
  IRequestDecoratorProvider,
  isIMetaProperty,
  isMetaRelation,
  IValueListAspect,
  LayoutType,
  mapToDevexpressSort,
  MetaCardinality,
  ODataDefaultData,
  ODataUrlBuilder,
  ODataUrlBuilderSymbol,
  QuinoCoreServiceSymbols,
  QuinoDefaultDataODataStore,
  QuinoODataStore,
  ValueListAspectIdentifier
} from '@quino/core';
import { inject, injectable } from 'inversify';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { IODataSourceFactory } from './IODataSourceFactory';
import { IQuinoDataSourceOptions } from './IQuinoDataSourceOptions';
import { IDataSourceFactoryTools, IDataSourceFactoryToolsSymbol } from './IDataSourceFactoryTools';
import isArray from 'lodash/isArray';

type ODataResult = {
  context: string;
  value: any;
};

export const ODataBooleanUndefined = 'QuinoODataBooleanUndefined';

@injectable()
export class ODataSourceFactory implements IODataSourceFactory {
  constructor(
    @inject(QuinoCoreServiceSymbols.IAuthenticationFeedback) private readonly authenticationFeedback: IAuthenticationFeedback,
    @inject(IGenericObjectFactorySymbol) private readonly genericObjectFactory: IGenericObjectFactory,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(ILayoutResolverSymbol) private readonly layoutResolver: ILayoutResolver,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder,
    @inject(QuinoCoreServiceSymbols.IRequestDecoratorProvider) private readonly requestDecorator: IRequestDecoratorProvider,
    @inject(ODataUrlBuilderSymbol) private readonly odataUrlBuilder: ODataUrlBuilder,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(IDataSourceFactoryToolsSymbol) private readonly dataSourceFactoryTools: IDataSourceFactoryTools,
    @inject(INotificationServiceSymbol) private readonly notificationService: INotificationService
  ) {}

  fetchList = async (layout: IMetaLayout, metaClass: string, expandRelationsToMultiple?: boolean): Promise<IGenericObject[]> => {
    const builder = this.odataUrlBuilder.entity(metaClass);
    const targetClass = this.metadataTree.getClass(metaClass);
    builder.apply(targetClass, layout, expandRelationsToMultiple || false);
    builder.apply(targetClass, this.layoutResolver.resolveSingle({ type: LayoutType.Title, metaClass: targetClass.name, element: layout }), false);
    builder.setLayoutFilter(layout);

    const result = await this.requestBuilder.create(builder.toString(), 'get').requiresAuthentication().fetchJson<ODataResult>();

    return result.value.map((x: any) => this.genericObjectFactory.create(x, metaClass));
  };

  fetch = async (primaryKey: string, layout: IMetaLayout, metaClass: string, expandRelationsToMultiple?: boolean): Promise<IGenericObject> => {
    const builder = this.odataUrlBuilder.entity(metaClass, primaryKey);
    const targetClass = this.metadataTree.getClass(metaClass);
    builder.apply(targetClass, layout, expandRelationsToMultiple || false);
    const targetClassTitleLayout = this.layoutResolver.tryResolveSingle({ type: LayoutType.Title, metaClass: targetClass.name, element: layout });
    targetClassTitleLayout && builder.apply(targetClass, targetClassTitleLayout, false);

    const result = await this.requestBuilder.create(builder.toString(), 'get').requiresAuthentication().fetchJson<IGenericObject>();

    return this.genericObjectFactory.create(result, metaClass);
  };

  create(relation: IMetaRelation): DataSource;
  create(layout: IMetaLayout, metaClass: string, options?: IQuinoDataSourceOptions, defaultData?: ODataDefaultData): DataSource;
  create(layout: IMetaLayout | IMetaRelation, metaClass?: string, options?: IQuinoDataSourceOptions, defaultData?: ODataDefaultData): DataSource {
    if (isMetaRelation(layout)) {
      const effectiveLayout = this.layoutResolver.resolveSingle({
        type: LayoutType.Title,
        metaClass: layout.targetClass,
        element: layout
      });
      const sorts = effectiveLayout.sorts.length > 0 ? effectiveLayout.sorts : layout.sorts;
      return this.create(
        effectiveLayout,
        layout.targetClass,
        {
          pageSize: 10,
          paginate: true,
          sort: mapToDevexpressSort(sorts)
        },
        defaultData
      );
    }

    const resolvedClass = this.metadataTree.getClass(metaClass!);
    const sorts = layout.sorts.length > 0 ? layout.sorts : resolvedClass.sorts;
    return this.createInternal(layout, metaClass!, { sort: mapToDevexpressSort(sorts), ...options }, defaultData);
  }

  private readonly createInternal = (
    layout: IMetaLayout,
    metaClass: string,
    dataSourceOptions?: IQuinoDataSourceOptions,
    defaultData?: ODataDefaultData
  ): DataSource => {
    const relations = layout.elements.filter((element) => isIMetaProperty(element) && element.dataType === DataType.Relation);
    const urlBuilder = this.odataUrlBuilder.entity(metaClass);
    const targetClass = this.metadataTree.getClass(metaClass);
    const targetClassPrimaryKey = targetClass.properties.find((x) => x.name === targetClass.primaryKey[0]);
    const keyType = (targetClassPrimaryKey && (targetClassPrimaryKey.dataType === DataType.Guid ? 'Guid' : 'Int32')) || undefined;
    let dataSource: DataSource;

    const predefinedFilters = dataSourceOptions?.filter;

    const store = new ODataStore({
      url: urlBuilder.toString(),
      key: targetClass.primaryKey[0],
      keyType: keyType,
      version: 4,
      deserializeDates: false,
      beforeSend: (options) => {
        options.headers = {};
        if (layout.name) {
          options.params.layout = layout.name;
        }

        // TODO: Ensure some of the decorators get applied properly onto the OData request as well.
        const request = new Request(urlBuilder.toString(), { method: 'get' });
        this.requestDecorator.getInstances().forEach((decorator) => decorator.decorate(request, dataSourceOptions));

        request.headers.forEach((value: string, key: string) => {
          return (options.headers[key] = value);
        });
      },
      onLoading: (loadOptions) => {
        this.logger.logDebug(`Loading OData source with options: ${JSON.stringify(loadOptions)}`);

        if (!loadOptions.filter && loadOptions.searchOperation && loadOptions.searchValue) {
          loadOptions.filter = this.dataSourceFactoryTools.createFilter(loadOptions.searchOperation, loadOptions.searchValue, layout);
        }

        if (!loadOptions.filter && predefinedFilters) {
          loadOptions.filter = predefinedFilters;
        }

        // Allow filtering boolean values for null value #9931
        if (loadOptions.filter && loadOptions.filter.find((value: any) => value === ODataBooleanUndefined)) {
          loadOptions.filter = loadOptions.filter.map((value: any) => (value === ODataBooleanUndefined ? null : value));
        }

        const builder = this.odataUrlBuilder.entity(metaClass);
        builder.apply(targetClass, layout, dataSourceOptions?.expandRelationsToMultiple || false);
        builder.apply(targetClass, this.layoutResolver.resolveSingle({ type: LayoutType.Title, metaClass: metaClass }), false);
        const result = builder.raw();

        if (typeof dataSourceOptions?.select === 'string') {
          loadOptions.select = isArray(loadOptions.select)
            ? [...new Set<string>([...loadOptions.select, dataSourceOptions?.select])]
            : [dataSourceOptions?.select];
        }

        loadOptions.select = (isArray(loadOptions.select) ? [...new Set<string>([...loadOptions.select, ...result.select])] : result.select).filter(
          (x: any) => x != null
        );

        loadOptions.expand = builder.toExpand();
        if (!loadOptions.sort && dataSourceOptions?.sort) {
          // Devextreme is removing the default sorts in case the column is not visible/mapped. Re-apply them here.
          loadOptions.sort = dataSourceOptions.sort;
        }
      },
      onLoaded: (result) => {
        result.forEach((genericObject) => {
          this.genericObjectFactory.create(genericObject, metaClass);

          for (const relation of relations) {
            if (isMetaRelation(relation)) {
              const aspect = getAspectOrDefault<IValueListAspect>(relation, ValueListAspectIdentifier);
              if (!aspect && genericObject[relation.path] != null) {
                this.genericObjectFactory.create(genericObject[relation.path], metaClass);
              } else {
                if (relation.cardinality === MetaCardinality.MustBeOne && !aspect) {
                  this.logger.logWarn(
                    `Cannot create a related genericObject for the relation [${relation.name}.${relation.path}]. The related value for the given path is missing within the current genericObject even though it's a one to one relation. [${metaClass}] ID: ${genericObject.Id}`
                  );
                }
              }
            }
          }
        });
      },
      errorHandler: (e) => {
        if (e.httpStatus === 401) {
          this.logger.logDebug(`401 while trying to load OData source. Re-authenticating.`);
          this.authenticationFeedback.requestLogin(() => {
            dataSource.reload().catch((e) => {
              const message = 'Failed to reload OData source.';
              this.logger.logError(`${message} ${JSON.stringify(e)}`);
              this.notificationService.notify({ message: e.message });
            });
          });
          return;
        }

        const message = 'Failed to load OData source.';
        this.logger.logError(`${message} ${JSON.stringify(e)}`);
        this.notificationService.notify(
          { message: message, messageDetails: JSON.stringify(e) },
          e.httpStatus === 403 ? Symbol.for('http-403') : undefined
        );
      }
    });

    let finalStore;
    if (defaultData) {
      finalStore = new QuinoDefaultDataODataStore(store, this.logger, defaultData);
    } else {
      finalStore = new QuinoODataStore(store);
    }

    dataSource = new DataSource({
      store: finalStore,
      key: layout.name,
      paginate: true,
      pageSize: 40,
      ...dataSourceOptions,
      errorHandler: (e: any) => {
        this.logger.logError(`Failed to load OData source. ${JSON.stringify(e)}`);
      }
    });

    return dataSource;
  };
}
