import React, { PropsWithChildren, useContext, useState } from 'react';
import { QuinoPopup, useService, QuinoPopupToolbar, QuinoPopupToolbarButton, IHomeNavigationService, IHomeNavigationServiceSymbol } from '@quino/ui';
import { LoadPanel } from 'devextreme-react/load-panel';
import {
  IAuthenticationService,
  IQuinoContext,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoContextContext,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { ICUISettingsConfigurationService, ICUISettingsConfigurationServiceSymbol } from '../../configuration';
import { SelectBox } from 'devextreme-react/select-box';

export function CUIContextPopupSelector(props: PropsWithChildren<{}>) {
  const { currentContext, isLoading, availableContexts, setCurrentContext } = useContext(QuinoContextContext);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const authenticationService = useService<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService);
  const cuiSettings = useService<ICUISettingsConfigurationService>(ICUISettingsConfigurationServiceSymbol).getCuiSettings();
  const homeNavigationService = useService<IHomeNavigationService>(IHomeNavigationServiceSymbol);
  const fullReload = useService<ICUISettingsConfigurationService>(ICUISettingsConfigurationServiceSymbol).getCuiSettings().contextSelectorHardReload;

  const [selectedContext, setSelectedContext] = useState<IQuinoContext>();

  if (isLoading) {
    return <LoadPanel message={'Loading...'} visible={isLoading} showIndicator={isLoading} />;
  }

  const noContextsAvailable = () => {
    return (
      <>
        {translationService.translate('Context.NoContextText')}{' '}
        <a onClick={async () => authenticationService.logoutAsync().then(() => document.location.reload())}>
          {translationService.translate('Header.UserMenu.LogOut')}
        </a>
      </>
    );
  };

  return (
    <>
      {((!cuiSettings.contextSelectorVisible || currentContext) && props.children) || (
        <QuinoPopup visible={true} title={translationService.translate('Context.SelectionTitle')} height={215}>
          <div className={'quino-ecui-context-popup-selector-content '}>
            <p>{translationService.translate('Context.SelectionText')}</p>
            {(availableContexts.length > 0 && (
              <SelectBox
                showClearButton={false}
                acceptCustomValue={false}
                searchEnabled={true}
                searchMode={'contains'}
                dataSource={availableContexts.length === 0 && currentContext != null ? [currentContext] : availableContexts}
                value={selectedContext != null ? selectedContext.value : undefined}
                displayExpr={'caption'}
                valueExpr={'value'}
                onValueChanged={async (e) => {
                  const selectedContext = availableContexts.find((x) => x.value === e.value);
                  selectedContext && setSelectedContext(selectedContext);
                }}
              />
            )) ||
              noContextsAvailable()}
          </div>
          {availableContexts.length > 0 && (
            <QuinoPopupToolbar
              rightItems={[
                <QuinoPopupToolbarButton
                  text={translationService.translate('Context.ButtonText')}
                  icon={'material-icons-outlined done'}
                  disabled={!selectedContext}
                  isPrimary={true}
                  onClick={async () => {
                    selectedContext && setCurrentContext(selectedContext);
                    if (fullReload) {
                      await homeNavigationService.navigateHome();
                    }
                  }}
                />
              ]}
            />
          )}
        </QuinoPopup>
      )}
    </>
  );
}
