import { inject, injectable } from 'inversify';
import { IValidationMessageProvider } from './IValidationMessageProvider';
import { ITranslationService, ITranslationServiceSymbol } from '../lang';

@injectable()
export class ValidationMessageProvider implements IValidationMessageProvider {
  constructor(@inject(ITranslationServiceSymbol) private readonly translation: ITranslationService) {}

  getBoundsMinError = (minValue: number): string => this.translation.translate('Validations.MinValue', { minValue });

  getBoundsMaxError = (maxValue: number): string =>
    this.translation.translate('Validations.MaxValue', {
      maxValue
    });

  getDataTypeInvalidStringError = (): string => this.translation.translate('Validations.InvalidString');

  getDataTypeInvalidDateError = (): string => this.translation.translate('Validations.InvalidDate');

  getDataTypeInvalidDateTimeError = (): string => this.translation.translate('Validations.InvalidDateTime');

  getDataTypeInvalidTimeError = (): string => this.translation.translate('Validations.InvalidTime');

  getDataTypeInvalidBooleanError = (): string => this.translation.translate('Validations.InvalidBoolean');

  getDataTypeInvalidIntegerError = (): string => this.translation.translate('Validations.InvalidInteger');

  getDataTypeInvalidLargeIntegerError = (): string => this.translation.translate('Validations.InvalidLargeInteger');

  getDataTypeInvalidSmallIntegerError = (): string => this.translation.translate('Validations.InvalidSmallInteger');

  getDataTypeInvalidTinyIntegerError = (): string => this.translation.translate('Validations.InvalidTinyInteger');

  getDataTypeInvalidCurrencyError = (): string => this.translation.translate('Validations.InvalidCurrencyInteger');

  getDataTypeInvalidDoubleError = (): string => this.translation.translate('Validations.InvalidDoubleInteger');

  getOptionValueError = (): string => this.translation.translate('Validations.OptionValue');

  getRegExError = (): string => this.translation.translate('Validations.RegExError');

  getRequiredError = (): string => this.translation.translate('Validations.RequiredError');
}
