import { IQuinoShortcut } from './IQuinoShortcutSettings';
import { IRegisteredShortcutSettings } from './ShortcutService';
import { IRegisteredShortcut, IRegisterShortcutService } from './IRegisterShortcutService';
import { injectable } from 'inversify';

@injectable()
export class RegisterShortcutService implements IRegisterShortcutService {
  registerShortcut(shortcuts: IQuinoShortcut[], action: () => void, configuration: IRegisteredShortcutSettings): Symbol[] {
    const symbols: Symbol[] = [];
    shortcuts.forEach((shortcut) => {
      const symbol = Symbol();
      this.registeredShortcuts.set(symbol, { shortcut: shortcut, action: action, settings: configuration });
      symbols.push(symbol);
    });
    return symbols;
  }

  unregisterShortcut(symbols?: Symbol[]): void {
    symbols?.forEach((symbol) => this.registeredShortcuts.delete(symbol));
  }

  getRegisteredShortcuts(): IRegisteredShortcut[] {
    return Array.from(this.registeredShortcuts.values());
  }

  private readonly registeredShortcuts = new Map<Symbol, IRegisteredShortcut>();
}
