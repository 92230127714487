import { CalendarBookmark } from '../bookmarks/CalendarBookmark';
import React, { FC, useCallback, useContext, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { Button as TextBoxButton, TextBox } from 'devextreme-react/text-box';
import { Popover } from 'devextreme-react/popover';
import { useService } from '../ioc';
import {
  ILogger,
  INotificationService,
  INotificationServiceSymbol,
  IRequestBuilder,
  ITranslationService,
  ITranslationServiceSymbol,
  IUrlManager,
  QuinoContextContext,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { isIRefreshableBookmark } from '../bookmarks/IRefreshableBookmark';
import { Item, Toolbar } from 'devextreme-react/toolbar';

export interface ICalendarBookmarkActionsProps {
  bookmark: CalendarBookmark;
}

export const CalendarBookmarkActions: FC<ICalendarBookmarkActionsProps> = (props) => {
  const { bookmark } = props;

  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const notificationService = useService<INotificationService>(INotificationServiceSymbol);
  const requestBuilder = useService<IRequestBuilder>(QuinoCoreServiceSymbols.IRequestBuilder);
  const urlManager = useService<IUrlManager>(QuinoCoreServiceSymbols.IUrlManager);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const { currentContext } = useContext(QuinoContextContext);

  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const [exportUrl, setExportUrl] = useState<string>('');

  const isRefreshableBookmark = isIRefreshableBookmark(bookmark);
  const refreshClickAction = useCallback(() => {
    if (isRefreshableBookmark) {
      bookmark.refresh();
    }
  }, [bookmark, isRefreshableBookmark]);

  const loadExportUrl = useCallback(async () => {
    if (bookmark.aspect.exportConfiguration) {
      const relativeExportUrl = bookmark.aspect.exportConfiguration.relativeExportUrl;
      const exportUrl = relativeExportUrl.startsWith('/') ? relativeExportUrl : '/' + relativeExportUrl;
      const url = new URL(exportUrl.startsWith('/') ? window.location.origin + exportUrl : exportUrl);

      const tokenResponse = await requestBuilder
        .create(urlManager.getIdentityUrl() + '/urltoken', 'get')
        .requiresAuthentication()
        .fetch();

      if (tokenResponse.ok) {
        const token = await tokenResponse.text();
        url.pathname += '/' + token;
      }

      if (currentContext?.value) {
        url.pathname += '/' + currentContext?.value;
      }

      url.pathname += '/calendar.ics';

      setExportUrl(url.toString());
    }
  }, [bookmark.aspect.exportConfiguration, currentContext?.value, requestBuilder, urlManager]);

  return (
    <div className={'quino-calendar-bookmark-actions'}>
      <Button
        icon={'material-icons-outlined autorenew'}
        onClick={refreshClickAction}
        visible={isRefreshableBookmark}
        hint={translationService.translate('Dropdown.RefreshContent')}
        stylingMode={'text'}
      />
      {exportUrl != null && (
        <>
          <Button
            id={'calendar-sync-button'}
            icon={'material-icons-outlined cloud_download'}
            text={translationService.translate('Calendar.Popover.Action.Button.Text')}
            onClick={() => setIsPopoverVisible(!isPopoverVisible)}
            hint={translationService.translate('Calendar.Popover.Action.Button.Hint')}
            stylingMode={'text'}
          />
          <Popover
            wrapperAttr={{ class: 'quino-calendar-popover' }}
            visible={isPopoverVisible}
            target={'#calendar-sync-button'}
            position={{ at: 'right bottom', my: 'right top', collision: 'fit flip' }}
            onShowing={() => void loadExportUrl()}
            onHiding={() => setIsPopoverVisible(!isPopoverVisible)}
            showTitle={false}
            width={'480px'}
            maxWidth={'95vw'}>
            <Toolbar elementAttr={{ class: 'dx-popup-title' }}>
              <Item location='before' text={translationService.translate('Calendar.Popover.Action.Title')} cssClass={'dx-toolbar-label'} />
            </Toolbar>
            <div className='quino-calendar-popover-content'>
              <p className='external-calendar-sync-description'>{translationService.translate('Calendar.Popover.Action.Content')}</p>
              <TextBox
                width={'100%'}
                value={exportUrl}
                onInput={console.log}
                readOnly={true}
                onFocusIn={(e) => e.element.querySelector<HTMLInputElement>('.dx-texteditor-input')?.select()}>
                <TextBoxButton
                  name={'copy-url-button'}
                  options={{
                    icon: 'material-icons-outlined content_paste',
                    hint: translationService.translate('Calendar.Popover.Action.Copy.Hint'),
                    stylingMode: 'text',
                    disabled: false,
                    onClick: async () => {
                      navigator.clipboard
                        .writeText(exportUrl)
                        .then(() =>
                          notificationService.notify({
                            area: 'global',
                            type: 'success',
                            message: translationService.translate('Calendar.Popover.Action.Copy.Toast.Success'),
                            autoDisappear: true,
                            closeButtonIsShown: true
                          })
                        )
                        .catch((e) => {
                          notificationService.notify({
                            area: 'global',
                            type: 'error',
                            message: translationService.translate('Calendar.Popover.Action.Copy.Toast.Error'),
                            autoDisappear: true,
                            closeButtonIsShown: true
                          });
                          logger.logError(`Calendar - Failed to copy URL. Full stack-trace: ${e}`);
                        });
                    }
                  }}
                />
              </TextBox>
            </div>
          </Popover>
        </>
      )}
    </div>
  );
};
