import { injectable } from 'inversify';
import { INamespace } from './INamespace';

export const DateMathNamespaceSymbol = Symbol.for('DateMathNamespace');

@injectable()
export class DateMathNamespace implements INamespace {
  AddMinutes = (date: Date, number: number): Date => {
    date.setMinutes(date.getMinutes() + number);
    return date;
  };

  AddHours = (date: Date, number: number): Date => {
    date.setHours(date.getHours() + number);
    return date;
  };

  AddDays = (date: Date, number: number): Date => {
    date.setDate(date.getDate() + number);
    return date;
  };

  AddMonths = (date: Date, number: number): Date => {
    date.setMonth(date.getMonth() + number);
    return date;
  };

  AddYears = (date: Date, number: number): Date => {
    date.setFullYear(date.getFullYear() + number);
    return date;
  };

  name = 'DateMath';
}
