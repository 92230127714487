import { ILogger, LogLevel } from './ILogger';
import { injectable } from 'inversify';

/**
 * Default logger that writes to the console.
 */
@injectable()
export class ConsoleLogger implements ILogger {
  log = (message: string, level: LogLevel): void => {
    switch (level) {
      case LogLevel.Debug:
        this.logDebug(message);
        break;
      case LogLevel.Information:
        this.logInfo(message);
        break;
      case LogLevel.Warning:
        this.logWarn(message);
        break;
      case LogLevel.Error:
        this.logError(message);
        break;
    }
  };

  logDebug = (message: string): void => {
    console.debug(message);
  };

  logInfo = (message: string): void => {
    console.info(message);
  };

  logWarn = (message: string): void => {
    console.warn(message);
  };

  logError = (message: string | Error): void => {
    console.error(message);
  };
}
