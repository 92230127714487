import { ILoadingFeedback, ILoadingFeedbackPosition, ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
import { inject, injectable } from 'inversify';

@injectable()
export class LoadingFeedback implements ILoadingFeedback {
  constructor(@inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService) {
    this.element = document.createElement('div');
    this.element.style.width = '0px';
    this.element.style.height = '0px';
    this.element.style.position = 'absolute';
    document.body.appendChild(this.element);
  }

  loadAtPosition(loadingFeedbackOptions: ILoadingFeedbackPosition, message?: string): () => void {
    if (message == null) {
      message = this.translationService.translate('Loading');
    }

    const symbol = Symbol();
    if (Object.entries(loadingFeedbackOptions).length > 0) {
      this.customFeedback.push({ symbol, loadingFeedbackOptions, message });
    } else {
      this.mainFeedback.push({ symbol, message });
    }

    const render = () => {
      const lastMainFeedback = (this.mainFeedback.length > 0 && this.mainFeedback[this.mainFeedback.length - 1]) || undefined;
      const lastCustomFeedback = (this.customFeedback.length > 0 && this.customFeedback[this.customFeedback.length - 1]) || undefined;

      if (lastCustomFeedback == null && lastMainFeedback == null) {
        ReactDOM.unmountComponentAtNode(this.element);
      } else {
        ReactDOM.render(
          <>
            {lastMainFeedback && <LoadPanel {...this.mainFeedbackConfig} message={lastMainFeedback.message} visible={true} />}
            {lastCustomFeedback && (
              <LoadPanel
                {...this.customFeedbackConfig}
                message={lastCustomFeedback.message}
                position={lastCustomFeedback.loadingFeedbackOptions}
                visible={true}
              />
            )}
          </>,
          this.element
        );
      }
    };

    render();
    return () => {
      if (Object.entries(loadingFeedbackOptions).length > 0) {
        this.customFeedback.splice(this.customFeedback.indexOf({ symbol, loadingFeedbackOptions, message }), 1);
      } else {
        this.mainFeedback.splice(this.mainFeedback.indexOf({ symbol, message }), 1);
      }

      render();
    };
  }

  load(message?: string): () => void {
    return this.loadAtPosition({}, message);
  }

  private readonly mainFeedbackConfig = {
    key: 'LoadPanelCentral',
    shading: false
  };

  private readonly customFeedbackConfig = {
    key: 'LoadPanelCustom',
    shading: false
  };

  private readonly mainFeedback: { symbol: symbol; message: string | undefined }[] = [];
  private readonly customFeedback: {
    symbol: symbol;
    loadingFeedbackOptions: ILoadingFeedbackPosition;
    message: string | undefined;
  }[] = [];
  private readonly element: HTMLElement;
}
