import * as Bowser from 'bowser';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { IQuinoShortcut, isControlKeyShortCut, isShiftControlKeyShortCut, isSingleKeyShortCut } from './IQuinoShortcutSettings';
import { IShortcutInformation, IShortcutInformationService } from './IShortcutInformationService';
import { inject, injectable } from 'inversify';

@injectable()
export class ShortcutInformationService implements IShortcutInformationService {
  constructor(@inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService) {}

  get(): IShortcutInformation {
    const browserInformation = Bowser.getParser(window.navigator.userAgent);
    const browser = browserInformation.getBrowserName(true);
    const os = browserInformation.getOSName(true);

    // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/accesskey
    const accessKeys: string[] = [];
    if (os === 'macos') {
      accessKeys.push('Cmd');
      if (browser === 'opera') {
        accessKeys.push('Alt');
      } else {
        accessKeys.push('Option');
      }
    } else if (os === 'windows' || os === 'linux') {
      accessKeys.push('Alt');
      if (browser !== 'opera') {
        accessKeys.push('Shift');
      }
    }

    if (accessKeys.length === 0) {
      accessKeys.push('Alt');
      accessKeys.push('Shift');
    }

    const controlKey = os === 'macos' ? 'Cmd' : 'Ctrl';

    const hint = (shortcut: IQuinoShortcut) => {
      const description = this.translationService.translate(shortcut.descriptionTranslationKey);
      const key = shortcut.key.toUpperCase();
      let shortcutString = '';
      if (isShiftControlKeyShortCut(shortcut)) {
        shortcutString += controlKey + ' + ' + 'Shift' + ' + ' + key;
      } else if (isControlKeyShortCut(shortcut)) {
        shortcutString += controlKey + ' + ' + key;
      } else if (isSingleKeyShortCut(shortcut)) {
        shortcutString += key;
      }

      return `${description} (${shortcutString})`;
    };

    return {
      browserName: browserInformation.getBrowserName(),
      osName: browserInformation.getOSName(),
      accessKeys: accessKeys,
      controlKey: controlKey,
      shiftKey: 'Shift',
      hint: hint
    };
  }
}
