import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import { CUISimpleScopeSelector } from './CUISimpleScopeSelector';
import { CUIContextSelector } from '../context/CUIContextSelector';
import { QuinoLabeled, useService, TResponsiveMode, useResponsiveMode } from '@quino/ui';
import { ITranslationService, ITranslationServiceSymbol, QuinoContextContext } from '@quino/core';
import { ICUISettingsConfigurationService, ICUISettingsConfigurationServiceSymbol } from '../../configuration';

export interface ICUICombinedScopeSelectorExtension {
  component: React.ReactNode;
  title: string;
}

export interface ICUICombinedScopeSelectorProps {
  customComponents?: ICUICombinedScopeSelectorExtension[];
}

export function CUICombinedScopeSelector(props: PropsWithChildren<ICUICombinedScopeSelectorProps>) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const customComponentLength = props.customComponents ? props.customComponents.length : 0;
  const { currentContext } = useContext(QuinoContextContext);
  const responsiveMode = useResponsiveMode();
  const [currentScope, setCurrentScope] = useState<string | undefined>();
  const settingsConfigurationService = useService<ICUISettingsConfigurationService>(ICUISettingsConfigurationServiceSymbol);
  const showEntitySelector = settingsConfigurationService.getCuiSettings().entityScopeSelectorVisible;
  const showContextSelector = settingsConfigurationService.getCuiSettings().contextSelectorVisible;
  const numberOfSelectors = (showContextSelector ? 1 : 0) + (showEntitySelector ? 1 : 0) + customComponentLength;
  const [displayText, setDisplayText] = useState<string>('');

  const contextSelector = (standalone: boolean) => <CUIContextSelector standalone={standalone} />;
  const entitySelector = (standalone: boolean) => (
    <CUISimpleScopeSelector standalone={standalone} onScopeChangeCallback={(caption: string) => setCurrentScope(caption)} />
  );

  const content = (standalone: boolean) => {
    return (
      <>
        {showContextSelector &&
          ((standalone && contextSelector(standalone)) || (
            <QuinoLabeled label={translationService.translate('Context')} visible={!standalone}>
              {contextSelector(standalone)}
            </QuinoLabeled>
          ))}
        {showEntitySelector &&
          ((standalone && entitySelector(standalone)) || (
            <QuinoLabeled label={translationService.translate('Scope')} visible={!standalone}>
              {entitySelector(standalone)}
            </QuinoLabeled>
          ))}
        {props.customComponents &&
          props.customComponents.map((component, index) => {
            return (
              (standalone && component.component) || (
                <QuinoLabeled key={index} label={component.title}>
                  {component.component}
                </QuinoLabeled>
              )
            );
          })}
      </>
    );
  };

  useEffect(() => {
    const textElements = [];
    currentContext && textElements.push(currentContext.caption);
    currentScope && textElements.push(currentScope);
    setDisplayText(textElements.join(' / '));
  }, [currentScope, currentContext]);

  if (numberOfSelectors > 1) {
    return (
      <DropDownBox
        className={'quino-ecui-header-scope-selector'}
        dropDownOptions={{ minWidth: responsiveMode === TResponsiveMode.Phone ? '300px' : '480px' }}
        items={[displayText]}
        value={displayText}
        hint={
          displayText ? translationService.translate('GlobalFilterDescriptionActive') : translationService.translate('GlobalFilterDescriptionEmpty')
        }>
        {content(false)}
      </DropDownBox>
    );
  } else if (numberOfSelectors === 1) {
    return content(true);
  } else {
    return <></>;
  }
}
