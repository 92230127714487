import { INotification, TNotificationArea } from '../INotification';

export const INotificationServiceSymbol = Symbol.for('INotificationService');

export interface INotificationService {
  /**
   * Start listening to notifications for a specific area
   * @param area - listen to notifications for this area
   * @param callback - callback when notification is issued
   */
  subscribeToNotification: (area: TNotificationArea, callback: (notification: INotification[]) => void) => Symbol;

  /**
   * Stop listening to notifications
   * @param symbol - the symbol that was received on subscription
   */
  unsubscribeFromNotification: (symbol: Symbol) => void;

  /**
   * Clears a single notification
   * @param symbol symbol of the notification
   */
  clearNotification: (symbol: Symbol) => void;

  /**
   * Clears a list of notifications
   * @param symbols symbols of notifications to remove
   */
  clearNotifications: (symbols: Symbol[]) => void;

  /**
   * Clears all notifications for the specified area
   * @param area
   */
  clearNotificationArea: (area: TNotificationArea) => void;

  /**
   * Clears ALL notifications
   */
  clearAllNotifications: () => void;

  /**
   * Display a notification
   * @param notification - the notification to display
   * @param predecessor - notification to replace
   */
  notify: (notification: INotification, predecessor?: Symbol) => Symbol;
}
