import { inject, injectable } from 'inversify';
import { IQuinoDataGridFilterService } from './IQuinoDataGridFilterService';
import { formatDate } from 'devextreme/localization';
import isDate from 'lodash/isDate';
import {
  DataType,
  getElementPath,
  IFormatStringService,
  IMetaProperty,
  isValueList,
  mapToDevexpressType,
  QuinoCoreServiceSymbols
} from '@quino/core';

@injectable()
export class QuinoDataGridFilterService implements IQuinoDataGridFilterService {
  constructor(@inject(QuinoCoreServiceSymbols.IFormatStringService) private readonly dateFormatter: IFormatStringService) {}

  calculateFilterExpression = (
    filterValue: any,
    selectedFilterOperation: string,
    target: 'filterRow' | 'headerFilter' | 'filterBuilder' | 'search',
    metaProperty: IMetaProperty,
    column: any
  ) => {
    if (target === 'search') {
      const format = this.dateFormatter.get(metaProperty);
      const formatToComparisonDate = (dateISOString: string | Date) => formatDate(new Date(dateISOString), format!);

      const newFilterValue = isDate(filterValue) ? formatToComparisonDate(filterValue) : filterValue;

      return [
        [(data: any) => data[getElementPath(metaProperty)], 'contains', filterValue],
        'or',
        [(data: any) => formatToComparisonDate(data[getElementPath(metaProperty)]), 'contains', newFilterValue]
      ];
    }

    if (target === 'headerFilter' && [DataType.Relation].includes(metaProperty.dataType)) {
      return [[(data: any) => data[getElementPath(metaProperty)], selectedFilterOperation, filterValue]];
    }

    if (target === 'filterRow') {
      if (filterValue === '--') {
        return [[(data: any) => data[getElementPath(metaProperty)], '=', null]];
      }
    }

    return column.defaultCalculateFilterExpression.apply(column, [filterValue, selectedFilterOperation, target]);
  };

  getColumnFilterOptions(metaProperty: IMetaProperty): string[] {
    const devexpressType = mapToDevexpressType(metaProperty.dataType);
    const filterOptions: string[] = [];

    if (isValueList(metaProperty)) {
      return ['anyof'];
    }

    // Special treatment for keys since they are mapped to a string within the mapToDevexpressType method.
    if (metaProperty.dataType === DataType.Key) {
      filterOptions.push('=', '<', '>', '<=', '>=', 'between');
      return filterOptions;
    }
    // Special treatment for the Guid data type since it doesnt need string filter options.
    if (metaProperty.dataType === DataType.Guid) {
      filterOptions.push('=');
      return filterOptions;
    }
    switch (devexpressType) {
      case 'date':
      case 'datetime':
        filterOptions.push('=', '<', '>', '<=', '>=', 'between');
        break;
      case 'boolean':
        filterOptions.push('=');
        break;
      case 'number':
        filterOptions.push('=', '<', '>', 'between');
        break;
      case 'string':
      default:
        filterOptions.push('contains', 'startswith', 'endswith', '=');
        break;
    }
    return filterOptions;
  }
}
