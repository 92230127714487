import { IDashboardTileRegistration } from './IDashboardTileRegistration';

/**
 * The symbol for the dashboard tile registry.
 */
export const IDashboardTileRegistrySymbol = Symbol.for('IDashboardTileRegistry');

/**
 * Registry for available dashboard tiles.
 */
export interface IDashboardTileRegistry {
  /**
   * Register a new tile type.
   * @param symbol - the symbol which needs to be unique.
   * @param registration - the type to register.
   */
  register<TPayload>(symbol: symbol, registration: IDashboardTileRegistration<TPayload>): void;

  /**
   * Get all instances currently registered.
   */
  getInstances(): IDashboardTileRegistration<any>[];
}
