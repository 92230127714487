import { IQuinoExcelRowBuilder } from './IQuinoExcelRowBuilder';
import { IQuinoExcelCell, IQuinoExcelRow, QuinoExcelRow } from '../model';
import { injectable } from 'inversify';
import { IExportExcelSettings } from '../settings';

@injectable()
export class QuinoExcelRowBuilder implements IQuinoExcelRowBuilder {
  build(): IQuinoExcelRow {
    return new QuinoExcelRow(this.cells, this.rowMarginTop);
  }

  withCells(cells: IQuinoExcelCell[]): IQuinoExcelRowBuilder {
    this.cells = cells;
    return this;
  }

  withMarginTop(marginTop: number): IQuinoExcelRowBuilder {
    this.rowMarginTop = marginTop;
    return this;
  }

  withDefaultSettings(defaultSettings: IExportExcelSettings): IQuinoExcelRowBuilder {
    this.rowMarginTop = defaultSettings.rowMarginTop;
    return this;
  }

  clear(defaultSettings: IExportExcelSettings): IQuinoExcelRowBuilder {
    this.cells = [];
    return this.withDefaultSettings(defaultSettings);
  }

  private cells: IQuinoExcelCell[] = [];
  private rowMarginTop: number;
}
