import React from 'react';
import { Button } from 'devextreme-react/button';

export interface ICUINavBarFooterProps {
  navBarExpanded: boolean;
  navBarExpandedCallback: (expanded: boolean) => void;
}

export const CUINavBarFooter = (props: ICUINavBarFooterProps) => {
  return (
    <div className={`quino-ecui-nav-bar-footer ${props.navBarExpanded ? 'is-expanded' : ''}`}>
      <div className='quino-ecui-nav-bar-toggle-button'>
        <Button
          stylingMode={'text'}
          icon={props.navBarExpanded ? 'chevrondoubleleft' : 'chevrondoubleright'}
          onClick={() => props.navBarExpandedCallback(!props.navBarExpanded)}
        />
      </div>
    </div>
  );
};
