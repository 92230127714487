import { inject, injectable } from 'inversify';
import { IAuthenticationService, ILogger, IRequestBuilder, IUrlHelper, IUrlManager, QuinoCoreServiceSymbols } from '@quino/core';
import { CalendarRequestObject } from './CalendarRequestObject';
import { ICalendarDataService, TCalendarAppointment } from './ICalendarDataService';

@injectable()
export class CalendarDataService implements ICalendarDataService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IAuthenticationService) private readonly authenticationService: IAuthenticationService,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder
  ) {}

  getRequestObject(calendarName: string, from: Date, to: Date): CalendarRequestObject {
    return new CalendarRequestObject(async () => this.fetchAppointments(calendarName, from, to));
  }

  async fetchAppointments(calendarName: string, from: Date, to: Date): Promise<Array<TCalendarAppointment>> {
    const url = this.urlManager.getCalendarUrl(calendarName, from, to);
    const result = await this.requestBuilder.create(url, 'get').requiresAuthentication().fetchJson<Array<TCalendarAppointment>>();

    const parsedEntries: Array<TCalendarAppointment> = Array.isArray(result)
      ? result.map((entry: any) => {
          return { ...entry, end: entry.end !== null ? entry.end : entry.start } as TCalendarAppointment;
        })
      : [];

    return Promise.resolve(parsedEntries);
  }
}
