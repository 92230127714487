import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for the sort order aspect
 */
export const SortOrderAspectIdentifier = 'SortOrder';

/**
 * The sort order aspect is used to define the position of an object among its siblings
 */
export interface ISortOrderAspect extends IMetaAspect {
  sortOrderProperty: string;
  siblingsRelation?: string;
}
