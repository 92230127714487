import { inject, injectable } from 'inversify';
import { IWizardService, IWizardStep, WizardInput as IWizardInput } from './IWizardService';
import * as React from 'react';
import { Fragment, ReactElement, useCallback, useMemo, useState } from 'react';
import {
  QuinoInfoBar,
  QuinoLabeled,
  QuinoPopup,
  QuinoPopupDefaultContent,
  QuinoPopupToolbar,
  QuinoPopupToolbarButton,
  useOnMount
} from '../components';
import {
  IApplication,
  INotificationService,
  INotificationServiceSymbol,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { TextBox } from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';
import { FileUploader } from 'devextreme-react/file-uploader';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { ScrollView } from 'devextreme-react/scroll-view';
import { SelectBox } from 'devextreme-react/select-box';
import ReactDOM from 'react-dom';
import { ContainerContext, useService } from '../ioc';
import { IQuinoShortcutSettings, IQuinoShortcutSettingsSymbol, useShortcutHandler } from '../shortcuts';
import { getResponsiveClassName, useResponsiveMode } from '../responsivity';

@injectable()
export class WizardService implements IWizardService {
  constructor(@inject(QuinoCoreServiceSymbols.IApplication) private readonly container: IApplication) {
    this.element = document.createElement('div');
    this.element.style.width = '0px';
    this.element.style.height = '0px';
    this.element.style.position = 'absolute';
    document.body.appendChild(this.element);
  }

  async showWizard(
    title: string,
    steps: IWizardStep[],
    action: (data: object) => Promise<JSX.Element>,
    doneText?: string,
    doneIcon?: string,
    width?: string | number,
    height?: string | number
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.mountComponent(
        <Wizard
          width={width}
          height={height}
          doneText={doneText}
          doneIcon={doneIcon}
          steps={steps}
          title={title}
          cancelAction={() => {
            reject();
            this.unMountComponent();
          }}
          doneAction={() => {
            resolve();
            this.unMountComponent();
          }}
          action={action}
        />
      );
    });
  }

  async showSimpleWizard(title: string, inputs: IWizardInput[], doneText?: string, doneIcon?: string): Promise<object> {
    return new Promise<object>((resolve, reject) => {
      this.mountComponent(
        <SimpleWizard
          doneText={doneText}
          doneIcon={doneIcon}
          inputs={inputs}
          title={title}
          cancelAction={() => {
            reject();
            this.unMountComponent();
          }}
          doneAction={(data) => {
            resolve(data);
            this.unMountComponent();
          }}
        />
      );
    });
  }

  protected mountComponent(component: ReactElement): void {
    if (!this.isMounted) {
      ReactDOM.render(<ContainerContext.Provider value={{ container: this.container }}>{component}</ContainerContext.Provider>, this.element);
      this.isMounted = true;
    }
  }

  protected unMountComponent(): void {
    ReactDOM.unmountComponentAtNode(this.element);
    this.isMounted = false;
  }

  protected readonly element: HTMLElement;
  protected isMounted: boolean;
}

const SimpleWizard = (props: {
  title: string;
  doneText?: string;
  doneIcon?: string;
  cancelText?: string;
  cancelIcon?: string;
  inputs: IWizardInput[];
  cancelAction: () => void;
  doneAction: (data: object) => void;
}) => {
  const { inputs, doneAction, cancelAction, title } = props;
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);

  const cancelText = props.cancelText ? props.cancelText : translationService.translate('Cancel');
  const cancelIcon = props.cancelIcon ? props.cancelIcon : 'material-icons-outlined clear';
  const doneText = props.doneText ? props.doneText : translationService.translate('Done');
  const doneIcon = props.doneIcon ? props.doneIcon : 'material-icons-outlined save';

  const [data, setData] = useState({});

  useShortcutHandler([shortcutSettings.cancel], () => cancelAction());

  const setInputValue = (value: any, input: IWizardInput) =>
    setData((d) => {
      d[input.name] = value;
      return Object.assign({}, d);
    });

  return (
    <QuinoPopup visible={true} title={title}>
      <QuinoPopupDefaultContent hasPaddingHorizontal={true}>
        {inputs.map((input, i) => (
          <WizardInput input={input} setValue={setInputValue} value={data[input.name]} key={i} />
        ))}
      </QuinoPopupDefaultContent>
      <QuinoPopupToolbar
        rightItems={[
          <QuinoPopupToolbarButton text={cancelText} icon={cancelIcon} onClick={cancelAction} />,
          <QuinoPopupToolbarButton isPrimary={true} text={doneText} icon={doneIcon} onClick={() => doneAction(data)} />
        ]}
      />
    </QuinoPopup>
  );
};

const Wizard = (props: {
  steps: IWizardStep[];
  title: string;
  cancelAction: () => void;
  doneAction: () => void;
  cancelText?: string;
  cancelIcon?: string;
  backText?: string;
  backIcon?: string;
  nextText?: string;
  nextIcon?: string;
  doneText?: string;
  doneIcon?: string;
  closeText?: string;
  closeIcon?: string;
  width?: string | number;
  height?: string | number;
  action: (data: object) => Promise<JSX.Element>;
}) => {
  const { steps, action, cancelAction, doneAction, title, width, height } = props;
  const [stepIndex, setStepIndex] = useState(0);
  const [result, setResult] = useState(null as JSX.Element | null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const isLast = useCallback(() => stepIndex === steps.length, [stepIndex, steps.length]);
  const isSecondLast = () => stepIndex === steps.length - 1;
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);
  const responsiveMode = useResponsiveMode();

  const cancelText = props.cancelText ? props.cancelText : translationService.translate('Cancel');
  const cancelIcon = props.cancelIcon ? props.cancelIcon : 'material-icons-outlined clear';
  const backText = props.backText ? props.backText : translationService.translate('Back');
  const backIcon = props.backIcon ? props.backIcon : 'material-icons-outlined chevron_left';
  const nextText = props.nextText ? props.nextText : translationService.translate('Next');
  const nextIcon = props.nextIcon ? props.nextIcon : 'material-icons-outlined chevron_right';
  const doneText = props.doneText ? props.doneText : translationService.translate('Done');
  const doneIcon = props.doneIcon ? props.doneIcon : 'material-icons-outlined save';
  const closeText = props.closeText ? props.closeText : translationService.translate('Close');
  const closeIcon = props.closeIcon ? props.closeIcon : 'material-icons-outlined clear';
  const { notify, clearNotification } = useService<INotificationService>(INotificationServiceSymbol);

  useShortcutHandler([shortcutSettings.cancel], () => cancelAction());

  const stepItem = (step: IWizardStep, i: number) => {
    const state = stepIndex === i ? ' active' : '';
    return (
      <Fragment key={i}>
        <div className={'quino-wizard-step' + state}>
          <div className={'quino-wizard-step-icon' + state}>
            {i < stepIndex || (isLast() && canContinue) ? <i className={'dx-icon-todo'} /> : i + 1}
          </div>
          <span className={'text'}>{step.title}</span>
        </div>
        {i === steps.length ? null : (
          <div className={'quino-wizard-separator'}>
            <div />
          </div>
        )}
      </Fragment>
    );
  };

  const canContinue = useMemo(() => {
    if (isLast()) {
      return result != null;
    }

    let allSet = true;
    for (const input of steps[stepIndex].inputs) {
      if (!Object.prototype.hasOwnProperty.call(data, input.name)) {
        allSet = false;
      }
    }
    return allSet;
  }, [isLast, result, steps, stepIndex, data]);

  const next = () => {
    if (canContinue) {
      setStepIndex((s) => s + 1);
    }
  };

  const back = () => {
    if (stepIndex > 0) {
      setStepIndex((s) => s - 1);
      if (result != null) {
        setResult(null);
      }
    }
  };

  const executeAction = () => {
    next();
    setIsLoading(true);

    action(data)
      .then((el) => {
        setResult(el);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const warningTruncateSymbol = Symbol.for('WarningTruncateSymbol');
  const setInputValue = (value: any, input: IWizardInput) =>
    setData((d) => {
      d[input.name] = value;
      if (value === 'Truncate') {
        notify(
          {
            closeButtonIsShown: false,
            message: translationService.translate('QuinoDataGrid.XmlImport.ConflictBehaviour.Truncate.Warning'),
            type: 'warning',
            area: 'custom',
            customAreaID: 'import-truncate-warning'
          },
          warningTruncateSymbol
        );
        return Object.assign({}, d);
      } else {
        clearNotification(warningTruncateSymbol);
        return Object.assign({}, d);
      }
    });

  const resultStep = { title: translationService.translate('Result'), inputs: [] } as IWizardStep;

  const toolbar = (
    <QuinoPopupToolbar
      leftItems={isLast() ? [] : [<QuinoPopupToolbarButton text={cancelText} icon={cancelIcon} onClick={cancelAction} />]}
      rightItems={[
        <QuinoPopupToolbarButton text={backText} icon={backIcon} disabled={stepIndex === 0 || isLoading} onClick={back} />,
        isSecondLast() ? (
          <QuinoPopupToolbarButton isPrimary={true} text={doneText} icon={doneIcon} onClick={executeAction} disabled={!canContinue} />
        ) : isLast() ? (
          <QuinoPopupToolbarButton text={closeText} isPrimary={true} icon={closeIcon} disabled={!canContinue} onClick={doneAction} />
        ) : (
          <QuinoPopupToolbarButton isPrimary={true} text={nextText} icon={nextIcon} onClick={next} disabled={!canContinue} />
        )
      ]}
    />
  );

  return (
    <QuinoPopup visible={true} title={title} width={width || 640} height={height || 480}>
      <div className={getResponsiveClassName('quino-wizard', responsiveMode)}>
        <div className={getResponsiveClassName('quino-wizard-steps', responsiveMode)}>{[...steps, resultStep].map(stepItem)}</div>
        <div className={'quino-wizard-content'}>
          <ScrollView direction={'vertical'} showScrollbar={'onScroll'} useNative={false} height={'auto'} className={'quino-wizard-scrollview'}>
            <div className={'quino-wizard-inputs'}>
              {isLast() ? (
                result ? (
                  result
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <LoadIndicator visible={true} />
                  </div>
                )
              ) : (
                steps[stepIndex].inputs.map((input, i) => (
                  <WizardInput input={input} setValue={setInputValue} value={data[input.name]} key={`${stepIndex}-${i}`} />
                ))
              )}
            </div>
          </ScrollView>
          <div className={'quino-popup-notification-wrapper'}>
            <QuinoInfoBar isDefault={false} customAreaID={'import-truncate-warning'} />
          </div>
          <div className={getResponsiveClassName('quino-wizard-toolbar', responsiveMode)}>{toolbar}</div>
        </div>
      </div>
    </QuinoPopup>
  );
};

const WizardInput = (props: { input: IWizardInput; setValue: (data: any, input: IWizardInput) => void; value: any }) => {
  const { input, value } = props;
  const setValue = (e: any) => {
    props.setValue(e.value, input);
  };

  useOnMount(() => {
    if (input.default != null && value == null) {
      props.setValue(input.default, input);
    }
  });

  const fetchInput = () => {
    switch (input.type) {
      case 'text':
        return <TextBox value={value} onValueChanged={setValue} />;
      case 'file':
        return (
          <FileUploader
            uploadFile={(file, updateProgress) => {
              props.setValue(file, input);
              updateProgress(100);
            }}
          />
        );
      case 'select':
        return (
          <SelectBox
            items={input.options}
            value={value}
            displayExpr={(i) => i?.text || i?.value}
            valueExpr={'value'}
            itemRender={(i) => <div title={i?.hint || i?.text || i?.value}>{i?.text || i?.value}</div>}
            onValueChanged={(e) => props.setValue(e.value, input)}
          />
        );
      case 'checkbox':
        return <CheckBox value={value} onValueChanged={setValue} />;
    }
  };

  return (
    <div className={'quino-wizard-input input-' + input.type}>
      <QuinoLabeled label={input.text} instruction={input.instruction} description={input.description}>
        {fetchInput()}
      </QuinoLabeled>
    </div>
  );
};
