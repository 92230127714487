import { IDashboardConfiguration } from './IDashboardConfiguration';

export const IDashboardConfigurationServiceSymbol = Symbol.for('IDashboardConfigurationService');

export interface IDashboardConfigurationService {
  /**
   * Gets the dashboard configuration
   */
  getDashboardConfiguration(): Promise<IDashboardConfiguration>;
}
