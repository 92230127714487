import { ITranslationService } from './ITranslationService';
import { injectable } from 'inversify';
import { ITranslations } from './ITranslations';

@injectable()
export class TranslationService implements ITranslationService {
  translate(key: string, interpolation?: { [key: string]: string }): string {
    let result = this.translations[key.toUpperCase()];

    if (result != null && interpolation) {
      Object.keys(interpolation).forEach((value) => {
        result = result.replace(new RegExp(`{{${value}}}`, 'ig'), interpolation[value]);
      });
    }

    return result ? result : key;
  }

  exists(key: string): boolean {
    return this.translate(key) !== key;
  }

  load(translations: ITranslations): void {
    Object.keys(translations).forEach((value) => {
      this.translations[value.toUpperCase()] = translations[value];
    });
  }

  private translations: ITranslations = {};
}
