import { injectable } from 'inversify';
import { ITokenRequestService } from './ITokenRequestService';

@injectable()
export class NullTokenRequestService implements ITokenRequestService {
  async confirmEmailAsync(email: string, token: string): Promise<void> {
    return Promise.resolve();
  }

  async requestActivationEmailAsync(userOrEmail: string): Promise<void> {
    return Promise.resolve();
  }

  async requestResetTokenAsync(userOrEmail: string): Promise<void> {
    return Promise.resolve();
  }
}
