import { IApplication, InitializationGroup } from '../application';
import { IDataDiffer } from '../data';
import { QuinoCoreServiceSymbols } from '../ioc';
import { DataDiffer } from '../data/DataDiffer';
import { IValidationMessageProvider } from './IValidationMessageProvider';
import { ValidationMessageProvider } from './ValidationMessageProvider';
import { IValidator } from './IValidator';
import { Validator } from './Validator';
import { IFieldValidatorProvider } from './IFieldValidatorProvider';
import { FieldValidatorProvider } from './FieldValidatorProvider';
import {
  BoundsRuleValidator,
  DataTypeValidator,
  MaxLengthValidator,
  OptionValuesValidator,
  RegExRuleValidator,
  RequiredRuleValidator
} from './fieldValidators';
import { IExpressionEvaluator } from '../expressions';
import { ControlModifiedRegistry, ControlValidationProvider, IControlBehaviorRegistry } from './controlValidator';
import { IFieldValidationResult } from './IFieldValidationResult';

/**
 * Identifier for the validation startup action.
 */
export const ValidationStartupActionIdentifier = Symbol.for('quino_validation_startup_action');

/**
 * Module for handling validation of elements.
 */
export class ValidationModule {
  public static use(application: IApplication) {
    application.registerSingle<IDataDiffer>(QuinoCoreServiceSymbols.IDataDiffer, DataDiffer);
    application.registerSingle<IValidationMessageProvider>(QuinoCoreServiceSymbols.IValidationMessageProvider, ValidationMessageProvider);
    application.bind<IValidator>(QuinoCoreServiceSymbols.IValidator).to(Validator);
    application.registerSingle<IFieldValidatorProvider>(QuinoCoreServiceSymbols.IFieldValidatorProvider, FieldValidatorProvider);
    application.registerSingle<IControlBehaviorRegistry<IFieldValidationResult>>(
      QuinoCoreServiceSymbols.ControlValidatorRegistry,
      ControlValidationProvider
    );
    application.registerSingle<IControlBehaviorRegistry<boolean>>(QuinoCoreServiceSymbols.ControlModifiedRegistry, ControlModifiedRegistry);
    application
      .registerStartupAction(ValidationStartupActionIdentifier, (app) => {
        const validatorProvider = app.get<IFieldValidatorProvider>(QuinoCoreServiceSymbols.IFieldValidatorProvider);
        const validationMessageProvider = app.get<IValidationMessageProvider>(QuinoCoreServiceSymbols.IValidationMessageProvider);
        const expressionEvaluator = app.get<IExpressionEvaluator>(QuinoCoreServiceSymbols.IExpressionEvaluator);

        validatorProvider.register(new DataTypeValidator(validationMessageProvider), Symbol.for('DataTypeValidator'));
        validatorProvider.register(new RequiredRuleValidator(validationMessageProvider, expressionEvaluator), Symbol.for('RequiredRuleValidator'));
        validatorProvider.register(new BoundsRuleValidator(validationMessageProvider), Symbol.for('BoundsRuleValidator'));
        validatorProvider.register(new RegExRuleValidator(validationMessageProvider), Symbol.for('RegExRuleValidator'));
        validatorProvider.register(new MaxLengthValidator(), Symbol.for('MaxLengthValidator'));
        validatorProvider.register(new OptionValuesValidator(validationMessageProvider), Symbol.for('OptionValuesValidator'));
      })
      .moveTo(InitializationGroup);
  }
}
