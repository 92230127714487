import {
  IFieldValidationResult,
  IFieldValidator,
  IGenericObject,
  IMetaProperty,
  ITranslationService,
  ITranslationServiceSymbol,
  IValidationContext
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { CONTROL_IDENTIFIER } from '../../rendering';
import { PasswordRepetitionFieldExtensionString } from './QuinoPasswordRepetition';

export const PasswordRepetitionValidatorSymbol = Symbol.for('PasswordRepetitionValidator');

@injectable()
export class PasswordRepetitionValidator implements IFieldValidator {
  constructor(@inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService) {}

  validate(field: IMetaProperty, value: any, context: IValidationContext): IFieldValidationResult {
    const isPasswordRepetitionField = field.name.includes(PasswordRepetitionFieldExtensionString);
    if (field.controlName !== CONTROL_IDENTIFIER.PASSWORD_REPETITION) {
      return {};
    }

    let password = value;
    const repetitionPasswordFieldName = field.name + PasswordRepetitionFieldExtensionString;
    let repetitionPassword = (context.data as IGenericObject)[repetitionPasswordFieldName];
    if (isPasswordRepetitionField) {
      repetitionPassword = value;
      const passwordFieldName = field.name.replace(PasswordRepetitionFieldExtensionString, '');
      password = (context.data as IGenericObject)[passwordFieldName];
    }

    if (!password || password.length < 1 || repetitionPassword === password) {
      return {};
    }

    return {
      fieldErrors: [
        {
          fieldName: isPasswordRepetitionField ? field.name : repetitionPasswordFieldName, // support both: general validation and single validation
          errorCode: 'VAL-27',
          errorMessage: this.translationService.translate('Validations.PasswordsDoNotMatch')
        }
      ]
    };
  }
}
