import { IListBookmark } from '../bookmarks';
import { IPagingContextService } from './IPagingContextService';
import { injectable } from 'inversify';

@injectable()
export class PagingContextService implements IPagingContextService {
  bookmark: IListBookmark | undefined = undefined;

  get(): IListBookmark | undefined {
    return this.bookmark;
  }

  reset(): void {
    this.bookmark = undefined;
  }

  set(listBookmark: IListBookmark): void {
    this.bookmark = listBookmark;
  }
}
