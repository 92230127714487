import React, { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { useService } from '../../ioc/hook';
import { IComponentFactory } from '../ComponentFactory';
import {
  getAspect,
  getAspectOrDefault,
  ILogger,
  IResponsiveBoxAspect,
  IResponsiveLocationAspect,
  isIMetaGroup,
  QuinoCoreServiceSymbols,
  ResponsiveBoxAspectIdentifier,
  ResponsiveLocationAspectIdentifier
} from '@quino/core';
import { QuinoUIServiceSymbols } from '../../ioc';
import { ObjectBookmark } from '../../bookmarks';
import ResponsiveBox, { Col, Item, Location, Row } from 'devextreme-react/responsive-box';
import { useMetadata } from '../../settings';
import { useOnDependencyChanged } from '../Util/MetaElementHooks';

export function QuinoResponsiveBox(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const factory = useService<IComponentFactory>(QuinoUIServiceSymbols.IComponentFactory);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const { visible } = useMetadata(props.element, props.bookmark.genericObject);
  useOnDependencyChanged(props.element, props.bookmark);
  if (!visible) {
    return <></>;
  }

  if (isIMetaGroup(props.element)) {
    const aspect = getAspect<IResponsiveBoxAspect>(props.element, ResponsiveBoxAspectIdentifier);
    const rows = aspect.rows.map((value, index) => <Row key={`row${index}`} ratio={value.ratio} baseSize={value.baseSize} shrink={value.shrink} />);
    const columns = aspect.columns.map((value, index) => (
      <Col key={`col${index}`} ratio={value.ratio} baseSize={value.baseSize} shrink={value.shrink} />
    ));

    const items = props.element.elements.map((element) => {
      const mapped = factory.create(element, props.bookmark, props.actions);
      const aspect = getAspectOrDefault<IResponsiveLocationAspect>(element, ResponsiveLocationAspectIdentifier);

      if (aspect != null) {
        return (
          <Item key={`${element.name}`}>
            <Location key={`location${element.name}`} col={aspect.column} row={aspect.row} colspan={aspect.columnSpan} rowspan={aspect.rowSpan} />
            <div
              className={
                columns.length < 2
                  ? 'quino-responsive-box-single-column'
                  : aspect.column === 0
                  ? aspect.columnSpan === columns.length
                    ? 'quino-responsive-box-single-column'
                    : 'quino-responsive-box-first-column'
                  : aspect.column < columns.length - 1
                  ? aspect.column + aspect.columnSpan === columns.length
                    ? 'quino-responsive-box-last-column'
                    : 'quino-responsive-box-first-column'
                  : 'quino-responsive-box-last-column'
              }>
              {mapped}
            </div>
          </Item>
        );
      } else {
        return <Item key={`${element.name}`}>{mapped}</Item>;
      }
    });

    return (
      <ResponsiveBox key={props.element.name + '_responsive_box'} singleColumnScreen={'xs'}>
        {rows}
        {columns}
        {items}
      </ResponsiveBox>
    );
  }

  logger.logError(`Trying to render a group with a non-group element. Rendering fragment for: [${props.element}]`);

  return <></>;
}
