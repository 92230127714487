import React from 'react';
import { IQuinoContextContext } from './IQuinoContextContext';
import noop from 'lodash/noop';

const defaultQuinoContextContext: IQuinoContextContext = {
  isLoading: false,
  setIsLoading: noop,
  currentContext: undefined,
  setCurrentContext: noop,
  availableContexts: [],
  setAvailableContexts: noop
};

export const QuinoContextContext = React.createContext<IQuinoContextContext>(defaultQuinoContextContext);
