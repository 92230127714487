import { ISession, ISessionEventTarget, ISessionState } from './index';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';

@injectable()
export class Session implements ISession {
  constructor(@inject(QuinoCoreServiceSymbols.ISessionEventTarget) private readonly eventTarget: ISessionEventTarget) {}

  exportSession(): ISessionState {
    return this.sessionState;
  }

  updateSession(sessionState: Partial<ISessionState>) {
    const oldState = this.sessionState;
    this.sessionState = {
      ...this.sessionState,
      ...sessionState
    };

    this.eventTarget.dispatch(oldState, this.sessionState);
  }

  getLocale = () => this.sessionState.locale;

  setFallbackLocale(fallbackLocale: string): void {
    this.updateSession({ locale: this.getLocale() || fallbackLocale });
  }

  private sessionState: ISessionState = {};
}
