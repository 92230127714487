import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression, isExpression } from './IExpression';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { isInnerExpression } from './IInnerExpression';

export const InnerExpressionEvaluatorSymbol = Symbol.for('InnerExpressionEvaluator');

@injectable()
export class InnerExpressionEvaluator implements IExpressionEvaluator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator) {}

  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (isExpression(expression) && isInnerExpression(expression)) {
      return this.evaluator.evaluate<TValue>(expression.InnerExpression, context);
    }

    // @ts-ignore
    return expression;
  }
}
