import { IODataBatchRequest } from '../api';
import { IODataBatchResponse } from '../api/QuinoServer/IODataBatchRequestFactory';

export const IBatchedRequestServiceSymbol = Symbol.for('IBatchedRequestService');

/**
 * A service which will perform multiple request in one batch
 */
export interface IBatchedRequestService {
  /**
   * Enqueues a OData batch requests which will be executed in a batch request.
   * @param batchRequest - an array of single request to be batched in one request.
   */
  enqueue(batchRequest: IODataBatchRequest): Promise<IODataBatchResponse>;
}
