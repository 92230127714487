import { IExpression } from './IExpression';

export interface INowExpression extends IExpression {
  name: string;
  Count: Number;
}

export const isNowExpression = (object: IExpression): object is INowExpression => {
  return (object as INowExpression).name === 'Now' && (object as INowExpression).Count === 0;
};
