import { IApplication } from '../application';
import {
  defaultNotificationConfiguration,
  INotificationConfiguration,
  INotificationConfigurationService,
  INotificationConfigurationServiceSymbol,
  INotificationConfigurationSymbol,
  NotificationConfigurationService
} from './configuration';
import { INotificationService, INotificationServiceSymbol, NotificationService } from './service';

export class NotificationModule {
  static use = (application: IApplication): IApplication => {
    application.registerConstant<INotificationConfiguration>(INotificationConfigurationSymbol, defaultNotificationConfiguration);
    application.registerSingle<INotificationConfigurationService>(INotificationConfigurationServiceSymbol, NotificationConfigurationService);
    application.registerSingle<INotificationService>(INotificationServiceSymbol, NotificationService);

    return application;
  };
}
