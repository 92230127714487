import { IThemeCustomization, IThemeCustomizationService } from './IThemeCustomizationService';
import { inject, injectable } from 'inversify';
import { ILogger, IRequestBuilder, LogLevel, QuinoCoreServiceSymbols } from '@quino/core';
import { ICUISettingsConfigurationService, ICUISettingsConfigurationServiceSymbol } from './ICUISettingsConfigurationService';

@injectable()
export class ThemeCustomizationService implements IThemeCustomizationService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder,
    @inject(ICUISettingsConfigurationServiceSymbol) private readonly cuiSettings: ICUISettingsConfigurationService,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger
  ) {}

  getCustomization(): IThemeCustomization {
    return this.customization;
  }

  updateColorVariables(): void {
    this.customization.items.forEach((item) => {
      const parsedKey = item.key[0] === '$' ? item.key.slice(1) : item.key;
      document.body.style.setProperty('--' + parsedKey, item.value);
    });
  }

  async changeCssPath(): Promise<void> {
    const cssReference = document.getElementById('mainStyle');
    if (cssReference) {
      let cssPath = 'assets/dx.generic.sandbox.css';
      const customCssPath = this.cuiSettings.getCuiSettings().customizationCssFilePath;
      if (customCssPath && customCssPath.endsWith('.css')) {
        const request = this.requestBuilder.create('/' + customCssPath, 'get');
        const response = await request.fetch();
        if (response.ok) {
          cssPath = customCssPath;
        }
      }
      cssReference.setAttribute('href', '/' + cssPath);
    } else {
      this.logger.log('Could not change the css path, the element with the id [mainStyle] is missing.', LogLevel.Warning);
    }
  }

  async initialize(): Promise<void> {
    const request = this.requestBuilder.create('/' + this.cuiSettings.getCuiSettings().customizationJsonFilePath, 'get');
    try {
      const response = await request.fetchJson<IThemeCustomization>();
      this.customization.items = response.items;
    } catch (error) {
      this.logger.log('Could not change the theme because the file specified was not found.', LogLevel.Warning);
    }
  }

  protected customization: IThemeCustomization = { items: [] };
}
