/**
 * Markdown converter service symbol.
 */
export const IMarkdownConverterSymbol = Symbol.for('IMarkdownConverter');

export interface IMarkdownConverter {
  /**
   * Converts markdown of a given string to html
   * @text a string containing markdown
   */
  convertToHtml(text: string): string;
  /**
   * Returns if a string contains markdown. True if prefix contains [markdown], false otherwise
   * @text a string which possibly contains markdown
   */
  isMarkdown(text: string): boolean;
}
