import { ISizingAspect } from '../meta';

/**
 * Utility function to extract height/width from an sizing aspect
 */
export const extractSize = (aspect: ISizingAspect | null, axis: 'horizontal' | 'vertical'): string | undefined => {
  if (!aspect || aspect[`${axis}SizeMode`] == null) return undefined;
  if (aspect[`${axis}SizeMode`] === 'AutoSize') return 'auto';
  return aspect[`${axis}Units`].toString() + (aspect[`${axis}SizeMode`] === 'Absolute' ? 'px' : '%');
};
