import { inject, injectable } from 'inversify';
import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression, isExpression } from './IExpression';
import { isUnaryExpression } from './IUnaryExpression';
import { ExpressionOperator } from './ExpressionOperator';
import { QuinoCoreServiceSymbols } from '../ioc';

export const UnaryExpressionEvaluatorSymbol = Symbol.for('UnaryExpressionEvaluator');

@injectable()
export class UnaryExpressionEvaluator implements IExpressionEvaluator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator) {}

  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (isExpression(expression) && isUnaryExpression(expression)) {
      const parameterValue = this.evaluator.evaluate(expression.Parameter, context);
      switch (expression.Operator) {
        case ExpressionOperator.Negate:
          // @ts-ignore
          return parameterValue * -1;
        case ExpressionOperator.Not:
          // @ts-ignore
          return !parameterValue;
        case ExpressionOperator.IsNull:
          // @ts-ignore
          return parameterValue == null;
        case ExpressionOperator.IsNotNull:
          // @ts-ignore
          return parameterValue != null;
        default:
          throw new Error('Unary operator does not support this type of operation.');
      }
    }

    // @ts-ignore
    return expression;
  }
}
