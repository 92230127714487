import { QuinoCoreServiceSymbols } from '../ioc';
import { IAuthenticationService } from './IAuthenticationService';
import { IRequestDecoratorProvider } from '../request';
import { IAuthenticationFeedback } from './IAuthenticationFeedback';
import { NullAuthenticationFeedback } from './NullAuthenticationFeedback';
import { IIdentityService } from './IIdentityService';
import { NullIdentityService } from './NullIdentityService';
import { FrameworkStartupGroup, InitializationGroup } from '../application/ExecutionGroup';
import { IApplication } from '../application';
import { URL, URLSearchParams } from '@cliqz/url-parser';
import { DefaultAuthenticationService } from './DefaultAuthenticationService';
import { ITokenRequestService } from './ITokenRequestService';
import { TokenRequestService } from './TokenRequestService';
// import { IFeedbackService, IFeedbackServiceSymbol } from '@quino/ui';

export const AuthenticationStartupActionIdentifier = Symbol.for('quino_authentication_startup_action');

export class AuthenticationModule {
  static use = (application: IApplication): void => {
    application.registerSingle<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService, DefaultAuthenticationService);
    application.registerSingle<IAuthenticationFeedback>(QuinoCoreServiceSymbols.IAuthenticationFeedback, NullAuthenticationFeedback);
    application.registerSingle<ITokenRequestService>(QuinoCoreServiceSymbols.ITokenRequestService, TokenRequestService);
    application.registerSingle<IIdentityService>(QuinoCoreServiceSymbols.IIdentityService, NullIdentityService);
    application.registerConstant<Storage>(QuinoCoreServiceSymbols.IStorage, localStorage);

    application
      .registerStartupAction(Symbol.for('access_token_from_query'), async (app) => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        if (params.has('access_token')) {
          const token = params.get('access_token')!;
          const tokenKey = '_authToken';
          const storage = app.get<Storage>(QuinoCoreServiceSymbols.IStorage);
          storage.setItem(tokenKey, decodeURIComponent(token));
        }
      })
      .moveTo(InitializationGroup);

    application
      .registerStartupAction(AuthenticationStartupActionIdentifier, async (app) => {
        const decorator = app.get<IRequestDecoratorProvider>(QuinoCoreServiceSymbols.IRequestDecoratorProvider);
        const authenticationService = app.get<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService);

        await authenticationService.isLoggedIn();

        decorator.register(
          {
            decorate: (request) => authenticationService.authenticateRequest(request)
          },
          Symbol.for('authenticationService.authenticateRequest')
        );
      })
      .moveTo(FrameworkStartupGroup);
  };
}
