import { getAspectOrDefault, IMetaAspect } from '../IMetaAspect';
import { IExpression } from '../../expressions';
import { IMetaElement } from '../IMetaElement';

/**
 * The identifier for retrieving a visible in explorer aspect.
 */
export const VisibleInExplorerAspectIdentifier = 'VisibleInExplorer';

/**
 * VisibleInExplorerAspect, which contains a visible expression or a boolean value
 */
export interface IVisibleInExplorerAspect extends IMetaAspect {
  visible: IExpression | boolean;
}

/**
 * Whether the given element has a visible in explorer aspect
 * @param metaElement - the meta element.
 */
export function hasVisibleInExplorerAspect(metaElement: IMetaElement): boolean {
  return getAspectOrDefault<IVisibleInExplorerAspect>(metaElement, VisibleInExplorerAspectIdentifier) != null;
}
