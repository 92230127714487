import React from 'react';
import { Calendar } from '../calendar/Calendar';
import { CalendarBookmarkActions } from '../calendar/CalendarBookmarkActions';
import { ICalendarAspect } from '../calendar/aspect/ICalendarAspect';
import { CalendarBookmarkStateIdentifier, ICalendarBookmark, TCalendarStateObject } from './ICalendarBookmark';
import { StateFullBookmark } from './StateFullBookmark';

export class CalendarBookmark extends StateFullBookmark implements ICalendarBookmark {
  constructor(surface = 'default', calendarAspect: ICalendarAspect) {
    super();

    this.surface = surface;
    this.aspect = calendarAspect;
    this.title = this.aspect.caption;
  }

  getCalenderState(): TCalendarStateObject | undefined {
    try {
      return JSON.parse(this.getStateValue(CalendarBookmarkStateIdentifier)) as TCalendarStateObject;
    } catch (e) {
      return undefined;
    }
  }

  subscribeToRefresh = (callback: () => void): Symbol => {
    const symbol = Symbol();
    this.refreshHandlers.set(symbol, callback);
    return symbol;
  };

  unsubscribeFromRefresh = (symbol: Symbol): void => {
    this.refreshHandlers.delete(symbol);
  };

  refresh(): void {
    this.refreshHandlers.forEach((callback) => callback());
  }

  render(): React.ReactElement {
    return (
      <div className='quino-ecui-bookmark-content'>
        <div className='quino-ecui-bookmark-detail'>
          <Calendar bookmark={this} />
        </div>
      </div>
    );
  }

  renderActions(): React.ReactElement {
    return <CalendarBookmarkActions bookmark={this} />;
  }

  protected readonly refreshHandlers = new Map<Symbol, () => void>();

  surface: string;
  readonly aspect: ICalendarAspect;
  readonly title: string;
  readonly name: Symbol = Symbol.for('CalendarBookmark');
}
