import { IBookmarkTitleCalculator } from './IBookmarkTitleCalculator';
import {
  IBookmark,
  isICustomBookmark,
  isIDashboardBookmark,
  isILayoutAwareBookmark,
  isIListBookmark,
  isIMetaBookmark,
  isIObjectBookmark,
  isIRelatedListBookmark
} from '../bookmarks';
import { inject, injectable } from 'inversify';
import { IDashboardSettingsService, IDashboardSettingsServiceSymbol } from '../dashboard';
import { ILayoutResolver, ILayoutResolverSymbol, IMetaAspect } from '@quino/core';

@injectable()
export class BookmarkTitleCalculator implements IBookmarkTitleCalculator {
  constructor(
    @inject(ILayoutResolverSymbol) private readonly layoutResolver: ILayoutResolver,
    @inject(IDashboardSettingsServiceSymbol) private readonly dashboardSettingsService: IDashboardSettingsService
  ) {}

  generate(bookmark: IBookmark): string {
    if (isILayoutAwareBookmark(bookmark)) {
      const titleAspect = bookmark.layout.aspects.find((aspect) => aspect.name === 'ListTitle');
      if (titleAspect) {
        return (titleAspect as IMetaAspect & { title: string }).title;
      }
    }
    if (isIRelatedListBookmark(bookmark)) {
      return bookmark.options.title || `${bookmark.sourceClass.caption} / ${bookmark.relation.caption}`;
    } else if (isIObjectBookmark(bookmark)) {
      return bookmark.genericObject.title == null || bookmark.genericObject.title === '' ? bookmark.layout.caption : bookmark.genericObject.title;
    } else if (isIListBookmark(bookmark)) {
      return bookmark.options.title || bookmark.metaClass.pluralCaption;
    } else if (isICustomBookmark(bookmark)) {
      return bookmark.title;
    }

    return bookmark.toString();
  }

  generateWithLayout(bookmark: IBookmark): string {
    let bookmarkTitle = this.generate(bookmark);

    if (isILayoutAwareBookmark(bookmark)) {
      let multipleLayouts = false;

      if (isIMetaBookmark(bookmark) && bookmark.metaClass && bookmark.layout) {
        multipleLayouts = this.layoutResolver.resolve({ metaClass: bookmark.metaClass.name, type: bookmark.layout.type }).length > 1;
      } else if (isIDashboardBookmark(bookmark)) {
        multipleLayouts = this.dashboardSettingsService.getCurrentNumberOfDashboards() > 1;
      }

      // Show layout detail name if there are multiple layouts or (for list layouts) if the layout is not the default list
      const isListAndNotDefault = isIListBookmark(bookmark) && bookmark.layout.name !== 'List';
      bookmarkTitle += bookmark.layout.caption && (multipleLayouts || isListAndNotDefault) ? ` (${bookmark.layout.caption})` : '';
    }
    return bookmarkTitle;
  }
}
