import { IErrorMapper } from './IErrorMapper';
import { DefaultErrorMapper } from './DefaultErrorMapper';

export class UnclassifiedServerErrorMapper extends DefaultErrorMapper implements IErrorMapper {
  static unclassifiedServerErrorMapperName = 'ServerError.Unclassified';
  get errorName() {
    return UnclassifiedServerErrorMapper.unclassifiedServerErrorMapperName;
  }

  getErrorMessage(error: Error): string {
    return this.translationService.translate('Notification.ErrorBoundaryError');
  }

  getErrorName(error: Error): string {
    return this.translationService.translate(this.errorName);
  }

  static createUnclassifiedError(): Error {
    const error = new Error();
    error.name = UnclassifiedServerErrorMapper.unclassifiedServerErrorMapperName;
    return error;
  }
}
