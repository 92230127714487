import { IGenericObject, IMetaClass, IMetaLayout, QuinoODataStore } from '@quino/core';
import { IListBookmark, ListBookmarkEvent } from './IListBookmark';
import DataSource from 'devextreme/data/data_source';
import { IListBookmarkOptions } from './IListBookmarkOptions';
import { StateFullBookmark } from './StateFullBookmark';
import { IObjectBookmark } from './IObjectBookmark';

export class ListBookmark extends StateFullBookmark implements IListBookmark {
  constructor(public metaClass: IMetaClass, public layout: IMetaLayout, source: IGenericObject[]) {
    super();
    this.internalSource = source;
    this.options = { storageKey: `${this.metaClass.name}_${this.layout.name}` };

    this.subscribeToStateChange((scopeKey) => {
      if (scopeKey === 'scope') {
        this.reloadDataSource();
      }
    });
  }

  dataSource?: DataSource;

  options: IListBookmarkOptions;
  parentBookmark: IObjectBookmark;

  get selection(): IGenericObject[] {
    return this.internalSelection;
  }

  set selection(selection: IGenericObject[]) {
    this.internalSelection = selection;

    this.selectionChangeHandlers.forEach((value) => value(selection));
  }

  get source(): IGenericObject[] {
    return this.internalSource;
  }

  set source(source: IGenericObject[]) {
    this.internalSource = source;

    this.sourceChangeHandlers.forEach((value) => value(source));
  }

  subscribe = (callback: (event: IGenericObject[]) => void, event: ListBookmarkEvent): Symbol => {
    const symbol = Symbol();

    switch (event) {
      case ListBookmarkEvent.SourceChanged:
        this.sourceChangeHandlers.set(symbol, callback);
        break;
      case ListBookmarkEvent.SelectionChanged:
        this.selectionChangeHandlers.set(symbol, callback);
        break;
    }

    return symbol;
  };

  unsubscribe = (symbol: Symbol, event: ListBookmarkEvent): void => {
    switch (event) {
      case ListBookmarkEvent.SourceChanged:
        this.sourceChangeHandlers.delete(symbol);
        break;
      case ListBookmarkEvent.SelectionChanged:
        this.selectionChangeHandlers.delete(symbol);
        break;
    }
  };

  refresh(): void {
    this.reloadDataSource();
  }

  reloadDataSource(): void {
    let store = this.dataSource?.store() as QuinoODataStore;
    store && store.clearRawDataCache && store.clearRawDataCache();
    this.dataSource?.reload();
  }

  hasInlineEditChanges() {
    return this.hasPendingEditorChanges;
  }

  setHasInlineEditChanges(hasChanges: boolean) {
    this.hasPendingEditorChanges = hasChanges;
  }

  private hasPendingEditorChanges = false;

  private internalSelection: IGenericObject[] = [];
  private internalSource: IGenericObject[] = [];

  private readonly sourceChangeHandlers = new Map<Symbol, (source: IGenericObject[]) => void>();
  private readonly selectionChangeHandlers = new Map<Symbol, (selections: IGenericObject[]) => void>();
}
