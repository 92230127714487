import { IDataSourceFactoryTools } from './IDataSourceFactoryTools';
import { DataType, IMetaLayout, IMetaProperty } from '@quino/core';
import { injectable } from 'inversify';

@injectable()
export class DataSourceFactoryTools implements IDataSourceFactoryTools {
  createFilter(searchOperation: string, searchValue: any, layout: IMetaLayout): any[] {
    const filter = [];

    for (const element of layout.elements) {
      if ((element as IMetaProperty).dataType === DataType.Text) {
        if (filter.length > 0) {
          filter.push('or');
        }
        filter.push([element.name, searchOperation, searchValue]);
      }
    }

    return filter;
  }
}
