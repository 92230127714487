import { IBookmark } from '../bookmarks';
import { ICombinedBookmarkActions } from './IQuinoAction';

/**
 * Symbol to retrieve the ClassActionService from the IoC.
 */
export const ClassActionsServiceSymbol = Symbol.for('ClassActionsService');

/**
 * Service to extract class actions from an array of IMetaElements
 */
export interface IClassActionsService {
  getClassActions(bookmark: IBookmark): ICombinedBookmarkActions;
  getClassActionDependencies(bookmark: IBookmark | undefined): string[];
}
