import * as React from 'react';
import { useMemo, useState } from 'react';
import { DataType, IMetadataTree, IMetaProperty, isIMetaAction, isValueList, QuinoCoreServiceSymbols } from '@quino/core';
import { useService } from '../../ioc';
import { SelectBox } from 'devextreme-react/select-box';
import DataSource from 'devextreme/data/data_source';

export interface IQuinoMetaPropertySelectorProps {
  metaClass?: string;
  value?: string;
  onSelect: (value: IMetaProperty) => void;
  excludeActionProperties?: boolean;
  excludeNonValueListRelations?: boolean;
  excludeNonNumericProperties?: boolean;
}

export function QuinoMetaPropertySelector(props: IQuinoMetaPropertySelectorProps) {
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const [value, setValue] = useState<IMetaProperty | undefined>(() => {
    if (props.metaClass) {
      const metaClass = metadataTree.getClass(props.metaClass);
      return metaClass.properties.find((x) => x.name === props.value) || metaClass.relations.find((x) => x.name === props.value);
    }
    return undefined;
  });

  const filterNumericAndBoolean = (properties: IMetaProperty[]): IMetaProperty[] => {
    return properties.filter((property) => {
      switch (property.dataType) {
        case DataType.Boolean:
        case DataType.Currency:
        case DataType.Double:
        case DataType.Integer:
        case DataType.LargeInteger:
        case DataType.SmallInteger:
        case DataType.TinyInteger:
          return true;
        default:
          return false;
      }
    });
  };

  const source = useMemo(() => {
    if (props.metaClass) {
      const metaClass = metadataTree.getClass(props.metaClass);

      let filteredProperties = props.excludeActionProperties ? metaClass.properties.filter((x) => !isIMetaAction(x)) : metaClass.properties;
      filteredProperties = props.excludeNonNumericProperties ? filterNumericAndBoolean(filteredProperties) : filteredProperties;
      let filteredRelations = props.excludeNonValueListRelations ? metaClass.relations.filter((x) => isValueList(x)) : metaClass.relations;
      filteredRelations = props.excludeNonNumericProperties ? [] : filteredRelations;

      const combinedItems = new DataSource([...filteredProperties, ...filteredRelations]);
      combinedItems.sort({ selector: 'caption' });
      return combinedItems;
    }
    return new DataSource([]);
  }, [metadataTree, props.excludeActionProperties, props.excludeNonNumericProperties, props.excludeNonValueListRelations, props.metaClass]);

  return (
    <SelectBox
      deferRendering={false}
      showClearButton={true}
      displayExpr={(data: any) => data && `${data.caption} (${data.name})`}
      value={value}
      dataSource={source}
      searchEnabled={true}
      dropDownOptions={{ width: '320px' }}
      onValueChanged={(e) => {
        setValue(e.value);
        props.onSelect(e.value);
      }}
    />
  );
}
