import * as React from 'react';
import { IObjectBookmark } from '../../bookmarks';
import { IMetaElement, isIMetaGroup, IMetaGroup } from '@quino/core';
import { QuinoObjectSummaryItem } from './QuinoObjectSummaryItem';

export function QuinoObjectSummary(props: { element: IMetaElement; bookmark: IObjectBookmark }) {
  const { element, bookmark } = props;

  return (
    <div className={'quino-object-summary'}>
      {isIMetaGroup(element) ? (
        <QuinoObjectSummaryGroup
          keyString={'quino-object-summary'}
          metaGroup={element}
          bookmark={bookmark}
          showRightBorder={false}
          showLeftBorder={false}
        />
      ) : (
        <>The top level element of an ObjectSummary must be a MetaGroup.</>
      )}
    </div>
  );
}

function QuinoObjectSummaryGroup(props: {
  metaGroup: IMetaGroup;
  keyString: string;
  bookmark: IObjectBookmark;
  showLeftBorder?: boolean;
  showRightBorder?: boolean;
}) {
  return (
    <>
      {props.metaGroup.elements.map((item, index, allElements) => {
        const showLeftBorder = (index === 0 && props.showLeftBorder) || (index > 0 && isIMetaGroup(allElements[index - 1]));
        const showRightBorder =
          (index === allElements.length - 1 && props.showRightBorder) ||
          (index < allElements.length - 1 && isIMetaGroup(allElements[index + 1]) && !isIMetaGroup(item));

        return isIMetaGroup(item) ? (
          <QuinoObjectSummaryGroup
            key={props.keyString + '-group' + index.toString()}
            keyString={props.keyString + '-group' + index.toString()}
            bookmark={props.bookmark}
            metaGroup={item}
            showLeftBorder={showLeftBorder}
            showRightBorder={showRightBorder}
          />
        ) : (
          <QuinoObjectSummaryItem
            key={props.keyString + '-item' + index.toString()}
            bookmark={props.bookmark}
            element={item}
            showLeftBorder={showLeftBorder}
            showRightBorder={showRightBorder}
          />
        );
      })}
    </>
  );
}
