import * as React from 'react';
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextBox } from 'devextreme-react/text-box';
import { IAuthenticationService, ITranslationService, ITranslationServiceSymbol, QuinoCoreServiceSymbols } from '@quino/core';
import { QuinoErrorSummary, QuinoLabeled, QuinoLoadButton, QuinoPasswordBox, searchForErrors, useService } from '@quino/ui';
import { ScrollView } from 'devextreme-react/scroll-view';

interface IChangePasswordComponentProps {
  onPasswordChanged: () => void;
  goToLogin: () => void;
  focusRequested: boolean;
}

export function CUIResetPasswordComponent(props: IChangePasswordComponentProps) {
  // services
  const authenticationService = useService<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  // props
  const { focusRequested, onPasswordChanged, goToLogin } = props;

  // refs
  const newPasswordTextBox = useRef<TextBox | null>(null);
  const confirmPasswordTextBox = useRef<TextBox | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  // translations
  const rememberPasswordString = translationService.translate('QuinoAuthenticationFeedback.HintRememberPassword');
  const gotoLoginString = translationService.translate('QuinoAuthenticationFeedback.ButtonGotoLogin');
  const setNewPasswordString = translationService.translate('QuinoAuthenticationFeedback.TitleSetNewPassword');
  const emailString = translationService.translate('QuinoAuthenticationFeedback.Email');
  const newPasswordString = translationService.translate('QuinoAuthenticationFeedback.NewPassword');
  const newPasswordRequiredString = translationService.translate('Validations.NewPasswordRequired');
  const confirmPasswordString = translationService.translate('QuinoAuthenticationFeedback.RepeatPassword');
  const passwordsDoNotMatchString = translationService.translate('Validations.PasswordsDoNotMatch');
  const passwordResetFailed = translationService.translate('QuinoAuthenticationFeedback.FailedToResetPassword');

  // states
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [newPasswordRequirementErrors, setNewPasswordRequirementErrors] = useState<string[]>([]);
  const [error, setError] = useState<string[] | undefined>(undefined);
  const [newPasswordErrors, setNewPasswordErrors] = useState<string[]>([]);
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const requiredCheckFunction = (text: string) => {
    return { isValid: text !== '', errorMessage: newPasswordRequiredString };
  };

  const passwordMatchFunction = (text: string) => {
    return { isValid: text === newPassword, errorMessage: passwordsDoNotMatchString };
  };

  const passwordMatchInverseFunction = (text: string) => {
    return { isValid: text === confirmPassword, errorMessage: passwordsDoNotMatchString };
  };

  const setFocusToUsernameTextBox = () => {
    newPasswordTextBox.current?.instance && newPasswordTextBox.current.instance.focus();
  };

  const userObject = useMemo<{ user: string; token: string }>(() => {
    const params = new URLSearchParams(document.location.search);
    return { user: params.get('email')!, token: params.get('token')! };
  }, []);

  useEffect(() => {
    setFocusToUsernameTextBox();
  }, [focusRequested]);

  const resetPassword = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (newPasswordErrors.length === 0 && confirmPasswordErrors.length === 0 && newPassword !== '' && confirmPassword !== '') {
        setLoading(true);
        authenticationService
          .resetPasswordAsync({ email: userObject.user, token: userObject.token, newPassword: newPassword })
          .then(() => onPasswordChanged())
          .catch((errors) => {
            if (errors != null) {
              let errorStringArray: string[] = [];
              errors.map((error: { code: string; description: string }) => {
                errorStringArray.push(error.description);
              });
              setError(errorStringArray);
            } else {
              setError([passwordResetFailed]);
            }
          })
          .finally(() => setLoading(false));
      }
    },
    [
      authenticationService,
      confirmPassword,
      confirmPasswordErrors.length,
      newPassword,
      newPasswordErrors.length,
      onPasswordChanged,
      passwordResetFailed,
      userObject.token,
      userObject.user
    ]
  );

  return (
    <>
      <h3 className={'quino-auth-title'}>{setNewPasswordString}</h3>
      <form
        // @ts-ignore
        ref={formRef}
        onSubmit={resetPassword}
        className={'quino-auth-form-content'}>
        <ScrollView className={'quino-auth-scrollview'} useNative={false}>
          {/*Do not remove div to prevent scroll view crashes when empty*/}
          <div>
            <QuinoLabeled label={emailString}>
              <TextBox disabled={true} value={userObject.user} />
            </QuinoLabeled>
            <QuinoLabeled label={newPasswordString} required={true} errorMessages={[...newPasswordErrors, ...newPasswordRequirementErrors]}>
              <QuinoPasswordBox
                showRequirements={true}
                onValidChanged={(fieldErrors) => {
                  setNewPasswordRequirementErrors(fieldErrors.map((error) => error.errorMessage));
                }}
                id='newPassword'
                ref={newPasswordTextBox}
                isValid={newPasswordErrors.length === 0}
                onValueChanged={(e) => {
                  setNewPassword(e.value);
                  searchForErrors(e.value, setNewPasswordErrors, [requiredCheckFunction]);
                  searchForErrors(e.value, setConfirmPasswordErrors, [passwordMatchInverseFunction]);
                }}
                valueChangeEvent={'keyup'}
              />
            </QuinoLabeled>
            <QuinoLabeled label={confirmPasswordString} required={true} errorMessages={confirmPasswordErrors}>
              <QuinoPasswordBox
                id='newPasswordConfirm'
                // @ts-ignore
                ref={confirmPasswordTextBox}
                isValid={confirmPasswordErrors.length === 0}
                onValueChanged={(e) => {
                  setConfirmPassword(e.value);
                  searchForErrors(e.value, setConfirmPasswordErrors, [passwordMatchFunction]);
                }}
                valueChangeEvent={'keyup'}
              />
            </QuinoLabeled>
            <QuinoErrorSummary errorMessages={error} topPadding />
          </div>
        </ScrollView>
        <div className={'quino-auth-footer'}>
          <QuinoLoadButton
            text={setNewPasswordString}
            icon={'material-icons-outlined check'}
            type='default'
            stylingMode='contained'
            className={'quino-auth-button'}
            useSubmitBehavior={true}
            loading={loading}
          />
          <p className={'quino-auth-hint-text'}>
            {rememberPasswordString}{' '}
            <a href='#' onClick={goToLogin}>
              {gotoLoginString}
            </a>
          </p>
        </div>
      </form>
    </>
  );
}
