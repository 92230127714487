import { DataType, getAspectOrDefault, IMaximumLengthAspect, IMetaProperty, MaximumLengthAspectIdentifier } from '../../meta';
import { IFieldValidationResult } from '../IFieldValidationResult';
import { FieldValidationErrorCodes } from '../FieldValidationErrorCodes';
import { injectable } from 'inversify';

import { IFieldValidator } from '../IFieldValidator';
import { IValidationContext } from '../IValidationContext';

@injectable()
export class MaxLengthValidator implements IFieldValidator {
  validate(field: IMetaProperty, value: any, _context: IValidationContext): IFieldValidationResult {
    const aspect = getAspectOrDefault<IMaximumLengthAspect>(field, MaximumLengthAspectIdentifier);

    if (aspect == null || field.dataType !== DataType.Text || typeof value !== 'string' || value.length <= aspect.maximumSize) {
      return {};
    }

    return {
      fieldErrors: [
        {
          fieldName: field.name,
          errorCode: FieldValidationErrorCodes.MAXLENGTH,
          errorMessage: aspect.message
        }
      ]
    };
  }
}
