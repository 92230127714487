import { getAspectOrDefault, IMetaElement } from '@quino/core';
import { injectable } from 'inversify';
import { IColumnConfigurationService } from './IColumnConfigurationService';
import { DefaultColumnConfiguration, IColumnConfiguration } from './IColumnConfiguration';
import { ColumnConfigurationAspectIdentifier, IColumnConfigurationAspect } from '../../aspects/IColumnConfigurationAspect';

@injectable()
export class QuinoColumnConfigurationService implements IColumnConfigurationService {
  getColumnConfiguration(element: IMetaElement): IColumnConfiguration {
    const aspect = getAspectOrDefault<IColumnConfigurationAspect>(element, ColumnConfigurationAspectIdentifier);
    if (aspect) {
      return { ...DefaultColumnConfiguration, ...aspect.configuration };
    }

    return DefaultColumnConfiguration;
  }
}
