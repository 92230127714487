import {
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import * as React from 'react';
import { PropsWithChildren, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { IQuinoMetaPanelExtensionProps } from './QuinoMetaPanel';
import { isIObjectBookmark, ObjectBookmark } from '../../bookmarks';
import { useOnBookmarkAnyEvent, useRerender } from '../Util';
import { useService } from '../../ioc';
import { IFeedbackService, IFeedbackServiceSymbol } from '../../feedback';

export function QuinoMetaPanelSaveButton(props: PropsWithChildren<IQuinoMetaPanelExtensionProps>) {
  const feedbackService = useService<IFeedbackService>(IFeedbackServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const rerender = useRerender();
  useOnBookmarkAnyEvent(props.bookmark, rerender);

  if (isIObjectBookmark(props.bookmark)) {
    const objectBookmark = props.bookmark as ObjectBookmark;

    return (
      <>
        <Button
          icon={'material-icons-outlined save'}
          type={'default'}
          disabled={isSaving || !objectBookmark.hasChanges()}
          text={translationService.translate('Save')}
          onClick={() => {
            setIsSaving(true);
            objectBookmark
              .validate()
              .then((result) => {
                if (result.hasErrors) {
                  const unload = loadingFeedback.load(translationService.translate('Saving'));
                  return objectBookmark
                    .save()
                    .then(() => {
                      props.updateMetaPanel!(objectBookmark);
                      setIsSaving(false);
                    })
                    .catch((error) => {
                      setIsSaving(false);
                      feedbackService
                        .showMessage(
                          translationService.translate('Error'),
                          translationService.translate('Detail.ErrorCouldNotSaveData'),
                          error != null ? error.toString() : undefined
                        )
                        .catch(logger.logError);
                    })
                    .finally(unload);
                } else {
                  props.updateMetaPanel!(objectBookmark);
                }

                return setIsSaving(false);
              })
              .catch(logger.logError);
          }}
        />
      </>
    );
  }

  return <div />;
}
