import {
  DataType,
  EmptyLayoutScope,
  ExpressionLayoutScope,
  getAspectOrDefault,
  IControlBehaviorRegistry,
  IExpressionEvaluator,
  IGenericObject,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILayoutScopeAspect,
  ILayoutScopeManager,
  ILogger,
  IMetaClass,
  IMetadataTree,
  IMetaElement,
  IMetaLayout,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  IMetaRelation,
  IReadOnlyCalculator,
  isLayoutScopeAspect,
  ITranslationService,
  ITranslationServiceSymbol,
  IValidator,
  IVisibleCalculator,
  LayoutScopeAspectIdentifier,
  LayoutScopeManagerSymbol,
  LayoutType,
  mapToDevexpressSort,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { IObjectBookmark, isIObjectBookmark, isNewObjectPrimaryKey } from './IObjectBookmark';
import { IListBookmark } from './IListBookmark';
import { ObjectBookmark } from './ObjectBookmark';
import { ListBookmark } from './ListBookmark';
import { IBookmarkFactory } from './IBookmarkFactory';
import { inject, injectable } from 'inversify';
import { IBookmark } from './IBookmark';
import DataSource from 'devextreme/data/data_source';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../data/IODataSourceFactory';
import { IBookmarkSerializerProvider, IBookmarkSerializerProviderSymbol } from '../navigation/IBookmarkSerializerProvider';
import { IDashboardLayout, IDashboardSettingsService, IDashboardSettingsServiceSymbol } from '../dashboard';
import { IDashboardBookmark } from './IDashboardBookmark';
import { DashboardBookmark } from './DashboardBookmark';
import Guid from 'devextreme/core/guid';
import { RelatedListBookmark } from './RelatedListBookmark';
import { BookmarkScopingServiceSymbol, IBookmarkScopingService } from './IBookmarkScopingService';
import { isStateFullBookmark } from './IStateFullBookmark';
import { IGenericObjectPersistenceService, IGenericObjectPersistenceServiceSymbol } from '../data/IGenericObjectPersistenceService';
import { IPrefilteredListBookmark } from './IPrefilteredListBookmark';
import { PrefilteredListBookmark } from './PrefilteredListBookmark';
import cloneDeep from 'lodash/cloneDeep';
import { ICalendarAspect, isICalendarAspect } from '../calendar/aspect/ICalendarAspect';
import { ICalendarBookmark } from './ICalendarBookmark';
import { CalendarBookmark } from './CalendarBookmark';

@injectable()
export class BookmarkFactory implements IBookmarkFactory {
  constructor(
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(ILayoutResolverSymbol) private readonly layoutResolver: ILayoutResolver,
    @inject(IODataSourceFactorySymbol) private readonly odataFactory: IODataSourceFactory,
    @inject(IBookmarkSerializerProviderSymbol) private readonly bookmarkSerializerProvider: IBookmarkSerializerProvider,
    @inject(IDashboardSettingsServiceSymbol) private readonly dashboardSettingsService: IDashboardSettingsService,
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService,
    @inject(QuinoCoreServiceSymbols.IReadOnlyCalculator) private readonly readOnlyCalculator: IReadOnlyCalculator,
    @inject(IMetaPropertyValueServiceSymbol) private readonly fieldValueService: IMetaPropertyValueService,
    @inject(BookmarkScopingServiceSymbol) private readonly bookmarkScopingService: IBookmarkScopingService,
    @inject(LayoutScopeManagerSymbol) private readonly scopingService: ILayoutScopeManager,
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly expressionEvaluator: IExpressionEvaluator,
    @inject(IGenericObjectPersistenceServiceSymbol) private readonly persistenceService: IGenericObjectPersistenceService,
    @inject(QuinoCoreServiceSymbols.IValidator) private readonly validator: IValidator,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(QuinoCoreServiceSymbols.ControlModifiedRegistry) private readonly controlModifiedRegistry: IControlBehaviorRegistry<boolean>,
    @inject(QuinoCoreServiceSymbols.IVisibleCalculator) private readonly visibleCalculator: IVisibleCalculator
  ) {}

  createList(metaClass: string): Promise<IListBookmark>;
  createList(metaClass: string, layout: IMetaLayout): Promise<IListBookmark>;
  createList(metaClass: string, layout: IMetaLayout, dataSource: DataSource): Promise<IListBookmark>;
  async createList(metaClass: string, layout?: IMetaLayout, dataSource?: DataSource): Promise<IListBookmark> {
    if (layout == null) {
      layout = this.layoutResolver.resolveSingle({ metaClass: metaClass, type: LayoutType.List });
    }

    if (!dataSource) {
      dataSource = this.odataFactory.create(layout, metaClass);
    }

    const resolvedClass = this.metadataTree.getClass(metaClass);

    const result = new ListBookmark(resolvedClass, layout, []);
    result.dataSource = dataSource;
    dataSource.on('changed', () => {
      if (result.source !== dataSource!.items()) {
        result.source = dataSource!.items();
      }
    });

    return this.applyScopeToBookmark(result);
  }

  createRelatedList(relation: IMetaRelation, sourceObject: IGenericObject): IListBookmark {
    const resolvedClass = this.metadataTree.getClass(relation.targetClass);
    const sourceClass = this.metadataTree.getClass(sourceObject.metaClass);
    const layout = this.layoutResolver.resolveSingle({ metaClass: resolvedClass.name, type: LayoutType.List });

    let value = sourceObject[relation.sourceProperties[0]];
    const dataType = resolvedClass.properties.find((x) => x.name === relation.targetProperties[0])!.dataType;
    value = dataType === DataType.Guid ? new Guid(value) : value;

    const dataSource = this.odataFactory.create(layout, resolvedClass.name, {
      filter: [relation.targetProperties[0], '=', value],
      sort: mapToDevexpressSort(relation.sorts)
    });

    return this.applyScopeToBookmark(new RelatedListBookmark(sourceClass, resolvedClass, layout, dataSource, relation, value));
  }

  createPrefilteredList(metaClass: string, filterExpression: any[], layoutName?: string): IPrefilteredListBookmark {
    const resolvedClass = this.metadataTree.getClass(metaClass);
    const resolvedLayout = layoutName ? resolvedClass.layouts.find((l) => l.name.toLowerCase() === layoutName.toLowerCase()) : undefined;
    const layout = cloneDeep(resolvedLayout ? resolvedLayout : this.layoutResolver.resolveSingle({ metaClass: metaClass, type: LayoutType.List }));
    layout.name = layout.name + '-prefiltered';

    const source = this.odataFactory.create(layout, metaClass, filterExpression.length ? { filter: filterExpression } : {});

    const newBookmark = new PrefilteredListBookmark(resolvedClass, layout, filterExpression, source);
    newBookmark.options.showLayoutSwitcher = false;
    newBookmark.options.storageKey = undefined;

    return newBookmark;
  }

  async createListFromMenuEntry(metaClass: string, element: IMetaElement): Promise<IListBookmark> {
    // Reset the scope to filter the correct layouts.
    const scopeAspect = getAspectOrDefault<ILayoutScopeAspect>(element, LayoutScopeAspectIdentifier);
    if (scopeAspect != null) {
      this.scopingService.push(new ExpressionLayoutScope(scopeAspect.filter, this.expressionEvaluator), true);
    } else {
      this.scopingService.push(new EmptyLayoutScope(), true);
    }

    // Resolve the layout AFTER setting the correct scope.
    const layout = this.layoutResolver.resolveSingle({ metaClass: metaClass, type: LayoutType.List, element: element });
    const source = this.odataFactory.create(layout, metaClass);
    const listBookmark = await this.createList(metaClass, layout, source);
    scopeAspect && listBookmark.setStateValue('layoutscope', JSON.stringify(scopeAspect));
    return listBookmark;
  }

  createObject(object: IGenericObject, layout?: IMetaLayout, parentBookmark?: IObjectBookmark): IObjectBookmark {
    if (layout == null) {
      layout = this.layoutResolver.resolveSingle({ metaClass: object.metaClass, type: LayoutType.Detail });
    }

    const resolvedClass = this.metadataTree.getClass(object.metaClass);
    const reloadObject = async (): Promise<IGenericObject> => {
      return isNewObjectPrimaryKey(object.primaryKey)
        ? Promise.resolve(object)
        : this.odataFactory.fetch(
            object.primaryKey!,
            layout ?? this.layoutResolver.resolveSingle({ metaClass: object.metaClass, type: LayoutType.Detail }),
            object.metaClass
          );
    };

    const newBookmark = this.applyScopeToBookmark(
      new ObjectBookmark(
        object,
        layout,
        resolvedClass,
        reloadObject,
        this.persistenceService,
        this.validator,
        this.fieldValueService,
        this.logger,
        this.controlModifiedRegistry,
        this.visibleCalculator
      )
    );

    newBookmark.parentBookmark = parentBookmark;
    return newBookmark;
  }

  async createDashboard(dashboard?: IDashboardLayout, title?: string): Promise<IDashboardBookmark> {
    const displayedTitle = title || this.translationService.translate('Dashboard');
    const usedDashboard = dashboard || (await this.dashboardSettingsService.getFavoriteOrDefaultDashboard());

    return this.applyScopeToBookmark(new DashboardBookmark(displayedTitle, usedDashboard));
  }

  async createNewObject(metaClass: string, parentBookmark?: IBookmark, layoutName?: string): Promise<IObjectBookmark> {
    const customLayout = layoutName
      ? this.metadataTree.getLayouts(metaClass).find((l) => l.name.toLowerCase() === layoutName.toLowerCase())
      : undefined;
    const layout = customLayout ?? this.layoutResolver.resolveSingle({ type: LayoutType.Detail, metaClass: metaClass });
    const cls = this.metadataTree.getClass(metaClass);

    const newObject = await this.odataFactory.fetch(this.newBookmarkPrimaryKey(cls), layout, metaClass);

    const newObjectBookmark = this.createObject(newObject, layout);

    if (isIObjectBookmark(parentBookmark)) {
      const creatorBookmarkMetaRelations: IMetaRelation[] = [];
      cls.relations.forEach((relation) => {
        if (relation.targetClass === parentBookmark.metaClass.name) {
          const readOnly = this.readOnlyCalculator.calculate(relation, newObjectBookmark.genericObject);

          if (!readOnly) {
            creatorBookmarkMetaRelations.push(relation);
          }
        }
      });

      creatorBookmarkMetaRelations.forEach((metaRelation) => {
        newObjectBookmark.updateFieldValue(
          this.fieldValueService.getElementPath(metaRelation),
          parentBookmark.genericObject[parentBookmark.metaClass.primaryKey[0]]
        );
        newObjectBookmark.updateFieldValue(metaRelation.path, parentBookmark.genericObject);
      });

      newObjectBookmark.parentBookmark = parentBookmark;
    }

    return this.applyScopeToBookmark(newObjectBookmark);
  }

  async createFromPath(path: string): Promise<IBookmark> {
    const serializer = this.bookmarkSerializerProvider.getInstances().find((x) => x.canDeserialize(path));

    if (serializer) {
      let bookmark = await serializer.deserialize(path);
      if (isStateFullBookmark(bookmark)) {
        const layoutScopeString = bookmark.getStateValue('layoutscope');
        if (layoutScopeString != null) {
          const scopeAspect = JSON.parse(layoutScopeString);
          if (isLayoutScopeAspect(scopeAspect)) {
            // Reset the scope to filter the correct layouts.
            this.scopingService.push(new ExpressionLayoutScope(scopeAspect.filter, this.expressionEvaluator), true);

            // Create new Bookmark again AFTER setting the correct scope.
            bookmark = await serializer.deserialize(path);
          }
        }
      }
      return bookmark;
    }

    return Promise.reject(`No serializer found to handle url [${path}]`);
  }

  createCalendar(aspect: ICalendarAspect): ICalendarBookmark {
    return new CalendarBookmark('default', aspect);
  }

  createCalendarFromMenuEntry(calendarName: string): ICalendarBookmark {
    const aspect = this.metadataTree.model.aspects.find((x) => x.name.toUpperCase() === calendarName?.toUpperCase());
    if (isICalendarAspect(aspect)) {
      return this.createCalendar(aspect);
    }

    throw new Error(
      `Could not find calendar name "${calendarName}" in the model's aspects [${this.metadataTree.model.aspects
        .map((aspect) => aspect.name)
        .join(', ')}].`
    );
  }

  newBookmarkPrimaryKey = (metaClass: IMetaClass) => {
    return this.isGuidPrimaryKey(metaClass) ? '00000000-0000-0000-0000-000000000000' : '0';
  };

  isGuidPrimaryKey = (metaClass: IMetaClass) => {
    return metaClass.properties.find((x) => x.name === metaClass.primaryKey[0])!.dataType === DataType.Guid;
  };

  private applyScopeToBookmark<TBookmark extends IBookmark>(bookmark: TBookmark): TBookmark {
    if (isStateFullBookmark(bookmark)) {
      const scope = this.bookmarkScopingService.getScope();
      bookmark.setStateValue('scope', scope, true);
    }
    return bookmark;
  }
}
