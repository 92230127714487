import { IFieldError } from './IFieldError';

export interface IServerValidationInfo {
  title: string;
  metaClass: string;
  errors: IFieldError[];
  type: string;
  status?: number;
}

export class ServerValidationError extends Error {
  constructor(message: any, validationInfo: IServerValidationInfo) {
    super(message);
    this.validationInfo = validationInfo;
  }

  public readonly validationInfo: IServerValidationInfo;

  get name() {
    return 'Error.Validation';
  }
}

export const isServerValidationError = (error: Error): error is ServerValidationError => {
  return error && (error as ServerValidationError).validationInfo !== undefined;
};

export const tryParseServerError = (errorMessage: string, metaClass: string): IServerValidationInfo | undefined => {
  try {
    const parsedValue = JSON.parse(errorMessage);

    if (parsedValue && typeof parsedValue === 'object' && parsedValue.errors && parsedValue.title && parsedValue.type) {
      const fieldErrors: IFieldError[] = [];

      Object.keys(parsedValue.errors).forEach((key) => {
        parsedValue.errors[key].forEach((e: string) =>
          fieldErrors.push({
            fieldName: key,
            errorMessage: e,
            errorCode: ''
          })
        );
      });

      return { ...parsedValue, errors: fieldErrors, metaClass } as IServerValidationInfo;
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
};
