import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  IBookmark,
  IBookmarkFactory,
  IDashboardBookmarkEvent,
  IDashboardLayout,
  IDashboardSettingsService,
  IDashboardSettingsServiceSymbol,
  IListBookmark,
  INavigationService,
  INavigationServiceSymbol,
  isIDashboardBookmark,
  isILayoutAwareBookmark,
  isIListBookmark,
  isIMetaBookmark,
  isIMetaClassAwareBookmark,
  isIObjectBookmark,
  QuinoUIServiceSymbols,
  useOnNavigation,
  useService
} from '@quino/ui';
import {
  IFavoriteLayoutService,
  IFavoriteLayoutServiceSymbol,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetaLayout,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { SelectBox } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';

export function CUILayoutSwitcher() {
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const dashboardSettingsService = useService<IDashboardSettingsService>(IDashboardSettingsServiceSymbol);
  const favoriteLayoutService = useService<IFavoriteLayoutService>(IFavoriteLayoutServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  const bookmark = useOnNavigation();
  const metaClass = isIMetaClassAwareBookmark(bookmark) ? bookmark.metaClass : undefined;
  const selectBoxRef = useRef<SelectBox>(null);

  const [disabled, setDisabled] = useState<boolean>(false);
  const [layout, setLayout] = useState<IMetaLayout | undefined>(undefined);
  const [layouts, setLayouts] = useState<IMetaLayout[]>([]);
  const [favoriteLayout, setFavoriteLayout] = useState<string | undefined>(undefined);

  const visible = useMemo<boolean>(
    () =>
      layouts.length > 1 && (isIListBookmark(bookmark) && bookmark.options.showLayoutSwitcher != null ? bookmark.options.showLayoutSwitcher : true),
    [bookmark, layouts.length]
  );

  const switchLayout = useCallback(
    async (metaLayout: IMetaLayout) => {
      if (metaLayout.name === layout?.name) {
        return;
      }

      if (isIListBookmark(bookmark) && !metaClass) {
        return;
      }

      let newBookmark: IBookmark | undefined;
      const unload = loadingFeedback.load();
      if (isIObjectBookmark(bookmark)) {
        newBookmark = bookmarkFactory.createObject(bookmark.genericObject, metaLayout);
      } else if (isIListBookmark(bookmark) && metaClass) {
        newBookmark = await bookmarkFactory.createList(metaClass.name, metaLayout);
        const layoutScopeState = bookmark.getStateValue('layoutscope');
        if (layoutScopeState) {
          (newBookmark as IListBookmark).setStateValue('layoutscope', layoutScopeState);
        }
      } else if (isIDashboardBookmark(bookmark)) {
        newBookmark = await bookmarkFactory.createDashboard(metaLayout as IDashboardLayout);
      }

      newBookmark && (await navigationService.replaceCurrent(newBookmark));
      unload();
    },
    [bookmark, bookmarkFactory, layout?.name, loadingFeedback, metaClass, navigationService]
  );

  const setUnsetFavorite = useCallback(
    (data: IMetaLayout) => {
      let className = '';
      if (isIMetaBookmark(bookmark) && metaClass) {
        className = metaClass.name;
      } else if (isIDashboardBookmark(bookmark)) {
        className = bookmark.name.toString();
      }

      if (className && favoriteLayout !== data.name) {
        favoriteLayoutService.setFavoriteLayout(className, data.name).catch(logger.logError);
        setFavoriteLayout(data.name);
      } else if (className && favoriteLayout === data.name) {
        favoriteLayoutService.clearFavoriteLayout(className).catch(logger.logError);
        setFavoriteLayout(undefined);
      }
    },
    [bookmark, favoriteLayout, favoriteLayoutService, logger.logError, metaClass]
  );

  const renderItem = useCallback(
    (data: IMetaLayout) => {
      const isFavorite = favoriteLayout && data.name === favoriteLayout;

      return (
        <div className={'quino-layout-switcher-item'}>
          <span>{data.caption}</span>
          <Button
            stylingMode={'text'}
            className={'favorite-button' + (isFavorite ? ' is--favorite' : '')}
            icon={'material-icons-outlined ' + (isFavorite ? 'star' : 'star_outline')}
            hint={translationService.translate(isFavorite ? 'RemoveFavorite' : 'SetFavorite')}
            onClick={(e) => {
              e.event?.preventDefault();
              e.event?.stopPropagation();
              setUnsetFavorite(data);
            }}
          />
        </div>
      );
    },
    [favoriteLayout, setUnsetFavorite, translationService]
  );

  const refreshLayoutSource = useCallback(
    async (bookmark?: IBookmark) => {
      const bookmarkLayout = isILayoutAwareBookmark(bookmark) ? bookmark.layout : undefined;
      let newLayouts = bookmarkLayout ? [bookmarkLayout] : [];
      let newFavoriteLayout: string | undefined;

      if (isIMetaBookmark(bookmark) && bookmark.metaClass && bookmarkLayout) {
        const metaLayouts = layoutResolver.resolve({
          metaClass: bookmark.metaClass.name,
          type: bookmarkLayout.type,
          data: isIObjectBookmark(bookmark) ? bookmark.genericObject : {}
        });
        if (metaLayouts.length > 0) {
          newLayouts = metaLayouts;
        }
        newFavoriteLayout = favoriteLayoutService.getFavoriteLayoutName(bookmark.metaClass.name);
      } else if (isIDashboardBookmark(bookmark)) {
        const metaLayouts = await dashboardSettingsService.getDashboards();
        if (metaLayouts.length > 0) {
          newLayouts = metaLayouts;
        }
        newFavoriteLayout = favoriteLayoutService.getFavoriteLayoutName(bookmark.name.toString());
      }

      setLayouts(SortLayouts(newLayouts));
      setLayout(bookmarkLayout);
      setFavoriteLayout(newFavoriteLayout);
    },
    [dashboardSettingsService, favoriteLayoutService, layoutResolver]
  );

  // Disable layout switcher when dashboard is in edit mode
  useEffect(() => {
    if (isIDashboardBookmark(bookmark)) {
      const dashboardBookmark = bookmark;
      const symbol = dashboardBookmark.subscribe((event: IDashboardBookmarkEvent) => {
        if (event.type === 'edit') {
          setDisabled(event.payload);
        }
      });

      return () => dashboardBookmark.unsubscribe(symbol);
    }
    return;
  }, [bookmark]);

  // Refresh source on bookmark change
  useEffect(() => {
    setDisabled(false);
    refreshLayoutSource(bookmark).catch(logger.logError);
  }, [bookmark, refreshLayoutSource, logger.logError]);

  // Repaint SelectBox when layouts are updated
  useEffect(() => selectBoxRef.current?.instance.repaint(), [layouts]);

  return (
    <div className={'quino-layout-switcher-wrapper'}>
      <SelectBox
        ref={selectBoxRef}
        className='quino-layout-switcher'
        visible={visible}
        disabled={disabled}
        items={layouts}
        displayExpr={'caption'}
        value={layout}
        hint={layout?.caption}
        itemRender={renderItem}
        onItemClick={(e) => void switchLayout(e.itemData).catch(logger.logError)}
      />
    </div>
  );
}

export function SortLayouts(layouts: IMetaLayout[]): IMetaLayout[] {
  return layouts.sort((first, second) => {
    return (first.caption ?? first.name ?? '').localeCompare(second.caption ?? second.name ?? '');
  });
}
