import { QuinoLabeled, QuinoLoadButton, searchForErrors, useService, validEmail } from '@quino/ui';
import { TextBox } from 'devextreme-react/text-box';
import * as React from 'react';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { ITokenRequestService, ITranslationService, ITranslationServiceSymbol, QuinoCoreServiceSymbols } from '@quino/core';

interface ICUIPasswordResetComponentProps {
  onGoBack: () => void;
  onStartPasswordReset: () => void;
  focusRequested: boolean;
}

export const CUIResetTokenComponent = (props: React.PropsWithChildren<ICUIPasswordResetComponentProps>) => {
  const { onGoBack, onStartPasswordReset, focusRequested } = props;

  const [emailErrors, setEmailErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const emailTextBox = useRef<TextBox>(null);
  const email = (): string => emailTextBox.current?.instance?.option('text')?.trim() || '';

  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const tokenRequestService = useService<ITokenRequestService>(QuinoCoreServiceSymbols.ITokenRequestService);

  const titleString = translationService.translate('QuinoAuthenticationFeedback.TitleReset');
  const hintString = translationService.translate('QuinoAuthenticationFeedback.ResetHint');
  const emailString = translationService.translate('QuinoAuthenticationFeedback.Email');
  const buttonResetString = translationService.translate('QuinoAuthenticationFeedback.ButtonReset');
  const rememberPasswordString = translationService.translate('QuinoAuthenticationFeedback.HintRememberPassword');
  const gotoLoginString = translationService.translate('QuinoAuthenticationFeedback.ButtonGotoLogin');

  const setFocusToUsernameTextBox = () => {
    emailTextBox.current?.instance && emailTextBox.current.instance.focus();
  };

  const requiredCheckFunction = useCallback(
    (text: string) => {
      return { isValid: text !== '', errorMessage: translationService.translate('Validations.RequiredError') };
    },
    [translationService]
  );

  const emailValidityFunction = useCallback(() => {
    return { isValid: validEmail().test(email()), errorMessage: translationService.translate('Validations.EmailNotCorrect') };
  }, [translationService]);

  useEffect(() => {
    setFocusToUsernameTextBox();
  }, [focusRequested]);

  const resetPassword = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      let errorCount = 0;
      errorCount += searchForErrors(email(), setEmailErrors, [requiredCheckFunction, emailValidityFunction]);
      if (errorCount > 0) {
        return;
      }

      setLoading(true);

      tokenRequestService
        .requestResetTokenAsync(email())
        .then(onStartPasswordReset)
        .finally(() => {
          setLoading(false);
        });
    },
    [tokenRequestService, emailValidityFunction, onStartPasswordReset, requiredCheckFunction]
  );

  return (
    <>
      <h3 className={'quino-auth-title'}>{titleString}</h3>
      <form className={'quino-auth-form-content'} onSubmit={resetPassword}>
        <div className={'quino-labeled-instruction'}>{hintString}</div>
        <QuinoLabeled label={emailString} required={true} errorMessages={emailErrors}>
          <TextBox
            ref={emailTextBox}
            onValueChanged={(e) => searchForErrors(e.value, setEmailErrors, [requiredCheckFunction, emailValidityFunction])}
            isValid={emailErrors.length === 0}
          />
        </QuinoLabeled>
        <div className={'quino-auth-footer'}>
          <QuinoLoadButton
            text={buttonResetString}
            icon={'material-icons-outlined email'}
            type='default'
            stylingMode='contained'
            className={'quino-auth-button'}
            useSubmitBehavior={true}
            loading={loading}
          />
          <p className={'quino-auth-hint-text'}>
            {rememberPasswordString}{' '}
            <a href='#' onClick={onGoBack}>
              {gotoLoginString}
            </a>
          </p>
        </div>
      </form>
    </>
  );
};
