import React, { PropsWithChildren, useEffect, useState } from 'react';
import { IApplication, QuinoApplication } from '@quino/core';
import { LoadPanel } from 'devextreme-react/load-panel';
import { ContainerContext } from '../../ioc';
import { QuinoPopup, QuinoPopupDefaultContent } from '../QuinoPopup';
import { QuinoToast } from '../QuinoNotificationComponents';
import { QuinoContextContextProvider, QuinoContextInitializer } from '../../context';
import { useShortcutBlocker } from '../../shortcuts';
import { ContainerLessQuinoServerHealthChecker } from './ContainerLessQuinoServerHealthChecker';
import { QuinoServerHealthChecker } from './QuinoServerHealthChecker';
import { useOnMountAsync } from '../Util';
import { QuinoBookmarkInitializer } from './QuinoBookmarkInitializer';

export interface IQuinoApplicationComponentProps {
  setup: (application: IApplication) => void;
  onReady?: (application: IApplication) => void;
}

enum StartupState {
  Loading,
  Ready,
  Failed
}

interface IApplicationState {
  state: StartupState;
  application?: IApplication;
  error?: { isNetworkError: boolean; reason: any };
}

export function QuinoApplicationRoot(props: PropsWithChildren<IQuinoApplicationComponentProps>) {
  const [state, setState] = useState<IApplicationState>(() => ({ state: StartupState.Loading }));
  useShortcutBlocker();

  useOnMountAsync(async () => {
    const application = new QuinoApplication();

    try {
      props.setup(application);
      await application.build();
      setState({ state: StartupState.Ready, application: application });
    } catch (e) {
      const isNetworkError = e.message.includes('NetworkError when attempting to fetch resource') || e.message.includes('Failed to fetch');
      setState({ state: StartupState.Failed, error: { isNetworkError: isNetworkError, reason: e } });
    }
  });

  const isLoading = state.state === StartupState.Loading;
  const isFailed = state.state === StartupState.Failed;
  const isReady = !isFailed && !isLoading && state.application;

  useEffect(() => {
    if (isReady && props.onReady) {
      props.onReady(state.application!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.application]);

  return (
    <>
      <LoadPanel message={'Loading Application...'} visible={isLoading} showIndicator={isLoading} />
      <ContainerLessQuinoServerHealthChecker
        visible={isFailed}
        isNetworkError={state.error?.isNetworkError}
        isLoading={isLoading}
        cause={state.error?.reason.message}>
        <QuinoPopup title={'Error'} visible={isFailed}>
          <QuinoPopupDefaultContent hasPaddingHorizontal={true} hasNoToolbar={true}>
            <div>Failed to load application. Error details:</div>
            <pre>{state.error ? state.error.reason.message : 'No details available.'}</pre>
          </QuinoPopupDefaultContent>
        </QuinoPopup>
      </ContainerLessQuinoServerHealthChecker>

      {isReady && (
        <ContainerContext.Provider value={{ container: state.application! }}>
          <QuinoContextContextProvider>
            <QuinoContextInitializer>
              <QuinoBookmarkInitializer />
              <QuinoToast />
              <QuinoServerHealthChecker />
              {props.children}
            </QuinoContextInitializer>
          </QuinoContextContextProvider>
        </ContainerContext.Provider>
      )}
    </>
  );
}
