import { IMetaAspect } from '@quino/core';
import { ILabelSettings } from '../settings';

/**
 * The identifier for retrieving a drop down configuration aspect.
 */
export const LabelOptionsAspectIdentifier = 'LabelOptionsAspect';

/**
 * A configuration aspect for the drop down.
 */
export interface ILabelOptionsAspect extends IMetaAspect {
  /**
   * The options available.
   */
  options: ILabelSettings;
}
