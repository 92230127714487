import { IMetaElement } from '../../../../../meta';
import { ExcelMapperBase } from './ExcelMapperBase';
import { injectable } from 'inversify';
import { IGenericObject } from '../../../../../data';
import { IQuinoExcelRow } from '../../model';
import { IExportExcelSettings } from '../../settings';

export const TabItemExcelMapperSymbol = Symbol.for('TabItemExcelMapper');

@injectable()
export class TabItemExcelMapper extends ExcelMapperBase {
  conditionMatches(element: IMetaElement, genericObject: IGenericObject): boolean {
    return this.isVisible(element, genericObject);
  }

  async getData(genericObject: IGenericObject, element: IMetaElement): Promise<IQuinoExcelRow[]> {
    const settings: IExportExcelSettings = await this.excelExportConfigurationService.getExportSettings(element);
    if (element.caption) {
      return [
        this.rowBuilder
          .withDefaultSettings(settings)
          .withMarginTop(settings.tabItemTitleSettings.rowMargin)
          .withCells([
            this.cellBuilder
              .withDefaultSettings(settings)
              .withFontSize(settings.tabItemTitleSettings.fontSize)
              .setIsBold(settings.tabItemTitleSettings.isBold)
              .withValue(element.caption)
              .build()
          ])
          .build()
      ];
    }
    return [];
  }

  getMappableElements(): string[] {
    return ['TabItem'];
  }
}
