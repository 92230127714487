import { IMetaClass, IMetaRelation } from '@quino/core';
import { IListBookmark } from './IListBookmark';
import { isIBookmark } from './IBookmark';

/**
 * A bookmark pointing to a specific navigation property.
 */
export interface IRelatedListBookmark extends IListBookmark {
  /**
   * The relation used to resolve the current bookmark.
   */
  readonly relation: IMetaRelation;

  /**
   * The source class for this relation.
   */
  readonly sourceClass: IMetaClass;

  /**
   * The key used to resolve the relation.
   */
  readonly foreignKey: any;
}

/**
 * Whether the given object is an ICustomBookmark
 */
export const isIRelatedListBookmark = (object: any): object is IRelatedListBookmark => {
  return isIBookmark(object) && (object as IRelatedListBookmark).relation !== undefined && (object as IRelatedListBookmark).foreignKey !== undefined;
};
