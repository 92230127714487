import { inject, injectable } from 'inversify';
import { INamespace } from './INamespace';
import { ITranslationService, ITranslationServiceSymbol } from '../../lang';

export const TranslationNamespaceSymbol = Symbol.for('TranslationNamespace');

@injectable()
export class TranslationNamespace implements INamespace {
  constructor(@inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService) {}

  Translate = (key: string, context: any): string => {
    return this.translationService.translate(key, context);
  };

  name = 'Language';
}
