import { IBookmark } from '../bookmarks';

export const IBookmarkTitleCalculatorSymbol = Symbol.for('IBookmarkTitleCalculator');

/**
 * Calculate a title for the given bookmark.
 */
export interface IBookmarkTitleCalculator {
  /**
   * Generate the title for the given bookmark.
   * @param bookmark - the bookmark to generate a title for.
   */
  generate(bookmark: IBookmark): string;

  /**
   * Generate the title of the given bookmark with the current layout information
   * @param bookmark - the bookmark to generate a title for.
   */
  generateWithLayout(bookmark: IBookmark): string;
}
