import { useService } from '../ioc';
import { IHomeNavigationService, IHomeNavigationServiceSymbol } from './IHomeNavigationService';

/**
 * @deprecated - use homeNavigationService.navigateHome directly
 */
export const useHomeNavigation = (): (() => Promise<void>) => {
  const homeNavigationService = useService<IHomeNavigationService>(IHomeNavigationServiceSymbol);

  return homeNavigationService.navigateHome;
};
