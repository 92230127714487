import { IMetaElement } from '../../meta';

export const IMaskFormatServiceSymbol = Symbol.for('IMaskFormatService');

/**
 * Get the format mask for the given meta element.
 */
export interface IMaskFormatService {
  /**
   * Return the mask format.
   * @param element - the element to get the mask for.
   */
  get(element: IMetaElement): string | undefined;
}
