import { IBookmark, IRefreshableBookmark } from '../bookmarks';
import { IGenericObject } from '@quino/core';

/**
 * The basic QuinoAction interface
 */
export interface IQuinoAction {
  /**
   * Callback to execute on click
   * @param args - arguments to pass to the executor
   */
  onClick: (args: IQuinoActionArgs) => void;

  /**
   * The icon to show in the action button
   */
  icon: string | ((source: TQuinoActionSource) => string);

  /**
   * The caption to show in the action button
   */
  caption: string | ((source: TQuinoActionSource) => string);

  /**
   * The hint to display when hovering over the action button
   */
  hint: string | ((source: TQuinoActionSource) => string);

  /**
   * Whether the action is visible
   */
  visible: boolean | ((source: TQuinoActionSource) => boolean);

  /**
   * Whether the action is disabled
   */
  disabled: boolean | ((source: TQuinoActionSource) => boolean);

  /**
   * Whether the current object needs to be saved before the action can be executed.
   */
  saveCallingObjectBeforeExecuting?: boolean;

  /**
   * Children of the action, shown as context menu button items
   */
  children?: IQuinoAction[];
}

export type TQuinoBookmarkActionLocation = 'main-before' | 'main-after' | 'icon-priority' | 'icon-normal' | 'icon-overflow';
export type TQuinoBookmarkActionButtonType = 'danger' | 'default' | 'normal' | 'success';
export type TQuinoBookmarkActionButtonStylingMode = 'text' | 'outlined' | 'contained';

export interface IQuinoBookmarkAction extends IQuinoAction {
  /**
   * Where to show the action
   * Default: icon-normal
   */
  location?: TQuinoBookmarkActionLocation;

  /**
   * The button type, if the action is shown as single button
   * Default: normal
   */
  buttonType?: TQuinoBookmarkActionButtonType | ((bookmark: IBookmark) => TQuinoBookmarkActionButtonType);

  /**
   * The button style, if the action is shown as single button
   * Default: text for icon buttons, outlined for main buttons
   */
  buttonStylingMode?: TQuinoBookmarkActionButtonStylingMode | ((bookmark: IBookmark) => TQuinoBookmarkActionButtonStylingMode);
}

export interface IQuinoListAction extends IQuinoAction {
  /**
   * Whether the action is shown in the overflow button by default
   */
  isOverflow?: boolean;
}

export type TQuinoActionSource = IBookmark | IGenericObject;

export interface IQuinoActionArgs {
  source: TQuinoActionSource;
  bookmarkToRefresh?: IRefreshableBookmark;
}

export interface ICombinedBookmarkActions {
  mainActions: IQuinoBookmarkAction[];
  iconActions: IQuinoBookmarkAction[];
}

/**
 * Utility function to get a QuinoAction property value that can be either value or function
 * @param value - the property value
 * @param source - the action source on which to calculate the value
 */
export function quinoActionPropValue<Type>(value: Type | ((source: TQuinoActionSource) => Type), source: TQuinoActionSource): Type {
  return value instanceof Function ? value(source) : value;
}
