import React, { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { useService } from '../../ioc/hook';
import { ILogger, QuinoCoreServiceSymbols } from '@quino/core';
import { IBookmark } from '../../bookmarks';

export function UnknownElement(props: PropsWithChildren<IQuinoComponentProps<IBookmark>>) {
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  logger.logError(`Trying to render an unknown element. Rendering fragment for: [${JSON.stringify(props.element)}]`);

  return (
    <span key={props.element.name} className={'quino-unknown-element-span'}>
      Unknown Element Style <strong>&apos;{props.element.controlName}</strong> (Caption: &apos;{props.element.caption}
      &apos;, Name: &apos;$
      {props.element.name}
      &apos;)
    </span>
  );
}
