import React from 'react';
import {
  IBookmark,
  IBookmarkTitleCalculator,
  IBookmarkTitleCalculatorSymbol,
  useOnBookmarkReloadEvent,
  useOnBookmarkSavedEvent,
  useRerender,
  useService
} from '@quino/ui';
import { CUILayoutSwitcher } from './CUILayoutSwitcher';
import { useCuiSettings } from '../../util';

export interface ICUITitleProps {
  bookmark: IBookmark;
  icon?: string;
}

export function CUITitle(props: ICUITitleProps) {
  const { bookmark, icon } = props;
  const titleCalculator = useService<IBookmarkTitleCalculator>(IBookmarkTitleCalculatorSymbol);
  const cuiSettings = useCuiSettings();

  const rerender = useRerender();
  useOnBookmarkSavedEvent(bookmark, rerender);
  useOnBookmarkReloadEvent(bookmark, rerender);

  return (
    <div className='quino-ecui-sticky-header-title-container'>
      {icon && <i key={'icon'} className={`material-icons-outlined ${icon} quino-ecui-sticky-header-icon`} />}
      {
        <h3 key={'title'} className='quino-ecui-sticky-header-title'>
          {titleCalculator.generate(bookmark)}
        </h3>
      }
      {cuiSettings.hasLayoutSwitcher && <CUILayoutSwitcher />}
    </div>
  );
}
