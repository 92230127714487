/**
 * Value list object.
 */
export interface IOptionValue {
  /**
   * The value related to this object.
   */
  Value: any;

  /**
   * The caption to display.
   */
  Caption: string;
}

export function isOptionValue(object: any): object is IOptionValue {
  return object != null && (object as IOptionValue).Value != null && (object as IOptionValue).Caption != null;
}
