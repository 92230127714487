import { IQuinoDataGridExporter } from './IQuinoDataGridExporter';
import dxDataGrid from 'devextreme/ui/data_grid';
import { Workbook } from 'exceljs';
import { inject, injectable } from 'inversify';
import {
  IDynamicStringCalculator,
  IDynamicStringCalculatorSymbol,
  IExportFileService,
  IMetaClass,
  IMetaElement,
  IMetaProperty,
  isIMetaAction,
  isIMetaLayout,
  isIMetaProperty,
  LayoutType,
  QuinoCoreServiceSymbols,
  QuinoExcelFile
} from '@quino/core';
import { IQuinoDataGridConfiguration } from './IQuinoDataGridConfiguration';

@injectable()
export class QuinoDataGridExporter implements IQuinoDataGridExporter {
  constructor(
    @inject(QuinoCoreServiceSymbols.IExportFileService) private readonly exportFileService: IExportFileService,
    @inject(IDynamicStringCalculatorSymbol) private readonly dynamicStringCalculator: IDynamicStringCalculator
  ) {}

  async handleExport(
    dataGrid: dxDataGrid,
    layout: IMetaElement,
    metaClass: IMetaClass,
    config: IQuinoDataGridConfiguration,
    callback: () => Promise<void>
  ): Promise<void> {
    if (isIMetaLayout(layout) && layout.type === LayoutType.List) {
      const hiddenElements: IMetaProperty[] = layout.elements.filter(
        (value) => isIMetaProperty(value) && !isIMetaAction(value) && dataGrid.columnOption(value.path, 'visible') === false
      ) as IMetaProperty[];
      if (config.exportHiddenColumns) {
        this.setVisibilityOption(dataGrid, hiddenElements, true);
      }
      try {
        await callback();
      } finally {
        if (config.exportHiddenColumns) {
          this.setVisibilityOption(dataGrid, hiddenElements, false);
        }
      }
    }
  }

  async exportExcel(
    dataGrid: dxDataGrid,
    layout: IMetaElement,
    metaClass: IMetaClass,
    config: IQuinoDataGridConfiguration,
    selectedOnly = false
  ): Promise<void> {
    const callback = async () => {
      const name = `${metaClass.pluralCaption}_${this.dynamicStringCalculator.calculateCaption(layout)}`;
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(name);
      const excelFile = new QuinoExcelFile(workbook);
      excelFile.name = name;
      await import('devextreme/excel_exporter').then(async ({ exportDataGrid }) =>
        exportDataGrid({ component: dataGrid, worksheet: worksheet, selectedRowsOnly: selectedOnly, loadPanel: { enabled: false } })
      );
      await this.exportFileService.exportFile(excelFile);
    };

    await this.handleExport(dataGrid, layout, metaClass, config, callback);
    return Promise.resolve();
  }

  async exportPdf(
    dataGrid: dxDataGrid,
    layout: IMetaElement,
    metaClass: IMetaClass,
    config: IQuinoDataGridConfiguration,
    selectedOnly = false
  ): Promise<void> {
    const callback = async () => {
      const layoutCaption = `${this.dynamicStringCalculator.calculateCaption(layout)}`;
      const filename = `${metaClass.pluralCaption}_${layoutCaption}.pdf`;
      const { jsPDF } = await import('jspdf');
      const pdfDocument = new jsPDF({ orientation: 'landscape', unit: 'mm' });

      pdfDocument.setFontSize(15);

      await import('devextreme/pdf_exporter').then(async ({ exportDataGrid }) =>
        exportDataGrid({
          jsPDFDocument: pdfDocument,
          component: dataGrid,
          selectedRowsOnly: selectedOnly,
          loadPanel: { enabled: false }
        })
      );

      await pdfDocument.save(filename, { returnPromise: true });
    };

    await this.handleExport(dataGrid, layout, metaClass, config, callback);
  }

  private setVisibilityOption(dataGrid: dxDataGrid, properties: IMetaProperty[], option: boolean) {
    properties.forEach((value: IMetaProperty) => dataGrid.columnOption(value.path, 'visible', option));
  }
}
