import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IUrlManager } from '../api';
import { IMessenger, IMessengerSymbol } from '../core';
import { ITokenRequestService } from './ITokenRequestService';
import { IRequestBuilder } from '../request';

@injectable()
export class TokenRequestService implements ITokenRequestService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) protected readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IStorage) protected readonly storage: Storage,
    @inject(IMessengerSymbol) readonly messenger: IMessenger,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) readonly requestBuilder: IRequestBuilder
  ) {}

  requestResetTokenAsync = async (userOrEmail: string): Promise<void> => {
    await this.requestBuilder.create(this.urlManager.getIdentityUrl() + '/password/reset/' + userOrEmail, 'get').fetch();
  };

  requestActivationEmailAsync = async (userOrEmail: string): Promise<void> => {
    await this.requestBuilder.create(this.urlManager.getIdentityUrl() + '/email/confirm/' + userOrEmail, 'get').fetch();
  };

  confirmEmailAsync = async (email: string, token: string): Promise<void> => {
    const request = this.requestBuilder.create(this.urlManager.getIdentityUrl() + '/email/confirm', 'post').setJsonPayload({ email, token });

    const result = await request.fetch();

    if (!result.ok) {
      return Promise.reject(await result.json());
    }
  };
}
