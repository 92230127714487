import { inject, injectable } from 'inversify';
import { IBatchRequestItem, IDataService } from '../IDataService';
import { IGenericObject } from '../../data';
import { ILanguageMetadata, ITranslationService, ITranslationServiceSymbol } from '../../lang';
import { IRequestBuilder } from '../../request';
import { IBatchedRequestService, IBatchedRequestServiceSymbol } from '../../request/IBatchedRequestService';
import { IUrlManager } from './IUrlManager';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IBackendVersionInfo } from '../../versionInfo/IBackendVersionInfo';
import { DeleteError, EntityError, ErrorGroup } from '../../error';
import { ConflictBehaviour, ILogEntry, IncludeRelations, MissingEntityBehaviour } from '../IDataManagement';
import { IODataBatchRequest, IODataBatchRequestFactory, IODataBatchRequestFactorySymbol, IODataBatchResponse } from './IODataBatchRequestFactory';
import { IMetadataTree } from '../IMetadataTree';

@injectable()
export class QuinoDataService implements IDataService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder,
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService,
    @inject(IODataBatchRequestFactorySymbol) private readonly batchRequestFactory: IODataBatchRequestFactory,
    @inject(IBatchedRequestServiceSymbol) private readonly batchRequestService: IBatchedRequestService,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree
  ) {}

  async getListAsync<TPayload>(className: string, viewName: string | null): Promise<TPayload> {
    const url = this.urlManager.getListUrl(className, viewName);

    return this.requestBuilder.create(url, 'get').requiresAuthentication().fetchJson<TPayload>();
  }

  async getObjectAsync<TPayload>(className: string, primaryKey: string | null, viewName: string | null): Promise<TPayload> {
    const url = this.urlManager.getObjectUrl(className, primaryKey, viewName);

    return this.requestBuilder.create(url, 'get').requiresAuthentication().fetchJson<TPayload>();
  }

  async getNewObjectAsync<TPayload>(className: string, viewName: string | null): Promise<TPayload> {
    return this.getObjectAsync<TPayload>(className, null, viewName);
  }

  async getRelatedObjectsAsync<T extends IGenericObject>(
    className: string,
    primaryKey: string,
    relationName: string,
    viewName: string | null
  ): Promise<T[]> {
    const url = this.urlManager.getRelatedObjectsForUrl(className, primaryKey, relationName, viewName);
    return this.requestBuilder.create(url, 'get').requiresAuthentication().fetchJson<T[]>();
  }

  async getRelatedObjectAsync<T extends IGenericObject>(className: string, primaryKey: string, relationName: string): Promise<T> {
    const url = this.urlManager.getRelatedObjectForUrl(className, primaryKey, relationName, null);
    return this.requestBuilder.create(url, 'get').requiresAuthentication().fetchJson<T>();
  }

  async updateObjectAsync<TPayload>(className: string, primaryKey: string, data: Partial<IGenericObject>): Promise<TPayload> {
    const url = this.urlManager.updateObjectUrl(className, primaryKey);
    const result = await this.requestBuilder.create(url, 'patch').setJsonPayload(data).requiresAuthentication().fetch('application/json');

    if (!result.ok) {
      await this.throwStatusError(result);
    }

    return result.json();
  }

  async throwStatusError(result: Response) {
    switch (result.status) {
      case 404:
        throw new Error(this.translationService.translate('Detail.ErrorCouldNotSaveData404'));
      case 401:
      case 403:
        throw new Error(this.translationService.translate('Detail.ErrorCouldNotSaveData403'));
      default:
        throw new Error(`${await result.text()}`);
    }
  }

  async insertObjectAsync<TPayload>(className: string, data: Partial<IGenericObject>): Promise<TPayload> {
    const url = this.urlManager.insertObjectUrl(className);

    const result = await this.requestBuilder.create(url, 'post').setJsonPayload(data).requiresAuthentication().fetch('application/json');

    if (!result.ok) {
      await this.throwStatusError(result);
    }

    return result.json();
  }

  async insertRelatedObjectAsync(
    className: string,
    primaryKey: string,
    relationName: string,
    data: Partial<IGenericObject>,
    viewName: string | null
  ): Promise<IGenericObject> {
    const url = this.urlManager.getRelatedObjectForUrl(className, primaryKey, relationName, viewName);

    return this.requestBuilder.create(url, 'post').requiresAuthentication().setJsonPayload(data).fetchJson<IGenericObject>();
  }

  async deleteObjectAsync(className: string, primaryKey: string): Promise<Response> {
    const url = this.urlManager.deleteObjectUrl(className, primaryKey);
    return this.requestBuilder.create(url, 'delete').requiresAuthentication().fetch();
  }

  async deleteObjectsAsync(className: string, primaryKeys: string[]): Promise<string[]> {
    const requests: IODataBatchRequest[] = primaryKeys.map((primaryKey) => {
      const request: IODataBatchRequest = {
        url: this.urlManager.deleteObjectUrl(className, primaryKey),
        method: 'delete',
        id: primaryKey
      };
      return request;
    });

    return Promise.allSettled(requests.map(async (r) => this.batchRequestService.enqueue(r))).then((results) => {
      const errors: EntityError[] = results
        .filter((r) => r.status === 'rejected' || r.value.status !== 200)
        .map((r) =>
          r.status == 'rejected'
            ? new DeleteError(r.reason?.body ?? r.reason, r.reason?.id ?? 'unknown')
            : new DeleteError(`[${r.value.status}] - ${r.value.body}`, r.value.id)
        );

      return errors.length > 0
        ? Promise.reject(new ErrorGroup(errors))
        : results.map((r) => (r as PromiseFulfilledResult<IODataBatchResponse>).value.id);
    });
  }

  async batchProcessObjectsAsync(className: string, objects: IBatchRequestItem[]): Promise<IODataBatchResponse[]> {
    const requests: IODataBatchRequest[] = objects.map((obj) => {
      const classPrimaryKeyPrefix = this.metadataTree.getClass(className).primaryKey[0] + '=';
      const stringifiedKey = obj.primaryKey.toString();
      const adjustedKey = stringifiedKey.includes(classPrimaryKeyPrefix) ? stringifiedKey : classPrimaryKeyPrefix + stringifiedKey;
      switch (obj.method) {
        case 'delete':
          return {
            url: this.urlManager.deleteObjectUrl(className, adjustedKey),
            method: 'delete',
            id: stringifiedKey
          };
        case 'patch':
          return {
            url: this.urlManager.updateObjectUrl(className, adjustedKey),
            method: 'patch',
            id: stringifiedKey,
            body: obj.data,
            headers: { 'Content-Type': 'application/json' }
          };
        case 'post':
          return {
            url: this.urlManager.insertObjectUrl(className),
            method: 'post',
            id: stringifiedKey,
            body: obj.data,
            headers: { 'Content-Type': 'application/json' }
          };
      }
    });

    return this.batchRequestFactory.fetch(requests);
  }

  async getLanguageListAsync(): Promise<ILanguageMetadata[]> {
    const url = this.urlManager.getLanguageListUrl();
    return this.requestBuilder.create(url, 'get').fetchJson<ILanguageMetadata[]>();
  }

  async getVersionInfoAsync(): Promise<IBackendVersionInfo> {
    const url = this.urlManager.getVersionInfoUrl();
    return this.requestBuilder.create(url, 'get').fetchJson<IBackendVersionInfo>();
  }

  async exportClassAsync(
    className: string,
    fileName: string,
    includeRelations: IncludeRelations,
    filter?: Map<string, string> | string[]
  ): Promise<void> {
    const url = this.urlManager.getExportUrl(className, includeRelations, filter);
    const blob = await this.requestBuilder.create(url, 'get').requiresAuthentication().fetchImage();
    const reader = new FileReader();
    reader.onload = (e) => {
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = e.target?.result?.toString() || '';
      anchor.download = `${fileName}.xml`;
      anchor.click();
    };
    reader.readAsDataURL(blob);
  }

  async importFileAsync(
    xmlFile: File,
    conflictBehaviour: ConflictBehaviour,
    missingEntityBehaviour: MissingEntityBehaviour,
    useTransaction: boolean
  ): Promise<ILogEntry[]> {
    const url = this.urlManager.getImportUrl(conflictBehaviour, missingEntityBehaviour, useTransaction);
    const formData = new FormData();
    formData.append('xmlFile', xmlFile);

    return this.requestBuilder.create(url, 'post').requiresAuthentication().setPayload(formData).fetchJson();
  }
}
