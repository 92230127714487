import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import {
  DataType,
  IGenericObject,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetaClass,
  IMetadataTree,
  IMetaLayout,
  IMetaProperty,
  IMetaRelation,
  isGenericObject,
  ITranslationService,
  ITranslationServiceSymbol,
  LayoutType,
  MetaCardinality,
  QuinoCoreServiceSymbols,
  QuinoODataStore
} from '@quino/core';
import DataSource from 'devextreme/data/data_source';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { DataSourceHelper } from '../../util';
import Guid from 'devextreme/core/guid';
import {
  IBookmark,
  IBookmarkFactory,
  IFeedbackService,
  IFeedbackServiceSymbol,
  INavigationService,
  INavigationServiceSymbol,
  IObjectBookmark,
  IODataSourceFactory,
  IODataSourceFactorySymbol,
  IPagingContextService,
  IPagingContextServiceSymbol,
  isIListBookmark,
  isIObjectBookmark,
  isNewObjectBookmark,
  isStateFullBookmark,
  QuinoUIServiceSymbols,
  useOnNavigation,
  useService
} from '@quino/ui';

const bookmarkStateTabIndexKey = 'tabindex';

export function CUIObjectPaging() {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const feedbackService = useService<IFeedbackService>(IFeedbackServiceSymbol);
  const odataFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const pagingContextService = useService<IPagingContextService>(IPagingContextServiceSymbol);
  const currentBookmark = useOnNavigation();
  const bookmarkStack = navigationService.get();

  const [cachedBookmark, setCachedBookmark] = useState<IObjectBookmark | undefined>(undefined);
  const [sourceHelper, setSourceHelper] = useState<DataSourceHelper | undefined>(undefined);
  const [positionAbsolute, setPositionAbsolute] = useState<number | undefined>(undefined);
  const [isAbsoluteLastItem, setIsAbsoluteLastItem] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
  const [showMiniLoader, setShowMiniLoader] = useState<boolean>(false);

  const previous = async () => {
    const unload = loadingFeedback.load();
    if (sourceHelper && positionAbsolute) {
      const item = await sourceHelper.previousItem(positionAbsolute);
      await loadItem(item, false);
    }
    unload();
  };

  const next = async () => {
    const unload = loadingFeedback.load();
    if (sourceHelper && positionAbsolute !== undefined && !isAbsoluteLastItem) {
      const item = await sourceHelper.nextItem(positionAbsolute);
      await loadItem(item, true);
    }
    unload();
  };

  const first = async () => {
    const unload = loadingFeedback.load();
    if (sourceHelper && positionAbsolute) {
      const item = await sourceHelper.firstItem();
      await loadItem(item, false);
    }
    unload();
  };

  const last = async () => {
    const unload = loadingFeedback.load();
    if (sourceHelper && positionAbsolute !== undefined && !isAbsoluteLastItem) {
      const item = await sourceHelper.lastItem();
      await loadItem(item, true);
    }
    unload();
  };

  const loadItem = async (itemData: any, directionForward: boolean): Promise<void> => {
    if (isIObjectBookmark(currentBookmark)) {
      const newBookmark = await createBookmark(itemData, currentBookmark.layout, currentBookmark.metaClass);
      if (newBookmark) {
        takeOverParent(currentBookmark, newBookmark);
        takeOverTab(currentBookmark, newBookmark);
        await navigationService.replaceCurrent(newBookmark);
      } else if (sourceHelper && positionAbsolute !== undefined) {
        const confirmed = await feedbackService.getConfirmation(
          translationService.translate('Paging.ItemDeleted'),
          translationService.translate('Paging.ItemDeletedText')
        );
        if (confirmed) {
          const nextValidItem = await sourceHelper.nextValidItem(positionAbsolute, directionForward);
          const nextValidBookmark = await createBookmark(nextValidItem, currentBookmark.layout, currentBookmark.metaClass);
          if (nextValidBookmark) {
            takeOverTab(currentBookmark, nextValidBookmark);
            await navigationService.replaceCurrent(nextValidBookmark);
          }
        }
      }
    }
  };

  const takeOverTab = (oldBookmark: IBookmark, newBookmark: IBookmark) => {
    if (isStateFullBookmark(oldBookmark) && isStateFullBookmark(newBookmark) && oldBookmark.getStateValue(bookmarkStateTabIndexKey) != undefined) {
      newBookmark.setStateValue(bookmarkStateTabIndexKey, oldBookmark.getStateValue(bookmarkStateTabIndexKey));
    }
  };

  const takeOverParent = (oldBookmark: IBookmark, newBookmark: IBookmark) => {
    if (isIObjectBookmark(oldBookmark) && isIObjectBookmark(newBookmark) && oldBookmark.parentBookmark) {
      newBookmark.parentBookmark = oldBookmark.parentBookmark;
    }
  };

  const createBookmark = async (itemData: any, layout: IMetaLayout, metaClass: IMetaClass): Promise<IBookmark | undefined> => {
    if (itemData && itemData.primaryKey) {
      const objectData = await odataFactory.fetch(itemData.primaryKey, layout, metaClass.name);
      if (objectData.primaryKey !== 'null' && isGenericObject(objectData)) {
        return bookmarkFactory.createObject(objectData, layout);
      }
    }
    return undefined;
  };

  const storePositionOnBookmark = useCallback(
    (position: number | undefined) => {
      if (isIObjectBookmark(currentBookmark)) {
        currentBookmark.pagingPositionAbsolute = position;
      }
    },
    [currentBookmark]
  );

  const setPositions = useCallback(
    async (helper: DataSourceHelper | undefined): Promise<void> => {
      if (helper && isIObjectBookmark(currentBookmark)) {
        const positionInfo = await helper.getItemPosition('primaryKey', currentBookmark.originalObject.primaryKey);
        if (positionInfo) {
          storePositionOnBookmark(positionInfo.absolutePosition);
          setPositionAbsolute(positionInfo.absolutePosition);
          setIsAbsoluteLastItem(positionInfo.isAbsoluteLast);
          return;
        }
      }

      storePositionOnBookmark(undefined);
      setPositionAbsolute(undefined);
      setIsAbsoluteLastItem(true);
    },
    [currentBookmark, storePositionOnBookmark]
  );

  const clearPagingInfo = useCallback(() => {
    setCachedBookmark(undefined);
    setTotalCount(undefined);
    setSourceHelper(undefined);
    setPositions(undefined).catch(logger.logError);
  }, [logger.logError, setPositions]);

  const previousIsListParent = (previous: IBookmark, current: IObjectBookmark): boolean => {
    return isIListBookmark(previous) && previous.metaClass.name === current.metaClass.name;
  };

  const relationToPrevious = (previous: IBookmark, current: IObjectBookmark): IMetaRelation | undefined => {
    if (isIObjectBookmark(previous)) {
      return previous.metaClass.relations.find(
        (relation: IMetaRelation) => relation.targetClass === current.metaClass.name && relation.cardinality === MetaCardinality.Multiple
      );
    }
    return undefined;
  };

  const createBasicSource = useCallback(
    (current: IObjectBookmark, originalSource: DataSource, originalLayout: IMetaLayout): DataSource => {
      // TODO: Create simpler source without expands
      const basicSource: DataSource = odataFactory.create(originalLayout, current.metaClass.name, {
        pageSize: originalSource.pageSize()
      });
      const originalSort = originalSource.sort();
      originalSort && basicSource.sort(originalSort);
      if (originalSource.filter()) {
        basicSource.filter(originalSource.filter());
      } else {
        const sourceStore = originalSource.store() as QuinoODataStore;
        const storeFilter = sourceStore.getLastLoadFilter ? sourceStore.getLastLoadFilter() : undefined;
        if (storeFilter) {
          basicSource.filter(storeFilter);
        }
      }

      const storedAbsolutePosition = current.pagingPositionAbsolute;
      if (storedAbsolutePosition) {
        const newPageIndex = Math.floor(storedAbsolutePosition / originalSource.pageSize());
        basicSource.pageIndex(newPageIndex);
      } else {
        basicSource.pageIndex(originalSource.pageIndex());
      }

      return basicSource;
    },
    [odataFactory]
  );

  const initNewSource = useCallback(
    async (previous: IBookmark, current: IObjectBookmark) => {
      setShowMiniLoader(true);
      let newSource: DataSource | undefined = undefined;

      const pagingContextListBookmark = pagingContextService.get();
      if (!isIListBookmark(previous) && pagingContextListBookmark && current.metaClass.name === pagingContextListBookmark.metaClass.name) {
        previous = pagingContextListBookmark;
      }

      if (isIListBookmark(previous) && previous.metaClass === current.metaClass) {
        const objectIsInSelection = previous.selection.find((x: IGenericObject) => x.primaryKey === current.originalObject.primaryKey) !== undefined;
        if (objectIsInSelection) {
          newSource = new DataSource({ store: previous.selection, pageSize: previous.selection.length + 1 });
          setTotalCount(previous.selection.length);
        } else if (previous.dataSource) {
          newSource = createBasicSource(current, previous.dataSource, previous.layout);
          setTotalCount(undefined);
        }
      } else if (isIObjectBookmark(previous)) {
        const validRelation = relationToPrevious(previous, current);
        if (validRelation) {
          // TODO: Create simpler source without expands
          const dataType = previous.metaClass.properties.find((x: IMetaProperty) => x.name === validRelation.sourceProperties[0])!.dataType;
          const sourceProp = previous.genericObject[validRelation.sourceProperties[0]];
          const value = dataType === DataType.Guid ? new Guid(sourceProp) : sourceProp;

          const listLayout = metadataTree.getLayout(current.metaClass.name, LayoutType.List);
          newSource = odataFactory.create(listLayout, current.metaClass.name, {
            filter: [validRelation.targetProperties[0], '=', value]
          });
        }
      }

      if (newSource) {
        try {
          const newHelper = new DataSourceHelper(newSource);
          await newHelper.init();
          await setPositions(newHelper);
          setSourceHelper(newHelper);
          setCachedBookmark(current);
        } catch (e) {
          clearPagingInfo();
        }
      } else {
        clearPagingInfo();
      }

      setShowMiniLoader(false);
    },
    [clearPagingInfo, createBasicSource, metadataTree, odataFactory, pagingContextService, setPositions]
  );

  useEffect(
    () => {
      if (isIObjectBookmark(currentBookmark) && !isNewObjectBookmark(currentBookmark)) {
        const previousBookmark: IBookmark | undefined = currentBookmark.parentBookmark
          ? currentBookmark.parentBookmark
          : bookmarkStack.length > 1
          ? bookmarkStack[bookmarkStack.length - 2]
          : undefined;
        if (previousBookmark) {
          if (
            sourceHelper &&
            cachedBookmark &&
            cachedBookmark.metaClass.name === currentBookmark.metaClass.name &&
            (previousIsListParent(previousBookmark, currentBookmark) || relationToPrevious(previousBookmark, currentBookmark))
          ) {
            setPositions(sourceHelper).catch(logger.logError);
            setCachedBookmark(currentBookmark);
            return;
          } else {
            initNewSource(previousBookmark, currentBookmark).catch(logger.logError);
            return;
          }
        }
      }
      clearPagingInfo();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [bookmarkStack, cachedBookmark, clearPagingInfo, currentBookmark, initNewSource, logger.logError, setPositions]
  );

  return (
    <div className='quino-ecui-paging'>
      {!positionAbsolute && showMiniLoader && <LoadIndicator height={18} width={18} />}
      {positionAbsolute !== undefined && (
        <>
          <Button
            className={'quino-ecui-paging-button'}
            stylingMode={'text'}
            icon={'material-icons-outlined first_page'}
            onClick={first}
            disabled={positionAbsolute === 0}
            hint={translationService.translate('Paging.FirstRecord')}
          />
          <Button
            className={'quino-ecui-paging-button'}
            stylingMode={'text'}
            icon={'material-icons-outlined chevron_left'}
            onClick={previous}
            disabled={positionAbsolute === 0}
            hint={translationService.translate('Paging.PreviousRecord')}
          />
          <div className={'quino-ecui-paging-position'}>
            {positionAbsolute + 1}
            {totalCount !== undefined ? ` / ${totalCount}` : undefined}
          </div>
          <Button
            className={'quino-ecui-paging-button'}
            stylingMode={'text'}
            icon={'material-icons-outlined chevron_right'}
            onClick={next}
            disabled={isAbsoluteLastItem}
            hint={translationService.translate('Paging.NextRecord')}
          />
          <Button
            className={'quino-ecui-paging-button'}
            stylingMode={'text'}
            icon={'material-icons-outlined last_page'}
            onClick={last}
            disabled={isAbsoluteLastItem}
            hint={translationService.translate('Paging.LastRecord')}
          />
        </>
      )}
    </div>
  );
}
