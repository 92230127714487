export const searchForErrors = (
  text: string,
  setter: (value: string[]) => void,
  checkFunctions: ((text: string) => { isValid: boolean; errorMessage: string })[]
): number => {
  let messages: string[] = [];

  for (const checkFunction of checkFunctions) {
    let result: { isValid: boolean; errorMessage: string } = checkFunction(text);

    if (!result.isValid) {
      messages.push(result.errorMessage);
    }
  }

  setter(messages);

  return messages.length;
};

export const validEmail = () => new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

export const validUrl = () => new RegExp('^((ht|f)tp(s?)://)' + '');
