import React, { ReactNode, useMemo } from 'react';
import { Button } from 'devextreme-react/button';
import { CUINavBarContextMenu, CUINavBarContextMenuTargetIdPrefix } from './CUINavBarContextMenu';
import { CUINavBarTreeView, CUINavBarTreeViewSearchEditorId } from './CUINavBarTreeView';
import { TabPanel } from 'devextreme-react/tab-panel';
import { CUINavBarListView } from './CUINavBarListView';
import { useService } from '@quino/ui';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { useCuiSettings } from '../../util';

export interface ICUINavBarProps {
  navBarExpanded: boolean;
  navBarExpandedCallback: (expanded: boolean) => void;
  useFavorites: boolean;
}

interface ITabItem {
  title: string;
  node: () => ReactNode;
  id: number;
}

export function CUINavBar(props: ICUINavBarProps) {
  return (
    <div className={`quino-ecui-nav-bar-wrapper ${!props.navBarExpanded ? 'is-narrowed' : ''}`}>
      {props.navBarExpanded ? <CUINavBarExpanded {...props} /> : <CUINavBarNarrowed {...props} />}
    </div>
  );
}

function CUINavBarNarrowed(props: ICUINavBarProps) {
  const cuiSettings = useCuiSettings();
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const favouriteButtonId = CUINavBarContextMenuTargetIdPrefix + 'favourite';
  const searchButtonId = CUINavBarContextMenuTargetIdPrefix + 'search';
  return (
    <>
      <div className='quino-ecui-nav-bar-top-button-wrapper'>
        <Button
          id={favouriteButtonId}
          key={'nav_favourites'}
          focusStateEnabled={false}
          hint={translationService.translate('NavigationBar.Favorites')}
          stylingMode={'text'}
          icon={'material-icons-outlined star_border'}
          visible={props.useFavorites}
        />
        <CUINavBarContextMenu ofId={favouriteButtonId} dataSource={[]} useFavorites={props.useFavorites} />
      </div>
      <div className={'quino-ecui-nav-bar-top-button-wrapper'}>
        <Button
          id={searchButtonId}
          key={'nav_search'}
          focusStateEnabled={false}
          hint={translationService.translate('SearchMenu')}
          stylingMode={'text'}
          icon={'material-icons-outlined search'}
          visible={cuiSettings.showMenuSearch}
          onClick={() => {
            props.navBarExpandedCallback(true);
            setTimeout(() => {
              const searchEditor = document.getElementById(CUINavBarTreeViewSearchEditorId);
              if (searchEditor) {
                const inputs = searchEditor.getElementsByTagName('input');
                inputs.length > 0 && inputs[0].focus();
              }
            }, 50);
          }}
        />
      </div>
      <CUINavBarTreeView {...props} />
    </>
  );
}

function CUINavBarExpanded(props: ICUINavBarProps) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const tabs = useMemo(() => {
    const tabItems: ITabItem[] = [
      {
        id: 0,
        title: translationService.translate('NavigationBar.Menu'),
        node: () => <CUINavBarTreeView {...props} />
      },
      {
        id: 1,
        title: translationService.translate('NavigationBar.Favorites'),
        node: () => <CUINavBarListView {...props} />
      }
    ];
    return tabItems;
  }, [props, translationService]);

  if (!props.useFavorites) {
    return <CUINavBarTreeView {...props} />;
  }
  return (
    <TabPanel
      deferRendering={false}
      className={`quino-tabs-container quino-ecui-nav-bar-tabs-container`}
      showNavButtons={true}
      items={tabs}
      itemKeyFn={(data) => data.id}
      itemRender={(tabItem: ITabItem) => tabItem.node()}
    />
  );
}
