import { inject, injectable } from 'inversify';
import { ILayoutFavorite, IFavoriteLayoutService } from './IFavoriteLayoutService';
import { IStorageService, PersistentStorageServiceSymbol } from '../storage';

@injectable()
export class FavoriteLayoutService implements IFavoriteLayoutService {
  constructor(@inject(PersistentStorageServiceSymbol) private readonly storageService: IStorageService) {}

  getFavoriteLayoutName(className: string): string | undefined {
    const favorite = this.favoriteSettings.find((x) => x.className.toLowerCase() === className.toLowerCase());
    return favorite ? favorite.layoutName : undefined;
  }

  async setFavoriteLayout(className: string, layoutName: string): Promise<void> {
    const updatedFavorites = this.favoriteSettings.filter((x) => x.className.toLowerCase() !== className.toLowerCase());
    updatedFavorites.push({ className: className, layoutName: layoutName });

    this.favoriteSettings = updatedFavorites;
    await this.storageService.set(this.favoriteSettingsKey, updatedFavorites);
  }

  async clearFavoriteLayout(className: string): Promise<void> {
    const updatedFavorites = this.favoriteSettings.filter((x) => x.className.toLowerCase() !== className.toLowerCase());

    this.favoriteSettings = updatedFavorites;
    await this.storageService.set(this.favoriteSettingsKey, updatedFavorites);
  }

  async loadInitialFavorites(): Promise<void> {
    const settings = await this.storageService.get(this.favoriteSettingsKey);
    this.favoriteSettings = Array.isArray(settings) ? (settings as ILayoutFavorite[]) : [];
  }

  private favoriteSettings: ILayoutFavorite[] = [];
  private readonly favoriteSettingsKey = 'LayoutFavorites';
}
