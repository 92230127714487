/**
 * Display mode of the application
 */
export enum TResponsiveMode {
  Phone = 'phone',
  Tablet = 'tablet',
  Desktop = 'desktop'
}

/**
 * Screen height mode of the application
 */
export enum TScreenHeight {
  Small = 'small',
  Normal = 'normal'
}

export const IResponsivityServiceSymbol = Symbol.for('IResponsivityService');

/**
 * Service used to get screen size infos
 */
export interface IResponsivityService {
  /**
   * Subscribe to changes in window width
   * @param callback The method to call when responsive mode changes
   */
  subscribeToWidthChanges(callback: (newMode: TResponsiveMode) => void): Symbol;

  /**
   * Unsubscribe from changes in window width
   * @param symbol The symbol of the subscription to remove
   */
  unsubscribeFromWidthChanges(symbol: Symbol): void;

  /**
   * Subscribe to changes in window height
   * @param callback The method to call when height mode changes
   */
  subscribeToHeightChanges(callback: (newMode: TScreenHeight) => void): Symbol;

  /**
   * Unsubscribe from changes in window height
   * @param symbol The symbol of the subscription to remove
   */
  unsubscribeFromHeightChanges(symbol: Symbol): void;

  /**
   * Get the current responsive mode
   */
  getCurrentResponsiveMode(): TResponsiveMode;

  /**
   * Get the current screen height mode
   */
  getCurrentHeightMode(): TScreenHeight;
}
