export const IThemeCustomizationServiceSymbol = Symbol.for('IThemeCustomizationService');

export type IThemeCustomization = {
  items: { [key: string]: string }[];
};

export interface IThemeCustomizationService {
  /**
   * Updates the theme color variables from theme customization Json File  file
   */
  updateColorVariables(): void;

  /**
   * Takes the main css path in index.html and replaces it with custom css file path taken from CUI settings => customizationCssFilePath
   */
  changeCssPath(): Promise<void>;

  /**
   * Get Customization Items
   */
  getCustomization(): IThemeCustomization;

  /**
   * Initialize
   */
  initialize(): Promise<void>;
}
