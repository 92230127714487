import { IRequestDecorator } from './IRequestDecorator';
import { IQuinoRequestOptions } from './IQuinoRequestOptions';

/**
 * Add some static headers to all requests that are leveraging the standard infrastructure.
 */
export class StaticRequestDecorator implements IRequestDecorator {
  decorate = (request: Request, _options?: IQuinoRequestOptions): void => {
    request.headers.set('cache', 'no-cache');
    request.headers.set('pragma', 'no-cache');
    request.headers.set('cache-control', 'no-cache');

    switch (request.method.toLowerCase()) {
      case 'post':
      case 'patch':
      case 'delete': {
        request.headers.set('redirect', 'follow');
        request.headers.set('referrer', 'no-referrer');
        request.headers.set('mode', 'cors');
        if (!request.headers.has('Content-Type')) {
          request.headers.set('Content-Type', 'application/json');
        }
      }
    }
  };
}
