import { IQuinoContext } from './IQuinoContext';
import { IContextDecorator } from '../ContextDecorator/IContextDecorator';

export const contextContextDecoratorSymbol = Symbol.for('ContextContextDecorator');

/**
 * Add context to global context.
 */
export class ContextContextDecorator implements IContextDecorator {
  public context: IQuinoContext | undefined = undefined;

  decorate(context: any): void {
    if (this.context) {
      try {
        context.context = JSON.parse(this.context.value);
      } catch {
        context.context = { value: context };
      }

      if (this.context.role) {
        context.context.role = this.context.role;
      } else if (this.context.value) {
        context.context.role = this.context.value;
      }
    }
  }
}
