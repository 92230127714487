import {
  extractSize,
  getAspectOrDefault,
  IExpression,
  IExpressionVisitor,
  IExpressionVisitorSymbol,
  IMetaElement,
  isExpression,
  isGenericIdentifierExpression,
  isIMetaProperty,
  ISizingAspect,
  isMetaPropertyExpression,
  isMetaRelation,
  isMetaRelationExpression,
  MetaCardinality,
  SizingAspectIdentifier
} from '@quino/core';
import { useService } from '../../ioc/hook';
import { useOnBookmark } from './BookmarkHooks';
import { IBookmark, IObjectBookmarkEvent } from '../../bookmarks';
import { useRerender } from './useRenderer';
import { useMemo } from 'react';

export const useOnDependencyChanged = (
  metaElement: IMetaElement,
  bookmark: IBookmark,
  callback?: (event: IObjectBookmarkEvent | undefined) => void
) => {
  const expressionVisitor = useService<IExpressionVisitor>(IExpressionVisitorSymbol);
  const rerender = useRerender();
  return useOnBookmark(
    bookmark,
    (event) => (callback ? callback(event) : rerender()),
    'changed',
    getDependentProperties(expressionVisitor, metaElement)
  );
};

export const getDependentProperties = (expressionVisitor: IExpressionVisitor, metaElement: IMetaElement): string[] => {
  const names: string[] = [];
  names.push(...getMetaPropertyNamesFromExpressionTree(expressionVisitor, metaElement.visible));
  names.push(...getMetaPropertyNamesFromExpressionTree(expressionVisitor, metaElement.enabled));
  names.push(...getMetaPropertyNamesFromExpressionTree(expressionVisitor, metaElement.readOnly));
  names.push(...getMetaPropertyNamesFromExpressionTree(expressionVisitor, metaElement.required));

  if (isIMetaProperty(metaElement)) {
    names.push(...getMetaPropertyNamesFromExpressionTree(expressionVisitor, metaElement.valueGenerator));
  }

  if (isMetaRelation(metaElement) && metaElement.cardinality !== MetaCardinality.Multiple) {
    names.push(metaElement.sourceProperties[0]);
  }

  return names.filter((v, i, a) => a.indexOf(v) === i);
};

export const getMetaPropertyNamesFromExpressionTree = (expressionVisitor: IExpressionVisitor, root: any, names: string[] = []): string[] => {
  if (isExpression(root)) {
    expressionVisitor.visit(root, (expr: IExpression) => {
      if (isMetaPropertyExpression(expr)) {
        names.push(expr.PropertyName);
      }

      if (isMetaRelationExpression(expr)) {
        names.push(expr.RelationName);
      }

      if (isGenericIdentifierExpression(expr)) {
        names.push(expr.name);
      }
    });
  }

  return names;
};

export const useSize = (element: IMetaElement): { width: string | undefined; height: string | undefined } => {
  return useMemo(() => {
    const aspect = getAspectOrDefault<ISizingAspect>(element, SizingAspectIdentifier);
    return {
      width: extractSize(aspect, 'horizontal'),
      height: extractSize(aspect, 'vertical')
    };
  }, [element]);
};
