import React, { PropsWithChildren } from 'react';
import { INavigationElement, INavigationGroup } from '@quino/core';
import { Menu } from 'devextreme-react/menu';

export interface IQuinoNavigationTreeProps {
  root: INavigationGroup;
  drilldown: (element: TNavigationItem) => void;
}

export type TNavigationItem = {
  text: string;
  items: TNavigationItem[];
  targetEntity?: string;
  origin: INavigationElement;
};

export function QuinoNavigationTree(props: PropsWithChildren<IQuinoNavigationTreeProps>) {
  const treeSource = loopTree(props.root);

  return (
    <Menu
      dataSource={treeSource[0].items}
      displayExpr={'text'}
      orientation={'vertical'}
      onItemClick={(e) => {
        const navItem = e.itemData as TNavigationItem;

        if (navItem && navItem.targetEntity != null) {
          props.drilldown(navItem);
        }
      }}
    />
  );
}

function loopTree(group: INavigationElement): TNavigationItem[] {
  const result: TNavigationItem[] = [];
  if (group.style === 'group') {
    const item: TNavigationItem = {
      text: group.caption,
      items: [],
      origin: group
    };
    result.push(item);
    const elements = (group as INavigationGroup).elements;

    if (elements && elements.length > 0) {
      for (const element of elements) {
        const childElements = loopTree(element);
        childElements.forEach((x) => {
          if (x.origin.style !== 'group') {
            item.targetEntity = x.targetEntity;
            // item.origin.style = x.origin.style;
          } else {
            item.items.push(x);
          }
        });
      }
    }
  } else {
    const item: TNavigationItem = {
      text: group.caption,
      items: [],
      origin: group,
      targetEntity: group.paramValue
    };
    result.push(item);
  }

  return result;
}
