import React from 'react';
import { CUIHeaderContextButtons, CUIRootView, CUIUserMenu } from '@quino/cui';
import { useService } from '@quino/ui';
import {
  IUrlHelper,
  IUserInfoService,
  IUserInfoServiceSymbol,
  ILogger,
  IRequestBuilder,
  ITranslationService,
  QuinoCoreServiceSymbols,
  ITranslationServiceSymbol
} from '@quino/core';

export function CommonUI() {
  const authService = useService<IUserInfoService>(IUserInfoServiceSymbol);
  const urlHelper = useService<IUrlHelper>(QuinoCoreServiceSymbols.IUrlHelper);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const requestBuilder = useService<IRequestBuilder>(QuinoCoreServiceSymbols.IRequestBuilder);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  return (
    <CUIRootView key={'root'} dashboard={<div>abc</div>} logo={'assets/encodo_logo.png'}>
      <CUIHeaderContextButtons
        adminMenu={{
          visible: authService.isInRole('admin'),
          additionalDataSource: [
            {
              text: translationService.translate('Header.AdminMenu.ResetDatabase'),
              icon: 'material-icons-outlined restart_alt',
              onItemClick: () => {
                requestBuilder.create(urlHelper.combineUrlParts('api/v1/data/reset'), 'get').fetch().catch(logger.logError);
              }
            }
          ]
        }}
      />
      <CUIUserMenu key={4} />
    </CUIRootView>
  );
}
