import { usePromise } from '../../components/Util/PromiseReducer';
import { ICalendarConfiguration, ICalendarConfigurationSymbol } from './ICalendarConfiguration';
import { ICalendarAspect } from '../aspect';
import { ICalendarConfigurationService, ICalendarConfigurationServiceSymbol } from './ICalendarConfigurationService';
import { useService } from '../../ioc';

export function useCalendarConfiguration(aspect: ICalendarAspect): ICalendarConfiguration {
  const calendarConfigurationService = useService<ICalendarConfigurationService>(ICalendarConfigurationServiceSymbol);
  const defaultConfiguration = useService<ICalendarConfiguration>(ICalendarConfigurationSymbol);

  const [calendarConfiguration] = usePromise<ICalendarConfiguration, any>(
    async () => calendarConfigurationService.getCalendarConfiguration(aspect),
    [aspect]
  );

  return calendarConfiguration ? calendarConfiguration : defaultConfiguration;
}
