/**
 * Messenger symbol.
 */
export const IMessengerSymbol = Symbol.for('IMessenger');

/**
 * Messenger for loosely coupling communication across components.
 */
export interface IMessenger {
  /**
   * Notify all subscribers of the given message.
   * @param payload - the message to deliver.
   */
  notify(payload: IMessage): void;

  /**
   * Subscribe to the given message.
   * @param symbol - the symbol to subscribe to.
   * @param callback - the callback to launch.
   */
  subscribe<TPayload extends IMessage>(symbol: symbol, callback: (payload: TPayload) => void): symbol;

  /**
   * Unsubscribe from the given message.
   * @param symbol - the symbol to use.
   */
  unsubscribe(symbol: symbol): void;
}

/**
 * Base interface for all messages.
 */
export interface IMessage {
  /**
   * The symbol to identify the message type.
   */
  symbol: symbol;
}
