import { IPreparedRequest } from './IPreparedRequest';
import {
  httpMethods,
  IAuthenticationFeedback,
  IAuthenticationService,
  INotificationService,
  IODataBatchRequest,
  IODataBatchResponse,
  IRequestBuilder,
  IUrlManager
} from '..';
import { UnclassifiedServerErrorMapper } from '../error';
import { IResponseHandler } from './IResponseHandler';

export class PreparedRequest implements IPreparedRequest {
  constructor(
    private readonly responseHandlers: IResponseHandler[],
    private readonly authenticationService: IAuthenticationService,
    private readonly authenticationFeedback: IAuthenticationFeedback,
    private readonly notificationService: INotificationService,
    private readonly urlManager: IUrlManager,
    private readonly requestBuilder: IRequestBuilder,
    private readonly request: Request,
    private requiresAuth: boolean = false,
    private payload: any = null,
    private readonly maxUrlLength = 1000
  ) {}

  setHeader = (name: string, value: string): IPreparedRequest => {
    this.request.headers.set(name, value);
    return this;
  };

  setJsonPayload = (data: any): IPreparedRequest => {
    if (this.request.method.toLowerCase() === 'get') {
      throw new Error('Get requests cannot have a payload.');
    }

    this.request.headers.set('Content-Type', 'application/json');

    this.payload = JSON.stringify(data);
    return this;
  };

  setPayload = (data: any): IPreparedRequest => {
    if (this.request.method.toLowerCase() === 'get') {
      throw new Error('Get requests cannot have a payload.');
    }
    if (data instanceof FormData) {
      this.request.headers.delete('Content-Type'); // delete the header because fetch should automatically set it if its not set
    }

    this.payload = data;
    return this;
  };

  requiresAuthentication = (): IPreparedRequest => {
    this.requiresAuth = true;

    return this;
  };

  fetchImage = async (): Promise<Blob> => {
    const response = await this.fetch('image/*');
    return response.blob();
  };

  fetchJson = async <TResponse>(): Promise<TResponse> =>
    this.fetch('application/json').then(async (r) => {
      if (r.ok) {
        try {
          return await r.json();
        } catch {
          throw UnclassifiedServerErrorMapper.createUnclassifiedError();
        }
      } else {
        const t = await r.text();

        if (t.length == 0) {
          throw UnclassifiedServerErrorMapper.createUnclassifiedError();
        }
        throw new Error(t);
      }
    });

  fetch = async (acceptMimeTypes?: string): Promise<Response> => {
    if (this.requiresAuth) {
      this.authenticationService.authenticateRequest(this.request);
    }

    if (acceptMimeTypes) {
      this.request.headers.set('Accept', acceptMimeTypes);
    }

    try {
      let response = await this.sendRequest();

      if (response.status === 401 && this.requiresAuth) {
        await new Promise<void>((resolve) => this.authenticationFeedback.requestLogin(resolve));
        this.authenticationService.authenticateRequest(this.request);
        response = await this.sendRequest();
      }

      response = await this.applyResponseHandlers(response);

      return response;
    } catch (e) {
      // Prevent HealthCheck to trigger itself
      if (!this.request.url.includes(this.urlManager.getServerHealthCheckUrl())) {
        this.notificationService.notify({ message: e.message });
      }
      throw e;
    }
  };

  private static toResponse(r: IODataBatchResponse): Response {
    return new Response(typeof r.body === 'string' ? r.body : JSON.stringify(r.body), {
      status: r.status,
      headers: r.headers
    });
  }

  private async sendRequest() {
    return this.request.url.length > this.maxUrlLength
      ? this.requestBuilder
          .create(this.urlManager.getODataBatchUrl(), 'post')
          .setJsonPayload({ requests: [this.toOdataBatchRequest()] })
          .requiresAuthentication()
          .fetch('application/json')
          .then(async (response) => response.json())
          .then((r) => PreparedRequest.toResponse(r.responses[0]))
      : window.fetch(this.request, { body: this.payload });
  }

  private async applyResponseHandlers(response: Response) {
    let reducedResponse = response;
    for (const responseHandler of this.responseHandlers) {
      reducedResponse = await responseHandler.handleResponse(this, reducedResponse);
    }

    return reducedResponse;
  }

  private toOdataBatchRequest(): IODataBatchRequest {
    return {
      body: this.payload ?? undefined,
      method: this.request.method as httpMethods,
      url: this.request.url.substr(this.request.url.indexOf('/odata')),
      headers: this.request.headers,
      id: Math.random().toString()
    };
  }
}
