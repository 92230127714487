import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import DataSource from 'devextreme/data/data_source';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import dxDropDownBox from 'devextreme/ui/drop_down_box';
import Guid from 'devextreme/core/guid';
import cloneDeep from 'lodash/cloneDeep';
import { QuinoLabeled } from '../QuinoLabeled';
import { QuinoMetaClassSelector } from '../QuinoMetaControls';
import {
  getMetaProperty,
  ILogger,
  IMetaClass,
  IMetadataTree,
  ITitleCalculator,
  ITitleCalculatorSymbol,
  ITranslationService,
  ITranslationServiceSymbol,
  LayoutType,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { QuinoUIServiceSymbols, useService } from '../../ioc';
import { IBookmarkFactory, IListBookmark, ObjectBookmark } from '../../bookmarks';
import { IQuinoComponentProps } from '../Types';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../../data';
import { useMetaPropertyValueState } from '../Util';
import { IQuinoDataGridConfigurationService, QuinoMetaDataGrid } from '../QuinoDataGrid';

export function EntitySelector(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const sourceFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const titleCalculator = useService<ITitleCalculator>(ITitleCalculatorSymbol);
  const metaDataGridConfigurationService = useService<IQuinoDataGridConfigurationService>(QuinoUIServiceSymbols.IQuinoDataGridConfigurationService);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const dataGridOptions = metaDataGridConfigurationService.getSearchEditGridConfiguration();

  const metaProperty = getMetaProperty(props.element);
  const [bookmarkValue, setBookmarkValue] = useMetaPropertyValueState<any>(metaProperty, props.bookmark);
  const [currentClass, setCurrentClass] = useState<IMetaClass | undefined>();
  const [currentId, setCurrentId] = useState<any>();
  const [listBookmark, setListBookmark] = useState<IListBookmark | undefined>(undefined);
  const [dataSource, setDataSource] = useState<DataSource>(new DataSource([]));
  const [dropdownComponent, setDropdownComponent] = useState<dxDropDownBox | undefined>(undefined);

  const updateClass = (metaClass: IMetaClass | undefined) => {
    if (metaClass?.name !== currentClass?.name) {
      clearSources();
      setCurrentId(undefined);
      setCurrentClass(metaClass);
      setBookmarkValue(null);
      metaClass && loadSources(metaClass);
    }
  };

  const updateId = (id: any) => {
    if (id && currentClass) {
      setCurrentId(id);
      setBookmarkValue(JSON.stringify({ class: currentClass.name, id: id }));
    } else {
      setCurrentId(undefined);
      setBookmarkValue(null);
    }
  };

  const clearSources = () => {
    setDataSource(new DataSource([]));
    setListBookmark(undefined);
  };

  const loadSources = useCallback(
    (metaClass: IMetaClass) => {
      // Make sure no filters from the original layouts are applied
      const namelessTitleLayout = cloneDeep(metadataTree.getLayout(metaClass.name, LayoutType.Title));
      namelessTitleLayout.name = '';

      const listLayouts = metadataTree.getLayouts(metaClass.name).filter((l) => l.type === LayoutType.List);
      const contextLayout = listLayouts.find((l) => l.name.toLowerCase() === 'contextlayout');
      const namelessListLayout = cloneDeep(contextLayout || metadataTree.getLayout(metaClass.name, LayoutType.List));
      namelessListLayout.name = '';

      setDataSource(sourceFactory.create(namelessTitleLayout, metaClass.name));
      bookmarkFactory
        .createList(metaClass.name, namelessListLayout)
        .then((result) => setListBookmark(result))
        .catch((e) => {
          const message = 'Failed to load sources for Entity selector';
          logger.logError(`${message} ${JSON.stringify(e)}`);
        });
    },
    [bookmarkFactory, logger, metadataTree, sourceFactory]
  );

  const displayExpression = useCallback(
    (item) => (currentClass ? titleCalculator.generate(item, currentClass.name) : ''),
    [currentClass, titleCalculator]
  );

  useEffect(() => {
    const parsedValue = typeof bookmarkValue === 'string' ? JSON.parse(bookmarkValue) : undefined;
    const parsedMetaClass = parsedValue ? metadataTree.getClass(parsedValue.class) : undefined;
    if (parsedMetaClass) {
      if (parsedMetaClass.name !== currentClass?.name) {
        setCurrentId(undefined);
        clearSources();
        setCurrentClass(parsedMetaClass);
        loadSources(parsedMetaClass);
      }
      setCurrentId(parsedValue.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarkValue, loadSources, metadataTree]);

  return (
    <>
      <QuinoLabeled label={translationService.translate('EntitySelector.SelectClass')} className={'quino-input-fields-max-width'}>
        <QuinoMetaClassSelector onSelect={updateClass} value={currentClass?.name} />
      </QuinoLabeled>

      {listBookmark && (
        <QuinoLabeled label={translationService.translate('EntitySelector.SelectObject')} className={'quino-input-fields-max-width'}>
          <DropDownBox
            dropDownOptions={{ height: '340px', width: '800px', maxWidth: '95vw' }}
            key={'entity-selector-dropdown'}
            onInitialized={(e) => setDropdownComponent(e.component)}
            dataSource={dataSource}
            value={currentId}
            displayExpr={displayExpression}
            deferRendering={false}
            showClearButton={true}
            openOnFieldClick={true}
            placeholder={translationService.translate('Select')}
            onValueChanged={(e) => e.value === null && updateId(e.value)}
            contentRender={() => (
              <QuinoMetaDataGrid
                bookmark={listBookmark}
                visible={true}
                gridProperties={{
                  className: 'quino-search-edit-data-grid',
                  ...dataGridOptions
                }}
                drilldown={(target) => {
                  const idPath = metadataTree.getClass(target.metaClass).primaryKey[0];
                  const newId =
                    typeof target[idPath] === 'string' || typeof target[idPath] === 'number' ? target[idPath] : new Guid(target[idPath]).valueOf();
                  updateId(newId);
                  dropdownComponent!.close();
                }}
              />
            )}
          />
        </QuinoLabeled>
      )}
    </>
  );
}
