import React, { PropsWithChildren } from 'react';
import { Button } from 'devextreme-react/button';
import { useService } from '@quino/ui';
import { IRequestBuilder, IUrlManager, QuinoCoreServiceSymbols } from '@quino/core';

export function CUIRestartServerButton(props: PropsWithChildren<{ visible: boolean }>) {
  const requestBuilder = useService<IRequestBuilder>(QuinoCoreServiceSymbols.IRequestBuilder);
  const urlManager = useService<IUrlManager>(QuinoCoreServiceSymbols.IUrlManager);

  return (
    <Button
      icon={'material-icons-outlined update'}
      visible={props.visible}
      stylingMode={'text'}
      onClick={async () => {
        await requestBuilder
          .create(urlManager.getServerRestartUrl() + '/1', 'get')
          .requiresAuthentication()
          .fetch();
      }}
    />
  );
}
