import React, { PropsWithChildren, useMemo } from 'react';
import { IPopupOptions, Popup } from 'devextreme-react/popup';
import { IBookmark } from '../../bookmarks';

export const PopupContext: { bookmarks: IBookmark[] } = { bookmarks: [] };

export interface IQuinoPopupOptions extends IPopupOptions {
  bookmark: IBookmark | undefined;
}

/**
 * Standard popup component used in UI.
 * Defaults are set for a modal dialog with 480px width and auto height.
 */
export function QuinoPopup(props: PropsWithChildren<Partial<IQuinoPopupOptions>>) {
  const popupAttributes = useMemo(() => {
    return {
      class: props.className || 'quino-popup'
    };
  }, [props.className]);

  return (
    <Popup
      {...props}
      onHiding={(e) => {
        props.onHiding && props.onHiding(e);
      }}
      onHidden={(e) => {
        PopupContext.bookmarks.pop();
        props.onHidden && props.onHidden(e);
      }}
      onShown={(e) => {
        if (props.bookmark) {
          PopupContext.bookmarks.push(props.bookmark);
        }
        props.onShown && props.onShown(e);
      }}
      width={props.width || '480px'}
      height={props.height || 'auto'}
      minWidth={props.minWidth || '240px'}
      animation={props.animation || undefined}
      wrapperAttr={popupAttributes}
      showCloseButton={props.showCloseButton || false}
      closeOnOutsideClick={props.closeOnOutsideClick || false}
      tabIndex={-1}
    />
  );
}
