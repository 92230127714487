import { inject, injectable } from 'inversify';
import { INotificationService } from './INotificationService';
import { INotification, TNotificationArea } from '../INotification';
import { ILogger } from '../../logging';
import { QuinoCoreServiceSymbols } from '../../ioc';
import {
  INotificationConfiguration,
  INotificationConfigurationService,
  INotificationConfigurationServiceSymbol,
  INotificationConfigurationSymbol
} from '../configuration';

interface INotificationHandler {
  area: TNotificationArea;
  callback: (notification: INotification[]) => void;
}

@injectable()
export class NotificationService implements INotificationService {
  constructor(
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(INotificationConfigurationSymbol) private config: INotificationConfiguration,
    @inject(INotificationConfigurationServiceSymbol) configurationService: INotificationConfigurationService
  ) {
    configurationService
      .getConfiguration()
      .then((config) => {
        if (config) {
          this.config = config;
        }
      })
      .catch(logger.logError);
  }

  subscribeToNotification = (area: TNotificationArea, callback: (notification: INotification[]) => void): Symbol => {
    const symbol = Symbol();
    this.notificationHandlers.set(symbol, { area: area, callback: callback });
    return symbol;
  };

  unsubscribeFromNotification = (symbol: Symbol): void => {
    this.notificationHandlers.delete(symbol);
  };

  clearNotification = (symbol: Symbol) => {
    if (this.notifications.has(symbol)) {
      const area = this.notifications.get(symbol)!.area ?? 'default';
      this.notifications.delete(symbol);
      let notifications = this.filterNotifications(area);
      this.filterHandlers(area).forEach((handler) => handler.callback(notifications));
    }
  };

  clearNotifications = (symbols: Symbol[]) => {
    const areas: TNotificationArea[] = [];

    // Remove notifications and collect areas
    for (const symbol of symbols) {
      if (this.notifications.has(symbol)) {
        const area = this.notifications.get(symbol)!.area ?? 'default';
        this.notifications.delete(symbol);
        if (!areas.includes(area)) {
          areas.push(area);
        }
      }
    }

    // Notify updated areas
    for (const area of areas) {
      let notifications = this.filterNotifications(area);
      this.filterHandlers(area).forEach((handler) => handler.callback(notifications));
    }
  };

  clearNotificationArea = (area: TNotificationArea) => {
    this.filterNotifications(area).forEach((notification) => this.notifications.delete(notification.symbol!));
    this.filterHandlers(area).forEach((handler) => handler.callback([]));
  };

  clearAllNotifications = () => {
    this.notifications.clear();
    Array.from(this.notificationHandlers).forEach(([, handler]) => handler.callback([]));
  };

  notify = (notificationParam: INotification, predecessor?: Symbol): Symbol => {
    const symbol = predecessor ?? notificationParam.symbol ?? Symbol(Math.random());
    const notification: INotification =
      notificationParam.type != null || notificationParam.message == null ? notificationParam : { ...notificationParam, type: 'error' };

    // Clear timeout of previous notification if present
    if (this.timeouts.has(symbol)) {
      clearTimeout(this.timeouts.get(symbol));
      this.timeouts.delete(symbol);
    }

    // Clear previous notification if present
    if (this.notifications.has(symbol)) {
      this.clearNotification(symbol);
    }

    // If no area specified, use default area instead
    if (!notification.area) {
      notification.area = 'default';
    }

    // Get notification handlers for specified area
    let handlersToNotify = this.filterHandlers(notification.area);

    // If no handlers available for the given area, notify in global area instead
    if (handlersToNotify.length === 0 && notification.area !== 'global') {
      notification.area = 'global';
      handlersToNotify = this.filterHandlers('global');
    }

    if (handlersToNotify.length > 0) {
      notification.symbol = symbol;
      this.notifications.set(symbol, notification);
      const notifications = this.filterNotifications(notification.area ?? 'default');
      handlersToNotify.forEach((handler) => handler.callback(notifications));
    } else {
      this.logger.logError(
        `Notification issued for area [${notification.area || 'default'}], but no subscriber available for ${
          notification.area === 'global' ? 'this' : 'this or global'
        } area.\nOriginal message: [${notification.message + ' ' + (notification.messageDetails || '')}]`
      );
    }

    if (notification.autoDisappear) {
      this.timeouts.set(
        symbol,
        setTimeout(() => {
          this.clearNotification(symbol);
          this.timeouts.delete(symbol);
        }, this.config.infoBarDisplayTime)
      );
    }

    return symbol;
  };

  private readonly filterHandlers = (area: TNotificationArea): INotificationHandler[] => {
    const handlerArray = Array.from(this.notificationHandlers, ([, handler]) => handler);
    return handlerArray.filter((handler) => handler.area === area);
  };

  private readonly filterNotifications = (area: TNotificationArea): INotification[] => {
    return Array.from(this.notifications, ([, notification]) => notification).filter((notification) => notification.area === area);
  };

  private readonly notificationHandlers: Map<Symbol, INotificationHandler> = new Map();
  private readonly notifications: Map<Symbol, INotification> = new Map();
  private readonly timeouts: Map<Symbol, number> = new Map();
}
