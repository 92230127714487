import { inject, injectable } from 'inversify';
import { IExportExcelService } from './IExportExcelService';
import { QuinoCoreServiceSymbols } from '../../../../ioc';
import { IExcelMapper, IExcelMapperProvider } from '../mapping';
import { IGenericObject } from '../../../../data';
import { IMetaElement, IMetaLayout, isIMetaGroup } from '../../../../meta';
import { IQuinoExcelFile, QuinoExcelFile } from '../model';
import { Workbook } from 'exceljs';
import { IExportFileService } from '../../common';
import { ILogger } from '../../../../logging';
import { IExportExcelConfigurationService, IExportExcelSettings } from '../settings';

@injectable()
export class ExportExcelService implements IExportExcelService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IExcelMapperProvider) private readonly provider: IExcelMapperProvider,
    @inject(QuinoCoreServiceSymbols.IExportFileService) private readonly exportFileService: IExportFileService,
    @inject(QuinoCoreServiceSymbols.IExportExcelConfigurationService)
    private readonly excelExportSettingsService: IExportExcelConfigurationService,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger
  ) {}

  async export(genericObject: IGenericObject, layout: IMetaLayout) {
    if (genericObject === undefined || layout === undefined) {
      return;
    }
    const excelFile = new QuinoExcelFile(new Workbook());
    await this.map(genericObject, layout, excelFile);
    const settings = await this.excelExportSettingsService.getExportSettings(null);
    if ((await this.excelExportSettingsService.getExportSettings(null)).printCreationTimeStamp) {
      ExportExcelService.addCreationTimeStamp(excelFile, settings);
    }
    await this.exportFileService.exportFile(excelFile);
  }

  private async map(genericObject: IGenericObject, layout: IMetaElement, excelFile: IQuinoExcelFile): Promise<void> {
    const mapper = this.provider.getInstances().find((mapper: IExcelMapper) => {
      return mapper.canMap(layout);
    });
    if (mapper !== undefined) {
      this.logger.logDebug(`Mapper: ${mapper.constructor.name} for element: ${layout.name}`);
      await mapper.map(genericObject, layout, excelFile);
    } else {
      this.logger.logDebug(`Could not map element: ${JSON.stringify(layout.name)}, element will be skipped`);
    }

    if (isIMetaGroup(layout) && !mapper?.canMapChildren(layout)) {
      for (const childElement of layout.elements) {
        await this.map(genericObject, childElement, excelFile);
      }
    }
  }

  private static addCreationTimeStamp(excelFile: IQuinoExcelFile, settings: IExportExcelSettings): void {
    excelFile.getActiveWorksheet().addRow([]);
    excelFile.getActiveWorksheet().addRow([]);
    const timeStampRow = excelFile
      .getActiveWorksheet()
      .addRow([`Created at: ${new Date().toLocaleDateString()}, ${new Date().toLocaleTimeString()}`]);
    timeStampRow.font = {
      name: settings.fontName,
      size: 8,
      bold: false
    };
  }
}
