import { HelpTextVisibility } from './HelpTextVisibility';
import { IHelpTextVisibilityStrategy } from './IHelpTextVisibilityStrategy';
import { injectable } from 'inversify';

/**
 * Implements @see IHelpTextVisibilityStrategy for global handling of help-text visibility.
 * This is the default implementation of @see IHelpTextVisibilityStrategy.
 */
@injectable()
export class GlobalHelpTextVisibilityStrategy implements IHelpTextVisibilityStrategy {
  constructor(private readonly globalHelpTextVisibility: HelpTextVisibility = HelpTextVisibility.Never) {}

  async getHelpTextVisibilityAsync(): Promise<HelpTextVisibility> {
    return this.globalHelpTextVisibility;
  }
}
