import { getMetaProperty } from '@quino/core';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { QuinoLabeled } from '../QuinoLabeled';
import { useValidation } from '../Util';
import { useLabelSettings } from '../../settings';
import { useMetadata } from '../../settings/useMetadata';
import { useRerenderOnChanges } from '../Util/MetaPropertyHooks';
import { QuinoDropDown } from './QuinoDropDown';

export function QuinoDropDownLabeled(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const { bookmark } = props;
  const metaProperty = getMetaProperty(props.element);
  const { description, caption, instruction } = metaProperty;

  const { visible, required } = useMetadata(metaProperty, bookmark.genericObject);
  const [, errorMessages] = useValidation(bookmark, metaProperty);
  const labelSettings = useLabelSettings(props.element);

  useRerenderOnChanges(bookmark, metaProperty);

  return (
    <QuinoLabeled
      visible={visible}
      className={'quino-input-fields-max-width'}
      label={caption}
      required={required}
      description={description}
      instruction={instruction}
      errorMessages={errorMessages}
      labelSettings={labelSettings}>
      <QuinoDropDown metaProperty={metaProperty} bookmark={bookmark} />
    </QuinoLabeled>
  );
}
