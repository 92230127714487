import { IBookmark, isIObjectBookmark } from '../../bookmarks';
import { INavigationLinkResolver } from './INavigationLinkResolver';
import { IExpressionEvaluator, INavigationLinkTarget, isExpression, isINavigationLinkTargetAbsolute, QuinoCoreServiceSymbols } from '@quino/core';
import { inject, injectable } from 'inversify';

export const AbsoluteNavigationLinkResolverSymbol = Symbol.for('AbsoluteNavigationLinkResolver');

@injectable()
export class AbsoluteNavigationLinkResolver implements INavigationLinkResolver {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly expressionEvaluator: IExpressionEvaluator) {}

  generateOnClickCallback(target: INavigationLinkTarget, currentBookmark?: IBookmark, openInNewTab?: boolean): (() => void) | undefined {
    if (isINavigationLinkTargetAbsolute(target)) {
      return () => {
        const url = isExpression(target.url)
          ? this.expressionEvaluator.evaluate<string>(target.url, isIObjectBookmark(currentBookmark) ? currentBookmark.genericObject : undefined)
          : target.url;

        window.open(url, openInNewTab ? '_blank' : '_self');
      };
    }

    return undefined;
  }
}
