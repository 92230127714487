import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for retrieving maximum length aspect.
 */
export const MaximumLengthAspectIdentifier = 'MaximumLength';

/**
 * Maximum length aspect containing the identifier for the maximum length to use.
 */
export interface IMaximumLengthAspect extends IMetaAspect {
  maximumSize: number;
  message: string;
}
