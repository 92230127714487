import { useMemo } from 'react';
import { useService } from '../../ioc/hook';
import {
  getAspectOrDefault,
  getMetaRelation,
  ILayoutResolver,
  ILayoutResolverSymbol,
  IMetaAspect,
  IMetaLayout,
  IMetaProperty,
  IMetaRelation,
  IOptionValue,
  isValueList,
  IValueListAspect,
  LayoutType,
  ODataDefaultData,
  SortedValueListAspectIdentifier,
  ValueListAspectIdentifier
} from '@quino/core';
import DataSource from 'devextreme/data/data_source';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../../data/IODataSourceFactory';

export function useValueList(property: IMetaProperty): IOptionValue[] {
  const aspect = getAspectOrDefault<IValueListAspect>(property, ValueListAspectIdentifier);
  const sortAspect = getAspectOrDefault<IMetaAspect>(property, SortedValueListAspectIdentifier);
  if (aspect == null) {
    throw new Error(`Use value list can only be used if a ValueListAspect is present.`);
  }
  return getAspectValues(aspect, sortAspect);
}

export function useValueListSource(property: IMetaRelation, layout: IMetaLayout, defaultData?: ODataDefaultData): DataSource {
  const oDataSourceFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  return oDataSourceFactory.create(layout, property.targetClass, { pageSize: 10, paginate: true }, defaultData);
}

export function useValueListDataSource(metaProperty: IMetaProperty, layoutType: LayoutType, defaultData?: ODataDefaultData) {
  const oDataSourceFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const aspect = getAspectOrDefault<IValueListAspect>(metaProperty, ValueListAspectIdentifier);
  const sortAspect = getAspectOrDefault<IMetaAspect>(metaProperty, SortedValueListAspectIdentifier);

  return useMemo(() => {
    if (isValueList(metaProperty)) {
      if (aspect == null) {
        throw new Error(`Use value list can only be used if a ValueListAspect is present.`);
      }

      return new DataSource(getAspectValues(aspect, sortAspect));
    }

    const metaClass = getMetaRelation(metaProperty).targetClass;
    const layout = layoutResolver.resolveSingle({
      metaClass: metaClass,
      type: layoutType,
      element: metaProperty
    });
    return oDataSourceFactory.create(layout, metaClass, { pageSize: 10, paginate: true }, defaultData);
  }, [metaProperty, layoutResolver, layoutType, oDataSourceFactory, defaultData, aspect, sortAspect]);
}

const getAspectValues = (aspect: IValueListAspect, sortAspect: IMetaAspect | null) =>
  sortAspect ? aspect.options.sort((a, b) => a.Caption.localeCompare(b.Caption)) : aspect.options;
