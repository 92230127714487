import { IQuinoShortcut } from './IQuinoShortcutSettings';
import { IRegisteredShortcutSettings } from './ShortcutService';

export const IRegisterShortcutServiceSymbol = Symbol.for('IRegisterShortcutService');

export interface IRegisteredShortcut {
  shortcut: IQuinoShortcut;
  action: () => void;
  settings: IRegisteredShortcutSettings;
}

export interface IRegisterShortcutService {
  /**
   * Register a global or popup keyboard shortcut
   */
  registerShortcut(shortcuts: IQuinoShortcut[], action: () => void, settings: IRegisteredShortcutSettings): Symbol[];

  /**
   * Unregister a global or popup keyboard shortcut
   */
  unregisterShortcut(symbols?: Symbol[]): void;

  /**
   * Gets the registered shortcuts
   */
  getRegisteredShortcuts(): IRegisteredShortcut[];
}
