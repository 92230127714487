import { inject, injectable } from 'inversify';
import { IConfigurationService, IConfigurationServiceSymbol } from '@quino/core';
import { IDashboardConfigurationService } from './IDashboardConfigurationService';
import { IDashboardConfiguration, IDashboardConfigurationSymbol } from './IDashboardConfiguration';

@injectable()
export class DashboardConfigurationService implements IDashboardConfigurationService {
  constructor(
    @inject(IConfigurationServiceSymbol) private readonly configService: IConfigurationService,
    @inject(IDashboardConfigurationSymbol) private readonly defaultSettings: IDashboardConfiguration
  ) {}

  async getDashboardConfiguration(): Promise<IDashboardConfiguration> {
    return this.configService.apply(this.defaultSettings, 'DashboardConfiguration');
  }
}
