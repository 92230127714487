import { IGenericObject } from '@quino/core/dist/data';

export const IGenericObjectPersistenceServiceSymbol = Symbol.for('IGenericObjectPersistenceService');

export interface IGenericObjectPersistenceService {
  /**
   * Saves a generic object
   * @param updated - the updated data
   * @param original - the original data
   */
  save(updated: IGenericObject, original?: IGenericObject): Promise<IGenericObject>;

  /**
   * Deletes one or multiple generic objects
   * @param data
   */
  delete(data: IGenericObject | IGenericObject[]): Promise<boolean>;
}
