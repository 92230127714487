import { ExcelMapperBase } from './ExcelMapperBase';
import { IMetaElement, isIMetaGroup } from '../../../../../meta';
import {
  IGenericObject,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  ITitleCalculator,
  ITitleCalculatorSymbol
} from '../../../../../data';
import { IQuinoExcelCell, IQuinoExcelCellBuilder, IQuinoExcelRow } from '../../model';
import { IQuinoExcelCellBuilderSymbol, IQuinoExcelRowBuilder, IQuinoExcelRowBuilderSymbol } from '../../builder';
import { IExpressionEvaluator, IVisibleCalculator } from '../../../../../expressions';
import { IExportExcelConfigurationService } from '../../settings';
import { IFormatStringService } from '../../../../formatting';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../../../../ioc';
import { IExcelMapperProvider } from '../IExcelMapperProvider';
import { IExcelMapper } from '../IExcelMapper';

export const ColumnsMapperSymbol = Symbol.for('ColumnsMapper');

@injectable()
export class ColumnsMapper extends ExcelMapperBase {
  constructor(
    @inject(IQuinoExcelRowBuilderSymbol) rowBuilder: IQuinoExcelRowBuilder,
    @inject(IQuinoExcelCellBuilderSymbol) cellBuilder: IQuinoExcelCellBuilder,
    @inject(QuinoCoreServiceSymbols.IVisibleCalculator) visibleCalculator: IVisibleCalculator,
    @inject(QuinoCoreServiceSymbols.IExportExcelConfigurationService)
    excelExportConfigurationService: IExportExcelConfigurationService,
    @inject(QuinoCoreServiceSymbols.IFormatStringService) formatStringService: IFormatStringService,
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) expressionEvaluator: IExpressionEvaluator,
    @inject(QuinoCoreServiceSymbols.IExcelMapperProvider) protected mapperProvider: IExcelMapperProvider,
    @inject(IMetaPropertyValueServiceSymbol) metaPropertyValueService: IMetaPropertyValueService,
    @inject(ITitleCalculatorSymbol) titleCalculator: ITitleCalculator
  ) {
    super(
      rowBuilder,
      cellBuilder,
      visibleCalculator,
      excelExportConfigurationService,
      formatStringService,
      expressionEvaluator,
      metaPropertyValueService,
      titleCalculator
    );
  }

  conditionMatches(element: IMetaElement, _genericObject: IGenericObject): boolean {
    return isIMetaGroup(element);
  }

  async getData(genericObject: IGenericObject, element: IMetaElement): Promise<IQuinoExcelRow[]> {
    // since we have cols
    // get all rows for each col and merge

    const allRows: IQuinoExcelRow[][] = [];
    if (isIMetaGroup(element)) {
      for (const child of element.elements) {
        allRows.push(await this.handleChildren(genericObject, child));
      }
    }

    return this.mergeRows(allRows);
  }

  async mergeRows(allCols: IQuinoExcelRow[][]): Promise<IQuinoExcelRow[]> {
    const defaultSettings = await this.excelExportConfigurationService.getExportSettings(null);
    const rows: IQuinoExcelRow[] = [];
    const maxlength = allCols.reduce((previousValue, currentValue) => Math.max(previousValue, currentValue.length), 0);

    for (let i = 0; i < maxlength; i++) {
      let cells: IQuinoExcelCell[] = [];
      let marginTop = 0;
      for (let j = 0; j < allCols.length; j++) {
        if (allCols[j].length > i) {
          cells = cells.concat(
            allCols[j][i].getCells(),
            j === allCols.length - 1
              ? []
              : this.cellBuilder.withDefaultSettings(defaultSettings).withMarginRight(defaultSettings.columnMarginRight).build()
          );
          if (marginTop < allCols[j][i].getTopMargin()) {
            marginTop = allCols[j][i].getTopMargin();
          }
        }
      }
      rows.push(this.rowBuilder.withDefaultSettings(defaultSettings).withMarginTop(marginTop).withCells(cells).build());
    }

    return rows;
  }

  async handleChildren(genericObject: IGenericObject, element: IMetaElement): Promise<IQuinoExcelRow[]> {
    let rows: IQuinoExcelRow[] = [];
    const mapper = this.mapperProvider.getInstances().find((mapper: IExcelMapper) => {
      return mapper.canMap(element);
    });
    if (mapper !== undefined) {
      rows = rows.concat(await mapper.getData(genericObject, element));
    }
    if (isIMetaGroup(element)) {
      for (const child of element.elements) {
        rows = rows.concat(await this.handleChildren(genericObject, child));
      }
    }
    return rows;
  }

  getMappableElements(): string[] {
    return ['Columns'];
  }

  canMapChildren(element: IMetaElement): boolean {
    return isIMetaGroup(element) && element.elements.reduce((mappable, val) => val.controlName === 'Column' && mappable, true);
  }
}
