import { inject, injectable } from 'inversify';
import { IQuinoLabelConfigurationService } from './IQuinoLabelConfigurationService';
import { ILabelSettings } from './ILabelSettings';
import { QuinoUIServiceSymbols } from '../ioc';
import { IMetaElement } from '@quino/core';

@injectable()
export class DefaultLabelConfigurationService implements IQuinoLabelConfigurationService {
  constructor(@inject(QuinoUIServiceSymbols.ILabelSettings) private readonly labelSettings: ILabelSettings) {}

  getLabelSettings(_element: IMetaElement | null): ILabelSettings {
    return this.labelSettings;
  }
}
