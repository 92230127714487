import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Item as ToolbarItem } from 'devextreme/ui/toolbar';
import { Properties as DropDownButtonProperties } from 'devextreme/ui/drop_down_button';
import { Properties as ButtonProperties } from 'devextreme/ui/button';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { useService } from '../../ioc';

export interface IFilterButtonProps {
  simpleEnabled: boolean;
  advancedEnabled: boolean;
  state: IFilterState;
  setState: Dispatch<SetStateAction<IFilterState>>;
  onChange?: (newState: IFilterState) => Promise<IFilterState>;
}

export enum FilterMode {
  simple,
  advanced
}

export interface IFilterState {
  enabled: boolean;
  mode: FilterMode;
}

export const useFilterButton = (props: IFilterButtonProps) => {
  const { simpleEnabled, advancedEnabled, state, setState, onChange } = props;

  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const update = useCallback(
    (newState: IFilterState) => {
      if (onChange) {
        onChange(newState).then(setState).catch(console.error);
      } else {
        setState(newState);
      }
    },
    [onChange, setState]
  );

  const toggle = useCallback(() => {
    update({ ...state, enabled: !state.enabled });
  }, [state, update]);

  const filterItems = useMemo(() => {
    const filterItems = [];

    simpleEnabled &&
      filterItems.push({
        icon: 'material-icons-outlined filter_alt',
        text: translationService.translate('QuinoDataGrid.Filter.Simple'),
        filterMode: FilterMode.simple
      });

    advancedEnabled &&
      filterItems.push({
        icon: 'material-icons-outlined filter_list',
        text: translationService.translate('QuinoDataGrid.Filter.Advanced'),
        filterMode: FilterMode.advanced
      });

    return filterItems;
  }, [simpleEnabled, translationService, advancedEnabled]);

  return useMemo((): ToolbarItem | undefined => {
    const common = {
      location: 'before',
      cssClass: 'quino-filter-button' + (state.enabled ? ' is--active' : '')
    };
    const commonOptions = {
      stylingMode: 'contained',
      icon: 'material-icons-outlined filter_' + (state.mode === FilterMode.simple ? 'alt' : 'list')
    };

    switch (filterItems.length) {
      case 0:
        return undefined;
      case 1:
        return {
          ...common,
          widget: 'dxButton',
          options: {
            ...commonOptions,
            onClick: toggle
          } as ButtonProperties
        } as ToolbarItem;
      default:
        return {
          ...common,
          widget: 'dxDropDownButton',
          options: {
            ...commonOptions,
            items: filterItems,
            displayExpr: 'text',
            onButtonClick: toggle,
            onContentReady: (e) => {
              const dropdownButton: HTMLElement | null = e.element.querySelector('.dx-dropdownbutton-toggle');
              if (dropdownButton) dropdownButton.title = translationService.translate('QuinoDataGrid.Filter.SwitchMode');
            },
            hint: translationService.translate('QuinoDataGrid.Filter.' + (state.enabled ? 'SwitchOff' : 'SwitchOn')),
            splitButton: true,
            onItemClick: (e) => {
              const newMode = e.itemData.filterMode;
              if (newMode != undefined) {
                update({ enabled: true, mode: newMode });
              }
            }
          } as DropDownButtonProperties
        } as ToolbarItem;
    }
  }, [filterItems, state, toggle, translationService, update]);
};

export const useFilterState = (simpleEnabled: boolean): [IFilterState, Dispatch<SetStateAction<IFilterState>>] => {
  const [state, setState] = useState<IFilterState>({
    enabled: false,
    mode: simpleEnabled ? FilterMode.simple : FilterMode.advanced
  });

  return [state, setState];
};
