import React, { PropsWithChildren, useContext } from 'react';
import { useOnNavigation, IQuinoBookmarkAction, useService, usePromise } from '@quino/ui';
import { CUIHeader } from './header/CUIHeader';
import { CUIBookmarkContent } from './content/CUIBookmarkContent';
import { useHtmlTitle } from '../util/useHtmlTitle';
import { CUIStagingEnvironment } from './header/CUIStagingEnvironment';
import { CUIContextPopupSelector } from './context/CUIContextPopupSelector';
import { ICUICombinedScopeSelectorExtension } from './header/CUICombinedScopeSelector';
import { CUINavWrapper } from './navbar/CUINavWrapper';
import { QuinoContextContext, IStorageService, PersistentStorageServiceSymbol } from '@quino/core';
import { CUIDisclaimerPopup } from './overlays/CUIDisclaimerPopup';
import { useBackgroundImage } from './Util';
import { useCuiSettings } from '../util';

export interface IRootViewProps extends PropsWithChildren<any> {
  customBookmarkActions?: IQuinoBookmarkAction[];
  customScopeSelectorComponents?: ICUICombinedScopeSelectorExtension[];
  customHeaderElement?: React.ReactNode;
}

export function CUIRootView(props: IRootViewProps) {
  const quinoContextContext = useContext(QuinoContextContext);

  const persistenceStorageService = useService<IStorageService>(PersistentStorageServiceSymbol);
  const cuiSettings = useCuiSettings();

  const bookmark = useOnNavigation();
  useHtmlTitle(bookmark);

  const [userSettingsContextSelectionBackgroundImage] = usePromise<string, any>(
    async () => persistenceStorageService.get<string>('ContextBackgroundImage'),
    []
  );

  const loginBackgroundImage = cuiSettings.loginBackgroundImage;
  const loginBackgroundImageTranslationKey = 'Login.BackGroundImage';

  const src = typeof userSettingsContextSelectionBackgroundImage === 'string' ? userSettingsContextSelectionBackgroundImage : loginBackgroundImage;
  const translationKey = typeof userSettingsContextSelectionBackgroundImage === 'string' ? '' : loginBackgroundImageTranslationKey;

  useBackgroundImage(
    src,
    translationKey,
    quinoContextContext.isLoading || quinoContextContext.currentContext != null || userSettingsContextSelectionBackgroundImage == undefined
  );

  if (quinoContextContext.isLoading) {
    return <></>;
  }

  return (
    <CUIContextPopupSelector>
      <div key={'rootBackground'} className='quino-ecui-root-background'>
        <div key={'rootBox'} className='quino-ecui-root-box'>
          <CUIStagingEnvironment />
          <CUIHeader {...props} />
          <div className={'quino-ecui-content-wrapper'}>
            <CUINavWrapper />
            <CUIBookmarkContent bookmark={bookmark} {...props} />
          </div>
        </div>
      </div>
      <CUIDisclaimerPopup />
    </CUIContextPopupSelector>
  );
}
