import { AuthenticationModule } from './authentication';
import { RequestModule } from './request';
import { ValidationModule } from './validations';
import { ApiModule, MetadataModule } from './api';
import { IApplication } from './application';
import { AuthorizationModule } from './authorization';
import { ExpressionModule } from './expressions';
import { SettingsModule } from './storage';
import { ScopingModule } from './scoping';
import { TranslationModule } from './lang/TranslationModule';
import { ConfigurationModule } from './configuration';
import { NotificationModule } from './notification';
import { ErrorHandlingModule } from './error';

/**
 * Register all standard modules from the core package.
 */
export class QuinoCoreModule {
  public static use(application: IApplication) {
    ApiModule.use(application);
    ExpressionModule.use(application);
    ValidationModule.use(application);
    MetadataModule.use(application);
    TranslationModule.use(application);
    RequestModule.use(application);
    ConfigurationModule.use(application);
    AuthenticationModule.use(application);
    AuthorizationModule.use(application);
    SettingsModule.use(application);
    ScopingModule.use(application);
    NotificationModule.use(application);
    ErrorHandlingModule.use(application);
  }
}
