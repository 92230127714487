import { IGenericObject, IMetaClass, IMetaLayout } from '@quino/core';
import DataSource from 'devextreme/data/data_source';
import { IListBookmarkOptions } from './IListBookmarkOptions';
import { IMetaBookmark, isIMetaBookmark } from './IMetaBookmark';
import { IRefreshableBookmark } from './IRefreshableBookmark';

/**
 * Events that are exposed by the list bookmark event.
 */
export enum ListBookmarkEvent {
  /**
   * Triggered if the source changes.
   */
  SourceChanged,

  /**
   * Triggered if the selection changes.
   */
  SelectionChanged
}

/**
 * Bookmark that represents a list of items.
 */
export interface IListBookmark extends IMetaBookmark, IRefreshableBookmark {
  /**
   * Subscribe in case the selection changes.
   * @param callback - the callback to use.
   * @param event - the event to subscribe to.
   */
  subscribe(callback: (event: IGenericObject[]) => void, event: ListBookmarkEvent): Symbol;

  /**
   * Unsubscribe from the selection change.
   * @param symbol - the symbol to unsubscribe.
   * @param event - the event to subscribe to.
   */
  unsubscribe(symbol: Symbol, event: ListBookmarkEvent): void;

  /**
   * Reloads the data source
   */
  reloadDataSource(): void;

  /**
   * The meta class to use with this list.
   */
  readonly metaClass: IMetaClass;

  /**
   * The layout to use with this bookmark.
   */
  readonly layout: IMetaLayout;

  /**
   * The items to display.
   *
   * @deprecated - use the DataSource instead.
   */
  source: IGenericObject[];

  /**
   * The selected items.
   */
  selection: IGenericObject[];

  /**
   * Optional property if a data source was set.
   */
  dataSource?: DataSource;

  /**
   * Options available for the list bookmark to override certain behaviour.
   */
  options: IListBookmarkOptions;

  /**
   * Whether the bookmark has pending inline edit changes
   */
  hasInlineEditChanges: () => boolean;

  /**
   * Manually set the pending changes state
   */
  setHasInlineEditChanges: (hasChanges: boolean) => void;
}

/**
 * Whether the given object is a IListBookmark.
 */
export const isIListBookmark = (object: any): object is IListBookmark => {
  return isIMetaBookmark(object) && (object as IListBookmark).options !== undefined;
};
