import React, { PropsWithChildren } from 'react';
import { TResponsiveMode, useResponsiveMode } from '../../responsivity';

export interface IQuinoCustomFormRowProps {
  columns: number;
}

export function QuinoCustomFormRow(props: PropsWithChildren<IQuinoCustomFormRowProps>) {
  const responsiveMode = useResponsiveMode();

  return (
    <div
      className={'quino-custom-form-row'}
      style={{ gridTemplateColumns: responsiveMode === TResponsiveMode.Phone ? '1fr' : `repeat(${props.columns}, 1fr)` }}>
      {props.children}
    </div>
  );
}
