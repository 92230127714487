import { IVisibleCalculator } from './IVisibleCalculator';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IExpressionEvaluator } from '../IExpressionEvaluator';
import { IMetaElement } from '../../meta';
import { isIMetaElement } from '../../meta/IMetaElement';

@injectable()
export class VisibleCalculator implements IVisibleCalculator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator) {}

  calculate(element: IMetaElement, object: any): boolean {
    if (isIMetaElement(element)) {
      return element.visible != null && this.evaluator.evaluate<boolean>(element.visible, object);
    }

    return true;
  }
}
