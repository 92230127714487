import { ICUIScopeSelectorService, ICUISimpleSelectorOptions } from './ICUIScopeSelectorService';
import { inject, injectable } from 'inversify';
import { ICUISettingsConfigurationService, ICUISettingsConfigurationServiceSymbol } from './ICUISettingsConfigurationService';
import { IMetadataTree, LayoutType, QuinoCoreServiceSymbols } from '@quino/core';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '@quino/ui';

@injectable()
export class CUIScopeSelectorService implements ICUIScopeSelectorService {
  constructor(
    @inject(ICUISettingsConfigurationServiceSymbol) private readonly cuiSettings: ICUISettingsConfigurationService,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(IODataSourceFactorySymbol) private readonly sourceFactory: IODataSourceFactory
  ) {}

  getSimpleSelectorOptions(): ICUISimpleSelectorOptions | undefined {
    const showScopeSelector = this.cuiSettings.getCuiSettings().entityScopeSelectorVisible;
    const entityName = this.cuiSettings.getCuiSettings().entityScopeEntityName;
    const valueExpression = this.cuiSettings.getCuiSettings().entityScopeValueExpression;
    const displayExpression = this.cuiSettings.getCuiSettings().entityScopeDisplayExpression;

    if (!showScopeSelector || entityName.length < 1 || valueExpression.length < 1 || displayExpression.length < 1) {
      return undefined;
    }

    const dataSource = this.sourceFactory.create(this.metadataTree.getLayout(entityName, LayoutType.Title), entityName, {
      ignoreScope: true
    });

    return { dataSource, valueExpression, displayExpression };
  }
}
