import dxDataGrid from 'devextreme/ui/data_grid';
import { IMetaClass, IMetaElement } from '@quino/core';
import { IQuinoDataGridConfiguration } from './IQuinoDataGridConfiguration';

export const IQuinoDataGridExporterSymbol = Symbol.for('IQuinoDataGridExporter');

export interface IQuinoDataGridExporter {
  /***
   * Exports the dataGrid as excel
   * @param dataGrid
   * @param layout
   * @param metaClass
   * @param config
   * @param selectedOnly
   */
  exportExcel(
    dataGrid: dxDataGrid,
    layout: IMetaElement,
    metaClass: IMetaClass,
    config: IQuinoDataGridConfiguration,
    selectedOnly?: boolean
  ): Promise<void>;

  exportPdf(
    dataGrid: dxDataGrid,
    layout: IMetaElement,
    metaClass: IMetaClass,
    config: IQuinoDataGridConfiguration,
    selectedOnly?: boolean
  ): Promise<void>;
}
