import { IControlBehaviorRegistry } from './IControlBehaviorRegistry';
import { injectable } from 'inversify';
import { IMetaProperty } from '../../meta';

@injectable()
export class ControlBehaviorRegistry<ReturnValue> implements IControlBehaviorRegistry<ReturnValue> {
  register(element: IMetaProperty, executingFunc: () => ReturnValue): void {
    this.controlBehaviorFunctions[ControlBehaviorRegistry.elementIdentifier(element)] = executingFunc;
  }

  unRegister(element: IMetaProperty): void {
    delete this.controlBehaviorFunctions[ControlBehaviorRegistry.elementIdentifier(element)];
  }

  execute(element: IMetaProperty): ReturnValue {
    const validationFunc = this.controlBehaviorFunctions[ControlBehaviorRegistry.elementIdentifier(element)];
    if (validationFunc) {
      return validationFunc();
    }

    return {} as ReturnValue;
  }

  getAllExecutingFunctions(): (() => ReturnValue)[] {
    return Object.keys(this.controlBehaviorFunctions).map((x) => this.controlBehaviorFunctions[x]);
  }

  private static elementIdentifier(element: IMetaProperty): string {
    return element.name;
  }

  protected controlBehaviorFunctions: { [name: string]: () => ReturnValue } = {};
}
