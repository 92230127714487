import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IMetaClass, IMetaElement, IMetaGroup, IMetaLayout, IMetaModel, isIMetaGroup, LayoutType } from '../meta';
import { IMetadataTree } from './IMetadataTree';
import { IMetaElementFactory } from './meta';
import { IModelFetcher, IModelFetcherSymbol } from './IModelFetcher';
import { ILogger } from '../logging';

@injectable()
export class MetadataTree implements IMetadataTree {
  constructor(
    @inject(QuinoCoreServiceSymbols.IStorage) public storage: Storage,
    @inject(QuinoCoreServiceSymbols.IMetaElementFactory) public metaElementFactory: IMetaElementFactory,
    @inject(IModelFetcherSymbol) public modelFetcher: IModelFetcher,
    @inject(QuinoCoreServiceSymbols.ILogger) public logger: ILogger
  ) {}

  getClasses = (): IMetaClass[] => {
    return this.model.classes;
  };

  getClass = (metaClass: string): IMetaClass => {
    const result = this.model.classes.find((x) => MetadataTree.normalizeKey(x.name) === MetadataTree.normalizeKey(metaClass));

    if (!result) {
      throw new Error(`Could not find [${metaClass}] in available classes.`);
    }

    return result;
  };

  getLayouts = (metaClass: string): IMetaLayout[] => {
    const key = MetadataTree.normalizeKey(metaClass);
    if (!this.viewMap.has(key)) {
      throw new Error(`Could not find [${metaClass}] in available layouts.`);
    }

    return this.viewMap.get(key)!;
  };

  getLayout = (metaClass: string, type: LayoutType | string): IMetaLayout => {
    const key = MetadataTree.normalizeKey(metaClass);
    if (!this.viewMap.has(key)) {
      throw new Error(`Could not find [${metaClass}] in available layouts.`);
    }

    const result = this.viewMap
      .get(key)!
      .find((layout) =>
        [MetadataTree.normalizeKey(layout.name), MetadataTree.normalizeKey(layout.type)].includes(MetadataTree.normalizeKey(type.toString()))
      );

    if (result == null) {
      throw new Error(`Could not find layout [${type}] for [${metaClass}] in available layouts.`);
    }

    return result;
  };

  getMenuLayout = (): IMetaGroup => {
    const menuLayout = this.model.layouts.find((x) => MetadataTree.normalizeKey(x.name) === MetadataTree.normalizeKey('menu'));

    if (!menuLayout) {
      if (this.model.layouts.length > 0) {
        return this.model.layouts[0];
      }

      throw new Error('Failed to retrieve menu layout from the model. Ensure it contains a valid menu layout.');
    }

    return menuLayout;
  };

  getDashboardLayouts = (): IMetaGroup[] => {
    return this.model.layouts.filter((x) => MetadataTree.normalizeKey(x.name).includes(MetadataTree.normalizeKey('dashboard')));
  };

  initialize = async (onSuccess?: () => void) => {
    if (!this.pendingFetch) {
      this.pendingFetch = this.fetchModel();
    }

    const metaModel = await this.pendingFetch;

    metaModel.classes = metaModel.classes.map((value) => this.applyDefaults(value)) as [IMetaClass];
    metaModel.layouts = metaModel.layouts.map((value) => this.applyDefaults(value)) as [IMetaGroup];
    metaModel.classes.forEach((metaClass) => {
      metaClass.layouts = metaClass.layouts.map((x) => this.applyDefaults(x)) as [IMetaLayout];
    });

    for (const entity of metaModel.classes) {
      this.viewMap.set(MetadataTree.normalizeKey(entity.name), entity.layouts);
    }

    this.model = metaModel;
    onSuccess && onSuccess();
  };

  fetchModel = async (): Promise<IMetaModel> => {
    // Get the hash and the existing model.
    const existingHash = this.storage.getItem(this.modelFetcher.hashStorageKey());
    const cachedModel = this.storage.getItem(this.modelFetcher.storageKey());

    const hashedModel = await this.modelFetcher.fetchCurrentModel(existingHash);
    if (hashedModel.model == null) {
      if (cachedModel) {
        return JSON.parse(cachedModel);
      } else {
        this.logger.logWarn('Cached model did not exist unexpectedly, try to fetch it without cache');
        const model = await this.modelFetcher.fetch();
        const hash = await this.modelFetcher.fetchHash();
        this.storage.setItem(this.modelFetcher.storageKey(), JSON.stringify(model));
        this.storage.setItem(this.modelFetcher.hashStorageKey(), hash);

        return model;
      }
    }

    try {
      this.storage.setItem(this.modelFetcher.storageKey(), JSON.stringify(hashedModel.model));
      this.storage.setItem(this.modelFetcher.hashStorageKey(), hashedModel.hash);
    } catch {
      // NOTE: Firefox has a bug with setting the local storage for "larger" items. Ensure we can continue.
    }

    return hashedModel.model;
  };

  public model: IMetaModel;
  protected viewMap: Map<string, IMetaLayout[]> = new Map<string, IMetaLayout[]>();

  private static normalizeKey(key: string): string {
    return key.toLowerCase();
  }

  private applyDefaults(value: IMetaElement): IMetaElement {
    value = this.metaElementFactory.create(value);

    if (isIMetaGroup(value)) {
      value.elements = value.elements.map((value1) => this.applyDefaults(value1));
    }

    return value;
  }

  private pendingFetch?: Promise<IMetaModel>;
}
