import { useService } from '../ioc';
import { DependencyList, useEffect } from 'react';
import { IFieldValidationResult, QuinoCoreServiceSymbols, IMetaProperty, IControlBehaviorRegistry } from '@quino/core';

export const useControlValidation = (element: IMetaProperty, validationFunc: () => IFieldValidationResult, deps: DependencyList) => {
  const controlValidationRegistry = useService<IControlBehaviorRegistry<IFieldValidationResult>>(QuinoCoreServiceSymbols.ControlValidatorRegistry);

  useEffect(() => {
    controlValidationRegistry.register(element, validationFunc);
    return () => controlValidationRegistry.unRegister(element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlValidationRegistry, ...deps]);
};
