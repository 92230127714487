import { IMetaElement } from '@quino/core';
import { IQuinoDropDownSettings } from './IQuinoDropDownSettings';

export const IQuinoDropDownConfigurationServiceSymbol = Symbol.for('IQuinoDropDownConfigurationService');

export interface IQuinoDropDownConfigurationService {
  /**
   * Gets the dropdown settings configuration.
   */
  getDropDownSettings(element: IMetaElement): Promise<IQuinoDropDownSettings>;
}
