import { useEffect, useMemo } from 'react';
import { defaultQuinoShortcutSettings, IQuinoShortcut, isControlKeyShortCut, isSingleKeyShortCut } from './IQuinoShortcutSettings';
import { isCtrlOrCmdEvent } from './ShortcutUtils';

export function useShortcutBlocker(boundaryElement?: HTMLElement): void {
  const allShortcuts = useMemo(() => Object.values(defaultQuinoShortcutSettings), []);

  useEffect(() => {
    const blockShortcuts = (e: KeyboardEvent) => {
      if (e.key) {
        const pressedKey = e.key.toLowerCase();
        const matchingShortcut = allShortcuts.find(
          (s: IQuinoShortcut) =>
            s.key.toLowerCase() === pressedKey &&
            (isSingleKeyShortCut(s) || (isControlKeyShortCut(s) && isCtrlOrCmdEvent(e) && !(e.altKey || e.shiftKey)))
        );

        if (matchingShortcut) {
          e.preventDefault();
        }
      }
    };

    const element = boundaryElement || document.body;
    element.addEventListener('keydown', blockShortcuts);

    return () => element.removeEventListener('keydown', blockShortcuts);
  }, [allShortcuts, boundaryElement]);
}
