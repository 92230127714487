/**
 * Describes a bookmark which contains context information about the currently displayed view.
 */
export interface IBookmark {
  // NOP - Marker interface for bookmarks.
}

/**
 * Whether the given type is a bookmark or not.
 */
export const isIBookmark = (object: any): object is IBookmark => {
  return object != null;
};
