import React, { FC } from 'react';
import {
  DEFAULT_SERVER_BASEURL,
  IApplication,
  IIdentityService,
  IQuinoServerServiceConfiguration,
  QuinoCoreModule,
  QuinoCoreServiceSymbols
} from '@quino/core';
import {
  DevExpressModule,
  IClientAction,
  IClientActionProvider,
  IClientActionProviderSymbol,
  IQuinoDataGridColumnFactory,
  IQuinoDataGridColumnFactorySymbol,
  QuinoApplicationRoot,
  UIModule
} from '@quino/ui';
import { CUIModule } from '@quino/cui';
import { CommonUI } from './examples/CommonUI';
import { IdentityService } from './identity/IdentityService';
import { SandboxDataGridColumnFactory } from './components/SanboxDataGridColumnFactory';
import { ConsoleLogAction, ConsoleLogActionSymbol } from './examples/ConsoleLogAction';

export const RegisterCustomActionsStartupAction = Symbol.for('RegisterCustomActionsStartupAction');

export const App: FC = () => {
  return (
    <QuinoApplicationRoot
      setup={(app: IApplication) => {
        QuinoCoreModule.use(app);
        UIModule.use(app);

        // Include OIDC module when required
        // OidcAuthenticationModule.use(app);

        DevExpressModule.use(app);
        CUIModule.use(app);

        app.registerConstant<IQuinoServerServiceConfiguration>(QuinoCoreServiceSymbols.IQuinoServerServiceConfiguration, {
          baseUrl: PRODUCTION ? '/' : DEFAULT_SERVER_BASEURL
        });
        app.registerSingle<IIdentityService>(QuinoCoreServiceSymbols.IIdentityService, IdentityService);
        app.registerSingle<IQuinoDataGridColumnFactory>(IQuinoDataGridColumnFactorySymbol, SandboxDataGridColumnFactory);
        app.registerSingle<IClientAction>(ConsoleLogActionSymbol, ConsoleLogAction);

        app.registerStartupAction(RegisterCustomActionsStartupAction, (app) => {
          const provider = app.get<IClientActionProvider>(IClientActionProviderSymbol);
          provider.register(app.get<IClientAction>(ConsoleLogActionSymbol), Symbol.for('ConsoleLogAction'));
        });
      }}>
      <CommonUI />
    </QuinoApplicationRoot>
  );
};
