import { DataType, getAspectOrDefault, IMetaProperty, IRegexRuleAspect, RegexRuleAspectIdentifier } from '../../meta';
import { IFieldValidationResult } from '../IFieldValidationResult';
import { FieldValidationErrorCodes } from '../FieldValidationErrorCodes';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IValidationMessageProvider } from '../IValidationMessageProvider';
import { IFieldValidator } from '../IFieldValidator';
import { IValidationContext } from '../IValidationContext';

@injectable()
export class RegExRuleValidator implements IFieldValidator {
  constructor(@inject(QuinoCoreServiceSymbols.IValidationMessageProvider) private readonly msgProvider: IValidationMessageProvider) {}

  validate(field: IMetaProperty, value: any, _context: IValidationContext): IFieldValidationResult {
    const aspect = getAspectOrDefault<IRegexRuleAspect>(field, RegexRuleAspectIdentifier);

    // TODO: Only text fields are valid for Regexp?
    if (aspect == null || RegExRuleValidator.isUnsupportedDataType(field) || !value) {
      return {};
    }

    const regEx = new RegExp(aspect.regularExpression);
    if (regEx.test(value)) {
      return {};
    }

    return {
      fieldErrors: [
        {
          fieldName: field.name,
          errorCode: FieldValidationErrorCodes.REGEX_NOT_MATCH,
          errorMessage: this.msgProvider.getRegExError()
        }
      ]
    };
  }

  private static isUnsupportedDataType(field: IMetaProperty) {
    return field.dataType !== DataType.Text;
  }
}
