import { IFileInfo } from './IFileInfo';

export const IQuinoFileHandlerSymbol = Symbol.for('IQuinoFileHandler');

export interface IQuinoFileHandler {
  init(entity: string, primaryKey: string | undefined, context: string | null): Promise<IFileInfo[]>;

  addFile(id: string): Promise<IFileInfo[]>;

  deleteFile(id: string): IFileInfo[];

  commitAllFiles(ownerMetaClass: string, ownerPrimaryKey: string, context?: string): Promise<{ fileMetaData: IFileInfo[] }>;

  reset(): IFileInfo[];
}
