/**
 * Symbol for the masked string service
 */
export const IStringMaskingServiceSymbol = Symbol.for('IStringMaskingService');

/**
 * Service used to mask strings outside editors
 */
export interface IStringMaskingService {
  /**
   * Gets the masked value of a string
   * @param unmaskedString: the unmasked string
   * @param mask: the mask to apply
   */
  getMaskedString(unmaskedString: string, mask: string): string;
}
