import { IBookmarkSerializer } from './IBookmarkSerializer';
import { IBookmark, IBookmarkFactory, IDashboardBookmark, isIDashboardBookmark } from '../bookmarks';
import { inject, injectable } from 'inversify';
import { QuinoUIServiceSymbols } from '../ioc';
import { IDashboardLayout, IDashboardSettingsService, IDashboardSettingsServiceSymbol } from '../dashboard';
import { StateFullBookmarkSerializer } from './StateFullBookmarkSerializer';
import { IUrlHelper, QuinoCoreServiceSymbols } from '@quino/core';
import { getBaseUrl, getPathParts, pathContainsIdentifierSequence, removeSlashes } from './UrlUtilities';

export const DashboardBookmarkSerializerSymbol = Symbol.for('DashboardBookmarkSerializer');

@injectable()
export class DashboardBookmarkSerializer extends StateFullBookmarkSerializer implements IBookmarkSerializer<IDashboardBookmark> {
  constructor(
    @inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory,
    @inject(IDashboardSettingsServiceSymbol) private readonly dashboardSettingsService: IDashboardSettingsService,
    @inject(QuinoCoreServiceSymbols.IUrlHelper) urlHelper: IUrlHelper
  ) {
    super(urlHelper);
  }

  canSerialize(bookmark: IBookmark): boolean {
    return isIDashboardBookmark(bookmark);
  }

  canDeserialize(path: string): boolean {
    return pathContainsIdentifierSequence(path, 'cui', 'd');
  }

  async deserialize(path: string): Promise<IDashboardBookmark> {
    const parts = getPathParts(path);
    const cuiIndex = parts.indexOf('cui');

    if (cuiIndex <= -1 || parts.length < cuiIndex + 2) {
      return Promise.reject();
    }

    const layoutName = parts[cuiIndex + 2];
    const dashboardLayout: IDashboardLayout = await this.dashboardSettingsService.getDashboardOrFallback(layoutName);
    const bookmark = await this.bookmarkFactory.createDashboard(dashboardLayout);

    super.deserializeState(path, bookmark);

    return bookmark;
  }

  serialize(bookmark: IDashboardBookmark): string {
    return new URL(
      `${removeSlashes(getBaseUrl())}/cui/d/${bookmark.getCurrentDashboard().name}${this.serializeState(bookmark)}`,
      document.location.origin
    ).toString();
  }
}
