import { IApplication, InitializationGroup } from '../application';
import { IConfigurationService, IConfigurationServiceSymbol } from './IConfigurationService';
import { ConfigurationService } from './ConfigurationService';

export class ConfigurationModule {
  public static use(application: IApplication) {
    application.registerSingle<IConfigurationService>(IConfigurationServiceSymbol, ConfigurationService);
    application
      .registerStartupAction(IConfigurationServiceSymbol, async (app) => {
        await app.get<IConfigurationService>(IConfigurationServiceSymbol).initialize();
      })
      .moveTo(InitializationGroup);
  }
}
