import {
  getAspectOrDefault,
  getMetaRelation,
  IAuthorizationService,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetadataTree,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  ISearchModeAspect,
  isMetaRelation,
  isValueList,
  ITitleCalculator,
  ITitleCalculatorSymbol,
  ITranslationService,
  ITranslationServiceSymbol,
  LayoutType,
  QuinoCoreServiceSymbols,
  SearchModeAspectIdentifier
} from '@quino/core';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import { IQuinoEditorProps } from '../Types';
import { IBookmarkFactory, IObjectBookmark } from '../../bookmarks';
import { registerKeyHandlers, useMetaPropertyValueState, useValidation } from '../Util';
import { useDropDownSettings, useLabelSettings } from '../../settings';
import { useMetadata } from '../../settings/useMetadata';
import { QuinoCrossLink } from '../QuinoCrossLink';
import { useValueListDataSource } from '../Util/ValueListReducer';
import { QuinoUIServiceSymbols, useService } from '../../ioc';
import { QuinoPopupEditor, QuinoPopupEditorOnHideAction } from '../QuinoPopupEditor';
import notify from 'devextreme/ui/notify';
import { ToolbarItem as PopupToolbarItem } from 'devextreme/ui/popup';
import dxSelectBox from 'devextreme/ui/select_box';
import { TResponsiveMode, useResponsiveMode } from '../../responsivity';

export function QuinoDropDown(props: IQuinoEditorProps) {
  const { bookmark, metaProperty, propagateValue, hideClearButton, hideNonEditorElements } = props;
  const { description } = metaProperty;

  const titleCalculator = useService<ITitleCalculator>(ITitleCalculatorSymbol);
  const valueService = useService<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const authorizationService = useService<IAuthorizationService>(QuinoCoreServiceSymbols.IAuthorizationService);
  const metaDataService = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  const defaultData = useMemo(() => {
    return isMetaRelation(metaProperty) ? { key: metaProperty.targetProperties[0], data: bookmark.genericObject[metaProperty.path] } : undefined;
  }, [bookmark.genericObject, metaProperty]);
  const dataSource = useValueListDataSource(metaProperty, LayoutType.Title, defaultData);
  const { readOnly, required, enabled } = useMetadata(metaProperty, bookmark.genericObject);
  const [isValid] = useValidation(bookmark, metaProperty);
  const labelSettings = useLabelSettings(metaProperty);
  const dropDownSettings = useDropDownSettings(metaProperty);
  const [bookmarkValue, setBookmarkValue] = useMetaPropertyValueState<any>(metaProperty, bookmark);
  const responsiveMode = useResponsiveMode();

  const [component, setComponent] = useState<dxSelectBox | undefined>(undefined);
  const [editBookmark, setEditBookmark] = useState<IObjectBookmark | undefined>(undefined);

  const isMetaRelationWithoutValueList = useMemo(() => isMetaRelation(metaProperty) && !isValueList(metaProperty), [metaProperty]);
  const searchModeAspect = useMemo(() => getAspectOrDefault<ISearchModeAspect>(metaProperty, SearchModeAspectIdentifier), [metaProperty]);

  const valueKey = useMemo(() => {
    if (isMetaRelation(metaProperty) && !isValueList(metaProperty)) {
      return metaProperty.targetProperties[0];
    }

    return 'Value';
  }, [metaProperty]);

  const displayExpression = useCallback(
    (item: any) => {
      if (item == null) {
        return '';
      }

      if (isMetaRelation(metaProperty) && !isValueList(metaProperty)) {
        return titleCalculator.generate(item, metaProperty.targetClass);
      }

      return item['Caption'];
    },
    [metaProperty, titleCalculator]
  );

  const addNewBookmark = useCallback(async () => {
    const unload = loadingFeedback.load();
    try {
      const newBookmark = await bookmarkFactory.createNewObject(getMetaRelation(metaProperty).targetClass);
      setEditBookmark(newBookmark);
    } catch (error) {
      notify({ message: translationService.translate('Notification.ErrorBoundaryError'), messageDetails: String(error) });
    } finally {
      unload();
    }
  }, [bookmarkFactory, loadingFeedback, metaProperty, translationService]);

  // Refresh by going back to page 0 and reloading the data-source
  const refreshDropDown = useCallback(() => {
    dataSource.pageIndex(0);
    dataSource.reload().catch(logger.logError);
  }, [dataSource, logger.logError]);

  const dropDownButtons: PopupToolbarItem[] = useMemo(() => {
    const buttons: PopupToolbarItem[] = [
      {
        widget: 'dxButton',
        location: 'center',
        toolbar: 'bottom',
        options: {
          icon: 'material-icons-outlined refresh',
          text: translationService.translate('Dropdown.RefreshContent'),
          onClick: refreshDropDown
        }
      }
    ];

    const canCreate = isMetaRelationWithoutValueList && authorizationService.getAuthorization(getMetaRelation(metaProperty).targetClass).canCreate();
    if (dropDownSettings.useCreateEntryButton && canCreate) {
      buttons.push({
        widget: 'dxButton',
        location: 'center',
        toolbar: 'bottom',
        options: {
          icon: 'material-icons-outlined add',
          text: translationService.translate('Dropdown.CreateNewItem'),
          onClick: addNewBookmark
        }
      });
    }

    return buttons;
  }, [
    addNewBookmark,
    authorizationService,
    dropDownSettings.useCreateEntryButton,
    isMetaRelationWithoutValueList,
    metaProperty,
    refreshDropDown,
    translationService
  ]);

  const dropDownOptions = useMemo(() => {
    if (isMetaRelationWithoutValueList) {
      const container = document.createElement('div');
      container.classList.add('quino-drop-down-toolbar-container');

      return {
        toolbarItems: dropDownButtons,
        onShowing: () => document.body.appendChild(container),
        onHiding: () => document.body.removeChild(container),
        container: container
      };
    }

    return undefined;
  }, [dropDownButtons, isMetaRelationWithoutValueList]);

  const setValue = useCallback(
    (value: any, item?: any) => {
      setBookmarkValue(value);
      isMetaRelation(metaProperty) && bookmark.updateFieldValue(metaProperty.path, item);
      propagateValue &&
        propagateValue(
          value,
          isMetaRelation(metaProperty) ? metaProperty.sourceProperties[0] : undefined,
          isMetaRelation(metaProperty) ? item : undefined
        );
    },
    [bookmark, metaProperty, propagateValue, setBookmarkValue]
  );

  const onValueChanged = useCallback(
    (e) => {
      const item = e.component.option('selectedItem');
      if (['number', 'boolean'].includes(typeof e.value)) {
        setValue(e.value, item);
      } else {
        setValue(e.value?.toString() ?? null, item);
      }
    },
    [setValue]
  );

  const crossLinkTitle = useMemo(() => {
    return isValueList(metaProperty) ? '' : displayExpression(valueService.getPlainValue(metaProperty, bookmark.genericObject));
  }, [bookmark.genericObject, displayExpression, metaProperty, valueService]);

  const popupEditorOnHide = useCallback(
    (action, genericObject) => {
      refreshDropDown();

      if (genericObject) {
        const primaryKey = genericObject[metaDataService.getClass(genericObject.metaClass).primaryKey[0]];
        switch (action) {
          case QuinoPopupEditorOnHideAction.save:
            setValue(primaryKey);
            break;
          case QuinoPopupEditorOnHideAction.createNewEntry:
            addNewBookmark()
              .then(() => setValue(primaryKey))
              .catch(logger.logError);
            break;
        }
      }

      setEditBookmark(undefined);
      component?.close();
    },
    [addNewBookmark, component, logger.logError, metaDataService, refreshDropDown, setValue]
  );

  const showCrossLink =
    bookmarkValue !== null &&
    bookmarkValue !== '00000000-0000-0000-0000-000000000000' &&
    !hideNonEditorElements &&
    dropDownSettings.useCrossLink &&
    isMetaRelationWithoutValueList;

  return (
    <>
      <div className={'quino-editor-with-crosslink'}>
        <SelectBox
          showClearButton={!required && !hideClearButton}
          className={'quino-drop-down-select-box'}
          searchEnabled={true}
          searchMode={searchModeAspect && searchModeAspect.useContains ? 'contains' : 'startswith'}
          readOnly={readOnly != null ? readOnly : false}
          noDataText={translationService.translate('Dropdown.NoOptionsAvailable')}
          focusStateEnabled={!readOnly}
          hoverStateEnabled={!readOnly}
          disabled={!enabled}
          searchTimeout={isValueList(metaProperty) ? 0 : undefined}
          dataSource={dataSource}
          valueExpr={valueKey}
          displayExpr={displayExpression}
          placeholder={props.metaProperty.readOnly || !props.metaProperty.enabled ? '' : translationService.translate('Select')}
          value={bookmarkValue}
          hint={labelSettings.hintType === 'None' ? description : undefined}
          onContentReady={(e) => {
            setComponent(e.component);
            if (dropDownSettings.alwaysShowScrollbar) {
              // @ts-ignore devex private API
              e.component._setListOption('showScrollbar', 'always');
            }
          }}
          dropDownOptions={dropDownOptions}
          onValueChanged={onValueChanged}
          isValid={isValid}
          visible={!readOnly || !showCrossLink}
          onInitialized={(e) => registerKeyHandlers(e.component)}
          itemRender={(item) => <div title={displayExpression(item)}>{displayExpression(item)}</div>}
          onFocusIn={() => {
            if (responsiveMode === TResponsiveMode.Phone) {
              setTimeout(() => {
                component?.open();
              }, 300);
            } else {
              const inputField = component?.field() as HTMLInputElement;
              if (inputField) {
                inputField.select();
              }
            }
          }}
          onInput={(e) => {
            setTimeout(() => {
              e.component.field().scrollLeft;
            }, 0);
          }}
        />
        <QuinoCrossLink
          metaProperty={metaProperty}
          primaryKey={bookmarkValue}
          title={crossLinkTitle}
          visible={showCrossLink}
          icon={!readOnly ? 'material-icons-outlined link' : undefined}
          showTitle={readOnly}
          isReadOnlyField={readOnly}
        />
      </div>
      {editBookmark && <QuinoPopupEditor visible={true} bookmark={editBookmark} onHide={popupEditorOnHide} />}
    </>
  );
}
