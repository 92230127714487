import { IBookmark, isIBookmark } from './IBookmark';
import { ReactElement } from 'react';

/**
 * Bookmark that can be used to render custom data
 */
export interface ICustomBookmark extends IBookmark {
  /**
   * Custom render function
   */
  render(): ReactElement;

  /**
   * Custom bookmark actions render function
   */
  renderActions(): ReactElement;

  /**
   * Bookmark title used for navigation and page header
   */
  readonly title: string;

  /**
   * The "class" name to identify the custom bookmark type
   */
  readonly name: Symbol;
}

/**
 * Whether the given object is an ICustomBookmark
 */
export const isICustomBookmark = (object: any): object is ICustomBookmark => {
  return isIBookmark(object) && (object as ICustomBookmark).render !== undefined;
};
