import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import {
  getAspectOrDefault,
  IAuthorizationService,
  IGenericObject,
  ILogger,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { IObjectBookmark, isIObjectBookmark, isNewObjectBookmark } from '../../bookmarks';
import { IQuinoMetaPanelActions } from '../Types';
import { QuinoPopup, QuinoPopupDefaultContent, QuinoPopupToolbar, QuinoPopupToolbarButton } from '../QuinoPopup';
import { QuinoUIServiceSymbols, useService } from '../../ioc';
import { useOnBookmarkAnyEvent, useOnBookmarkChangedEvent, useOnBookmarkReloadEvent, useRerender } from '../Util';
import {
  IQuinoShortcutSettings,
  IQuinoShortcutSettingsSymbol,
  IShortcutInformation,
  IShortcutInformationService,
  IShortcutInformationServiceSymbol,
  useShortcutHandler
} from '../../shortcuts';
import { IBookmarkTitleCalculator, IBookmarkTitleCalculatorSymbol } from '../../navigation/IBookmarkTitleCalculator';
import { IPendingChangesService, IPendingChangesServiceSymbol } from '../../navigation/IPendingChangesService';
import { PendingChangesFeedbackValue } from '../../navigation/PendingChangesService';
import { QuinoInfoBar } from '../QuinoNotificationComponents';
import { QuinoCrossLink } from '../QuinoCrossLink';
import { TResponsiveMode, useResponsiveMode } from '../../responsivity';
import {
  ILayoutActionsService,
  IQuinoActionFactory,
  IQuinoActionFactorySymbol,
  IQuinoBookmarkAction,
  LayoutActionsServiceSymbol,
  quinoActionPropValue
} from '../../actions';
import { QuinoContextMenuButton } from '../ContextMenu';
import { useNotifications } from '../../feedback/useNotifications';
import noop from 'lodash/noop';
import { QuinoMetaPropertyPanel } from '../QuinoMetaPropertyPanel';
import { BookmarkActionsAspectIdentifier, IBookmarkActionsAspect } from '../../aspects';
import { ClassActionsServiceSymbol, IClassActionsService } from '../../actions/IClassActionsService';

export const QuinoPopupEditorNotificationAreaId = 'popup-editor-notification-area';

export enum QuinoPopupEditorOnHideAction {
  cancel,
  save,
  createNewEntry,
  delete
}

export function QuinoPopupEditor(props: {
  bookmark?: IObjectBookmark;
  visible: boolean;
  onHide: (action: QuinoPopupEditorOnHideAction, genericObject?: IGenericObject) => void;
  onHidden?: () => void;
  customWidth?: number;
  customHeight?: number;
}) {
  const actions = useService<IQuinoMetaPanelActions>(QuinoUIServiceSymbols.IQuinoMetaPanelActions);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const bookmarkTitleCalculator = useService<IBookmarkTitleCalculator>(IBookmarkTitleCalculatorSymbol);
  const authorizationService = useService<IAuthorizationService>(QuinoCoreServiceSymbols.IAuthorizationService);
  const classActionsService = useService<IClassActionsService>(ClassActionsServiceSymbol);
  const { notify, clearNotifications } = useNotifications();
  const shortcutInformationService = useService<IShortcutInformationService>(IShortcutInformationServiceSymbol);
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);
  const pendingChangesService = useService<IPendingChangesService>(IPendingChangesServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const actionFactory = useService<IQuinoActionFactory>(IQuinoActionFactorySymbol);
  const actionService = useService<ILayoutActionsService>(LayoutActionsServiceSymbol);
  const [loadingOfSaveButton, setLoadingOfSaveButton] = useState<boolean>(false);
  const [loadingOfDeleteButton, setLoadingOfDeleteButton] = useState<boolean>(false);
  const [onHideCalled, setOnHideCalled] = useState<boolean>(false);

  const { bookmark } = props;

  const symbol = Symbol.for('QuinoPopupEditor');

  const bookmarkActionsAspect = useMemo(
    () => (bookmark && getAspectOrDefault<IBookmarkActionsAspect>(bookmark.layout, BookmarkActionsAspectIdentifier)) || undefined,
    [bookmark]
  );

  const sidebarActionItems = useMemo(() => {
    if (bookmark) {
      return actionService.getSidebarActions(bookmark).map((item) => actionFactory.convertBookmarkActionToContextMenuItem(item, bookmark));
    }
    return [];
  }, [actionFactory, actionService, bookmark]);

  const classActionItems = useMemo(() => {
    const frontActions: IQuinoBookmarkAction[] = [];
    const backActions: IQuinoBookmarkAction[] = [];
    bookmark &&
      classActionsService
        .getClassActions(bookmark)
        .mainActions.forEach((a) => (a.location === 'main-before' ? frontActions.push(a) : backActions.push(a)));

    return { frontActions: frontActions, backActions: backActions };
  }, [bookmark, classActionsService]);

  const responsiveMode = useResponsiveMode();

  const shortcutsEnabled = bookmark != undefined;
  useShortcutHandler([shortcutSettings.cancel], () => feedbackOnLeavingThePopup(), shortcutsEnabled);
  useShortcutHandler([shortcutSettings.createEntryPrimary], () => save(QuinoPopupEditorOnHideAction.createNewEntry), shortcutsEnabled);
  useShortcutHandler([shortcutSettings.saveDetail], () => save(QuinoPopupEditorOnHideAction.save), shortcutsEnabled);

  const rerender = useRerender();

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  useOnBookmarkAnyEvent(bookmark, () => {
    if (bookmark) {
      setHasChanges(bookmark.hasChanges());
    }
  });

  useOnBookmarkReloadEvent(bookmark, rerender);
  useOnBookmarkChangedEvent(bookmark, rerender, classActionsService.getClassActionDependencies(bookmark));

  const title = useMemo<string>(() => {
    if (bookmark != null) {
      return bookmarkTitleCalculator.generate(bookmark);
    }

    return '';
  }, [bookmarkTitleCalculator, bookmark]);

  const shortcutInformation = useMemo<IShortcutInformation>(() => shortcutInformationService.get(), [shortcutInformationService]);

  const castActionToToolbarButton = useCallback((action: IQuinoBookmarkAction, bookmark: IObjectBookmark): ReactNode => {
    return (
      <QuinoPopupToolbarButton
        text={quinoActionPropValue(action.caption, bookmark)}
        visible={quinoActionPropValue(action.visible, bookmark)}
        icon={quinoActionPropValue(action.icon, bookmark)}
        stylingMode={quinoActionPropValue(action.buttonStylingMode, bookmark)}
        type={quinoActionPropValue(action.buttonType, bookmark)}
        disabled={quinoActionPropValue(action.disabled, bookmark)}
        onClick={() => action.onClick({ source: bookmark })}
      />
    );
  }, []);

  const closePopup = (action?: QuinoPopupEditorOnHideAction, genericObject?: IGenericObject) => {
    if (action) {
      setOnHideCalled(true);
      props.onHide(action, genericObject);
    } else {
      setOnHideCalled(false);
      props.onHide(QuinoPopupEditorOnHideAction.cancel);
    }

    setHasChanges(false);
    clearNotifications();
  };

  const feedbackOnLeavingThePopup = () => {
    if (bookmark == undefined) {
      return;
    }

    if (bookmark.hasChanges()) {
      pendingChangesService
        .getLeavingFeedback()
        .then((feedback) => {
          if (feedback === PendingChangesFeedbackValue.Discard) {
            closePopup();
          } else if (feedback === PendingChangesFeedbackValue.Save) {
            save(QuinoPopupEditorOnHideAction.save);
          }
        })
        .catch(logger.logError);
    } else {
      closePopup();
    }
  };

  const saveDisabled = !hasChanges;
  const isNew = isNewObjectBookmark(bookmark);
  const canUpdate = bookmark && authorizationService.getAuthorization(bookmark.metaClass.name).canUpdate();
  const canCreate = bookmark && authorizationService.getAuthorization(bookmark.metaClass.name).canCreate();
  const saveAndNewVisible = bookmark && (canUpdate || canCreate) && isNew;
  const canDelete = bookmark && authorizationService.getAuthorization(bookmark.metaClass.name).canDelete() && !isNew;
  const save = (action: QuinoPopupEditorOnHideAction) => {
    const currentBookmark = bookmark;
    if (currentBookmark && isIObjectBookmark(currentBookmark)) {
      setLoadingOfSaveButton(true);
      currentBookmark
        .validate()
        .then((validationResult) => {
          if (!validationResult.hasErrors) {
            currentBookmark
              .save()
              .then(() => {
                const crossLinkToNewlyCreatedObject = (
                  <>
                    {responsiveMode !== TResponsiveMode.Phone && (
                      <span className={'cross-link-intro'}>{translationService.translate('QuinoDataGrid.Popup.LinkToNewObject')}</span>
                    )}
                    <QuinoCrossLink
                      primaryKey={currentBookmark.genericObject.primaryKey || null}
                      title={
                        responsiveMode === TResponsiveMode.Phone
                          ? translationService.translate('QuinoDataGrid.Popup.LinkToNewObjectMobile')
                          : currentBookmark.genericObject.title
                      }
                      targetClass={currentBookmark.metaClass.name}
                      visible={true}
                      showTitle={true}
                      invertedColor={true}
                    />
                  </>
                );
                closePopup(action, currentBookmark.genericObject);

                notify(
                  {
                    type: 'success',
                    message: translationService.translate('SaveConfirmation'),
                    customElement: crossLinkToNewlyCreatedObject,
                    area: 'global',
                    autoDisappear: true
                  },
                  symbol
                );
              })
              .catch((error: Error) => {
                notify(
                  {
                    area: 'custom',
                    customAreaID: QuinoPopupEditorNotificationAreaId,
                    message: translationService.translate('Notification.ErrorBoundaryError'),
                    messageDetails: error.message
                  },
                  symbol
                );
              });
          } else {
            notify(
              {
                area: 'custom',
                customAreaID: QuinoPopupEditorNotificationAreaId,
                message: translationService.translate('Notification.ValidationError')
              },
              symbol
            );
          }
        })
        .catch(logger.logError)
        .finally(() => {
          setLoadingOfSaveButton(false);
        });
    }
  };

  const deleteAction = () => {
    if (!bookmark) {
      return;
    }
    setLoadingOfDeleteButton(true);
    actions
      .delete(bookmark.genericObject)
      .then(() => {
        closePopup(QuinoPopupEditorOnHideAction.delete, bookmark?.genericObject);
        notify(
          {
            type: 'success',
            message: translationService.translate('DeleteConfirmation'),
            area: 'global',
            autoDisappear: true
          },
          symbol
        );
      })
      .catch((error: Error) => {
        notify(
          {
            area: 'custom',
            customAreaID: QuinoPopupEditorNotificationAreaId,
            message: translationService.translate('Notification.ErrorBoundaryError'),
            messageDetails: error.message
          },
          symbol
        );
      })
      .finally(() => setLoadingOfDeleteButton(false));
  };

  const leftItems: ReactNode[] = [];

  sidebarActionItems.length > 0 &&
    leftItems.push(
      <QuinoContextMenuButton
        dataSource={sidebarActionItems}
        buttonId={'PopupContextButton'}
        buttonIcon={'material-icons-outlined more_vert'}
        buttonText={translationService.translate('More')}
        buttonStylingMode={'outlined'}
        buttonType={'default'}
      />
    );

  const rightItems: ReactNode[] = [];

  rightItems.push(
    <QuinoPopupToolbarButton
      text={translationService.translate('Cancel')}
      icon={'material-icons-outlined clear'}
      onClick={() => closePopup()}
      hint={shortcutInformation.hint(shortcutSettings.cancel)}
    />
  );

  bookmark && classActionItems.frontActions.forEach((action) => rightItems.push(castActionToToolbarButton(action, bookmark)));

  canDelete &&
    !bookmarkActionsAspect?.hideDelete &&
    rightItems.push(
      <QuinoPopupToolbarButton
        loading={loadingOfDeleteButton}
        text={translationService.translate(bookmarkActionsAspect?.customDeleteTranslationKey || 'Delete')}
        icon={'material-icons-outlined delete'}
        onClick={deleteAction}
      />
    );

  saveAndNewVisible &&
    !bookmarkActionsAspect?.hideCreate &&
    !bookmarkActionsAspect?.hideSave &&
    rightItems.push(
      <QuinoPopupToolbarButton
        text={translationService.translate(bookmarkActionsAspect?.customSaveAndNewTranslationKey || 'SaveAndNew')}
        icon={'material-icons-outlined add_box'}
        onClick={() => save(QuinoPopupEditorOnHideAction.createNewEntry)}
        disabled={saveDisabled}
        hint={shortcutInformation.hint(shortcutSettings.createEntryPrimary)}
      />
    );

  (canUpdate || (canCreate && isNew)) &&
    !bookmarkActionsAspect?.hideSave &&
    rightItems.push(
      <QuinoPopupToolbarButton
        loading={loadingOfSaveButton}
        text={translationService.translate(bookmarkActionsAspect?.customSaveTranslationKey || 'Save')}
        icon={bookmarkActionsAspect?.customSaveIcon || 'material-icons-outlined save'}
        isPrimary={true}
        onClick={() => save(QuinoPopupEditorOnHideAction.save)}
        disabled={saveDisabled}
        hint={shortcutInformation.hint(shortcutSettings.saveDetail)}
      />
    );

  bookmark && classActionItems.backActions.forEach((action) => rightItems.push(castActionToToolbarButton(action, bookmark)));

  return (
    <QuinoPopup
      visible={bookmark != null}
      bookmark={bookmark}
      title={title}
      width={props.customWidth ?? 1090}
      height={props.customHeight ?? 800}
      onHidden={props.onHidden}
      onHiding={() => !onHideCalled && props.onHide(QuinoPopupEditorOnHideAction.cancel)}
      onInitialized={(e) => e.component?.registerKeyHandler('escape', noop)}>
      <div className={'quino-popup-notification-wrapper'}>
        <QuinoInfoBar isDefault={false} customAreaID={QuinoPopupEditorNotificationAreaId} />
      </div>
      <QuinoPopupDefaultContent isScrollable={!bookmark?.rootNodeIsTabContainer()}>
        {bookmark && <QuinoMetaPropertyPanel isPopup={true} key={'EditPopupPropertyPanel'} {...props} bookmark={bookmark} actions={actions} />}
      </QuinoPopupDefaultContent>
      <QuinoPopupToolbar key={'EditPopupToolbar'} showTopBorder={true} leftItems={leftItems} rightItems={rightItems} />
    </QuinoPopup>
  );
}
