import { IBookmark, isILayoutAwareBookmark } from '../../bookmarks';
import { useMemo } from 'react';
import { IMetaAction, IMetaElement, IMetaGroup, isIMetaAction, isIMetaGroup, IVisibleCalculator, QuinoCoreServiceSymbols } from '@quino/core';
import { useService } from '../../ioc';

/**
 * Extract the actions from the given bookmark.
 * @param bookmark - the bookmark to extract from.
 */
export const useMetaActionsFromBookmark = (bookmark: IBookmark | undefined): IMetaAction[] => {
  const visibleCalculator = useService<IVisibleCalculator>(QuinoCoreServiceSymbols.IVisibleCalculator);

  return useMemo<IMetaAction[]>(() => {
    if (!bookmark || !isILayoutAwareBookmark(bookmark)) {
      return [];
    }

    const result: IMetaElement[] = [];
    visitElements(bookmark.layout, result);
    return result.filter((x) => isIMetaAction(x) && visibleCalculator.calculate(x, {})) as IMetaAction[];
  }, [bookmark, visibleCalculator]);
};

function visitElements(element: IMetaGroup, result: IMetaElement[]) {
  result.push(...element.elements);

  for (const child of element.elements) {
    if (isIMetaGroup(child)) {
      visitElements(child, result);
    }
  }
}
