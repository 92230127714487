import React from 'react';
import { IInitialBookmarkService } from '../../bookmarks';
import { QuinoUIServiceSymbols, useService } from '../../ioc';
import { ILoadingFeedback, ILoadingFeedbackSymbol, ILogger, QuinoCoreServiceSymbols } from '@quino/core';
import { useOnMount } from '../Util';
import { INavigationService, INavigationServiceSymbol } from '../../navigation';

export function QuinoBookmarkInitializer() {
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const initialBookmarkService = useService<IInitialBookmarkService>(QuinoUIServiceSymbols.IInitialBookmarkService);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  useOnMount(() => {
    const unload = loadingFeedback.load();
    initialBookmarkService
      .getFromPathOrHomeBookmark()
      .then((bookmark) => {
        bookmark && navigationService.set([bookmark]);
      })
      .catch((e) => logger.logError(`Failed to load initial bookmark during startup. ${JSON.stringify(e)}`))
      .finally(unload);
  });

  return <></>;
}
