import { IMetaElement, IMetaLayout, IMetaProperty, isIMetaGroup, isIMetaProperty } from '../meta';
import { IValidationResult } from './IValidationResult';
import { IValidationContext } from './IValidationContext';
import { IFieldError } from './IFieldError';
import { IValidator } from './IValidator';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IFieldValidatorProvider } from './IFieldValidatorProvider';
import { IExpressionEvaluator } from '../expressions';
import { IMetaPropertyValueService, IMetaPropertyValueServiceSymbol } from '../data/IMetaPropertyValueService';
import { IGenericObject } from '../data/IGenericObject';
import { ILogger } from '../logging';
import { IControlBehaviorRegistry } from './controlValidator';
import { IFieldValidationResult } from './IFieldValidationResult';

@injectable()
export class Validator implements IValidator {
  constructor(
    @inject(QuinoCoreServiceSymbols.IFieldValidatorProvider) private readonly fieldValidatorProvider: IFieldValidatorProvider,
    @inject(IMetaPropertyValueServiceSymbol) private readonly propertyValueService: IMetaPropertyValueService,
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly expressionEvaluator: IExpressionEvaluator,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(QuinoCoreServiceSymbols.ControlValidatorRegistry)
    private readonly controlValidatorRegistry: IControlBehaviorRegistry<IFieldValidationResult>
  ) {}

  validate(layout: IMetaLayout, data: object): IValidationResult {
    const context: IValidationContext = {
      layout,
      data
    };

    const fieldErrors = this.validateElements(this.flattenElements(layout, context), context);

    return {
      hasErrors: fieldErrors.length > 0,
      fieldErrors: fieldErrors
    };
  }

  private validateElements(elements: IMetaProperty[] = [], context: IValidationContext): IFieldError[] {
    const isEditable = (element: IMetaProperty) => {
      let isReadOnly = false;
      if (element.readOnly) {
        isReadOnly = this.expressionEvaluator.evaluate<boolean>(element.readOnly, context.data);
      }
      let isVisible = false;
      if (element.visible) {
        isVisible = this.expressionEvaluator.evaluate<boolean>(element.visible, context.data);
      }

      return !isReadOnly && isVisible;
    };
    const validateElement = (element: IMetaProperty) => this.validateElement(element, context);
    const concatenate = (array1: [], array2: []): IFieldError[] => array1.concat(array2);

    return elements.filter(isEditable).map(validateElement).reduce(concatenate, []);
  }

  private validateElement(element: IMetaProperty, context: IValidationContext): IFieldError[] {
    const fieldErrors: IFieldError[] = [];
    const value = this.propertyValueService.getFieldValue(element, context.data as IGenericObject);

    const fieldValidators = this.fieldValidatorProvider.getInstances();
    if (fieldValidators.length < 1) {
      this.logger.logWarn('No field validators registered in field validator provider');
    }

    for (const validator of fieldValidators) {
      const validationResult = validator.validate(element, value, context);
      if (validationResult.fieldErrors) {
        fieldErrors.push(...validationResult.fieldErrors);
      }
    }

    const validationResult = this.controlValidatorRegistry.execute(element);
    if (validationResult.fieldErrors) {
      fieldErrors.push(...validationResult.fieldErrors);
    }

    return fieldErrors;
  }

  private flattenElements(element: IMetaElement, context: IValidationContext): IMetaProperty[] {
    const isVisible = (element: IMetaElement) => {
      let isVisible = false;
      if (element.visible) {
        isVisible = this.expressionEvaluator.evaluate<boolean>(element.visible, context.data);
      }

      return isVisible;
    };
    const result: IMetaProperty[] = [];
    if (isIMetaGroup(element)) {
      element.elements.filter(isVisible).forEach((e) => {
        if (isIMetaProperty(e)) {
          result.push(e);
        } else if (isIMetaGroup(e)) {
          result.push(...this.flattenElements(e, context));
        }
      });
    }
    return result;
  }
}
