import { inject, injectable } from 'inversify';
import { ILayoutResolver, ResolveContext } from './ILayoutResolver';
import { getAspectOrDefault, ILayoutAspect, IMetaLayout, isDynamicLayoutString, isLayoutAspect, isStaticLayoutString } from '../meta';
import { LayoutScopeManager } from './LayoutScopeManager';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IMetadataTree } from '../api';
import { DefaultAspectIdentifier } from './IDefaultAspect';
import { ILogger } from '../logging';
import { LayoutScopeManagerSymbol } from './ILayoutScopeManager';
import { IFavoriteLayoutService, IFavoriteLayoutServiceSymbol } from './IFavoriteLayoutService';
import { IExpressionEvaluator, IVisibleCalculator } from '../expressions';

@injectable()
export class LayoutResolver implements ILayoutResolver {
  constructor(
    @inject(LayoutScopeManagerSymbol) public scopeManager: LayoutScopeManager,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) public metadataTree: IMetadataTree,
    @inject(IFavoriteLayoutServiceSymbol) public favoriteLayoutService: IFavoriteLayoutService,
    @inject(QuinoCoreServiceSymbols.IVisibleCalculator) public visibleCalculator: IVisibleCalculator,
    @inject(QuinoCoreServiceSymbols.ILogger) public logger: ILogger,
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) public expressionEvaluator: IExpressionEvaluator
  ) {}

  resolve(context: ResolveContext): IMetaLayout[] {
    const layouts = this.metadataTree
      .getLayouts(context.metaClass)
      .filter((x) => x.type === context.type && this.visibleCalculator.calculate(x, context.data));

    return this.scopeManager.reduce(layouts);
  }

  tryResolveSingle(context: ResolveContext): IMetaLayout | undefined {
    try {
      return this.resolveSingle(context);
    } catch {
      return undefined;
    }
  }

  resolveSingle(context: ResolveContext): IMetaLayout {
    const scopedLayouts = this.resolve(context);
    const metaClassLayouts = this.metadataTree.getLayouts(context.metaClass);
    const allLayouts = metaClassLayouts.filter((x) => x.type === context.type && this.visibleCalculator.calculate(x, {}));

    if (context.element != null) {
      for (const aspect of context.element.aspects) {
        // If we find a layout aspect with the correct type resolve it - ignoring visibility.
        if (isLayoutAspect(aspect)) {
          const layout = this.getLayoutOrDefaultFromAspect(metaClassLayouts, aspect, context.data);
          if (layout != null && layout.type === context.type) {
            return layout;
          }
        }
      }
    }

    const defaultLayoutScoped = this.getFavoriteOrDefault(scopedLayouts, context.metaClass);
    if (defaultLayoutScoped != null) {
      return defaultLayoutScoped;
    }

    if (scopedLayouts.length > 0) {
      return scopedLayouts[0];
    }

    const defaultLayout = this.getFavoriteOrDefault(allLayouts, context.metaClass);
    if (defaultLayout) {
      return defaultLayout;
    }

    if (allLayouts.length > 0) {
      return allLayouts[0];
    }

    // We're out of options at this point. Delegate the resolve to the MetadataTree.
    return this.metadataTree.getLayout(context.metaClass, context.type);
  }

  private getLayoutOrDefaultFromAspect(layouts: IMetaLayout[], aspect: ILayoutAspect, data: any) {
    let layoutName = '';
    if (isStaticLayoutString(aspect.layout)) {
      layoutName = aspect.layout;
    } else if (isDynamicLayoutString(aspect.layout)) {
      layoutName = this.expressionEvaluator.evaluate<string>(aspect.layout, data);
    }

    return layouts.find((x) => x.name.toLowerCase() == layoutName.toLowerCase());
  }

  private getFavoriteOrDefault(layouts: IMetaLayout[], className: string): IMetaLayout | undefined {
    const favoriteLayoutName = this.favoriteLayoutService.getFavoriteLayoutName(className);
    if (favoriteLayoutName) {
      const existingFavorite = layouts.find((x) => x.name.toLowerCase() === favoriteLayoutName.toLowerCase());
      if (existingFavorite && this.visibleCalculator.calculate(existingFavorite, {})) {
        return existingFavorite;
      }
    }

    return layouts.find((x) => getAspectOrDefault(x, DefaultAspectIdentifier) != null && this.visibleCalculator.calculate(x, {}));
  }
}
