import React from 'react';
import { InternalQuinoServerHealthChecker } from './InternalQuinoServerHealthChecker';
import { useService } from '../../ioc';
import { ILoadingFeedback, ILoadingFeedbackSymbol, INotificationService, INotificationServiceSymbol } from '@quino/core';
import { useOnMount } from '../Util';
import { firstErrorOccurredLocalStorageKey } from './ContainerLessQuinoServerHealthChecker';

export const QuinoServerHealthChecker: React.FunctionComponent = () => {
  const notificationService = useService<INotificationService>(INotificationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);

  useOnMount(() => localStorage.removeItem(firstErrorOccurredLocalStorageKey));

  return <InternalQuinoServerHealthChecker notificationService={notificationService} loadingFeedback={loadingFeedback} />;
};
