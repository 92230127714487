import { DataGridOptionsAspectIdentifier, IDataGridOptionsAspect, IQuinoDataGridConfiguration, QuinoDataGridConfigurationService } from '@quino/ui';
import {
  getAspectOrDefault,
  IAuthorizationService,
  IConfigurationService,
  IConfigurationServiceSymbol,
  IMetaClass,
  IMetaLayout,
  IMetaRelation,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { inject, injectable } from 'inversify';

@injectable()
export class CUIDataGridConfigurationService extends QuinoDataGridConfigurationService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IAuthorizationService) authorizationService: IAuthorizationService,
    @inject(IConfigurationServiceSymbol) private readonly configService: IConfigurationService
  ) {
    super(authorizationService);
  }

  getMetaDataGridConfiguration(element: IMetaLayout, metaClass: IMetaClass): IQuinoDataGridConfiguration {
    // Fetch the default settings from the base service
    const result = super.getMetaDataGridConfiguration(element, metaClass);

    // Basic overrides for CUI
    result.usePaging = false;
    result.useGroupPanel = false;
    result.useCrossLinks = true;
    result.useColumnReordering = true;
    result.useColumnSelection = true;
    result.useColumnResizing = true;

    // Apply global remote config
    this.configService.apply(result, 'DataGridConfiguration');

    // Apply custom options from the element
    const aspect = getAspectOrDefault<IDataGridOptionsAspect>(element, DataGridOptionsAspectIdentifier);
    const customOverlay = aspect != null ? aspect.options : {};

    return { ...result, ...customOverlay };
  }

  getInlineGridConfiguration(layout: IMetaLayout, relation?: IMetaRelation): IQuinoDataGridConfiguration {
    // Fetch the default settings from the base service
    const result = super.getInlineGridConfiguration(layout, relation);

    // Apply global remote config
    this.configService.apply(result, 'InlineDataGridConfiguration');

    if (relation) {
      // Apply custom options from the element and the layout
      const aspect = getAspectOrDefault<IDataGridOptionsAspect>(relation, DataGridOptionsAspectIdentifier);
      const layoutAspect = getAspectOrDefault<IDataGridOptionsAspect>(layout, DataGridOptionsAspectIdentifier);

      const customOverlay: Partial<IQuinoDataGridConfiguration> = aspect != null ? aspect.options : {};
      const layoutOverlay = layoutAspect != null ? layoutAspect.options : {};

      return {
        ...result,
        ...layoutOverlay,
        ...customOverlay,
        showPopupCreate:
          customOverlay.showPopupCreate != null ? customOverlay.showPopupCreate : customOverlay.showPopupEditor || result.showPopupEditor
      };
    }

    return { ...result };
  }

  getSearchEditGridConfiguration(relation?: IMetaRelation): IQuinoDataGridConfiguration {
    // Fetch the default settings from the base service
    const result = super.getSearchEditGridConfiguration(relation);

    if (relation) {
      // Apply custom options from the element
      const aspect = getAspectOrDefault<IDataGridOptionsAspect>(relation, DataGridOptionsAspectIdentifier);
      const customOverlay = aspect?.options ?? {};
      return { ...result, ...customOverlay };
    }

    return { ...result };
  }
}
