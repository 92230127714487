import React from 'react';
import { IMarkdownConverter, IMarkdownConverterSymbol, useService } from '@quino/ui';

export function CUIAuthenticationMarkdownBlock(props: { content: string | null; customClasses?: string }) {
  const markdownConverter = useService<IMarkdownConverter>(IMarkdownConverterSymbol);

  return props.content ? (
    <div
      className={`quino-auth-markdown ${props.customClasses ?? ''}`}
      dangerouslySetInnerHTML={{ __html: markdownConverter.convertToHtml(props.content) }}
    />
  ) : (
    <></>
  );
}
