import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  getAspectOrDefault,
  getMetaProperty,
  IAuthenticationService,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  INotificationService,
  INotificationServiceSymbol,
  IRequestDecoratorProvider,
  IRequestFactory,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols,
  ILogger
} from '@quino/core';
import { QuinoUIServiceSymbols, useService } from '../../ioc';
import { IQuinoFileHandler, IQuinoFileHandlerSymbol } from './api/IQuinoFileHandler';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { IFileInfo, IQuinoFileStorage, IQuinoFileStorageSymbol } from './api';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { IQuinoFileUploadConfigurationService, QuinoLabeledLabelPosition, useMetadata } from '../../settings';
import {
  useOnBookmarkReloadEvent,
  useOnBookmarkResetEvent,
  useOnBookmarkSavedEvent,
  useOnMountAsync,
  useRerenderOnChanges,
  useValidation
} from '../Util';
import { QuinoLabeled } from '../QuinoLabeled';
import { useControlValidation } from '../../bookmarks/useControlValidation';
import { useControlModifiedState } from '../../bookmarks/useControlModifiedState';
import { FileUploadOptionsAspectIdentifier, IFileUploadOptionsAspect } from '../../aspects';
import { IFileUploadValidator, IFileUploadValidatorSymbol } from '../../validations';
import { QuinoFileErrorBar } from './QuinoFileErrorBar';
import { QuinoFileSelectorBar } from './QuinoFileSelector';
import { Button as DataGridButton, Column, DataGrid } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { QuinoDataGridRowActionOverflow } from '../QuinoDataGrid/QuinoDataGridRowAction';
import notify from 'devextreme/ui/notify';
import { ProgressBar } from 'devextreme-react/progress-bar';
import DevExpress from 'devextreme';
import { FileUploader } from 'devextreme-react/file-uploader';
import { RowClickEvent } from 'devextreme/ui/data_grid';
import { DropZoneEnterEvent, ProgressEvent, UploadedEvent, UploadStartedEvent, ValueChangedEvent } from 'devextreme/ui/file_uploader';
import Queue from 'queue-promise';
import { TResponsiveMode, useResponsiveMode } from '../../responsivity';
import dxDataGrid = DevExpress.ui.dxDataGrid;

const supportedMimeTypes: Map<string, string[]> = new Map([
  ['image/png', ['.png']],
  ['image/jpeg', ['.jpeg', '.jpg']],
  ['application/pdf', ['.pdf']],
  ['text/csv', ['.csv']],
  ['application/msword', ['.doc']],
  ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', ['.docx']],
  ['application/vnd.ms-excel', ['.xls']],
  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', ['.xlsx']],
  ['application/vnd.ms-powerpoint', ['.ppt']],
  ['application/vnd.openxmlformats-officedocument.presentationml.presentation', ['.pptx']]
]);

export const QuinoFileUpload: React.FC<IQuinoComponentProps<ObjectBookmark>> = (props) => {
  const { element, bookmark } = props;
  const metaProperty = getMetaProperty(element);
  const { description, instruction, caption } = metaProperty;
  const { readOnly, required, visible, enabled } = useMetadata(metaProperty, bookmark.genericObject);

  const quinoFileHandler = useService<IQuinoFileHandler>(IQuinoFileHandlerSymbol);
  const fileUploadValidator = useService<IFileUploadValidator>(IFileUploadValidatorSymbol);
  const fileUploadConfigurationService = useService<IQuinoFileUploadConfigurationService>(QuinoUIServiceSymbols.IQuinoFileUploadConfigurationService);
  const fieldValueService = useService<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol);
  const notificationService = useService<INotificationService>(INotificationServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const quinoFileStorage = useService<IQuinoFileStorage>(IQuinoFileStorageSymbol);
  const authenticationService = useService<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService);
  const decoratorProvider = useService<IRequestDecoratorProvider>(QuinoCoreServiceSymbols.IRequestDecoratorProvider);
  const requestFactory = useService<IRequestFactory>(QuinoCoreServiceSymbols.IRequestFactory);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  const quinoFileUploadContainerRef = useRef<HTMLDivElement>(null);
  const quinoFileUploadOverlayRef = useRef<HTMLDivElement>(null);
  const customFileUploadButtonRef = useRef<Button>(null);
  const customFileUploadBDropZoneRef = useRef<HTMLDivElement>(null);
  const queue = useRef<Queue>(
    new Queue({
      concurrent: 1,
      interval: 100
    })
  );

  const uploadSettingsAspect = useMemo(() => {
    return getAspectOrDefault<IFileUploadOptionsAspect>(element, FileUploadOptionsAspectIdentifier);
  }, [element]);
  const responsiveMode = useResponsiveMode();

  const [files, setFiles] = useState<IFileInfo[] | undefined>(undefined);
  const [modifiedOnce, setModifiedOnce] = useState<boolean>(false);
  const [initialFiles, setInitialFiles] = useState<IFileInfo[] | undefined>(undefined);
  const [isValid, validationMessages] = useValidation(bookmark, metaProperty);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [maxFileSizeInBytes, setMaxFileSizeInBytes] = useState<number | undefined>();
  const [acceptedFileEndings, setAcceptedFileEndings] = useState<string>();
  const [acceptedMimeTypes, setAcceptedMimeTypes] = useState<string[]>([]);
  const [isDropZoneActive, setIsDropZoneActive] = useState<boolean>(false);
  const [currentSelectedFiles, setCurrentSelectedFiles] = useState<Array<IFileInfo>>([]);
  const [dataGrid, setDataGrid] = useState<dxDataGrid>();

  const fileUploadPrimaryKey = fieldValueService.getFieldValue<string>(metaProperty, bookmark.genericObject);
  const isInitialState = files == undefined || initialFiles == undefined || (fileUploadPrimaryKey == undefined && !modifiedOnce);

  const getUploadedFiles = (): IFileInfo[] => {
    return files ? files.filter((file) => file.uploadInProgress == undefined && file.uploadError == undefined) : [];
  };

  const getTemporaryFiles = (): IFileInfo[] => {
    return files ? files.filter((file) => file.uploadInProgress != undefined || file.uploadError != undefined) : [];
  };

  const getUploadInProgressFiles = (): IFileInfo[] => {
    return files ? files.filter((file) => file.uploadInProgress != undefined && file.uploadError == undefined) : [];
  };

  useControlValidation(
    metaProperty,
    () => fileUploadValidator.validateControl(metaProperty, getUploadedFiles(), getUploadInProgressFiles(), required),
    [metaProperty, files, required]
  );

  const modifiedFunc = (): boolean => {
    return JSON.stringify(initialFiles) != JSON.stringify(getUploadedFiles());
  };

  useControlModifiedState(metaProperty, bookmark, modifiedFunc, isInitialState, [JSON.stringify(getUploadedFiles()), initialFiles]);

  const fileUploadContext = (uploadSettingsAspect && uploadSettingsAspect.context) || undefined;
  const fileUploadOwnerClass =
    (uploadSettingsAspect && uploadSettingsAspect.ownerClass && uploadSettingsAspect.ownerClass) || bookmark.genericObject.metaClass;

  const init = async () => {
    const fileHandlerState = await quinoFileHandler.init(fileUploadOwnerClass, fileUploadPrimaryKey, fileUploadContext || null);
    setInitialFiles(fileHandlerState);
    setFiles(fileHandlerState);
  };

  useOnMountAsync(async () => {
    await init();

    // since firefox can not handle multiple mime types we have to supply extensions
    const rawMimeTypes = fileUploadConfigurationService.getFileUploadSettings(element).acceptedMimeTypesCsv.split(';');
    const allowedMimeTypes: string[] = [];
    const allowedFileEndings: string[] = [];
    rawMimeTypes.forEach((t: string) => {
      const trimmedValue = t.trim();
      const endings = supportedMimeTypes.get(trimmedValue);
      if (endings) {
        allowedMimeTypes.push(trimmedValue);
        allowedFileEndings.push(...endings);
      } else {
        logger.logWarn(`MIME type [${trimmedValue}] is not supported by QuinoFileUpload.`);
      }
    });

    setAcceptedMimeTypes(allowedMimeTypes);
    setAcceptedFileEndings(allowedFileEndings.sort().join(', '));

    const maxFileSizeInBytes = fileUploadConfigurationService.getFileUploadSettings(element).maxFileSizeInBytes;
    setMaxFileSizeInBytes(maxFileSizeInBytes);
  });

  useEffect(() => {
    const dragover = (e: any) => {
      if (readOnly || !enabled) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'none';
        e.dataTransfer.effectAllowed = 'none';
      }
    };

    if (quinoFileUploadContainerRef.current) {
      quinoFileUploadContainerRef.current.addEventListener('dragover', dragover, false);
    }

    return () => {
      if (quinoFileUploadContainerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        quinoFileUploadContainerRef.current.removeEventListener('dragover', dragover, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly, enabled, quinoFileUploadContainerRef.current]);

  useEffect(() => {
    if (quinoFileUploadOverlayRef.current && quinoFileUploadContainerRef.current) {
      // Sets the height of the overlay. This is necessary because the overlay has an absolut position with a z-index of 2. Minus 2 x vertical margin.
      quinoFileUploadOverlayRef.current.style.height = quinoFileUploadContainerRef.current.clientHeight - 8 + 'px';
    }
  }, [files, validationMessages]);

  useRerenderOnChanges(bookmark, metaProperty);
  useOnBookmarkSavedEvent(bookmark, async () => {
    // Do not use global variable as state will not get updated
    const fileUploadPrimaryKey = fieldValueService.getFieldValue<string>(metaProperty, bookmark.genericObject);
    if (!fileUploadPrimaryKey) {
      notificationService.notify({
        message: translationService.translate('FileUpload.Commit.Failed', { name: caption, primaryKeyPath: metaProperty.path })
      });
      return;
    }

    const result = await quinoFileHandler.commitAllFiles(fileUploadOwnerClass, fileUploadPrimaryKey, fileUploadContext);
    setFiles(result.fileMetaData);
    setInitialFiles(result.fileMetaData);
  });

  useOnBookmarkResetEvent(bookmark, () => {
    setFiles(quinoFileHandler.reset());
  });

  useOnBookmarkReloadEvent(bookmark, async () => {
    await init();
  });

  const onRowClick = (e: RowClickEvent) => {
    if (e.isSelected) {
      const currentSelectedRowKeys = [...e.component.getSelectedRowKeys().filter((rowKeys) => rowKeys != e.key)];
      selectFiles(currentSelectedRowKeys);
      void e.component.selectRows(currentSelectedRowKeys, false);
    } else {
      const currentSelectedRowKeys = [...e.component.getSelectedRowKeys(), e.key];
      selectFiles(currentSelectedRowKeys);
      void e.component.selectRows(currentSelectedRowKeys, false);
    }
  };

  const calculateDataGridHeight = (): number => {
    const maxRowsVisible = 5;
    const rowHeight = 43;
    const topPadding = 2 + (readOnly ? 1 : 0);
    return (
      topPadding +
      (files?.length && files?.length > maxRowsVisible ? rowHeight / 2.0 : 0) +
      Math.max(1, Math.min(maxRowsVisible, (files && files.length) || 0)) * rowHeight
    );
  };

  const selectFiles = useCallback((keys: any[]) => setCurrentSelectedFiles(files?.filter((f) => keys.includes(f.id)) ?? []), [files]);

  // https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
  function formatBytes(bytes: number, decimals = 0) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const onDropZoneEnter = (e?: DropZoneEnterEvent) => {
    if (e && e.dropZoneElement.id === 'quino-file-upload') {
      setIsDropZoneActive(true);
    }
  };

  const onDropZoneLeave = () => {
    setIsDropZoneActive(false);
  };

  const downloadFile = async (id: string, name: string) => {
    const blob = await quinoFileStorage.downLoadFile(id);
    if (blob) {
      // taken from https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
      const link = document.createElement('a');
      link.href = (window as any).URL.createObjectURL(blob);
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    } else {
      notify(translationService.translate('FileUpload.DownLoadError'), 'error', 3000);
    }
  };

  const headers = () => {
    const request = requestFactory.create(quinoFileStorage.getUploadUrl(), {
      method: 'POST'
    });
    decoratorProvider.getInstances().forEach((decorator) => decorator.decorate(request));
    authenticationService.authenticateRequest(request);

    return {
      'ACCEPT-LANGUAGE': request.headers.get('ACCEPT-LANGUAGE'),
      Authorization: request.headers.get('Authorization')
    };
  };

  const createTemporaryFileId = (file: File, uploadedTimestamp = ''): string =>
    file.lastModified + file.name + file.size + file.type + uploadedTimestamp;

  const uploadError = (request: XMLHttpRequest | string | undefined, errorFiles: File[] | undefined) => {
    if (request) {
      let errorText: string;
      if (typeof request === 'string') {
        errorText = request;
      } else {
        if (request.response) {
          try {
            errorText = JSON.parse(request.response).title;
          } catch {
            errorText = request.response;
          }
        } else {
          errorText = request.statusText;
        }
      }

      if (errorFiles && files && errorText) {
        const uploadTimestamp = Date.now().toString();
        setFiles([
          ...files.filter((file) => errorFiles.filter((errorFile) => file.id === createTemporaryFileId(errorFile)).length === 0),
          ...errorFiles.map((errorFile) => {
            return {
              id: createTemporaryFileId(errorFile, uploadTimestamp),
              length: errorFile.size,
              name: errorFile.name,
              contentType: errorFile.type,
              context: '',
              ownerClass: '',
              ownerPrimaryKey: '',
              uploadTimestamp: '',
              uploadError: errorText
            };
          })
        ]);
      } else if (errorText.length > 0) {
        setErrorMessages([errorText]);
      }
    }
  };

  const onUploadStarted = (e: UploadStartedEvent) => {
    onDropZoneLeave();
    if (
      uploadSettingsAspect &&
      uploadSettingsAspect.maxFiles &&
      getUploadedFiles().length + getUploadInProgressFiles().length >= uploadSettingsAspect.maxFiles
    ) {
      e.request.abort();

      const errorMessage = translationService.translate('FileUpload.Validations.InvalidFileUploadMaxNumber', {
        maxFiles: uploadSettingsAspect.maxFiles
      });
      uploadError(errorMessage, undefined);
      return;
    }

    if (maxFileSizeInBytes && e.file.size > maxFileSizeInBytes) {
      const errorMessage = translationService.translate('FileUpload.Validations.InvalidFileUploadMaxFileSize', {
        maxSizeMB: maxFileSizeInBytes && maxFileSizeInBytes / (1024 * 1024)
      });
      e.request.abort();

      uploadError(errorMessage, [e.file]);
      return;
    }

    if (!files) {
      return;
    }

    setFiles([
      ...files,
      {
        id: createTemporaryFileId(e.file),
        length: e.file.size,
        name: e.file.name,
        contentType: e.file.type,
        context: '',
        ownerClass: '',
        ownerPrimaryKey: '',
        uploadTimestamp: '',
        uploadInProgress: 0
      }
    ]);
  };

  const onProgress = (e: ProgressEvent) => {
    if (!files) {
      return;
    }
    setFiles(
      files.map((fileInfo) => {
        if (fileInfo.id === createTemporaryFileId(e.file)) {
          fileInfo.uploadInProgress = Math.floor((e.bytesLoaded / e.bytesTotal) * 100);
          return fileInfo;
        }
        return fileInfo;
      })
    );
  };

  const onUploaded = async (e: UploadedEvent) => {
    if (!files) {
      return;
    }

    const asyncTask = async () => {
      const temporaryFileId = createTemporaryFileId(e.file);
      const newFiles = await quinoFileHandler.addFile(JSON.parse(e.request.responseText).id);
      return { newFiles: newFiles, temporaryFileIdToRemove: temporaryFileId };
    };

    queue.current.enqueue(asyncTask);
  };

  useEffect(() => {
    const update = (taskResults: { newFiles: IFileInfo[]; temporaryFileIdToRemove: string }) => {
      const newTemporaryFiles = getTemporaryFiles().filter((fileInfo) => fileInfo.id != taskResults.temporaryFileIdToRemove);
      setFiles([...taskResults.newFiles, ...newTemporaryFiles]);
    };

    const currentQueue = queue.current;
    currentQueue.on('resolve', update);

    return () => {
      currentQueue.off('resolve', update);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  // since "accept={acceptedFileEndings}" is set to FileUpload we have to catch not allowed files here
  const onValueChanged = (e: ValueChangedEvent) => {
    setModifiedOnce(true);
    onDropZoneLeave();

    if (e.value == undefined || e.previousValue == undefined || !acceptedFileEndings) {
      return;
    }

    const newFiles = e.value.slice(e.previousValue.length, e.value.length);
    const notAllowedFiles = newFiles.filter((newFile) => !acceptedMimeTypes.includes(newFile.type));

    if (notAllowedFiles.length > 0) {
      const errorMessage = translationService.translate('FileUpload.Validations.InvalidFileType', { acceptedFiles: acceptedFileEndings });
      uploadError(errorMessage, notAllowedFiles);
    }
  };

  return (
    <div className={'quino-file-upload'} id={'quino-file-upload'} ref={customFileUploadBDropZoneRef}>
      <QuinoLabeled
        label={caption}
        required={required}
        visible={visible}
        instruction={instruction}
        description={description}
        labelPosition={QuinoLabeledLabelPosition.Top}
        valueTextAlign={files ? 'left' : 'center'}
        errorMessages={(!isValid && validationMessages) || undefined}>
        {(!files && <LoadIndicator height={50} width={50} />) || (
          <div>
            <div id={'quino-file-upload-container'} ref={quinoFileUploadContainerRef}>
              <div id={'quino-file-upload-overlay'} ref={quinoFileUploadOverlayRef} style={{ display: isDropZoneActive ? 'flex' : 'none' }}>
                {translationService.translate('FileUpload.DropField.DropItHere')}
              </div>
              <QuinoFileSelectorBar
                selectedFiles={currentSelectedFiles}
                clearSelection={() => {
                  dataGrid?.clearSelection();
                  selectFiles([]);
                }}
                onDeleteFiles={(files) => files.forEach((file) => setFiles(quinoFileHandler.deleteFile(file.id)))}
                onDownloadFiles={(files) =>
                  files.filter((file) => file.uploadError == undefined).forEach((file) => void downloadFile(file.id, file.name))
                }
                readonly={readOnly}
              />
              <DataGrid
                className={'quino-filemanager ' + (enabled && !readOnly ? 'is--editable' : 'is--disabled')}
                dataSource={files}
                keyExpr={'id'}
                height={calculateDataGridHeight()}
                disabled={!enabled}
                noDataText={translationService.translate('FileUpload.NoFiles')}
                showColumnLines={false}
                visible={(files && files.length > 0) || readOnly || !enabled}
                onInitialized={(e) => setDataGrid(e.component)}
                onRowClick={onRowClick}>
                <Column name={'iconColumn'} type={'buttons'} width={60} visible={responsiveMode != TResponsiveMode.Phone}>
                  <DataGridButton
                    render={(e: any) => {
                      if (e.data.uploadInProgress != undefined) {
                        return <i className='material-icons-outlined upload disabled' />;
                      } else if (e.data.uploadError != undefined) {
                        return <i className='material-icons-outlined error has--error' />;
                      } else if (e.data.contentType.includes('pdf')) {
                        return <i className='material-icons-outlined description' />;
                      } else {
                        return <i className='material-icons-outlined image' />;
                      }
                    }}
                  />
                </Column>
                <Column
                  name={'nameColumn'}
                  dataField='name'
                  cellRender={(cell: any) => {
                    if (cell.data.uploadError != undefined) {
                      return (
                        <div>
                          <div>{cell.value}</div>
                          <div className={'error-message'}>{cell.data.uploadError}</div>
                        </div>
                      );
                    }
                    return (
                      <div
                        className={
                          (cell.data.uploadInProgress != undefined ? 'disabled' : '') +
                          ' ' +
                          (responsiveMode == TResponsiveMode.Phone ? 'is--mobile' : '')
                        }>
                        {cell.value}
                      </div>
                    );
                  }}
                />
                <Column
                  name={'lengthColumn'}
                  dataField='length'
                  cellRender={(cell: any) => {
                    if (cell.data.uploadError != undefined) {
                      return <div />;
                    } else if (cell.data.uploadInProgress != undefined) {
                      return <ProgressBar showStatus={false} min={0} max={100} value={cell.data.uploadInProgress} />;
                    } else {
                      return <div>{formatBytes(cell.value)}</div>;
                    }
                  }}
                />
                <Column name={'actionColumn'} key={'rowActions'} cssClass={'quino-data-grid-action-column'} type={'buttons'} width={80}>
                  <DataGridButton
                    render={(e: any) => {
                      if (e.data.uploadError != undefined) {
                        return (
                          <Button
                            className={'close-button'}
                            type={'normal'}
                            stylingMode={'text'}
                            icon='close'
                            onClick={(clickEvent) => {
                              clickEvent.event?.preventDefault();
                              clickEvent.event?.stopPropagation();
                              files && setFiles(files.filter((fileInfo) => fileInfo.id != e.data.id));
                              const currentSelectedRowKeys = [...e.component.getSelectedRowKeys().filter((rowKeys: any) => rowKeys != e.data.id)];
                              selectFiles(currentSelectedRowKeys);
                            }}
                          />
                        );
                      } else if (e.data.uploadInProgress != undefined) {
                        return;
                      } else {
                        return (
                          <QuinoDataGridRowActionOverflow
                            rowKey={e.data.id}
                            buttonHint={''}
                            genericObject={e.data}
                            actions={[
                              {
                                caption: translationService.translate('Delete'),
                                hint: '',
                                icon: 'material-icons-outlined delete',
                                disabled: readOnly,
                                visible: true,
                                onClick: () => {
                                  setFiles([...quinoFileHandler.deleteFile(e.data.id), ...getTemporaryFiles()]);
                                  const currentSelectedRowKeys = [...e.component.getSelectedRowKeys().filter((rowKeys: any) => rowKeys != e.key)];
                                  selectFiles(currentSelectedRowKeys);
                                }
                              },
                              {
                                caption: translationService.translate(translationService.translate('FileUpload.Download')),
                                hint: '',
                                icon: 'material-icons-outlined get_app',
                                disabled: false,
                                visible: true,
                                onClick: async () => downloadFile(e.data.id, e.data.name)
                              }
                            ]}
                          />
                        );
                      }
                    }}
                  />
                </Column>
              </DataGrid>
              {!readOnly && enabled && (
                <div className={'quino-file-uploader'}>
                  <QuinoFileErrorBar errorMessages={errorMessages} onDismissErrorMessage={() => setErrorMessages([])} />
                  <FileUploader
                    name={'formFile'}
                    dialogTrigger={(customFileUploadButtonRef.current && (customFileUploadButtonRef.current as any)._element) || undefined}
                    dropZone={(customFileUploadBDropZoneRef.current && customFileUploadBDropZoneRef.current) || undefined}
                    onDropZoneEnter={onDropZoneEnter}
                    onDropZoneLeave={onDropZoneLeave}
                    uploadHeaders={headers()}
                    uploadUrl={quinoFileStorage.getUploadUrl()}
                    uploadMode={'instantly'}
                    uploadMethod={'POST'}
                    onProgress={onProgress}
                    onUploaded={onUploaded}
                    onUploadStarted={onUploadStarted}
                    onUploadError={(e) => uploadError(e.request, [e.file])}
                    onValueChanged={onValueChanged}
                    multiple={true}
                    accept={acceptedFileEndings}
                  />
                  <div className={'quino-file-upload-button-wrapper'}>
                    <Button
                      ref={customFileUploadButtonRef}
                      className={'quino-file-upload-button'}
                      text={translationService.translate('FileUpload.UploadButton')}
                    />
                    <div className={'quino-file-upload-label'}>{translationService.translate('FileUpload.DropField')}</div>
                  </div>
                </div>
              )}
            </div>
            {acceptedFileEndings && (
              <span className='quino-file-uploader-allowed-file-extensions'>
                {translationService.translate('FileUpload.Validations.AllowedExtensions')}
                <span className={'extensions'}>{acceptedFileEndings}</span>
              </span>
            )}
          </div>
        )}
      </QuinoLabeled>
    </div>
  );
};
