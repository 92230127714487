import { IGenericObject, IMetaLayout, IMetaProperty } from '@quino/core';
import { IQuinoDataGridProps } from './QuinoDataGrid';
import dxDataGrid from 'devextreme/ui/data_grid';

export const IQuinoDataGridColumnFactorySymbol = Symbol.for('IQuinoDataGridColumnFactory');

/**
 * Factory for generating data grid columns.
 */
export interface IQuinoDataGridColumnFactory {
  generate(
    property: IMetaProperty,
    forLocalSource: boolean,
    dataGridProps: IQuinoDataGridProps,
    onDrilldown: (data: IGenericObject) => void,
    canEdit?: boolean,
    dxDataGrid?: dxDataGrid,
    fixedColumnsWidth?: number,
    positionFromBack?: number,
    editMode?: boolean,
    editLayout?: IMetaLayout
  ): any;
}
