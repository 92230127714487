import { IStorageService } from './IStorageSevice';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IUrlManager } from '../api';
import { IRequestBuilder } from '../request';
import { LookupNormalizer } from './LookupNormalizer';
import { ILogger } from '../logging';

/**
 * Symbol for the PersistentStorageService
 */
export const PersistentStorageServiceSymbol = Symbol.for('PersistentStorageServiceSymbol');

@injectable()
export class PersistentStorageService implements IStorageService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger
  ) {}

  async get<TPayload>(key: string): Promise<TPayload> {
    const url = this.urlManager.getSettingUrl(LookupNormalizer.Normalize(key));

    return this.requestBuilder.create(url, 'get').requiresAuthentication().fetchJson<TPayload>();
  }

  async set<TPayload>(key: string, value: TPayload): Promise<void> {
    const url = this.urlManager.setSettingUrl(LookupNormalizer.Normalize(key));
    await this.requestBuilder.create(url, 'post').requiresAuthentication().setJsonPayload(value).fetch();
  }

  async delete(key: string): Promise<void> {
    const url = this.urlManager.getSettingUrl(LookupNormalizer.Normalize(key));

    await this.requestBuilder.create(url, 'delete').requiresAuthentication().fetch();
  }

  async clear(prefix?: string): Promise<void> {
    if (prefix) {
      this.logger.logError('Clearing PersistentStorageService with prefix is not implemented');
    } else {
      const url = this.urlManager.clearSettingsUrl();
      await this.requestBuilder.create(url, 'delete').requiresAuthentication().fetch();
    }
  }
}
