import React, { PropsWithChildren } from 'react';
import { Button } from 'devextreme-react/button';
import { useService } from '@quino/ui';
import { ILanguageService, QuinoCoreServiceSymbols, ILogger } from '@quino/core';

export function CUITranslationModeButton(props: PropsWithChildren<{ visible?: boolean }>) {
  const languageService = useService<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  return (
    <Button
      icon={'material-icons-outlined g_translate'}
      visible={props.visible != null ? props.visible : true}
      stylingMode={'text'}
      onClick={() => {
        languageService.setCurrentLanguageAsync('la').catch(logger.logError);
      }}
    />
  );
}
