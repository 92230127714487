import { IFileInfo } from './IFileInfo';

export const IQuinoFileStorageServiceSymbol = Symbol.for('IQuinoFileStorageService');

export interface IQuinoFileStorageService {
  /**
   * Find file info based on attributes
   * @param id
   * @param ownerClass
   * @param ownerPrimaryKey
   * @param context
   */
  getFileInfoAsync(id: string | null, ownerClass: string | null, ownerPrimaryKey: string | null, context: string | null): Promise<IFileInfo[]>;

  /**
   * Updates file info of a stored file
   * @param fileInfo
   */
  updateFileInfoAsync(fileInfo: Partial<IFileInfo>): Promise<void>;

  /**
   * Delete file from persistent storage
   * @param id The id of the file
   */
  deleteFileStorageAsync(id: string): Promise<any>;

  /**
   * Download file from persistent storage
   * @param id The id of the file
   */
  downloadFileAsync(id: string): Promise<Blob>;
}
