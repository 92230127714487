import { IExpression } from './IExpression';

/**
 * The generic identifier expression which can mark arbitrary identifiers independent of metadata.
 */
export interface IGenericIdentifierExpression extends IExpression {
  ExpressionType: number;
  name: string;
}

export const isGenericIdentifierExpression = (object: IExpression): object is IGenericIdentifierExpression => {
  return (object as IGenericIdentifierExpression).ExpressionType === 16 && (object as IGenericIdentifierExpression).name !== undefined;
};
