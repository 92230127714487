import { CalendarRequestObject } from './CalendarRequestObject';

export const ICalendarDataServiceSymbol = Symbol.for('ICalendarDataService');

export type TCalendarAppointment = {
  uid: string | undefined;
  summary: string | undefined;
  description: string | undefined;
  organizer: string | undefined;
  location: string | undefined;
  priority: string | undefined;
  start: Date | undefined;
  end: Date | undefined;
  isAllDay: boolean;
  metaClass: string | undefined;
  id: string | undefined;
  serializedRecurrenceRule: string | undefined;
  serializedExceptionRule: string | undefined;
};

export interface ICalendarDataService {
  /**
   * Gets a new calendar request object.
   * @param calendarName: Name of the calendar
   * @param from: The start-date of the fetch timespan
   * @param to: The end-date of the fetch timespan
   */
  getRequestObject: (calendarName: string, from: Date, to: Date) => CalendarRequestObject;
}
