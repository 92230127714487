import { IGenericObjectFactory } from './IGenericObjectFactory';
import { IGenericObject } from './IGenericObject';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IMetadataTree } from '../api';
import { ITitleCalculator, ITitleCalculatorSymbol } from './ITitleCalculator';

@injectable()
export class GenericObjectFactory implements IGenericObjectFactory {
  constructor(
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(ITitleCalculatorSymbol) private readonly titleCalculator: ITitleCalculator
  ) {}

  create(obj: any, metaClass: string): IGenericObject {
    this.enrichGenericObject(obj, metaClass);
    return obj;
  }

  private readonly enrichGenericObject = (obj: any, metaClass: string): void => {
    const actualClass = this.metadataTree.getClass(metaClass);

    if (!obj) {
      return;
    }

    if (obj.metaClass == null) {
      obj.metaClass = metaClass;
    }

    if (obj.title == null) {
      obj.title = this.titleCalculator.generate(obj, metaClass);
    }

    actualClass.primaryKey.forEach((primaryKey) => {
      if (this.isNewObjectPrimaryKey(obj[primaryKey])) {
        obj.primaryKey = 'null';
        obj[actualClass.primaryKey[0]] = null;
      } else if (obj.primaryKey == null) {
        obj.primaryKey = primaryKey + '=' + obj[primaryKey];
      }
    });
  };

  private readonly isNewObjectPrimaryKey = (primaryKey: any) => {
    return (
      primaryKey == null || primaryKey === 'null' || primaryKey === '0' || primaryKey === 0 || primaryKey === '00000000-0000-0000-0000-000000000000'
    );
  };
}
