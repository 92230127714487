import { IMetaAspect } from '../IMetaAspect';
import { IExpression } from '../../expressions';

/**
 * The identifier for retrieving a navigation link aspect.
 */
export const NavigationLinkAspectIdentifier = 'NavigationLinkAspect';

export type TOpenInTarget = 'current' | 'popup' | 'newTab';

/**
 * Navigation aspect containing the navigation link info
 */
export interface INavigationLinkAspect extends IMetaAspect {
  target: INavigationLinkTarget;
  openInNewTab?: boolean;
  openInTarget: TOpenInTarget;
}

export interface INavigationLinkTarget {
  targetType: string;
}
export const isINavigationLinkTarget = (object: any): object is INavigationLinkTarget => {
  return (object as INavigationLinkTarget).targetType !== undefined;
};

export interface INavigationLinkTargetAbsolute extends INavigationLinkTarget {
  url: IExpression | string;
}
export const isINavigationLinkTargetAbsolute = (object: any): object is INavigationLinkTargetAbsolute => {
  return isINavigationLinkTarget(object) && (object as INavigationLinkTargetAbsolute).url !== undefined;
};

export interface INavigationLinkTargetClass extends INavigationLinkTarget {
  metaClass: string;
  layout?: IExpression | string;
  filter?: any;
}
export const isINavigationLinkTargetClass = (object: any): object is INavigationLinkTargetClass => {
  return isINavigationLinkTarget(object) && (object as INavigationLinkTargetClass).metaClass !== undefined;
};

export interface INavigationLinkTargetObject extends INavigationLinkTarget {
  metaClass: string;
  primaryKey: string;
  layout?: IExpression | string;
  tabIndex?: number;
}
export const isINavigationLinkTargetObject = (object: any): object is INavigationLinkTargetObject => {
  return (
    isINavigationLinkTarget(object) &&
    (object as INavigationLinkTargetObject).metaClass !== undefined &&
    (object as INavigationLinkTargetObject).primaryKey !== undefined
  );
};
