import { IGenericObject } from '../../../../../data';
import { DataType, IMetaElement, isIMetaProperty, isMetaRelation } from '../../../../../meta';
import { ExcelMapperBase } from './ExcelMapperBase';
import { IQuinoExcelRow } from '../../model';
import { injectable } from 'inversify';

@injectable()
export abstract class ControlExcelMapperBase extends ExcelMapperBase {
  async getData(genericObject: IGenericObject, element: IMetaElement): Promise<IQuinoExcelRow[]> {
    const settings = await this.excelExportConfigurationService.getExportSettings(element);
    return [
      this.rowBuilder
        .withDefaultSettings(settings)
        .withCells([
          this.cellBuilder.withDefaultSettings(settings).withValue(element.caption).build(),
          this.cellBuilder
            .withDefaultSettings(settings)
            .withFormat(this.formatStringService.get(element))
            .withValue(this.getValue(element, genericObject))
            .build()
        ])
        .build()
    ];
  }

  getValue(element: IMetaElement, genericObject: IGenericObject): string | number | Date {
    if (isIMetaProperty(element)) {
      if (isMetaRelation(element)) {
        return this.titleCalculator.getTitle(element, genericObject);
      } else {
        const value = this.metaPropertyValueService.getFieldValue<any>(element, genericObject);
        if (element.dataType === DataType.Date || element.dataType === DataType.DateTime) {
          return new Date(value);
        }
        return value;
      }
    }
    return '';
  }

  conditionMatches(element: IMetaElement, genericObject: IGenericObject): boolean {
    return isIMetaProperty(element) && this.isVisible(element, genericObject);
  }
}
