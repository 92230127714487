import { DataType } from './index';
import { IMetaElement } from './IMetaElement';
import { IExpression } from '../expressions';

export type WidthMode = 'Absolute' | 'AutoSize' | 'Percent' | undefined;
export type TDateFormatString = 'GeneralDateTime' | 'ShortDate';

/**
 * Represents a final element in the meta tree. Usually complies to a property in the layout.
 */
export interface IMetaProperty extends IMetaElement {
  /**
   * The path to the property.
   */
  path: string;

  /**
   * Whether the element is required.
   */
  required: IExpression | boolean;

  /**
   * Whether the element is read only.
   */
  readOnly: IExpression | boolean;

  /**
   * The date type to use with this element.
   */
  dataType: DataType;

  /**
   * The description for this property. This usually provides additional information about what this property represents.
   */
  description?: string;

  /**
   * The instruction for this property. This usually provides additional information about what this property should be filled with.
   */
  instruction?: string;

  /**
   * @deprecated - use the aspect.
   */
  width?: string;

  /**
   * @deprecated - use the aspect.
   */
  widthMode?: WidthMode;

  /**
   * @deprecated - there's no point in this.
   */
  sortable?: boolean;

  /**
   * @deprecated - use the aspect.
   */
  formatString?: TDateFormatString;

  /**
   * @deprecated - instead check for a layout aspect or use a fallback.
   */
  targetViewName?: string;

  /**
   * Generator used to generate a value dynamically. Fields with a generator are not editable.
   */
  valueGenerator?: IExpression;

  /**
   * Whether the element is transient
   */
  transient?: boolean;
}

export function isIMetaProperty(object: IMetaElement): object is IMetaProperty {
  return (
    object != null &&
    (object as IMetaProperty).dataType !== undefined &&
    (object as IMetaProperty).path !== undefined &&
    (object as IMetaProperty).controlName !== undefined
  );
}

export function getMetaProperty(object: IMetaElement): IMetaProperty {
  if (!isIMetaProperty(object)) {
    throw new Error(`Element [${object.name}] is not a valid IMetaProperty. Full element: [${JSON.stringify(object)}]`);
  }

  return object;
}
