import { IExportFileService } from './IExportFileService';
import { IExportableFile } from './IExportableFile';
import FileSaver from 'file-saver';
import { injectable } from 'inversify';

@injectable()
export class ExportFileService implements IExportFileService {
  async exportFile(file: IExportableFile): Promise<void> {
    const fileBlob = await file.getBlob();
    FileSaver.saveAs(fileBlob, this.sanitizeFileName(file.getFileName()));
  }

  private sanitizeFileName(fileName: string): string {
    return fileName
      .replace(this.illegalCharRegex, '')
      .replace(this.controlCharactersRegex, '')
      .replace(this.reservedRegex, '')
      .replace(this.windowsReservedRegex, '')
      .replace(this.windowsTrailingRegex, '');
  }

  // eslint-disable-next-line no-useless-escape
  private readonly illegalCharRegex = /[\/\?<>\\:\*\|"]/g;
  // eslint-disable-next-line no-control-regex
  private readonly controlCharactersRegex = /[\x00-\x1f\x80-\x9f]/g;
  private readonly reservedRegex = /^\.+$/;
  private readonly windowsReservedRegex = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
  // eslint-disable-next-line no-useless-escape
  private readonly windowsTrailingRegex = /[\. ]+$/;
}
