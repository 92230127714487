import { injectable } from 'inversify';
import { IFrontendVersionInfoService } from './IFrontendVersionInfoService';
import { IFrontendVersionInfo } from './IFrontendVersionInfo';

@injectable()
export class FrontendVersionInfoService implements IFrontendVersionInfoService {
  public getFrontendVersionInfo = (): IFrontendVersionInfo => {
    const { version } = require('../../package.json');
    return {
      frontendVersion: version
    };
  };
}
