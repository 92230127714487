import DevExpress from 'devextreme';
import DataSource = DevExpress.data.DataSource;

export const ICUIScopeSelectorServiceSymbol = Symbol.for('ICUIScopeSelectorService');

export interface ICUISimpleSelectorOptions {
  dataSource: DataSource;
  displayExpression: string;
  valueExpression: string;
}

export interface ICUIScopeSelectorService {
  getSimpleSelectorOptions(): ICUISimpleSelectorOptions | undefined;
}
