import { IExpression } from '@quino/core';

export const ISharedDashboardsServiceSymbol = Symbol.for('ISharedDashboardsService');

export interface ISharedDashboardsService {
  /**
   * Gets the shared dashboards configuration
   */
  get(): Promise<string>;

  /**
   * Sets the shared dashboards configuration
   */
  set(value: string): Promise<void>;

  /**
   * Parses an Expression
   */
  parseExpression(stringExpression: string): Promise<IExpression | undefined>;
}
