import { DependencyList, useEffect, useState } from 'react';

export function usePromise<TResult, TError>(promise: () => Promise<TResult>, inputs?: DependencyList): [TResult | undefined, TError | undefined] {
  const [state, dispatch] = useState<{ result: [TResult | undefined, TError | undefined] }>({
    result: [undefined, undefined]
  });

  useEffect(
    () => {
      let dismissed = false;
      promise()
        .then((result) => {
          if (!dismissed) {
            dispatch({ result: [result, undefined] });
          }
        })
        .catch((error) => {
          if (!dismissed) {
            dispatch({ result: [undefined, error] });
          }
        });

      return () => {
        dismissed = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    inputs === undefined ? [] : inputs
  );

  return state.result;
}
