import React, { useCallback, useEffect, useState } from 'react';
import {
  IBookmark,
  IBookmarkFactory,
  IBookmarkTitleCalculator,
  IBookmarkTitleCalculatorSymbol,
  INavigationService,
  INavigationServiceSymbol,
  IObjectBookmark,
  IODataSourceFactory,
  IODataSourceFactorySymbol,
  isCtrlOrCmdEvent,
  isIMetaBookmark,
  isIObjectBookmark,
  QuinoUIServiceSymbols,
  useOnBookmarkSavedEvent,
  useOnNavigation,
  useService
} from '@quino/ui';
import {
  BreadcrumbParentAspectIdentifier,
  getAspectOrDefault,
  IBreadcrumbParentAspect,
  IGenericObject,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  IMetaRelation,
  LayoutType,
  MetaCardinality
} from '@quino/core';

export function CUIBreadcrumbs() {
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const titleCalculator = useService<IBookmarkTitleCalculator>(IBookmarkTitleCalculatorSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const odataFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const fieldValueService = useService<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol);
  const currentBookmark = useOnNavigation();

  const [previousBookmark, setPreviousBookmark] = useState<IBookmark | undefined>(undefined);
  const [previousNavigationBookmark, setPreviousNavigationBookmark] = useState<IBookmark | undefined>(currentBookmark);
  const [hierarchy, setHierarchy] = useState<IBookmark[]>([]);
  const [extracting, setExtracting] = useState<boolean>(false);
  const [showMoreIndicator, setShowMoreIndicator] = useState<boolean>(false);
  const [parentInfo, setParentInfo] = useState<{ relation: IMetaRelation; currentValue: any } | undefined>(undefined);
  const [savedGenericObject, setSavedGenericObject] = useState<IGenericObject | undefined>(undefined);

  const maxVisibleParentsDefault = 3;

  const getBreadcrumbAspect = (bookmark: IObjectBookmark) => {
    return (
      getAspectOrDefault<IBreadcrumbParentAspect>(bookmark.layout, BreadcrumbParentAspectIdentifier) ||
      getAspectOrDefault<IBreadcrumbParentAspect>(bookmark.metaClass, BreadcrumbParentAspectIdentifier)
    );
  };

  const getParentRelation = (bookmark: IObjectBookmark, relationName: string) => {
    return bookmark.metaClass.relations.find(
      (relation: IMetaRelation) => relation.name === relationName && relation.cardinality !== MetaCardinality.Multiple
    );
  };

  const extractParentBookmark = useCallback(
    async (childBookmark: IBookmark): Promise<IBookmark | undefined> => {
      if (isIObjectBookmark(childBookmark)) {
        const breadcrumbAspect = getBreadcrumbAspect(childBookmark);
        if (breadcrumbAspect) {
          const parentRelation = getParentRelation(childBookmark, breadcrumbAspect.parentRelationName);
          if (parentRelation) {
            const parentLayout = layoutResolver.resolveSingle({ metaClass: parentRelation.targetClass, type: LayoutType.Detail });
            const parentObjectPrimaryKey = fieldValueService.getFieldValue<string>(parentRelation, childBookmark.genericObject);
            if (parentObjectPrimaryKey) {
              const parentObject = await odataFactory.fetch(parentObjectPrimaryKey, parentLayout, parentRelation.targetClass);
              if (parentObject !== null && parentObject.primaryKey !== 'null') {
                return bookmarkFactory.createObject(parentObject);
              }
            }
          }
        }
      }
      return undefined;
    },
    [bookmarkFactory, fieldValueService, layoutResolver, odataFactory]
  );

  const extractHierarchy = useCallback(
    async (currentBookmark: IBookmark | undefined): Promise<IBookmark[]> => {
      const newHierarchy: IBookmark[] = [];

      // Generate parent list
      if (currentBookmark && isIObjectBookmark(currentBookmark)) {
        const initialAspect = getBreadcrumbAspect(currentBookmark);
        if (initialAspect) {
          const maxLevels = initialAspect.maxNumberOfLevels;
          const maxVisibleParents = maxLevels && maxLevels > 0 && maxLevels < maxVisibleParentsDefault ? maxLevels : maxVisibleParentsDefault;

          let parentBookmark = await extractParentBookmark(currentBookmark);
          while (parentBookmark && newHierarchy.length < maxVisibleParents) {
            newHierarchy.unshift(parentBookmark);
            parentBookmark = await extractParentBookmark(parentBookmark);
            if (newHierarchy.length === maxVisibleParents) {
              setShowMoreIndicator(parentBookmark !== undefined && maxVisibleParents !== maxLevels);
            }
          }
        }
      }

      // Show list of current object type if neither parents nor previous bookmark is available
      if (newHierarchy.length === 0 && navigationService.get().length <= 1 && isIObjectBookmark(currentBookmark)) {
        const parentListLayout = layoutResolver.resolveSingle({ metaClass: currentBookmark.metaClass.name, type: LayoutType.List });
        const parentList = await bookmarkFactory.createList(currentBookmark.metaClass.name, parentListLayout);
        newHierarchy.unshift(parentList);
      }

      return newHierarchy;
    },
    [bookmarkFactory, extractParentBookmark, layoutResolver, navigationService]
  );

  const renderLink = (bookmark: IBookmark, isBackLink: boolean) => {
    const backLinkPrefix = '‹ ';
    const bookmarkTitle = (isBackLink ? titleCalculator.generateWithLayout(bookmark) : titleCalculator.generate(bookmark)).trim();

    return (
      <a
        href={navigationService.extractUrl(bookmark)}
        title={(isIMetaBookmark(bookmark) && bookmark.layout.type === LayoutType.Detail ? `${bookmark.metaClass.caption}: ` : '') + bookmarkTitle}
        className={isBackLink ? 'back-link' : ''}
        onClick={async (event) => {
          if (!isCtrlOrCmdEvent(event)) {
            event.preventDefault();
            if (bookmark !== currentBookmark) {
              const unload = loadingFeedback.load();
              if (isBackLink) {
                await navigationService.set(navigationService.get().slice(0, -1), true);
              } else {
                await navigationService.push(bookmark);
              }
              unload();
            }
          }
        }}>
        {isBackLink && backLinkPrefix}
        {bookmarkTitle}
      </a>
    );
  };

  const renderHierarchyChain = (hierarchy: IBookmark[]) => {
    return (
      <>
        {showMoreIndicator ? (
          <>
            <span className={'quino-ecui-breadcrumbs-more'}>...</span>
            <span className={'quino-ecui-breadcrumbs-separator'}>/</span>
          </>
        ) : (
          <></>
        )}
        {hierarchy.map((bookmark, index) => (
          <React.Fragment key={index}>
            {renderLink(bookmark, false)}
            <span className={'quino-ecui-breadcrumbs-separator'}>/</span>
          </React.Fragment>
        ))}
      </>
    );
  };

  const storeParentInfo = useCallback(() => {
    if (currentBookmark && isIObjectBookmark(currentBookmark)) {
      const breadcrumbAspect = getBreadcrumbAspect(currentBookmark);
      if (breadcrumbAspect) {
        const parentRelation = getParentRelation(currentBookmark, breadcrumbAspect.parentRelationName);
        if (parentRelation) {
          setParentInfo({
            relation: parentRelation,
            currentValue: fieldValueService.getFieldValue(parentRelation, currentBookmark.genericObject)
          });
          return;
        }
      }
    }

    setParentInfo(undefined);
  }, [currentBookmark, fieldValueService]);

  const reload = useCallback(() => {
    setExtracting(true);
    const bookmarkStack = navigationService.get();
    setPreviousBookmark(bookmarkStack.length > 1 ? bookmarkStack[bookmarkStack.length - 2] : undefined);
    void extractHierarchy(currentBookmark).then((newHierarchy) => {
      setHierarchy(newHierarchy);
      setExtracting(false);
    });
  }, [currentBookmark, extractHierarchy, navigationService]);

  // Regenerate breadcrumb when parent relation field is changed
  useOnBookmarkSavedEvent(currentBookmark, () => isIObjectBookmark(currentBookmark) && setSavedGenericObject(currentBookmark.genericObject));
  useEffect(() => {
    if (savedGenericObject && parentInfo) {
      const newValue = fieldValueService.getFieldValue(parentInfo.relation, savedGenericObject);
      if (newValue !== parentInfo.currentValue) {
        setParentInfo({ ...parentInfo, currentValue: newValue });
        reload();
      }
    }
  }, [fieldValueService, parentInfo, reload, savedGenericObject]);

  // Regenerate breadcrumb when bookmark changes
  useEffect(() => {
    setPreviousNavigationBookmark(currentBookmark);
    if (isIObjectBookmark(previousNavigationBookmark) && isIObjectBookmark(currentBookmark)) {
      if (previousNavigationBookmark.genericObject.primaryKey === currentBookmark.genericObject.primaryKey) {
        return; // just reload bookmark if bookmark key changes
      }
    }
    storeParentInfo();
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBookmark, reload, storeParentInfo]);

  return (
    <div className='quino-ecui-breadcrumbs'>
      {previousBookmark && renderLink(previousBookmark, true)}
      {!extracting && hierarchy.length > 0 && renderHierarchyChain(hierarchy)}
      {!extracting && currentBookmark && <div className={'quino-ecui-breadcrumbs-current'}>{titleCalculator.generate(currentBookmark)}</div>}
    </div>
  );
}
