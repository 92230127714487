import {
  getAspectOrDefault,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  IMetaMenuItem,
  INavigationLinkAspect,
  NavigationLinkAspectIdentifier
} from '@quino/core';
import {
  IBookmark,
  IBookmarkFactory,
  INavigationLinkService,
  INavigationLinkServiceSymbol,
  INavigationService,
  INavigationServiceSymbol,
  QuinoUIServiceSymbols,
  useService
} from '@quino/ui';

export const useMenuItemNavigator = () => {
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const linkResolver = useService<INavigationLinkService>(INavigationLinkServiceSymbol);

  return async (item: IMetaMenuItem, openInNewTab: boolean): Promise<void | IBookmark> => {
    switch (item.target) {
      case 'Class': {
        const unload = loadingFeedback.load();
        try {
          const result = await bookmarkFactory.createListFromMenuEntry(item.context, item);
          if (openInNewTab) {
            window.open(navigationService.extractUrl(result), '_blank', 'noopener,noreferrer');
            return;
          } else {
            return await navigationService.set([result]);
          }
        } finally {
          unload();
        }
      }
      case 'Object':
      case 'Url': {
        const action = linkResolver.getOnClickAction(item.name, getAspectOrDefault<INavigationLinkAspect>(item, NavigationLinkAspectIdentifier));
        action && action();
        break;
      }
      case 'Calendar': {
        const unload = loadingFeedback.load();
        try {
          const result = await bookmarkFactory.createCalendarFromMenuEntry(item.context);
          if (openInNewTab) {
            window.open(navigationService.extractUrl(result), '_blank', 'noopener,noreferrer');
            return;
          } else {
            return await navigationService.set([result]);
          }
        } finally {
          unload();
        }
      }
    }
  };
};
