import { RefObject } from 'react';
import { PopupContext } from '../QuinoPopup';
import { useOnMount } from './useOnMount';

export const useKeepFocusInContainerWhileTabbing = (container: RefObject<HTMLElement>): void => {
  const fakeElementID = 'tab-guard-last-child';

  const handleKeyDownEvent = (event: KeyboardEvent) => {
    if (!(PopupContext.bookmarks.length > 0) && event.key.toLowerCase() === 'tab' && !event.shiftKey) {
      if (document.activeElement?.id === fakeElementID && container.current) {
        const firstFocusable: HTMLElement | null = container.current.querySelector('[tabindex]:not([tabindex="-1"])');
        firstFocusable && firstFocusable.focus();
      }
    }
  };

  useOnMount(() => {
    let fakeFocusElement: HTMLDivElement | null = null;
    if (!(PopupContext.bookmarks.length > 0) && container.current) {
      fakeFocusElement = document.createElement('div');
      fakeFocusElement.tabIndex = 0;
      fakeFocusElement.style.height = '0';
      fakeFocusElement.style.width = '0';
      fakeFocusElement.id = fakeElementID;

      container.current.appendChild(fakeFocusElement);
      document.addEventListener('keyup', handleKeyDownEvent);
    }

    return () => {
      document.removeEventListener('keyup', handleKeyDownEvent);
      fakeFocusElement && fakeFocusElement.remove();
    };
  });
};
