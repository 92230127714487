import { inject, injectable } from 'inversify';
import { IExportExcelConfigurationService } from './IExportExcelConfigurationService';
import { QuinoCoreServiceSymbols } from '../../../../ioc';
import { IExportExcelSettings } from './IExportExcelSettings';

@injectable()
export class DefaultExportExcelConfigurationService implements IExportExcelConfigurationService {
  constructor(@inject(QuinoCoreServiceSymbols.IExportExcelSettings) private readonly settings: IExportExcelSettings) {}

  getExportSettings = async (): Promise<IExportExcelSettings> => this.settings;
}
