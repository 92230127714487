import React, { PropsWithChildren } from 'react';

export function QuinoErrorSummary(props: PropsWithChildren<{ errorMessages?: string[]; topPadding?: boolean }>) {
  const { errorMessages, topPadding } = props;

  if (!errorMessages || errorMessages.length < 1) {
    return <></>;
  }

  return (
    <div className={`quino-invalid-message ${(topPadding && 'quino-invalid-message-top-padding') || ''}`}>
      {errorMessages.length == 1 ? (
        errorMessages[0]
      ) : (
        <ul>
          {errorMessages.map((errorMessage: string, index: number) => (
            <li key={index}>{errorMessage}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
