import { IBookmark, isIBookmark } from './IBookmark';
import { IObjectBookmark } from './IObjectBookmark';
import { ICustomBookmark, isICustomBookmark } from './ICustomBookmark';

export interface IOidcBookmark extends IBookmark, ICustomBookmark {
  scopes: string[];
  clientId: string | null;
  queryString: string;
  values: { [key: string]: string };
}

export const isIOidcBookmark = (object: any): object is IObjectBookmark => {
  return isICustomBookmark(object) && (object as IOidcBookmark).scopes && (object as IOidcBookmark).queryString != null;
};
