import { inject, injectable } from 'inversify';
import Guid from 'devextreme/core/guid';
import { DataType, mapToDevexpressSort, LookupNormalizer, QuinoCoreServiceSymbols, IMetadataTree } from '@quino/core';
import { IBookmarkSerializer } from './IBookmarkSerializer';
import { IRelatedListBookmark, IBookmark, isIRelatedListBookmark, RelatedListBookmark } from '../bookmarks';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../data';
import { getBaseUrl, getPathParts, pathContainsIdentifierSequence, removeSlashes } from './UrlUtilities';

export const RelatedListBookmarkSerializerSymbol = Symbol.for('RelatedListBookmarkSerializer');

@injectable()
export class RelatedListBookmarkSerializer implements IBookmarkSerializer<IRelatedListBookmark> {
  constructor(
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(IODataSourceFactorySymbol) private readonly odataFactory: IODataSourceFactory
  ) {}

  canSerialize(bookmark: IBookmark): boolean {
    return isIRelatedListBookmark(bookmark);
  }

  canDeserialize(path: string): boolean {
    return pathContainsIdentifierSequence(path, 'cui', 'r');
  }

  async deserialize(path: string): Promise<IRelatedListBookmark> {
    const parts = getPathParts(path);
    const cuiIndex = parts.indexOf('cui');

    if (cuiIndex <= -1) {
      return Promise.reject();
    }

    if (parts.length < cuiIndex + 4) {
      return Promise.reject();
    }

    // Split the url e.g. /cui/r/company/people/list/12
    const metaClass = parts[cuiIndex + 2];
    const relation = parts[cuiIndex + 3];
    const layout = parts[cuiIndex + 4];
    const foreignKey = parts[cuiIndex + 5];

    // Resolve the initial class e.g. Company
    const resolvedClass = this.metadataTree.getClass(metaClass);
    const resolvedRelation = resolvedClass.relations.find((x) => LookupNormalizer.Normalize(x.name) === LookupNormalizer.Normalize(relation))!;

    // Fetch the target class that is on the relation e.g. Person
    const targetClass = this.metadataTree.getClass(resolvedRelation.targetClass);

    // Resolve the layout
    const resolvedLayout = targetClass.layouts.find((x) => LookupNormalizer.Normalize(x.name) === LookupNormalizer.Normalize(layout));
    if (resolvedLayout == null) {
      return Promise.reject();
    }

    // Parse the foreign key via the target class type
    const dataType = targetClass.properties.find((x) => x.name === resolvedRelation.targetProperties[0])!.dataType;
    const value = dataType === DataType.Guid ? new Guid(foreignKey) : parseInt(foreignKey.toString(), 0);

    // Initialize the data source with the given filter.
    const dataSource = this.odataFactory.create(resolvedLayout, targetClass.name, {
      filter: [resolvedRelation.targetProperties[0], '=', value],
      sort: mapToDevexpressSort(resolvedRelation.sorts)
    });

    return new RelatedListBookmark(resolvedClass, targetClass, resolvedLayout, dataSource, resolvedRelation, value);
  }

  serialize(bookmark: IRelatedListBookmark): string {
    return new URL(
      `${removeSlashes(getBaseUrl())}/cui/r/${bookmark.sourceClass.name}/${bookmark.relation.name}/${bookmark.layout.name}/${bookmark.foreignKey}`,
      document.location.origin
    ).toString();
  }
}
