import * as React from 'react';
import { useState } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import { QuinoPopup, QuinoPopupSimpleText, QuinoPopupToolbar, QuinoPopupToolbarButton } from '../QuinoPopup';
import { useService } from '../../ioc';
import { ILogger, QuinoCoreServiceSymbols } from '@quino/core';

export interface IQuinoRememberChoicePopupProps {
  title: string;
  text: string;
  rememberChoiceText: string;
  acceptButtonText: string;
  declineButtonText: string;
  hideCallback: () => void;
  callback: (accept: boolean, rememberChoice: boolean) => Promise<void>;
}

export function QuinoRememberChoicePopup(props: IQuinoRememberChoicePopupProps) {
  const [rememberChoice, setRememberChoice] = useState<boolean>(false);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);

  const onClickWithSelection = async (accept: boolean) => {
    await props.callback(accept, rememberChoice);
    props.hideCallback();
  };

  return (
    <QuinoPopup visible={true} title={props.title} onHiding={props.hideCallback}>
      <QuinoPopupSimpleText text={props.text} />
      <QuinoPopupToolbar
        leftItems={[<CheckBox value={rememberChoice} onValueChanged={(e) => setRememberChoice(e.value)} text={props.rememberChoiceText} />]}
        rightItems={[
          <QuinoPopupToolbarButton
            text={props.declineButtonText}
            icon={'material-icons-outlined clear'}
            onClick={() => {
              onClickWithSelection(false).catch((e) => logger.logError(e));
            }}
          />,
          <QuinoPopupToolbarButton
            text={props.acceptButtonText}
            icon={'material-icons-outlined done'}
            isPrimary={true}
            onClick={() => {
              onClickWithSelection(true).catch((e) => logger.logError(e));
            }}
          />
        ]}
      />
    </QuinoPopup>
  );
}
