// noinspection SpellCheckingInspection
export const CONTROL_IDENTIFIER = {
  CHECKBOX: 'CheckBox',
  COLUMN: 'Column',
  COLUMNS: 'Columns',
  DATEPICKER: 'DatePicker',
  DATETIMEPICKER: 'DateTimePicker',
  GRIDEDITOR: 'GridEditor',
  GROUPBOX: 'GroupBox',
  GROUPROW: 'GroupRow',
  LABEL: 'Label',
  LOOKUPEDIT: 'LookupEdit',
  COMBOBOX: 'ComboBox',
  MULTILINETEXTEDITOR: 'MultiLineTextEditor',
  RADIOBUTTONGROUP: 'RadioButtonGroup',
  SEARCHEDIT: 'SearchEdit',
  SINGLELINETEXTEDITOR: 'SingleLineTextEditor',
  TABCONTAINER: 'TabContainer',
  TIMEPICKER: 'TimePicker',
  RESPONSIVEBOX: 'ResponsiveBox',
  IMAGEPICKER: 'ImagePicker',
  IMAGEURLPICKER: 'ImageUrlPicker',
  MULTILINERICHTTEXTEDITOR: 'MultiLineRichTextEditor',
  PASSWORDEDITOR: 'PasswordEditor',
  PASSWORD_REPETITION: 'PasswordRepetition',
  LIST: 'List',
  LANGUAGEPICKER: 'LanguagePicker',
  COLORPICKER: 'ColorPicker',
  BUTTON: 'Button',
  URLLABEL: 'UrlLabel',
  TRANSLATIONKEYPICKER: 'TranslationKeyPicker',
  XMLEDITOR: 'XMLEditor',
  METACLASSSELECTOR: 'MetaClassSelector',
  QUINOMODELEXPLORER: 'QuinoModelExplorer',
  ENTITYSELECTOR: 'EntitySelector',
  TIMESPAN: 'TimeSpan',
  OBJECTSUMMARY: 'ObjectSummary',
  FILEUPLOAD: 'FileUpload',
  MULTIPURPOSETEXTEDITOR: 'MultiPurposeTextEditor',
  MULTISELECTBOX: 'MultiSelectBox'
};
