import { IBookmarkSerializer } from './IBookmarkSerializer';
import { IBookmark, IBookmarkFactory, IObjectBookmark, isIObjectBookmark } from '../bookmarks';
import {
  ILayoutResolver,
  ILayoutResolverSymbol,
  IMetadataTree,
  IMetaLayout,
  IMetaProperty,
  IUrlHelper,
  LayoutType,
  LookupNormalizer,
  ODataUrlPathDelimiter,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../data';
import { QuinoUIServiceSymbols } from '../ioc';
import { StateFullBookmarkSerializer } from './StateFullBookmarkSerializer';
import { CuiQueryCustomValueIdentifier, getBaseUrl, getPathParts, pathContainsIdentifierSequence, removeSlashes } from './UrlUtilities';
import cloneDeep from 'lodash/cloneDeep';
import { flattenGroupProperties } from '../components/Util';

export const ObjectBookmarkSerializerSymbol = Symbol.for('ObjectBookmarkSerializer');

@injectable()
export class ObjectBookmarkSerializer extends StateFullBookmarkSerializer implements IBookmarkSerializer<IObjectBookmark> {
  constructor(
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(ILayoutResolverSymbol) private readonly layoutResolver: ILayoutResolver,
    @inject(IODataSourceFactorySymbol) private readonly odataFactory: IODataSourceFactory,
    @inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory,
    @inject(QuinoCoreServiceSymbols.IUrlHelper) urlHelper: IUrlHelper
  ) {
    super(urlHelper);
  }

  canSerialize(bookmark: IBookmark): boolean {
    return isIObjectBookmark(bookmark);
  }

  canDeserialize(path: string): boolean {
    return pathContainsIdentifierSequence(path, 'cui', 'o');
  }

  async deserialize(path: string): Promise<IObjectBookmark> {
    const parts = getPathParts(path);
    const cuiIndex = parts.indexOf('cui');

    if (cuiIndex <= -1 || parts.length < cuiIndex + 3) {
      return Promise.reject();
    }

    const metaClassName = parts[cuiIndex + 2];
    const primaryKey = parts[cuiIndex + 3];

    let layout = this.layoutResolver.resolveSingle({ metaClass: metaClassName, type: LayoutType.Detail });
    if (parts.length > cuiIndex + 3) {
      const resolvedLayout = this.metadataTree
        .getLayouts(metaClassName)
        .find((x) => LookupNormalizer.Normalize(x.name) === LookupNormalizer.Normalize(parts[cuiIndex + 4]));
      layout = resolvedLayout ?? layout;
    }

    let bookmark: IObjectBookmark | undefined;
    if (primaryKey === 'null') {
      bookmark = await this.bookmarkFactory.createNewObject(metaClassName, undefined, layout.name);
      super.deserializeState(path, bookmark);

      const customFieldValues = bookmark.getStateValue(CuiQueryCustomValueIdentifier);
      if (customFieldValues) {
        const deserialized = JSON.parse(customFieldValues);
        const keys = Object.keys(deserialized);
        if (deserialized && keys.length > 0) {
          bookmark.genericObject = { ...bookmark.genericObject, ...deserialized };
          bookmark.setHasChanges(true);
          // load relations
          for (const key of keys) {
            const relationToLoad = bookmark.metaClass.relations.find((relation) =>
              relation.sourceProperties.find((sourceProperty) => sourceProperty.toLowerCase() === key.toLowerCase())
            );

            const primaryKey = deserialized[key];
            if (relationToLoad && primaryKey) {
              const parentLayoutProperties = flattenGroupProperties(cloneDeep(bookmark.layout));
              const subLayoutProperties: IMetaProperty[] = [];
              parentLayoutProperties.forEach((property) => {
                const paths = property.path.split(ODataUrlPathDelimiter);
                if (paths.length > 1 && paths[0] === relationToLoad.targetClass) {
                  paths.shift();
                  const strippedPath = paths.join(ODataUrlPathDelimiter);
                  property.name = strippedPath;
                  property.path = strippedPath;

                  subLayoutProperties.push(property);
                }
              });

              const subLayout: IMetaLayout = {
                aspects: [],
                caption: '',
                controlName: '',
                enabled: false,
                name: '',
                readOnly: false,
                required: false,
                type: LayoutType.Detail,
                uri: '',
                visible: true,
                sorts: [],
                elements: subLayoutProperties
              };

              bookmark.genericObject[relationToLoad.name] = await this.odataFactory.fetch(primaryKey, subLayout, relationToLoad.targetClass);
            }
          }
        }

        bookmark.clearStateValue(CuiQueryCustomValueIdentifier, true);
      }
    } else {
      const genericObject = await this.odataFactory.fetch(primaryKey, layout, metaClassName);
      bookmark = this.bookmarkFactory.createObject(genericObject, layout);

      super.deserializeState(path, bookmark);
    }

    return bookmark;
  }

  serialize(bookmark: IObjectBookmark): string {
    return new URL(
      `${removeSlashes(getBaseUrl())}/cui/o/${bookmark.genericObject.metaClass}/${bookmark.genericObject.primaryKey}/${
        bookmark.layout.name
      }${this.serializeState(bookmark)}`,
      document.location.origin
    ).toString();
  }
}
