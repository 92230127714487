import { inject, injectable } from 'inversify';
import { INavigationService, INavigationServiceSymbol } from './INavigationService';
import {
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetadataTree,
  IMetaGroup,
  IMetaMenuItem,
  isIMetaGroup,
  isIMetaMenuItem,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { QuinoUIServiceSymbols } from '../ioc';
import { IBookmark, IBookmarkFactory, IInitialBookmarkService } from '../bookmarks';
import { IHomeNavigationService } from './IHomeNavigationService';

@injectable()
export class HomeNavigationService implements IHomeNavigationService {
  constructor(
    @inject(ILoadingFeedbackSymbol) private readonly loadingFeedback: ILoadingFeedback,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(QuinoUIServiceSymbols.IInitialBookmarkService) private readonly initialBookmarkService: IInitialBookmarkService,
    @inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory,
    @inject(INavigationServiceSymbol) private readonly navigationService: INavigationService
  ) {}

  navigateHome = async (): Promise<void> => {
    const getFirstValidMenuItem = (group: IMetaGroup): IMetaMenuItem | undefined => {
      for (const x of group.elements) {
        const endNode = isIMetaGroup(x) ? getFirstValidMenuItem(x) : x;
        if (isIMetaMenuItem(endNode)) {
          return endNode;
        }
      }
      return undefined;
    };

    const unload = this.loadingFeedback.load();

    let initialBookmark: IBookmark | undefined = await this.initialBookmarkService.getHomeBookmark();

    if (!initialBookmark) {
      const layoutElement = getFirstValidMenuItem(this.metadataTree.getMenuLayout());
      if (layoutElement) {
        initialBookmark = await this.bookmarkFactory.createList(layoutElement.context);
      }
    }

    if (initialBookmark) {
      try {
        await this.navigationService.set([initialBookmark]);
      } catch (e) {
        this.logger.logError(e);
      } finally {
        unload();
      }
    } else {
      window.location.pathname = '/';
      unload();
    }
  };
}
