import { IMessage, IMessenger } from './IMessenger';
import { injectable } from 'inversify';

@injectable()
export class Messenger implements IMessenger {
  notify(payload: IMessage): void {
    const sym = payload.symbol;
    if (this.registry.has(sym)) {
      this.registry.get(sym)!.forEach((x) => {
        x(payload);
      });
    }
  }

  subscribe<TPayload extends IMessage>(symbol: symbol, callback: (payload: TPayload) => void): symbol {
    const funcSymbol = Symbol();

    if (!this.registry.has(symbol)) {
      this.registry.set(symbol, new Map());
    }

    this.registry.get(symbol)!.set(funcSymbol, callback);

    return funcSymbol;
  }

  unsubscribe(symbol: symbol): void {
    this.registry.forEach((x) => {
      x.delete(symbol);
    });
  }

  private readonly registry: Map<symbol, Map<symbol, (payload: any) => void>> = new Map();
}
