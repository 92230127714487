import { IMetaElement } from './IMetaElement';

/**
 * Response delivered by the API-endpoint if an action was executed.
 */
export type IMetaActionExecutionResponse = 'None' | 'CallingObjectChanged' | 'MultipleObjectsChanged';

/**
 * Represents an action that can be executed in the context of the current layout or class.
 */
export interface IMetaAction extends IMetaElement {
  /**
   * Whether the current object needs to be saved before the action can be executed.
   */
  saveCallingObjectBeforeExecuting: boolean;
}

/**
 * Whether the given object is an IMetaAction.
 * @param object - the element to check.
 */
export function isIMetaAction(object: IMetaElement): object is IMetaAction {
  return object != null && (object as IMetaAction).saveCallingObjectBeforeExecuting !== undefined;
}
