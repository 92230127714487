import { IGenericObject, ILayoutResolver, ILayoutResolverSymbol, ILogger, IValidationResult, LayoutType, QuinoCoreServiceSymbols } from '@quino/core';
import { IQuinoMetaPanelActions } from '../Types/IQuinoMetaPanelActions';
import { IBookmark, IBookmarkFactory, isIObjectBookmark } from '../../bookmarks';
import { QuinoUIServiceSymbols } from '../../ioc';
import { inject, injectable } from 'inversify';
import { INavigationService, INavigationServiceSymbol } from '../../navigation';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../../data/IODataSourceFactory';
import { IGenericObjectPersistenceService, IGenericObjectPersistenceServiceSymbol } from '../../data/IGenericObjectPersistenceService';

@injectable()
export class DefaultQuinoMetaPanelActions implements IQuinoMetaPanelActions {
  constructor(
    @inject(IODataSourceFactorySymbol) private readonly dataSourceFactory: IODataSourceFactory,
    @inject(ILayoutResolverSymbol) private readonly layoutResolver: ILayoutResolver,
    @inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory,
    @inject(INavigationServiceSymbol) private readonly navigationService: INavigationService,
    @inject(QuinoCoreServiceSymbols.ILogger) public logger: ILogger,
    @inject(IGenericObjectPersistenceServiceSymbol) private readonly persistenceService: IGenericObjectPersistenceService
  ) {}

  async delete(data: IGenericObject | IGenericObject[]): Promise<boolean> {
    return this.persistenceService.delete(data);
  }

  async drilldown(data: IGenericObject, context?: any): Promise<IBookmark> {
    const layout = this.layoutResolver.resolveSingle({ metaClass: data.metaClass, type: LayoutType.Detail, element: context, data: data });
    const object = await this.dataSourceFactory.fetch(data.primaryKey!, layout, data.metaClass);
    const bookmark = this.bookmarkFactory.createObject(object, layout);
    if (isIObjectBookmark(this.navigationService.active)) {
      bookmark.parentBookmark = this.navigationService.active;
    }

    await this.navigationService.push(bookmark);

    return bookmark;
  }

  async save(data: IGenericObject, original: IGenericObject): Promise<IGenericObject> {
    return this.persistenceService.save(data, original);
  }

  async validate(bookmark: IBookmark): Promise<IValidationResult> {
    if (isIObjectBookmark(bookmark)) {
      return bookmark.validate();
    }
    return Promise.reject("Can't validate bookmark type");
  }
}
