import { INamespace } from '@quino/core';
import { inject, injectable } from 'inversify';
import { IResponsivityBreakpointService, IResponsivityBreakpointServiceSymbol } from '../responsivity';

export const BreakpointNamespaceSymbol = Symbol.for('BreakpointNamespace');

@injectable()
export class BreakpointNamespace implements INamespace {
  constructor(@inject(IResponsivityBreakpointServiceSymbol) private readonly breakpointService: IResponsivityBreakpointService) {}

  IsDesktop = (): boolean => {
    return this.getScreenWidth() > this.breakpointService.getTabletBreakpoint();
  };

  IsTablet = (): boolean => {
    return !this.IsDesktop() && !this.IsPhone();
  };

  IsPhone = (): boolean => {
    return this.getScreenWidth() < this.breakpointService.getPhoneBreakpoint();
  };

  name = 'Breakpoint';

  private getScreenWidth() {
    return window.innerWidth;
  }
}
