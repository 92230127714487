import { IApplication } from '../application';
import { IAuthenticationService } from './IAuthenticationService';
import { QuinoCoreServiceSymbols } from '../ioc';
import { OidcAuthenticationService } from './OidcAuthenticationService';
import { AuthenticationModule } from './AuthenticationModule';

export class OidcAuthenticationModule {
  static use = (application: IApplication): void => {
    AuthenticationModule.use(application);
    application.registerSingle<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService, OidcAuthenticationService);
  };
}
