import { IBookmarkKeyValueStore, IStateFullBookmark } from './IStateFullBookmark';

export class StateFullBookmark implements IStateFullBookmark {
  setStateValue = (key: string, value: any, doNotNotify?: boolean) => {
    const currentValue = this.state[key.toLowerCase()];
    const currentValueIsNull = currentValue === null || currentValue === undefined;
    const newValueIsNull = value === null || value === 'null' || value === undefined;
    if (value !== currentValue && !(newValueIsNull && currentValueIsNull)) {
      this.state[key.toLowerCase()] = value;
      !doNotNotify && this.stateChangeHandlers.forEach((callback) => callback(key.toLowerCase()));
    }
  };

  getStateValue = (key: string) => {
    return this.state[key.toLowerCase()];
  };

  getState = (): IBookmarkKeyValueStore => {
    return this.state;
  };

  clearStateValue(key: string, doNotNotify?: boolean): any {
    const newState: IBookmarkKeyValueStore = {};
    Object.keys(this.state).forEach((k) => {
      if (k !== key) {
        newState[k] = this.state[k];
      }
    });
    this.state = newState;
    !doNotNotify && this.stateChangeHandlers.forEach((callback) => callback(key.toLowerCase()));
  }

  subscribeToStateChange = (callback: (scopeKey: string) => void): Symbol => {
    const symbol = Symbol();
    this.stateChangeHandlers.set(symbol, callback);
    return symbol;
  };

  unsubscribeFromStateChange = (symbol: Symbol): void => {
    this.stateChangeHandlers.delete(symbol);
  };

  private state: IBookmarkKeyValueStore = [];
  protected readonly stateChangeHandlers = new Map<Symbol, (scopeKey: string) => void>();
}
