import { IBookmarkSerializer } from './IBookmarkSerializer';
import { CalendarBookmark, CalendarBookmarkSymbol, IBookmark, IBookmarkFactory, ICalendarBookmark, isICustomBookmark } from '../bookmarks';
import { inject, injectable } from 'inversify';
import { StateFullBookmarkSerializer } from './StateFullBookmarkSerializer';
import { IMetadataTree, IUrlHelper, QuinoCoreServiceSymbols } from '@quino/core';
import { getBaseUrl, getPathParts, pathContainsIdentifierSequence, removeSlashes } from './UrlUtilities';
import { QuinoUIServiceSymbols } from '../ioc';
import { isICalendarAspect } from '../calendar';

export const CalendarBookmarkSerializerSymbol = Symbol.for('CalendarBookmarkSerializer');

@injectable()
export class CalendarBookmarkSerializer extends StateFullBookmarkSerializer implements IBookmarkSerializer<ICalendarBookmark> {
  constructor(
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory,
    @inject(QuinoCoreServiceSymbols.IUrlHelper) urlHelper: IUrlHelper
  ) {
    super(urlHelper);
    this.calendarUrlHelper = urlHelper;
  }

  canSerialize(bookmark: IBookmark): boolean {
    return isICustomBookmark(bookmark) && bookmark.name === CalendarBookmarkSymbol;
  }

  canDeserialize(path: string): boolean {
    return pathContainsIdentifierSequence(path, 'cui', 'calendar');
  }

  async deserialize(path: string): Promise<ICalendarBookmark> {
    const parts = getPathParts(path);
    const cuiIndex = parts.indexOf('cui');

    if (cuiIndex <= -1 || parts.length < cuiIndex + 2) {
      return Promise.reject();
    }

    const calendarQueryOptions = path.split('?')[1].split('&');
    const calendarName = calendarQueryOptions.find((o) => o.startsWith('name='))?.split('=')[1];
    const aspect = this.metadataTree.model.aspects.find((x) => x.name.toUpperCase() === calendarName?.toUpperCase());

    if (calendarName && isICalendarAspect(aspect)) {
      const calendarBookmark = this.bookmarkFactory.createCalendar(aspect);
      super.deserializeState(path, calendarBookmark);

      return calendarBookmark;
    } else {
      return Promise.reject('Calendar name not supplied');
    }
  }

  serialize(bookmark: CalendarBookmark): string {
    const queryParams = this.calendarUrlHelper.combineQueryParams({ name: bookmark.aspect.name, ...bookmark.getState() });
    return new URL(`${removeSlashes(getBaseUrl())}/cui/calendar${queryParams}`, document.location.origin).toString();
  }

  private readonly calendarUrlHelper: IUrlHelper;
}
