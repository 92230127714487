import { IControlBehaviorRegistry, IMetaProperty, QuinoCoreServiceSymbols } from '@quino/core';
import { DependencyList, useEffect } from 'react';
import { IObjectBookmark } from './IObjectBookmark';
import { useService } from '../ioc';

export const useControlModifiedState = (
  element: IMetaProperty,
  bookmark: IObjectBookmark,
  modifiedFunc: () => boolean,
  initialState: boolean,
  deps: DependencyList
) => {
  const controlModifiedRegistry = useService<IControlBehaviorRegistry<boolean>>(QuinoCoreServiceSymbols.ControlModifiedRegistry);

  useEffect(() => {
    controlModifiedRegistry.register(element, modifiedFunc);
    if (!initialState) {
      bookmark.notifyChangeHandlers(bookmark.createObjectBookmarkEvent('changedInternally', [element.path]));
    }
    return () => controlModifiedRegistry.unRegister(element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmark, ...deps]);
};
