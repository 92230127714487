import { ILogger } from './ILogger';
import { injectable } from 'inversify';

/**
 * Empty logger implementation.
 */
@injectable()
export class NullLogger implements ILogger {
  log = (): void => {
    // NOP
  };

  logDebug = (): void => {
    // NOP
  };

  logInfo = (): void => {
    // NOP
  };

  logWarn = (): void => {
    // NOP
  };

  logError = (): void => {
    // NOP
  };
}
