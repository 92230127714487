import * as React from 'react';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import DevExpress from 'devextreme';
import {
  getResponsiveClassName,
  QuinoPopup,
  TResponsiveMode,
  useResponsiveMode,
  useService,
  TScreenHeight,
  useScreenHeightMode,
  usePromise
} from '@quino/ui';
import { CUILoginComponent } from './CUILoginComponent';
import { CUIResetTokenComponent } from './CUIResetTokenComponent';
import { CUIConfirmationDialogComponent } from './CUIConfirmationDialogComponent';
import { CUIResetPasswordComponent } from './CUIResetPasswordComponent';
import { ITranslationService, ITranslationServiceSymbol, IVersionInfoService, IVersionInfoServiceSymbol, IVersionInfo } from '@quino/core';
import { AuthenticationScreenState } from './AuthenticationScreenState';
import { useCuiSettings } from '../../util';
import { ICUIRegisterAccountComponentFactory, ICUIRegisterAccountComponentFactorySymbol } from './ICUIRegisterAccountComponentFactory';
import { CUILocalizedImage } from '../content/CUILocalizedImage';

interface ICUIQuinoAuthenticationComponentProps {
  initialScreenState?: AuthenticationScreenState;
  onLoggedIn: () => void;
}

export function CUIAuthenticationComponent(props: PropsWithChildren<ICUIQuinoAuthenticationComponentProps>) {
  const { initialScreenState, onLoggedIn } = props;
  const [hash, setHash] = useState(window.location.hash.toLowerCase());
  const [popupShown, setPopupShown] = useState(false);
  const versionInfoService = useService<IVersionInfoService>(IVersionInfoServiceSymbol);
  const settings = useCuiSettings();
  const responsiveMode = useResponsiveMode();
  const screenHeightMode = useScreenHeightMode();
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const registrationFactory = useService<ICUIRegisterAccountComponentFactory>(ICUIRegisterAccountComponentFactorySymbol);
  const [dxPopup, setDxPopup] = useState<DevExpress.ui.dxPopup>();

  const [versionInfo] = usePromise<IVersionInfo, any>(async () => versionInfoService.getVersionInfo(), []);

  useEffect(() => {
    const listener = () => {
      setHash(window.location.hash.toLowerCase());
    };
    window.addEventListener('hashchange', listener, false);

    return function cleanup() {
      window.removeEventListener('hashchange', listener, false);
    };
  }, []);

  const getHashScreenState = useCallback(() => {
    switch (hash) {
      case '#register':
        return AuthenticationScreenState.RegistrationForm;
      case '#forgotpassword':
        return AuthenticationScreenState.ResetTokenForm;
      case '#resetpassword':
        return AuthenticationScreenState.ResetPasswordForm;
      case '#confirmemail':
        return AuthenticationScreenState.RegistrationConfirmationForm;
      default:
        return null;
    }
  }, [hash]);

  const [screenState, setScreenState] = useState<AuthenticationScreenState>(
    initialScreenState ?? getHashScreenState() ?? AuthenticationScreenState.LoginForm
  );

  useEffect(() => {
    const hashScreenState = getHashScreenState();
    if (hashScreenState != null) {
      setScreenState(hashScreenState);
    }
  }, [getHashScreenState, hash]);

  useEffect(() => {
    dxPopup?.repaint();
  }, [dxPopup, screenState]);

  const goToLogin = () => {
    setScreenState(AuthenticationScreenState.LoginForm);
    setHash('');
    let searchParams = new URLSearchParams(window.location.search.substr(1));
    const url = searchParams.has('returnUrl') ? searchParams.get('returnUrl') : window.location.pathname + window.location.search;
    window.history.pushState('', document.title, url);
  };

  const getCurrentScreen = () => {
    switch (screenState) {
      case AuthenticationScreenState.RegistrationConfirmationForm:
      case AuthenticationScreenState.EmailConfirmationRequiredForm:
      case AuthenticationScreenState.EmailConfirmationSentForm:
      case AuthenticationScreenState.LoginForm:
      case AuthenticationScreenState.ResetPasswordConfirmationForm:
        return (
          <CUILoginComponent
            onLogin={onLoggedIn}
            screenState={screenState}
            focusRequested={popupShown}
            onAccountNotActivated={() => setScreenState(AuthenticationScreenState.EmailConfirmationRequiredForm)}
            onPasswordResetClick={() => setScreenState(AuthenticationScreenState.ResetTokenForm)}
            onUserRegistrationClick={() => setScreenState(AuthenticationScreenState.RegistrationForm)}
            onConfirmEmailSent={() => setScreenState(AuthenticationScreenState.EmailConfirmationSentForm)}
            goToLogin={goToLogin}
          />
        );
      case AuthenticationScreenState.ResetTokenForm:
        return (
          <CUIResetTokenComponent
            onGoBack={goToLogin}
            onStartPasswordReset={() => setScreenState(AuthenticationScreenState.ResetTokenRequestedForm)}
            focusRequested={popupShown}
          />
        );
      case AuthenticationScreenState.RegistrationForm: {
        const props = {
          onGoBack: goToLogin,
          focusRequested: popupShown,
          onRegister: () => setScreenState(AuthenticationScreenState.EmailConfirmationSentForm)
        };
        return registrationFactory.create(props);
      }
      case AuthenticationScreenState.ResetTokenRequestedForm: {
        const title = translationService.translate('QuinoAuthenticationFeedback.TitleResetTokenRequested');
        const description = translationService.translate('QuinoAuthenticationFeedback.TokenRequestedDescription');
        const buttonText = translationService.translate('QuinoAuthenticationFeedback.ButtonGotoLogin');
        const contactSupportString = translationService.translate('QuinoAuthenticationFeedback.HintContactSupport');
        const needHelpString = translationService.translate('QuinoAuthenticationFeedback.HintNeedHelp');
        const hintText = (
          <p className={'quino-auth-hint-text'}>
            {needHelpString} <a href={settings.supportUrl}>{contactSupportString}</a>
          </p>
        );

        return (
          <CUIConfirmationDialogComponent
            onPerformConfirmAction={goToLogin}
            goToLogin={undefined}
            title={title}
            description={description}
            buttonText={buttonText}
            hintText={hintText}
            icon={'material-icons-outlined lock'}
            buttonIsDefault={false}
            loading={false}
          />
        );
      }
      case AuthenticationScreenState.ResetPasswordForm:
        return (
          <CUIResetPasswordComponent
            focusRequested={popupShown}
            goToLogin={goToLogin}
            onPasswordChanged={() => setScreenState(AuthenticationScreenState.ResetPasswordConfirmationForm)}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <QuinoPopup
      visible={screenState !== AuthenticationScreenState.None}
      showTitle={false}
      width={responsiveMode === TResponsiveMode.Phone ? '90%' : 'auto'}
      maxWidth={responsiveMode === TResponsiveMode.Phone ? '320' : 'unset'}
      onInitialized={(e) => setDxPopup(e.component)}
      onShown={() => setPopupShown(true)}>
      <div className={getResponsiveClassName('quino-auth-content', responsiveMode)}>
        {settings.loginLogo && (
          <div className={getResponsiveClassName('quino-auth-logo-area', responsiveMode)}>
            <CUILocalizedImage
              alt={'Logo'}
              translationKey={'Login.Logo'}
              src={settings.loginLogo}
              className={getResponsiveClassName('quino-auth-logo', responsiveMode) + (screenHeightMode === TScreenHeight.Small ? ' hidden' : '')}
            />
            <div className={getResponsiveClassName('quino-auth-version', responsiveMode)}>
              {versionInfo?.frontend.frontendVersion + ' / ' + versionInfo?.backend.backendVersion}
            </div>
          </div>
        )}
        <div className={getResponsiveClassName('quino-auth-form-area', responsiveMode)}>{getCurrentScreen()}</div>
      </div>
    </QuinoPopup>
  );
}
