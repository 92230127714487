import { IMetaAspect } from '@quino/core';
import { IQuinoDropDownSettings } from '../settings/dropdown';

/**
 * The identifier for retrieving a drop down configuration aspect.
 */
export const DropDownOptionsAspectIdentifier = 'DropDownOptionsAspect';

/**
 * A configuration aspect for the drop down.
 */
export interface IDropDownOptionsAspect extends IMetaAspect {
  /**
   * The options available.
   */
  options: IQuinoDropDownSettings;
}
