// noinspection SpellCheckingInspection
export const QuinoUIServiceSymbols = {
  IComponentMappingProvider: Symbol.for('IComponentMappingProvider'),
  IComponentFactory: Symbol.for('IComponentFactory'),
  ICellRenderer: Symbol.for('ICellRenderer'),
  IComponentRegistry: Symbol.for('IComponentRegistry'),
  ICellRenderStrategy: Symbol.for('ICellRenderStrategy'),
  IValidationResultService: Symbol.for('IValidationResultService'),
  IHelpTextVisibilityStrategy: Symbol.for('IHelpTextVisibilityStrategy'),
  IBookmarkFactory: Symbol.for('IBookmarkFactory'),
  IQuinoMetaPanelActions: Symbol.for('IQuinoMetaPanelActions'),
  IQuinoDataGridConfigurationService: Symbol.for('IQuinoDataGridConfigurationService'),
  IQuinoLabelConfigurationService: Symbol.for('IQuinoLabelConfigurationService'),
  IQuinoFileUploadConfigurationService: Symbol.for('IQuinoFileUploadConfigurationService'),
  ILabelSettings: Symbol.for('ILabelSettings'),
  IFileUploadSettings: Symbol.for('IFileUploadSettings'),
  IValidationSettings: Symbol.for('IValidationSettings'),
  IQuinoDataGridFilterService: Symbol.for('IQuinoDataGridFilterService'),
  IBookmarkTrackChangesService: Symbol.for('IBookmarkTrackChangesService'),
  IInitialBookmarkService: Symbol.for('IInitialBookmarkService'),
  IPendingChangesSetting: Symbol.for('IPendingChangesSetting'),
  IQuinoDataGridSortService: Symbol.for('IQuinoDataGridSortService'),
  MarkdownHtmlRenderer: Symbol.for('MarkdownHtmlRenderer'),
  IQuinoAuthenticationComponentFactory: Symbol.for('IQuinoAuthenticationComponentFactory')
};
