import { injectable } from 'inversify';
import { IClientAction, IQuinoActionArgs } from '@quino/ui';

export const ConsoleLogActionSymbol = Symbol.for('ConsoleLogAction');

@injectable()
export class ConsoleLogAction implements IClientAction {
  onClick = (_args: IQuinoActionArgs) => {
    console.log(`ConsoleLogAction executed`);
  };
}
