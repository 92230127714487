import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for retrieving a expanded state aspect.
 */
export const ExpandedAspectIdentifier = 'expanded';

/**
 * Expanded aspect
 */
export interface IExpandedAspect extends IMetaAspect {
  expanded: boolean;
}
