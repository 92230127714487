import { IFileInfo } from './IFileInfo';

export const IQuinoFileStorageSymbol = Symbol.for('IQuinoFileStorage');

export interface IQuinoFileStorage {
  getUploadUrl(): string;

  setFileInfo(id: string, name: string, ownerClass: string, ownerPrimaryKey: string, context?: string): Promise<void>;

  getFileInfo(id: string | null, ownerClass: string | null, ownerPrimaryKey: string | null, context: string | null): Promise<IFileInfo[]>;

  deleteFile(id: string): Promise<void>;

  downLoadFile(id: string): Promise<Blob>;
}
