import * as React from 'react';
import { PropsWithChildren, useState } from 'react';
import { TextBox } from 'devextreme-react/text-box';
import { ValidationSummary } from 'devextreme-react/validation-summary';
import { Button } from 'devextreme-react/button';
import {
  AuthenticationState,
  IApplication,
  IAuthenticationResult,
  IAuthenticationService,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ITranslationService,
  ITranslationServiceSymbol,
  PasswordAuthenticationData,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { QuinoPopup } from '../QuinoPopup';
import { QuinoPasswordBox } from '../QuinoTextBox';
import { useOnMount } from '../Util';

interface IQuinoAuthenticationComponentState {
  username?: string;
  password?: string;
  isLoggedIn: boolean;
  error?: string[];
}

interface IQuinoAuthenticationComponentProps {
  application: IApplication;
  onLoggedIn: () => void;
}

export function QuinoAuthenticationComponent(props: PropsWithChildren<IQuinoAuthenticationComponentProps>) {
  const { onLoggedIn } = props;
  const authenticationService = props.application.get<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService);
  const translationService = props.application.get<ITranslationService>(ITranslationServiceSymbol);
  const loadingFeedback = props.application.get<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const [state, dispatch] = useState<IQuinoAuthenticationComponentState>({
    isLoggedIn: true
  });

  useOnMount(() => {
    const unload = loadingFeedback.load();
    authenticationService
      .isLoggedIn()
      .then((result) => {
        if (result) {
          dispatch({ ...state, isLoggedIn: true });
          onLoggedIn();
        } else {
          dispatch({ ...state, isLoggedIn: false });
        }
      })
      .catch(() => dispatch({ ...state, isLoggedIn: false }))
      .finally(unload);
  });

  return (
    <QuinoPopup
      visible={!state.isLoggedIn}
      title={translationService.translate('QuinoAuthenticationFeedback.Title')}
      showCloseButton={false}
      closeOnOutsideClick={false}
      height={300}
      width={400}>
      <form>
        <div className='dx-field'>
          <div className='dx-field-label'>{translationService.translate('QuinoAuthenticationFeedback.Username')}</div>
          <div className='dx-field-value'>
            <TextBox onValueChanged={(e) => dispatch({ ...state, username: e.value })} />
          </div>
        </div>
        <div className='dx-field'>
          <div className='dx-field-label'>{translationService.translate('QuinoAuthenticationFeedback.Password')}</div>
          <div className='dx-field-value'>
            <QuinoPasswordBox onValueChanged={(e) => dispatch({ ...state, password: e.value })} />
          </div>
        </div>
        <ValidationSummary visible={state.error !== undefined}>{state.error}</ValidationSummary>
        <Button
          text={translationService.translate('QuinoAuthenticationFeedback.Button')}
          icon={'material-icons-outlined lock_open'}
          className={'quino-authentication-component-button'}
          disabled={state.username == null || state.username.length === 0}
          onClick={() => {
            const failedToAuthenticateString = translationService.translate('QuinoAuthenticationFeedback.FailedToAuthenticate');
            const unload = loadingFeedback.load();
            authenticationService
              .loginAsync(new PasswordAuthenticationData(state.username!, state.password!))
              .then((result: IAuthenticationResult) => {
                if (result.state === AuthenticationState.Successful) {
                  dispatch({ ...state, error: undefined, isLoggedIn: true });
                  onLoggedIn();
                } else {
                  if (Array.isArray(result.error)) {
                    dispatch({ ...state, error: result.error.map((x) => x.description) });
                  } else if (result.error) {
                    dispatch({ ...state, error: [result.error] });
                  } else {
                    dispatch({ ...state, error: [failedToAuthenticateString] });
                  }
                }
              })
              .catch(() => dispatch({ ...state, error: [failedToAuthenticateString] }))
              .finally(unload);
          }}
        />
      </form>
    </QuinoPopup>
  );
}
