import { IMetaAspect } from '@quino/core';
import { ICalendarConfiguration, ICalendarExportConfiguration } from '../configuration';

/**
 * The identifier for retrieving a calendar configuration aspect.
 */
export const CalendarAspectIdentifier = 'CalendarAspect';

export interface ICalendarAspect extends IMetaAspect {
  /**
   * The caption of the calendar.
   */
  caption: string;

  /**
   * The name of the calendar.
   */
  name: string;

  /**
   * The configuration available.
   */
  configuration?: ICalendarConfiguration;

  /**
   * The export-configuration available.
   */
  exportConfiguration?: ICalendarExportConfiguration;
}

export function isICalendarAspect(object: IMetaAspect | undefined | null): object is ICalendarAspect {
  return object != null && (object as ICalendarAspect).caption !== undefined && (object as ICalendarAspect).name !== undefined;
}
