import { ISessionState } from './ISessionState';
import { ISessionEventTarget, TStateChangedListener } from './ISessionEventTarget';
import { injectable } from 'inversify';

@injectable()
export class SessionEventTarget implements ISessionEventTarget {
  addStateChangedListener(listener: TStateChangedListener): void {
    this.stateChangedListeners.push(listener);
  }

  removeStateChangedListener(listener: TStateChangedListener): void {
    this.stateChangedListeners = this.stateChangedListeners.filter((l) => l !== listener);
  }

  dispatch(oldSessionState: ISessionState, newSessionState: ISessionState) {
    this.stateChangedListeners.forEach((listener) => listener(newSessionState, oldSessionState));
  }

  private stateChangedListeners: TStateChangedListener[] = [];
}
