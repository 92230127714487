import { IOidcBookmark } from './IOidcBookmark';
import { OidcAuthorization } from '../components/Oidc';
import React, { ReactElement } from 'react';

export class OidcBookmark implements IOidcBookmark {
  constructor(private readonly params: URLSearchParams) {}

  get scopes(): string[] {
    return this.params.get('scope')?.split(' ') ?? [];
  }

  get clientId(): string | null {
    return this.params.get('client_id');
  }

  get values(): { [key: string]: string } {
    const info: { [key: string]: string } = {};
    for (let [key, value] of this.params.entries()) {
      info[key] = value;
    }
    return info;
  }

  get queryString(): string {
    return (
      '?' +
      Object.entries(this.values)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    );
  }

  render(): ReactElement {
    return <OidcAuthorization bookmark={this} />;
  }

  renderActions(): React.ReactElement {
    return <></>;
  }

  readonly name: Symbol = Symbol.for('OidcBookmark');
  readonly title: string = '';
}
