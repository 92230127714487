import React, { useEffect, useState } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import {
  BookmarkScopingServiceSymbol,
  IBookmarkScopingService,
  INavigationService,
  INavigationServiceSymbol,
  IODataSourceFactory,
  IODataSourceFactorySymbol,
  isIObjectBookmark,
  isStateFullBookmark,
  useOnMount,
  useService,
  IHomeNavigationService,
  IHomeNavigationServiceSymbol
} from '@quino/ui';
import { ICUIScopeSelectorService } from '../../configuration';
import { ICUIScopeSelectorServiceSymbol } from '../../configuration/ICUIScopeSelectorService';
import {
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ITranslationService,
  ITranslationServiceSymbol,
  LayoutType
} from '@quino/core';

export interface ICUISimpleScopeSelectorProps {
  onScopeChangeCallback?: (caption: string | undefined) => void;
  standalone?: boolean;
}

export function CUISimpleScopeSelector(props: ICUISimpleScopeSelectorProps) {
  const scopingService = useService<IBookmarkScopingService>(BookmarkScopingServiceSymbol);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const homeNavigationService = useService<IHomeNavigationService>(IHomeNavigationServiceSymbol);
  const scopeSelectorService = useService<ICUIScopeSelectorService>(ICUIScopeSelectorServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const oDataSourceFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);

  const simpleSelectorOptions = scopeSelectorService.getSimpleSelectorOptions();

  const [scope, setScope] = useState<string | undefined>();

  useEffect(() => {
    if (scope !== undefined && scopingService.getScope() != scope) {
      scopingService.setScope(scope);
    }
  }, [scope, scopingService]);

  useOnMount(() => {
    const bookmark = navigationService.active;
    if (isStateFullBookmark(bookmark)) {
      const scopeValue = bookmark.getStateValue('scope');
      if (scopeValue && scopeValue !== 'null') {
        setScope(scopeValue);
      }
    }
  });

  useEffect(() => {
    void (async () => {
      if (!props.onScopeChangeCallback) {
        return;
      }

      let caption: string | undefined = undefined;
      if (scope != null) {
        if (simpleSelectorOptions) {
          await simpleSelectorOptions.dataSource.load();
          const scopeItems = simpleSelectorOptions.dataSource.items();
          const scopeItem = scopeItems.find((item) => {
            const value = item[simpleSelectorOptions.valueExpression];
            return value.toString() == scope.toString();
          });
          if (scopeItem) {
            caption = scopeItem[simpleSelectorOptions.displayExpression];
          }
        }
      }
      props.onScopeChangeCallback(caption);
    })();
  }, [props, scope, simpleSelectorOptions]);

  const checkCurrentBookmarkIsInScope = (scope: string | undefined) => {
    const bookmark = navigationService.active;

    if (isIObjectBookmark(bookmark) && scope && scope != '') {
      // check if bookmark visible with new scope
      const unload = loadingFeedback.load();
      const layout = layoutResolver.resolveSingle({ type: LayoutType.List, metaClass: bookmark.metaClass.name });
      const metaClass = bookmark.metaClass.name;
      const primaryKeysQuery = bookmark.metaClass.primaryKey.map((primaryKeyName) => [primaryKeyName, '=', bookmark.genericObject[primaryKeyName]]);
      const filter: any[] = [];
      primaryKeysQuery.forEach((query, index) => {
        if (index > 0) {
          filter.push('and');
        }
        filter.push(query);
      });

      const dataSource = oDataSourceFactory.create(layout, metaClass, {
        pageSize: 1,
        filter: filter
      });

      dataSource
        .load()
        .then(async (result) => {
          unload();
          if (result.length < 1) {
            await homeNavigationService.navigateHome();
          }
        })
        .catch(unload);
    }
  };

  return (
    <SelectBox
      onContentReady={(e) => {
        // @ts-ignore devex private API
        e.component._setListOption('showScrollbar', 'always');
      }}
      className={(props.standalone && 'quino-ecui-header-scope-selector') || undefined}
      showClearButton={true}
      acceptCustomValue={false}
      searchEnabled={true}
      searchMode={'contains'}
      value={scope}
      dataSource={simpleSelectorOptions?.dataSource || []}
      valueExpr={simpleSelectorOptions?.valueExpression}
      displayExpr={simpleSelectorOptions?.displayExpression}
      onValueChanged={(e) => {
        setScope(e.value);
        checkCurrentBookmarkIsInScope(e.value);
      }}
      placeholder={translationService.translate('ScopePlaceholder')}
    />
  );
}
