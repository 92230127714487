export const QuinoCoreServiceSymbols = {
  IApplication: Symbol.for('IApplication'),
  Headers: Symbol.for('Headers'),
  HeadersFactory: Symbol.for('HeadersFactory'),
  IAuthenticationService: Symbol.for('IAuthenticationService'),
  ITokenRequestService: Symbol.for('ITokenRequestService'),
  IAuthorizationService: Symbol.for('IAuthorizationService'),
  IDataDiffer: Symbol.for('IDataDiffer'),
  IDataService: Symbol.for('IDataService'),
  IStorage: Symbol.for('IStorage'),
  IQuinoServerServiceConfiguration: Symbol.for('IQuinoServerServiceConfiguration'),
  IQuinoTranslationBackend: Symbol.for('IQuinoTranslationBackend'),
  ISession: Symbol.for('ISession'),
  ISessionEventTarget: Symbol.for('ISessionEventTarget'),
  ITranslationProvider: Symbol.for('ITranslationsProvider'),
  IUrlHelper: Symbol.for('IUrlHelper'),
  IUrlManager: Symbol.for('IUrlManager'),
  Window: Symbol.for('WINDOW'),
  IValidator: Symbol.for('IValidator'),
  IValidationMessageProvider: Symbol.for('IValidationMessageProvider'),
  IDefaultTranslations: Symbol.for('IDefaultTranslations'),
  IRequestDecoratorProvider: Symbol.for('IRequestDecoratorProvider'),
  IContextDecoratorProvider: Symbol.for('IContextDecoratorProvider'),
  IRequestFactory: Symbol.for('IRequestFactory'),
  IRequestBuilder: Symbol.for('IRequestBuilder'),
  IMetadataTree: Symbol.for('IMetadataTree'),
  ILogger: Symbol.for('ILogger'),
  IAuthenticationFeedback: Symbol.for('IAuthenticationFeedback'),
  IIdentityService: Symbol.for('IIdentityService'),
  IFieldValidatorProvider: Symbol.for('IFieldValidatorProvider'),
  ControlValidatorRegistry: Symbol.for('ControlValidatorRegistry'),
  ControlModifiedRegistry: Symbol.for('ControlModifiedRegistry'),
  ILanguageService: Symbol.for('ILanguageService'),
  IExpressionEvaluator: Symbol.for('IExpressionEvaluator'),
  IFormatStringService: Symbol.for('IFormatStringService'),
  IMetaElementFactory: Symbol.for('IMetaElementFactory'),
  IExpressionEvaluatorProvider: Symbol.for('IExpressionEvaluatorProvider'),
  IValueListTools: Symbol.for('IValueListTools'),
  IResponseHandler: Symbol.for('IResponseHandler'),
  IReadOnlyCalculator: Symbol.for('IReadOnlyCalculator'),
  IEnabledCalculator: Symbol.for('IEnabledCalculator'),
  IRequiredCalculator: Symbol.for('IRequiredCalculator'),
  IVisibleCalculator: Symbol.for('IVisibleCalculator'),
  IExportExcelService: Symbol.for('IExportExcelService'),
  IExcelMapperProvider: Symbol.for('IExcelMapperProvider'),
  IExportFileService: Symbol.for('IExportFileService'),
  IExportExcelSettings: Symbol.for('IExportExcelSettings'),
  IExportExcelConfigurationService: Symbol.for('IExportExcelConfigurationService'),
  IFrontendVersionInfoService: Symbol.for('IFrontendVersionInfoService'),
  IBackendVersionInfoService: Symbol.for('IBackendVersionInfoService')
};
