import { inject, injectable } from 'inversify';
import { ILanguageService } from './ILanguageService';
import { QuinoCoreServiceSymbols } from '../ioc';
import { ILanguageMetadata } from './ILanguageMetadata';
import { IRequestBuilder } from '../request';
import { IUrlManager } from '../api/QuinoServer';
import { IStorageService, LocalStorageServiceSymbol, PersistentStorageServiceSymbol } from '../storage';

type LanguageSetting = {
  key: string;
  translationModeEnabled: boolean;
};

@injectable()
export class QuinoLanguageService implements ILanguageService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder,
    @inject(PersistentStorageServiceSymbol) private readonly settingsService: IStorageService,
    @inject(LocalStorageServiceSymbol) private readonly localStorageService: IStorageService
  ) {}

  async getLanguageListAsync(): Promise<ILanguageMetadata[]> {
    if (this.languageList == null) {
      const url = this.urlManager.getLanguageListUrl();
      this.languageList = this.requestBuilder.create(url, 'get').fetchJson<ILanguageMetadata[]>();
    }

    return this.languageList;
  }

  async toggleTranslationMode(): Promise<void> {
    const languageSettings = await this.getCachedLanguageSetting();

    if (languageSettings && languageSettings.translationModeEnabled !== undefined) {
      await this.setCurrentLanguageAsync(languageSettings.translationModeEnabled ? languageSettings.key : this.latinKey);
    } else {
      await this.setCurrentLanguageAsync(this.latinKey);
    }
  }

  async getCurrentLanguageAsync(): Promise<string> {
    const queryLanguage = await this.getLanguageFromQuery();
    const languageSettings = await this.getCachedLanguageSetting();

    this.language = await this.getLanguageAsync(languageSettings);
    return queryLanguage ?? this.language;
  }

  async getLocalLanguageAsync(): Promise<string> {
    const queryLanguage = await this.getLanguageFromQuery();
    const language: LanguageSetting | null = await this.localStorageService.get<LanguageSetting | null>(this.storageKey);
    return queryLanguage ?? (await this.getLanguageAsync(language));
  }

  async setCurrentLanguageAsync(language: string): Promise<void> {
    const availableLanguages = (await this.getLanguageListAsync()).map((language) => language.name);
    const isLatin = language === this.latinKey;
    if (!language || (!availableLanguages.includes(language) && !isLatin)) {
      this.languageSettingCache = null;
      return Promise.reject();
    }
    const currentLanguage = await this.getCurrentLanguageAsync();
    const languageSettings = { key: isLatin ? currentLanguage : language, translationModeEnabled: isLatin };
    await this.localStorageService.set<LanguageSetting>(this.storageKey, languageSettings);

    return this.settingsService.set<LanguageSetting>(this.storageKey, languageSettings).then(() => {
      this.languageSettingCache = languageSettings;
      window.location.reload();
    });
  }

  async getCurrentLocaleAsync(): Promise<string> {
    const currentLanguage = await this.getCurrentLanguageAsync();
    if (currentLanguage && currentLanguage.length > 2) {
      return currentLanguage;
    }

    switch (currentLanguage) {
      case 'de':
        return 'de-CH';
      case 'fr':
        return 'fr-CH';
      case 'it':
        return 'it-CH';
      case 'en':
        return 'en-US';
    }

    // Default to Swiss-German formatting for numbers and dates.
    return `de-CH`;
  }

  getDeterminedLanguage(): string | null {
    return this.language;
  }

  async updateLocalLanguageAsync(): Promise<boolean> {
    const localLanguage = await this.getLocalLanguageAsync(); // cached language
    const currentLanguage = await this.getCurrentLanguageAsync(); // comes form the server
    if (localLanguage === currentLanguage) {
      return false;
    } else {
      const languageSettings = { key: currentLanguage, translationModeEnabled: currentLanguage === this.latinKey };
      return this.localStorageService.set<LanguageSetting>(this.storageKey, languageSettings).then(() => {
        window.location.reload();
        return true;
      });
    }
  }

  private async getLanguageFromQuery() {
    if (this.queryLanguage) {
      return this.queryLanguage;
    }
    const searchParams = new URLSearchParams(document.location.search);
    let language = searchParams.get(this.languageQueryParameterName);
    this.queryLanguage = language;
    return language;
  }

  private async getCachedLanguageSetting(): Promise<LanguageSetting | null> {
    let languageSettings: LanguageSetting | null;
    if (this.languageSettingCache != null) {
      languageSettings = this.languageSettingCache;
    } else {
      languageSettings = await this.settingsService.get<LanguageSetting | null>(this.storageKey);
      this.languageSettingCache = languageSettings;
    }
    return languageSettings;
  }

  private async getLanguageAsync(language: LanguageSetting | null): Promise<string> {
    if (language != null && language.key != null) {
      if (language.translationModeEnabled) {
        return this.latinKey;
      }
      return language.key;
    }

    return (await this.getAvailableNavigatorLanguageAsync()) || (await this.getDefaultLanguageAsync()) || 'en';
  }

  private static getNavigatorLanguages(): string[] {
    const { languages: navigatorLanguages, language: uiLanguage } = window.navigator;

    const strippedNavigatorLanguages = (navigatorLanguages || []).map((languageCode) => languageCode.replace(/-.*/, ''));

    return [...strippedNavigatorLanguages, uiLanguage];
  }

  private async getAvailableNavigatorLanguageAsync() {
    const availableLanguages = (await this.getLanguageListAsync()).map((language) => language.name);

    return QuinoLanguageService.getNavigatorLanguages().find((language) => availableLanguages.includes(language));
  }

  private async getDefaultLanguageAsync() {
    const defaultLanguage = (await this.getLanguageListAsync()).find((language) => language.isDefault !== undefined && language.isDefault);

    return defaultLanguage && defaultLanguage.name;
  }

  private queryLanguage: string | null = null;
  private languageList: Promise<ILanguageMetadata[]>;
  private language: string | null = null;
  private languageSettingCache: LanguageSetting | null = null;
  private readonly storageKey = 'locale';
  private readonly latinKey = 'la';
  private readonly languageQueryParameterName = 'language';
}
