import { ILayoutAwareBookmark, isILayoutAwareBookmark } from './ILayoutAwareBookmark';
import { IMetaClassAwareBookmark, isIMetaClassAwareBookmark } from './IMetaClassAwareBookmark';
import { isIBookmark } from './IBookmark';
import { IObjectBookmark } from './IObjectBookmark';
import { IStateFullBookmark } from './IStateFullBookmark';

/**
 * A meta bookmark that contains a meta class and a layout.
 */
export interface IMetaBookmark extends ILayoutAwareBookmark, IMetaClassAwareBookmark, IStateFullBookmark {
  parentBookmark?: IObjectBookmark;
}

/**
 * Whether the given object is a IListBookmark.
 */
export const isIMetaBookmark = (object: any): object is IMetaBookmark => {
  return isIBookmark(object) && isILayoutAwareBookmark(object) && isIMetaClassAwareBookmark(object);
};
