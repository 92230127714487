import { useService } from '../ioc/hook';
import { IMaskFormatService, IMaskFormatServiceSymbol, IMetaElement } from '@quino/core';

/**
 * Retrieve the mask format string assigned with this element.
 * @param element - the element to retrieve the format string from.
 */
export function useMaskFormat(element: IMetaElement): string | undefined {
  const maskFormatService = useService<IMaskFormatService>(IMaskFormatServiceSymbol);
  return maskFormatService.get(element);
}
