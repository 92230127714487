import React, { PropsWithChildren } from 'react';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { getMetaProperty } from '@quino/core';
import { useMetaPropertyValueState } from '../Util';
import { useService } from '../../ioc/hook';
import { IMarkdownConverter } from '../../rendering/MarkdownConverter';
import { useMetadata } from '../../settings';
import { IMarkdownConverterSymbol } from '../../rendering/MarkdownConverter/IMarkdownConverter';

export function QuinoLabel(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const { element, bookmark } = props;
  const metaProperty = getMetaProperty(element);
  const { visible } = useMetadata(metaProperty, bookmark.genericObject);
  const { description } = metaProperty;
  const [value] = useMetaPropertyValueState<string | undefined>(metaProperty, bookmark);
  const markdownConverter = useService<IMarkdownConverter>(IMarkdownConverterSymbol);

  const commonClasses = `dx-field-label quino-dx-field-label quino-input-fields-max-width`;

  if (!visible) {
    // prevents to crash if a QuinoLabel is made invisible directly inside a ScrollView
    return <div />;
  }

  if (markdownConverter.isMarkdown(value || '')) {
    const convertedMarkdown = markdownConverter.convertToHtml(value || '');
    return <div className={`${commonClasses} quino-label-markdown`} title={description} dangerouslySetInnerHTML={{ __html: convertedMarkdown }} />;
  } else {
    return (
      <div className={commonClasses} title={description}>
        {' '}
        {value}{' '}
      </div>
    );
  }
}
