import { IErrorMapper } from './IErrorMapper';
import { DefaultErrorMapper } from './DefaultErrorMapper';

export class ExceptionServerErrorMapper extends DefaultErrorMapper implements IErrorMapper {
  get errorName() {
    return 'ServerError.Exception';
  }

  canHandleError(error: Error): boolean {
    try {
      const parsedError = JSON.parse(error.message);
      return parsedError.message != undefined && parsedError.instanceAnnotations != undefined;
    } catch {
      return false;
    }
  }

  getErrorMessage(error: Error): string {
    const parsedError = JSON.parse(error.message);
    return parsedError.message;
  }

  getErrorName(error: Error): string {
    return this.translationService.translate(this.errorName);
  }
}
