import { IApplication, InitializationGroup } from '../application';
import { IVersionInfoService, IVersionInfoServiceSymbol } from './IVersionInfoService';
import { VersionInfoService } from './VersionInfoService';
import { IFrontendVersionInfoService } from './IFrontendVersionInfoService';
import { QuinoCoreServiceSymbols } from '../ioc';
import { FrontendVersionInfoService } from './FrontendVersionInfoService';
import { IBackendVersionInfoService } from './IBackendVersionInfoService';
import { BackendVersionInfoService } from './BackendVersionInfoService';

export const VersionServiceStartUpActionSymbol = Symbol.for('VersionServiceStartUpAction');

export class VersionModule {
  public static use(application: IApplication) {
    application.registerSingle<IVersionInfoService>(IVersionInfoServiceSymbol, VersionInfoService);
    application.registerSingle<IFrontendVersionInfoService>(QuinoCoreServiceSymbols.IFrontendVersionInfoService, FrontendVersionInfoService);
    application.registerSingle<IBackendVersionInfoService>(QuinoCoreServiceSymbols.IBackendVersionInfoService, BackendVersionInfoService);
  }
}
