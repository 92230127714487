import { useEffect } from 'react';
import { IBookmark, IObjectBookmarkEvent, isIObjectBookmark, ObjectBookmarkEventType } from '../../bookmarks';

export function useOnBookmarkAnyEvent(bookmark: IBookmark | undefined, callback: (() => unknown) | undefined): void {
  useOnBookmark(bookmark, callback);
}

export function useOnBookmarkSavedEvent(bookmark: IBookmark | undefined, callback: (() => unknown) | undefined): void {
  useOnBookmark(bookmark, callback, 'saved');
}

export function useOnBookmarkReloadEvent(bookmark: IBookmark | undefined, callback: (() => unknown) | undefined): void {
  useOnBookmark(bookmark, callback, 'reload');
}

export function useOnBookmarkResetEvent(bookmark: IBookmark | undefined, callback: (() => unknown) | undefined): void {
  useOnBookmark(bookmark, callback, 'reset');
}

export function useOnBookmarkChangedEvent(
  bookmark: IBookmark | undefined,
  callback: ((event: IObjectBookmarkEvent) => unknown) | undefined,
  properties?: string[]
): void {
  useOnBookmark(bookmark, callback, 'changed', properties);
}

export function useOnBookmark(
  bookmark: IBookmark | undefined,
  callback: ((event?: IObjectBookmarkEvent) => unknown) | undefined,
  eventType?: ObjectBookmarkEventType,
  properties?: string[]
): void {
  useEffect(() => {
    if (isIObjectBookmark(bookmark) && callback) {
      const symbol = bookmark.subscribe(async (event) => {
        if (eventType) {
          if (event.type === eventType) {
            const isPropertyDependentCallback = !!properties;
            let relevantPropertiesChanged = false;
            if (properties && properties.length > 0 && event.changedProperties && event.changedProperties.length > 0) {
              const changedPropertiesLowercase = event.changedProperties.map((p) => p.toLowerCase());
              relevantPropertiesChanged = properties.filter((p) => changedPropertiesLowercase.indexOf(p.toLowerCase()) !== -1)?.length > 0;
            }
            (!isPropertyDependentCallback || (isPropertyDependentCallback && relevantPropertiesChanged)) && (await callback(event));
          }
        } else {
          await callback(event);
        }
      });

      return () => {
        bookmark.unsubscribe(symbol);
      };
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmark]);
}
