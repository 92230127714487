/**
 * Enum that specify the visibility of a help-texts.
 */
export enum HelpTextVisibility {
  /**
   * Help-Text is always visible to the user.
   */
  Always,
  /**
   * Help-Text is only visible if the user hover the mouse pointer over the control (=Tooltip).
   */
  OnHover,
  /**
   * Help-Text will never be visible.
   */
  Never
}
