import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IBookmark,
  IBookmarkFactory,
  INavigationService,
  INavigationServiceSymbol,
  IQuinoShortcutSettings,
  IQuinoShortcutSettingsSymbol,
  isCtrlOrCmdEvent,
  IShortcutInformation,
  IShortcutInformationService,
  IShortcutInformationServiceSymbol,
  isIDashboardBookmark,
  isIListBookmark,
  isIObjectBookmark,
  QuinoUIServiceSymbols,
  useExpression,
  useOnNavigation,
  useRerender,
  useService
} from '@quino/ui';
import {
  ExpandedAspectIdentifier,
  getAspectOrDefault,
  IconAspectIdentifier,
  IExpandedAspect,
  IIconAspect,
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILogger,
  IMetadataTree,
  IMetaElement,
  IMetaGroup,
  IMetaLayout,
  isIMetaGroup,
  isIMetaMenuItem,
  ITranslationService,
  ITranslationServiceSymbol,
  IVisibleCalculator,
  LayoutType,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { useCuiSettings } from '../../util/useCuiSettings';
import dxTreeView, { Item as TreeViewItem, Node as TreeViewNode } from 'devextreme/ui/tree_view';
import { Item as ContextMenuItem } from 'devextreme/ui/context_menu';
import { Button } from 'devextreme-react/button';
import { TreeView } from 'devextreme-react/tree-view';
import { CUINavBarContextMenu, CUINavBarContextMenuTargetIdPrefix } from './CUINavBarContextMenu';
import { ICUINavBarProps } from './CUINavBar';
import DevExpress from 'devextreme';
import { TextBox } from 'devextreme-react/text-box';
import EventObject = DevExpress.events.EventObject;
import { useMenuItemNavigator } from './useMenuItemNavigator';

type KeyDownEventObject = EventObject & {
  key: string;
};

export type TreeViewKey = {
  key: string;
};

export interface INavigationItemType extends TreeViewKey {
  level: number;
  navBarExpanded: boolean;
  useFavorites: boolean;
  rerenderCallback?: () => void;
}

export const isINavigationItemType = (object: any): object is INavigationItemType => {
  return (
    object != null &&
    (object as INavigationItemType).level !== undefined &&
    (object as INavigationItemType).navBarExpanded !== undefined &&
    (object as INavigationItemType).useFavorites !== undefined
  );
};

export interface IMetaElementNavigationType extends INavigationItemType {
  layout?: IMetaLayout;
  metaClass?: string;
  element: IMetaElement;
  metaElement?: IMetaElement | undefined;
}

export const isIMetaElementNavigationType = (object: any): object is IMetaElementNavigationType => {
  return isINavigationItemType(object) && (object as IMetaElementNavigationType).level !== undefined;
};

export interface ICustomItemNavigationType extends INavigationItemType {
  text: string;
  icon: string;
  onClick: () => void;
}

export const isICustomItemNavigationType = (object: any): object is ICustomItemNavigationType => {
  return (
    isINavigationItemType(object) &&
    (object as ICustomItemNavigationType).text !== undefined &&
    (object as ICustomItemNavigationType).icon !== undefined &&
    (object as ICustomItemNavigationType).onClick !== undefined
  );
};

export const CUINavBarTreeViewSearchEditorId = 'quino-ecui-nav-bar-tree-view-search-editor';

export const CUINavBarTreeView: React.FC<ICUINavBarProps> = (props) => {
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);
  const metadataService = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const cuiSettings = useCuiSettings();
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const visibleService = useService<IVisibleCalculator>(QuinoCoreServiceSymbols.IVisibleCalculator);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const shortcutInformationService = useService<IShortcutInformationService>(IShortcutInformationServiceSymbol);
  const navigate = useMenuItemNavigator();
  const shortcutInformation = useMemo<IShortcutInformation>(() => shortcutInformationService.get(), [shortcutInformationService]);
  const layout = metadataService.getMenuLayout();
  const [selectedItem, selectItem] = useState<{ treeViewItem: TreeViewNode; bookmark: IBookmark } | undefined>();
  const currentBookmark = useOnNavigation();
  const expressionContextChangedMessage = useExpression();
  const [treeViewComponent, setTreeViewComponent] = useState<dxTreeView>();
  const [searchValue, setSearchValue] = useState('');

  const visitRecursive = (items: TreeViewItem[], callback: (x: TreeViewNode) => void) => {
    for (const item of items) {
      callback(item);

      if (item.items && item.items.length > 0) {
        visitRecursive(item.items, callback);
      }
    }
  };

  const expandChildren = useCallback(
    (item: TreeViewItem & TreeViewKey, group: IMetaGroup, level: number, firstLevelGroupsOnly: boolean) => {
      const classes = metadataService.getClasses();

      for (const ele of group.elements) {
        const visible = visibleService.calculate(ele, {});
        const expandedAspect = getAspectOrDefault<IExpandedAspect>(ele, ExpandedAspectIdentifier);
        const iconAspect = getAspectOrDefault<IIconAspect>(ele, IconAspectIdentifier);
        const newItem: TreeViewItem & IMetaElementNavigationType = {
          text: ele.caption,
          metaElement: ele,
          expanded: expandedAspect?.expanded ?? false,
          items: [],
          icon: iconAspect ? iconAspect.icon : undefined,
          level: level,
          navBarExpanded: props.navBarExpanded,
          useFavorites: props.useFavorites,
          element: ele,
          visible: visible,
          key: item.key + '_' + ele.name
        };
        item.items?.push(newItem);

        if (!firstLevelGroupsOnly && isIMetaGroup(ele)) {
          const hasValidChildren = ele.elements.filter((value) => isIMetaMenuItem(value) || isIMetaGroup(value)).length > 0;
          if (hasValidChildren) {
            expandChildren(newItem, ele, level + 1, firstLevelGroupsOnly);
          }
        }

        if (isIMetaMenuItem(ele) && ele.context) {
          const metaClass = classes.find((x: IMetaElement) => x.name.toLowerCase() === ele.context.toLowerCase());
          if (metaClass) {
            newItem.metaClass = metaClass.name;
            newItem.layout = layoutResolver.tryResolveSingle({ metaClass: metaClass.name, type: LayoutType.List, element: ele });
          }
        }
      }
    },
    [layoutResolver, metadataService, props.navBarExpanded, props.useFavorites, visibleService]
  );

  const items = useMemo<TreeViewItem[]>(() => {
    if (layout && isIMetaGroup(layout)) {
      const rootNode: TreeViewItem & TreeViewKey = { text: 'Root', hasItems: true, items: [], key: 'root' };
      expandChildren(rootNode, layout, 0, !props.navBarExpanded);
      return rootNode.items!;
    }

    return [];

    // Update the items after receiving the expression-context-changed-message
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout, expandChildren, props.navBarExpanded, expressionContextChangedMessage]);

  let matchingItem: TreeViewItem | undefined = undefined;

  if (currentBookmark !== selectedItem?.bookmark) {
    visitRecursive(items, (x) => {
      const item = x as IMetaElementNavigationType;

      if (isIListBookmark(currentBookmark)) {
        if (
          !matchingItem &&
          item.metaClass &&
          item.layout &&
          item.metaClass.toLowerCase() === currentBookmark.metaClass.name.toLowerCase() &&
          item.layout.uri.toLowerCase() === currentBookmark.layout.uri.toLowerCase()
        ) {
          matchingItem = x;
        }
      } else if (isIObjectBookmark(currentBookmark)) {
        if (!matchingItem && item.metaClass && item.metaClass.toLowerCase() === currentBookmark.genericObject.metaClass.toLowerCase()) {
          matchingItem = x;
        }
      }
    });

    if (matchingItem && matchingItem !== selectedItem?.treeViewItem) {
      selectItem({ treeViewItem: matchingItem, bookmark: navigationService.active });
    }
  }

  const applyRerenderCallback = (node?: TreeViewNode) => {
    if (!node) {
      return;
    }

    const item = node.itemData as INavigationItemType;
    item.rerenderCallback && item.rerenderCallback();
  };

  const onItemClick = (node: TreeViewNode, component: dxTreeView, openInNewTab: boolean) => {
    if (props.navBarExpanded) {
      const item = node.itemData as IMetaElementNavigationType;
      if (isIMetaMenuItem(item.element)) {
        component.selectItem(node.key);
        navigate(item.element, openInNewTab)
          .then((x) => x && selectItem({ treeViewItem: node, bookmark: x }))
          .catch((e) => logger.logError(e));
      } else {
        // Expand collapse everything else that is not a leaf.
        if (node.expanded) {
          component?.collapseItem(node.key);
        } else {
          component?.expandItem(node.key);
        }
      }
    }
  };

  const isKeyDownEvent = (item: EventObject | undefined): item is KeyDownEventObject => {
    return (item as KeyDownEventObject)?.key !== undefined;
  };

  const menuItems = useMemo<TreeViewItem[]>(() => {
    const menuItemsArray: TreeViewItem[] = [];
    if (cuiSettings.dashboardEnabled) {
      const dashboardItem: ICustomItemNavigationType = {
        level: 0,
        navBarExpanded: props.navBarExpanded,
        useFavorites: false,
        text: translationService.translate('Dashboard'),
        key: 'dashboard',
        icon: 'material-icons-outlined dashboard',
        onClick: async () => {
          const activeBookmark = navigationService.active;
          if (!isIDashboardBookmark(activeBookmark)) {
            bookmarkFactory
              .createDashboard()
              .then(async (dashBoardBookmark) => navigationService.push(dashBoardBookmark))
              .catch(logger.logError);
          } else {
            activeBookmark.refresh();
          }
        }
      };
      menuItemsArray.push(dashboardItem);
    }

    return menuItemsArray.concat(items);
  }, [bookmarkFactory, cuiSettings.dashboardEnabled, items, logger.logError, navigationService, props.navBarExpanded, translationService]);

  const filteredItems = useMemo(() => {
    const isMatch = (item: TreeViewItem) => item.text?.toLowerCase().includes(searchValue.toLowerCase());
    const filter = (item: TreeViewItem) => {
      if (isMatch(item)) {
        return true;
      }

      if (item.items && item.items.length > 0) {
        if (item.items.filter(filter).length > 0) return true;
      }

      return false;
    };

    const transform = (item: TreeViewItem): TreeViewItem => {
      if (isMatch(item)) return item;
      return {
        ...item,
        items: item.items?.filter(filter).map(transform)
      };
    };

    return menuItems.filter(filter).map(transform);
  }, [menuItems, searchValue]);

  return (
    <div className={`quino-ecui-nav-bar-treeview-wrapper ${!props.navBarExpanded ? 'is-narrowed' : ''}`}>
      {cuiSettings.showMenuSearch && props.navBarExpanded && (
        <TextBox
          hint={shortcutInformation.hint(shortcutSettings.menuSearch)}
          placeholder={translationService.translate('SearchMenu')}
          id={CUINavBarTreeViewSearchEditorId}
          valueChangeEvent={'keyup blur'}
          mode={'search'}
          onValueChanged={(e) => {
            setSearchValue(e.value);
          }}
          onKeyDown={(e) => {
            if (isKeyDownEvent(e.event) && e.event.key === 'ArrowDown') {
              // The purpose of this line of code is to emulate the "TAB" key, when pressing the "ArrowDown" key in the search bar
              // First we have to get the TreeView content element, by using a querySelector for the div that has a tabindex of 0
              // Then we need to cast this to an HTMLElement, so we can call the focus() function (The Element is actually an HTMLElement)
              const element = treeViewComponent?.element().querySelector('div[tabindex="0"]');
              if (element) {
                (element as HTMLElement).focus();
              } else {
                logger.logError('Failed to find element for ArrowDown on Navbar search!');
              }
            }
          }}
        />
      )}
      <TreeView
        className={'quino-ecui-navbar-treeview'}
        items={filteredItems}
        selectionMode={'single'}
        focusStateEnabled={props.navBarExpanded}
        noDataText={translationService.translate('NavigationBar.NoDataText')}
        searchEnabled={false}
        itemComponent={CUINavBarTreeViewItemTemplate}
        itemKeyFn={(data: TreeViewKey) => data.key}
        onItemClick={(e) => e.node && e.component && onItemClick(e.node, e.component, isCtrlOrCmdEvent(e.event))}
        onItemExpanded={(e) => applyRerenderCallback(e.node)}
        onItemCollapsed={(e) => applyRerenderCallback(e.node)}
        onInitialized={(e) => setTreeViewComponent(e.component)}
      />
    </div>
  );
};

const CUINavBarTreeViewItemTemplate: React.FC<{
  data: TreeViewItem & (IMetaElementNavigationType | ICustomItemNavigationType);
}> = (props) => {
  const { data } = props;
  const rerender = useRerender();

  const metadataService = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);

  const [contextMenuItems, setContextMenuItems] = useState<(ContextMenuItem & IMetaElementNavigationType)[]>([]);

  data.rerenderCallback = rerender;
  const isNode = !data.navBarExpanded || (data.items && data.items.length > 0);
  const icon = ((isICustomItemNavigationType(data) || isNode) && (data.icon || 'material-icons browser_not_supported')) || 'noicon';
  const expanderIcon = isNode ? (data.expanded ? 'material-icons-outlined expand_less' : 'material-icons-outlined expand_more') : undefined;
  const visibleService = useService<IVisibleCalculator>(QuinoCoreServiceSymbols.IVisibleCalculator);

  useEffect(() => {
    if (!props.data.navBarExpanded && isIMetaElementNavigationType(data) && isIMetaGroup(data.metaElement)) {
      const newItems: (ContextMenuItem & IMetaElementNavigationType)[] = [];
      for (const element of data.metaElement.elements) {
        if (isIMetaMenuItem(element)) {
          const visible = visibleService.calculate(element, {});
          const newItem: ContextMenuItem & IMetaElementNavigationType = {
            level: 0,
            navBarExpanded: data.navBarExpanded,
            useFavorites: data.useFavorites,
            element: element,
            text: element.caption,
            key: data.key + '_' + element.name,
            visible: visible
          };
          newItems.push(newItem);
        }
      }
      setContextMenuItems(newItems);
    }
  }, [data, data.key, metadataService, props.data.navBarExpanded, visibleService]);

  const id = CUINavBarContextMenuTargetIdPrefix + (isIMetaElementNavigationType(data) && data.metaElement ? data.metaElement.name : data.key);

  return (
    <div className={`quino-ecui-nav-bar-treeview-item ${!data.navBarExpanded ? 'is-narrowed' : ''}`}>
      <Button
        id={id}
        className={`quino-ecui-nav-bar-treeview-item-title-button ${isNode || isICustomItemNavigationType(data) ? 'is-node' : ''}`}
        key={'nav_title'}
        focusStateEnabled={false}
        hoverStateEnabled={false}
        text={(data.navBarExpanded && data.text) || undefined}
        hint={data.text}
        stylingMode={'text'}
        icon={icon}
      />
      {expanderIcon != null && (
        <Button
          className={'quino-ecui-nav-bar-treeview-item-expander-button'}
          key={'nav_expander'}
          focusStateEnabled={false}
          hoverStateEnabled={false}
          visible={data.navBarExpanded}
          stylingMode={'text'}
          icon={expanderIcon}
        />
      )}
      {data.useFavorites && expanderIcon == null && <i className='material-icons star_border hide' />}
      {!data.navBarExpanded && !isICustomItemNavigationType(data) && (
        <CUINavBarContextMenu ofId={id} dataSource={contextMenuItems} useFavorites={data.useFavorites} />
      )}
    </div>
  );
};
