import { IQuinoBaseProps } from '../Types';
import * as React from 'react';
import { convertToBase64ImageSource } from '@quino/core';
import { useSize } from '../Util/MetaElementHooks';

export const QuinoImagePickerBase = (props: IQuinoBaseProps) => {
  const { data, element } = props;
  const { height, width } = useSize(element);

  if (!data || typeof data !== 'string') {
    return <></>;
  }

  const isUrl = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const src = isUrl(data) ? data : convertToBase64ImageSource(data);
  return (
    <div className={'quino-image-picker'}>
      <img src={src} alt={'N/A'} style={{ objectFit: 'contain', height: height ?? 'auto', width: width ?? 'auto' }} />
    </div>
  );
};
