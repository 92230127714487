import { IClientConfiguration, IConfigurationService } from './IConfigurationService';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IUrlManager } from '../api';
import { IRequestBuilder } from '../request';

@injectable()
export class ConfigurationService implements IConfigurationService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager) private readonly urlManager: IUrlManager,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder
  ) {}

  get(key: string): IClientConfiguration | undefined {
    return this.configs.find((x) => x.key.trim().toUpperCase() === key.toUpperCase());
  }

  getByPrefix(prefix: string): IClientConfiguration[] {
    return this.configs.filter((x) => x.key.toUpperCase().startsWith(prefix.toUpperCase()));
  }

  apply(defaults: any, prefix: string): any {
    Object.keys(defaults).forEach((x) => {
      const configEntry = this.get(`${prefix}:${x}`);
      if (configEntry != null) {
        switch (typeof defaults[x]) {
          case 'string':
            defaults[x] = configEntry.value;
            break;
          case 'boolean':
            defaults[x] = configEntry.value.toUpperCase() === 'TRUE';
            break;
          case 'number':
          case 'bigint':
            defaults[x] = parseFloat(configEntry.value);
            break;
        }
      }
    });

    return defaults;
  }

  async initialize(): Promise<void> {
    this.configs = await this.requestBuilder.create(this.urlManager.getConfigurationUrl(), 'get').fetchJson<IClientConfiguration[]>();

    return Promise.resolve();
  }

  protected configs: IClientConfiguration[] = [];
}
