import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for the responsive box aspect.
 */
export const ResponsiveBoxAspectIdentifier = 'ResponsiveBox';

/**
 * The responsive box aspect is used to define the rows and columns of the responsive box.
 */
export interface IResponsiveBoxAspect extends IMetaAspect {
  rows: IQualifier[];
  columns: IQualifier[];
}

export interface IQualifier {
  ratio: number;
  shrink: number;
  baseSize?: number;
}
