import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { GridItemHTMLElement, GridStack } from 'gridstack';
import 'gridstack/dist/h5/gridstack-dd-native';
import { QuinoDashboardTile } from './tiles/QuinoDashboardTile';
import { useService } from '../ioc';
import { IDashboardTileRegistration, IDashboardTileRegistry, IDashboardTileRegistrySymbol } from './registry';
import { ScrollView } from 'devextreme-react/scroll-view';
import {
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { IDashboardLayout, IDashboardSettingsService, IDashboardSettingsServiceSymbol, IQuinoDashboardItem } from './settings';
import { dashboardConfigurationLimits, IDashboardConfiguration, useDashboardConfiguration } from './configuration';
import { Guid } from 'guid-typescript';
import { IDashboardBookmark } from '../bookmarks';
import { getIosClassName, TResponsiveMode, useResponsiveMode } from '../responsivity';
// NOTE: Do not shorten following imports to avoid circular dependencies
import { useRerender } from '../components/Util/useRenderer';
import { DashboardType } from './DashboardType';

interface IQuinoDashboardTileItem {
  item: IQuinoDashboardItem;
  ref: React.RefObject<any>;
  widget: GridItemHTMLElement | undefined;
}

export function QuinoDashboard(props: { bookmark: IDashboardBookmark }) {
  const dashboardConfiguration = useDashboardConfiguration();
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const settingsService = useService<IDashboardSettingsService>(IDashboardSettingsServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const registry = useService<IDashboardTileRegistry>(IDashboardTileRegistrySymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const responsiveMode = useResponsiveMode();
  const rerender = useRerender();

  const bookmark: IDashboardBookmark = props.bookmark;
  const bookmarkRef = useRef<IDashboardBookmark>();
  bookmarkRef.current = bookmark;

  const [loadedDashboardName, setLoadedDashboardName] = useState<string | undefined>(undefined);

  const [tiles, setTiles] = useState<IQuinoDashboardTileItem[]>([]);
  const tilesRef = useRef<IQuinoDashboardTileItem[]>(tiles);
  tilesRef.current = tiles;

  const gridRef = useRef<GridStack>();

  const [initialItemsLoaded, setInitialItemsLoaded] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const readOnly = useMemo<boolean>(
    () => dashboardConfiguration.readOnly || bookmark.getCurrentDashboard().dashboardType == DashboardType.System,
    [bookmark.layout]
  );

  const adjustedConfiguration = useMemo<IDashboardConfiguration>(() => {
    const config = dashboardConfiguration;
    const limits = dashboardConfigurationLimits;

    const numberOfColumns =
      config.numberOfColumns < limits.numberOfColumnsMin
        ? limits.numberOfColumnsMin
        : config.numberOfColumns > limits.numberOfColumnsMax
        ? limits.numberOfColumnsMax
        : config.numberOfColumns;
    const rowHeight =
      config.rowHeight < limits.rowHeightMin ? limits.rowHeightMin : config.rowHeight > limits.rowHeightMax ? limits.rowHeightMax : config.rowHeight;
    const tileWidth =
      config.newTileDefaultWidth < 1 ? 1 : config.newTileDefaultWidth < numberOfColumns ? config.newTileDefaultWidth : numberOfColumns;
    const tileHeight = config.newTileDefaultHeight < 1 ? 1 : config.newTileDefaultHeight;

    return {
      ...dashboardConfiguration,
      numberOfColumns: numberOfColumns,
      rowHeight: rowHeight,
      newTileDefaultWidth: tileWidth,
      newTileDefaultHeight: tileHeight
    };
  }, [dashboardConfiguration]);

  const addTile = (type: IDashboardTileRegistration<any>, payload: any = {}) => {
    const tile: IQuinoDashboardTileItem = {
      item: {
        id: Guid.create().toString(),
        type: type,
        payload: payload,
        position: {
          w: adjustedConfiguration.newTileDefaultWidth,
          h: adjustedConfiguration.newTileDefaultHeight
        }
      },
      ref: createRef(),
      widget: undefined
    };

    setTiles([...tilesRef.current, tile]);
    bookmark.setHasChanges(true);
  };

  const removeTile = (id: string) => {
    setTiles(
      tiles.filter((tile) => {
        if (tile.item.id === id) {
          if (tile.widget) {
            gridRef.current?.removeWidget(tile.widget, false);
          }
          return false;
        }
        return true;
      })
    );
    bookmark.setHasChanges(true);
  };

  const loadInitialItems = () => {
    const unload = loadingFeedback.load();
    gridRef.current?.removeAll(false);

    const initialTiles: IQuinoDashboardTileItem[] = [];
    const initialDashboard = bookmark.getCurrentDashboard();
    initialDashboard.items.forEach((item) => {
      const type = registry.getInstances().find((x) => x.name === item.type.name);
      if (type) {
        initialTiles.push({ item: { ...item, type: type, payload: JSON.parse(JSON.stringify(item.payload)) }, ref: createRef(), widget: undefined });
      } else {
        logger.logError(
          `Tile type [${item.type.name}] does not exist. Tiles of this type will be definitely removed from the dashboard after next save.`
        );
      }
    });

    setTiles(initialTiles);
    setLoadedDashboardName(initialDashboard.name);
    bookmark.setHasChanges(false);
    unload();
  };

  const saveCurrentState = () => {
    const grid = gridRef.current;
    const baseDashboard = bookmark.getCurrentDashboard();
    if (grid && baseDashboard) {
      const unload = loadingFeedback.load();
      const newItems: IQuinoDashboardItem[] = [];
      grid.engine.nodes.forEach((node) => {
        const tile = tilesRef.current.find((tile) => tile.item.id === node.id);
        if (tile) {
          newItems.push({ ...tile.item, position: { x: node.x, y: node.y, w: node.w, h: node.h } });
        }
      });
      const newDashboard: IDashboardLayout = { ...baseDashboard, items: newItems };

      let promise: Promise<IDashboardLayout>;
      if (newDashboard.dashboardType == DashboardType.Personal) {
        promise = settingsService.saveCustomDashboard(newDashboard);
      } else if (newDashboard.dashboardType == DashboardType.Shared) {
        promise = settingsService.saveSharedDashboard(newDashboard);
      } else {
        logger.logWarn(`System dashboards can not be saved. Tried to save system dashboard ${bookmark.getCurrentDashboard().name}`);
        return;
      }

      promise
        .then(() => {
          bookmark.layout = newDashboard;
          bookmark.setHasChanges(false);
          setLoadedDashboardName(baseDashboard.name);
          unload();
        })
        .catch(logger.logError);
    }
  };

  const restoreInitialState = () => {
    if (bookmark.hasChanges()) {
      updateGrid();
    }
  };

  const updateGrid = () => {
    const currentTiles = tilesRef.current;
    if (currentTiles.length > 0) {
      const unload = loadingFeedback.load();
      const initialDashboardItems = bookmark.getCurrentDashboard().items;

      // Remove tiles from grid that are not in initial dashboard items
      const remainingTiles = currentTiles.filter((tile) => {
        if (initialDashboardItems.find((x) => x.id === tile.item.id)) {
          return true;
        } else {
          if (tile.widget) {
            gridRef.current?.removeWidget(tile.widget, false);
          }
          return false;
        }
      });

      // Update/add tiles for initial dashboard items
      const updatedTiles: IQuinoDashboardTileItem[] = [];
      initialDashboardItems.forEach((item) => {
        const existingTile = remainingTiles.find((x) => x.item.id === item.id);
        if (existingTile) {
          const newTile = { ...existingTile, item: { ...item, payload: JSON.parse(JSON.stringify(item.payload)) } };
          if (newTile.widget) {
            const pos = newTile.item.position;
            const node = newTile.widget.gridstackNode;
            if (pos.h !== node?.h || pos.w !== node?.w || pos.x !== node?.x || pos.y !== node?.y) {
              gridRef.current?.update(newTile.widget, { h: pos.h, w: pos.w, x: pos.x, y: pos.y });
            }
          }
          updatedTiles.push(newTile);
        } else {
          updatedTiles.push({ item: item, ref: createRef(), widget: undefined });
        }
      });
      setTiles(updatedTiles);

      bookmark.setHasChanges(false);
      unload();
    } else {
      loadInitialItems();
    }
  };

  const refreshAll = (): void => {
    tilesRef.current.forEach((t) => t.item.type.dataSource.refreshData());
  };

  const onChange = (): void => {
    if (!readOnly && responsiveMode !== TResponsiveMode.Phone) {
      bookmarkRef.current?.setHasChanges(true);
    }
  };

  // Subscribe to bookmark events
  useEffect(() => {
    setEditMode(false);
    const stateChangeSymbol = bookmark.subscribeToStateChange(refreshAll);
    const dashboardEventsSymbol = bookmark.subscribe((event) => {
      switch (event.type) {
        case 'edit':
          setEditMode(event.payload);
          break;
        case 'addTile':
          addTile(event.payload.type, event.payload.payload);
          break;
        case 'reset':
          restoreInitialState();
          break;
        case 'refresh':
          refreshAll();
          break;
        case 'save':
          saveCurrentState();
          break;
      }
    });
    return () => {
      bookmark.unsubscribe(dashboardEventsSymbol);
      bookmark.unsubscribeFromStateChange(stateChangeSymbol);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmark]);

  // Update grid when a new dashboard layout is loaded
  useEffect(() => {
    const newDashboard = bookmark.getCurrentDashboard();
    if (newDashboard && Array.isArray(newDashboard.items)) {
      if (gridRef.current) {
        gridRef.current.off('change');
        gridRef.current.on('change', () => onChange());
        if (newDashboard.name === loadedDashboardName) {
          updateGrid();
        } else {
          loadInitialItems();
        }
      } else {
        setInitialItemsLoaded(true);
      }
    } else {
      logger.logError('Error: Dashboard format invalid. Cannot load dashboard contents.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmark.layout]);

  // Initialize grid after first dashboard content has been loaded
  // Note: This is in a separate effect to make sure that DOM is rendered at least once before initialising Gridstack
  useEffect(() => {
    if (initialItemsLoaded) {
      gridRef.current = GridStack.init({
        animate: false,
        float: true,
        cellHeight: adjustedConfiguration.rowHeight,
        column: adjustedConfiguration.numberOfColumns,
        margin: 12,
        minRow: 1
      });
      gridRef.current.on('change', () => onChange());
      gridRef.current.on('resizestop', () => rerender());
      gridRef.current.disable();

      loadInitialItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialItemsLoaded]);

  // Update grid when tiles are added
  useEffect(() => {
    const grid = gridRef.current;
    if (grid) {
      grid.batchUpdate();
      tiles.forEach((tile: IQuinoDashboardTileItem) => {
        if (!tile.widget) {
          for (const [position, value] of Object.entries(tile.item.position)) {
            tile.ref.current.setAttribute('gs-' + position, String(value));
          }
          tile.ref.current.setAttribute('gs-resize-handles', 'se, sw');
          tile.ref.current.setAttribute('gs-id', tile.item.id);
          tile.widget = grid.makeWidget(tile.ref.current);
        }
      });
      grid.commit();
    }
  }, [tiles]);

  // Toggle edit mode
  useEffect(() => {
    gridRef.current?.enableMove(editMode);
    gridRef.current?.enableResize(editMode);
    gridRef.current?.getGridItems().forEach((item: GridItemHTMLElement) => gridRef.current?.movable(item, editMode));
  }, [editMode]);

  return (
    <div className={'quino-dashboard'}>
      {initialItemsLoaded && tiles.length === 0 && (
        <div className={'quino-dashboard-empty-message'}>
          {!editMode && <p>{translationService.translate('Dashboard.HintEmptyDashboard')}</p>}
          {!readOnly && responsiveMode !== TResponsiveMode.Phone && (
            <p>{editMode ? translationService.translate('Dashboard.HintAddElements') : translationService.translate('Dashboard.HintSwitchToEdit')}</p>
          )}
        </div>
      )}

      <ScrollView className={getIosClassName('quino-dashboard-scrollarea' + (tiles.length === 0 ? ' is--hidden' : ''))}>
        <div className={'grid-stack quino-dashboard-wrapper'}>
          {tiles.map((tile: IQuinoDashboardTileItem) => {
            return (
              <div ref={tile.ref} key={tile.item.id} className={'grid-stack-item quino-dashboard-tile-wrapper'}>
                <QuinoDashboardTile
                  savePayload={(payload) => {
                    tile.item.payload = payload;
                    setTiles([...tiles]); // necessary to trigger rerender
                    bookmark.setHasChanges(true);
                  }}
                  id={tile.item.id}
                  payload={tile.item.payload}
                  position={tile.item.position}
                  type={tile.item.type}
                  deleteTile={() => removeTile(tile.item.id)}
                  editable={editMode}
                  currentHeight={tile.widget?.gridstackNode?.h || 0}
                  currentWidth={tile.widget?.gridstackNode?.w || 0}
                />
              </div>
            );
          })}
        </div>
      </ScrollView>
    </div>
  );
}
