import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import {
  IAuthenticationService,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  IServerError,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols,
  INotificationService,
  INotificationServiceSymbol,
  IFieldError
} from '@quino/core';
import {
  getResponsiveClassName,
  QuinoErrorSummary,
  QuinoLabeled,
  QuinoPasswordBox,
  QuinoPopup,
  searchForErrors,
  TResponsiveMode,
  useResponsiveMode,
  useService,
  useShortcutHandler,
  IQuinoShortcutSettings,
  IQuinoShortcutSettingsSymbol
} from '@quino/ui';
import isArray from 'lodash/isArray';
import { ScrollView } from 'devextreme-react/scroll-view';

interface IChangePasswordComponentProps {
  close: () => void;
}

export function CUIChangePasswordComponent(props: IChangePasswordComponentProps) {
  const authenticationService = useService<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const notificationService = useService<INotificationService>(INotificationServiceSymbol);
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);
  const responsiveMode = useResponsiveMode();

  const [oldPasswordErrors, setOldPasswordErrors] = useState<string[]>([]);
  const [newPasswordRequirementsErrors, setNewPasswordRequirementsErrors] = useState<string[]>([]);
  const [newPasswordErrors, setNewPasswordErrors] = useState<string[]>([]);
  const [repeatPasswordErrors, setRepeatPasswordErrors] = useState<string[]>([]);
  const [error, setError] = useState<string[] | undefined>(undefined);

  const oldPasswordTextBox = useRef<TextBox | null>(null);
  const newPasswordTextBox = useRef<TextBox | null>(null);
  const repeatPasswordTextBox = useRef<TextBox | null>(null);

  useShortcutHandler([shortcutSettings.cancel], props.close);

  const setFocusToOldPasswordTextBox = useCallback(() => {
    oldPasswordTextBox.current?.instance.focus();
  }, []);

  const requiredCheckFunction = useCallback(
    (text: string) => {
      return { isValid: text !== '', errorMessage: translationService.translate('Validations.RequiredError') };
    },
    [translationService]
  );

  const checkRepeatPassword = useCallback(
    (text: string) => {
      return { isValid: text === getCurrentNewPassword(), errorMessage: translationService.translate('Validations.PasswordsDoNotMatch') };
    },
    [translationService]
  );

  const getCurrentOldPassword = () => oldPasswordTextBox.current?.instance?.option('text');
  const getCurrentNewPassword = () => newPasswordTextBox.current?.instance?.option('text');
  const getCurrentRepeatPassword = () => repeatPasswordTextBox.current?.instance?.option('text');

  const changePassword = useCallback(() => {
    const currentNewPassword = getCurrentNewPassword() ?? '';
    const currentOldPassword = getCurrentOldPassword() ?? '';
    const currentRepeatPassword = getCurrentRepeatPassword() ?? '';

    let errorCount = 0;
    errorCount += searchForErrors(currentOldPassword, setOldPasswordErrors, [requiredCheckFunction]);
    errorCount += searchForErrors(currentNewPassword, setNewPasswordErrors, [requiredCheckFunction]);
    errorCount += searchForErrors(currentRepeatPassword, setRepeatPasswordErrors, [requiredCheckFunction, checkRepeatPassword]);

    if (errorCount > 0) {
      return;
    }

    setError(undefined);
    const unload = loadingFeedback.load();
    authenticationService
      .changePasswordAsync({
        newPassword: currentNewPassword,
        oldPassword: currentOldPassword
      })
      .then(() => {
        props.close();
        notificationService.notify({
          message: translationService.translate('QuinoAuthenticationFeedback.TitleConfirmPasswordChange'),
          type: 'success',
          area: 'global',
          autoDisappear: true
        });
      })
      .catch((errors: IServerError[]) => {
        if (errors && isArray(errors)) {
          const generalErrors: string[] = [];
          for (const error of errors) {
            if (error.code === 'PasswordMismatch') {
              setOldPasswordErrors([...oldPasswordErrors, error.description]);
            } else {
              generalErrors.push(error.description);
            }
          }
          generalErrors.length > 0 && setError(generalErrors);
        }
      })
      .finally(unload);
  }, [authenticationService, checkRepeatPassword, loadingFeedback, oldPasswordErrors, props.close, requiredCheckFunction]);

  return (
    <QuinoPopup
      visible={true}
      showTitle={false}
      showCloseButton={true}
      closeOnOutsideClick={false}
      height={'auto'}
      width={responsiveMode === TResponsiveMode.Phone ? '90%' : 'auto'}
      maxWidth={responsiveMode === TResponsiveMode.Phone ? '320' : 'unset'}
      minWidth={'120'}
      onShown={setFocusToOldPasswordTextBox}
      onHiding={props.close}>
      <div className={getResponsiveClassName('quino-auth-content', responsiveMode)}>
        <div className={getResponsiveClassName('quino-auth-form-area', responsiveMode)}>
          <h3 className={'quino-auth-title'}>{translationService.translate('QuinoAuthenticationFeedback.TitleChange')}</h3>
          <form className={'quino-auth-form-content'}>
            <ScrollView className={'quino-auth-scrollview'} useNative={false}>
              {/*Do not remove div to prevent scroll view crashes when empty*/}
              <div>
                <QuinoLabeled
                  label={translationService.translate('QuinoAuthenticationFeedback.OldPassword')}
                  errorMessages={oldPasswordErrors}
                  required={true}>
                  <QuinoPasswordBox
                    ref={oldPasswordTextBox}
                    onEnterKey={changePassword}
                    valueChangeEvent={'keyup'}
                    isValid={oldPasswordErrors.length === 0}
                    onValueChanged={(e) => searchForErrors(e.value, setOldPasswordErrors, [requiredCheckFunction])}
                  />
                </QuinoLabeled>
                <QuinoLabeled
                  label={translationService.translate('QuinoAuthenticationFeedback.NewPassword')}
                  errorMessages={[...newPasswordErrors, ...newPasswordRequirementsErrors]}
                  required={true}>
                  <QuinoPasswordBox
                    onValidChanged={(fieldErrors: IFieldError[]) => {
                      setNewPasswordRequirementsErrors(fieldErrors.map((error: IFieldError) => error.errorMessage));
                    }}
                    showRequirements={true}
                    ref={newPasswordTextBox}
                    onEnterKey={changePassword}
                    valueChangeEvent={'keyup'}
                    isValid={newPasswordErrors.length === 0}
                    onValueChanged={(e) => {
                      searchForErrors(e.value, setNewPasswordErrors, [requiredCheckFunction]);
                      searchForErrors(getCurrentRepeatPassword() ?? '', setRepeatPasswordErrors, [requiredCheckFunction, checkRepeatPassword]);
                    }}
                  />
                </QuinoLabeled>
                <QuinoLabeled
                  label={translationService.translate('QuinoAuthenticationFeedback.RepeatPassword')}
                  errorMessages={repeatPasswordErrors}
                  required={true}>
                  <QuinoPasswordBox
                    // @ts-ignore
                    ref={repeatPasswordTextBox}
                    onEnterKey={changePassword}
                    valueChangeEvent={'keyup'}
                    isValid={repeatPasswordErrors.length === 0}
                    onValueChanged={(e) => searchForErrors(e.value, setRepeatPasswordErrors, [requiredCheckFunction, checkRepeatPassword])}
                  />
                </QuinoLabeled>
                <QuinoErrorSummary errorMessages={error} topPadding />
              </div>
            </ScrollView>
            <div className={getResponsiveClassName('quino-change-password-button-bar', responsiveMode)}>
              <Button
                text={translationService.translate('Cancel')}
                icon={'material-icons-outlined close'}
                stylingMode='contained'
                style={{ float: 'right', marginTop: '16px', marginRight: '10px' }}
                onClick={() => props.close()}
              />
              <Button
                text={translationService.translate('QuinoAuthenticationFeedback.ButtonChange')}
                icon={'material-icons-outlined done'}
                type='default'
                stylingMode='contained'
                style={{ float: 'right', marginTop: '16px' }}
                onClick={changePassword}
              />
            </div>
          </form>
        </div>
      </div>
    </QuinoPopup>
  );
}
