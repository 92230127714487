import { IExportExcelTitleSettings } from './IExportExcelTitleSettings';

export interface IExportExcelSettings {
  fontName: string;
  fontSize: number;
  wrapText: boolean;
  columnMarginRight: number;
  pageMarginTop: number;
  pageMarginBottom: number;
  pageMarginLeft: number;
  pageMarginRight: number;
  rowMarginTop: number;
  pageMarginHeader: number;
  pageMarginFooter: number;
  rowMarginTopHeight: number;
  tabItemTitleSettings: IExportExcelTitleSettings;
  groupBoxTitleSettings: IExportExcelTitleSettings;
  tableTitleSettings: IExportExcelTitleSettings;
  workSheetTitleSettings: IExportExcelTitleSettings;
  printCreationTimeStamp: boolean;
}

export const defaultExcelExportSettings: IExportExcelSettings = {
  fontName: 'Calibri',
  fontSize: 11,
  wrapText: true,
  columnMarginRight: 2.5,
  rowMarginTop: 0,
  rowMarginTopHeight: 10,
  pageMarginTop: 1.5,
  pageMarginBottom: 1,
  pageMarginLeft: 1.5,
  pageMarginRight: 1.0,
  pageMarginHeader: 0.76,
  pageMarginFooter: 0.76,
  tabItemTitleSettings: { fontSize: 13, rowMargin: 1, isBold: true },
  groupBoxTitleSettings: { fontSize: 11, rowMargin: 1, isBold: true },
  tableTitleSettings: { fontSize: 11, rowMargin: 1, isBold: true },
  workSheetTitleSettings: { fontSize: 15, rowMargin: 0, isBold: true },
  printCreationTimeStamp: false
};
