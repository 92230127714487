import { useService } from '../ioc/hook';
import { IFormatStringService, IMetaElement, QuinoCoreServiceSymbols } from '@quino/core';

/**
 * Retrieve the format string assigned with this element.
 * @param element - the element to retrieve the format string from.
 */
export function useFormatString(element: IMetaElement): string | undefined {
  const quinoFormatStringService = useService<IFormatStringService>(QuinoCoreServiceSymbols.IFormatStringService);

  return quinoFormatStringService.get(element);
}
