import { IMetaGroup, IMetaProperty, isIMetaGroup, isIMetaProperty } from '@quino/core';

export const flattenGroupProperties = (metaGroup: IMetaGroup): IMetaProperty[] => {
  const properties: IMetaProperty[] = [];
  metaGroup.elements.forEach((e) => {
    const groupProperties: IMetaProperty[] = isIMetaGroup(e) ? flattenGroupProperties(e) : isIMetaProperty(e) ? [e] : [];
    // eslint-disable-next-line prefer-spread
    properties.push.apply(properties, groupProperties);
  });

  return properties;
};
