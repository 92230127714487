import { IExpression, isExpression } from './IExpression';

/**
 * A namespace expression.
 */
export interface INamespaceExpression extends IExpression {
  namespace: string;
}

export const isNamespaceExpression = (object: any): object is INamespaceExpression => {
  return object != null && isExpression(object) && (object as INamespaceExpression).namespace !== undefined;
};
