import { IQuinoDataGridProps } from './QuinoDataGrid';
import { IMetaClass, IMetaElement, INotification } from '@quino/core';
import dxDataGrid from 'devextreme/ui/data_grid';
import { IQuinoContextMenuItem } from '../ContextMenu';

export const IExportButtonFactorySymbol = Symbol.for('IExportButtonFactory');

export interface IExportButtonFactory {
  createXmlImportItem(): IQuinoContextMenuItem;

  createXmlExportItem(
    dataGrid: dxDataGrid,
    className: string,
    notify: (notification: INotification | undefined) => void,
    filter?: Map<string, string> | string[]
  ): IQuinoContextMenuItem;

  createExcelExportItem(toolbarEvent: dxDataGrid, props: IQuinoDataGridProps, selectedOnly?: boolean): IQuinoContextMenuItem;

  createPdfExportItem(dataGrid: dxDataGrid, props: IQuinoDataGridProps, selectedOnly?: boolean): IQuinoContextMenuItem;

  createExportButton(exportContextMenuItems: IQuinoContextMenuItem[], layout: IMetaElement | undefined, metaClass: IMetaClass | undefined): object;
}
