export interface IValidationRule {
  validationType: ValidationType;
  message?: string;
  minValue?: any;
  maxValue?: any;
  regularExpression?: string;
}

export enum ValidationType {
  /** Dummy entry to occupy the default value slot (JSON serialization) */
  None = 'None',
  Required = 'Required',
  Bounds = 'Bounds',
  RegularExpression = 'RegularExpression',
  Unknown = 'Unknown'
}
