/**
 * Symbol to retrieve the BookmarkScopingService from the IoC.
 */
export const BookmarkScopingServiceSymbol = Symbol.for('BookmarkScopingService');

/**
 * Scope service for the bookmarks
 */
export interface IBookmarkScopingService {
  /**
   * Sets the scope.
   * @param scope - the scope to apply.
   */
  setScope(scope: string | null): void;

  /**
   * Gets the scope.
   */
  getScope(): string | null;
}
