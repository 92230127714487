export enum LayoutType {
  /** The layout to use to display a single object for editing. */
  Detail = 'Detail',

  /** The layout to use to display a list of objects. */
  List = 'List',

  /** The layout to use to calculate the standard title for an object. */
  Title = 'Title',

  /** The layout to use to search a list of objects. */
  Search = 'Search',

  // TODO: Create issue for DropDown type.
  // https://secure.encodo.ch/jira/browse/QWC-165

  /** The layout to use for custom views. */
  Custom = 'Custom',

  /** The layout to use for dashboards */
  Dashboard = 'Dashboard'
}
