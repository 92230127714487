import React, { useMemo, useState } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import { IMetadataTree, LayoutType, QuinoCoreServiceSymbols } from '@quino/core';
import { useService } from '../../ioc';

export interface IQuinoLayoutSelectorProps {
  metaClass?: string;
  layoutType: LayoutType;
  value?: string;
  onSelect: (value: string) => void;
  hidden?: boolean;
  hideIfNoOptions?: boolean;
}

export const QuinoLayoutSelector: React.FC<IQuinoLayoutSelectorProps> = (props) => {
  const { layoutType, onSelect, metaClass, hidden, hideIfNoOptions } = props;

  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);

  const [value, setValue] = useState<string | undefined>(props.value);

  const source = useMemo(
    () => (metaClass ? metadataTree.getLayouts(metaClass).filter((x) => x.type === layoutType) : []),
    [metadataTree, layoutType, metaClass]
  );

  return (
    <SelectBox
      deferRendering={false}
      value={value}
      items={source}
      valueExpr={'name'}
      displayExpr={'caption'}
      disabled={!source.length}
      visible={!(hideIfNoOptions && source.length < 2) && !hidden}
      onValueChanged={(e) => {
        setValue(e.value);
        onSelect(e.value);
      }}
    />
  );
};
