import { inject, injectable } from 'inversify';
import { IInitialBookmarkService } from './IInitialBookmarkService';
import { IBookmark } from './IBookmark';
import { QuinoUIServiceSymbols } from '../ioc';
import { IBookmarkFactory } from './IBookmarkFactory';

@injectable()
export class InitialBookmarkService implements IInitialBookmarkService {
  constructor(@inject(QuinoUIServiceSymbols.IBookmarkFactory) private readonly bookmarkFactory: IBookmarkFactory) {}

  async getHomeBookmark(): Promise<IBookmark | undefined> {
    return this.bookmarkFactory.createDashboard();
  }

  async getFromPathOrHomeBookmark(): Promise<IBookmark | undefined> {
    return this.bookmarkFactory.createFromPath(document.location.pathname + document.location.search).catch(async () => this.getHomeBookmark());
  }
}
