import * as React from 'react';
import { IObjectBookmark } from '../../bookmarks';
import {
  getAspectOrDefault,
  IconAspectIdentifier,
  IIconAspect,
  INavigationLinkAspect,
  NavigationLinkAspectIdentifier,
  getMetaProperty,
  IMetaElement,
  BackgroundColorAspectIdentifier,
  FontColorAspectIdentifier,
  IBackgroundColorAspect,
  IFontColorAspect,
  IColorAspect,
  isExpression,
  QuinoCoreServiceSymbols,
  IExpressionEvaluator,
  IValueFormatter,
  IValueFormatterSymbol
} from '@quino/core';
import { useMetaPropertyValueState, useOnBookmarkAnyEvent, useRerender } from '../Util';
import { useMetadata } from '../../settings';
import { useService } from '../../ioc';
import { INavigationLinkService, INavigationLinkServiceSymbol } from '../../navigation';
import { useCallback, useMemo } from 'react';

export function QuinoObjectSummaryItem(props: {
  element: IMetaElement;
  bookmark: IObjectBookmark;
  showLeftBorder: boolean;
  showRightBorder: boolean;
}) {
  const navigationLinkService = useService<INavigationLinkService>(INavigationLinkServiceSymbol);
  const expressionEvaluator = useService<IExpressionEvaluator>(QuinoCoreServiceSymbols.IExpressionEvaluator);
  const valueFormatter = useService<IValueFormatter>(IValueFormatterSymbol);

  const { element, bookmark } = props;
  const metaProperty = getMetaProperty(element);
  const [value] = useMetaPropertyValueState<any>(metaProperty, bookmark);
  const formattedValue = useMemo(() => valueFormatter.formatValue(metaProperty, value), [metaProperty, value, valueFormatter]);
  const { visible, enabled } = useMetadata(metaProperty, bookmark.genericObject);
  const rerender = useRerender();

  useOnBookmarkAnyEvent(bookmark, rerender);

  const extractColor = useCallback(
    (colorAspect: IColorAspect | null): string | undefined => {
      if (!colorAspect) {
        return undefined;
      }

      return isExpression(colorAspect.color) ? expressionEvaluator.evaluate<string>(colorAspect.color, bookmark.genericObject) : colorAspect.color;
    },
    [bookmark.genericObject, expressionEvaluator]
  );

  const navigationAspect = getAspectOrDefault<INavigationLinkAspect>(element, NavigationLinkAspectIdentifier);
  const onClick = navigationLinkService.getOnClickAction(element.name, navigationAspect, bookmark);

  const icon = getAspectOrDefault<IIconAspect>(element, IconAspectIdentifier)?.icon || 'material-icons-outlined help_outline';
  const iconColor = extractColor(getAspectOrDefault<IFontColorAspect>(element, FontColorAspectIdentifier));
  const buttonColor = extractColor(getAspectOrDefault<IBackgroundColorAspect>(element, BackgroundColorAspectIdentifier));

  return visible ? (
    <div
      className={`quino-object-summary-item-wrapper ${enabled ? '' : 'disabled'} ${props.showLeftBorder ? 'left-border' : ''} ${
        props.showRightBorder ? 'right-border' : ''
      }`}>
      <div onClick={enabled ? onClick : undefined} className={'quino-object-summary-item' + (onClick && enabled ? ' is--clickable' : '')}>
        <i className={icon + ' quino-object-summary-item-icon'} style={{ color: iconColor, background: buttonColor }} title={metaProperty.caption}>
          {navigationAspect?.openInNewTab && <div className={'quino-object-summary-new-tab-indicator'} style={{ borderColor: iconColor }} />}
        </i>
        <div className={'quino-object-summary-item-text'} title={formattedValue}>
          {formattedValue}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
