import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Scheduler } from 'devextreme-react';
import { QuinoUIServiceSymbols, useService } from '../ioc';
import {
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  LayoutType,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { useCalendarConfiguration } from './configuration/useCalendarConfiguration';
import { TCalendarView } from './configuration/ICalendarConfiguration';
import { CalendarEntryTooltipContent } from './CalendarEntryTooltipContent';
import { CalendarBookmarkStateIdentifier, ICalendarBookmark, TCalendarStateObject } from '../bookmarks/ICalendarBookmark';
import { IBookmarkFactory } from '../bookmarks/IBookmarkFactory';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../data';
import { INavigationLinkService, INavigationLinkServiceSymbol } from '../navigation/NavigationLinks/INavigationLinkService';
import { TResponsiveMode, useResponsiveMode } from '../responsivity';
import { CalendarRequestObject, ICalendarDataService, ICalendarDataServiceSymbol, TCalendarAppointment } from './service';
import { isCtrlOrCmdEvent } from '../shortcuts';

export interface ICalendarProps {
  bookmark: ICalendarBookmark;
}

type TDateRangeObject = { start: Date; end: Date };

export const Calendar: FC<ICalendarProps> = (props) => {
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const odataFactory = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const calendarDataService = useService<ICalendarDataService>(ICalendarDataServiceSymbol);

  const navigationLinkService = useService<INavigationLinkService>(INavigationLinkServiceSymbol);

  const responsiveMode = useResponsiveMode();
  const configuration = useCalendarConfiguration(props.bookmark.aspect);

  const [appointments, setAppointments] = useState<TCalendarAppointment[] | undefined>(undefined);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const schedulerRef = useRef<Scheduler>(null);
  const requestObjectRef = useRef<CalendarRequestObject>();

  const getCurrentDateRange = (): TDateRangeObject => {
    return { start: schedulerRef.current?.instance.getStartViewDate(), end: schedulerRef.current?.instance.getEndViewDate() } as TDateRangeObject;
  };

  const getCurrentView = (): TCalendarView => {
    return schedulerRef.current?.instance.option().currentView as TCalendarView;
  };

  const dateOfToday = useMemo(() => {
    return new Date();
  }, []);

  const initialState = useMemo<TCalendarStateObject | undefined>(() => {
    const bookmarkState = props.bookmark.getCalenderState();
    if (bookmarkState && responsiveMode !== TResponsiveMode.Phone && configuration.availableViews.includes(bookmarkState.view)) {
      let startDate = new Date(bookmarkState.start);

      if (bookmarkState.view === 'month' || bookmarkState.view === 'timelineMonth') {
        startDate = new Date(startDate.getUTCFullYear(), startDate.getMonth() + 1, 1, 0, 0, 0);
      }

      return { start: startDate, end: bookmarkState.end, view: bookmarkState.view };
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bookmark]);

  // Called by double-click on calendar entry or via popover-button
  const navigateToDetail = (event: any, data: TCalendarAppointment): void => {
    const isCtrlOrCmd = isCtrlOrCmdEvent(event.event);
    const metaClass = data.metaClass;
    const id = data.id;

    if (metaClass != null && id != null) {
      const unload = loadingFeedback.load();
      const layout = layoutResolver.resolveSingle({ metaClass: metaClass, type: LayoutType.Detail });

      odataFactory
        .fetch(id, layout, metaClass)
        .then((genericObject) => {
          if (genericObject !== null && genericObject.primaryKey !== 'null') {
            const objectBookmark = bookmarkFactory.createObject(genericObject, layout);
            navigationLinkService.openInTarget(objectBookmark, isCtrlOrCmd ? 'newTab' : 'current');
          }
        })
        .catch(logger.logError)
        .finally(unload);
    } else {
      logger.logWarn(`MetaClass or Id is null. Cannot navigate to object source detail of appointment. See: ${data}`);
    }
  };

  const fetchAppointments = () => {
    // There needs to be a timeout because otherwise currentDateRange & currentView will be out-of-sync
    window.setTimeout(() => {
      requestObjectRef.current?.cancel();

      const unload = loadingFeedback.loadAtPosition({ my: 'center', at: 'center', of: '#quino-calendar' });
      const currentDateRange = getCurrentDateRange();
      const currentView = getCurrentView();

      const calendarState: TCalendarStateObject = { start: currentDateRange.start, end: currentDateRange.end, view: currentView };
      props.bookmark.setStateValue(CalendarBookmarkStateIdentifier, JSON.stringify(calendarState));

      const requestObject = calendarDataService.getRequestObject(props.bookmark.aspect.name, calendarState.start, calendarState.end);
      requestObjectRef.current = requestObject;

      if (!requestObject.isCancelled()) {
        requestObject
          .fetch()
          .then((result) => {
            !requestObject.isCancelled() && setAppointments(result);
          })
          .catch(logger.logError)
          .finally(() => {
            requestObjectRef.current = undefined;
            unload();
          });
      } else {
        requestObjectRef.current = undefined;
        unload();
      }
    });
  };

  // Subscribe to new bookmark & fetch initial appointments
  useEffect(() => {
    const subSymbol = props.bookmark.subscribeToRefresh(fetchAppointments);
    fetchAppointments();

    return () => {
      props.bookmark.unsubscribeFromRefresh(subSymbol);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bookmark]);

  return (
    <Scheduler
      id='quino-calendar'
      className='quino-calendar'
      ref={schedulerRef}
      onContentReady={(e) => {
        if (!isInitialized) {
          e.component.scrollTo(dateOfToday);
          setIsInitialized(true);
        }
      }}
      dataSource={appointments}
      views={responsiveMode === TResponsiveMode.Phone ? ['agenda'] : configuration.availableViews}
      defaultCurrentView={responsiveMode === TResponsiveMode.Phone ? 'agenda' : initialState ? initialState.view : configuration.defaultView}
      defaultCurrentDate={initialState ? initialState.start : dateOfToday}
      // TODO: Use configuration to dis-/ enable drop-down-view-switcher #11659
      useDropDownViewSwitcher={true}
      adaptivityEnabled={false}
      showCurrentTimeIndicator={true}
      cellDuration={60}
      editing={false}
      height={'100%'}
      width={'100%'}
      remoteFiltering={true}
      dateSerializationFormat='yyyy-MM-ddTHH:mm:ss'
      textExpr='summary'
      startDateExpr='start'
      endDateExpr='end'
      descriptionExpr='description'
      allDayExpr='isAllDay'
      recurrenceRuleExpr='serializedRecurrenceRule'
      recurrenceExceptionExpr='serializedExceptionRule'
      onCurrentViewChange={() => fetchAppointments()}
      onCurrentDateChange={() => fetchAppointments()}
      onAppointmentFormOpening={(e) => {
        e.cancel = true;
      }}
      onAppointmentDblClick={(e) => {
        e.cancel = true;
        if (!configuration.hideLink) {
          navigateToDetail(e, e.appointmentData as TCalendarAppointment);
        }
      }}
      appointmentTooltipRender={(e) => {
        return (
          <CalendarEntryTooltipContent
            appointment={e.appointmentData as TCalendarAppointment}
            navigateToDetail={navigateToDetail}
            hideLink={configuration.hideLink}
          />
        );
      }}
    />
  );
};
