import { inject, injectable } from 'inversify';
import { IResponsivityService, TResponsiveMode, TScreenHeight } from './IResponsivityService';
import { IResponsivityBreakpointService, IResponsivityBreakpointServiceSymbol } from './IResponsivityBreakpointService';

@injectable()
export class ResponsivityService implements IResponsivityService {
  constructor(@inject(IResponsivityBreakpointServiceSymbol) private readonly breakpointService: IResponsivityBreakpointService) {
    this.currentResponsiveMode = this.calculateResponsiveMode();
    this.currentScreenHeight = ResponsivityService.calculateScreenHeightMode();
    window.addEventListener('resize', this.setResizeCheckTimeout);
  }

  subscribeToWidthChanges(callback: (newMode: TResponsiveMode) => void): Symbol {
    const symbol = Symbol();
    this.widthChangeHandlers.set(symbol, callback);
    return symbol;
  }

  unsubscribeFromWidthChanges(symbol: Symbol): void {
    this.widthChangeHandlers.delete(symbol);
  }

  getCurrentResponsiveMode(): TResponsiveMode {
    return this.currentResponsiveMode;
  }

  subscribeToHeightChanges(callback: (newMode: TScreenHeight) => void): Symbol {
    const symbol = Symbol();
    this.heightChangeHandlers.set(symbol, callback);
    return symbol;
  }

  unsubscribeFromHeightChanges(symbol: Symbol): void {
    this.heightChangeHandlers.delete(symbol);
  }

  getCurrentHeightMode(): TScreenHeight {
    return this.currentScreenHeight;
  }

  private calculateResponsiveMode(): TResponsiveMode {
    const newWidth = window.innerWidth;
    const tabletBreakpoint = this.breakpointService.getTabletBreakpoint();
    const phoneBreakpoint = this.breakpointService.getPhoneBreakpoint();

    if (newWidth < phoneBreakpoint) {
      return TResponsiveMode.Phone;
    } else if (newWidth < tabletBreakpoint) {
      return TResponsiveMode.Tablet;
    } else {
      return TResponsiveMode.Desktop;
    }
  }

  private static calculateScreenHeightMode(): TScreenHeight {
    return window.innerHeight < 400 ? TScreenHeight.Small : TScreenHeight.Normal;
  }

  private readonly setResizeCheckTimeout = () => {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.checkResponsiveMode();
      this.checkScreenHeightMode();
    }, 100);
  };

  private readonly checkResponsiveMode = () => {
    let newMode = this.calculateResponsiveMode();
    if (newMode !== this.currentResponsiveMode) {
      this.currentResponsiveMode = newMode;
      this.notifyWidthChangeHandlers(newMode);
    }
  };

  private readonly checkScreenHeightMode = () => {
    let newHeight = ResponsivityService.calculateScreenHeightMode();
    if (newHeight !== this.currentScreenHeight) {
      this.currentScreenHeight = newHeight;
      this.notifyHeightChangeHandlers(newHeight);
    }
  };

  private readonly notifyWidthChangeHandlers = (newMode: TResponsiveMode) => {
    this.widthChangeHandlers.forEach((callback) => callback(newMode));
  };

  private readonly notifyHeightChangeHandlers = (newHeight: TScreenHeight) => {
    this.heightChangeHandlers.forEach((callback) => callback(newHeight));
  };

  private timeOut: number | undefined;
  private currentResponsiveMode: TResponsiveMode;
  private currentScreenHeight: TScreenHeight;
  private readonly widthChangeHandlers: Map<Symbol, (newMode: TResponsiveMode) => void> = new Map();
  private readonly heightChangeHandlers: Map<Symbol, (newMode: TScreenHeight) => void> = new Map();
}
