import { IDashboardConfiguration, IDashboardConfigurationSymbol } from './IDashboardConfiguration';
import { useService } from '../../ioc';
import { IDashboardConfigurationService, IDashboardConfigurationServiceSymbol } from './IDashboardConfigurationService';
import { usePromise } from '../../components/Util/PromiseReducer';

export function useDashboardConfiguration(): IDashboardConfiguration {
  const defaultConfiguration = useService<IDashboardConfiguration>(IDashboardConfigurationSymbol);
  const configurationService = useService<IDashboardConfigurationService>(IDashboardConfigurationServiceSymbol);

  const [configurationFromService] = usePromise<IDashboardConfiguration, any>(async () => configurationService.getDashboardConfiguration(), []);

  return configurationFromService || defaultConfiguration;
}
