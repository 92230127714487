import React from 'react';
import { IContainer } from '../../../ioc';
import dxDataGrid from 'devextreme/ui/data_grid';
import { IListSelectionAction, QuinoDataGridSelectionActionBar } from './QuinoDataGridSelectionActionBar';
import { QuinoDataGridInlineEditActionBar } from './QuinoDataGridInlineEditActionBar';
import { TGridEditingMode } from '../IDataGridSettingsService';

export function QuinoDataGridActionBar(props: {
  gridComponent: dxDataGrid | undefined;
  gridMode: TGridEditingMode | undefined;
  applicationContainer: IContainer;
  inlineCreateButtonVisible: boolean;
  selectionActions: IListSelectionAction[];
  numberOfSelectedRows: number;
}) {
  switch (props.gridMode) {
    case TGridEditingMode.InlineEditing:
      return (
        <QuinoDataGridInlineEditActionBar
          gridComponent={props.gridComponent}
          applicationContainer={props.applicationContainer}
          showCreateButton={props.inlineCreateButtonVisible}
        />
      );
    case TGridEditingMode.Selection:
      return (
        <QuinoDataGridSelectionActionBar
          gridComponent={props.gridComponent}
          applicationContainer={props.applicationContainer}
          actions={props.selectionActions}
          numberOfSelectedRows={props.numberOfSelectedRows}
        />
      );
    default:
      return <></>;
  }
}
