import React, { PropsWithChildren, useMemo } from 'react';
import {
  IQuinoShortcut,
  useService,
  isControlKeyShortCut,
  isShiftControlKeyShortCut,
  IShortcutInformationService,
  IShortcutInformationServiceSymbol,
  IShortcutInformation
} from '@quino/ui';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';

export function CUIShortcutItem(props: PropsWithChildren<{ shortcut: IQuinoShortcut }>) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const shortcutInformationService = useService<IShortcutInformationService>(IShortcutInformationServiceSymbol);
  const shortcutInformation = useMemo<IShortcutInformation>(() => shortcutInformationService.get(), [shortcutInformationService]);

  const { key, descriptionTranslationKey } = props.shortcut;

  const description = useMemo(() => translationService.translate(descriptionTranslationKey), [descriptionTranslationKey, translationService]);

  const keyCombination = useMemo(() => {
    if (isShiftControlKeyShortCut(props.shortcut)) {
      return (
        <div className={'single-key-combination-container'}>
          <kbd>{shortcutInformation.controlKey}</kbd>
          <div className={'stretch'}>{' + '}</div>
          <kbd>{shortcutInformation.shiftKey}</kbd>
          <div className={'stretch'}>{' + '}</div>
        </div>
      );
    }
    if (isControlKeyShortCut(props.shortcut)) {
      return (
        <div className={'single-key-combination-container'}>
          <kbd>{shortcutInformation.controlKey}</kbd>
          <div className={'stretch'}>{' + '}</div>
        </div>
      );
    }

    return React.Fragment;
  }, [props, shortcutInformation]);

  return (
    <div className={'quino-ecui-shortcut-information-item'}>
      <div className={'key-combination-container'}>
        <div className={'key-combination'}>
          {keyCombination}
          <kbd>{key.toUpperCase()}</kbd>
        </div>
      </div>
      {`${description}`}
    </div>
  );
}
