import { ControlExcelMapperBase } from './ControlExcelMapperBase';
import { IMetaElement } from '../../../../../meta/IMetaElement';
import { IGenericObject } from '../../../../../data/IGenericObject';
import { injectable } from 'inversify';

export const CheckBoxExcelMapperSymbol = Symbol.for('CheckBoxExcelMapper');

@injectable()
export class CheckBoxExcelMapper extends ControlExcelMapperBase {
  getValue(element: IMetaElement, genericObject: IGenericObject): string {
    if (super.getValue(element, genericObject)) {
      return '[X]';
    } else {
      return '[ ]';
    }
  }

  getMappableElements(): string[] {
    return ['CheckBox'];
  }
}
