import { ILayoutScope } from './ILayoutScope';
import { IMetaLayout } from '../meta';
import { IExpressionEvaluator } from '../expressions';

/**
 * Empty layout scope which does not apply any restrictions.
 */
export class EmptyLayoutScope implements ILayoutScope {
  applicable(_layout: IMetaLayout, _evaluator: IExpressionEvaluator): boolean {
    return true;
  }
}
