import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression } from './IExpression';
import { inject, injectable } from 'inversify';
import { isNamespaceExpression } from './INamespaceExpression';
import { INamespaceProvider, NamespaceProviderSymbol } from './namespaces';

export const NamespaceExpressionEvaluatorSymbol = Symbol.for('NamespaceExpressionEvaluator');

@injectable()
export class NamespaceExpressionEvaluator implements IExpressionEvaluator {
  constructor(@inject(NamespaceProviderSymbol) private readonly namespaces: INamespaceProvider) {}

  evaluate<TValue>(expression: IExpression | TValue, _context: any): TValue {
    if (isNamespaceExpression(expression)) {
      // @ts-ignore
      return this.namespaces.getInstances().find((value) => value.name.toLowerCase() === expression.namespace.toLowerCase());
    }

    // @ts-ignore
    return expression;
  }
}
