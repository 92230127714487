import { IMetaPropertyValueService } from './IMetaPropertyValueService';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IExpressionEvaluator } from '../expressions';
import { IMetaProperty, IMetaRelation, isMetaRelation } from '../meta';
import { IGenericObject } from './IGenericObject';
import { IControlBehaviorRegistry, IFieldError, IFieldValidationResult, IFieldValidatorProvider, IValidationContext } from '../validations';
import { ODataUrlPathDelimiter } from '../api/QuinoServer';
import { IMetadataTree } from '../api';

@injectable()
export class MetaPropertyValueService implements IMetaPropertyValueService {
  constructor(
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) protected expressionEvaluator: IExpressionEvaluator,
    @inject(QuinoCoreServiceSymbols.IFieldValidatorProvider) protected fieldValidatorProvider: IFieldValidatorProvider,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(QuinoCoreServiceSymbols.ControlValidatorRegistry)
    private readonly controlValidatorRegistry: IControlBehaviorRegistry<IFieldValidationResult>
  ) {}

  getElementPath(metaProperty: IMetaProperty): string {
    if (isMetaRelation(metaProperty)) {
      return metaProperty.sourceProperties[0];
    }

    return metaProperty.path;
  }

  getPlainValue<T>(metaProperty: IMetaProperty, genericObject: IGenericObject): T | undefined {
    return MetaPropertyValueService.getPlainFieldValue(metaProperty, genericObject);
  }

  getRelatedPropertyData(
    baseProperty: IMetaProperty,
    genericObject: IGenericObject
  ): { metaProperty: IMetaProperty; genericObject: IGenericObject } | undefined {
    const pathElements = baseProperty.path.split(ODataUrlPathDelimiter);
    if (pathElements.length < 2) {
      return { metaProperty: baseProperty, genericObject: genericObject };
    }

    let parentObject = genericObject;
    for (const [i, path] of pathElements.entries()) {
      parentObject = parentObject[path];
      if (i >= pathElements.length - 2 || !parentObject) {
        break;
      }
    }

    if (parentObject && parentObject.metaClass) {
      const propertyClass = this.metadataTree.getClass(parentObject.metaClass);
      if (propertyClass) {
        const propertyName = pathElements[pathElements.length - 1];
        const property = [...propertyClass.properties, ...propertyClass.relations].find((p) => p.name.toLowerCase() === propertyName.toLowerCase());
        return property ? { metaProperty: property, genericObject: parentObject } : undefined;
      }
    }

    return undefined;
  }

  setPlainValue<T>(value: T | undefined, metaPropertyPath: string, genericObject: IGenericObject): boolean {
    return MetaPropertyValueService.setPlainFieldValue(value, metaPropertyPath, genericObject);
  }

  getFieldValue<T>(metaProperty: IMetaProperty, genericObject: IGenericObject): T | undefined {
    if (isMetaRelation(metaProperty)) {
      return genericObject[metaProperty.sourceProperties[0]] === -1 ? undefined : this.getSourceValue(metaProperty, genericObject);
    }

    const plainValue = MetaPropertyValueService.getPlainFieldValue(metaProperty, genericObject);

    if (metaProperty.valueGenerator) {
      const evaluatedResult = this.expressionEvaluator.evaluate(metaProperty.valueGenerator, genericObject);
      return evaluatedResult != null ? evaluatedResult : plainValue;
    }

    return plainValue;
  }

  async validate(metaProperty: IMetaProperty, value: any, validationContext: IValidationContext): Promise<IFieldError[]> {
    const result: IFieldValidationResult[] = [];
    this.fieldValidatorProvider.getInstances().forEach((validator) => result.push(validator.validate(metaProperty, value, validationContext)));
    result.push(this.controlValidatorRegistry.execute(metaProperty));
    return result.flatMap((result) => (result.fieldErrors ? result.fieldErrors : []));
  }

  private getSourceValue(metaProperty: IMetaRelation, genericObject: IGenericObject) {
    const paths = metaProperty.path.split(ODataUrlPathDelimiter);
    let value: any = genericObject;
    paths.forEach((path, index) => {
      if (value !== undefined && value !== null) {
        value = index === paths.length - 1 ? value[metaProperty.sourceProperties[0]] : value[path];
      } else {
        value = undefined;
      }
    });
    return value;
  }

  private static getPlainFieldValue(metaProperty: IMetaProperty, genericObject: IGenericObject) {
    const paths = metaProperty.path.split(ODataUrlPathDelimiter);
    let value: any = genericObject;
    for (const path of paths) {
      if (value !== undefined && value !== null) {
        value = value[path];
      } else {
        value = undefined;
      }
    }

    return value;
  }

  private static setPlainFieldValue<T>(value: T | undefined, metaPropertyPath: string, genericObject: IGenericObject): boolean {
    const paths = metaPropertyPath.split(ODataUrlPathDelimiter);
    let subObject: any = genericObject;
    for (let index = 0; index < paths.length; index++) {
      const path = paths[index];
      if (index < paths.length - 1) {
        if (subObject[path] == undefined) {
          subObject[path] = {};
        }
        subObject = subObject[path];
      } else {
        if (subObject[path] === value) {
          return false;
        } else {
          subObject[path] = value;
          return true;
        }
      }
    }
    return false;
  }
}
