import React, { PropsWithChildren, useContext } from 'react';
import { QuinoContextContext } from '@quino/core';
import { useService } from '../ioc';
import { IQuinoContextInitializerService, IQuinoContextInitializerServiceSymbol } from './IQuinoContextInitializerService';
import { useOnMount } from '../components/Util/useOnMount';
import { LoadPanel } from 'devextreme-react/load-panel';

export function QuinoContextInitializer(props: PropsWithChildren<any>) {
  const quinoContextContext = useContext(QuinoContextContext);
  const quinoContextInitializerService = useService<IQuinoContextInitializerService>(IQuinoContextInitializerServiceSymbol);

  useOnMount(() => quinoContextInitializerService.initialize(quinoContextContext));

  if (quinoContextContext.isLoading) {
    return <LoadPanel message={'Loading Context...'} visible={true} showIndicator={true} />;
  }

  return props.children;
}
