import { IMetaAspect } from '@quino/core';
import { IQuinoDataGridConfiguration } from '../components';

/**
 * The identifier for retrieving a grid configuration aspect.
 */
export const DataGridOptionsAspectIdentifier = 'DataGridOptionsAspect';

/**
 * A configuration aspect for the data grid.
 */
export interface IDataGridOptionsAspect extends IMetaAspect {
  /**
   * The options available.
   */
  options: IQuinoDataGridConfiguration;
}
