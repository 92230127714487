/**
 * Symbol to retrieve ScopingService from the IoC.
 */
export const ScopingServiceSymbol = Symbol.for('ScopingService');

/**
 * Scope service
 */
export interface IScopingService {
  /**
   * Sets the scope.
   * @param scope - the scope to apply.
   */
  setScope(scope: string | null): void;

  /**
   * Gets the scope.
   */
  getScope(): string | null;
}
