import { IServerHealthCheckerService } from './IServerHealthCheckerService';
import { injectable } from 'inversify';

@injectable()
export class ContainerLessHealthCheckerService implements IServerHealthCheckerService {
  async check(): Promise<void> {
    if (this.checkCount > 1) {
      document.location.reload();
    } else {
      this.checkCount++;
      throw Error();
    }
  }

  getHelpline(): string | undefined {
    return undefined;
  }

  getErrorIntervalMS(): number {
    return 60 * 1000;
  }

  getRoutineIntervalMS(): number {
    return 0; // Not used for this case
  }

  private checkCount = 0;
}
