import { inject, injectable } from 'inversify';
import { IQuinoActionFactory, IQuinoActionFactorySymbol } from './IQuinoActionFactory';
import { IClassActionsService } from './IClassActionsService';
import { getAspectOrDefault, IExpressionVisitor, IExpressionVisitorSymbol, IMetaAction, IMetaElement, isIMetaAction } from '@quino/core';
import { ICombinedBookmarkActions, IQuinoBookmarkAction } from './IQuinoAction';
import { ClassActionAspectIdentifier, IClassActionAspect } from '../aspects';
import { IBookmark, isILayoutAwareBookmark, isIMetaClassAwareBookmark } from '../bookmarks';
import { getDependentProperties } from '../components';

@injectable()
export class ClassActionsService implements IClassActionsService {
  constructor(
    @inject(IQuinoActionFactorySymbol) private readonly actionFactory: IQuinoActionFactory,
    @inject(IExpressionVisitorSymbol) private readonly expressionVisitor: IExpressionVisitor
  ) {}

  getClassActionDependencies(bookmark: IBookmark | undefined): string[] {
    if (!bookmark) {
      return [];
    }
    const metaActions = this.getMetaActions(bookmark);
    return metaActions.map((action) => getDependentProperties(this.expressionVisitor, action)).flat();
  }

  getClassActions(bookmark: IBookmark): ICombinedBookmarkActions {
    const mainActions: IQuinoBookmarkAction[] = [];
    const iconActions: IQuinoBookmarkAction[] = [];
    const metaActions: IMetaAction[] = this.getMetaActions(bookmark);

    metaActions.forEach((a) => {
      const classActionAspect = getAspectOrDefault<IClassActionAspect>(a, ClassActionAspectIdentifier);
      if (classActionAspect) {
        const action = this.actionFactory.createAction(a as unknown as IMetaAction) as IQuinoBookmarkAction;
        action.location = classActionAspect.showAsMainAction
          ? classActionAspect.showAtStartOfGroup
            ? 'main-before'
            : 'main-after'
          : classActionAspect.showAtStartOfGroup
          ? 'icon-priority'
          : 'icon-normal';
        action.buttonType = classActionAspect.isPrimary ? 'default' : undefined;
        action.buttonStylingMode = classActionAspect.isPrimary ? 'contained' : undefined;
        classActionAspect.showAsMainAction ? mainActions.push(action) : iconActions.push(action);
      }
    });

    return {
      mainActions: mainActions,
      iconActions: iconActions
    };
  }

  private getMetaActions(bookmark: IBookmark) {
    const actionNames: Set<string> = new Set<string>();
    const metaActions: IMetaAction[] = [];

    const filterDistinctActions = (elements: IMetaElement[]) => {
      elements.forEach((element) => {
        if (isIMetaAction(element) && !actionNames.has(element.name)) {
          actionNames.add(element.name);
          metaActions.push(element);
        }
      });
    };

    isILayoutAwareBookmark(bookmark) && filterDistinctActions(bookmark.layout.elements);
    isIMetaClassAwareBookmark(bookmark) && filterDistinctActions(bookmark.metaClass.properties);

    return metaActions;
  }
}
