import { isIfExpression } from './IIfExpressionEvaluator';
import { inject, injectable } from 'inversify';
import { IExpressionEvaluator } from './IExpressionEvaluator';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IExpression, isExpression } from './IExpression';

export const IfExpressionEvaluatorSymbol = Symbol.for('IfExpressionEvaluator');

@injectable()
export class IfExpressionEvaluator implements IExpressionEvaluator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator) {}

  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (isExpression(expression) && context != null && isIfExpression(expression)) {
      // @ts-ignore
      return this.evaluator.evaluate(expression.Element0, context)
        ? this.evaluator.evaluate(expression.Element1, context)
        : this.evaluator.evaluate(expression.Element2, context);
    }

    // @ts-ignore
    return expression;
  }
}
