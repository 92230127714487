import * as React from 'react';
import { IQuinoEditorProps } from '../Types';
import { ColorBox } from 'devextreme-react/color-box';
import { useMetaPropertyValueState, useValidation } from '../Util';
import { useLabelSettings, useMetadata } from '../../settings';

export function QuinoColorPicker(props: IQuinoEditorProps) {
  const { metaProperty, bookmark, propagateValue, hideClearButton } = props;
  const { description } = metaProperty;
  const labelSettings = useLabelSettings(metaProperty);
  const { readOnly, enabled, visible } = useMetadata(metaProperty, bookmark.genericObject);
  const [value, setValue] = useMetaPropertyValueState<string>(metaProperty, bookmark);
  const [isValid] = useValidation(bookmark, metaProperty);

  return (
    <ColorBox
      readOnly={readOnly != null ? readOnly : false}
      focusStateEnabled={!readOnly}
      hoverStateEnabled={!readOnly}
      disabled={!enabled}
      hint={labelSettings.hintType === 'None' ? description : undefined}
      value={value}
      visible={visible}
      onValueChanged={(e) => {
        setValue(e.value);
        propagateValue && propagateValue(e.value);
      }}
      isValid={isValid}
      showClearButton={!hideClearButton}
      openOnFieldClick={true}
      showDropDownButton={false}
    />
  );
}
