import { IErrorMapper } from './IErrorMapper';
import { DefaultErrorMapper } from './DefaultErrorMapper';

type ODataError = {
  error: {
    message: string;
  };
};

export class ODataServerErrorMapper extends DefaultErrorMapper implements IErrorMapper {
  get errorName() {
    return 'ServerError.OData';
  }

  canHandleError(error: Error): boolean {
    try {
      const parsedError = JSON.parse(error.message);

      return this.isODataError(parsedError);
    } catch {
      return false;
    }
  }

  getErrorMessage(error: Error): string {
    const parsedError = JSON.parse(error.message) as ODataError;

    return JSON.stringify(parsedError.error.message);
  }

  getErrorName(error: Error): string {
    return 'Server Error';
  }

  isODataError = (object: any): object is ODataError => {
    return object.error != undefined && object.error.message != undefined;
  };
}
