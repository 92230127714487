import { ICalendarAspect } from '../aspect';
import { ICalendarConfiguration } from './ICalendarConfiguration';

export const ICalendarConfigurationServiceSymbol = Symbol.for('ICalendarConfigurationService');

export interface ICalendarConfigurationService {
  /**
   * Gets the calendar configuration.
   */
  getCalendarConfiguration(aspect: ICalendarAspect): Promise<ICalendarConfiguration>;
}
