import React, { PropsWithChildren } from 'react';
import { QuinoPasswordBox } from './QuinoPasswordBox';
import { QuinoLabeled } from '../QuinoLabeled';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import {
  getMetaProperty,
  IMetaProperty,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  ITranslationService,
  ITranslationServiceSymbol
} from '@quino/core';
import { useLabelSettings, useMetadata } from '../../settings';
import { useMetaPropertyValueState, useOnBookmarkChangedEvent, useRerenderOnChanges, useValidation } from '../Util';
import { useService } from '../../ioc';

export const PasswordRepetitionFieldExtensionString = '_repetition';

export function QuinoPasswordRepetition(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const fieldValueService = useService<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol);

  const { element, bookmark } = props;
  const metaProperty = getMetaProperty(element);
  const repeatMetaProperty: IMetaProperty = {
    ...metaProperty,
    name: metaProperty.name + PasswordRepetitionFieldExtensionString,
    path: metaProperty.path + PasswordRepetitionFieldExtensionString,
    uri: metaProperty.uri + PasswordRepetitionFieldExtensionString
  };
  const { description, instruction } = metaProperty;
  const labelSettings = useLabelSettings(element);
  const { visible, readOnly, required, enabled } = useMetadata(metaProperty, bookmark.genericObject);

  const [password, setPassword] = useMetaPropertyValueState<string | undefined>(metaProperty, bookmark);
  const [repeatPassword, setRepeatPassword] = useMetaPropertyValueState<string | undefined>(repeatMetaProperty, bookmark);
  const [isValidPassword, passwordErrorMessages] = useValidation(bookmark, metaProperty);
  const [isValidRepeatPassword, repeatPasswordErrorMessages] = useValidation(bookmark, repeatMetaProperty);

  useRerenderOnChanges(bookmark, metaProperty);
  useRerenderOnChanges(bookmark, repeatMetaProperty);

  useOnBookmarkChangedEvent(
    bookmark,
    () => {
      bookmark.notifyChangeHandlers(bookmark.createObjectBookmarkEvent('changed', [fieldValueService.getElementPath(repeatMetaProperty)]));
    },
    [fieldValueService.getElementPath(metaProperty)]
  );

  const uniqueRepeatPasswordErrorMessages = [...new Set(repeatPasswordErrorMessages)];

  const passwordComponent = (
    value: string | undefined,
    setValue: (value: string | undefined) => void,
    caption: string,
    isValid: boolean,
    errorMessages: string[],
    metaProperty?: IMetaProperty,
    showRequirements?: boolean
  ): React.ReactNode => (
    <QuinoLabeled
      visible={visible}
      className={'quino-input-fields-max-width'}
      label={caption}
      required={required}
      description={description}
      instruction={instruction}
      errorMessages={(!isValid && errorMessages) || undefined}
      labelSettings={labelSettings}>
      <QuinoPasswordBox
        showRequirements={showRequirements}
        metaProperty={metaProperty}
        readOnly={readOnly != null && readOnly}
        focusStateEnabled={!readOnly}
        hoverStateEnabled={!readOnly}
        showClearButton={true}
        isValid={isValid}
        hint={labelSettings.hintType === 'None' ? description : undefined}
        disabled={!enabled}
        value={value}
        valueChangeEvent={'keyup'}
        onValueChanged={(e) => setValue(e.value)}
        inputAttr={{ autocomplete: 'new-password' }}
      />
    </QuinoLabeled>
  );

  return (
    <>
      {passwordComponent(
        password,
        setPassword,
        translationService.translate('QuinoAuthenticationFeedback.Password'),
        isValidPassword,
        passwordErrorMessages,
        metaProperty,
        true
      )}
      {passwordComponent(
        repeatPassword,
        setRepeatPassword,
        translationService.translate('QuinoAuthenticationFeedback.RepeatPassword'),
        isValidRepeatPassword,
        uniqueRepeatPasswordErrorMessages
      )}
    </>
  );
}
