import { IBookmark } from '../bookmarks/IBookmark';
import { IQuinoBookmarkAction } from './index';
import { IListBookmark, IObjectBookmark } from '../bookmarks';

/**
 * Symbol to retrieve the BookmarkScopingService from the IoC.
 */
export const StandardBookmarkActionsServiceSymbol = Symbol.for('StandardBookmarkActionsService');

export interface IFieldValue {
  propertyName: string;
  propertyValue: any;
}

/**
 * Service to get the standard bookmark actions
 */
export interface IStandardBookmarkActionsService {
  /**
   * Gets the standard bookmark actions for a given bookmark
   * @param bookmark
   * @param shortcutInfo
   */
  getBookmarkActions(bookmark: IBookmark): IQuinoBookmarkAction[];

  getObjectDeleteMethod(): (bookmark: IBookmark) => void;
  getObjectDirectDeleteMethod(): (bookmark: IBookmark) => void;
  getObjectSaveMethod(): (bookmark: IBookmark) => void;
  getObjectDiscardMethod(): (bookmark: IBookmark) => void;
  getObjectCreateMethod(): (bookmark: IBookmark) => void;

  objectSaveActive(bookmark: IBookmark): boolean;
  objectDeleteActive(bookmark: IBookmark): boolean;
  objectDiscardActive(bookmark: IBookmark): boolean;
  createActive(bookmark: IBookmark): boolean;

  getObjectBookmarkCreateAction(bookmark: IObjectBookmark, onCreate: (newBookmark: IBookmark | undefined) => void): IQuinoBookmarkAction;
  getListBookmarkCreateAction(
    bookmark: IListBookmark,
    onCreate: (newBookmark: IBookmark | undefined) => void,
    parentBookmark?: IBookmark
  ): IQuinoBookmarkAction;

  getSortPropertyName(bookmark: IBookmark): string | undefined;
  createNewBookmark(bookmark: IBookmark, customFieldValues?: IFieldValue[], parentBookmark?: IBookmark): Promise<IObjectBookmark | undefined>;
}
