import React, { useState } from 'react';
import { DropDownButton } from 'devextreme-react/drop-down-button';
import { IDashboardTileRegistration, IDashboardTileRegistry, IDashboardTileRegistrySymbol } from './registry';
import { useService } from '../ioc';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { IDashboardBookmark } from '../bookmarks';
// NOTE: Do not shorten following imports to avoid circular dependencies
import { QuinoPopup } from '../components/QuinoPopup';

export const QuinoDashboardAddTileButton = (props: { bookmark: IDashboardBookmark }) => {
  const registry = useService<IDashboardTileRegistry>(IDashboardTileRegistrySymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const [configDialog, setConfigDialog] = useState<boolean>(false);
  const [configTileType, setConfigTileType] = useState<IDashboardTileRegistration<any> | undefined>();

  const translatedTileTypes = registry.getInstances().map((x) => {
    return {
      value: x.name,
      display: translationService.translate('Dashboard.TileType.' + x.name)
    };
  });

  return (
    <>
      <DropDownButton
        text={translationService.translate('Dashboard.AddButton.ButtonCaption')}
        showArrowIcon={false}
        icon={'material-icons-outlined add'}
        dropDownOptions={{ width: 'auto' }}
        items={translatedTileTypes}
        displayExpr={'display'}
        onItemClick={({ itemData }) => {
          const tileType = registry.getInstances().find((x) => x.name === itemData.value);
          if (tileType) {
            setConfigTileType(tileType);
            setConfigDialog(true);
          }
        }}
      />

      <QuinoPopup visible={configDialog} width={800} height={600} title={translationService.translate('Dashboard.AddButton.NewTileDialog.Title')}>
        <>
          {configTileType &&
            configTileType.renderSettings(
              {},
              (data) => {
                setConfigDialog(false);
                props.bookmark.addTile(configTileType, data);
                setConfigTileType(undefined);
              },
              () => {
                setConfigDialog(false);
                setConfigTileType(undefined);
              }
            )}
        </>
      </QuinoPopup>
    </>
  );
};
