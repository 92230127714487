import { IQuinoShortcut } from './IQuinoShortcutSettings';

export const IShortcutInformationServiceSymbol = Symbol.for('IShortcutInformationService');

export interface IShortcutInformation {
  browserName: string;
  osName: string;
  accessKeys: string[];
  controlKey: string;
  shiftKey: string;
  hint: (shortcut: IQuinoShortcut) => string;
}

export interface IShortcutInformationService {
  get(): IShortcutInformation;
}
