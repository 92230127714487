export class EntityError extends Error {
  constructor(message: string, public readonly entityKey: string, public readonly entity?: any) {
    super(message);
  }

  get name() {
    return 'Error.Entity';
  }
}

export class DeleteError extends EntityError {
  get name() {
    return super.name + '.Delete';
  }
}
