import { isIBookmark } from './IBookmark';
import { ICustomBookmark } from './ICustomBookmark';
import { ILayoutAwareBookmark } from './ILayoutAwareBookmark';
import { ITrackableBookmark } from './ITrackableBookmark';
import { IDashboardLayout, IDashboardTileRegistration } from '../dashboard';
import { IRefreshableBookmark } from './IRefreshableBookmark';
import { IStateFullBookmark } from './IStateFullBookmark';

export type DashboardBookmarkEventType = 'changes' | 'edit' | 'save' | 'reset' | 'addTile' | 'refresh';

export interface IDashboardBookmarkEvent {
  type: DashboardBookmarkEventType;
  payload: any;
}

export const DashboardBookmarkSymbol = Symbol.for('Dashboard');

/**
 * Bookmark used to render dashboards
 */
export interface IDashboardBookmark extends ICustomBookmark, ILayoutAwareBookmark, ITrackableBookmark, IRefreshableBookmark, IStateFullBookmark {
  /**
   * Get the current dashboard layout
   */
  getCurrentDashboard(): IDashboardLayout;

  /**
   * Add a new tile
   * @param type - tile type
   * @param payload - tile payload
   */
  addTile(type: IDashboardTileRegistration<any>, payload: any): void;

  /**
   * Set edit mode
   * @param enabled
   */
  setEditMode(enabled: boolean): void;

  /**
   * Subscribe to bookmark events
   * @param callback
   */
  subscribe(callback: (event: IDashboardBookmarkEvent) => void): Symbol;

  /**
   * Unsubscribe from bookmark events
   * @param symbol
   */
  unsubscribe(symbol: Symbol): void;
}

/**
 * Whether the given object is an IDashboardBookmark
 */
export const isIDashboardBookmark = (object: any): object is IDashboardBookmark => {
  return isIBookmark(object) && (object as IDashboardBookmark).getCurrentDashboard !== undefined;
};
