import {
  IBatchedRequestService,
  IBatchedRequestServiceSymbol,
  IGenericObject,
  IMetaProperty,
  IODataBatchRequest,
  IUrlManager,
  QuinoCoreServiceSymbols,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  IODataBatchResponse
} from '@quino/core';
import { useService, IFileInfo } from '@quino/ui';
import * as React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';

import { Button, LoadIndicator } from 'devextreme-react';

export interface HasFileUploadButtonProps {
  genericObject: IGenericObject;
  metaProperty: IMetaProperty;
}

export function HasFileUploadButton(props: PropsWithChildren<HasFileUploadButtonProps>) {
  const { genericObject, metaProperty } = props;
  const batchedRequestService = useService<IBatchedRequestService>(IBatchedRequestServiceSymbol);
  const urlManager = useService<IUrlManager>(QuinoCoreServiceSymbols.IUrlManager);
  const fieldValueService = useService<IMetaPropertyValueService>(IMetaPropertyValueServiceSymbol);

  const [showLoadingWheel, setShowLoadingWheel] = useState<boolean>(true);
  const [hasDocuments, setHasDocuments] = useState<boolean>(false);

  useEffect(() => {
    const fileUploadPrimaryKey = fieldValueService.getFieldValue<string>(metaProperty, genericObject);
    if (!fileUploadPrimaryKey) {
      console.log(`No Primary Key found in: `, genericObject);
      return;
    }

    const url = urlManager.getFileInfoUrl(null, genericObject.metaClass, fileUploadPrimaryKey, 'IdentityCard');
    const request: IODataBatchRequest = {
      url: url,
      method: 'get',
      id: `${fileUploadPrimaryKey}`
    };

    batchedRequestService
      .enqueue(request)
      .then((result: IODataBatchResponse) => {
        const fileInfos = result.body as IFileInfo[];
        setHasDocuments(fileInfos.length > 0);
      })
      .catch(() => {
        setHasDocuments(false);
      })
      .finally(() => setShowLoadingWheel(false));
  }, [batchedRequestService, fieldValueService, genericObject, genericObject.metaClass, genericObject.primaryKey, metaProperty, urlManager]);

  return (
    <>
      <LoadIndicator visible={showLoadingWheel} className={'has-file-upload-button'} id='small-indicator' height={20} width={20} />
      <Button
        visible={!showLoadingWheel && hasDocuments}
        className={'has-file-upload-button'}
        icon={'material-icons-outlined get_app'}
        stylingMode={'text'}
      />
    </>
  );
}
