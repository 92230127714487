import isBoolean from 'lodash/isBoolean';
import isInteger from 'lodash/isInteger';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { IFieldValidationResult } from '../IFieldValidationResult';
import { DataType, IMetaProperty } from '../../meta';
import { FieldValidationErrorCodes } from '../FieldValidationErrorCodes';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IValidationMessageProvider } from '../IValidationMessageProvider';
import { IFieldValidator } from '../IFieldValidator';
import { IValidationContext } from '../IValidationContext';

@injectable()
export class DataTypeValidator implements IFieldValidator {
  constructor(@inject(QuinoCoreServiceSymbols.IValidationMessageProvider) private readonly msgProvider: IValidationMessageProvider) {}

  validate(field: IMetaProperty, value: any, _context: IValidationContext): IFieldValidationResult {
    switch (field.dataType) {
      case DataType.Text:
        return DataTypeValidator.validateString(
          field,
          value,
          FieldValidationErrorCodes.DATATYPE_MISMATCH_STRING,
          this.msgProvider.getDataTypeInvalidStringError()
        );
      case DataType.Date:
        return DataTypeValidator.validateDate(
          field,
          value,
          FieldValidationErrorCodes.DATATYPE_MISMATCH_DATE,
          this.msgProvider.getDataTypeInvalidDateError()
        );
      case DataType.DateTime:
        return DataTypeValidator.validateDate(
          field,
          value,
          FieldValidationErrorCodes.DATATYPE_MISMATCH_DATETIME,
          this.msgProvider.getDataTypeInvalidDateTimeError()
        );
      case DataType.Time:
        return DataTypeValidator.validateTime(
          field,
          value,
          FieldValidationErrorCodes.DATATYPE_MISMATCH_TIME,
          this.msgProvider.getDataTypeInvalidTimeError()
        );
      case DataType.Boolean:
        return DataTypeValidator.validateBoolean(
          field,
          value,
          FieldValidationErrorCodes.DATATYPE_MISMATCH_BOOLEAN,
          this.msgProvider.getDataTypeInvalidBooleanError()
        );
      case DataType.Integer:
        return DataTypeValidator.validateInteger(
          field,
          value,
          FieldValidationErrorCodes.DATATYPE_MISMATCH_INTEGER,
          this.msgProvider.getDataTypeInvalidIntegerError()
        );
      case DataType.LargeInteger:
        return DataTypeValidator.validateInteger(
          field,
          value,
          FieldValidationErrorCodes.DATATYPE_MISMATCH_LARGEINTEGER,
          this.msgProvider.getDataTypeInvalidLargeIntegerError()
        );
      case DataType.SmallInteger:
        return DataTypeValidator.validateInteger(
          field,
          value,
          FieldValidationErrorCodes.DATATYPE_MISMATCH_SMALLINTEGER,
          this.msgProvider.getDataTypeInvalidSmallIntegerError()
        );
      case DataType.TinyInteger:
        return DataTypeValidator.validateInteger(
          field,
          value,
          FieldValidationErrorCodes.DATATYPE_MISMATCH_TINYINTEGER,
          this.msgProvider.getDataTypeInvalidTinyIntegerError()
        );
      case DataType.Currency:
        return DataTypeValidator.validateNumber(
          field,
          value,
          FieldValidationErrorCodes.DATATYPE_MISMATCH_CURRENCY,
          this.msgProvider.getDataTypeInvalidCurrencyError()
        );
      case DataType.Double:
        return DataTypeValidator.validateNumber(
          field,
          value,
          FieldValidationErrorCodes.DATATYPE_MISMATCH_DOUBLE,
          this.msgProvider.getDataTypeInvalidDoubleError()
        );
      case DataType.Relation:
        break;
    }

    return {};
  }

  private static validateDate(field: IMetaProperty, value: any, errorCode: string, errorMessage = ''): IFieldValidationResult {
    const dateValidator = (v: any) => {
      if (v instanceof Date) {
        return !isNaN(v.getTime());
      }

      return (
        new Date(v).toISOString() === v ||
        new Date(v).toISOString().replace('.000Z', 'Z') === v ||
        new Date(v).toISOString().replace('T00:00:00.000Z', '') === v ||
        new Date(v).toISOString().replace('T00:00:00Z', '') === v ||
        (v != null && typeof v === 'string' && new Date(v).toISOString().replace(/\..*/, '') === v.replace(/\..*/, ''))
      );
    };

    return DataTypeValidator.validateType(field, value, dateValidator, errorCode, errorMessage);
  }

  private static validateTime(field: IMetaProperty, value: any, errorCode: string, errorMessage = ''): IFieldValidationResult {
    const timeValidator = (v: any) => {
      if (v instanceof Date) {
        return !isNaN(v.getTime());
      }
      const dateValidationResult = DataTypeValidator.validateDate(field, value, errorCode, errorMessage);
      if (dateValidationResult && dateValidationResult.fieldErrors) {
        if (dateValidationResult.fieldErrors.length > 0) {
          if (typeof value === 'string' && /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/.exec(value)) {
            const date = new Date(value);
            const time = value.split('T')[1].split(':');
            const hours = parseInt(time[0], 10);
            const minutes = parseInt(time[1], 10);
            return date.getHours() === hours && date.getMinutes() === minutes;
          }
          return false;
        }
      }
      return dateValidationResult !== null;
    };

    return DataTypeValidator.validateType(field, value, timeValidator, errorCode, errorMessage);
  }

  private static validateString(field: IMetaProperty, value: any, errorCode: string, errorMessage = ''): IFieldValidationResult {
    return DataTypeValidator.validateType(field, value, (v) => isString(v), errorCode, errorMessage);
  }

  private static validateBoolean(field: IMetaProperty, value: any, errorCode: string, errorMessage = ''): IFieldValidationResult {
    return DataTypeValidator.validateType(field, value, (v) => isBoolean(v), errorCode, errorMessage);
  }

  private static validateInteger(field: IMetaProperty, value: any, errorCode: string, errorMessage = ''): IFieldValidationResult {
    return DataTypeValidator.validateType(field, value, (v) => isInteger(v), errorCode, errorMessage);
  }

  private static validateNumber(field: IMetaProperty, value: any, errorCode: string, errorMessage = ''): IFieldValidationResult {
    return DataTypeValidator.validateType(field, value, (v) => isNumber(v), errorCode, errorMessage);
  }

  private static validateType(
    field: IMetaProperty,
    value: any,
    validator: (value: any) => boolean,
    errorCode: string,
    errorMessage = ''
  ): IFieldValidationResult {
    if (value !== null && value !== undefined && !validator(value)) {
      return {
        fieldErrors: [
          {
            fieldName: field.name,
            errorMessage: errorMessage,
            errorCode: errorCode
          }
        ]
      };
    }

    return {};
  }
}
