import {
  IGenericObject,
  IMetaPropertyValueService,
  IMetaPropertyValueServiceSymbol,
  ITitleCalculator,
  ITitleCalculatorSymbol
} from '../../../../../data';
import { IMetaElement } from '../../../../../meta';
import { IQuinoExcelCell, IQuinoExcelCellBuilder, IQuinoExcelFile, IQuinoExcelRow } from '../../model';
import { inject, injectable } from 'inversify';
import { IQuinoExcelCellBuilderSymbol, IQuinoExcelRowBuilder, IQuinoExcelRowBuilderSymbol } from '../../builder';
import { QuinoCoreServiceSymbols } from '../../../../../ioc';
import { IExpressionEvaluator, IVisibleCalculator } from '../../../../../expressions/';
import { IExportExcelConfigurationService } from '../../settings';
import { IExcelMapper } from '../IExcelMapper';
import { IFormatStringService } from '../../../../formatting';

/**
 * Base implementation of an @IExcelMapper
 */
@injectable()
export abstract class ExcelMapperBase implements IExcelMapper {
  constructor(
    @inject(IQuinoExcelRowBuilderSymbol) protected rowBuilder: IQuinoExcelRowBuilder,
    @inject(IQuinoExcelCellBuilderSymbol) protected cellBuilder: IQuinoExcelCellBuilder,
    @inject(QuinoCoreServiceSymbols.IVisibleCalculator) protected visibleCalculator: IVisibleCalculator,
    @inject(QuinoCoreServiceSymbols.IExportExcelConfigurationService)
    protected excelExportConfigurationService: IExportExcelConfigurationService,
    @inject(QuinoCoreServiceSymbols.IFormatStringService) protected formatStringService: IFormatStringService,
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) protected expressionEvaluator: IExpressionEvaluator,
    @inject(IMetaPropertyValueServiceSymbol) protected metaPropertyValueService: IMetaPropertyValueService,
    @inject(ITitleCalculatorSymbol) protected titleCalculator: ITitleCalculator
  ) {}

  async map(genericObject: IGenericObject, element: IMetaElement, excelFile: IQuinoExcelFile): Promise<void> {
    const defaultSettings = await this.excelExportConfigurationService.getExportSettings(null);
    if (this.conditionMatches(element, genericObject)) {
      const quinoRows = await this.getData(genericObject, element);
      for (const quinoRow of quinoRows) {
        let currentRowWidth = 0;
        for (let i = 0; i < quinoRow.getTopMargin(); i++) {
          const marginRow = excelFile.getActiveWorksheet().addRow([]);
          marginRow.height = defaultSettings.rowMarginTopHeight;
        }

        const row = excelFile.getActiveWorksheet().addRow(
          quinoRow.getCells().map((cell) => {
            return cell.value();
          })
        );

        for (let i = 0; i < row.cellCount; i++) {
          const excelCell = row.getCell(i + 1);
          const quinoCell: IQuinoExcelCell = quinoRow.getCells()[i];
          const isNumber = typeof quinoCell.value() === 'number';
          excelCell.alignment = {
            wrapText: quinoCell.wrapText(),
            vertical: 'top',
            horizontal: isNumber ? 'right' : 'left'
          };

          excelCell.font = {
            name: quinoCell.getFontName(),
            size: quinoCell.getFontSize(),
            bold: quinoCell.isBold()
          };
          excelCell.numFmt = (quinoCell.format() ? quinoCell.format() : '') as string;

          this.fixColumnWidth(excelFile, i + 1, quinoCell);
          currentRowWidth = Math.max(currentRowWidth, currentRowWidth + (excelFile.getActiveWorksheet().getColumn(i + 1).width || 10));
        }
        if (currentRowWidth > 87) {
          excelFile.getActiveWorksheet().pageSetup.orientation = 'landscape';
        }
      }
    }
  }

  canMap(metaElement: IMetaElement): boolean {
    return this.getMappableElements().includes(metaElement.controlName);
  }

  /**
   * Returns an array of mappable elements (controls/layout)
   */
  abstract getMappableElements(): string[];

  /**
   *
   * @param genericObject
   * @param element
   */
  abstract getData(genericObject: IGenericObject, element: IMetaElement): Promise<IQuinoExcelRow[]>;

  /**
   * Returns a boolean which indicates, based on the element and the data object, whether it can be mapped or not
   * @param element
   * @param genericObject
   */
  abstract conditionMatches(element: IMetaElement, genericObject: IGenericObject): boolean;

  canMapChildren(_element: IMetaElement): boolean {
    return false;
  }

  /**
   * Fixes the column width based on its current width and the newly added value
   * @param excelFile
   * @param colIndex
   * @param quinoCell
   */
  protected fixColumnWidth(excelFile: IQuinoExcelFile, colIndex: number, quinoCell: IQuinoExcelCell) {
    const column = excelFile.getActiveWorksheet().getColumn(colIndex);
    if (column === undefined) {
      return;
    }
    const cellValue = quinoCell.value();
    const value: string = typeof cellValue === 'object' && cellValue !== null ? cellValue.toISOString() : `${cellValue}`;
    const isNumber = typeof cellValue === 'number';
    const lines = value.split(/\n/g).length;
    let textLength = value.length / lines;
    textLength = isNumber ? textLength + 3 : textLength + 2;
    const maxWidth = 50;

    if (!column.width) {
      column.width = textLength;
    }

    column.width = Math.max(column.width, textLength);
    column.width = Math.max(column.width, quinoCell.getMarginRight() > 0 ? quinoCell.getMarginRight() : 5);

    column.width = Math.min(column.width, maxWidth);
    if (column.width === maxWidth) {
      column.alignment = { wrapText: true, vertical: 'top' };
    }
  }

  protected isVisible(metaElement: IMetaElement, genericObject: IGenericObject): boolean {
    return this.visibleCalculator.calculate(metaElement, genericObject);
  }
}
