import React, { PropsWithChildren } from 'react';
import { getMetaProperty } from '@quino/core';
import { IQuinoComponentProps } from '../Types';
import { ObjectBookmark } from '../../bookmarks';
import { useValidation } from '../Util';
import { QuinoLabeled } from '../QuinoLabeled';
import { useLabelSettings, useMetadata } from '../../settings';
import { useRerenderOnChanges } from '../Util/MetaPropertyHooks';
import { QuinoMultiPurposeTextEditor } from './QuinoMultiPurposeTextEditor';

export function QuinoMultiPurposeTextEditorLabeled(props: PropsWithChildren<IQuinoComponentProps<ObjectBookmark>>) {
  const { element, bookmark } = props;
  const metaProperty = getMetaProperty(element);
  const { description, caption, instruction } = metaProperty;
  const { visible, required } = useMetadata(metaProperty, bookmark.genericObject);
  const labelSettings = useLabelSettings(metaProperty);

  const [isValid, errorMessages] = useValidation(bookmark, metaProperty);

  useRerenderOnChanges(bookmark, metaProperty);

  return (
    <QuinoLabeled
      visible={visible}
      className={'quino-input-fields-max-width'}
      label={caption}
      required={required}
      description={description}
      instruction={instruction}
      errorMessages={(!isValid && errorMessages) || undefined}
      labelSettings={{ ...labelSettings, alignment: labelSettings.alignment }}>
      <QuinoMultiPurposeTextEditor bookmark={bookmark} metaProperty={metaProperty} />
    </QuinoLabeled>
  );
}
