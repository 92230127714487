/**
 * Gets the symbol for the CUI settings.
 */

export const ICUISettingSymbol = Symbol.for('ICUISetting');

/**
 * Settings for configuring the CUI environment.
 */
export interface ICUISettings {
  /**
   * Whether to show the menu search box.
   */
  showMenuSearch: boolean;

  /**
   * Whether to show the global search box.
   */
  showGlobalSearch: boolean;

  /**
   * Whether to show the sidebar action search
   */
  showActionSearch: boolean;

  /**
   * Whether to show the layout switcher or not.
   */
  hasLayoutSwitcher: boolean;

  /**
   * Whether to show the menu search box.
   */
  showExplorer: boolean;

  /**
   * The application name
   */
  applicationName: string;

  /**
   * The login page logo path
   */
  loginLogo: string;

  /**
   * The header section logo path
   */
  headerLogo: string;

  /**
   * If the logos should be localized
   */
  enableLocalizedLogos: boolean;

  /**
   * The favicon path
   */
  faviconLogo: string;

  /**
   * The favicon path
   */
  faviconSelector: string;

  /**
   * The URL for the User Guide
   */
  userGuideUrl: string;

  /**
   * Whether to show the User Guide button
   */
  hasUserGuide: boolean;

  /**
   * The customization json file path selector
   */
  customizationJsonFilePath: string;

  /**
   * The customization css file path selector
   */
  customizationCssFilePath: string;

  /**
   * The entity scope selector visibility
   */
  entityScopeSelectorVisible: boolean;

  /**
   * The entity scope display expression
   */
  entityScopeDisplayExpression: string;

  /**
   * The entity scope value expression
   */
  entityScopeValueExpression: string;

  /**
   * The entity scope entity name
   */
  entityScopeEntityName: string;

  /**
   * If the context selector is visible
   */
  contextSelectorVisible: boolean;

  /**
   * The API path, where the contexts are retrieved from
   * relative to base path https://hostname.ch/api/v1
   */
  contextSelectorAPIUrl: string;

  /**
   * Whether to navigate home and reload the page on global filter change; else the bookmark is just refreshed
   */
  contextSelectorHardReload: boolean;

  /**
   * Stage name selector
   */
  stageName: string;

  /**
   * Stage color selector
   */
  stageColor: string;

  /**
   * Helpline
   */
  helpline: string;

  /**
   * Retry interval in milli seconds of health check in case the server is not reachable
   */
  healthCheckErrorIntervalMS: number;

  /**
   * Routine health check interval in milli seconds
   */
  healthCheckRoutineIntervalMS: number;

  /**
   * Enable the dashboard in the menu
   */
  dashboardEnabled: boolean;

  /**
   * Width for the Sidebar component
   */
  initialSidebarWidth: number;

  /**
   * Breakpoint in px for phone mode
   */
  phoneBreakpoint: number;

  /**
   * Breakpoint in px for tablet mode
   */
  tabletBreakpoint: number;

  /**
   * Whether to use a disclaimer popup
   */
  enableDisclaimerPopup: boolean;

  /**
   * The login background image path
   */
  loginBackgroundImage: string;

  /**
   * The url of 'Contact support' link
   */
  supportUrl: string;
}

export const defaultCUISettings: ICUISettings = {
  showMenuSearch: true,
  showGlobalSearch: false,
  showActionSearch: true,
  hasLayoutSwitcher: true,
  showExplorer: true,
  applicationName: 'Quino Client Sandbox',
  loginLogo: 'assets/logo.png',
  headerLogo: 'assets/logo.png',
  enableLocalizedLogos: false,
  userGuideUrl: '#',
  hasUserGuide: false,
  faviconLogo: 'assets/icon.png',
  faviconSelector: 'favicon',
  customizationJsonFilePath: 'assets/dx.generic.sandbox.json',
  customizationCssFilePath: 'assets/dx.generic.sandbox.css',
  entityScopeSelectorVisible: false,
  entityScopeDisplayExpression: '',
  entityScopeValueExpression: '',
  entityScopeEntityName: '',
  contextSelectorVisible: false,
  contextSelectorAPIUrl: 'api/v1/context',
  contextSelectorHardReload: false,
  stageName: '',
  stageColor: '#F2B535',
  helpline: '',
  healthCheckErrorIntervalMS: 60 * 1000,
  healthCheckRoutineIntervalMS: 5 * 60 * 1000,
  dashboardEnabled: true,
  initialSidebarWidth: 240,
  phoneBreakpoint: 768,
  tabletBreakpoint: 960,
  enableDisclaimerPopup: false,
  loginBackgroundImage: '',
  supportUrl: ''
};
