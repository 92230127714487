import { ITranslations } from './ITranslations';

/**
 * Translation service symbol.
 */
export const ITranslationServiceSymbol = Symbol.for('ITranslationService');

/**
 * Main translation service which is initialized on startup.
 */
export interface ITranslationService {
  /**
   * Translate the given key with the currently active language.
   * @param key - the key to use.
   * @param interpolation
   */
  translate(key: string, interpolation?: { [key: string]: string | number }): string;

  /**
   * Check if translation exists for the given key and the currently active language.
   * @param key - the key to use.
   */
  exists(key: string): boolean;

  /**
   * Load a new set of translation into the current service.
   * @param translations - the translations to load.
   */
  load(translations: ITranslations): void;
}
