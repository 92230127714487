import { INamespace, IQuinoContext } from '@quino/core';
import { injectable } from 'inversify';

export const ContextNamespaceSymbol = Symbol.for('ContextNamespace');

@injectable()
export class ContextNamespace implements INamespace {
  public context: IQuinoContext | undefined = undefined;

  Id = (): any => {
    if (!this.context) {
      return '';
    }

    try {
      const parsedContext = JSON.parse(this.context.value);
      if (parsedContext['id'] != null) {
        return parsedContext['id'];
      }
    } catch {
      return '';
    }

    return '';
  };

  Class = (): string => {
    if (!this.context) {
      return '';
    }

    try {
      const parsedContext = JSON.parse(this.context.value);
      if (parsedContext['class'] != null) {
        return parsedContext['class'];
      }
    } catch {
      return '';
    }

    return '';
  };

  Role = (): string => {
    if (!this.context) {
      return '';
    }

    return this.context.role ?? '';
  };

  name = 'CurrentContext';
}
