export class ErrorGroup extends Error {
  constructor(public errors: Error[]) {
    super();
  }

  get message() {
    return this.errors.map((e: Error, k: number) => `[${k}]: ${e.message ?? 'unknown error'}`).join('\n');
  }

  get name() {
    return 'Error.ErrorGroup';
  }
}
