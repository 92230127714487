import React, { PropsWithChildren, useState } from 'react';
import { Col, Item, Location, ResponsiveBox, Row } from 'devextreme-react/responsive-box';
import {
  IHomeNavigationService,
  IHomeNavigationServiceSymbol,
  useService,
  getResponsiveClassName,
  TResponsiveMode,
  useResponsiveMode
} from '@quino/ui';
import { useCuiSettings } from '../../util';
import { CUICombinedScopeSelector, ICUICombinedScopeSelectorExtension } from './CUICombinedScopeSelector';
import { CUIGlobalSearch } from './globalSearch/CUIGlobalSearch';
import { Button } from 'devextreme-react/button';
import { CUILocalizedImage } from '../content/CUILocalizedImage';

export interface ICUIHeaderProps {
  customScopeSelectorComponents?: ICUICombinedScopeSelectorExtension[];
  customHeaderElement?: React.ReactNode;
}

const enum MobileDisplayRowType {
  none,
  scopeSelector,
  globalSearch
}

type THeaderLayoutElement = 'logo' | 'filter' | 'search' | 'custom' | 'tools';

export function CUIHeader(props: PropsWithChildren<ICUIHeaderProps>) {
  const homeNavigationService = useService<IHomeNavigationService>(IHomeNavigationServiceSymbol);

  const cuiSettings = useCuiSettings();
  const responsiveMode = useResponsiveMode();

  const [mobileDisplayRowType, setMobileDisplayRowType] = useState<MobileDisplayRowType>(MobileDisplayRowType.none);

  const scopeSelector = () => <CUICombinedScopeSelector customComponents={props.customScopeSelectorComponents} />;
  const globalSearch = (focus?: boolean) => cuiSettings.showGlobalSearch && <CUIGlobalSearch focus={focus} />;

  const entityScopeSelectorVisible = cuiSettings.entityScopeSelectorVisible;
  const contextSelectorVisible = cuiSettings.contextSelectorVisible;
  const globalSearchVisible = cuiSettings.showGlobalSearch;
  const customHeaderElementVisible = !!props.customHeaderElement;

  const columnLayout: THeaderLayoutElement[] = [
    'logo',
    entityScopeSelectorVisible || contextSelectorVisible ? 'filter' : null,
    globalSearchVisible ? 'search' : null,
    customHeaderElementVisible ? 'custom' : null,
    'tools'
  ].filter((value) => value != null) as THeaderLayoutElement[];

  const mobileHeaderContent = () => {
    return [
      <Item visible={entityScopeSelectorVisible || contextSelectorVisible} key={'mobile-header-scope-button-wrapper'}>
        <Location col={columnLayout.indexOf('filter')} row={0} />
        <div className='quino-button-spacing header-padding'>
          <Button
            icon={'material-icons-outlined filter_list'}
            stylingMode={'text'}
            onClick={() => {
              if (mobileDisplayRowType === MobileDisplayRowType.scopeSelector) {
                setMobileDisplayRowType(MobileDisplayRowType.none);
              } else {
                setMobileDisplayRowType(MobileDisplayRowType.scopeSelector);
              }
            }}
          />
        </div>
      </Item>,
      <Item visible={globalSearchVisible} key={'mobile-header-search-button-wrapper'}>
        <Location col={columnLayout.indexOf('search')} row={0} />
        <div className={'quino-button-spacing header-padding'}>
          <Button
            icon={'material-icons-outlined search'}
            stylingMode={'text'}
            onClick={() => {
              if (mobileDisplayRowType === MobileDisplayRowType.globalSearch) {
                setMobileDisplayRowType(MobileDisplayRowType.none);
              } else {
                setMobileDisplayRowType(MobileDisplayRowType.globalSearch);
              }
            }}
          />
        </div>
      </Item>,
      <Item key={'mobile-header-second-row-wrapper'} visible={mobileDisplayRowType !== MobileDisplayRowType.none}>
        <Location col={0} colspan={4} row={1} />
        <div
          className={'quino-ecui-header-scope-wrapper header-padding'}
          style={{ display: mobileDisplayRowType === MobileDisplayRowType.scopeSelector ? undefined : 'none' }}>
          {scopeSelector()}
        </div>
        <div
          className={'quino-ecui-header-global-search-wrapper header-padding'}
          style={{ visibility: mobileDisplayRowType === MobileDisplayRowType.globalSearch ? undefined : 'hidden' }}>
          {globalSearch(mobileDisplayRowType === MobileDisplayRowType.globalSearch)}
        </div>
      </Item>,
      <Item visible={customHeaderElementVisible} key={'desktop-header-custom-element-container'}>
        <Location col={columnLayout.indexOf('custom')} row={0} />
        <div className={'quino-ecui-header-custom-element-wrapper header-padding'}>{props.customHeaderElement}</div>
      </Item>
    ];
  };

  const desktopHeaderContent = () => {
    return [
      <Item visible={entityScopeSelectorVisible || contextSelectorVisible} key={'desktop-header-scope-container'}>
        <Location col={columnLayout.indexOf('filter')} row={0} />
        <div className='quino-ecui-header-scope-wrapper header-padding'>{scopeSelector()}</div>
      </Item>,
      <Item visible={globalSearchVisible} key={'desktop-header-search-container'}>
        <Location col={columnLayout.indexOf('search')} row={0} />
        <div className='quino-ecui-header-global-search-wrapper header-padding'>{globalSearch()}</div>
      </Item>,
      <Item visible={customHeaderElementVisible} key={'desktop-header-custom-element-container'}>
        <Location col={columnLayout.indexOf('custom')} row={0} />
        <div className={'quino-ecui-header-custom-element-wrapper header-padding'}>{props.customHeaderElement}</div>
      </Item>
    ];
  };

  const responsiveBoxHeight = mobileDisplayRowType != MobileDisplayRowType.none ? 100 : 50;
  const rowBaseSize = 50;
  const colBaseSizeMini = 52;
  const colBaseSizeSmall = responsiveMode !== TResponsiveMode.Desktop ? colBaseSizeMini : 200;
  const colBaseSizeLarge = responsiveMode === TResponsiveMode.Phone ? colBaseSizeMini : TResponsiveMode.Tablet ? 240 : 320;

  return (
    <ResponsiveBox height={responsiveBoxHeight} className='quino-ecui-header'>
      <Row baseSize={rowBaseSize} ratio={0} />
      <Row baseSize={rowBaseSize} ratio={0} />
      <Row baseSize={rowBaseSize} ratio={0} />
      <Col baseSize={colBaseSizeSmall} ratio={0} shrink={0} />
      {(entityScopeSelectorVisible || contextSelectorVisible) && <Col baseSize={colBaseSizeLarge} ratio={0} shrink={0} />}
      {globalSearchVisible && <Col baseSize={colBaseSizeLarge} ratio={responsiveMode === TResponsiveMode.Phone ? 0 : 1} shrink={0} />}
      {customHeaderElementVisible && <Col baseSize={'auto'} ratio={1} shrink={0} />}
      <Col
        baseSize={'auto'}
        ratio={
          (responsiveMode === TResponsiveMode.Phone && customHeaderElementVisible) ||
          (responsiveMode !== TResponsiveMode.Phone && (globalSearchVisible || customHeaderElementVisible))
            ? 0
            : 1
        }
        shrink={0}
      />

      <Item>
        <Location col={columnLayout.indexOf('logo')} row={0} />
        <div className={getResponsiveClassName('quino-ecui-header-logo-wrapper', responsiveMode) + ' header-padding'}>
          <CUILocalizedImage
            alt={'Logo'}
            translationKey={'Header.Logo'}
            src={responsiveMode === TResponsiveMode.Desktop ? cuiSettings.headerLogo : cuiSettings.faviconLogo}
            onClick={homeNavigationService.navigateHome}
          />
        </div>
      </Item>

      {responsiveMode === TResponsiveMode.Phone ? mobileHeaderContent() : desktopHeaderContent()}

      <Item>
        <Location col={columnLayout.indexOf('tools')} row={0} />
        <div className='quino-ecui-header-meta-wrapper header-padding'>{props.children}</div>
      </Item>
    </ResponsiveBox>
  );
}
