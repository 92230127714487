import {
  ILayoutResolver,
  ILayoutResolverSymbol,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetadataTree,
  IMetaProperty,
  isMetaRelation,
  LayoutType,
  QuinoCoreServiceSymbols
} from '@quino/core';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useService } from '../../ioc/hook';
import { INavigationService, INavigationServiceSymbol } from '../../navigation';
import { IBookmarkFactory } from '../../bookmarks';
import { QuinoUIServiceSymbols } from '../../ioc';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../../data/IODataSourceFactory';
import { IHighlightTextService, IHighlightTextServiceSymbol } from '../Util/HighlightTextService';
import { isCtrlOrCmdEvent } from '../../shortcuts';

export interface IQuinoCrossLinkProps {
  metaProperty?: IMetaProperty;
  primaryKey: string | null;
  visible: boolean;
  icon?: string;
  showTitle: boolean;
  title: string;
  targetClass?: string;
  isBold?: boolean;
  onClick?: () => void;
  highlightedText?: string;
  isReadOnlyField?: boolean;
  invertedColor?: boolean;
  error?: string | undefined;
}

export const QuinoCrossLink: FC<IQuinoCrossLinkProps> = ({
  metaProperty,
  primaryKey,
  icon,
  visible,
  showTitle,
  title,
  isBold,
  targetClass,
  onClick,
  highlightedText,
  isReadOnlyField,
  invertedColor,
  error
}) => {
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const metaDataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const navigationService = useService<INavigationService>(INavigationServiceSymbol);
  const layoutResolver = useService<ILayoutResolver>(ILayoutResolverSymbol);
  const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
  const dataService = useService<IODataSourceFactory>(IODataSourceFactorySymbol);
  const loadingFeedback = useService<ILoadingFeedback>(ILoadingFeedbackSymbol);
  const highlightTextService = useService<IHighlightTextService>(IHighlightTextServiceSymbol);

  const metaClass = useMemo(() => {
    if (targetClass) {
      return targetClass;
    }

    if (metaProperty && isMetaRelation(metaProperty)) {
      return metaProperty.targetClass;
    }

    return null;
  }, [targetClass, metaProperty]);

  const [hasDefaultLayout, setHasDefaultLayout] = useState(false);
  useEffect(() => {
    try {
      if (metaClass && metaDataTree.getLayout(metaClass, LayoutType.Detail)) {
        setHasDefaultLayout(true);
      }
    } catch (e) {
      logger.logDebug(`QuinoCrossLink: No default detail layout found for MetaClass:[${metaClass}]`);
    }
  }, [metaClass, metaDataTree, logger]);

  const openInNewTab = useCallback(() => {
    if (metaClass) {
      const emptyGenericObjectBookmark = bookmarkFactory.createObject(
        { primaryKey: primaryKey || undefined, metaClass, title },
        layoutResolver.resolveSingle({ type: LayoutType.Detail, metaClass: metaClass, element: metaProperty })
      );
      window.open(navigationService.extractUrl(emptyGenericObjectBookmark), '_blank', 'noopener,noreferrer');
    }
  }, [bookmarkFactory, layoutResolver, metaClass, metaProperty, navigationService, primaryKey, title]);

  const anchorOnClick = useCallback(
    (event) => {
      event.preventDefault();

      if (isCtrlOrCmdEvent(event)) {
        openInNewTab();
      } else {
        if (onClick) {
          onClick();
        } else if (metaClass) {
          const layout = layoutResolver.resolveSingle({ type: LayoutType.Detail, metaClass: metaClass, element: metaProperty });
          const unload = loadingFeedback.load();

          dataService
            .fetch(primaryKey || 'null', layout, metaClass)
            .then(async (genericObject) => {
              const bookmark = bookmarkFactory.createObject(genericObject, layout);
              return navigationService.push(bookmark);
            })
            .catch(logger.logError)
            .finally(unload);
        }
      }
    },
    [
      openInNewTab,
      onClick,
      metaClass,
      layoutResolver,
      metaProperty,
      loadingFeedback,
      dataService,
      primaryKey,
      logger.logError,
      bookmarkFactory,
      navigationService
    ]
  );

  const onMouseUp = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (e.button === 1) {
        openInNewTab();
      }
    },
    [openInNewTab]
  );

  const titleElement = useMemo(() => {
    if (showTitle) {
      return highlightTextService.highlight(title, highlightedText);
    }

    return null;
  }, [showTitle, highlightTextService, title, highlightedText]);

  if (!visible) {
    return <></>;
  }

  return error ? (
    <div className={'quino-cross-link has--error'}>{error}</div>
  ) : (
    <div className={`quino-cross-link${isReadOnlyField ? ' quino-cross-link-dotted-border' : ''}`}>
      {titleElement &&
        (metaClass && hasDefaultLayout ? (
          <a
            className={`quino-cross-link-text${isBold ? ' quino-cross-link-bold' : ''}${invertedColor ? ' is--inverted-color' : ''}`}
            onClick={anchorOnClick}
            onMouseUp={onMouseUp}
            title={title || ''}>
            {titleElement}
          </a>
        ) : (
          <div className={`quino-cross-link-text${isBold ? ' quino-cross-link-bold' : ''}${invertedColor ? ' is--inverted-color' : ''}`}>
            {titleElement}
          </div>
        ))}
      {icon && (
        <a className={'quino-cross-link-icon'} onClick={anchorOnClick} onMouseUp={onMouseUp} title={title || ''}>
          <i className={icon} />
        </a>
      )}
    </div>
  );
};

QuinoCrossLink.displayName = 'QuinoCrossLink';
