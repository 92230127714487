import { IQuinoAction } from './index';
import { IMetaAction, IMetaLayout } from '@quino/core';
import { IBookmark, IRefreshableBookmark } from '../bookmarks';

/**
 * Symbol to retrieve the BookmarkScopingService from the IoC.
 */
export const LayoutActionsServiceSymbol = Symbol.for('LayoutActionsService');

/**
 * Service to get actions from a specific layout
 */
export interface ILayoutActionsService {
  getSidebarActions(bookmark: IBookmark): IQuinoAction[];
  getListSelectionActions(layout: IMetaLayout): IListSelectionActions;
  getListRowActions(layout: IMetaLayout, bookmarkToRefresh?: IRefreshableBookmark): IListRowActions;
}

export interface IListSelectionActions {
  normalActions: IMetaAction[];
  overflowActions: IMetaAction[];
}

export interface IListRowActions {
  normalActions: IQuinoAction[];
  overflowActions: IQuinoAction[];
}
