import { injectable } from 'inversify';
import { IExpressionVisitor } from './IExpressionVisitor';
import { IExpression } from './IExpression';
import { isUnaryExpression } from './IUnaryExpression';
import { isInnerExpression } from './IInnerExpression';
import { isTextGroupExpression } from './ITextGroupExpression';
import { isIfExpression } from './IIfExpressionEvaluator';
import { isBinaryExpression } from './IBinaryExpression';
import { isChainExpression } from './IChainExpression';

@injectable()
export class ExpressionVisitor implements IExpressionVisitor {
  visit = (root: IExpression, callback: (element: IExpression) => void): void => {
    callback(root);

    if (isBinaryExpression(root)) {
      this.visit(root.left, callback);
      this.visit(root.right, callback);
    }

    if (isUnaryExpression(root)) {
      this.visit(root.Parameter, callback);
    }

    if (isIfExpression(root)) {
      this.visit(root.Element0, callback);
      this.visit(root.Element1, callback);
      this.visit(root.Element2, callback);
    }

    if (isChainExpression(root)) {
      this.visit(root.call, callback);
      this.visit(root.target, callback);
    }

    if (isTextGroupExpression(root)) {
      for (let i = 0; i <= root.Count - 1; i++) {
        const innerExp = root['Element' + i] as IExpression;
        this.visit(innerExp, callback);
      }
    }

    if (isInnerExpression(root)) {
      this.visit(root.InnerExpression, callback);
    }
  };
}
