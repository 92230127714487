import { IApplication, ITranslationService, ITranslationServiceSymbol, loadDefaultTranslations, TranslationService } from '@quino/core';
import {
  IResponsivityBreakpointService,
  IResponsivityBreakpointServiceSymbol,
  IResponsivityService,
  IResponsivityServiceSymbol,
  ResponsivityBreakpointService,
  ResponsivityService
} from '../../responsivity';
import { IServerHealthCheckerService, IServerHealthCheckerServiceSymbol } from './IServerHealthCheckerService';
import { ContainerLessHealthCheckerService } from './ContainerLessHealthCheckerService';

export class ServerLessModule {
  static use = (app: IApplication) => {
    app.registerSingle<IServerHealthCheckerService>(IServerHealthCheckerServiceSymbol, ContainerLessHealthCheckerService);
    app.registerSingle<ITranslationService>(ITranslationServiceSymbol, TranslationService);
    app.registerSingle<IResponsivityService>(IResponsivityServiceSymbol, ResponsivityService);
    app.registerSingle<IResponsivityBreakpointService>(IResponsivityBreakpointServiceSymbol, ResponsivityBreakpointService);
    app.registerStartupAction(Symbol.for('LoadTranslations'), (app) => {
      const translationService = app.get<ITranslationService>(ITranslationServiceSymbol);
      const browserLanguage = window.navigator.language.replace(/-.*/, '');
      translationService.load(loadDefaultTranslations(browserLanguage));
    });
  };
}
