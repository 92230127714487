import * as React from 'react';
import { useCallback } from 'react';
import { DateBox } from 'devextreme-react/date-box';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { IQuinoEditorProps } from '../Types';
import { CONTROL_IDENTIFIER } from '../../rendering';
import { registerKeyHandlers, useMetaPropertyValueState, useValidation } from '../Util';
import { useLabelSettings, useMetadata } from '../../settings';
import { useFormatString } from '../../settings/useFormatString';
import { useService } from '../../ioc';
import { TResponsiveMode, useResponsiveMode } from '../../responsivity';

export function QuinoDateTimePicker(props: IQuinoEditorProps) {
  const { metaProperty, bookmark, propagateValue, hideClearButton } = props;
  const { description, controlName } = metaProperty;
  const labelSettings = useLabelSettings(metaProperty);
  const { readOnly, enabled } = useMetadata(metaProperty, bookmark.genericObject);
  const formatString = useFormatString(metaProperty);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const responsiveMode = useResponsiveMode();

  const dateTimeMode = controlName === CONTROL_IDENTIFIER.TIMEPICKER ? 'time' : controlName === CONTROL_IDENTIFIER.DATEPICKER ? 'date' : 'datetime';
  const dateSerializationFormat = dateTimeMode === 'time' ? 'yyyy-MM-ddTHH:mm:ss' : dateTimeMode === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-ddTHH:mm:ssZ';
  const [isValid] = useValidation(bookmark, metaProperty);
  const [bookmarkValue, setBookmarkValue] = useMetaPropertyValueState<Date | number | string>(metaProperty, bookmark);

  const setValue = useCallback(
    (value: any) => {
      setBookmarkValue(value);
      propagateValue && propagateValue(value);
    },
    [propagateValue, setBookmarkValue]
  );

  const getDate = (localDateString: Date | number | string | undefined) => {
    if (!localDateString) {
      return localDateString;
    }
    switch (dateTimeMode) {
      case 'date': {
        const localDate = new Date(localDateString);
        return new Date(
          Date.UTC(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), localDate.getUTCHours(), localDate.getUTCMinutes())
        ).toISOString();
      }
      case 'datetime':
        return localDateString;
      case 'time':
        return localDateString;
    }
  };

  const onValueChanged = (e: any) => {
    setValue(getDate(e.value));
  };

  const finalDate = getDate(bookmarkValue);

  return (
    <DateBox
      readOnly={readOnly != null ? readOnly : false}
      focusStateEnabled={!readOnly}
      hoverStateEnabled={!readOnly}
      value={finalDate}
      pickerType={'calendar'}
      acceptCustomValue={responsiveMode !== TResponsiveMode.Phone}
      isValid={isValid}
      displayFormat={formatString}
      useMaskBehavior={true}
      type={dateTimeMode}
      hint={labelSettings.hintType === 'None' ? description : undefined}
      disabled={!enabled}
      dateSerializationFormat={dateSerializationFormat}
      onValueChanged={onValueChanged}
      adaptivityEnabled={true}
      placeholder={
        readOnly || !enabled
          ? ''
          : controlName === CONTROL_IDENTIFIER.TIMEPICKER
          ? translationService.translate('Placeholder.Time')
          : controlName === CONTROL_IDENTIFIER.DATEPICKER
          ? translationService.translate('Placeholder.Date')
          : `${translationService.translate('Placeholder.Date')} ${translationService.translate('Placeholder.Time')}`
      }
      showClearButton={!hideClearButton}
      onInitialized={(e) => registerKeyHandlers(e.component)}
    />
  );
}
