import { isBase64ImageSource, ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { useService } from '@quino/ui';

export const useBackgroundImage = (src: string, translationKey: string, hide: boolean) => {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const translation = translationService.translate(translationKey);
  const hasBase64TranslatedImage = translation != translationKey && isBase64ImageSource(translation);

  let localizedSrc: string = src;
  if (hasBase64TranslatedImage) {
    // Use translated base64 image if available
    localizedSrc = translation;
  }

  if (localizedSrc.length > 0) {
    const rootElement = document.getElementById(document.body.firstElementChild?.id || 'root');
    if (rootElement) {
      if (hide) {
        rootElement.style.backgroundImage = 'unset';
      } else {
        const hasBase64ImageSource = isBase64ImageSource(localizedSrc);
        rootElement.style.backgroundImage = `url(${hasBase64ImageSource ? '' : '/'}${localizedSrc})`;
        rootElement.style.backgroundSize = 'cover';
      }
    }
  }
};
