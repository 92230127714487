import { IGenericObject, IMetaLayout, IMetaRelation, ODataDefaultData } from '@quino/core';
import DataSource from 'devextreme/data/data_source';
import { IQuinoDataSourceOptions } from './IQuinoDataSourceOptions';

export const IODataSourceFactorySymbol = Symbol.for('IODataSourceFactory');

/**
 * A factory for creating OData sources from a layout and class.
 */
export interface IODataSourceFactory {
  /**
   * Create a new source from the given layout and class.
   * @param layout - the layout to use.
   * @param metaClass - the meta class to use.
   * @param options - additional options forwarded to the data source.
   * @param defaultData - default data
   */
  create(layout: IMetaLayout, metaClass: string, options?: IQuinoDataSourceOptions, defaultData?: ODataDefaultData): DataSource;

  /**
   * Create a new source for the given relation.
   * @param relation - the relation to use.
   * @param defaultData - default data
   */
  create(relation: IMetaRelation, defaultData?: ODataDefaultData): DataSource;

  /**
   * Fetch a list of generic objects from the OData backend.
   * @param layout - the layout to use.
   * @param metaClass - the meta class to use.
   * @obsolete - as this will fetch the entire list without any paging don't use it anymore.
   * @param expandRelationsToMultiple - if relations to multiple should be expanded.
   */
  fetchList(layout: IMetaLayout, metaClass: string, expandRelationsToMultiple?: boolean): Promise<IGenericObject[]>;

  /**
   * Fetch a single object from the server to display.
   * @param primaryKey - the primary key to use.
   * @param layout - the layout.
   * @param metaClass - the meta class.
   * @param expandRelationsToMultiple - if relations to multiple should be expanded.
   */
  fetch(primaryKey: string, layout: IMetaLayout, metaClass: string, expandRelationsToMultiple?: boolean): Promise<IGenericObject>;
}
