import { ILogger, IRequestBuilder, IUrlManager, QuinoCoreServiceSymbols } from '@quino/core';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import { IQuinoEditorProps } from '../Types';
import { useMetaPropertyValueState, useValidation } from '../Util';
import { useLabelSettings, useMetadata } from '../../settings';
import { useService } from '../../ioc';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

let translationKeys: string[] = [];

export function QuinoTranslationKeyPicker(props: IQuinoEditorProps) {
  const { bookmark, metaProperty, propagateValue } = props;
  const { description } = metaProperty;
  const labelSettings = useLabelSettings(metaProperty);
  const { readOnly, required, enabled } = useMetadata(metaProperty, bookmark.genericObject);
  const [isValid] = useValidation(bookmark, metaProperty);
  const builder = useService<IRequestBuilder>(QuinoCoreServiceSymbols.IRequestBuilder);
  const urlManager = useService<IUrlManager>(QuinoCoreServiceSymbols.IUrlManager);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const isCustomTranslation = bookmark.genericObject['TranslationType'] !== 0;
  const emptyDataSource = new DataSource([]);
  const [dataSource, setDataSource] = useState<DataSource>(emptyDataSource);
  const [bookmarkValue, setBookmarkValue] = useMetaPropertyValueState<any>(metaProperty, bookmark);

  const setValue = useCallback(
    (value: any) => {
      setBookmarkValue(value);
      propagateValue && propagateValue(value);
    },
    [propagateValue, setBookmarkValue]
  );

  useMemo(() => {
    if (isCustomTranslation) {
      setDataSource(emptyDataSource);
    } else if (translationKeys.length > 0) {
      setDataSource(new DataSource({ store: new ArrayStore({ data: translationKeys }), paginate: true, pageSize: 5 }));
    } else {
      const url = urlManager.getDefaultTranslationsUrl().replace('/all', '/keys');
      builder
        .create(url, 'get')
        .fetchJson<[]>()
        .then((result) => {
          translationKeys = result;
          setDataSource(new DataSource({ store: new ArrayStore({ data: translationKeys }), paginate: true, pageSize: 5 }));
        })
        .catch(logger.logError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmark.genericObject['TranslationType']]);

  return (
    <div className={'quino-editor-with-crosslink'}>
      <SelectBox
        showClearButton={!required}
        className={'quino-translation-key-picker-select-box'}
        searchEnabled={true}
        showDropDownButton={!isCustomTranslation}
        searchMode={'contains'}
        readOnly={readOnly != null ? readOnly : false}
        focusStateEnabled={!readOnly}
        hoverStateEnabled={!readOnly}
        disabled={!enabled}
        dataSource={dataSource}
        placeholder={''}
        value={bookmarkValue}
        deferRendering={false}
        hint={labelSettings.hintType === 'None' ? description : undefined}
        onValueChanged={(e) => setValue(e.value)}
        isValid={isValid}
        acceptCustomValue={isCustomTranslation}
      />
    </div>
  );
}
