import { useEffect } from 'react';

export const useOnMount = (func: () => void): void => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(func, []);
};

export const useOnMountAsync = (func: () => Promise<void>): void => {
  useEffect(
    () => {
      void (async () => {
        await func();
      })();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
