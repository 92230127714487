import { IMetaAction } from '@quino/core';
import { IQuinoAction, IQuinoActionArgs } from './IQuinoAction';
import { IConfirmationActionAspect } from '../aspects/IConfirmationActionAspect';
import { IBookmark } from '../bookmarks';
import { IQuinoContextMenuItem } from '../components';

/**
 * The action factory symbol.
 */
export const IQuinoActionFactorySymbol = Symbol.for('IQuinoActionFactory');

/**
 * Action factory for converting IMetaActions to IQuinoActions that can be used in various contexts
 */
export interface IQuinoActionFactory {
  createAction(action: IMetaAction): IQuinoAction;
  getActionOnClick(action: IMetaAction): (args: IQuinoActionArgs) => void;
  getConfirmationActionFeedback(action: IMetaAction, confirmationActionAspect: IConfirmationActionAspect): Promise<string>;
  convertBookmarkActionToContextMenuItem(action: IQuinoAction, bookmark: IBookmark): IQuinoContextMenuItem;
}
