import {
  getAspectOrDefault,
  IActionExecutor,
  IActionExecutorSymbol,
  IconAspectIdentifier,
  IEnabledCalculator,
  IGenericObject,
  IIconAspect,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  ILogger,
  IMetaAction,
  INotificationService,
  INotificationServiceSymbol,
  isGenericObject,
  ITranslationService,
  ITranslationServiceSymbol,
  IVisibleCalculator,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { inject, injectable } from 'inversify';
import { IBookmark, IRefreshableBookmark, isIBookmark, isIObjectBookmark, isNewObjectPrimaryKey } from '../bookmarks';
import { IQuinoActionFactory } from './IQuinoActionFactory';
import { IQuinoAction, IQuinoActionArgs, quinoActionPropValue, TQuinoActionSource } from './IQuinoAction';
import { IClientActionProvider, IClientActionProviderSymbol } from './IClientActionProvider';
import { IClientAction } from './IClientAction';
import { ConfirmationActionAspectIdentifier, IConfirmationActionAspect } from '../aspects/IConfirmationActionAspect';
import { IFeedbackService, IFeedbackServiceSymbol } from '../feedback';
import { IQuinoContextMenuItem } from '../components';

export enum ActionConfirmationFeedbackValue {
  Execute = 'Execute',
  Cancel = 'Cancel'
}

@injectable()
export class QuinoActionFactory implements IQuinoActionFactory {
  constructor(
    @inject(IActionExecutorSymbol) private readonly actionExecutor: IActionExecutor,
    @inject(ILoadingFeedbackSymbol) private readonly loadingFeedback: ILoadingFeedback,
    @inject(INotificationServiceSymbol) private readonly notificationService: INotificationService,
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger,
    @inject(QuinoCoreServiceSymbols.IVisibleCalculator) private readonly visibleCalculator: IVisibleCalculator,
    @inject(QuinoCoreServiceSymbols.IEnabledCalculator) private readonly enabledCalculator: IEnabledCalculator,
    @inject(IClientActionProviderSymbol) private readonly clientActionProvider: IClientActionProvider,
    @inject(IFeedbackServiceSymbol) private readonly feedbackService: IFeedbackService
  ) {}

  convertBookmarkActionToContextMenuItem = (action: IQuinoAction, bookmark: IBookmark): IQuinoContextMenuItem => {
    return {
      onItemClick: () => action.onClick({ source: bookmark }),
      icon: quinoActionPropValue(action.icon, bookmark),
      text: quinoActionPropValue(action.caption, bookmark),
      hint: quinoActionPropValue(action.hint, bookmark),
      visible: quinoActionPropValue(action.visible, bookmark),
      disabled: quinoActionPropValue(action.disabled, bookmark),
      items:
        action.children && action.children.length > 0
          ? action.children.map((a) => this.convertBookmarkActionToContextMenuItem(a, bookmark))
          : undefined
    };
  };

  createAction(action: IMetaAction): IQuinoAction {
    return {
      onClick: this.getActionOnClick(action),
      icon: getAspectOrDefault<IIconAspect>(action, IconAspectIdentifier)?.icon ?? 'material-icons-outlined bolt',
      caption: action.caption,
      hint: action.caption,
      saveCallingObjectBeforeExecuting: action.saveCallingObjectBeforeExecuting,
      visible: (source: TQuinoActionSource) => this.getActionVisibilityFromSource(action, source),
      disabled: (source: TQuinoActionSource) => this.getActionDisabledFromSource(action, source)
    };
  }

  getActionOnClick(action: IMetaAction): (args: IQuinoActionArgs) => void {
    const clientAction = this.clientActionProvider.find(action);

    return clientAction ? this.clientActionOnClick(action, clientAction) : this.defaultOnClick(action);
  }

  async getConfirmationActionFeedback(action: IMetaAction, confirmationActionAspect: IConfirmationActionAspect): Promise<string> {
    const feedbackOptions = [
      {
        value: ActionConfirmationFeedbackValue.Cancel,
        text: confirmationActionAspect.cancelButtonText || this.translationService.translate('Cancel'),
        isPrimary: false,
        isCancelOption: true
      },
      {
        value: ActionConfirmationFeedbackValue.Execute,
        text: confirmationActionAspect.continueButtonText || this.translationService.translate('Action.Execute'),
        isPrimary: true,
        isCancelOption: false
      }
    ];

    return this.feedbackService.getCustomFeedback(
      confirmationActionAspect.title || this.translationService.translate('Action.Title'),
      confirmationActionAspect.message || this.translationService.translate('Action.Message', { action: action.caption }),
      feedbackOptions
    );
  }

  private getActionVisibilityFromSource(action: IMetaAction, source: TQuinoActionSource): boolean {
    const object = this.extractObjectFromSource(source);
    return action.visible !== undefined ? this.visibleCalculator.calculate(action, object ?? source) : true;
  }

  private getActionDisabledFromSource(action: IMetaAction, source: TQuinoActionSource): boolean {
    const object = this.extractObjectFromSource(source);
    return !(action.enabled !== undefined ? this.enabledCalculator.calculate(action, object ?? source) : true);
  }

  private readonly extractObjectFromSource = (source: TQuinoActionSource): IGenericObject | undefined => {
    return isGenericObject(source) ? source : isIObjectBookmark(source) ? source.genericObject : undefined;
  };

  private clientActionOnClick(action: IMetaAction, clientAction: IClientAction): (args: IQuinoActionArgs) => void {
    if (action.saveCallingObjectBeforeExecuting) {
      return (args) => {
        const genericObject = this.extractObjectFromSource(args.source);
        if (genericObject && (!genericObject.primaryKey || isNewObjectPrimaryKey(genericObject.primaryKey))) {
          throw new Error(`Can not execute action ${[action.name]} on an unsaved object.`);
        } else {
          clientAction.onClick(args);
        }
      };
    }

    return (args) => clientAction.onClick(args);
  }

  private defaultOnClick(action: IMetaAction) {
    return async (args: IQuinoActionArgs) => {
      const { source } = args;
      if (isGenericObject(source)) {
        await this.executeGenericObjectAction(action, source, args.bookmarkToRefresh);
      } else if (isIBookmark(source)) {
        if (isIObjectBookmark(source)) {
          await this.executeGenericObjectAction(action, source.genericObject, source);
        } else {
          this.logger.logError(`Bookmark actions not implemented for current bookmark type. Cannot execute action [${action.caption}].`);
        }
      } else {
        this.logger.logError(`Invalid action source: [${source}]. Cannot execute action [${action.caption}].`);
      }
    };
  }

  private async executeGenericObjectAction(action: IMetaAction, genericObject: IGenericObject, bookmarkToRefresh?: IRefreshableBookmark) {
    const confirmActionAspect = getAspectOrDefault<IConfirmationActionAspect>(action, ConfirmationActionAspectIdentifier);

    if (confirmActionAspect) {
      const feedback = await this.getConfirmationActionFeedback(action, confirmActionAspect);
      if (feedback === ActionConfirmationFeedbackValue.Cancel) {
        return;
      }
    }

    const unload = this.loadingFeedback.load();
    this.actionExecutor
      .execute(action, genericObject)
      .then((result) => {
        this.notificationService.notify({
          message: this.translationService.translate('Action.Executed', { name: action.caption }),
          area: 'global',
          type: 'success',
          autoDisappear: true
        });
        if (result !== 'None' && bookmarkToRefresh) {
          bookmarkToRefresh.refresh();
        }
      })
      .catch(() => {
        this.notificationService.notify({
          message: this.translationService.translate('Action.Failed', { name: action.caption }),
          area: 'global',
          type: 'error',
          autoDisappear: true
        });
      })
      .finally(unload);
  }
}
