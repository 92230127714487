import { IMetaElement, isIMetaGroup, isIMetaProperty } from '../../meta';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IExpressionEvaluator } from '../index';
import { IRequiredCalculator } from './IRequiredCalculator';

@injectable()
export class RequiredCalculator implements IRequiredCalculator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator) {}

  calculate(element: IMetaElement, object: any): boolean {
    if (isIMetaProperty(element) || isIMetaGroup(element)) {
      return element.required != null && this.evaluator.evaluate<boolean>(element.required, object);
    }

    return false;
  }
}
