import { IMetaAspect } from '../IMetaAspect';

/**
 * The identifier for retrieving a value radio group layout aspect.
 */
export const RadioGroupLayoutAspectIdentifier = 'RadioGroupLayout';

/**
 * radio group layout aspect containing whether the layout of the radio group should be vertical or horizontal.
 */
export interface IRadioGroupLayoutAspect extends IMetaAspect {
  layout: 'horizontal' | 'vertical';
}
