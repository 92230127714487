import { IMetaElement } from '../../meta';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IExpressionEvaluator } from '../index';
import { IEnabledCalculator } from './IEnabledCalculator';
import { isIMetaElement } from '../../meta/IMetaElement';

@injectable()
export class EnabledCalculator implements IEnabledCalculator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator) {}

  calculate(element: IMetaElement, object: any): boolean {
    if (isIMetaElement(element)) {
      return element.enabled != null && this.evaluator.evaluate<boolean>(element.enabled, object);
    }

    return true;
  }
}
