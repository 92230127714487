import { injectable } from 'inversify';
import { Provider } from '@quino/core';
import { INavigationLinkResolver } from './INavigationLinkResolver';
import { INavigationLinkResolverProvider } from './INavigationLinkResolverProvider';

@injectable()
export class NavigationLinkResolverProvider extends Provider<INavigationLinkResolver> implements INavigationLinkResolverProvider {
  find(targetName: string): INavigationLinkResolver | undefined {
    const key = Object.keys(this.instances).find((x) => x === targetName);
    if (key) {
      return this.instances[key];
    }

    return undefined;
  }
}
