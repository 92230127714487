import { IMetaElement, IMetaLayout, LayoutType } from '../meta';

/**
 * Symbol to retrieve the ILayoutResolver from the IoC.
 */
export const ILayoutResolverSymbol = Symbol.for('LayoutResolver');

/**
 * Context type to resolve a layout for.
 */
export type ResolveContext = {
  metaClass: string;
  type: LayoutType;
  element?: IMetaElement;
  data?: any;
};

/**
 * Resolve layouts context dependant.
 */
export interface ILayoutResolver {
  /**
   * Resolve a list of layouts that are allowed in the current scope.
   * @param context - the context to apply.
   */
  resolve(context: ResolveContext): IMetaLayout[];

  /**
   * Resolve a single layout for the current scope.
   * @param context - the context to apply.
   */
  resolveSingle(context: ResolveContext): IMetaLayout;

  /**
   * Try to resolve a layout with the given context.
   * @param context - the context to apply.
   */
  tryResolveSingle(context: ResolveContext): IMetaLayout | undefined;
}
