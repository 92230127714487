/**
 * Check whether a device is an iOS device
 */
function isIOS() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
  }
}

/**
 * Return classname with iOS suffix if device runs with iOS
 */
export const getIosClassName = (className: string): string => {
  return className + (isIOS() ? ' is--ios' : '');
};
