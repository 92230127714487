import { IQuinoContextInitializerService } from './IQuinoContextInitializerService';
import { inject, injectable } from 'inversify';
import {
  currentContextStorageKey,
  ILogger,
  IQuinoContext,
  IQuinoContextContext,
  IRequestBuilder,
  LocalStorageService,
  LocalStorageServiceSymbol,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { IQuinoContextUrlService, IQuinoContextUrlServiceSymbol } from './IQuinoContextUrlService';

@injectable()
export class QuinoContextInitializerService implements IQuinoContextInitializerService {
  constructor(
    @inject(LocalStorageServiceSymbol) private readonly localStorageService: LocalStorageService,
    @inject(IQuinoContextUrlServiceSymbol) private readonly quinoContextUrlService: IQuinoContextUrlService,
    @inject(QuinoCoreServiceSymbols.IRequestBuilder) private readonly requestBuilder: IRequestBuilder,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger
  ) {}

  initialize(quinoContextContext: IQuinoContextContext): void {
    this.localStorageService
      .get<IQuinoContext | null>(currentContextStorageKey)
      .then((localStorageContext) => {
        const url = this.quinoContextUrlService.getUrl();
        (url &&
          this.requestBuilder
            .create(url, 'get')
            .requiresAuthentication()
            .fetchJson<IQuinoContext[]>()
            .then((fetchedContexts) => {
              if (localStorageContext != null) {
                const foundContext = fetchedContexts.find((x) => x.value != null && x.value === localStorageContext.value);
                if (foundContext != null) {
                  quinoContextContext.setCurrentContext(foundContext);
                }
              }
              if (quinoContextContext.currentContext == undefined && fetchedContexts.length === 1) {
                quinoContextContext.setCurrentContext(fetchedContexts[0]);
              }
              quinoContextContext.setAvailableContexts(fetchedContexts);
            })
            .catch((e) => this.logger.logError(`Error while trying to load contexts in QuinoContextInitializerService, details: ${e}`))
            .finally(() => quinoContextContext.setIsLoading(false))) ||
          quinoContextContext.setIsLoading(false);
      })
      .catch((e) => {
        const message = 'Failed to initialize QuinoContextInitializerService';
        this.logger.logError(`${message} ${JSON.stringify(e)}`);
      });
  }
}
