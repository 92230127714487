import { IProvider } from './IProvider';
import { injectable } from 'inversify';

@injectable()
export abstract class Provider<TInstance> implements IProvider<TInstance> {
  getInstances = (): TInstance[] => {
    const instances: TInstance[] = [];
    for (const identifier in this.instances) {
      instances.push(this.instances[identifier]);
    }
    return instances;
  };

  register = (instance: TInstance, symbol: symbol): void => {
    const identifier = Symbol.keyFor(symbol);
    if (!identifier) {
      throw new Error('Could not resolve symbol, did you use the global Symbol.for(...) registration?');
    }

    this.instances[identifier] = instance;
  };

  protected instances: { [identifier: string]: TInstance } = {};
}
