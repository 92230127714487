import { IDashboardLayout } from './IDashboardLayout';

/**
 * The symbol for the dashboard settings.
 */
export const IDashboardSettingsServiceSymbol = Symbol.for('IDashboardSettingsService');

/**
 * The dashboard settings handler
 */
export interface IDashboardSettingsService {
  /**
   * Gets all available dashboards
   * @returns {Promise<IDashboardLayout[]>}
   */
  getDashboards(): Promise<IDashboardLayout[]>;

  /**
   * Gets the settings for the dashboard with the given name; returns fallback if name not found
   * @param dashboardName: Name of the dashboard to get
   * @returns {Promise<IDashboardLayout | undefined>}
   */
  getDashboardOrFallback(dashboardName: string): Promise<IDashboardLayout>;

  /**
   * Gets the favorite/default dashboard
   * @returns {Promise<IDashboardLayout>}
   */
  getFavoriteOrDefaultDashboard(): Promise<IDashboardLayout>;

  /**
   * Gets the current number of dashboards
   */
  getCurrentNumberOfDashboards(): number;

  /**
   * Sets the settings of a custom dashboard
   * @param dashboard: Custom dashboard to save
   * @returns {Promise<IDashboardLayout>}
   */
  saveCustomDashboard(dashboard: IDashboardLayout): Promise<IDashboardLayout>;

  /**
   * Sets the settings of a shared dashboard
   * @param dashboard: Custom dashboard to save
   * @param visibleExpression: Visible expression to apply to the shared dashboard
   * @returns {Promise<IDashboardLayout>}
   */
  saveSharedDashboard(dashboard: IDashboardLayout, visibleExpression?: string): Promise<IDashboardLayout>;

  /**
   * Creates a new custom dashboard layout and returns it
   * @param caption - caption of the new dashboard
   */
  createCustomDashboard(caption?: string): Promise<IDashboardLayout>;

  /**
   * Duplicates a custom dashboard and returns the duplicate
   * @param originalDashboard - dashboard layout to duplicate
   * @param newCaption - caption of the new layout
   */
  duplicateCustomDashboard(originalDashboard: IDashboardLayout, newCaption: string): Promise<IDashboardLayout>;

  /**
   * Deletes a custom dashboard
   * @param dashboardName - name of the dashboard to delete
   * @returns the favorite or default dashboard
   */
  deleteCustomDashboard(dashboardName: string, createDefaultDashboardIfNeeded: boolean): Promise<IDashboardLayout>;

  /**
   * Deletes a shared dashboard
   * @param dashboardName - name of the dashboard to delete
   * @returns the favorite or default dashboard
   */
  deleteSharedDashboard(dashboardName: string, createDefaultDashboardIfNeeded: boolean): Promise<IDashboardLayout>;
}
