import { IMessage } from '../core';

/**
 * Symbol for the expression context changed message.
 */
export const ExpressionContextChangedMessageSymbol = Symbol.for('ExpressionContextChangedMessage');

/**
 * Message that triggers if expressions should be re-evaluated.
 */
export class ExpressionContextChangedMessage implements IMessage {
  symbol: symbol = ExpressionContextChangedMessageSymbol;

  /**
   * The selected context.
   */
  context: any;
}
