import { inject, injectable } from 'inversify';
import { IQuinoFileStorage } from './IQuinoFileStorage';
import { IUrlManager, QuinoCoreServiceSymbols } from '@quino/core';
import { IQuinoFileStorageService, IQuinoFileStorageServiceSymbol } from './IQuinoFileStorageService';
import { IFileInfo } from './IFileInfo';

@injectable()
export class QuinoFileStorage implements IQuinoFileStorage {
  constructor(
    @inject(QuinoCoreServiceSymbols.IUrlManager)
    private readonly urlManager: IUrlManager,
    @inject(IQuinoFileStorageServiceSymbol) private readonly quinoFileStorageService: IQuinoFileStorageService
  ) {}

  getUploadUrl(): string {
    return this.urlManager.getFileUploadUrl();
  }

  async setFileInfo(id: string, name: string, ownerClass: string, ownerPrimaryKey: string, context: string) {
    await this.quinoFileStorageService.updateFileInfoAsync({
      id,
      name,
      ownerClass,
      ownerPrimaryKey,
      context
    });
  }

  async getFileInfo(id: string | null, ownerClass: string | null, ownerPrimaryKey: string | null, context: string | null): Promise<IFileInfo[]> {
    return this.quinoFileStorageService.getFileInfoAsync(id, ownerClass, ownerPrimaryKey, context);
  }

  async deleteFile(id: string): Promise<void> {
    await this.quinoFileStorageService.deleteFileStorageAsync(id);
  }

  async downLoadFile(id: string): Promise<Blob> {
    return this.quinoFileStorageService.downloadFileAsync(id);
  }
}
