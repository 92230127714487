import React, { ReactNode, useRef } from 'react';
import { useService } from '../../ioc';
import { IFeedbackService, IFeedbackServiceSymbol } from '../../feedback';
import {
  ILogger,
  INotificationAction,
  ITranslationService,
  ITranslationServiceSymbol,
  QuinoCoreServiceSymbols,
  TNotificationType
} from '@quino/core';

const iconClasses = {
  error: 'error_outline',
  warning: 'report_problem',
  success: 'check_circle',
  info: 'info'
};

export function QuinoNotificationContent(props: {
  onClose?: () => void;
  type: TNotificationType;
  message: string;
  messageDetails?: string;
  customActions?: INotificationAction[];
  customHeight?: number;
  customElement?: ReactNode;
  closeButtonIsShown?: boolean;
}) {
  const feedbackService = useService<IFeedbackService>(IFeedbackServiceSymbol);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const iconClass = iconClasses[props.type] || 'error_outline';
  const messageRef = useRef<HTMLDivElement>(null);
  const messageDetails = props.messageDetails
    ? `${/^[.,:!?]/.exec(props.message.slice(-1)) ? ' ' + translationService.translate('Details') : ''}: ${props.messageDetails}`
    : '';

  const showDetails = () => {
    feedbackService.showMessage(translationService.translate('Details'), props.message, props.messageDetails).catch(logger.logError);
  };

  return (
    <div className={'quino-notification-content ' + props.type} style={{ height: props.customHeight ? props.customHeight + 'px' : '' }}>
      <i className={'quino-notification-icon material-icons-outlined ' + iconClass} />
      <div className={'quino-notification-content-elements'}>
        <div ref={messageRef} className={'quino-notification-message'} title={props.message + messageDetails}>
          {props.message}
          <span className={'quino-notification-message-detail'}>{messageDetails}</span>
        </div>
        {props.customElement && <div className={'quino-notification-custom-element'}>{props.customElement}</div>}
      </div>
      <div className={'quino-notification-buttons-container'}>
        <i
          title={translationService.translate('Notification.ViewDetails')}
          className='quino-notification-button material-icons-outlined receipt'
          onClick={showDetails}
        />
        {props.customActions &&
          props.customActions.map((action) => (
            <i title={action.hint} className={'quino-notification-button ' + action.icon} onClick={action.callback} />
          ))}
        {props.closeButtonIsShown !== false && props.onClose && (
          <i
            title={translationService.translate('Notification.ClearNotification')}
            className='quino-notification-button material-icons-outlined clear'
            onClick={props.onClose}
          />
        )}
      </div>
    </div>
  );
}
