import { IFileUploadValidator } from './IFileUploadValidator';
import { getAspectOrDefault, IFieldValidationResult, IMetaProperty, ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { FileUploadOptionsAspectIdentifier, IFileUploadOptionsAspect } from '../../aspects';
import { inject, injectable } from 'inversify';

@injectable()
export class FileUploadValidator implements IFileUploadValidator {
  constructor(@inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService) {}

  validateControl = (
    metaProperty: IMetaProperty,
    uploadedFiles: any[] | undefined,
    filesInProgress: any[] | undefined,
    required: boolean
  ): IFieldValidationResult => {
    const uploadSettingsAspect = getAspectOrDefault<IFileUploadOptionsAspect>(metaProperty, FileUploadOptionsAspectIdentifier);

    const getMinFilesError = (field: IMetaProperty, minFiles?: number): IFieldValidationResult => {
      return {
        fieldErrors: [
          {
            fieldName: field.name,
            errorCode: 'TBD',
            errorMessage: this.translationService.translate('FileUpload.Validations.InvalidFileUploadMinNumber', {
              minFiles: minFiles!
            })
          }
        ]
      };
    };

    const getMaxFilesError = (field: IMetaProperty, maxFiles?: number): IFieldValidationResult => {
      return {
        fieldErrors: [
          {
            fieldName: field.name,
            errorCode: 'TBD',
            errorMessage: this.translationService.translate('FileUpload.Validations.InvalidFileUploadMaxNumber', {
              maxFiles: maxFiles!
            })
          }
        ]
      };
    };

    const getUploadInProgressError = (field: IMetaProperty): IFieldValidationResult => {
      return {
        fieldErrors: [
          {
            fieldName: field.name,
            errorCode: 'TBD',
            errorMessage: this.translationService.translate('FileUpload.Validations.UploadInProgress')
          }
        ]
      };
    };

    const validateMinNumberOfUploads = (minFiles?: number, numberOfFiles?: number): boolean => {
      numberOfFiles = numberOfFiles ? numberOfFiles : 0;

      if (minFiles) {
        if (numberOfFiles < minFiles) {
          return false;
        }
      }

      return true;
    };

    const validateMaxNumberOfUploads = (maxFiles?: number, numberOfFiles?: number): boolean => {
      numberOfFiles = numberOfFiles ? numberOfFiles : 0;

      if (maxFiles) {
        if (numberOfFiles > maxFiles) {
          return false;
        }
      }

      return true;
    };

    if (filesInProgress?.length) {
      return getUploadInProgressError(metaProperty);
    }

    if (!uploadedFiles) {
      return {};
    }

    if (
      (required && uploadSettingsAspect?.minFiles && !validateMinNumberOfUploads(uploadSettingsAspect.minFiles, uploadedFiles.length)) ||
      (required && uploadedFiles.length < 1)
    ) {
      return getMinFilesError(metaProperty, uploadSettingsAspect?.minFiles ? uploadSettingsAspect.minFiles : 1);
    }

    if (uploadSettingsAspect && !validateMaxNumberOfUploads(uploadSettingsAspect.maxFiles, uploadedFiles.length)) {
      return getMaxFilesError(metaProperty, uploadSettingsAspect.maxFiles);
    }

    return {};
  };
}
