const reflectMetadata = require('reflect-metadata');
import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { App } from './App';

import('./quino.sandbox.less');
import('./codemirror.less');

console.assert(reflectMetadata, 'Could not load reflect-metadata: ', reflectMetadata);
console.assert(Symbol, 'Could not load Symbol');
console.assert(Promise, 'Could not load Promise');
console.assert(fetch, 'Could not load fetch');

try {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: PRODUCTION
        ? 'InstrumentationKey=a45068b6-94f8-40f2-954b-454e4d42fcae;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/'
        : '',
      extensions: [reactPlugin]
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
} catch {
  // NOP
}

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
