import React, { PropsWithChildren } from 'react';
import {
  CONTROL_IDENTIFIER,
  getResponsiveClassName,
  IBookmark,
  IMetaBookmark,
  IObjectBookmark,
  IQuinoBookmarkAction,
  isICustomBookmark,
  isIListBookmark,
  isIObjectBookmark,
  QuinoMetaPanel,
  QuinoObjectSummary,
  TResponsiveMode,
  usePromise,
  useResponsiveMode,
  useService
} from '@quino/ui';
import { CUIStickyHeader } from './CUIStickyHeader';
import { IVersionInfo, IVersionInfoService, IVersionInfoServiceSymbol } from '@quino/core';
import { CUISidebar } from '../sidebar/CUISidebar';

function MetaBookmarkContent(props: { bookmark: IMetaBookmark }) {
  return <QuinoMetaPanel bookmark={props.bookmark} />;
}

const MemoizedMetaBookmarkContent = React.memo(MetaBookmarkContent);

export interface ICUIBookmarkContentProps {
  bookmark?: IBookmark;
  customBookmarkActions?: IQuinoBookmarkAction[];
}

export function CUIBookmarkContent(props: PropsWithChildren<ICUIBookmarkContentProps>) {
  const { bookmark, customBookmarkActions } = props;
  const responsiveMode = useResponsiveMode();
  const versionInfoService = useService<IVersionInfoService>(IVersionInfoServiceSymbol);

  const [versionInfo] = usePromise<IVersionInfo, any>(async () => versionInfoService.getVersionInfo(), []);

  return bookmark ? (
    <div className='quino-ecui-content'>
      <CUIStickyHeader bookmark={bookmark} position={'Header'} customBookmarkActions={customBookmarkActions} />
      <main className={'quino-ecui-content-main'}>
        {bookmarkContents(bookmark, responsiveMode)}
        {isIObjectBookmark(bookmark) && responsiveMode === TResponsiveMode.Desktop && <CUISidebar bookmark={bookmark} />}
      </main>
      <footer className={getResponsiveClassName('quino-ecui-version', responsiveMode)}>
        <span title={'Client Version: ' + versionInfo?.frontend.frontendVersion}>{versionInfo?.frontend.frontendVersion}</span>
        <span>{' / '}</span>
        <span title={'Server Version: ' + versionInfo?.backend.backendVersion}>{versionInfo?.backend.backendVersion}</span>
      </footer>
    </div>
  ) : (
    <></>
  );
}

function bookmarkContents(bookmark: IBookmark, responsiveMode: TResponsiveMode) {
  if (isIObjectBookmark(bookmark) || isIListBookmark(bookmark)) {
    return (
      <div className={'quino-ecui-bookmark-content'}>
        {responsiveMode !== TResponsiveMode.Phone && isIObjectBookmark(bookmark) && objectSummary(bookmark, responsiveMode)}
        <div className={getResponsiveClassName('quino-ecui-bookmark-detail', responsiveMode)}>
          <MemoizedMetaBookmarkContent bookmark={bookmark} />
        </div>
      </div>
    );
  } else if (isICustomBookmark(bookmark)) {
    return bookmark.render();
  }

  return <div>Bookmark not mapped - ensure your bookmark is either a List, Object or provides a custom render function (ICustomBookmark).</div>;
}

function objectSummary(bookmark: IObjectBookmark, responsiveMode: TResponsiveMode) {
  const objectSummary = bookmark.layout.elements.find((e) => e.controlName === CONTROL_IDENTIFIER.OBJECTSUMMARY);
  if (objectSummary) {
    return (
      <div className={getResponsiveClassName('quino-ecui-bookmark-custom-header', responsiveMode)}>
        <QuinoObjectSummary element={objectSummary} bookmark={bookmark} />
      </div>
    );
  }

  return <></>;
}
