import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { IQuinoMetaPanelExtensionProps } from './QuinoMetaPanel';
import { Button } from 'devextreme-react/button';
import { useService } from '../../ioc';
import { useOnBookmarkAnyEvent, useRerender } from '../Util';
import { isIObjectBookmark } from '../../bookmarks';

export function QuinoMetaPanelResetButton(props: PropsWithChildren<IQuinoMetaPanelExtensionProps>) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);

  const rerender = useRerender();
  useOnBookmarkAnyEvent(props.bookmark, rerender);

  if (isIObjectBookmark(props.bookmark)) {
    const objectBookmark = props.bookmark;

    return (
      <Button
        icon={'material-icons-outlined undo'}
        type={'normal'}
        text={translationService.translate('Discard')}
        disabled={!objectBookmark.hasChanges()}
        onClick={() => {
          objectBookmark.reset();
          objectBookmark.generalErrors.splice(0, objectBookmark.generalErrors.length);
          props.updateMetaPanel!(objectBookmark);
        }}
      />
    );
  }

  return <div />;
}
