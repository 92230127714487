import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { IContainer, ContainerContext } from '../../../ioc';
import { IResponsivityService, IResponsivityServiceSymbol, TResponsiveMode } from '../../../responsivity';
import { IQuinoContextMenuItem, QuinoContextMenuButton } from '../../ContextMenu';
import dxDataGrid from 'devextreme/ui/data_grid';

export interface IListSelectionAction {
  caption: string;
  icon: string;
  onClick?: (grid: dxDataGrid) => void;
  children?: IListSelectionAction[];
  isOverflow?: boolean;
  visible: boolean;
  disabled: boolean;
}

export function QuinoDataGridSelectionActionBar(props: {
  gridComponent: dxDataGrid | undefined;
  applicationContainer: IContainer;
  actions: IListSelectionAction[];
  numberOfSelectedRows: number;
}) {
  const { actions, gridComponent, numberOfSelectedRows, applicationContainer } = props;

  const translationService = applicationContainer.get<ITranslationService>(ITranslationServiceSymbol);
  const responsivityService = applicationContainer.get<IResponsivityService>(IResponsivityServiceSymbol);

  const [responsiveMode, setResponsiveMode] = useState<TResponsiveMode>(responsivityService.getCurrentResponsiveMode());

  useEffect(() => {
    const symbol = responsivityService.subscribeToWidthChanges(setResponsiveMode);

    return () => {
      responsivityService.unsubscribeFromWidthChanges(symbol);
    };
  }, [responsivityService]);

  return (
    <ContainerContext.Provider value={{ container: applicationContainer }}>
      {numberOfSelectedRows > 0 && (
        <div className='list-action-bar'>
          <div className='list-action-bar-selection-info'>
            <span>
              <b>{numberOfSelectedRows}</b> {translationService.translate('QuinoDataGrid.ActionBar.SelectedItems')}
            </span>
            <Button
              stylingMode={'text'}
              className={'list-action-bar-button'}
              icon={'material-icons-outlined clear'}
              hint={translationService.translate('QuinoDataGrid.ActionBar.ClearSelection')}
              onClick={gridComponent?.clearSelection}
            />
          </div>
          <ListSelectionActions actions={actions} gridComponent={gridComponent} isMobile={responsiveMode === TResponsiveMode.Phone} />
        </div>
      )}
    </ContainerContext.Provider>
  );
}

function ListSelectionActions(props: { actions: IListSelectionAction[]; gridComponent: dxDataGrid | undefined; isMobile: boolean }) {
  const { actions, gridComponent, isMobile } = props;

  const normalActions: IListSelectionAction[] = [];
  const defaultOverflowActions: IListSelectionAction[] = [];
  actions.filter((a) => a.visible).forEach((a) => (a.isOverflow ? defaultOverflowActions.push(a) : normalActions.push(a)));

  const maxVisible = isMobile ? 0 : 3;
  const visibleActions = normalActions.length > maxVisible ? normalActions.slice(0, maxVisible) : normalActions;
  const overflowActions =
    normalActions.length > maxVisible ? [...normalActions.slice(maxVisible), ...defaultOverflowActions] : defaultOverflowActions;

  const mapActionToItem = (action: IListSelectionAction): IQuinoContextMenuItem => {
    return {
      text: action.caption,
      hint: action.caption,
      icon: action.icon,
      disabled: action.disabled,
      onItemClick: () => gridComponent && action.onClick && action.onClick(gridComponent),
      items: action.children && action.children.length > 0 ? action.children.map(mapActionToItem) : undefined
    };
  };

  return (
    <div className={'list-action-bar-button-wrapper'}>
      {visibleActions.length > 0 &&
        visibleActions.map((action, index) => {
          const buttonKey = 'quino-list-selection-button-' + index;

          return action.children && action.children.length > 0 ? (
            <QuinoContextMenuButton
              buttonClassname={'list-action-bar-overflow-button'}
              buttonIcon={action.icon}
              dataSource={action.children.map(mapActionToItem)}
              buttonId={buttonKey}
              key={buttonKey}
              buttonHintKey={action.caption}
              position={{ at: 'right bottom', my: 'right top' }}
              submenuDirection={'left'}
            />
          ) : (
            <Button
              className={'list-action-bar-button'}
              stylingMode={'text'}
              key={buttonKey}
              hint={action.caption}
              icon={action.icon}
              disabled={action.disabled}
              onClick={() => gridComponent && action.onClick && action.onClick(gridComponent)}
            />
          );
        })}
      {overflowActions.length > 0 && (
        <QuinoContextMenuButton
          buttonClassname={'list-action-bar-overflow-button'}
          buttonIcon={'material-icons-outlined more_vert'}
          dataSource={overflowActions.map(mapActionToItem)}
          buttonId={'quino-list-selection-overflow-button'}
          buttonHintKey={'ActionArea.MoreActions'}
          position={{ at: 'right bottom', my: 'right top' }}
          submenuDirection={'left'}
        />
      )}
    </div>
  );
}
