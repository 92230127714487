import React, { useCallback, useEffect } from 'react';
import dxDataGrid, { DataChange } from 'devextreme/ui/data_grid';
import { IContainer } from '../../../ioc';
import { Button } from 'devextreme-react/button';
import { ILogger, ITranslationService, ITranslationServiceSymbol, QuinoCoreServiceSymbols } from '@quino/core';
import {
  IQuinoShortcutSettings,
  IQuinoShortcutSettingsSymbol,
  IRegisterShortcutService,
  IRegisterShortcutServiceSymbol,
  IShortcutInformationService,
  IShortcutInformationServiceSymbol
} from '../../../shortcuts';
import { IFeedbackService, IFeedbackServiceSymbol } from '../../../feedback';

export function QuinoDataGridInlineEditActionBar(props: {
  gridComponent: dxDataGrid | undefined;
  applicationContainer: IContainer;
  showCreateButton: boolean;
}) {
  const translationService = props.applicationContainer.get<ITranslationService>(ITranslationServiceSymbol);
  const shortcutSettings = props.applicationContainer.get<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);
  const registerShortcutService = props.applicationContainer.get<IRegisterShortcutService>(IRegisterShortcutServiceSymbol);
  const feedbackService = props.applicationContainer.get<IFeedbackService>(IFeedbackServiceSymbol);
  const logger = props.applicationContainer.get<ILogger>(QuinoCoreServiceSymbols.ILogger);
  const shortcutInfo = props.applicationContainer.get<IShortcutInformationService>(IShortcutInformationServiceSymbol).get();

  const componentForPendingChanges: DataChange[] | undefined = props.gridComponent?.option('editing.changes');
  const pendingChanges = componentForPendingChanges ? componentForPendingChanges.length : 0;

  const saveChanges = useCallback(() => {
    props.gridComponent?.saveEditData().catch(logger.logError);
  }, [logger.logError, props.gridComponent]);

  const discardChanges = useCallback(() => {
    feedbackService
      .getConfirmation(
        translationService.translate('QuinoDataGrid.EditModeDiscardEdits.Title'),
        translationService.translate('QuinoDataGrid.EditModeDiscardEditsShortcut.Text')
      )
      .then((discard) => discard && props.gridComponent?.cancelEditData())
      .catch(logger.logError);
  }, [feedbackService, logger.logError, props.gridComponent, translationService]);

  const addRow = useCallback(() => {
    props.gridComponent?.addRow().catch(logger.logError);
  }, [logger.logError, props.gridComponent]);

  useEffect(() => {
    const changeChangesShortcutSymbols = registerShortcutService.registerShortcut([shortcutSettings.saveDetail], saveChanges, {
      keyHandlerDisabled: !pendingChanges
    });
    const discardChangesShortcutSymbol = registerShortcutService.registerShortcut([shortcutSettings.discardDetail], discardChanges, {
      keyHandlerDisabled: !pendingChanges
    });
    const addRowShortcutSymbol = registerShortcutService.registerShortcut([shortcutSettings.createEntryPrimary], addRow, {});

    return () => {
      registerShortcutService.unregisterShortcut(changeChangesShortcutSymbols);
      registerShortcutService.unregisterShortcut(discardChangesShortcutSymbol);
      registerShortcutService.unregisterShortcut(addRowShortcutSymbol);
    };
  }, [addRow, discardChanges, pendingChanges, registerShortcutService, saveChanges, shortcutSettings]);

  return (
    <div className='list-action-bar'>
      <div>
        <b>{pendingChanges || 0}</b> {translationService.translate('QuinoDataGrid.InlineEditBar.Changes')}
      </div>
      <div className={'list-action-bar-button-wrapper'}>
        {props.showCreateButton && (
          <Button
            className={'list-action-bar-button'}
            stylingMode={'text'}
            icon={'material-icons-outlined add'}
            hint={shortcutInfo.hint(shortcutSettings.createEntryPrimary)}
            onClick={addRow}
          />
        )}
        <Button
          className={'list-action-bar-button'}
          stylingMode={'text'}
          icon={'material-icons-outlined replay'}
          hint={shortcutInfo.hint(shortcutSettings.discardDetail)}
          disabled={!pendingChanges}
          onClick={discardChanges}
        />
        <Button
          className={'list-action-bar-button'}
          stylingMode={'text'}
          icon={'material-icons-outlined save'}
          hint={shortcutInfo.hint(shortcutSettings.saveDetail)}
          disabled={!pendingChanges}
          onClick={saveChanges}
        />
      </div>
    </div>
  );
}
