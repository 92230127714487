import { IApplication } from '../../application';
import { ExportExcelModule } from './excel';
import { ExportFileService, IExportFileService } from './common';
import { QuinoCoreServiceSymbols } from '../../ioc';

export class ExportFileModule {
  static use = (application: IApplication): IApplication => {
    application.registerSingle<IExportFileService>(QuinoCoreServiceSymbols.IExportFileService, ExportFileService);
    ExportExcelModule.use(application);
    return application;
  };
}
