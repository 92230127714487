import React, { ReactElement } from 'react';
import { IDashboardLayout, IDashboardTileRegistration } from '../dashboard';
import { QuinoDashboardActions } from '../dashboard/QuinoDashboardActions';
import { QuinoDashboard } from '../dashboard/QuinoDashboard';
import { StateFullBookmark } from './StateFullBookmark';
import { DashboardBookmarkEventType, DashboardBookmarkSymbol, IDashboardBookmark, IDashboardBookmarkEvent } from './IDashboardBookmark';

export class DashboardBookmark extends StateFullBookmark implements IDashboardBookmark {
  constructor(title: string, dashboardLayout: IDashboardLayout) {
    super();
    this._title = title;
    this.layout = dashboardLayout;
  }

  get title(): string {
    return this._title;
  }

  renderActions(): ReactElement {
    return <QuinoDashboardActions bookmark={this} />;
  }

  render(): ReactElement {
    return <QuinoDashboard bookmark={this} />;
  }

  hasChanges(): boolean {
    return this._pendingChanges;
  }

  setHasChanges(pendingChanges: boolean) {
    this.notifyChangeHandlers(this.createDashboardBookmarkEvent('changes', pendingChanges));
    this._pendingChanges = pendingChanges;
  }

  getCurrentDashboard(): IDashboardLayout {
    return this.layout;
  }

  addTile(type: IDashboardTileRegistration<any>, payload: any = {}): void {
    this.notifyChangeHandlers(this.createDashboardBookmarkEvent('addTile', { type: type, payload: payload }));
  }

  async save(preventUIUpdate?: boolean): Promise<void> {
    if (!preventUIUpdate) {
      this.notifyChangeHandlers(this.createDashboardBookmarkEvent('save'));
      this.notifyChangeHandlers(this.createDashboardBookmarkEvent('edit', false));
    }

    // TODO: Save directly from here (instead of in QuinoDashboard)
    return Promise.resolve();
  }

  reset(preventUIUpdate?: boolean): void {
    if (!preventUIUpdate) {
      this.notifyChangeHandlers(this.createDashboardBookmarkEvent('reset'));
      this.notifyChangeHandlers(this.createDashboardBookmarkEvent('edit', false));
    }

    // TODO: Reset directly from here (instead of in QuinoDashboard)
  }

  refresh(): void {
    this.notifyChangeHandlers(this.createDashboardBookmarkEvent('refresh'));
  }

  setEditMode(editMode: boolean): void {
    this.notifyChangeHandlers(this.createDashboardBookmarkEvent('edit', editMode));
  }

  subscribe(callback: (event: IDashboardBookmarkEvent) => void): Symbol {
    const symbol = Symbol();
    this.changeHandlers.set(symbol, callback);
    return symbol;
  }

  unsubscribe(symbol: Symbol): void {
    this.changeHandlers.delete(symbol);
  }

  notifyChangeHandlers(event: IDashboardBookmarkEvent): void {
    this.changeHandlers.forEach((value) => value(event));
  }

  createDashboardBookmarkEvent(eventType: DashboardBookmarkEventType, payload?: any): IDashboardBookmarkEvent {
    return {
      type: eventType,
      payload: payload
    } as IDashboardBookmarkEvent;
  }

  readonly name = DashboardBookmarkSymbol;
  layout: IDashboardLayout;
  private _pendingChanges = false;
  private readonly _title: string;
  private readonly changeHandlers = new Map<Symbol, (event: IDashboardBookmarkEvent) => void>();
}
