import { IQuinoExcelCell } from './IQuinoExcelCell';

export class QuinoExcelCell implements IQuinoExcelCell {
  constructor(
    private readonly textWrapped: boolean,
    private readonly cellValue: string | number | Date,
    private readonly formatString: string | undefined,
    private readonly fontName: string,
    private readonly fontSize: number,
    private readonly textIsBold: boolean,
    private readonly marginRight: number
  ) {}

  wrapText(): boolean {
    return this.textWrapped;
  }

  value(): string | number | Date {
    return this.cellValue;
  }

  format(): string | undefined {
    return this.formatString;
  }

  getFontName(): string {
    return this.fontName;
  }

  getFontSize(): number {
    return this.fontSize;
  }

  isBold(): boolean {
    return this.textIsBold;
  }

  getMarginRight(): number {
    return this.marginRight;
  }
}
