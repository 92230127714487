import { IApplication } from './IApplication';
import { IExecutable } from './IExecutable';

/**
 * Default execution group for Initialization not depending on the framework or an authenticated context
 */
export const InitializationGroup = Symbol.for('InitializationGroup');
/**
 * Default execution group for theme customization startup actions.
 */
export const ThemeInitializationGroup = Symbol.for('ThemeInitializationGroup');
/**
 * Default execution group for all framework dependent startup actions.
 */
export const FrameworkStartupGroup = Symbol.for('FrameworkStartup');

/**
 * Default group for all application startup actions. They'll run after the framework startup actions.
 */
export const ApplicationStartupGroup = Symbol.for('ApplicationStartup');

/**
 * A group of actions that are executed in parallel.
 */
export class ExecutionGroup implements IExecutable {
  constructor(public identifier: Symbol, public parent: IExecutable | null) {}

  execute = (app: IApplication): void | Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises,no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        // If all children are executable execute them in parallel.
        if (this.children.every((value) => value.children.length === 0)) {
          await Promise.all(this.children.map((child) => child.execute(app)));
        } else {
          // If any of our children are groups keep the ordering and iterate and execute them one by one.
          for (const child of this.children) {
            await child.execute(app);
          }
        }
      } catch (e) {
        reject(e);
      }

      resolve();
    });
  };

  before = (symbol: Symbol): void => {
    const siblings = this.getSiblings();
    const otherGroupSameLevel = this.getOther(siblings, symbol);
    const indexOther = siblings.indexOf(otherGroupSameLevel);
    const indexCurrent = siblings.indexOf(this);

    if (indexCurrent < indexOther) {
      return;
    }

    siblings.splice(indexCurrent, 1);
    siblings.splice(indexOther, 0, this);
  };

  after = (symbol: Symbol): void => {
    const siblings = this.getSiblings();
    const otherGroupSameLevel = this.getOther(siblings, symbol);
    const indexOther = siblings.indexOf(otherGroupSameLevel);
    const indexCurrent = siblings.indexOf(this);

    if (indexCurrent > indexOther) {
      return;
    }

    siblings.splice(indexCurrent, 1);
    siblings.splice(indexOther + 1, 0, this);
  };

  children: IExecutable[] = [];

  private readonly getOther = (siblings: IExecutable[], symbol: Symbol): IExecutable => {
    const otherGroupSameLevel = siblings.find((value) => value.identifier === symbol);
    if (otherGroupSameLevel == null) {
      throw new Error('To order groups make sure they have the same parent.');
    }

    return otherGroupSameLevel;
  };

  private readonly getSiblings = (): IExecutable[] => {
    if (this.parent == null) {
      throw new Error('Group without a parent can not be ordered.');
    }

    return this.parent.children;
  };
}
