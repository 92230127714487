import { IMetaVisitor } from './IMetaVisitor';
import { IMetaElement } from './IMetaElement';
import { isIMetaGroup } from './IMetaGroup';
import { injectable } from 'inversify';

@injectable()
export class MetaVisitor implements IMetaVisitor {
  visit = (root: IMetaElement, callback: (element: IMetaElement) => void): void => {
    callback(root);

    if (isIMetaGroup(root)) {
      for (const subElement of root.elements) {
        this.visit(subElement, callback);
      }
    }
  };
}
