export const ICalendarConfigurationSymbol = Symbol.for('ICalendarConfiguration');

export type TCalendarView = 'day' | 'week' | 'workWeek' | 'month' | 'timelineDay' | 'timelineWeek' | 'timelineWorkWeek' | 'timelineMonth' | 'agenda';

export interface ICalendarConfiguration {
  /**
   * The default calendar view.
   */
  defaultView: TCalendarView;

  /**
   * The available calendar views.
   */
  availableViews: Array<TCalendarView>;

  /**
   * Specifies whether to show a dropdown-view-switcher instead of a button per view
   */
  useDropDownViewSwitcher: boolean;

  /**
   * Whether to hide the link in the tooltip or not.
   */
  hideLink: boolean;
}

export const defaultCalendarConfiguration: ICalendarConfiguration = {
  defaultView: 'workWeek',
  availableViews: ['day', 'workWeek', 'week', 'month'],
  useDropDownViewSwitcher: false,
  hideLink: false
};
