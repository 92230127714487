import React, { useState } from 'react';
import { InternalQuinoServerHealthChecker } from './InternalQuinoServerHealthChecker';
import { IApplication, QuinoApplication } from '@quino/core';
import { useOnMount } from '../Util';
import { ContainerContext } from '../../ioc';
import { ServerLessModule } from './ServerLessModule';

export interface ContainerLessQuinoServerHealthChecker {
  visible: boolean;
  isNetworkError?: boolean;
  isLoading: boolean;
  cause?: string;
}

export const firstErrorOccurredLocalStorageKey = 'firstErrorOccurred';

export const ContainerLessQuinoServerHealthChecker: React.FunctionComponent<ContainerLessQuinoServerHealthChecker> = (props) => {
  const [application, setApplication] = useState<IApplication>();

  useOnMount(() => {
    const application = new QuinoApplication();
    ServerLessModule.use(application);
    application
      .build()
      .then(() => {
        setApplication(application);
      })
      .catch((e) => {
        console.error(e);
      });
  });

  let firstErrorOccurredString = localStorage.getItem(firstErrorOccurredLocalStorageKey);
  if (!props.isLoading) {
    if (props.visible && props.isNetworkError) {
      if (!firstErrorOccurredString || isNaN(Number(firstErrorOccurredString))) {
        firstErrorOccurredString = Date.now().toString();
        localStorage.setItem(firstErrorOccurredLocalStorageKey, firstErrorOccurredString);
      }
    } else {
      localStorage.removeItem(firstErrorOccurredLocalStorageKey);
    }
  }

  if (!application) {
    return <></>;
  }

  return (
    <>
      {props.visible &&
        ((props.isNetworkError && (
          <ContainerContext.Provider value={{ container: application }}>
            <InternalQuinoServerHealthChecker cause={props.cause} firstErrorOccurredDate={Number(firstErrorOccurredString)} />
          </ContainerContext.Provider>
        )) ||
          props.children)}
    </>
  );
};
