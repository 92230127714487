import { ControlExcelMapperBase } from './ControlExcelMapperBase';

export const DefaultControlExcelMapperSymbol = Symbol.for('DefaultControlExcelMapper');

export class DefaultControlExcelMapper extends ControlExcelMapperBase {
  getMappableElements(): string[] {
    return [
      'LanguagePicker',
      'LookupEdit',
      'MultiLineTextEditor',
      'SearchEdit',
      'SingleLineTextEditor',
      'DatePicker',
      'DateTimePicker',
      'TimePicker'
    ];
  }
}
